import { validEmailRegexp } from 'constants/email';
import { isExpired } from './validators';
import { vaioSettings } from '../../VaioProviders/helpers';

const MIN_PORT_VALUE = 0;
const MAX_PORT_VALUE = 65535;
const MIN_RECONNECT_SECONDS = 2;
const MAX_RECONNECT_SECONDS = 900;
const MIN_REQUIRED_KEY_LENGTH = 18;

export const regexUrlHasPort = /:\d+/u;

// true condition == error
// eslint-disable-next-line complexity
export const getFormValidations = (formValues, requiredInputs) => {
    const { keyExpiration, reconnectionDelay, locationType } = formValues;
    const name = formValues.name.trim();
    const email = formValues.email?.trim();
    const botName = formValues.botName.trim();
    const url = formValues.url.trim();
    const vaioKey = formValues.vaioKey?.trim();
    const httpsPort = parseInt(formValues.httpsPort) || 0;
    const wssPort = parseInt(formValues.wssPort) || 0;

    const portInUrl = regexUrlHasPort.test(url);

    return { // true condition == error
        name: !name,
        email: !validEmailRegexp.test(email),
        botName: botName && !/^[a-zA-Z0-9]+$/u.test(botName), // only allow alphanumeric
        url:
            (requiredInputs.url && !url) ||
            /\s/u.test(url) || // No spaces allowed
            (!portInUrl && !httpsPort) || // Port must be in url if not valid httpsPort
            (!portInUrl && !wssPort),  // Port must be in url if not valid wssPort
        vaioKey: requiredInputs.vaioKey && (!vaioKey || vaioKey.length < MIN_REQUIRED_KEY_LENGTH),
        keyExpiration: locationType === vaioSettings.locationTypes.cloudBased && keyExpiration && isExpired(keyExpiration),
        httpsPort:
            (requiredInputs.httpsPort && !httpsPort) ||
            httpsPort < MIN_PORT_VALUE ||
            httpsPort > MAX_PORT_VALUE,
        wssPort:
            (requiredInputs.wssPort &&  !wssPort) ||
            wssPort < MIN_PORT_VALUE ||
            wssPort > MAX_PORT_VALUE,
        reconnectionDelay:
            isNaN(reconnectionDelay) ||
            reconnectionDelay < MIN_RECONNECT_SECONDS ||
            reconnectionDelay > MAX_RECONNECT_SECONDS,
    };
};
