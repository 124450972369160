
import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { PUBLIC_ROUTES } from 'constants/routes';
import { CommonConstants } from 'Constants';
import { useAccess } from 'hooks/useAccess';
import { Button } from 'library/Button';
import { DownloadLink } from 'library/DownloadLink';
import { Title } from 'library/Title';
import { Label } from 'library/Label';
import { SearchOptionable } from 'library/SearchOptionable';
import { ReactComponent as HierarchyIcon } from 'assets/icons/hierarchy.svg';
import { searchOptionsUser, searchOptionsDistributor } from '../searchOptions';
import { getProfile } from 'services/Auth';
import { useSearchWithDebounce } from 'hooks/useSearchWithDebounce';

import './Header.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getOwnerAccount } from 'actions/userAccounts';

export const defaultSearchValue = {
    selectValue: 'Store_Number',
    searchValue: ''
};

const { Subscription_ID: subscriptionId } = getProfile();

const { subscription, userPermissions } = CommonConstants;

const notAvailableSubscriptionsIds = [
    Number(subscription.basic),
    Number(subscription.basicWithUpgrade),
    Number(subscription.nitroEssentials)
];

export const Header = ({
    uuid,
    isCorpUser = false,
    isDistributorUser = false,
    isFileLoading = false,
    isStoresAvailable = false,
    onSearch,
    onAddNewStore,
    onDownloadFile,
    onModalOpen
}) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState(defaultSearchValue);
    const hasAddStoreAccess = useAccess(userPermissions.AddStore);
    const { onSearchChangeHandler, onSearchHandler } = useSearchWithDebounce(onSearch, setSearchValue, { searchValueSetter: onSearch });
    const hasEditStoreAdvancedPermission = useAccess(userPermissions.EditStoreAdvanced);

    const isNeedToShowManageHierarchyButton = useMemo(() => !uuid && !notAvailableSubscriptionsIds.includes(subscriptionId), [uuid]);
    const searchOptions = useMemo(() => isDistributorUser ? searchOptionsDistributor : searchOptionsUser, [isDistributorUser]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (uuid) {
            getOwnerAccount(uuid)(dispatch);
        }
    }, [uuid]);

    const isAccountActive = useSelector(({ userAccounts: { userAccounts = [] } = {} } = {}) => userAccounts[0]?.Account_IsActive, shallowEqual) === 1;

    const downloadLinkDisabled = useMemo(() => isFileLoading || (uuid && !isAccountActive) || !isStoresAvailable, [isFileLoading, isAccountActive, isStoresAvailable]);

    return (
        <div className="hme-stores-status--public__header">
            <Title>{t('common__stores')}</Title>
            <SearchOptionable
                value={searchValue}
                onChange={onSearchChangeHandler}
                onSearch={onSearchHandler}
                searchSelectOptions={searchOptions}
            />
            <div className="hme-stores-status--public__header__actions-wrapper">
                {
                    isStoresAvailable && (
                        <Button className="hme-stores-status--public__header__sort-btn" onClick={onModalOpen}>
                            {t('common__sort')}
                        </Button>
                    )
                }
                <div
                    className={classNames('hme-stores-status--public__header__actions', {
                        'hme-stores-status--public__header__actions--borderless': !isStoresAvailable
                    })}
                >
                    {
                        hasEditStoreAdvancedPermission &&
                        isNeedToShowManageHierarchyButton &&
                        <div className="hme-stores-status--public__header__manage-hierarchy">
                            <Link type="button" to={`/settings/${PUBLIC_ROUTES.manageReportGroups}`}>
                                <Button variants={['transparent']}>
                                    <HierarchyIcon
                                        className="hme-s-size hme-stores-status--public__header__manage-hierarchy__icon"
                                    />
                                    {t('stores-hierarchy__title')}
                                </Button>
                            </Link>
                        </div>
                    }
                    <div className="hme-stores-status--public__header__download-stores">
                        <DownloadLink
                            className="hme-stores-status--public__header__download-stores-btn"
                            disabled={downloadLinkDisabled}
                            onClick={onDownloadFile}
                        >
                            {t('common__download--csv')}
                        </DownloadLink>
                    </div>
                </div>
            </div>
            {
                isCorpUser && <Label className="hme-stores-status--public__corporate-stores">* {t('stores__corporate-store')}</Label>
            }
            {
                uuid && hasAddStoreAccess &&
                <Button
                    className="hme-stores-status--public__header__add-store-btn"
                    onClick={onAddNewStore}
                >{t('admin__stores__add-store')}</Button>
            }
        </div>
    );
};
