import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { identity, map, sortBy, prop, pipe, cond, pluck, uniq, uniqBy, T } from 'ramda';

import { Grid } from 'components/Common/Grid';
import {
    InProgressStatus,
    CompletedStatus,
    FailedStatus,
    DownloadInProgressStatus,
    DownloadCompletedStatus,
    DownloadFailedStatus,
    SnapshotOverwritenStatus,
    ApplyInProgressStatus,
    ApplyFailedStatus,
    UnknownStatus,
} from 'pages/ApplyDeviceSettings/DeviceSettings/JobsList/StatusCells';
import { taskConfig } from 'constants/applyDeviceSettingsTask';

import {
    getStatusCell,
    getStatusCellValue,
} from '../../../DeviceSettings/helpers';

const unknownStatusValue = -1;

const ALL_BRANDS_FILTER_TEXT = 'apply-device-settings-status__grid-filter__all-brands';
const ALL_VERSIONS_FILTER_TEXT = 'apply-device-settings-status__grid-filter__all-versions';
const ALL_STATUSES_FILTER_TEXT = 'apply-device-settings-status__grid-filter__all-statuses';

const { statuses: deviceJobStatuses, inProgressStatuses } = taskConfig;

const gridHeaders = [
    {
        text: 'apply-device-settings-status__grid-title__target-devices',
        property: [
            {
                text: 'common__device__serial-number',
                property: 'Device_SerialNumber',
                className: 'apply-job-serial-number',
                flex: 1.5,
            },
            {
                text: 'common__device__product-id',
                property: 'Device_Product_ID',
                className: 'hme-grid-cell-show-all-content apply-job-serial-number',
                flex: 1.5,
            },
            {
                text: 'common__store__name',
                property: 'Store_Name',
            },
            {
                text: 'common__brand',
                property: 'Brand_Name',
            },
            {
                text: 'common__store__number',
                property: 'Store_Number',
                className: 'apply-job-store-number',
            },
            {
                text: 'common__device__version',
                property: 'Device_MainVersion',
            },
            {
                text: 'apply-device-settings-status__grid-header__device-status',
                property: 'statusCell',
                flex: 1,
            },
            {
                text: '',
                property: 'nothing',
                className: 'hme-grid-empty-cell',
                flex: 1,
            },
        ],
    },
    {
        text: '',
        property: [
            {
                text: '',
                property: 'empty',
                flex: 6,
                className: 'hme-grid-empty-cell',
            },
        ],
    },
];

const deviceToRow = (device) => ({
    ...device,
    statusCell: getStatusCell(device.ApplySettingsStatus),
    statusCellValue: getStatusCellValue(device.ApplySettingsStatus),
});

const sortDevices = (devices) => devices.sort((a, b) => a.ApplySettingsStatus - b.ApplySettingsStatus);

const statusFilterItems = [
    {
        text: <InProgressStatus />,
        value: deviceJobStatuses.IN_PROGRESS,
    },
    {
        text: <CompletedStatus />,
        value: deviceJobStatuses.COMPLETED,
    },
    {
        text: <FailedStatus />,
        value: deviceJobStatuses.FAILED,
    },
    {
        text: <DownloadInProgressStatus />,
        value: deviceJobStatuses.DOWNLOAD_IN_PROGRESS,
    },
    {
        text: <DownloadCompletedStatus />,
        value: deviceJobStatuses.DOWNLOAD_COMPLETED,
    },
    {
        text: <DownloadFailedStatus />,
        value: deviceJobStatuses.DOWNLOAD_FAILED,
    },
    {
        text: <SnapshotOverwritenStatus />,
        value: deviceJobStatuses.SNAPSHOT_OVERWRITEN,
    },
    {
        text: <ApplyInProgressStatus />,
        value: deviceJobStatuses.APPLY_IN_PROGRESS,
    },
    {
        text: <ApplyFailedStatus />,
        value: deviceJobStatuses.APPLY_FAILED,
    },
    {
        text: <UnknownStatus />,
        value: unknownStatusValue,
    },
];
const knownStatuses = pluck('value', statusFilterItems);

const getBrandData = ({ Brand_ID: value, Brand_Name: text }) => ({
    text,
    value,
});
const getBrands = pipe(map(getBrandData), uniqBy(prop('value')), sortBy(prop('text')));
const getVersions = pipe(pluck('Device_MainVersion'), uniq, sortBy(identity));
const getDeviceStatuses = pipe(pluck('ApplySettingsStatus'), uniq);

export const TargetDeviceList = ({ data }) => {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [gridFilters, setGridFilters] = useState({
        Brand_Name: [],
        Device_MainVersion: [],
        statusCell: [],
    });
    const [availableFilters, setAvailableFilters] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const brands = getBrands(rows);
        const versions = getVersions(rows);

        // We need to think may be move it out somewhere to reuse in other filters by versions,
        // statuses, etc.
        const devicesStatuses = getDeviceStatuses(rows).map((status) =>
            knownStatuses.includes(status) ? status : unknownStatusValue,
        );

        const availableStatuses = statusFilterItems.filter(({ value }) => devicesStatuses.includes(value));

        setGridFilters({
            Brand_Name: pluck('value', brands),
            Device_MainVersion: versions,
            statusCell: pluck('value', availableStatuses),
        });

        setAvailableFilters([
            {
                property: 'Brand_Name',
                items: brands,
                allText: ALL_BRANDS_FILTER_TEXT,
            },
            {
                property: 'Device_MainVersion',
                items: versions.map((version) => ({
                    text: version,
                    value: version,
                })),
                allText: ALL_VERSIONS_FILTER_TEXT,
            },
            {
                property: 'statusCell',
                items: availableStatuses,
                allText: ALL_STATUSES_FILTER_TEXT,
            },
        ]);
    }, [rows, setGridFilters, setAvailableFilters]);

    useEffect(() => {
        const newFilteredDevices = rows
            .filter(({ Brand_ID }) => gridFilters.Brand_Name.includes(Brand_ID))
            .filter(({ Device_MainVersion }) => gridFilters.Device_MainVersion.includes(Device_MainVersion))
            // @todo need to verify that we can use data will be filtered correct
            .filter(({ statusCellValue }) => statusFilterItems.map(({ value }) => value).includes(statusCellValue));
        setFilteredRows(newFilteredDevices);
    }, [rows, gridFilters, setFilteredRows]);

    useEffect(() => {
        const devices = data.destinationDevices.map(device => deviceToRow(device));

        setRows(sortDevices(devices));
    }, [data.destinationDevices, setRows]);

    return (
        <Grid
            rows={filteredRows}
            headers={gridHeaders}
            rowKey="Device_UID"
            availableFilters={availableFilters}
            filters={gridFilters}
            onFiltersChange={setGridFilters}
            noRecordsMessage={t('apply-device-settings-status__no-devices--found')}
        />
    );
};
