const sortByStatus = (accountsList, sortBy, direction) => {
    const sortFunc = (val1, val2) => direction * (val1[sortBy] - val2[sortBy]);
    return accountsList.sort(sortFunc);
};

const defaultSort = (accountsList, sortBy, direction) => {
    const defaultSortFunc = (val1, val2) => direction *
        val1[sortBy].toLowerCase().localeCompare(val2[sortBy].toLowerCase());
    return accountsList.sort(defaultSortFunc)
};

export const getSortedAccountsList = (accountsList, newSortData) => {
    const [[sortBy, sortDirection]] = Object.entries(newSortData);
    switch (sortBy) {
        case 'isActive':
            return sortByStatus(accountsList, 'Account_IsActive', sortDirection);
        default:
            return defaultSort(accountsList, sortBy, sortDirection);
    }
};
