import {sortBySerialNumber} from "./sortBySerialNumber";
import {sortByVersion} from "./sortByVersion";
import {sortByStatus} from "./sortByStatus";
import {sortByStoreProps} from "./sortByStoreProps";
import {applySearchValue} from 'components/Common/Grid';
import {searchOptions} from "../constants";
import { sortByProductID } from "./sortByProductID";
import { mapDeviceColumns } from "./mapDeviceColumns";

const sortRows = (rows, sorting) => {
    const prop = Object.keys(sorting)[0];
    const direction = sorting[prop];

    switch (prop) {
        case 'SerialNumber':
            return sortBySerialNumber(rows, direction).map(({ Device_Details: devices, ...row }) => mapDeviceColumns({ devices, ...row }));
        case 'Version':
            return sortByVersion(rows, direction).map(({ Device_Details: devices, ...row }) => mapDeviceColumns({ devices, ...row }));
        case 'ProductID':
        case 'DeviceProductID':
            return sortByProductID(rows, direction).map(({ Device_Details: devices, ...row }) => mapDeviceColumns({ devices, ...row }));

        case 'Status':
            return sortByStatus(rows, direction).map(({ Device_Details: devices, ...row }) => mapDeviceColumns({ devices, ...row }));

        case 'brandText':
            return sortByStoreProps(rows, direction, 'Brand_Name');

        default:
            return sortByStoreProps(rows, direction, prop);
    }
};

const allSearchFields = searchOptions
    .map(({ value }) => value)
    .filter(value => !!value);

export const filterAndSort = (searchValue, rows, sorting, setFilteredRows, setIsFiltering) => {
    setIsFiltering(true);

    const fields = searchValue.selectValue ? [searchValue.selectValue] : allSearchFields;
    const newFilteredRows = applySearchValue(rows, fields, searchValue.searchValue, { minLength: 1 });
    const sortedRows = sortRows(newFilteredRows, sorting);

    setFilteredRows([...sortedRows]);
    setIsFiltering(false);
};
