import { useState } from 'react';
import { compose, cond, T } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import { Loader } from 'library/Loader';
import { LoadingModal } from 'library/LoadingModal';
import { NotificationsList } from 'library/NotificationsList'

import { TypeFilter, TYPE_FILTERS } from './TypeFilter';
import { Header } from './Header';
import { List } from './List';
import { Modals } from './Modals';
import { useNotificationsCenterContext } from './Context';

import './NotificationsCenter.scss';

const NotificationsCenterPage = ({
    notifications,
    isLoadingModalShown,
}) => {
    const [typeFilter, setTypeFilter] = useState(TYPE_FILTERS.ALL);
    const {
        removeNotification,
    } = useNotificationsCenterContext();

    return (
        <div className='hme-notifications-center'>
        <NotificationsList />
            <Header />
            { !!notifications.length && <TypeFilter value={typeFilter} onChange={setTypeFilter} /> }
            <List
                notifications={notifications}
            />
            <LoadingModal show={isLoadingModalShown || removeNotification.isRemoving} />
            <Modals />
        </div>
    );
};

const NotificationCenterCond = cond([
    [({ isNotificationsLoading }) => isNotificationsLoading, () => (
        <div className='hme-notifications-center'>
            <Loader />
        </div>)],
    [T, (props) => <NotificationsCenterPage {...props} />]
]);

export const NotificationsCenter = compose(
    withHMELayout()
)(NotificationCenterCond);
