import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { baseAuthService } from 'components/Security/AuthenticationService';
import { Config } from 'Config';

import { useLocation } from 'react-router-dom';

const ga4Key = Config.ga4Key;

if (ga4Key) {
    ReactGA.initialize(ga4Key, {
        gtagOptions: {
            send_page_view: false,
        },
    });
}

const AnalyticsComponent = () => {
    const location = useLocation();

    useEffect(() => {
        if (!ga4Key) {
            return;
        }

        const isAdmin = baseAuthService.isAdmin();

        if (isAdmin) {
            return;
        }

        const isMasquerade = baseAuthService.isMasquerade();

        if (isMasquerade) {
            return;
        }

        ReactGA.send('pageview');
    }, [location]);

    return null;
};

export const Analytics = AnalyticsComponent;
