import React from 'react';
/**
 *  Hook that handles stores and permission checking before executing the callback
 *  @param {boolean} noPermissions
 *  @param {boolean} noStores
 *  @param {object} t translation object
 *  @return {JSX} error message
 */
const AccountErrorMessage = ({ noPermissions, noStores, t}) => {
    if (noPermissions) {
        return (
            <div className="reportBasicUser">
                <h3 className="up_head">{t('common__upgrade-account-to-premium')}</h3>
                <h5 className="up_sub">{t('common__call-to-upgrade')}</h5>
                <div className="contactList">
                    <div className="contact-d row">
                        <span className="contact-dr col-12 col-sm-4 col-md-2">{t('common__domestic-sales--title')}</span>
                        <span className="col-sm-8 col-12 col-md-10">{t('common__domestic-sales--info')}</span>
                    </div>
                    <div className="contact-d row">
                        <span className="contact-dr col-12 col-sm-4 col-md-2">{t('common__international-sales--title')}</span>
                        <span className="col-sm-8 col-12 col-md-10">{t('common__international-sales--info-regions')}</span>
                    </div>
                    <div className="contact-d row">
                        <span className="contact-dr col-12 col-sm-4 col-md-2"></span>
                        <span className="col-sm-8 col-12 col-md-10">{t('common__international-sales--info-other-countries')}</span>
                    </div>
                </div>
            </div>
        );
    } else if (noStores) {
        return (<div>{t('common__register-zoom')}</div>);
    }
};

export default AccountErrorMessage;