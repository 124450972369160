import classNames from 'classnames';

import { ReactComponent as Ribbon } from 'assets/icons/certificate-ribbon.svg';

import './Rank.scss';

export const Rank = ({ rank }) => {
    return (
        <div className={classNames(
            'hme-contest-view-edit-form__results__rank',
            {
                'hme-contest-view-edit-form__results__rank--leader': rank === 1,
            }
        )}>
            {rank}
            {rank === 1 && <Ribbon className='hme-contest-view-edit-form__results__rank__ribbon' />}
        </div>
    );
}