import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { OnOff } from 'library/OnOff';
import { GridActions } from 'library/GridActions';
import { Button } from 'library/Button';

const buttonVariants = ['transparent'];

export const Actions = ({ notification, onEnable, onDisable, onRemove }) => {
    const { t } = useTranslation();

    const editLink = useMemo(
        () => `/admin/customerNotification/${notification.customerNotificationID}`,
        [notification.customerNotificationID]
    );

    const onSliderChanged = useCallback(() => {
        if (notification.isEnabled) {
            return onDisable && onDisable(notification);
        }

        return onEnable && onEnable(notification);
    }, [notification, onEnable, onDisable]);

    const onRemoveNotification = useCallback(() => {
        onRemove && onRemove(notification);
    }, [notification, onRemove]);

    return (
        <GridActions className='hme-customer-notification-status__actions'>
            <OnOff
                checked={notification.isEnabled}
                onChange={onSliderChanged}
            />
            <Button
                variants={buttonVariants}
                className='hme-customer-notification-status__actions__edit'
                as={Link}
                to={editLink}
            >{t('common__edit')}</Button>
            <Button variants={buttonVariants} onClick={onRemoveNotification}>{t('common__delete')}</Button>
        </GridActions>
    );
}
