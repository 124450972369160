import React from 'react';

import { Button } from 'library/Button';

import './Footer.scss';
import { useTranslation } from 'react-i18next';

export const Footer = ({ isSubmitEnabled, onMerge }) => {
    const { t } = useTranslation();
    return (
        <div className="merge-devices-footer">
            <Button variants={['submit']} disabled={!isSubmitEnabled} onClick={onMerge}>
                {t('merge_devices__footer__submit')}
            </Button>
        </div>
    );
};
