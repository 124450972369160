import { Config } from '../Config';
import { CommonConstants } from '../Constants';
import { getDataAsync, postDataAsync } from '../Api';
const { deviceType } = CommonConstants;

import { deviceConfig } from 'constants/device';

const {
    zoomNitro: {
        applySettingsMinVersion: applySettingsMinVersionNitro,
    },
    zoom: {
        applySettingsMinVersion: applySettingsMinVersionZoom,
        applySettingsMaxVersion: applySettingsMaxVersionZoom,
    }
} = deviceConfig;

const getSearchString = obj => Object.keys(obj).reduce((params, key) => {
    params.set(key, obj[key]);
    return params;
}, new URLSearchParams()).toString();

export const getBrands = async () => {
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.getBrands;

    const response = await getDataAsync(url);

    return response.status ? response.data : [];
};

export const getAccountBrandDevices = async ({
    accountId,
    sourceDevice,
    selectedDeviceType,
}) => {
    const filters = {
        accountId,
        deviceIsActive: 1,
    };

    switch (selectedDeviceType) {
        case 'zoom':
            filters.deviceTypeId = deviceType.zoom.id;
            filters.deviceMaxMainVersion = applySettingsMaxVersionZoom;
            filters.deviceMinMainVersion = sourceDevice ?
                sourceDevice.Device_MainVersion :
                applySettingsMinVersionZoom;

            break;
        case 'zoomNitro':
            filters.deviceTypeId = deviceType.zoomNitro.id;
            filters.deviceMinMainVersion = applySettingsMinVersionNitro;

            break;
        case 'nexeo':
            filters.deviceTypeId = deviceType.nexeo.id;

            break;
        default:

            break;
    }

    if (sourceDevice) {
        filters.excludeDeviceUID = sourceDevice.Device_UID;
    }

    const search = getSearchString(filters);
    const absoluteUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getAccountBrandDevices}`;
    const response = await getDataAsync(`${absoluteUrl}?${search}`);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.data;
};

export const getBrandDeviceUpgradeVersions = async (brandID) => {
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.getBrandDeviceUpgradeVersions(brandID);

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};

export const getBrand = async (brandID) => {
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.getBrand(brandID);

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};

export const saveBrandDeviceUpgradeVersions = async (brandID, payload) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.saveBrandDeviceUpgradeVersions(brandID)}`;

    const response = await postDataAsync(url, payload);

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.status;
};

export const getBrandsDeviceUpgradeVersions = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getBrandAllDeviceUpgradeVersions}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};
