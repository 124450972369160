import React from 'react'
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class Error extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showCommonLoader: true
    }
  }

  render () {
    return (<section>
      {this.renderError()}
    </section>)
  }
  renderError () {
    const { t } = this.props;

    if (this.props.error) {
      return (
        <div className='col1'>
          <div className='forms clear'>
            <h3 className='error'>{t('common__error--connect-unable')}</h3>
            <div className='error redbox_err_all redbox_marg'>
              <ul>
                <li>{t('device-connect__error--bad-id')}</li>
              </ul>
            </div>
          </div>
        </div>
      )
    } else {
      return (<div />)
    }
  }
}

function mapStateToProps (state) {
  return {
  }
}
function matchDispatchToProps (dispatch) {
  return bindActionCreators(
    {
    }, dispatch
  )
}
export default compose(
  connect(mapStateToProps, matchDispatchToProps),
  withTranslation()
)(Error);
