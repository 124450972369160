export const MOBILE_SORT_OPTIONS = [
    {
        label: 'settings_snapshots__tiles__sorting__last-used__asc',
        value: { AppliedDate: -1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__last-used__desc',
        value: { AppliedDate: 1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__name__asc',
        value: { SettingName: 1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__name__desc',
        value: { SettingName: -1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__description__asc',
        value: { Description: 1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__description__desc',
        value: { Description: -1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__settings-version__asc',
        value: { SettingsVersion: 1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__settings-version__desc',
        value: { SettingsVersion: -1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__brand__asc',
        value: { SourceBrand: 1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__brand__desc',
        value: { SourceBrand: -1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__account__asc',
        value: { SourceAccount: 1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__account__desc',
        value: { SourceAccount: -1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__source-type__asc',
        value: { SourceDeviceType: 1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__source-type__desc',
        value: { SourceDeviceType: -1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__source-settings__asc',
        value: { SourceSettingsVersion: 1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__source-settings__desc',
        value: { SourceSettingsVersion: -1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__source-version__asc',
        value: { SourceSoftwareVersion: 1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__source-version__desc',
        value: { SourceSoftwareVersion: -1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__source-serial-number__asc',
        value: { SourceSN: 1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__source-serial-number__desc',
        value: { SourceSN: -1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__source-product-id__asc',
        value: { SourceDeviceProductID: 1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__source-product-id__desc',
        value: { SourceDeviceProductID: -1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__lane__asc',
        value: { SourceLaneConfig: 1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__lane__desc',
        value: { SourceLaneConfig: -1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__created-by__asc',
        value: { CreatedBy: 1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__created-by__desc',
        value: { CreatedBy: -1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__is-brand-approved__asc',
        value: { IsBrandApproved: 1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__is-brand-approved__desc',
        value: { IsBrandApproved: -1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__status__asc',
        value: { Status: 1 },
    },
    {
        label: 'settings_snapshots__tiles__sorting__status__desc',
        value: { Status: -1 },
    },
];
