import React from 'react';
import classNames from 'classnames';

import { PureButton } from 'library/PureButton';

import { useBannerCustomerNotification } from './hooks';

import { ReactComponent as CloseIcon } from 'assets/icons/IconCloseNavigation.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import './CustomerNotificationBanner.scss';

export const CustomerNotificationBanner = ({ className = '' }) => {
    const { text, handleHideBanner, isHidden } = useBannerCustomerNotification();

    if (isHidden) {
        return null;
    }

    return (
        <article className={classNames('hme-customer-notification', className)}>
            <div className="hme-customer-notification__info-wrapper">
                <InfoIcon className="hme-customer-notification__info-icon" />
            </div>

            <div className="hme-customer-notification__text" dangerouslySetInnerHTML={{ __html: text }} />

            <PureButton
                className="hme-customer-notification__close-btn"
                onClick={handleHideBanner}
            >
                <CloseIcon className="hme-customer-notification__close-icon" />
            </PureButton>
        </article>
    );
};
