import { useMemo } from 'react';

export const useExtensions = (extensions) => {
    const extensionsArray = useMemo(() => extensions.split(','), [extensions]);
    const accept = useMemo(() => extensionsArray.map(ext => `.${ext}`).join(','), [extensionsArray]);

    return {
        extensionsArray,
        accept, 
    };
};
