import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { postDataAsync } from 'Api';
import { isPartner } from './Auth';

export const isResetPassTokenValid = (token) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.checkResetPasswordLinkExpiry}`;

    return postDataAsync(url, {
        resetUID: token
    });
};

export const resetUserPassword = (payload) => {
    const url = `${Config.apiBaseUrl}${ isPartner() ? CommonConstants.apiUrls.resetPasswordPartner : CommonConstants.apiUrls.resetPassword}`;

    return postDataAsync(url, {
        resetUID: payload.token,
        newPassword: payload.password,
        confirmPassword: payload.confirm,
        language: payload.language
    });
};
