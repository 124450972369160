import React from 'react';
import { ReportTemplateProvider } from './ReportLandingPage/context/ReportTemplateContext';
import { Outlet } from 'react-router-dom';

// Wrapper component to share context between report components(pages)

export const ReportContextWrapper = () => {
    return <ReportTemplateProvider>
        <Outlet />
    </ReportTemplateProvider>;
};
