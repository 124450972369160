const swKey = 'sw-version';
const swVersion = localStorage.getItem(swKey);
const expectedSWVersion = '3';

async function unregister () {
    if ('serviceWorker' in navigator) {
        const registrations = await navigator.serviceWorker.getRegistrations();

        return Promise.all(registrations.map((registration) => registration.unregister()));
    }
  
    return Promise.resolve([]);
}

const registerServiceWorker = async () => {
    await unregister();
    localStorage.setItem(swKey, expectedSWVersion);
};

export const updateSW = async () => {
    if (swVersion !== expectedSWVersion) {
        registerServiceWorker();
    }
}
