import React from 'react';
import { Link } from 'react-router-dom';

import { Title } from 'library/Title';
import { Button } from 'library/Button';

import { GridFiltersCount } from 'library/GridFiltersCount';
import { DateFilter } from 'pages/ApplyDeviceSettings/DeviceSettings/Header/DateFilter'

import './Header.scss';

export const Header = ({
    filtersCount,
    isLoading,
    onFiltersReset,
    onDateRangeChange,
}) => (
    <div className='admin-apply-store-settings-taskbar'>
        <Title>ZOOM Store Settings Tasks</Title>
        <DateFilter label='Show Me' isDisabled={isLoading} onDateRangeChange={onDateRangeChange} />
        <div className='admin-apply-store-settings-taskbar-separated admin-apply-store-settings-taskbar-filters-count'>
            <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} />
        </div>
        <div className='admin-apply-store-settings-taskbar-link'>
            <Link to='/admin/applyStoreSettings'>
                <Button>Create New Task</Button>
            </Link>
        </div>
    </div>
);
