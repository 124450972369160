import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Grid, headersType } from 'components/Common/Grid';
import { TileList, mobileHeadersType } from 'library/TileList';

import './List.scss';

export const List = ({ headers, mobileHeaders, rows, isLoading }) => {
    const { t } = useTranslation();

    return (
        <>
            <Grid
                headers={headers}
                rows={rows}
                rowKey='Role_UID'
                isLoading={isLoading}
                loadingText={t('common__loading')}
                noRecordsMessage={t('common__error--no-records-found')}
            />
            <TileList
                className='hme-roles__tile-list'
                headers={mobileHeaders}
                rows={rows}
                rowKey='Role_UID'
                isLoading={isLoading}
                loadingText={t('common__loading')}
                noRecordsMessage={t('common__error--no-records-found')}
            />
        </>
    );
};

List.propTypes = {
    mobileHeaders: mobileHeadersType,
    headers: headersType,
    rows: PropTypes.arrayOf(PropTypes.any),
    isLoading: PropTypes.bool,
};
