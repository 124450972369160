import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Radio } from 'library/Radio';

import { useSortingItem } from '../../../hooks/useSortingItem';

export const SortingItem = ({
    sortSelection,
    onSortChange,
    label,
    value
}) => {
    const { t } = useTranslation();

    const { checked, onChange } = useSortingItem(sortSelection, onSortChange, value);

    return <Radio
        key={label}
        label={t(label)}
        checked={checked}
        onChange={onChange}
    />;
};

SortingItem.propTypes = {
    sortSelection: PropTypes.shape({}),
    onSortChange: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.object
};
