import React from 'react';

import { ViewEdit } from '../Common';
import { useDistributorEdit } from './hooks/useDistributorEdit';

export const DistributorEdit = () => {
    const state = useDistributorEdit();

    return (
        <ViewEdit {...state} />
    );
};
