/**
 * Should format the registration key to format XXX-XXX-XXXX
 * @param {string} registrationKey
 * @returns {string}
 */ 
export const formatRegistrationKey = (registrationKey) => {
    if (!registrationKey || typeof registrationKey !== 'string') {
        return '-';
    }

    // valid registration key should have 10 characters
    if (registrationKey.length === 10) {
        return registrationKey.substring(0, 3)
        + '-' + registrationKey.substring(3, 6)
        + '-' + registrationKey.substring(6);
    }

    return registrationKey;
};
