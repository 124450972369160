/* eslint-disable complexity */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { T, compose, cond } from 'ramda';
import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';
import { withHMELayout } from 'HOCs/withHMELayout';
import { CenterLoader } from 'components/Common/CenterLoader';
import { addSuccessNotification } from 'services/Notifications';
import { NotificationsList } from 'library/NotificationsList';

import { CopyTokenModal } from '../Common/CopyTokenModal';
import { EditTokenForm } from './EditApiManagementForm';
import { Footer } from './Footer';
import { DetailsSection } from './DetailsSection';
import { GeneralSection } from './GeneralSection';
import { EmailSection } from './EmailSection';
import { AccountsSection } from './DXS/AccountsSection';
import {
    formDataValidator,
    createToken,
    tokenManagementBaseUrl,
    EMAIL
} from './helpers';
import {
    FORM_DEFAULTS,
    HME_API_TYPES_ENUM,
    HME_API_ID_MAP,
    HME_API_TYPES,
    REQUIRED_INPUTS,
    TOKEN_INITIAL,
    validHmeApiIds
} from './helpers/constants';

import './APIManagementForm.scss';
import { fetchHMEApis } from 'services/TokenManagement';

const APIManagementFormPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [formErrors, setFormErrors] = useState({});
    const [formValues, setFormValues] = useState(FORM_DEFAULTS[HME_API_TYPES_ENUM.VAIO]);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [token, setGeneratedToken] = useState(TOKEN_INITIAL);
    const [isCopyTokenModalShown, setIsCopyTokenModalShown] = useState(false);

    const HME_APIS = useRef([]);

    // true condition == error
    const formValidations = {
        tokenName: !formValues.tokenName.trim(),
        tokenManager: !EMAIL.test(formValues.tokenManager.email.trim()),
        email2: formValues.email2.trim() && !EMAIL.test(formValues.email2.trim()),
        email3: formValues.email3.trim() && !EMAIL.test(formValues.email3.trim())
    };

    const onChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const onProviderChange = useCallback((provider) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            vaioProvider: provider
        }));
    }, []);

    const onAccountSelection = useCallback((account) => {
        setFormValues(({ cloudAccounts = [], ...prevFormValues }) => ({
            ...prevFormValues,
            cloudAccounts: [...cloudAccounts, account]
        }));
    });

    const onAccountRemoval = useCallback((userID) => {
        setFormValues(({ cloudAccounts = [], ...prevFormValues }) => ({
            ...prevFormValues,
            cloudAccounts: cloudAccounts.filter(({ User_ID: accountID } = {}) => accountID !== userID)
        }));
    });

    useEffect(() => {
        let enableSave = true;

        for (const input of REQUIRED_INPUTS[formValues.apiType]) {
            if (!formValues[input] || (Array.isArray(formValues[input]) && !formValues[input].length) || !formValues.tokenManager.id) {
                enableSave = false;
                break;
            }
        }

        // check if updated formValue fixes error
        if (formValues.tokenName) {
            const formErrorsCopy = { ...formErrors };
            for (const error in formErrorsCopy) {
                if (!formValidations[error]) {
                    delete formErrorsCopy[error];
                }
            }
            const errorLength = Object.keys(formErrorsCopy).length;
            if (errorLength > 0) enableSave = false;
            if (errorLength !== Object.keys(formErrors).length) setFormErrors(formErrorsCopy);
        }

        setSaveEnabled(enableSave);
    }, [formValues, formErrors]);

    // Fetch HME Apis
    useEffect(() => {
        (async () => {
            const response = await fetchHMEApis();
            const { data: cloudAPIs = [] } = response || {};
            const apiIds = cloudAPIs.map(({ Category_ID: categoryID } = {}) => Number(categoryID)).filter(Boolean);

            HME_APIS.current =
        apiIds.filter((id) => validHmeApiIds.includes(id))
                .map((id) => ({ value: HME_API_ID_MAP[id], text: HME_API_TYPES[HME_API_ID_MAP[id]]?.genericLabel }));

            setIsLoading(false);
        })();
    }, []);

    const onTokenExpChange = (newTokenValue) => {
        setFormValues({ ...formValues, tokenExpiration: newTokenValue });
    };

    const onTokenCreate = useCallback(async () => {
        const errors = formDataValidator(formValidations, t);
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setSaveEnabled(false);
            return;
        }
        // Go through values in formValues and trim beginning and ending whitespace
        const trimmedFormValues = {};
        for (const key of Object.keys(formValues)) {
            trimmedFormValues[key] = typeof formValues[key] === 'string' ? formValues[key].trim() : formValues[key];
        }
        const response = await createToken(trimmedFormValues, navigate, setIsLoading, null, t);
        if (response) {
            const { token: newlyGeneratedToken } = response || {};
            setGeneratedToken(newlyGeneratedToken);
            setIsCopyTokenModalShown(true);
            setIsLoading(false);
            addSuccessNotification(
                    'api-token-management__form--token-create-success', { autoClose: 5000 }
            );
        }
    }, [formValues, navigate, t]);

    const handleCopyPopupClose = useCallback(() => {
        setIsCopyTokenModalShown(false);
        // navigate(ADMIN_ROUTES.tokenManagement);
        // eslint-disable-next-line no-warning-comments
        // TODO: ADMIN_ROUTES is coming in as a string instead of an object, fix it
        navigate(tokenManagementBaseUrl);
    }, []);

    const onCancel = () => {
        navigate(tokenManagementBaseUrl);
    };

    return (
        <div className="hme-page-component api-management-form-page">
            {isLoading ? (
                <CenterLoader>{t('common__loading')}</CenterLoader>
            ) : (
                <>
                    <div className="api-management-form-page-header">
                        <Title>{t('api-token-management__form-create__page-title')}</Title>
                    </div>
                    <div className="api-management-form-page-sections">
                        <GeneralSection
                            formValues={formValues}
                            onChange={onChange}
                            formErrors={formErrors}
                            onTokenExpChange={onTokenExpChange}
                            setFormValues={setFormValues}
                            HME_APIS={HME_APIS.current}
                        />
                        <EmailSection
                            formValues={formValues}
                            onChange={onChange}
                            formErrors={formErrors}
                            onTokenExpChange={onTokenExpChange}
                            setFormValues={setFormValues}
                        />
                        {formValues.apiType !== HME_API_TYPES_ENUM.GigDelivery && formValues.tokenManager.uid && (formValues.apiType === HME_API_TYPES_ENUM.VAIO ?
                            <DetailsSection
                                formValues={formValues}
                                onProviderChange={onProviderChange}
                            /> :
                            <AccountsSection
                                isLoading={isLoading}
                                formValues={formValues}
                                onAccountSelection={onAccountSelection}
                                onAccountRemoval={onAccountRemoval}
                            />
                        )}
                    </div>

                    <CopyTokenModal
                        isShown={isCopyTokenModalShown}
                        tokenName={token.name}
                        tokenValue={token.generatedToken}
                        onClose={handleCopyPopupClose}
                    />
                </>
            )}

            <Footer isSubmitEnabled={saveEnabled} onCancel={onCancel} onApply={onTokenCreate} />
        </div>
    );
};

const APIManagement = cond([
    [({ isCreatePage }) => isCreatePage, () => <APIManagementFormPage />],
    [T, () => <EditTokenForm />]
]);

const APIManagementComponent = () => {
    const [searchParams] = useSearchParams();
    const tokenUID = searchParams.get('tokenUID');

    const isCreatePage = !tokenUID;

    return (
        <>
            <NotificationsList />
            <APIManagement isCreatePage={isCreatePage} />
        </>
    );
};

export const APIManagementForm = compose(
        withHMELayout()
)(APIManagementComponent);
