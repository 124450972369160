import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { uniq } from 'ramda';

import { Config } from 'Config';
import { useAsync } from 'hooks/useAsync';
import { PUBLIC_ROUTES } from 'constants/routes';
import { fetchContest } from 'services/LeaderboardContests';
import { addErrorNotification } from 'services/Notifications';
import { DateLib } from '@hme-cloud/utility-common';

import { defaultContest } from '../../../Common/Constants';
import { prepareScheduledTimePeriod } from '../../../Common/utils';

const getScheduledTimePeriod = (contest) => {
    const startDate = new DateLib(contest.StartDate ?? '');
    const endDate = new DateLib(contest.EndDate ?? '');

    const from = startDate
        .formatInTimeZone(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD, contest.ContestTimeZone.ZoneName);
    const to = endDate.addSeconds(1)
        .formatInTimeZone(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD, contest.ContestTimeZone.ZoneName);

    const preparedPeriods = prepareScheduledTimePeriod({ from, to });

    return { from, to, periods: preparedPeriods };
};

export const useLoadContest = () => {
    const { t } = useTranslation();
    const { uid: contestUid } = useParams();
    const navigate = useNavigate();

    const {
        run,
        isLoading,
        data: contest
    } = useAsync();

    const loadContest = useCallback(() => {
        run(fetchContest(contestUid).then((loadedContest) => {
            const { ZoneName } = loadedContest.ContestTimeZone;

            return ({
                name: loadedContest.ContestName,
                description: loadedContest.ContestDesc,
                metric: loadedContest.Metric,
                metricDetails: loadedContest.MetricDetail,
                status: loadedContest.ContestStatus,
                reward: loadedContest.RewardDesc,
                rewardImg: loadedContest.RewardImage ? `${Config.blobNitroStorageBaseUrl}/${loadedContest.RewardImage}` : '',
                scheduledTimePeriod: getScheduledTimePeriod(loadedContest),
                startDate: loadedContest.StartDate ? new DateLib(loadedContest.StartDate) : '',
                endDate: loadedContest.EndDate ? new DateLib(loadedContest.EndDate) : '',
                recurrenceDays: uniq(loadedContest.ContestScheduleDays.map(({ ScheduleDay }) => ScheduleDay)),
                contestStores: loadedContest.ContestStores || [],
                contestStoresSearchQuery: '',
                contestUID: loadedContest.ContestUID,
                timeZone: {
                    text: '',
                    value: ZoneName
                }
            });
        })
        .catch(() => {
            navigate(PUBLIC_ROUTES.contestsList);

            setTimeout(() => {
                addErrorNotification(t('contest__not-found'), { autoClose: 5000 });
            }, 300);
        }));
    }, [contestUid, navigate, t]);

    useEffect(() => {
        loadContest();
    }, [loadContest]);

    return { isLoading, contest: contest ?? defaultContest };
};
