
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';
import { SearchOptionable } from 'library/SearchOptionable';
import { Button } from 'library/Button';
import { searchOptions } from '../constants';
import { useSearchWithDebounce } from 'hooks/useSearchWithDebounce';

import './Header.scss';

export const defaultSearchValue = {
    selectValue: 'Store_Number',
    searchValue: ''
};

export const Header = ({
    isStoresAvailable = false,
    onSearch,
   onModalOpen
}) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState(defaultSearchValue);
    const { onSearchChangeHandler, onSearchHandler } = useSearchWithDebounce(onSearch, setSearchValue);

    return (
        <div className="hme-stores-status--partner__header">
            <Title>{t('common__stores')}</Title>
            <SearchOptionable
                value={searchValue}
                onChange={onSearchChangeHandler}
                onSearch={onSearchHandler}
                searchSelectOptions={searchOptions}
            />
            {
                isStoresAvailable && (
                    <Button className="hme-stores-status--partner__header__sort-btn" onClick={onModalOpen}>
                        {t('common__sort')}
                    </Button>
                )
            }
        </div>
    );
};
