import { DateLib } from '@hme-cloud/utility-common';

export const prepareScheduledTimePeriod = ({ from, to }) => {
    const periods = [];

    let startHour = parseInt(from);
    const endHour = parseInt(to);

    while (startHour !== (endHour || 23)) {
        const time = new DateLib(DateLib.parse(startHour.toString(), DateLib.FORMAT_TYPES.HOURS), { locale: 'en-US' });

        const start = time.format(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD);
        const end = time.addHours(1).format(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD);

        periods.push(`${start} - ${end}`);

        startHour++;
    }

    if (endHour === 0) {
        periods.push('23:00 pm - 00:00 am');
    }

    return periods;
};
