/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { flatten, pluck, uniq } from 'ramda';
import { useTranslation } from 'react-i18next';

import { Grid } from 'components/Common/Grid';
import { Config } from 'Config';
import sortBySelection from 'components/DeviceUpgrades/helpers/sortBySelection';
import { LandingSubHeader } from 'containers/CustomSmackTalk/ManageMessages/MessagesLanding/LandingSubHeader';
import { DateLib } from '@hme-cloud/utility-common';

import { Receivers } from './Receivers';
import { gridFiltersCounter } from '../../../../helpers/filters';
import { HistoryTiles } from './HistoryTiles/HistoryTiles';
import { categoryKeys, messageKeys } from '../../constants';

import './HistoryListPage.scss';

const defaultCategoryIcon = 'icons/smacktalk-category-congratulate.png';

const changedSortedProperties = {
    'dateTime': 'dateTimeTimestamp',
    'category': 'categoryName',
    'to': 'csReceivers'
};

const gridHeaders = [{
    text: 'common__category',
    property: 'category',
    flex: '2',
    sortable: true
}, {
    text: 'common__from',
    property: 'from',
    flex: '2',
    sortable: true
}, {
    text: 'common__to',
    property: 'to',
    flex: '3',
    className: 'ellipsis-cell',
    sortable: true
}, {
    text: 'common__message',
    property: 'message',
    flex: '3',
    sortable: true
}, {
    text: 'smack-talk__tab__history-list--date-time',
    property: 'dateTime',
    flex: '1',
    sortable: true,
    sortDirections: [0, -1, 1]
}];

const defaultSortSelection = { dateTime: -1 };
export const HistoryListPage = ({ data, loader }) => {
    const [messages, setMessages] = useState([]);
    const [messagesPopulated, setMessagesPopulated] = useState([]);
    const [messagesFiltered, setMessagesFiltered] = useState([]);
    const [messagesTranslated, setMessagesTranslated] = useState([]);
    const [messagesSorted, setMessagesSorted] = useState([]);
    const [gridFilters, setGridFilters] = useState({
        Category: []
    });
    const [availableFilters, setAvailableFilters] = useState([]);
    const [sortSelection, setSortSelection] = useState(defaultSortSelection);
    const scrollableRef = useRef(null);

    const [initialFilters, setinitialFilters] = useState([]);
    const [filtersCounter, setFiltersCounter] = useState(0);
    const { t } = useTranslation();

    const filterLables = {
        category: {
            title: t('common__categories'),
            allText: t('common__all-categories')
        },
        from: {
            title: t('common__senders'),
            allText: t('common__all-senders')
        },
        to: {
            title: t('common__recipients'),
            allText: t('common__all-recipients')
        }
    };

    const setFilters = (newMessages) => {
        const categories = pluck('categoryName', newMessages);
        const uniqCategories = uniq(categories).sort();
        const from = pluck('from', newMessages);
        const uniqFrom = uniq(from).sort();
        const to = pluck('to', newMessages);
        const uniqTo = uniq(flatten(to)).sort();
        const initialFilters = {
            category: uniqCategories,
            from: uniqFrom,
            to: uniqTo
        };
        setGridFilters(initialFilters);
        setinitialFilters(initialFilters);
        setAvailableFilters([
            {
                property: 'category',
                allText: t('common__all'),
                items: uniqCategories.map((category) => ({
                    value: category,
                    text: t(categoryKeys[category])
                }))
            },
            {
                property: 'from',
                allText: t('common__all'),
                items: uniqFrom.map((from) => ({
                    value: from,
                    text: from
                }))
            },
            {
                property: 'to',
                allText: t('common__all'),
                items: uniqTo.map((to) => ({
                    value: to,
                    text: to
                }))
            }
        ]);
    };
    const prepareMessages = async (data) => {
        try {
            const newMessages = data.map((el) => {
                const message = {
                    categoryName: `smacktalk__category_${el['CategoryName']}`,
                    categoryIcon: el['CategoryIcon'],
                    from: el['CreatedBy'],
                    to: el.stores.map(({ Store: { StoreNumber: storeNumber } }) => storeNumber),
                    message: el['MessageText'],
                    type: el['CreatedBy'],
                    dateTime: new DateLib(el['CreatedDate']).format(DateLib.FORMAT_TYPES.DATE_AND_TIME),
                    dateTimeTiled: new DateLib(el['CreatedDate']).format(DateLib.FORMAT_TYPES.DATE_AND_TIME),
                    dateTimeTimestamp: Number(new DateLib(el['CreatedDate']).getDate().getTime()) || null,
                    messageId: Number(el['MessageID'])
                };
                return message;
            });
            setMessages(newMessages);
            setFilters(newMessages);
        } catch (e) {
            console.error(e);
        }
    };

    const getCategory = (row, icon) => {
        return (
            <>
                <div className="hme-history-category">
                    <img src={`${Config.blobNitroStorageBaseUrl}/${icon}`} alt={row['categoryName']} />
                    &nbsp;&nbsp;
                    <div className="smacktalk-history-categories-title">{t(categoryKeys[row['categoryName']])}</div>
                </div>
            </>
        );
    };

    const populateCategories = (data = []) => {
        const newData = data.map((el, i) => {
            const row = { ...el };
            const icon = row['categoryIcon'] || defaultCategoryIcon;
            row['category'] = getCategory(row, icon);
            return row;
        });
        return newData;
    };

    useEffect(()=>{
        setMessagesSorted(sortBySelection(messagesTranslated, sortSelection, changedSortedProperties));
    }, [sortSelection, messagesTranslated]);

    useEffect(() => {
        const translated = messagesFiltered.map((el) => {
            const message = {
                category: el.category,
                categoryName: t(categoryKeys[el.categoryName]),
                message: t(messageKeys[el.message]) || el.message,
                from: el.from,
                to: <Receivers
                    el={el}
                    scrollableRef={scrollableRef}
                />,
                csReceivers: el.to && el.to.toString() || '',
                status: el.status,
                messageId: el.messageId,
                dateTime: el.dateTime,
                dateTimeTiled: el.dateTimeTiled,
                dateTimeTimestamp: el.dateTimeTimestamp
            };
            return message;
        });
        setMessagesTranslated(translated);
    }, [messagesFiltered]);
    useEffect(() => {
        const filtered = messagesPopulated.filter(
                ({ categoryName, from, to }) => {
                    const isFiltered =
                        gridFilters.category.includes(categoryName)
                        && gridFilters.from.includes(from)
                        && gridFilters.to.some((filter) => to.some((item) => item === filter));
                    return isFiltered;
                }
        );
        setMessagesFiltered(filtered);
    }, [gridFilters, messagesPopulated]);

    useEffect(() => {
        const populated = populateCategories(messages);
        setMessagesPopulated(populated);
    }, [messages]);

    useEffect(() => {
        prepareMessages(data);
    }, [data, t]);

    useEffect(() => {
        setFiltersCounter(gridFiltersCounter(availableFilters, initialFilters, gridFilters));
    }, [availableFilters, gridFilters]);

    const resetFilters = ()=>{
        setGridFilters(initialFilters);
    };

    const resetSorts = () => {
        setSortSelection(defaultSortSelection);
    };

    return (
        <div className={classNames('custom-smacktalk-page-history')}>
            <div className={classNames('hme-history-container')} ref={scrollableRef}>
                <LandingSubHeader filtersCounter={filtersCounter} resetFilters={resetFilters} />
                <Grid
                    rows={messagesSorted}
                    headers={gridHeaders}
                    rowKey="dateTimeTimestamp"
                    isLoading={loader}
                    filters={gridFilters}
                    availableFilters={availableFilters}
                    onFiltersChange={setGridFilters}
                    onSortChange={setSortSelection}
                    sortSelection={sortSelection}
                    loadingText={t('common__loading')}
                    noRecordsMessage={t('common__error--no-data-found')}
                />
            </div>
            <HistoryTiles
                tiles={messagesSorted}
                isLoading={loader}
                filters={gridFilters}
                filtersCounter={filtersCounter}
                availableFilters={availableFilters}
                onFiltersChange={setGridFilters}
                resetFilters={resetFilters}
                resetSorts={resetSorts}
                filterLables={filterLables}
                sortSelection={sortSelection}
                onSortChange={setSortSelection}
            />
        </div>
    );
};
