import React from 'react';
import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';
import { TileList } from 'library/TileList';
import { Grid } from 'components/Common/Grid';

import { headers, MOBILE_HEADERS } from './headers';

export const DevicesInformation = ({ rows }) => {
    const { t } = useTranslation();

    return <div className="hme-view-edit-store__devices-info">
        <Title variants={['block']}>{t('common__devices')}</Title>
        <Grid
            headers={headers}
            rows={rows}
            className="hme-view-edit-store__devices-grid hme-l-size"
            rowKey="Device_UID"
            noRecordsMessage={t('stores__view--edit__devices__no-records')}
        />

        <TileList
            headers={MOBILE_HEADERS}
            rowKey="Device_UID"
            rows={rows}
            className="hme-view-edit-store__devices-tile hme-s-size"
            listItemComponentProps={{
                className: 'hme-view-edit-store__devices-tile-item'
            }}
        />
    </div>;
};
