import React from 'react';
import { Constant } from '@hme-cloud/utility-common';
import { Button } from 'library/Button';
import { Tip } from 'library/Tip';
import getDeviceType from 'helpers/Device/getDeviceType';
import { Config } from 'Config';
import { DeviceDropdown } from '../../DeviceDropdown';
import { checkIsUpgradeAvailable, checkScheduledUpgrade } from '../../../../Common/helpers';
import { ModelName } from '../../../../Common/ModelName';
import { Actions } from '../Actions';
import { DeviceUpgradeStatus } from '../../DeviceUpgradeStatus';

const { BuildVersions } = Constant;

export const prepareLinkedDevices = ({ device, onLinkedDeviceRebootTrigger, onLinkedDeviceReconnectTrigger }) => {
    const linkedDevices = (device.LinkedDevices || []).map((linkedDevice) => {
        return {
            modelName: <ModelName device={linkedDevice} modelName={getDeviceType(linkedDevice)?.hardwareName} />,
            serialNumber: linkedDevice.Device_SerialNumber,
            macAddress: linkedDevice.Device_MacAddress,
            mainVersion: linkedDevice.Device_MainVersion,
            latestFirmwareAvailable: '',
            onLatestVersion: '',
            actionsComponent: (
                <Actions
                    device={device}
                    linkedDevice={linkedDevice}
                    onDeviceReboot={onLinkedDeviceRebootTrigger}
                    onDeviceReconnect={onLinkedDeviceReconnectTrigger}
                />
            ),
        };
    });

    return linkedDevices;
};

export const prepareDevices = ({
    device,
    deviceSettings,
    deviceRecentUpgradeTasks,
    availableDeviceUpgradeVersions,
    onDeviceVersionUpgrade,
    deviceScheduledUpgrade,
    onLinkedDeviceRebootTrigger,
    onLinkedDeviceReconnectTrigger,
    t,
}) => {
    const deviceType = getDeviceType(device);
    const { iotDeviceID, upgradeInProgress } = deviceSettings;

    const upgradeVersionOptions = availableDeviceUpgradeVersions.map((version) => ({
        name: `ZOOM ${version.ModelName} v${version.Version}`,
        modelName: version.ModelName,
        version: version.Version,
        deviceUID: device.Device_UID,
        deviceType: device.DeviceType_Name,
    }));

    let latestAvailableDeviceUpgradeVersions = '';
    if (availableDeviceUpgradeVersions.length > 0) {
        latestAvailableDeviceUpgradeVersions =
            availableDeviceUpgradeVersions[availableDeviceUpgradeVersions.length - 1];
    }

    const isOnLatestVersion =
        !latestAvailableDeviceUpgradeVersions.Version ||
        (device.mainVersion &&
            BuildVersions.semverGte(
                BuildVersions.formatVersion(device.mainVersion) || '0.0.0',
                latestAvailableDeviceUpgradeVersions.Version,
            ));

    // If IoT then check if upgrade is completed, else wait 30 minutes before allowing upgrade again
    const isUpgradeAvailable = iotDeviceID ? !upgradeInProgress : checkIsUpgradeAvailable(deviceSettings);
    const isScheduledUpgrade = checkScheduledUpgrade({ deviceScheduledUpgrade });

    const isDisabled =
        !device.Device_IsActive ||
        device.Device_IsPreconfigured ||
        (deviceSettings.iotDeviceID && deviceSettings.upgradeInProgress) ||
        isScheduledUpgrade ||
        isOnLatestVersion ||
        !isUpgradeAvailable;

    // ZOOM devices with software version below 3.6.0 are not compatible with upgrade versions above 3.6.0.
    // Such devices must be first upgrade to 3.6.0 before upgrading them to latest version.
    const isBackwardCompatible = BuildVersions.semverGte(
        BuildVersions.formatVersion(device.Device_MainVersion) || '0.0.0',
        Config.minimumBulkupgradableVersion,
    );

    const actionsComponent = isBackwardCompatible ? (
        <DeviceDropdown
            onChange={onDeviceVersionUpgrade}
            placeholder="admin-system-status__action__send-upgrade"
            options={upgradeVersionOptions}
            isDisabled={isDisabled}
        />
    ) : (
        <Button
            variants={['transparent']}
            disabled={isDisabled}
            onClick={() => {
                onDeviceVersionUpgrade({
                    deviceUID: device.Device_UID,
                    modelName: '',
                    version: Config.minimumBulkupgradableVersion,
                    deviceType: device.DeviceType_Name,
                });
            }}
        >
            {t('admin-system-status__action__send-upgrade')}
        </Button>
    );

    const { currentUpgradeTask, lastUpgradeTask } = deviceRecentUpgradeTasks || {};

    const preparedDevice = {
        modelName: <ModelName device={device} modelName={deviceType.hardwareName} />,
        serialNumber: (
            <span className="hme-grid-serial-number-cell-content">
                {device.Device_SerialNumber}
                <Tip
                    className="hme-admin-system-status-tooltip__tip"
                    withCloseButton
                    title={t('system-status__mac-address').toUpperCase()}
                >
                    {device.Device_MacAddress}
                </Tip>
            </span>
        ),
        mainVersion: device.Device_MainVersion,
        latestFirmwareAvailable: latestAvailableDeviceUpgradeVersions.Version,
        onLatestVersion: isOnLatestVersion ? t('common__yes') : t('common__no'),
        lastUpgradeData: lastUpgradeTask ? (
            <DeviceUpgradeStatus
                title="admin-system-status__upgrade-title--latest"
                task={lastUpgradeTask}
                serialNumber={device.Device_SerialNumber}
            />
        ) : null,
        currentUpgradeData: currentUpgradeTask ? (
            <DeviceUpgradeStatus
                title="admin-system-status__upgrade-title--latest"
                task={currentUpgradeTask}
                serialNumber={device.Device_SerialNumber}
            />
        ) : null,
        actionsComponent,
    };

    return [
        preparedDevice,
        ...prepareLinkedDevices({ device, onLinkedDeviceRebootTrigger, onLinkedDeviceReconnectTrigger }),
    ];
};
