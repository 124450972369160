import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Header.css';

const Header = ({ className, type, children, secondary, uppercase, centered, bold, faded }) => {
  const classes = {
    'hme-header': true,
    'hme-header--secondary': secondary,
    'hme-header--uppercase': uppercase,
    'hme-header--centered': centered,
    'hme-header--bold': bold,
    'hme-header--faded': faded,
  };

  switch(type) {
    case 'huge':
      return <h1 className={classNames(className, classes, 'hme-header--huge')}>{children}</h1>
    case 'large':
      return <h2 className={classNames(className, classes, 'hme-header--large')}>{children}</h2>
    case 'big':
      return <h3 className={classNames(className, classes, 'hme-header--big')}>{children}</h3>
    case 'normal':
      return <h4 className={classNames(className, classes, 'hme-header--normal')}>{children}</h4>
    case 'small':
      return <h5 className={classNames(className, classes, 'hme-header--small')}>{children}</h5>
    case 'tiny':
      return <h6 className={classNames(className, classes, 'hme-header--tiny')}>{children}</h6>
  }
};

Header.propTypes = {
  type: PropTypes.string,
  secondary: PropTypes.bool,
  uppercase: PropTypes.bool,
  centered: PropTypes.bool,
  bold: PropTypes.bool,
  faded: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Header.defaultProps = {
  type: 'big',
};

export { Header };
