import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from 'library/Section';
import { Toggle } from 'library/Toggle';
import { DeviceForm } from './DeviceForm';

export const deviceTypes = {
    ZOOM_NITRO: 'zoomNitro',
    NEXEO: 'nexeo',
};

export const SourceSection = ({
    params,
    sourceType,
    devices,
    snapshots,
    isSnapshotsLoading,
    onSourceTypeChange,
    onSourceChange,
}) => {
    const { t } = useTranslation();

    return (
        <Section
            className='apply-settings-public-left-section'
            number={1}
            title={t('common__select-source-device')}
        >
            <div className="form-group">
                <Toggle
                    className='source-type-toggle'
                    value={sourceType}
                    label={t('common__device-type')}
                    onChange={onSourceTypeChange}
                >
                    {[
                        {
                            text: t('common__device__type--zoom-nitro'),
                            value: deviceTypes.ZOOM_NITRO,
                        },
                        {
                            text: t('common__device__type--nexeo'),
                            value: deviceTypes.NEXEO,
                        },
                    ]}
                </Toggle>
                <DeviceForm
                    deviceType={sourceType}
                    params={params}
                    onParamsChange={onSourceChange}
                    devices={devices}
                    snapshots={snapshots}
                    isSnapshotsLoading={isSnapshotsLoading}
                />
            </div>
        </Section>
    );  
};
