import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Title } from 'library/Title';
import { InputComponent } from 'components/Inputs';
import { ErrorLabel } from 'library/ErrorLabel';
import { ReactComponent as CrossIcon } from 'assets/icons/close-cross.svg';

import './AccountNumbers.scss';

export const AccountNumbers = ({
    AXCustomerNumber,
    setAXCustomerNumber,
    validationErrors
}) => {
    const { t } = useTranslation();
    return <div className="hme-admin-store-details__account-numbers">
        <InputComponent
            value={AXCustomerNumber}
            label="common__customer-number"
            variants={['label-inside']}
            onChange={setAXCustomerNumber}
            isRequired
            maxLength={10}
        />
        {validationErrors.invalidAXCustomerNumber &&
            <ErrorLabel>
                <CrossIcon />
                {t('stores__error--invalid-ax-account-number')}
            </ErrorLabel>}
    </div>;
};

AccountNumbers.propTypes = {
    AXCustomerNumber: PropTypes.string.isRequired,
    setAXCustomerNumber: PropTypes.func.isRequired,
    validationErrors: PropTypes.shape({
        invalidAXCustomerNumber: PropTypes.string
    }) || {}
};
