import { useState, useEffect, useCallback } from 'react';
import { getCloudAccounts } from 'services/DataFeed';

export const useLoadCloudAccounts = () => {
    const [cloudAccounts, setCloudAccounts] = useState([]);
    const [isCloudAccountsLoading, setIsCloudAccountsLoading] = useState(false);
    const [cloudAccountsLoadingError, setCloudAccountsLoadingError] = useState(null);

    const loadCloudAccounts = useCallback(async () => {
        try {
            setIsCloudAccountsLoading(true);
            setCloudAccountsLoadingError(null);
            const accountList = (await getCloudAccounts()) || [];

            setCloudAccounts(accountList);
        } catch (e) {
            setCloudAccounts([]);
            setCloudAccountsLoadingError(e);
        }

        setIsCloudAccountsLoading(false);
    }, []);

    useEffect(() => { loadCloudAccounts(); }, []);

    return { cloudAccounts, isCloudAccountsLoading, cloudAccountsLoadingError, loadCloudAccounts };
};
