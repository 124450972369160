import React from 'react';

import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';

import './ErrorMessage.scss';

export const ErrorMessage = ({ message }) => (
    <div className='hme-error-message'>
        <ErrorIcon className='hme-error-icon' />
        <span className='hme-error-message-info'>{message}</span>
    </div>
);