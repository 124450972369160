import React from 'react';

export const DeviceProductIDs = ({ devices }) => (
    <>
        {
            devices.map((device) => (
                <div className='hme-stores-status--public__grid__inner-cell' key={device.Device_UID}>
                    {device.Device_Product_ID || ' '}
                </div>
            ))
        }
    </>
);