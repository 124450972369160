import React, { useState } from 'react';
import { t } from 'i18next';
import { cond, T } from 'ramda';

import { CenterLoader } from 'components/Common/CenterLoader';
import { CenterErrorMessage, CenterSuccessMessage } from 'components/Common/CenterMessages';

export const PAGE_STATE = {
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    INFO: 'INFO',
};

const isState = state => ({ pageState }) => state === pageState;

const isLoadingState = isState(PAGE_STATE.LOADING);
const isSuccessState = isState(PAGE_STATE.SUCCESS);
const isErrorState = isState(PAGE_STATE.ERROR);
const isInfoState = isState(PAGE_STATE.INFO);

const ProcessPage = cond([
    [isLoadingState, ({ loaderText }) => (
        <CenterLoader>{t(loaderText)}</CenterLoader>
    )],
    [isSuccessState, ({ successText }) => (
        <CenterSuccessMessage>{t(successText)}</CenterSuccessMessage>
    )],
    [isErrorState, ({ errorText }) => (
        <CenterErrorMessage>{t(errorText)}</CenterErrorMessage>
    )],
    [isInfoState, ({ children }) => (
        <>{children}</>
    )],
    [T, () => null]
]);

export const withPageState = options => Component => props => {
    const [pageState, setPageState] = useState(PAGE_STATE.INFO);

    return (
        <ProcessPage {...options} pageState={pageState}>
            <Component {...props} setPageState={setPageState} />
        </ProcessPage>
    );
};
