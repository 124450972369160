import React, { useContext } from 'react';

import { Title } from 'library/Title';
import { Button } from 'library/Button';
import { InputComponent } from 'components/Inputs';
import { DateLib } from 'helpers/DateLib';
import { tokenTypes } from 'constants/token';

import { Section } from '../Common/Section';
import { ProviderField } from '../DetailsSection/ProviderSection/ProviderForm';

import { ReactComponent as InfoIcon } from 'assets/icons/infoIcon.svg';
import { useTranslation } from 'react-i18next';

const TokenFormContext = React.createContext();
TokenFormContext.displayName = 'TokenFormContext';

// used to set UI related elements such as title and button text as may be different for each specific token
const TokenFormProvider = ({ title, children }) => {
    return <TokenFormContext.Provider value={{ title }}>{children}</TokenFormContext.Provider>;
};

const useTokenProviderContext = () => {
    const context = useContext(TokenFormContext);

    if (!context) {
        throw new Error('`useTokenProviderContext` must be used inside TokenFormProvider')
    }

    return context;
};

const DXSTOokenForm = ({
    formValues,
    onOpenRenewTokenModal,
    isRenewTokenDisabled
}) => {
    const {
        tokenName,
        expiryDate,
        tokenManager,
        email2,
        email3
    } = formValues;

    return <TokenFormProvider
        title="api-token-management__form-edit__page-title--DXS"
    >
        <TokenForm
            formValues={formValues}
            onOpenRenewTokenModal={onOpenRenewTokenModal}
            isRenewTokenDisabled={isRenewTokenDisabled}
        >
            <TokenFormSection.General
                expiryDate={expiryDate}
                tokenName={tokenName}
            />
            <TokenFormSection.TokenReceiver
                email2={email2}
                email3={email3}
                tokenManager={tokenManager}
            />
        </TokenForm>
    </TokenFormProvider>;
};

const GigDeliveryTokenForm = ({
    formValues,
    onOpenRenewTokenModal,
    isRenewTokenDisabled
}) => {
    const {
        tokenName,
        expiryDate,
        tokenManager,
        email2,
        email3
    } = formValues;

    return <TokenFormProvider
        title="api-token-management__form-edit__page-title"
    >
        <TokenForm
            formValues={formValues}
            onOpenRenewTokenModal={onOpenRenewTokenModal}
            isRenewTokenDisabled={isRenewTokenDisabled}
        >
            <TokenFormSection.General
                expiryDate={expiryDate}
                tokenName={tokenName}
            />
            <TokenFormSection.TokenReceiver
                email2={email2}
                email3={email3}
                tokenManager={tokenManager}
            />
        </TokenForm>
    </TokenFormProvider>;
};

const VAIOTokenForm = ({
    formValues,
    onOpenRenewTokenModal,
    isRenewTokenDisabled
}) => {
    const { t } = useTranslation();

    const {
        tokenName,
        expiryDate,
        tokenManager,
        email2,
        email3,
        vaioProvider
    } = formValues;

    return <TokenFormProvider
        title="api-token-management__form-edit__page-title"
    >
        <TokenForm
            formValues={formValues}
            onOpenRenewTokenModal={onOpenRenewTokenModal}
            isRenewTokenDisabled={isRenewTokenDisabled}
        >
            <TokenFormSection.General
                expiryDate={expiryDate}
                tokenName={tokenName}
            >
                <div className="url-tooltip">
                    <InfoIcon className="info-icon-svg" />
                    <p className="tooltip-text">{t('partner__token-management__api-name')}</p>
                </div>
            </TokenFormSection.General>
            <TokenFormSection.TokenReceiver
                email2={email2}
                email3={email3}
                tokenManager={tokenManager}
            />
            <TokenFormSection.AssociatedEntities
                vaioProvider={vaioProvider}
            />
        </TokenForm>
    </TokenFormProvider>;
};

const TokenForm = ({
    onOpenRenewTokenModal,
    isRenewTokenDisabled,
    children
}) => {
    const { title } = useTokenProviderContext();
    const { t } = useTranslation();

    return (
        <div className="hme-page-component partner-api-management-form-page">
            <div className="partner-api-management-form-page-header">
                <Title as="h1">
                    {t(title)}
                </Title>
                <Button
                    onClick={onOpenRenewTokenModal}
                    disabled={isRenewTokenDisabled}
                >
                    {t('api-token-management__renew-token')}
                </Button>
            </div>

            <div className="partner-api-management-form-page-sections">
                {children}
            </div>
        </div>
    );
};

const General = ({ expiryDate, tokenName, children }) => {
    return (
        <Section
            className="partner-api-management-form-page-general-section"
            title="common__general"
        >
            <InputComponent
                className="partner-api-management-form-page-input"
                valueClassName="partner-api-management-form-page-input-value"
                label="api-token-management__form--token-name"
                isReadOnly
                name="tokenName"
                value={tokenName}
            />

            <InputComponent
                className="partner-api-management-form-page-input"
                valueClassName="partner-api-management-form-page-input-value"
                label="api-token-management__form--token-expiration"
                isReadOnly
                name="tokenExpiration"
                value={new DateLib(expiryDate).format(DateLib.FORMAT_TYPES.DATE_SELECTOR_DEFAULT)}
            />

            {children}
        </Section>
    );
};

const TokenReceiver = ({ email2, email3, tokenManager }) => {
    return (
        <Section
            className="partner-api-management-form-page-send-to-section"
            title="api-token-management__section__title--email"
        >
            <InputComponent
                className="partner-api-management-form-page-input"
                valueClassName="partner-api-management-form-page-input-value"
                label="api-token-management__form--token-manager-email"
                isReadOnly
                name="tokenManager"
                value={tokenManager}
                universalOnChange
            />

            <InputComponent
                className="partner-api-management-form-page-input"
                valueClassName="partner-api-management-form-page-input-value"
                label="api-token-management__form--email2"
                isReadOnly
                name="email2"
                value={email2 || '-'}
                maxLength={50}
                universalOnChange
            />

            <InputComponent
                className="partner-api-management-form-page-input"
                valueClassName="partner-api-management-form-page-input-value"
                label="api-token-management__form--email3"
                isReadOnly
                name="email3"
                value={email3 || '-'}
                maxLength={50}
                universalOnChange
            />
        </Section>
    );
};

const AssociatedEntities = ({ vaioProvider }) => {
    return (
        <Section
            title="api-token-management__section__title--VAIO--detail"
            className="partner-api-management-form-page-vaio-providers-section"
        >
            <div>
                <ProviderField vaioProvider={vaioProvider} />
            </div>
        </Section>
    );
};

const TokenFormSection = {
    General,
    TokenReceiver,
    AssociatedEntities
};

export const TokenFormMap = new Map([
    [tokenTypes.DXS, DXSTOokenForm],
    [tokenTypes.VAIO, VAIOTokenForm],
    [tokenTypes.GIG_DELIVERY, GigDeliveryTokenForm],
]);
