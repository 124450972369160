import { DateLib } from '@hme-cloud/utility-common';

const dateFormat = DateLib.FORMAT_TYPES.DATE_SELECTOR_DEFAULT

export const validateKeyExpirationDate = (value) => {
    const date = DateLib.parse(value, dateFormat);

    if (!date.isValid()) {
        return 'common__validation--date';
    }

    const currentDay = new DateLib().toUTC().startOfDay();

    if (currentDay && date.isBefore(currentDay)) {
        return 'common__validation--today-date';
    }

    return '';
};
