import { checkAccess } from '../../../actions/permissions';
import { CommonConstants } from '../../../Constants';

// object with permissions for each tab
export const DeviceUpgradesSubHeadersPermissions = {
    stores: CommonConstants.adminPermissions.ViewAllStores,
    deviceUpgrades: CommonConstants.adminPermissions.PerformDeviceUpdate
};

// We need to store the tabs order
const tabsOrder = ['stores', 'deviceUpgrades'];

export const getDeviceUpgradesSubHeaders = () => {
    return tabsOrder
        .filter(tabName => checkAccess(DeviceUpgradesSubHeadersPermissions[tabName]));
};
