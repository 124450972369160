import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Select } from 'library/Select';
import { Button } from 'library/Button';
import { Label } from 'library/Label';

import './LanguageSelect.scss';

export const LanguageSelect = ({ value, languages = [], className = '', onLanguageChange, onLanguageRemove }) => {
    const { t } = useTranslation();

    return (
        <div className={classNames('hme-customer-notification-language-select', className)}>
            <Label>{t('language-select__label')}</Label>

            <div className="hme-customer-notification-language-select__content">
                <Select
                    value={value}
                    className="hme-customer-notification-language-select__selector"
                    placeholder={t('admin__customer-notification__select-lang')}
                    onChange={onLanguageChange}
                >
                    {languages}
                </Select>

                <Button
                    onClick={onLanguageRemove}
                    className="hme-customer-notification-language-select__delete-btn"
                >
                    - {t('admin__customer-notification__remove-lang')}
                </Button>
            </div>
        </div>
    );
};
