import React from 'react';
import { Navigate } from 'react-router-dom';
import { T, compose, cond } from 'ramda';

import { Loader } from 'library/Loader';
import { PUBLIC_ROUTES } from 'constants/routes';

import { CONTEST_STATUS_NAMES } from '../../Common/Constants';
import { withGamificaionMarketingLayout } from '../../Common/HOCs';
import { useContestInfo } from './hooks/useContestInfo';
import { ViewPage } from '../ViewPage';
import { EditPage } from '../EditPage';

import './ResultsPage.scss';

const ResultsCond = cond([
    [
        (({ isLoading }) => isLoading),
        () => {
            return (
                <div className="hme-contest-view-edit-form">
                    <Loader />
                </div>
            );
        }
    ],
    [
        ({ contestType }) => [CONTEST_STATUS_NAMES.COMPLETED, CONTEST_STATUS_NAMES.IN_PROGRESS].includes(contestType),
        ({ contest }) => <ViewPage contest={contest} />
    ],
    [
        (({ contestType }) => CONTEST_STATUS_NAMES.SCHEDULED === contestType),
        ({ contest }) => <EditPage contest={contest} />
    ],
    [T, () => <Navigate to={PUBLIC_ROUTES.contestsList} />]
]);

const Results = () => {
    const state = useContestInfo();

    return <ResultsCond {...state} />;
};

export const ResultsPage = compose(
    withGamificaionMarketingLayout({
        className: ['hme-edit-contest-page']
    })
)(Results);
