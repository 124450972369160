import React from 'react';
import { ViewEditAction } from '../../Common/List';
import { isAdmin } from 'services/Auth';

const isAdminUser = isAdmin();

const getUrl = (user, accOwnerUID) => `${isAdminUser ? '/admin' : ''}/settings/users/user${isAdminUser ? '?uuid=' : '/'}${user.User_UID}${accOwnerUID ? `&auuid=${accOwnerUID}` : ''}`;

export const usersToRows = ({ users, onConfirmModalOpen, hasManageUserAccess, accOwnerUID }) => users.map((user) => ({
    ...user,
    RoleText: user.IsOwner ? 'Account Owner' : user.Role_Name,
    Actions: (!user.IsOwner || isAdminUser) && <ViewEditAction
        url={getUrl(user, accOwnerUID)}
        isOwner={user.IsOwner}
        onConfirmModalOpen={onConfirmModalOpen}
        uuid={user.User_UID}
        hasManageUserAccess={hasManageUserAccess}
    /> })
);

