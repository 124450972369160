/* eslint-disable react/prop-types */
import './CustomSmackTalk.scss';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationsList } from 'library/NotificationsList';
import { Title } from 'library/Title';
import { Tabs } from '../../components/Common/Tabs/Tabs';
import { MessagesHistory } from './MessagesHistory';
import { ManageMessages } from './ManageMessages';
import { SendMessage } from './SendMessage';
import { checkAccess } from '../../actions/permissions';
import api from 'api/smackTalkApi';
import customSmackTalkApi from 'api/customSmackTalkApi';
import * as _ from 'lodash';
import { withLocalStorageContext } from '../../HOCs/withLocalStorageContext';
import { useLocation, useNavigate } from 'react-router-dom';

const SMACK_TALK_PATH  = '/leaderBoards/smackTalk';
const originViews = [
    { value: 'send', label: 'smack-talk__tab--send-message', component: SendMessage },
    { value: 'manage', label: 'smack-talk__tab--manage-messages', component: ManageMessages },
    { value: 'history', label: 'common__history', component: MessagesHistory }
];
const defaultView = 'send';

const CustomSmackTalkPage = ({ defaultTab }) => {
    const mountedRef = useRef(true);
    const [activeTab, setActiveTab] = useState(defaultView);
    const [smacktalkData, setSmacktalkData] = useState();
    const { t } = useTranslation();
    const views = useMemo(() => originViews.map((view) => ({
        ...view,
        label: t(view.label).toUpperCase()
    })), [t]);
    const navigate = useNavigate();
    const location = useLocation();

    // do not show customize smacktalk tabs for users without the CustomizeSmackTalk permission
    const allowedViews = views.filter((view) => !(view.value == 'manage' && !checkAccess('CustomizeSmackTalk')));

    const findView = (views, tab) => views.find((el) => String(tab).includes(el.value)) || {};
    const getComponent = (tab) => findView(allowedViews, tab).component;

    const changeTab = (tab) => {
        if (!defaultTab || !getComponent(tab)) {
            return setActiveTab(defaultView);
        }
        setActiveTab(findView(allowedViews, tab).value);
        if (location.pathname !== `${SMACK_TALK_PATH}/${tab}`) {
            return navigate(`${SMACK_TALK_PATH}/${tab}`);
        }
    };

    useEffect(() => {
        changeTab(defaultTab);
    }, [defaultTab, activeTab]);

    useEffect(() => {
        (async () => {
            const data = await api.getData();
            let messages = await customSmackTalkApi.getData();
            // Filter to remove excluded messages
            messages = messages.filter((message) => !message.MessageExclusionID);
            const customCategories = _.map(_.values(_.groupBy(messages, 'SmackTalkCategoryID')), (messages = []) => {
                const newEl = {
                    ..._.pick(
                            messages[0],
                            [
                                'CategoryDesc',
                                'CategoryIcon',
                                'CategoryName',
                                'SmackTalkCategoryID',
                                'Status'
                            ]
                    ),
                    messages: _.map(messages, (m) => {
                        return {
                            LeaderboardDisplayText: m.MessageText || m.MessageKey,
                            MessageDesc: m.MessageText || m.MessageKey,
                            SmackTalkCategoryID: m.SmackTalkCategoryID,
                            SmackTalkPredefinedMessageID: m.MessageID,
                            Status: !m.MessageExclusionID,
                            MessageExclusionID: m.MessageExclusionID
                        };
                    })
                };
                return newEl;
            });
            if (!mountedRef.current) return null;
            setSmacktalkData({ ...data, customCategories });
        })();
        return () => {
            mountedRef.current = false;
        };
    }, []);

    return (
        <div className="hme-page-component custom-smacktalk-page">
            <NotificationsList />
            <Title>
                <span>{t('common__smack-talk')}</span>
            </Title>
            <Tabs activeTab={activeTab} onTabChange={changeTab} tabs={allowedViews} divider="">
                {(tab) => {
                    const Component = getComponent(tab);
                    return <Component smacktalkData={smacktalkData}/>;
                }}
            </Tabs>
        </div>
    );
};

export const CustomSmackTalk = withLocalStorageContext(
        CustomSmackTalkPage,
        'customSmackTalk_configuration'
);
