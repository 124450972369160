import { cond, T } from 'ramda';
import { taskConfig } from 'constants/applyDeviceSettingsTask';
import {
    InProgressStatus,
    CompletedStatus,
    FailedStatus,
    UnknownStatus
} from '../JobsList/StatusCells';

const {
    statuses: deviceJobStatuses,
    taskInProgressStatuses,
    failedStatuses,
    completedStatuses,
} = taskConfig;

export const getTaskStatusValue = cond([[
    devices => devices.some(device => taskInProgressStatuses.includes(device.ApplySettingsStatus)),
    () => deviceJobStatuses.IN_PROGRESS
], [
    devices => devices.some(device => failedStatuses.includes(device.ApplySettingsStatus)),
    () => deviceJobStatuses.FAILED
], [
    devices => devices.some(device => completedStatuses.includes(device.ApplySettingsStatus)),
    () => deviceJobStatuses.COMPLETED
], [
    T,
    () => -1
]]);

export const getTaskStatusCell = cond([[
    devices => devices.some(device => taskInProgressStatuses.includes(device.ApplySettingsStatus)),
    (props) => <InProgressStatus {...props} />
], [
    devices => devices.some(device => failedStatuses.includes(device.ApplySettingsStatus)),
    (props) => <FailedStatus {...props} />
], [
    devices => devices.some(device => completedStatuses.includes(device.ApplySettingsStatus)),
    (props) => <CompletedStatus {...props} />
], [
    T,
    (props) => <UnknownStatus {...props} />
]]);