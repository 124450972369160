import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'library/Checkbox';
import { SourceDeviceSettingsInfo } from '../SourceDeviceSettingsInfo/SourceDeviceSettingsInfo';

import './SourceDeviceSettings.css';

const options = [{
    value: 'LANE_SETTINGS',
    text: 'apply-device-settings-status__setting-name--lane-settings',
}, {
    value: 'DAYPARTS_AND_SHIFTS',
    text: 'apply-device-settings-status__setting-name--dayparts-and-shifts',
}, {
    value: 'STORE_HOURS',
    text: 'apply-device-settings-status__setting-name--store-hours',
}, {
    value: 'DASHBOARD_SETTINGS',
    text: 'apply-device-settings-status__setting-name--dashboard-settings',
}, {
    value: 'TIME_FORMAT',
    text: 'common__time-format',
}, {
    value: 'SPEED_GOALS',
    text: 'apply-device-settings-status__setting-name--speed-goals',
}];

export const allOptions = options.map(({ value }) => value);
export const allSettingsGroup = ['ALL_SETTINGS'];

const TYPES = {
    none: 'none',
    all: 'all',
    group: 'group',
};

const getInputOnChangeHandler = (value, values, onChange) => () => {
    const newValues = values.includes(value) ?
        values.filter((v) => v !== value) :
        [...values, value];
    onChange(newValues);
};

export const SourceDeviceSettings = ({ values, onChange }) => {
    const [isInfoShown, setIsInfoShown] = useState(false);
    const [type, setType] = useState(TYPES.none);

    const { t } = useTranslation();

    useEffect(() => {
        if (values === null) {
            setType(TYPES.none);
        }
    }, [values]);

    return (
        <>
            <div className="source-device-settings-public">
                <div className="source-device-settings-title">
                    <span className="hme-label">{t('apply-device-settings-new__settings__title')}</span>
                    <span className="icon-info ms-2 subheader-info-icon"
                        onClick={() => setIsInfoShown(true) }>
                    </span>
                </div>
                <div className="source-device-settings-container btn-group">
                    <button
                        id="source-device-settings-select-all"
                        value="SELECT_ALL"
                        type="button"
                        className={`btn source-device-settings-radio shadow-none me-1 ${(type === TYPES.all) ? "active" : "inactive"}`}
                        name="select_group"
                        onClick={() => {
                            setType(TYPES.all);
                            onChange(allSettingsGroup);
                        }}
                    >{t('apply-device-settings-new__settings__toggle__all')}</button>

                    <button
                        id="source-device-settings-select-specific"
                        value="SELECT_SPECIFIC"
                        type="button"
                        className={`btn source-device-settings-radio shadow-none ${(type === TYPES.group) ? "active" : "inactive"}`}
                        name="select_group"
                        onClick={() => {
                            setType(TYPES.group);
                            onChange([]);
                        }}
                    >{t('apply-device-settings-new__settings__toggle__groups')}</button>
                </div>
                <div className="group-setting-inputs">
                    {
                        (type === TYPES.group) &&
                        (options.map(({ value, text }) => (
                            <Checkbox
                                key={value}
                                checked={!!values && values.includes(value)}
                                label={t(text)}
                                onChange={getInputOnChangeHandler(value, values, onChange)}
                            />
                        )))
                    }
                    {
                        type === TYPES.all &&
                        (
                            <div className="all-settings-disclaimer card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-2">
                                            <span className="icon-info"
                                                onClick={() => setIsInfoShown(true) }>
                                            </span>
                                        </div>
                                        <div className="col-10">
                                            <p className="card-text">{t('apply-device-settings-new__settings__all-info')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <SourceDeviceSettingsInfo open={isInfoShown} onClose={() => setIsInfoShown(false)} />
        </>
    )
};
