export const CONFIRM_UPGRADE_VERSIONS_GRID_HEADERS = [
    {
        text: 'common__device__type--nexeo-base',
        property: 'nexeo',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-centered-cell hme-grid-cell-show-all-content',
    },
    {
        text: 'common__device__type--ib7000',
        property: 'IB7000',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-centered-cell hme-grid-cell-show-all-content',
    },
    {
        text: 'common__device__type--zoom-nitro',
        property: 'zoomNitro',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-centered-cell hme-grid-cell-show-all-content',
    },
];
