import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { cond, T, compose } from 'ramda';

import authenticate from 'components/Security/Authentication';
import { withHMELayout } from 'HOCs/withHMELayout';
import { PUBLIC_ROUTES } from 'constants/routes';
import { Config } from 'Config';
import { Title as LibraryTitle } from 'library/Title';
import { NotificationsList } from 'library/NotificationsList';
import { ResetWidget, RuleMessage } from 'library/Password';
import { Button } from 'library/Button';
import { CenterLoader } from 'components/Common/CenterLoader';
import { Loader } from 'library/Loader';
import { Checkbox } from 'library/Checkbox';
import { InputComponent as Input } from 'components/Inputs';
import { useAccount } from 'hooks/account';
import { isPartner } from 'services/Auth';
import { useResetPasswordRules, isInvalidStatus, isValidStatus, publicRules, partnerRules } from 'hooks/useResetPassword';

import { useChangePassword } from './hooks';
import { ExternalLink } from './ExternalLink';

import './NewAccount.scss';

const { storageUrl } = Config;

const DEFAULT_INPUT_VARIANTS = ['label-inline'];
const BTN_VARIANTS = ['submit'];

export const NewAccountComponent = ({ fetchedAccountData }) => {
    const { t } = useTranslation();
    const isSSOAccount = fetchedAccountData.isDomainSSOEnabled;

    const isPartnerUser = useMemo(isPartner, []);
    const rules = useMemo(() => isPartnerUser ? partnerRules : publicRules, [isPartnerUser]);

    const { isLoading: changingAccountPassword, handleChangePassword } = useChangePassword();

    const [passwordFields, setPasswordFields] = useReducer(
            (state, update) => ({
                ...state,
                ...update
            }),
            {
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: '',
                agreedWithTOS: false
            }
    );

    const { currentPassword, newPassword, confirmNewPassword, agreedWithTOS } = passwordFields;

    const { isAllValid, isSomeInvalid } = useResetPasswordRules({ newPassword, confirmNewPassword });

    const handleInputFieldUpdate = useCallback(({ currentTarget }) => {
        const { name, value } = currentTarget;

        setPasswordFields({
            [name]: value
        });
    }, []);

    const handleCheckboxInputUpdate = useCallback((newValue) => {
        setPasswordFields({ agreedWithTOS: newValue });
    }, []);

    const isSubmitDisabled = !currentPassword || !agreedWithTOS || !isAllValid;

    const newPasswordInputVariants = useMemo(() => {
        const variants = [...DEFAULT_INPUT_VARIANTS];

        if (isAllValid) {
            variants.push('valid');
        }

        if (isSomeInvalid) {
            variants.push('invalid');
        }

        return variants;
    }, [isAllValid, isSomeInvalid]);

    const Title = useMemo(
            () => (
                <LibraryTitle className="hme-new-user__title">
                    {t('profile__account__change-password')}
                </LibraryTitle>
            ),
            [t]
    );

    const Rules = useMemo(
            () =>
                rules.map(({ tKey, rule }) => {
                    const status = rule(newPassword, confirmNewPassword);
                    const isValid = isValidStatus(status);
                    const isInvalid = isInvalidStatus(status);

                    return (
                        <RuleMessage
                            key={tKey}
                            message={t(tKey)}
                            isInvalid={isInvalid}
                            isValid={isValid}
                            className="hme-new-user__rule"
                        />
                    );
                }),
            [newPassword, confirmNewPassword, t]
    );

    const handleSubmit = useCallback(
            async (evt) => {
                evt.preventDefault();

                if (!isSSOAccount && (isSubmitDisabled || changingAccountPassword)) {
                    return;
                }

                try {
                    await handleChangePassword({ ...fetchedAccountData, ...passwordFields, isPartnerUser });
                } catch (_err) {
                    // Do nothing when password update was unsuccessful
                }
            },
            [isSubmitDisabled, changingAccountPassword, fetchedAccountData, passwordFields, handleChangePassword]
    );

    const FormBody = useMemo(
            () => {
                const DOCUMENT_LINKS = {
                    privacy: (
                        <ExternalLink href={t('footer__privacy-policy__url')}>
                            {t('footer__privacy-policy')}
                        </ExternalLink>
                    ),
                    agreement: (
                        <ExternalLink href={t('footer__user-agreement__url', { url: storageUrl })}>
                            {t('footer__user-agreement')}
                        </ExternalLink>
                    ),
                    dataSharingAgreement: (
                        <ExternalLink href={t('footer__partner-data-agreement__url', { url: storageUrl })}>
                            {t('footer__partner-data-agreement')}
                        </ExternalLink>
                    )
                };

                return (
                    <>
                        { !isSSOAccount && <>
                            <Input
                                value={currentPassword}
                                onChange={handleInputFieldUpdate}
                                universalOnChange
                                variants={DEFAULT_INPUT_VARIANTS}
                                label={t('my-account__account-info__current-password')}
                                name="currentPassword"
                                type="password"
                                autoComplete="current-password"
                                autoFocus
                            />
                            <Input
                                value={newPassword}
                                onChange={handleInputFieldUpdate}
                                universalOnChange
                                variants={newPasswordInputVariants}
                                label={t('my-account__account-info__new-password')}
                                name="newPassword"
                                type="password"
                                autoComplete="new-password"
                            />
                            <Input
                                value={confirmNewPassword}
                                onChange={handleInputFieldUpdate}
                                universalOnChange
                                variants={newPasswordInputVariants}
                                label={t('reset-pass__label--confirm')}
                                name="confirmNewPassword"
                                type="password"
                                autoComplete="off"
                            />
                        </>
                        }

                        <Checkbox
                            checked={agreedWithTOS}
                            onChange={handleCheckboxInputUpdate}
                            label={
                                <>
                                    <Trans
                                        i18nKey={isPartnerUser ? "my-account__account-info__partner-agree-to-hme" : "my-account__account-info__agree-to-hme"}
                                        components={DOCUMENT_LINKS}
                                    />
                                </>
                            }
                            className="hme-new-user__checkbox"
                        />
                    </>
                );
            },
            [newPasswordInputVariants, currentPassword, newPassword, confirmNewPassword, agreedWithTOS, t]
    );

    const FormFooter = useMemo(
            () => (
                <div className="hme-new-user__buttons">
                    {!isSSOAccount ? <Button
                        variants={BTN_VARIANTS}
                        disabled={isSubmitDisabled || changingAccountPassword}
                        className="hme-new-user__submit-btn"
                        type="submit"
                    >
                        {!changingAccountPassword ? (
                        t('profile__account__change-password')
                    ) : (
                        <span className="hme-new-user__loader-wrapper">
                            <Loader className="hme-new-user__btn-loader" />
                        </span>
                    )}
                    </Button> :
                    <Button
                        variants={BTN_VARIANTS}
                        disabled={!agreedWithTOS}
                        className="hme-new-user__submit-btn"
                        type="submit"
                    >
                        {!changingAccountPassword ? (
                            t('common__confirm')
                        ) : (
                            <span className="hme-new-user__loader-wrapper">
                                <Loader className="hme-new-user__btn-loader" />
                            </span>
                        )}
                    </Button> 
                    }
                </div>
            ),
            [isSubmitDisabled, changingAccountPassword, agreedWithTOS, t]
    );

    if (fetchedAccountData.AccountTOSAgree) {
        return <Navigate to={ isPartnerUser ? `/` : `/${PUBLIC_ROUTES.account}`} replace />;
    }

    return (
        <ResetWidget
            Title={Title}
            Rules={Rules}
            FormBody={FormBody}
            FormFooter={FormFooter}
            onSubmit={handleSubmit}
            className="hme-new-user__form"
            isSSOAccount={isSSOAccount}
        />
    );
};

const NewAccountCondition = cond([
    [({ isLoading }) => isLoading, () => {
        const { t } = useTranslation();
        return <CenterLoader>{t('common__loading')}</CenterLoader>;
    }],
    [T, (props) => <NewAccountComponent {...props} />]
]);

const NewAccountBasic = () => {
    const { isLoading: isLoadingAccountData, fetchedAccountData, loadAccount } = useAccount();

    useEffect(() => {
        loadAccount();
    }, [loadAccount]);

    return (
        <div className="hme-new-user">
            <div className="hme-new-user__content">
                <NewAccountCondition isLoading={isLoadingAccountData} fetchedAccountData={fetchedAccountData}  />
            </div>

            <NotificationsList />
        </div>
    );
};

export const NewAccount = compose(
        authenticate,
        withHMELayout({
            contentClasses: ['hme-new-user__page']
        })
)(NewAccountBasic);
