import React from 'react';
import { Modal } from 'library/Modal';
import { useTranslation } from 'react-i18next';

import './AddStoreModal.css';

export const AddStoreModal = ({ onHide, children, ...modalProps }) => {
    const { t } = useTranslation();

    return (
        <Modal
            {...modalProps}
            title={t('stores__add_store__title')}
            onHide={onHide}
            dialogClassName="hme-add-store-modal-dialog"
        >
            <div className="hme-add-store-modal">{children}</div>
        </Modal>
    );
};
