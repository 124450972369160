import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { sortPermissions } from '../constants/sorting';
import { Label } from 'library/Label';
import { Accordion } from 'library/Accordion';
import { permissionsProp } from '../../types/permissionsProps';
import { roleProp } from '../../types/roleProps';
import { Permission } from './Permission';

import './PermissionsSection.scss';

const PermissionsList = ({ permissions, role, isDisabled, onChange }) => {
    return (
        <>
            {
                permissions.map((permission) => (
                    <Permission
                        key={permission.value}
                        permission={permission}
                        isDisabled={isDisabled}
                        checked={role.permissions?.includes(permission.value)}
                        onChange={onChange}
                    />
                ))
            }
        </>
    );
};

export const PermissionsSection = ({ title, isFirst, isDisabled, permissions, role, type, onChange }) => {
    const { t } = useTranslation();
    const sortedPermissions = useMemo(() => permissions.map((permission) => ({
        ...permission,
        index: sortPermissions[type].indexOf(permission.text)
    })).sort(({ index: indexA }, { index: indexB }) => indexA - indexB), [permissions, type]);


    return (
        <div className={classNames('hme-view-role__permissions-section', {
            'hme-view-role__permissions-section--first': isFirst,
        })}>
            <div className='hme-l-size'>
                <Label>{t(title)}</Label>
                <PermissionsList
                    permissions={sortedPermissions}
                    role={role}
                    isDisabled={isDisabled}
                    onChange={onChange}
                />
            </div>
            <Accordion className='hme-s-size' title={title}>
                <PermissionsList
                    permissions={sortedPermissions}
                    role={role}
                    isDisabled={isDisabled}
                    onChange={onChange}
                />
            </Accordion>
        </div>
    );
};

PermissionsSection.propTypes = {
    permissions: permissionsProp,
    title: PropTypes.string,
    isFirst: PropTypes.bool,
    isDisabled: PropTypes.bool,
    type: PropTypes.string,
    role: roleProp,
    onChange: PropTypes.func,
};
