import React from 'react';

import { Label } from 'library/Label';

import './List.scss';

export const List = ({ title, items }) => (
    <div className='hme-list'>
        <Label>{title}</Label>
        <ul className='hme-list-items'>{
            items.map((item, index) => (
                <li key={index} className='hme-list-item'>{item}</li>
            ))
        }</ul>
    </div>
);
