import { CommonConstants } from 'Constants';
import { permissionTypes } from 'constants/permissionTypes';

const { userPermissions, externalPermissions } = CommonConstants;

export const sortPermissions = {
    [permissionTypes.BASIC]: [
        userPermissions.ManageUser,
        userPermissions.EditStoreBasic,
        userPermissions.RemoteConnect,
        userPermissions.ViewLeaderboard,
        userPermissions.ManageRole
    ],
    [permissionTypes.ADMIN]: [
        userPermissions.ManageDeviceSettings,
        userPermissions.EditDeviceSettings,
        userPermissions.EditLeaderboard,
        userPermissions.EditStoreAdvanced,
        userPermissions.PerformDeviceRestart,
        userPermissions.ViewEditContests,
        userPermissions.ViewEditAwards,
        userPermissions.SmackTalk,
        userPermissions.SendSmackTalkMessage,
        userPermissions.CustomizeSmackTalk,
        userPermissions.SendTextConnectMessage,
        userPermissions.ViewAllStores
    ],
    [permissionTypes.EXTERNAL]: [
        externalPermissions.ManageDevicesBasic,
        externalPermissions.ManageDevicesAdvanced,
        externalPermissions.ManageRoles,
        externalPermissions.ManageStoresBasic,
        externalPermissions.ManageStoresAdvanced,
        externalPermissions.ManageUsers,
    ],
};
