import React from 'react';
import { compose, cond, T } from 'ramda';
import { CommonConstants } from 'Constants';
import { withHMELayout } from 'HOCs/withHMELayout';
import { isDistributor } from 'services/Auth';
import { checkAccess } from 'actions/permissions';
import authenticate from 'components/Security/Authentication';
import { withAccess } from 'HOCs/withAccess';
import { accountTypes } from 'constants/accountTypes';
import { getApplySettingsTaskHistory } from '../../DeviceSettings/DeviceSettingsService';
import { StatusPage } from '../StatusPage';
import { DeviceSettings } from '../../DeviceSettings';

import './ApplyDeviceSettingsPage.scss';

const loadAllJobs = async (dateRange, setJobs, setAreJobsLoading) => {
    setAreJobsLoading(true);

    try {
        const tasks = await getApplySettingsTaskHistory({ dateRange });

        if (tasks.status) {
            setJobs(tasks.data || []);
        }
    } catch (err) {
        setJobs([]);
    }

    setAreJobsLoading(false);
};

const hasDistributorAccess = () => isDistributor() && checkAccess(CommonConstants.externalPermissions.ManageDevicesAdvanced);
const PublicApplyDeviceSettings = () => (
    <DeviceSettings permissionType={accountTypes.DISTRIBUTOR} loadAllJobs={loadAllJobs} />
);

export const ApplyDeviceSettingsPage = cond([
    [
        isDistributor,
        compose(
            withHMELayout({ variants: ['extended'] }),
            authenticate,
            withAccess({
                checkAccess: hasDistributorAccess,
            }),
        )(PublicApplyDeviceSettings),
    ],
    [T, StatusPage],
]);
