import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
    Grid,
    useAvailableFilters,
    resetFilters,
    getFiltersCount,
    applyGridFilters,
    applySearchValue,
} from 'components/Common/Grid';
import { TileList } from 'library/TileList';
import { Checkbox } from 'library/Checkbox';
import { Paginate } from 'library/Paginate';
import { applyPagination } from 'helpers';
import { PAGE_DATA, PAGE_DEFAULT } from 'constants/paginationDefault';
import { accountTypes } from 'constants/accountTypes';
import {
    BRAND_ACCOUNT_FILTER_OPTIONS,
    NEXEO_BRAND_ACCOUNT_FILTER_OPTIONS,
    GRID_HEADERS,
    NEXEO_GRID_HEADERS,
    MOBILE_GRID_HEADERS,
    MOBILE_NEXEO_GRID_HEADERS,
    BRAND_ACCOUNT_SEARCH_PROPERTIES
} from '../../constants';
import { deviceTypes, deviceServerTypes } from 'constants/device';
import { checkSelectable } from '../../../helpers/checkSelectable';
import { TargetDeviceHeader } from './TargetDeviceHeader';

const getSearchProperties = (isNexeoSourceType) =>
    isNexeoSourceType ? [...BRAND_ACCOUNT_SEARCH_PROPERTIES, 'Device_SettingVersion'] : BRAND_ACCOUNT_SEARCH_PROPERTIES;

const { PAGE_SIZES_ADMIN, PAGE_SIZES_PUBLIC } = PAGE_DATA;

export const BrandAccountGrid = ({
    isLoading,
    selectedSnapshot,
    targetDevices, // List of all potential destination devices
    params, // params.targetDevices == List of selected destination devices
    selectedDeviceType,
    permissionType,
    onParamsChange,
    onFiltersClear,
}) => {
    const [filter, setFilter] = useState('');
    const [devices, setDevices] = useState([]);
    const [filtersCount, setFiltersCount] = useState(0);
    const [gridFilters, setGridFilters] = useState({
        Brand_Name: [],
        Company_Type: [],
        Device_MainVersion: [],
        Device_LaneConfig_Name: [],
    });
    const [availableFilters, setAvailableFilters] = useState([]);
    const [isDisabledHidden, setIsDisabledHidden] = useState(false);
    const [pageData, setPageData] = useState(PAGE_DEFAULT);

    const sourceDevice = params?.sourceDevice;
    const isNexeoSourceType = selectedDeviceType === deviceTypes.NEXEO;
    const FILTER_OPTIONS = isNexeoSourceType ? NEXEO_BRAND_ACCOUNT_FILTER_OPTIONS : BRAND_ACCOUNT_FILTER_OPTIONS;

    const { t } = useTranslation();
    useAvailableFilters(FILTER_OPTIONS, targetDevices, setGridFilters, setAvailableFilters); 

    useEffect(() => {
        const rowsFilteredByGrid = applyGridFilters(FILTER_OPTIONS, targetDevices, gridFilters);
        const rowsFilteredBySearch = applySearchValue(rowsFilteredByGrid, getSearchProperties(isNexeoSourceType), filter, {
            minLength: 1,
        });
        const rowsFilteredBySettingsVersion = rowsFilteredBySearch.map((device) => {
            const isSelectable = checkSelectable({ device, selectedSnapshot, sourceDevice });

            return {
                ...device,
                isSelectable,
                rowClassName: !isSelectable ? 'hme-grid-row-disabled' : '',
            };
        });

        setDevices(rowsFilteredBySettingsVersion);
    }, [targetDevices, gridFilters, filter, selectedSnapshot, sourceDevice, setDevices]);

    useEffect(() => {
        setFiltersCount(getFiltersCount(availableFilters, gridFilters));
        setPageData({ ...pageData, page: PAGE_DATA.INIT_PAGE });
    }, [availableFilters, gridFilters]);

    const checkSelected = (row) => targetDeviceUIDs.includes(row.Device_UID);

    const checkDisabled = (row) => !checkSelectable({ device: row, selectedSnapshot, sourceDevice });

    const onFiltersReset = useCallback(() => {
        resetFilters(availableFilters, setGridFilters);
    }, [availableFilters, setGridFilters]);

    const onSelectAllDevices = useCallback((value) => {
        onParamsChange({
            ...params,
            targetDevices: value ? devices : []
        });
    }, [params, devices]);

    const onSelectDevice = useCallback((row, value) => {
        const newTargetDevices = value
            ? [...params.targetDevices, row]
            : [...params.targetDevices].filter((d) => d.Device_UID !== row.Device_UID);

        onParamsChange({
            ...params,
            targetDevices: newTargetDevices
        });
    }, [params]);

    const targetDeviceUIDs = useMemo(() => params.targetDevices.map((td) => td.Device_UID), [params]);

    const filteredDeviceRows = useMemo(
        () => isDisabledHidden
                ? devices.filter((row) => checkSelectable({ device: row, selectedSnapshot, sourceDevice }))
                : devices,
        [devices, isDisabledHidden, selectedSnapshot, sourceDevice, checkSelectable],
    );

    useEffect(() => {
        setPageData({ ...pageData, page: PAGE_DATA.INIT_PAGE });
    }, [isDisabledHidden]);

    const deviceRows = useMemo(() => {
        return filteredDeviceRows.map((row) => {
            const checked = targetDeviceUIDs.includes(row.Device_UID);
            const isDisabled = !checkSelectable({ device: row, selectedSnapshot, sourceDevice });

            return {
                ...row,
                DeviceServerType: row.iotDeviceId ? t('common__service-type__iot') : t('common__service-type__legacy'),
                selectColumn: (
                    <Checkbox
                        label={t('common__select')}
                        checked={checked}
                        onChange={(value) => onSelectDevice(row, value)}
                        disabled={isDisabled}
                    />
                ),
            };
        });
    }, [filteredDeviceRows, targetDeviceUIDs, selectedSnapshot, sourceDevice, checkSelectable, onSelectDevice]);

    return (
        <>
            <TargetDeviceHeader
                devices={devices}
                gridFiltersCount={filtersCount}
                searchValue={filter}
                isDisabledHidden={isDisabledHidden}
                onDisabledViewChange={setIsDisabledHidden}
                onSearchChange={setFilter}
                onFiltersReset={onFiltersReset}
                onFiltersClear={onFiltersClear}
                isNexeoSourceType={isNexeoSourceType}
            />
            <Grid
                rows={applyPagination(deviceRows, pageData)}
                headers={isNexeoSourceType ? NEXEO_GRID_HEADERS : GRID_HEADERS}
                isLoading={isLoading}
                rowKey="Device_UID"
                selection={params.targetDevices}
                availableFilters={availableFilters}
                filters={gridFilters}
                onFiltersChange={setGridFilters}
                noRecordsMessage={t('common__no-devices--found')}
                checkSelected={checkSelected}
                checkDisabled={checkDisabled}
                onSelectionChange={(selected) => {
                    onParamsChange({
                        ...params,
                        targetDevices: selected,
                    })
                }}
                selectable
            />
            <TileList
                headers={isNexeoSourceType ? MOBILE_NEXEO_GRID_HEADERS : MOBILE_GRID_HEADERS}
                rows={applyPagination(deviceRows, pageData)}
                isLoading={isLoading}
                noRecordsMessage="common__no-devices--found"
                sortButtonName="common__filter"
                radioColumn={'selectColumn'}
                checkSelected={checkSelected}
                checkDisabled={checkDisabled}
                filtersOptions={FILTER_OPTIONS}
                availableFilters={availableFilters}
                filters={gridFilters}
                filtersCount={filtersCount}
                onFiltersChange={setGridFilters}
                onFiltersAndSortReset={onFiltersReset}
                onFiltersReset={onFiltersReset}
                onSelectAll={onSelectAllDevices}
                selectable
            />
            <Paginate
                className="admin-create-apply-device-settings__paginate hme-paginate__mobile"
                page={pageData.page}
                recordsPerPage={pageData.recordsPerPage}
                total={deviceRows.length}
                onChange={setPageData}
                pageSizes={permissionType === accountTypes.ADMIN ? PAGE_SIZES_ADMIN : PAGE_SIZES_PUBLIC}
            />
        </>
    );
};

BrandAccountGrid.propTypes = {
    isLoading: PropTypes.bool,
    devices: PropTypes.arrayOf(PropTypes.object),
    params: PropTypes.object,
    onParamsChange: PropTypes.func,
    onFiltersClear: PropTypes.func,
    permissionType: PropTypes.string,
};