
import { Constant } from '@hme-cloud/utility-common';
import { CommonConstants } from 'Constants';
import { checkVersionsEquality } from './checkVersionsEquality';

const { deviceType } = CommonConstants;
const { BuildVersions } = Constant;

export const checkSelectable = ({ device, selectedSnapshot, sourceDevice }) => {
    switch (true) {
        // NEXEO
        case (device.Device_DeviceType_ID === deviceType.nexeo.id):
            return selectedSnapshot && checkVersionsEquality(device.Device_SettingVersion, selectedSnapshot?.SourceSettingsVersion);

        // ZOOM Nitro
        case (
            device.Device_DeviceType_ID === deviceType.zoom.id
            && BuildVersions.semverGte(device.Device_MainVersion, deviceType.zoomNitro.entryVersion)
        ):
            // If device is busy, it is not selectable
            if (device.Device_IsBusy !== 0) {
                return false;
            }

            // IOT snapshots should be applied to IOT devices only
            const isSnapshotIOT = selectedSnapshot?.IsIOT === 1;
            const isDeviceIOT = !!device.iotDeviceId;

            return isSnapshotIOT === isDeviceIOT;

        // ZOOM
        case (
            device.Device_DeviceType_ID === deviceType.zoom.id
            && BuildVersions.semverLt(device.Device_MainVersion, deviceType.zoomNitro.entryVersion)
        ):
            return device.Device_IsBusy === 0
                && device.Device_LaneConfig_ID === sourceDevice?.Device_LaneConfig_ID
                && device.Device_IsSameDetectorsOf === 1;
        // Other
        default:
            return true;
    }
};