import React from 'react';

import Actions from '../List/Actions/Actions';
import { Status } from '../List/Status';

export const getAccountsRows = (accounts) => {
    if (accounts && accounts.length) {
        return accounts.map((account) => ({
            ...account,
            isActive: <Status isActive={account.Account_IsActive} />,
            Actions: <Actions companyType={account.Company_Type} userUID={account.User_UID} />,
            hideActionsLink: false
        }));
    }
    return [];
};
