import React from 'react';

import { DeviceStatus } from 'library/DeviceStatus';
import { ViewDeviceLink } from 'library/ViewDeviceLink';
import { RemoveDeviceButton } from 'library/RemoveDeviceButton';

import getDeviceType from 'helpers/Device/getDeviceType';

export const getDeviceRows = (devices, onRemoveDevice) => devices.map((device) => {
    const { displayName } = getDeviceType(device);

    return ({
        ...device,
        status: (
            <DeviceStatus
                online={device.Device_IsActive}
                isPreconfigured={device.Device_IsPreconfigured}
                name={displayName}
                version={device.Device_MainVersion}
                deviceUid={device.Device_UID}
                className="hme-admin-store-details__device-info__status-link"
            />
        ),
        Actions: (
            <>
                <ViewDeviceLink device={device} />
                <RemoveDeviceButton device={device} onRemove={onRemoveDevice} />
            </>
        )
    });
});
