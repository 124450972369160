import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';

import './Header.scss';

export const Header = ({ title }) => {
    const { t } = useTranslation();

    return (
        <div className="hme-admin-account-header">
            <div className="hme-admin-account-header-title-wrapper">
                <Title as="h2">{t(title)}</Title>
            </div>
        </div>
    );
};
