import React from 'react';

import { Translator } from 'library/Translator';

const defaultConfig = {
  MIN_IMG_WIDTH_PX: 20,
  MAX_IMG_WIDTH_PX: 500,
  MIN_IMG_HEIGHT_PX: 20,
  MAX_IMG_HEIGHT_PX: 500
};

export const imageSize = config => async (userImage) => {
  // skip validation if image is null or image not in base64 format
  if(!userImage || !userImage.startsWith('data:')) {
    return;
  }

  config = Object.assign((config || {}), defaultConfig);

  const image = new Image();
  image.src = userImage;

  const validateImage = () => new Promise(resolve => {
    image.addEventListener('load', e => {
      const {width, height} = e.target;

      if (
        width > config.MAX_IMG_WIDTH_PX || width < config.MIN_IMG_WIDTH_PX ||
        height > config.MAX_IMG_HEIGHT_PX || height < config.MIN_IMG_HEIGHT_PX
      ) {
        const imgSizeError = (
          <Translator tKey='common__error--image-size' options={{
            minWidth: config.MIN_IMG_WIDTH_PX,
            minHeight: config.MIN_IMG_HEIGHT_PX,
            maxWidth: config.MAX_IMG_WIDTH_PX,
            maxHeight: config.MAX_IMG_HEIGHT_PX,
          }} />
        );

        resolve(imgSizeError);
      }

      resolve();
    });
  })

  const imgError = await validateImage();

  return imgError ? imgError : undefined;
};
