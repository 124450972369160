import React, { createContext, useContext, useEffect } from 'react';

import { useAsync } from 'hooks/useAsync';
import { isLoggedIn } from 'services/Auth';
import { getAccountData } from 'services/Account';
import { partnerTokenTypeIds, tokenTypes } from 'constants/token';

const PartnerContext = createContext();

const isPartnerAccount = true;

export const PartnerDataProvider = ({ children }) => {
    const { run, isLoading, data } = useAsync({ data: [] });

    useEffect(() => {
        let currentRender = true;

        if (currentRender && isLoggedIn()) {
            run(Promise.all([
                getAccountData(isPartnerAccount)
            ]));
        }

        return () => {
            currentRender = false;
        };
    }, []);

    const [userDetails] = !isLoading ? data || [] : [];

    return (
        <PartnerContext.Provider value={{ userDetails, isLoading }}>{children}</PartnerContext.Provider>
    );
};

export const usePartnerData = () => {
    const context = useContext(PartnerContext);

    if (!context) {
        throw new Error('usePartnerData must be used within a PartnerProvider');
    }

    return context;
};

export const usePartnerProviders = () => {
    const { userDetails, isLoading } = usePartnerData();

    const isVaioPartner = userDetails?.providerTypes.some(
        ({ PartnerCategoryID }) => PartnerCategoryID === partnerTokenTypeIds[tokenTypes.VAIO]
    );

    return {
        isVaioPartner,
        isLoading
    };
};
