export const commonErrors = {
    INVALID_DATA: 'Data is invalid',
    INTERNAL_SERVER: 'Internal Server Error',
    NO_RECORDS_FOUND: 'No Records found'
};

export const commonErrorTranslations = {
    [commonErrors.INVALID_DATA]: 'common__error--invalid-data',
    [commonErrors.INTERNAL_SERVER]: 'common__error--internal-server',
    [commonErrors.NO_RECORDS_FOUND]: 'common__error--no-records-found'
};

export const INTERNAL_SERVER_ERROR_KEY = 'common__error--internal-server';

export const AX_ERRORS = {
    INVALID_AX_ACC: 'INVALID_AX_ACC',
    AX_ACCOUNT_ALREADY_EXISTS: 'AX_ACCOUNT_ALREADY_EXISTS',
    AX_ACCOUNT_NOT_FOUND: 'AX_ACCOUNT_NOT_FOUND'
};
