import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { omit } from 'ramda';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Loader } from 'library/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationsList } from 'library/NotificationsList';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';
import { useTrigger } from 'hooks/useTrigger';
import { useAccess } from 'hooks/useAccess';
import { addErrorNotification } from 'services/Notifications';
import { CommonConstants } from 'Constants';
import { DateLib } from '@hme-cloud/utility-common';
import { useLoadUpgradeTask } from '../hooks/useLoadUpgradeTask';
import { useLoadDeviceVersions } from '../hooks/useLoadDeviceVersions';
import { useLoadUpgradeVersions } from '../hooks/useLoadUpgradeVersions';
import { useLoadUpgradesCsv } from '../hooks/useLoadUpgradesCsv';
import { Header } from './Header';
import { Footer } from './Footer';
import { DeclineMessage } from './DeclineMessage';
import { UpgradeInfoSection } from './UpgradeInfoSection';
import { DeviceListSection } from './DeviceListSection';
import {
    getUpgradeStatus,
    validationFieldsMessagesMap,
    formDataValidator,
    declineUpgradeFormDataValidator,
} from '../helpers';
import { ADMIN_DEVICE_UPGRADES_URL } from '../constants';
import { upgradeStatuses } from '../constants/upgradeStatuses';
import { updatedUpgradeTask, sendCancelDeviceUpgrade, approveUpgradeTask, declineUpgradeTask } from './Controller';

import 'react-toastify/scss/main.scss';
import './ViewDeviceUpgrade.scss';

const { adminPermissions } = CommonConstants;

export const ViewDeviceUpgrade = () => {
    const { t } = useTranslation();
    const [upgradeData, setUpgradeData] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [declineUpgradeFormErrors, setDeclineUpgradeFormErrors] = useState({});
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const [isUpgradeUpdating, setIsUpgradeUpdating] = useState(false);
    const [isDeviceUpgradeCancelling, setIsDeviceUpgradeCancelling] = useState(false);
    const [isDeviceUpgradeApproving, setIsDeviceUpgradeApproving] = useState(false);
    const [isDeviceUpgradeDeclining, setIsDeviceUpgradeDeclining] = useState(false);
    const [isApproveConfirmShown, setIsApproveConfirmShown] = useState(false);
    const [isDeclineConfirmShown, setIsDeclineConfirmShown] = useState(false);
    const [declineMessage, setDeclineMessage] = useState('');

    const { scheduledupgradeid } = useParams();

    const { upgradeTask, isUpgradeTaskLoading, loadUpgradeTask } = useLoadUpgradeTask(scheduledupgradeid);
    const { deviceVersions, isDeviceVersionsLoading } = useLoadDeviceVersions();
    const { upgradeVersions, isUpgradeVersionsLoading } = useLoadUpgradeVersions();
    const { isUpgradesCsvLoading, loadUpgradesCsv } = useLoadUpgradesCsv();

    const navigate = useNavigate();
    const changeUpgradeInfoTrigger = useTrigger();

    const hasApproveUpgradeAccess = useAccess(adminPermissions.DeviceUpgradeApproval);

    const { scheduledUpgrade, deviceUpgrade = [] } = upgradeTask || {};

    const upgradeStatus = useMemo(() => getUpgradeStatus(deviceUpgrade), [deviceUpgrade]);

    const isPendingApprovalView = hasApproveUpgradeAccess && upgradeStatus === upgradeStatuses.PENDING_APPROVAL;

    const checkIsValidUpgradeInfo = useCallback(
        (formData) =>
            Object.keys(formData).every(
                (key) =>
                    (Array.isArray(formData[key]) && formData[key].length) ||
                    (!Array.isArray(formData[key]) && formData[key]),
            ),
        [],
    );

    const resetFormErrors = useCallback(
        (formData, updatedFields) => {
            // find specific updated field in the form
            const changedField = Object.keys(updatedFields).find(
                (fieldKey) => updatedFields[fieldKey] !== formData[fieldKey],
            );

            if (changedField) {
                let formErrorsToRemove = validationFieldsMessagesMap[changedField] || [];

                if (changedField === 'deviceType') {
                    formErrorsToRemove = [
                        ...formErrorsToRemove,
                        ...validationFieldsMessagesMap.targetDeviceVersions,
                        ...validationFieldsMessagesMap.upgradeVersion,
                    ];
                }
                setFormErrors(omit(formErrorsToRemove, formErrors));
            }
        },
        [validationFieldsMessagesMap],
    );

    const onUpgradeInfoChange = useCallback(
        (formData) => {
            const infoData = {
                name: formData.upgradeName,
                deviceType: formData.targetDeviceType,
                peripheralDeviceType: formData.targetPeripheralDeviceType,
                targetDeviceVersions: formData.targetDeviceVersions,
                upgradeVersion: formData.upgradeVersion,
                runDate: formData.deviceUpgradeDate,
                from: formData.deviceUpgradeTimeFrom,
                to: formData.deviceUpgradeTimeTo,
                scheduleDays: formData.upgradeScheduleDays,
                installRunDate: formData.deviceInstallDate, 
                installScheduleDays: formData.installScheduleDays,
                installFrom: formData.deviceInstallTimeFrom,
                installTo: formData.deviceInstallTimeTo,
            };

            if (upgradeData) {
                resetFormErrors(upgradeData, infoData);
            }

            if (
                upgradeData &&
                (upgradeData.deviceType !== formData.targetDeviceType ||
                    upgradeData.sourceVersion !== formData.deviceVersion)
            ) {
                changeUpgradeInfoTrigger && changeUpgradeInfoTrigger.trigger();
            }

            setUpgradeData((prevUpgradeData) => ({
                ...prevUpgradeData,
                ...infoData,
            }));
        },
        [resetFormErrors, checkIsValidUpgradeInfo, changeUpgradeInfoTrigger],
    );

    const checkIsFormChanged = useCallback(
        (upgradeData, scheduledUpgrade) => {
            const scheduledUpgradeStartDate = new DateLib(scheduledUpgrade.UpgradeStartDate).formatInUTC(DateLib.FORMAT_TYPES.YEAR_MONTH_DATE);
            const scheduledUpgradeStartTime = new DateLib(scheduledUpgrade.StartTime).formatInUTC(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD);
            const scheduledUpgradeEndTime = new DateLib(scheduledUpgrade.EndTime).formatInUTC(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD);


            const { InstallSchedule } = scheduledUpgrade;
            const scheduledInstallStartDate = InstallSchedule?.startDate
                ? new DateLib(InstallSchedule.startDate).formatInTimeZone(DateLib.FORMAT_TYPES.YEAR_MONTH_DATE)
                : '';
            const scheduledInstallStartTime = InstallSchedule?.startTime
                ? new DateLib(InstallSchedule.startTime).formatInTimeZone(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD)
                : '';
            const scheduledInstallEndTime = InstallSchedule?.endTime
                ? new DateLib(InstallSchedule.endTime).formatInTimeZone(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD)
                : '';

            const isInstallScheduleChanged =
                scheduledInstallStartDate !== upgradeData.installRunDate ||
                scheduledInstallStartTime !== upgradeData.installFrom ||
                scheduledInstallEndTime !== upgradeData.installTo ||
                InstallSchedule?.weekDays !== upgradeData.installScheduleDays;

            return (
                scheduledUpgrade.ScheduledUpgradeName !== upgradeData.name ||
                scheduledUpgrade.UpgradeToVersion !== upgradeData.upgradeVersion ||
                scheduledUpgrade.DaysofWeek !== upgradeData.scheduleDays ||
                scheduledUpgradeStartDate !== upgradeData.runDate ||
                scheduledUpgradeStartTime !== upgradeData.from ||
                scheduledUpgradeEndTime !== upgradeData.to ||
                isInstallScheduleChanged
            );
        },
        [upgradeData, scheduledUpgrade],
    );

    const onCancelDevicesUpgrade = useCallback(
        async (deviceUpgradeIDs) => {
            await sendCancelDeviceUpgrade({
                deviceUpgradeIDs,
                setIsDeviceUpgradeCancelling,
            });
            loadUpgradeTask(scheduledupgradeid);
        },
        [sendCancelDeviceUpgrade, loadUpgradeTask],
    );

    const onCancelUpgrade = useCallback(() => {
        navigate(ADMIN_DEVICE_UPGRADES_URL);
    }, [navigate]);

    const onApplyUpgrade = useCallback(() => {
        const errors = formDataValidator(upgradeData);

        if (errors.status) {
            setFormErrors(errors);
            return;
        }

        updatedUpgradeTask({ upgradeData, upgradeTask, setIsUpgradeUpdating, navigate });
    }, [upgradeData, upgradeTask, formDataValidator]);

    useEffect(() => {
        if (isSubmitEnabled || !scheduledUpgrade || !upgradeData) {
            return;
        }

        const isFormChanged = checkIsFormChanged(upgradeData, scheduledUpgrade);

        if (isFormChanged) {
            setIsSubmitEnabled(true);
        }
    }, [upgradeData, scheduledUpgrade]);

    useEffect(() => {
        // error message is used for showing the warning icon in the notification
        if (isPendingApprovalView) {
            addErrorNotification('device-upgrades__notification__upgrade-requires-reviewer', {
                className: 'hme-device-upgrades-reviewer-warning-notification',
            });
        }
    }, [isPendingApprovalView]);

    const onApprove = () => {
        setIsApproveConfirmShown(true)
    };

    const onDecline = () => {
        setIsDeclineConfirmShown(true)
    };

    const onCancel = () => {
        navigate(ADMIN_DEVICE_UPGRADES_URL)
    };

    const onApproveUpgradeCancel = () => {
        setIsApproveConfirmShown(false)
    };

    const onDeclineUpgradeCancel = useCallback(() => {
        setIsDeclineConfirmShown(false);
        setDeclineMessage('');
        setDeclineUpgradeFormErrors({});
    }, []);

    const onApproveUpgrade = useCallback(() => {
        setIsApproveConfirmShown(false);

        approveUpgradeTask({
            upgradeId: scheduledUpgrade?.DeviceScheduledUpgradeID,
            setIsDeviceUpgradeApproving,
            navigate,
        });
    }, [scheduledUpgrade]);

    const onDeclineUpgrade = useCallback(() => {
        const errors = declineUpgradeFormDataValidator({ message: declineMessage });
        setDeclineUpgradeFormErrors(errors);

        if (errors.status) {
            return;
        }

        setIsDeclineConfirmShown(false);
        setDeclineMessage('');

        declineUpgradeTask({
            upgradeId: scheduledUpgrade?.DeviceScheduledUpgradeID,
            message: declineMessage,
            setIsDeviceUpgradeDeclining,
            navigate,
        });
    }, [declineMessage, scheduledUpgrade]);

    const onDeclineMessageChange = useCallback((message) => {
        const errors = declineUpgradeFormDataValidator({ message });

        setDeclineUpgradeFormErrors(errors);
        setDeclineMessage(message);
    }, []);

    const onUpgradesCsvDownload = useCallback(() => {
        if (scheduledUpgrade) {
            loadUpgradesCsv(scheduledUpgrade)
        }
    }, [scheduledUpgrade]);

    const isLoading =
        isUpgradeTaskLoading ||
        isDeviceVersionsLoading ||
        isUpgradeVersionsLoading ||
        isUpgradeUpdating ||
        isDeviceUpgradeCancelling ||
        isDeviceUpgradeApproving ||
        isDeviceUpgradeDeclining;

    if (isLoading) {
        return (
            <div className="hme-components view-device-upgrade-wrapper">
                <NotificationsList />
                <div className="view-device-upgrade">
                    <Loader />
                </div>
            </div>
        );
    }

    return (
        <div className="hme-components view-device-upgrade-wrapper">
            <NotificationsList />
            {upgradeTask && (
                <>
                    <div
                        className={classNames([
                            'view-device-upgrade',
                            `view-device-upgrade--${upgradeStatus.toLowerCase().replace(' ', '-')}`,
                        ])}
                    >
                        <div className="view-device-upgrade-header">
                            <Header
                                scheduledUpgrade={scheduledUpgrade}
                                isUpgradesCsvLoading={isUpgradesCsvLoading}
                                onUpgradesCsvDownload={onUpgradesCsvDownload}
                            />
                        </div>
                        <div className="view-device-upgrade-sections">
                            <UpgradeInfoSection
                                sectionNumber={1}
                                scheduledUpgrade={scheduledUpgrade}
                                upgradeStatus={upgradeStatus}
                                deviceVersions={deviceVersions}
                                onUpgradeInfoChange={onUpgradeInfoChange}
                                upgradeVersions={upgradeVersions}
                                formErrors={formErrors}
                                onCancelUpgrade={onCancelUpgrade}
                                onApplyUpgrade={onApplyUpgrade}
                                isSubmitEnabled={isSubmitEnabled}
                            />
                            <DeviceListSection
                                sectionNumber={2}
                                devices={deviceUpgrade}
                                deviceType={scheduledUpgrade.DeviceTypeSource}
                                upgradeStatus={upgradeStatus}
                                onUpgradeInfoChange={onUpgradeInfoChange}
                                onCancelDevicesUpgrade={onCancelDevicesUpgrade}
                            />
                        </div>
                    </div>
                    <ConfirmPopupComponent
                        show={isApproveConfirmShown}
                        className="view-device-upgrade__confirm-popup"
                        dialogClassName="view-device-upgrade__confirm-popup--approve"
                        title={t('common__double-checking')}
                        message={t('device-upgrades__popup__approve-upgrade--warning')}
                        onHide={onApproveUpgradeCancel}
                        actions={[{
                            children: t('common__popup--cancel-action'),
                            onClick: onApproveUpgradeCancel
                        }, {
                            children: t('device-upgrades__popup__approve-upgrade--confirm'),
                            variants: ['submit'],
                            onClick: onApproveUpgrade
                        }]}
                    />
                    <ConfirmPopupComponent
                        show={isDeclineConfirmShown}
                        className="view-device-upgrade__confirm-popup"
                        dialogClassName="view-device-upgrade__confirm-popup--decline"
                        title={t('device-upgrades__popup__decline-upgrade--title')}
                        message={<DeclineMessage formErrors={declineUpgradeFormErrors} onMessageChange={onDeclineMessageChange} />}
                        onHide={onDeclineUpgradeCancel}
                        actions={[{
                            children: t('common__popup--cancel-action'),
                            onClick: onDeclineUpgradeCancel
                        }, {
                            children: t('device-upgrades__popup__decline-upgrade--confirm'),
                            variants: ['submit'],
                            onClick: onDeclineUpgrade
                        }]}
                    />
                    {isPendingApprovalView && <Footer onCancel={onCancel} onDecline={onDecline} onApprove={onApprove} />}
                </>
            )}
        </div>
    );
};
