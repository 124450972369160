export const applyGridFilters = (options, data, gridFilters) => data.filter(
    item => Object.keys(gridFilters).every(property => {
        const valuePropName = (options[property] && options[property].valuePropName) || property;
        const itemValue = item[valuePropName];

        const valueArray = Array.isArray(itemValue) ? itemValue : [itemValue];

        const filters = (gridFilters[property] || []).reduce(
            (acc, item) => (Array.isArray(item) ? [...acc, ...item] : [...acc, item]),
            [],
        );

        return valueArray.some(value => filters.includes(value));
    })
);
