import { LeaderboardInvite } from './LeaderboardInvite';
import { Remove } from './Remove';

export const Modals = () => {
    return (
        <>
            <LeaderboardInvite />
            <Remove />
        </>
    );
};
