import React from 'react';
import { useTranslation } from 'react-i18next';

import { CenterLoader } from 'components/Common/CenterLoader';

export const DeviceInfoLoading = () => {
    const { t } = useTranslation();

    return <CenterLoader>{t('system-status__message__loading-device')}</CenterLoader>;
};
