import React from 'react';
import classNames from 'classnames';

import { Label } from 'library/Label';

import './InfoField.scss';

export const InfoField = ({ className = '', label, children }) => (
    <div className={classNames('hme-info-field', className)}>
        <Label>{label}</Label>
        <div className='hme-info-field-content'>
            {children}
        </div>
    </div>
);
