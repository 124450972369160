import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { VisuallyHidden } from '@reach/visually-hidden';

import { BUTTON_VARIANTS, Button } from 'library/Button';
import { ErrorLabel } from 'library/ErrorLabel';

import { ReactComponent as TrashCanIcon } from 'assets/icons/trash-can.svg';

import './RewardImageInput.scss';

export const getBase64FromImage = (image) => {
    const fileReader = new FileReader();
    const img = new Image();

    return new Promise((resolve, reject) => {
        img.onload = () => {
            fileReader.onloadend = () => resolve(fileReader.result);
            fileReader.onerror = () => reject(fileReader.error);

            fileReader.readAsDataURL(image);
        };

        img.src = URL.createObjectURL(image);
    });
};

const INPUT_PROPS = {
    style: {}
};

const ACCEPT_MIMETYPES = {
    'image/*': []
};

const buttonVariants = [BUTTON_VARIANTS.TRANSPARENT];

const ImageThumb = ({ src, ...props }) => {
    return (
        <div className="hme-reward-image-input__preview-thumb">
            <img src={src} {...props} />
        </div>
    );
};

export const RewardImageInput = ({
    accept = ACCEPT_MIMETYPES,
    onDrop,
    onImageRemove,
    src = null,
    Thumb = ImageThumb,
    error = '',
    readOnly = false
}) => {
    const { t } = useTranslation();

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        accept,
        multiple: false,
        noClick: true,
        noKeyboard: true
    });

    return (
        <div className="hme-reward-image-input" {...getRootProps()}>
            <div className="hme-reward-image-input__preview-container">
                <Thumb src={src} />

                {src && !readOnly && (
                    <Button
                        onClick={onImageRemove}
                        variants={buttonVariants}
                        className="hme-reward-image-input__remove-preview-btn"
                    >
                        <TrashCanIcon />
                    </Button>
                )}

                <div className="hme-reward-image-input__ribbon-container">{t('contest__reward')}</div>
            </div>

            {error && <ErrorLabel>{error}</ErrorLabel>}

            {!readOnly && (
                <Button onClick={open} className="hme-reward-image-input__upload-image-btn">{t('file-select__upload-image')}</Button>
            )}

            <VisuallyHidden>
                <input {...getInputProps(INPUT_PROPS)} />
            </VisuallyHidden>
        </div>
    );
};
