export const sortByStoreProps = (rows, direction, prop) => (
    rows.sort((a, b) => {
        const firstValue = a[prop] || '';
        const secondValue = b[prop] || '';

        if (firstValue === secondValue) {
            return 0;
        }

        return firstValue < secondValue ? -direction : direction;
    })
);
