import React from 'react'
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';

import './Alerts.css'

const LoaderImg = require('../../images/Loader.gif')

class ActionLoader extends React.Component {
  constructor (props) {
    super(props)
  }
  render () {
    const {
      showLoader,
      t,
      message
    } = this.props

    return (
      <section className={'actionLoaderSection text-center ' + (showLoader ? 'show' : 'hidden')}>
        <h3 className='loaderTxt'>{t(message)}...</h3>
        <div><img src={LoaderImg} alt='loaderImage' /></div>
      </section>
    )
  }
}

export default compose(
  withTranslation()
)(ActionLoader);
