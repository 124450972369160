import React, { useState, useEffect, useCallback } from 'react';
import { t } from 'i18next';
import { Tip } from 'library/Tip';
import { AccountInput } from './AccountInput';
import { AccountBrandGrid } from './AccountBrandGrid';

import './AccountForm.scss';

export const AccountForm = ({
    filters,
    sourceDevice,
    selectedDeviceType,
    onFiltersChange
}) => {
    const [account, setAccount] = useState(null);
    const [selectedBrands, setSelectedBrands] = useState([]);

    useEffect(() => {
        if (!account) {
            return;
        }

        const accountBrand = filters.accountBrand.find(
            ({ accountId }) => account.Account_ID === accountId
        ) || {};

        setSelectedBrands(accountBrand.brandIds || []);
    }, [account, filters.accountBrand]);

    const onBrandIdsChange = useCallback(newBrandIds => {
        const newAccountBrands = filters.accountBrand.filter(
            ({ accountId }) => account.Account_ID !== accountId
        );

        if (newBrandIds.length !== 0) {
            newAccountBrands.push({
                accountId: account.Account_ID,
                brandIds: newBrandIds,
            });
        }

        onFiltersChange({
            ...filters,
            accountBrand: newAccountBrands,
        });
    }, [
        filters,
        account,
        onFiltersChange
    ]);

    return (
        <div className='hme-create-task-account-form'>
            <div className='hme-create-task-account-form-input-row'>
                <AccountInput
                    account={account}
                    sourceDevice={sourceDevice}
                    selectedDeviceType={selectedDeviceType}
                    onAccountChange={setAccount}
                />
                <Tip className='hme-create-task-account-form-tip'>
                    {t('device-upgrades__section__warning__add-devices-multiple-accounts')}
                </Tip>
            </div>
            {
                account ?
                    <AccountBrandGrid
                        account={account}
                        sourceDevice={sourceDevice}
                        selectedDeviceType={selectedDeviceType}
                        selectedBrandIds={selectedBrands}
                        onSelectedBrandIdsChange={onBrandIdsChange}
                    />:
                    null
            }
        </div>
    );
};
