import React from 'react';

import { navigationService } from 'components/Common/NavigationServices';
import { PARTNER_ROUTES } from 'constants/routes';
import {HOME_TAB_NAME} from "./PartnerTitle";

const homeItem = {
    name: HOME_TAB_NAME,
    tKey: 'main-header__item--welcome',
    icon: <i className='icon icon-home' />,
    topMenu: false,
    url: navigationService.getUrl('welcome'),
    className: 'hme-menu-item--with-home-icon',
    hasAccess: true,
}

const storesItem = {
    name: PARTNER_ROUTES.stores,
    tKey: 'common__stores',
    icon: <i className='icon icon-dashboard' />,
    url: navigationService.getUrl(PARTNER_ROUTES.stores),
    hasAccess: true,
    linkText: 'common__stores'
};

const settingsItem = {
    name: PARTNER_ROUTES.settingsFull,
    tKey: 'partner__provider-settings__title',
    icon: <i className='icon icon-settings' />,
    url: navigationService.getUrl(PARTNER_ROUTES.settingsFull),
    hasAccess: true,
    linkText: 'partner__provider-settings__title'
};

const tokenManagementItem = {
    name: PARTNER_ROUTES.tokenManagement,
    tKey: 'admin__token-management__tab-title',
    icon: <i className='icon icon-settings' />,
    url: navigationService.getUrl(PARTNER_ROUTES.tokenManagement),
    hasAccess: true,
    linkText: 'admin__token-management__tab-title'
};

export const quickLinks = [
    storesItem,
    settingsItem,
    tokenManagementItem
];

const menuItems = [
    homeItem,
    ...quickLinks
];

const isActiveTab = (item) => {
    const activeTab = navigationService.getPartnerSelectedMenu();

    return activeTab === item.name || (item.allowed && item.allowed.includes(activeTab)) || window.location.pathname.indexOf(item.url) !== -1;
};

export const getMenuItems = (items = menuItems) => {
    return items
        .filter(({ hasAccess }) => hasAccess)
        .map((item) => ({
            ...item,
            items: item.items && getMenuItems(item.items)
        }))
        .filter(({ items }) => !items || items.length)
        .map((item) => {
            const isActive = item.items ? item.items.some(({ isActive }) => isActive) : isActiveTab(item);

            return ({
                ...item,
                isActive,
            });
        });
};
