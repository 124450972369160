import { CommonConstants } from 'Constants';

const { subscription, subscriptionNames } = CommonConstants;

export const DEFAULT_REGIONS_COUNTRY_ID = 1;
export const DEFAULT_SUBSCRIPTION_TYPE = 4;
export const ACCOUNTS_PAGE_URL = '/admin/accounts';

// TODO move companyTypes to the "constants/company/type.js" when the "epic/BB-58" branch is merged
export const companyTypes = {
    FRANCHISE: 'Franchise',
    CORPORATION: 'Corporation',
    DISTRIBUTOR: 'Distributor',
};

export const companyTypeList = [
    {
        text: 'common__company__type--franchise',
        value: companyTypes.FRANCHISE,
    },
    {
        text: 'common__company__type--corporation',
        value: companyTypes.CORPORATION,
    },
    {
        text: 'common__distributor',
        value: companyTypes.DISTRIBUTOR,
    },
];

export const lbVersions = {
    HME: 'hme',
    AZURE: 'azure',
    NITRO: 'nitro'
  }

export const nitroSubscriptions = [
    subscription.nitroEssentials,
    subscription.nitroData,
    subscription.nitroLeaderboard,
    subscription.nitroGamification,
].map(Number);

export const subscriptionTypeList = [
    {
        label: subscriptionNames.nitroEssentials,
        tKey: 'admin__account__section__form--subscription-nitro-essentials',
        value: Number(subscription.nitroEssentials),
        isNitro: true,
    },
    {
        label: subscriptionNames.nitroData,
        tKey: 'admin__account__section__form--subscription-nitro-data',
        value: Number(subscription.nitroData),
        isNitro: true,
    },
    {
        label: subscriptionNames.nitroLeaderboard,
        tKey: 'admin__account__section__form--subscription-nitro-leaderboard',
        value: Number(subscription.nitroLeaderboard),
        isNitro: true,
    },
    {
        label: subscriptionNames.nitroGamification,
        tKey: 'admin__account__section__form--subscription-nitro-gamification',
        value: Number(subscription.nitroGamification),
        isNitro: true,
    },
    {
        label: subscriptionNames.jtechCustomerOnly,
        tKey: '',
        value: Number(subscription.jtechCustomerOnly),
        isNitro: false,
    },
    {
        label: subscriptionNames.nexeoCustomerOnly,
        tKey: '',
        value: Number(subscription.nexeoCustomerOnly),
        isNitro: false,
    }
];

export const jtechSubscriptionTypeList = [
    {
        tKey: 'admin__account__section__form--jtech-subscription-linkwear-free',
        value: 'isLinkwearFree',
    },
    {
        tKey: 'admin__account__section__form--jtech-subscription-linkwear-task',
        value: 'isLinkwearTask',
    },
    {
        tKey: 'admin__account__section__form--jtech-subscription-linkwear-connect',
        value: 'isLinkwearConnect',
    },
    {
        tKey: 'admin__account__section__form--jtech-subscription-linkwear-analytics',
        value: 'isLinkwearAnalytics',
    },
];

export const pageViews = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
};

export const pageTitlesByViews = {
    ADD: 'admin__account__header__create-new-account',
    EDIT: 'admin__account__header__edit-account',
    VIEW: 'account__header__view-account',
};
