import PropTypes from 'prop-types';
import React, { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'library/Button';
import { Title } from 'library/Title';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';

import getDeviceType from '../../../../helpers/Device/getDeviceType';
import { checkIsUpgradeAvailable } from '../helpers/upgrade-availability';

import { useTranslation } from 'react-i18next';

import './Header.scss';

const redirect = (connectDeviceURL, window, navigate, event) =>
    event.ctrlKey || event.metaKey ? window.open(connectDeviceURL, '_blank') : navigate(connectDeviceURL);

export const Header = ({ device = {}, deviceSettings, onDeviceReboot, onDeviceReconnect }) => {
    const deviceType = getDeviceType(device);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const connectDeviceURL = `/connect?duid=${device.Device_UID}`;
    const confirmPopupOptions = {
        reconnect: {
            message: t('system-status__action__reconnect--confirm-message'),
            handler: onDeviceReconnect,
        },
        reboot: {
            message: t('system-status__action__reboot--confirm-message'),
            handler: onDeviceReboot,
        },
    };

    const [confirmWindowDetails, setConfirmWindowDetails] = useState({});

    const isRemoteAccessEnabled = useMemo(
        () => device && !device.Device_IsPreconfigured && device.Device_IsActive,
        [device],
    );

    const isRemoteActionEnabled = useMemo(() => {
        const { iotDeviceID, upgradeInProgress } = deviceSettings;
        const enableActions = iotDeviceID ? !upgradeInProgress : checkIsUpgradeAvailable(deviceSettings);
        return isRemoteAccessEnabled && enableActions;
    }, [isRemoteAccessEnabled, deviceSettings]);

    const [isConfirmShown, setConfirmShown] = useState(false);
    const onConfirmHide = useCallback(() => {
        setConfirmShown(false);
    }, [setConfirmShown]);

    const showConfirm = useCallback((confirmType) => {
        setConfirmWindowDetails(confirmPopupOptions[confirmType]);
        setConfirmShown(true);
    }, [setConfirmShown, setConfirmWindowDetails, confirmPopupOptions]);

    const onRemoteAccessClick = useCallback(
        (event) => redirect(connectDeviceURL, window, navigate, event),
        [connectDeviceURL, window, navigate],
    );
    const onForceReconnectClick = useCallback(() => showConfirm('reconnect'), []);
    const onRebootClick = useCallback(() => showConfirm('reboot'), []);

    return (
        <div className="hme-admin-system-status-header">
            <div className="hme-admin-system-status-header-title-buttons">
                <Title className="hme-admin-system-status-header-title">
                    {deviceType.displayName}
                    {device.Store_Name ? ` - ${device.Store_Name} ` : ''} - {device.Store_Number}
                </Title>
                <div className="hme-admin-system-status-header-buttons">
                    {/* @todo should be disabled until it is implemented on device side. */}
                    {/* disabled={true} need to be changed to disabled={!isRemoteAccessEnabled} */}
                    <Button onClick={onRemoteAccessClick} disabled={true}>
                        {t('system-status__action__remote-access')}
                    </Button>
                    <Button disabled={!isRemoteActionEnabled} onClick={onForceReconnectClick}>
                        {t('system-status__action__force-reconnect')}
                    </Button>
                    <Button onClick={onRebootClick} disabled={!isRemoteActionEnabled}>
                        {t('system-status__action__reboot-system')}
                    </Button>
                </div>
            </div>
            <ConfirmPopupComponent
                show={isConfirmShown}
                message={confirmWindowDetails.message}
                onHide={onConfirmHide}
                onConfirm={() => confirmWindowDetails.handler(device)}
            />
        </div>
    );
};

Header.propTypes = {
    device: PropTypes.object,
    deviceSettings: PropTypes.object,
    onDeviceReboot: PropTypes.func,
    onDeviceReconnect: PropTypes.func,
};
