import { accountTypes } from 'constants/accountTypes';

export const MOBILE_SNAPSHOTS_HEADERS = [
    {
        column: [
            { text: 'common__name', property: 'SettingName' },
            { text: 'common__description', property: 'Description', accountTypes: [accountTypes.ADMIN, accountTypes.DISTRIBUTOR], },
            { text: 'common__snapshot__version', property: 'SettingsVersion' },
            { text: 'common__brand', property: 'SourceBrand' },
            { text: 'settings_snapshots__grid__header-title--brand-approved', property: 'IsBrandApproved', accountTypes: [accountTypes.ADMIN, accountTypes.DISTRIBUTOR], },
            { text: 'settings_snapshots__grid__header-title--test-snapshot', property: 'IsTestSnapshot', accountTypes: [accountTypes.ADMIN, accountTypes.DISTRIBUTOR], },
            { text: 'common__account', property: 'SourceAccount', accountTypes: [accountTypes.ADMIN, accountTypes.DISTRIBUTOR], },
            { text: 'settings_snapshots__grid__header-title--source-type', property: 'SourceDeviceType' },
        ],
    },
    {
        column: [
            { text: 'settings_snapshots__grid__header-title--source-settings-version', property: 'SourceSettingsVersion' },
            { text: 'settings_snapshots__grid__header-title--source-version', property: 'SourceSoftwareVersion' },
            { text: 'settings_snapshots__grid__header-title--serial-number', property: 'SourceSN' },
            { text: 'common__device__product-id', property: 'SourceDeviceProductID' },
            { text: 'common__lane', property: 'SourceLaneConfig' },
            { text: 'common__last-used--date-time', property: 'AppliedDate' },
            { text: 'common__created-by', property: 'CreatedBy' },
            { text: 'common__status', property: 'Status' },
        ],
    },
];
