import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Expand } from 'library/Icons/Expand';
import { Label } from 'library/Label';

import './Accordion.scss';

export const Accordion = ({
    className,
    title,
    isExpanded: defaultExpanded = true,
    children
}) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);
    const { t } = useTranslation();

    const onExpandClick = useCallback(() => {
        setIsExpanded(!isExpanded)
    }, [isExpanded, setIsExpanded]);

    return (
        <div className={classNames('hme-accordion', className, {
            'hme-accordion--expanded': isExpanded,
        })}>
            <div className='hme-accordion__title' onClick={onExpandClick}>
                <Label className='hme-accordion__title__text'>{t(title)}</Label>
                <Expand expanded={isExpanded} className='hme-accordion__title__icon' />
            </div>
            <div className='hme-accordion__content'>
                {children}
            </div>
        </div>
    );
};
