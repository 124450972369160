import { CommonConstants } from 'Constants';
import { COMMON_ROUTES } from 'constants/routes';

const { subscription } = CommonConstants;

const allSubscriptions = [
    subscription.basic,
    subscription.basicWithUpgrade,
    subscription.nitroEssentials,
    subscription.premiumStore,
    subscription.nitroData,
    subscription.premiumStoreDriveThru,
    subscription.nitroLeaderboard,
    subscription.nitroGamification,
    subscription.jtechCustomerOnly,
    subscription.nexeoCustomerOnly
];

const selfServiceSubscriptions = [
    subscription.nitroLeaderboard,
    subscription.nitroGamification
];

export const reportNames = {
    'multi': 'reports__title-multi',
    'single': 'reports__title-single',
    'trendsHistory': 'reports__trends-dashboard',
    'trends': 'reports__trends',
    'outliers-client': 'reports__title-outliers-client',
    'performance-analysis': 'reports__title-performance-analysis',
    'self-service': 'reports__title-self-service',
    'rcd': 'reports__title-rcd',
    'overview': 'reports__overview',
    'new-reports-scheduler': 'scheduler__title',
    'create': 'scheduler__create-new-scheduled-report',
    'edit': 'scheduler__edit-scheduled-report'
};

export const reportUserGuides = new Map([
    ['multi', 'reports__multi-store__docs__url'],
    ['single', 'reports__single-store__docs__url'],
    ['outliers-client', 'reports__outliers__docs__url'],
    ['rcd', 'reports__raw-car__docs__url'],
    ['trendsHistory', 'reports__trends-dashboard__docs__url'],
    ['trends', 'reports__trend__docs__url'],
    ['performance-analysis', 'reports__performance-analysis__docs__url'],
    ['overview', 'reports__docs__url'],
    ['self-service', 'reports__self-service__docs__url'],
    ['new-reports-scheduler', 'reports__scheduler__docs__url'],
    ['create', 'reports__scheduler__docs__url'],
    ['edit', 'reports__scheduler__docs__url']
]);

export const reportDetails = [
    {
        id: 1,
        type: 'multi',
        title: reportNames.multi,
        path: COMMON_ROUTES.multi,
        description: 'reports__description-multi',
        subscription: allSubscriptions
    },
    {
        id: 2,
        type: 'single',
        title: reportNames.single,
        path: COMMON_ROUTES.single,
        description: 'reports__description-single',
        subscription: allSubscriptions
    },
    {
        id: 3,
        type: 'trendsHistory',
        title: reportNames.trendsHistory,
        path: COMMON_ROUTES.trendsHistory,
        description: 'reports__description-trends-dashboard',
        subscription: allSubscriptions
    },
    {
        id: 4,
        type: 'trends',
        title: reportNames.trends,
        path: COMMON_ROUTES.trends,
        description: 'reports__description-trends',
        subscription: allSubscriptions
    },
    {
        id: 5,
        type: 'outliers-client',
        title: reportNames['outliers-client'],
        path: COMMON_ROUTES['outliers-client'],
        description: 'reports__description-outliers-client',
        subscription: allSubscriptions
    },
    {
        id: 6,
        type: 'performance-analysis',
        title: reportNames['performance-analysis'],
        path: COMMON_ROUTES['performance-analysis'],
        description: 'reports__description-performance-analysis',
        subscription: allSubscriptions
    },
    {
        id: 7,
        type: 'self-service',
        title: reportNames['self-service'],
        path: COMMON_ROUTES['self-service'],
        description: 'reports__description-self-service',
        subscription: selfServiceSubscriptions
    },
    {
        id: 8,
        type: 'rcd',
        title: reportNames.rcd,
        path: COMMON_ROUTES.rcd,
        description: 'reports__description-rcd',
        subscription: allSubscriptions
    }
];

export const defaultReportUserGuideURL = 'reports__docs__url';

export const getReportTranslation = (reportType) => {
    if (reportUserGuides.has(reportType) === false) {
        return false;
    }

    return reportUserGuides.get(reportType);
};
