export const adminUsersSelectSearchOptions = [
    {
        value: 'User_FirstName',
        text: 'common__user__first-name',
        placeholder: 'users__search-placeholder--first-name',
    },
    {
        value: 'User_LastName',
        text: 'common__user__last-name',
        placeholder: 'users__search-placeholder--last-name',
    },
    {
        value: 'User_EmailAddress',
        text: 'common__user__username',
        placeholder: 'users__search-placeholder--username',
    },
    {
        value: 'Company_Name',
        text: 'common__company__name',
        placeholder: 'users__search-placeholder--company-name',
    },
    {
        value: '',
        text: 'common__all',
        placeholder: 'common__search-placeholder--all',
    },
];
