import { sum } from 'ramda';

export const getFiltersCount = (availablefilters, gridFilters) => {
    const appliedFilters = availablefilters.map(({ property, items }) => {
        const filterItems = gridFilters[property];

        return items?.length === filterItems?.length ? 0 : 1;
    });

    return sum(appliedFilters);
};
