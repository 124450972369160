/* eslint-disable react/prop-types */

import './ControlledCarousel.scss';
import '../../../../SmackTalk/SmackTalk.scss';
import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { CategorySlide } from '../CategorySlide';
import { splitEvery } from 'ramda';
const numberOfCategories = 4;
export const ControlledCarousel = ({ activeCategory, onCategoryChange, ...props }) => {
    const [chanks, setChanks] = useState([]);

    useEffect(() => {
        const categories =
            [].concat(props.categories)
                    .filter((el) => el['SmackTalkCategoryID'] <= 4); // remove "filtration" and "display:none" from scss when we need a carousel
        setChanks(splitEvery(numberOfCategories, categories).slice(0, 1)); // remove ".slice(0, 1)" and "display:none" from scss when we need a carousel
        if (props.categories) {
            onCategoryChange({});
        }
    }, [props.categories]);

    const onChangeActiveCategory = (category) => {
        onCategoryChange(category);
    };

    return (
        <Carousel
            interval={null}
            className="d-block smacktalk-categories-carousel"
            slide={false}
            {...props}
        >
            {chanks.map((chank, i) => (
                <Carousel.Item key={`chank-${i}`}>
                    <div className="smacktalk-categories-carousel-item">
                        {chank.map((category, j) => (
                            <CategorySlide
                                key={`category-${j}`}
                                category={category}
                                activeCategory={activeCategory}
                                onChangeActiveCategory={onChangeActiveCategory}
                            />
                        ))}
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};
