import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash';
import axios from 'axios';
import './TypeAhead.css';
function getRegexAnywhere(val) {
	return new RegExp(`${val}`, 'i');
}

function sortMatches(matchesArr, query, param) {
	return matchesArr.sort ( (a,b) => {
		const matches1 = _.startsWith(a[param], query)
		const matches2 = _.startsWith(b[param], query)
		if (!matches1 && matches2)
			return true
		else if (matches1 && !matches2)
			return false
		return a[param] < b[param] ? -1 : +(a[param] > b[param])
	}).slice(0,25)
}

function escapeRegexCharacters(str) {
	return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

class TypeAheadInput extends React.Component {
	constructor() {
		super();

		this.state = {
			suggestions: [],
			selections: [],
			isLoading: false
		};

		this.cache = {
			suggestions: this.state.suggestions
		};
	}

	getMatchingUser(value, data, param) {
		const escapedValue = escapeRegexCharacters(value.trim());
		if (escapedValue === '') {
			return [];
		}
		const regex = getRegexAnywhere(escapedValue);
		const suggestions = data.filter(user => regex.test(user[param]));
		if (suggestions.length === 0 && this.props.customMessage) {
			return [
			  { isAddNew: true }
			];
		  }
		return suggestions;
	}

	loadSuggestions(value) {
		if (this.cache.suggestions.length)
			this.setState({
				isLoading: true,
				suggestions: sortMatches(this.getMatchingUser(value, this.cache.suggestions, this.props.displayKeys[1]), value, this.props.displayKeys[1])
			})
		else {
			this.setState({
				isLoading: true,
				suggestions: []
			})
		}



		if (this.props.cacheValue){
			this.cache.suggestions = [...this.cache.suggestions, ...this.props.data]
		} else {
			this.cache.suggestions = this.props.data
		}
				this.cache.suggestions = _.uniqBy(this.cache.suggestions, (s) => s[this.props.displayKeys[1]])
				this.setState({
					isLoading: false,
					suggestions: sortMatches(this.getMatchingUser(value, this.cache.suggestions, this.props.displayKeys[1]), value, this.props.displayKeys[1])
				})
	}

	onChange = (event, {
		newValue
	}) => {
        this.props.onChange(newValue)
	};

	onBlur = () => this.props.onBlur && this.props.onBlur();

	onFocus = () => this.props.onFocus && this.props.onFocus();

	onSuggestionsFetchRequested = ({
		value
	}) => {
		this.loadSuggestions(value);
	};

	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: []
		});
	};

	onSuggestionSelected = (evt, {
		suggestion
	}) => {
		if (!suggestion.isAddNew) {
			this.props.setSelection(suggestion)
			this.setState({
				value: suggestion[this.props.displayKeys[1]],
				selections: [suggestion]
			});
		} else {
			// this.props.setSelection(suggestion)
			this.setState({
				value: this.state.value

			});
		}
        
	};

	getSuggestionValue(suggestion) {
		if (suggestion.isAddNew) {
			return '';
		}

		return suggestion[this.props.displayKeys[1]];
	}

	renderSuggestion(suggestion, {
		query
	}) {
		if (suggestion.isAddNew && this.props.customMessage) {
			return  (
			  <span className="type-ahead-custom-message">
				{this.props.customMessage}
			  </span>
			);
		
		}

		if (suggestion.isAddNew) {
			return null
		}
		const regexp = new RegExp(`^(.*?)(${escapeRegexCharacters(query)})(.*)$`, 'i')
		let matches = this.getSuggestionValue(suggestion).match(regexp);
		if (!matches || matches.length < 3) return null;
		if (matches) {
			matches.shift();
			matches[0] = <b key={0}>{matches[0]}</b>;
			matches[2] = <b key={2}>{matches[2]}</b>;
		} else {
			matches = suggestion[this.props.displayKeys[0]];
		}
		return (
			<span className="suggestion">
			{suggestion[this.props.displayKeys[0]] && 
				<em className="username">{suggestion[this.props.displayKeys[0]]}</em>
			}
			<span className="name">{matches}</span>
			</span>
		);
	}

	render() {
		const {
			suggestions,
			isLoading
		} = this.state;
		const inputProps = {
			placeholder: this.props.placeholder,
            value: this.props.value,
			onChange: this.onChange,
			onBlur: this.onBlur,
			onFocus: this.onFocus,
			disabled: this.props.disabled
		};

		return (
			<div className="wrapper">
				<Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue.bind(this)}
          renderSuggestion={this.renderSuggestion.bind(this)}
			onSuggestionSelected={this.onSuggestionSelected}
          inputProps={inputProps} />
      </div>
		);
	}
}


 
export default TypeAheadInput;