import { DateLib } from '@hme-cloud/utility-common';

export const validateRunDate = (value, { initialUpgradeDate, minDate, maxDate }) => {
    const date = new DateLib(new Date(value));

    if (!date.isValid()) {
        return 'common__validation--date';
    }

    // don't validate if the date is equal to initial
    if (initialUpgradeDate && initialUpgradeDate?.isValid() && value === new DateLib(initialUpgradeDate).format(DateLib.FORMAT_TYPES.DATE_SELECTOR_DEFAULT)) {
        return '';
    }

    const minDateDay = minDate ? new DateLib(minDate).startOfDay() : null;
    const maxDateDay = maxDate ? new DateLib(maxDate).startOfDay() : null;

    if ((minDateDay && date.isBefore(minDateDay)) || (maxDateDay && date.isAfter(maxDateDay))) {
        return 'device-upgrades__section__form__validation--date-today';
    }

    return '';
};
