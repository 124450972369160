import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from 'library/Section';
import { StoreSearchForm } from '../StoreSearchForm';
import { StoreDevices } from '../StoreDevices';
import { ResultCount } from '../ResultCount';

import './SourceDeviceSection.css';

const sourceDevicesDeviceFilter = {
    deviceIsActive: 1,
};

const sourceDevicesStoreFilter = {
    limit: 100,
    deviceIsActive: 1,
};

export const SourceDeviceSection = ({
    sourceStore,
    sourceDevice,
    loadDevices,
    onStoreSelection,
    onDeviceSelection,
    onLoadDevices,
    checkSelected,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [storeDevices, setStoreDevices] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        if (sourceStore) {
            loadDevices({
                deviceStoreID: sourceStore.Store_ID,
                setIsLoading,
                setStoreDevices,
                deviceFilter: sourceDevicesDeviceFilter,
                onLoadDevices,
                t,
            });
        }
    }, [sourceStore]);

    return (
        <div className="merge-devices-source-device-section">
            <Section number={1} title={'merge_devices__source-device__title--simple'} disabled={false}>
                <StoreSearchForm onStoreSelection={onStoreSelection} storeFilter={sourceDevicesStoreFilter} />
                {storeDevices.length > 0 && <ResultCount count={storeDevices.length} />}
                {sourceStore && (
                    <StoreDevices
                        selectedDevice={sourceDevice}
                        storeDevices={storeDevices}
                        isLoading={isLoading}
                        onDeviceSelection={onDeviceSelection}
                        checkSelected={checkSelected}
                    />
                )}
            </Section>
        </div>
    );
};
