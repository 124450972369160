import React, { useState, useEffect } from 'react';
import { cond, T } from 'ramda';

import { Row } from '../Row';

const expandedHeaders = [{
    text: '',
    property: 'content',
    path: ['content'],
    className: 'hme-grid-expanded-content'
}];

export const ExpandedContent = cond([
    [({ expanded }) => expanded, ({ data, hovered, onHoverChange, odd, ExpandComponent }) => {
        const [content, setContent] = useState({
            content: null
        });

        useEffect(() => {
            setContent({
                content: <ExpandComponent data={data} />
            });
        }, [data, ExpandComponent]);

        return (
            <Row
                data={content}
                expandHidden
                expandable
                headers={expandedHeaders}
                hovered={hovered}
                clickable={false}
                onHoverChange={onHoverChange}
                odd={odd}
                className='hme-grid-expanded-row'
            />
        );
    }],
    [T, () => null]
]);
