import { useState, useEffect } from 'react';

import { useLoadNotifications, defaultNotifications } from './useLoadNotifications';
import { useGlobalState } from 'globalState';
import { useProviderData } from './useProviderData';
import { useMarkReadNotification } from './useMarkReadNotification';

export const usePublic = () => {
    const { unreadNotifications } = useGlobalState();

    const {
        isNotificationsLoading,
        notifications,
        loadNotifications,
    } = useLoadNotifications({ changeHasUnread: unreadNotifications.changeHasUnread });

    const [showNotifications, setShownNotifications] = useState(notifications);

    const onAfterRead = useMarkReadNotification({ setNotifications: setShownNotifications });

    const providerData = useProviderData({
        loadNotifications,
        onAfterRead,
        isLoaded: notifications !== defaultNotifications,
        notifications
    });

    useEffect(() => {
        setShownNotifications(notifications);
    }, [notifications]);

    useEffect(() => {
        if (!showNotifications.length) {
            return;
        }

        unreadNotifications.changeHasUnread(!!showNotifications.find(({ readDate }) => !readDate));
    }, [showNotifications]);

    return {
        state: {
            isNotificationsLoading,
            notifications: showNotifications,
            isLoadingModalShown: providerData.isLoading,
        },
        providerData,
    };
};
