import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './MenuItem.scss';

export const MenuItem = ({
    className,
    tKey,
    tOptions,
    icon,
    url,
    items,
    name,
    isActive,
    onClick,
    translateUrl,
    openBlank
}) => {
    const { t } = useTranslation();

    const onClickHandler = useCallback(() => {
        if ((!items || items.length === 0) && url) {
            if (translateUrl) {
                url = t(url, tOptions);
            }
            if (openBlank) {
                window.open(url,'_blank');
            } else {
                window.location = url;
            }

            return;
        }

        onClick && onClick(name);
    }, [items, url, name, onClick]);

    return (
        <div className={classNames('hme-menu-item', className, {
            'hme-menu-item--active': isActive,
        })} onClick={onClickHandler}>
            {icon && <div className='hme-menu-item__icon'>{icon}</div>}
            <div className='hme-menu-item__text'>{t(tKey, tOptions)}</div>
        </div>
    );
};
