import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';
import { TextareaInput } from 'library/TextareaInput';
import { InputComponent } from 'components/Inputs';
import { roleProp } from '../types/roleProps';

import './General.scss';

const inputVariants = ['label-inside-mobile-only'];

export const General = ({ role, isOwner, onRoleChange }) => {
    const { t } = useTranslation();

    const onNameChange = useCallback((name) => {
        onRoleChange && onRoleChange({
            ...role,
            name,
        });
    }, [role, onRoleChange]);

    const onDescriptionChange = useCallback((description) => {
        onRoleChange && onRoleChange({
            ...role,
            description,
        });
    }, [role, onRoleChange]);

    return (
        <div className='hme-view-role__general'>
            <Title variants={['block']}>{t('common__general')}</Title>
            <InputComponent
                label='add-role__label--role-name'
                variants={inputVariants}
                value={role.name}
                isRequired={true}
                isDisabled={isOwner}
                onChange={onNameChange}
            />
            <TextareaInput
                isDisabled={isOwner}
                label='common__description'
                value={role.description}
                onChange={onDescriptionChange}
            />
        </div>
    );
};

General.propTypes = {
    role: roleProp,
    isOwner: PropTypes.bool,
    onRoleChange: PropTypes.func,
};
