import React from 'react';
import { compose } from 'ramda';
import { useTranslation } from 'react-i18next';

import { TabPanel, Tab, useTabs } from 'library/TabPanel';
import { NotificationsList } from 'library/NotificationsList';
import { PARTNER_ROUTES } from 'constants/routes';
import { withHMELayout } from 'HOCs/withHMELayout';
import authenticate from 'components/Security/Authentication';

import { PartnerPersonalInfo } from '../Profile';

const tabPanelVariants = ['subheader'];

const fullTabRoutes = {
    [PARTNER_ROUTES.account]: `/${PARTNER_ROUTES.accountInfoFull}`
};

const AccountComponent = () => {
    const { t } = useTranslation();
    const { activeTab, handleActiveTabChange } = useTabs(PARTNER_ROUTES.account, fullTabRoutes);

    return (
        <>
            <TabPanel
                className="hme-partner-account"
                activeTab={activeTab}
                variants={tabPanelVariants}
                onActiveTabChange={handleActiveTabChange}
            >
                <Tab text={t('partner__account-info')} value={PARTNER_ROUTES.account}>
                    <PartnerPersonalInfo />
                </Tab>
            </TabPanel>

            <NotificationsList />
        </>
    );
};

export const PartnerAccountPages = compose(withHMELayout(), authenticate)(AccountComponent);
