export const announcementItems = {
    // Notifications part
    SMACKTALK: 'SMACKTALK',
    WINNER_BANNER: 'WINNERBANNER',
    ROUND_STARTING_BANNER: 'ROUNDSTARTINGBANNER',
    NOTIFICATION_FREQUENCY: 'NOTIFICATIONFREQUENCY',
    CONTEST_STARTING_BANNER: 'CONTESTSTARTINGBANNER',
    CONTEXTUAL_FEEDBACK: 'CONTEXTUALFEEDBACK',

    // Presentation Pages part
    AWARDPAGE: 'AWARDPAGE',
    CONTEST_WINNER: 'CONTESTWINNER'
};

export const formItemParts = {
    PERIOD: 'PERIOD',
    INTERVAL: 'INTERVAL',
    FEEDBACK_INTERVAL: 'FEEDBACK_INTERVAL'
};

export const formPartTypes = {
    NOTIFICATIONS: 'NOTIFICATIONS',
    PRESENTATION_PAGES: 'PRESENTATION_PAGES'
};

export const notificationDurationTypes = [
    announcementItems.SMACKTALK,
    announcementItems.WINNER_BANNER,
    announcementItems.ROUND_STARTING_BANNER,
    announcementItems.CONTEST_STARTING_BANNER
];

export const FORM_SECTIONS = [
    {
        type: formPartTypes.NOTIFICATIONS,
        title: 'announcement-settings__notifications',
        items: [
            announcementItems.SMACKTALK,
            announcementItems.WINNER_BANNER,
            announcementItems.CONTEST_STARTING_BANNER,
            announcementItems.ROUND_STARTING_BANNER,
            announcementItems.NOTIFICATION_FREQUENCY,
            announcementItems.CONTEXTUAL_FEEDBACK
        ]
    },
    {
        type: formPartTypes.PRESENTATION_PAGES,
        title: 'announcement-settings__presentation-pages',
        items: [announcementItems.AWARDPAGE, announcementItems.CONTEST_WINNER]
    }
];

export const timeLabelTranslations = new Map([
    ['second', 'announcement-settings__period-option--second'],
    ['minute', 'announcement-settings__period-option--minute'],
    ['hour', 'announcement-settings__period-option--hour']
]);

export const errorsTranslations = new Map([
    ['frequency-awards-equal', 'announcement-settings__validation--same-frequency'],
    ['out-of-range', 'announcement-settings__validation--out-of-range'],
    ['frequency-duration', 'announcement-settings__validation--frequency-duration']
]);
