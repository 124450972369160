import React from 'react';
import { useTranslation } from 'react-i18next';

import { Title as LibraryTitle } from 'library/Title';

export const Title = () => {
    const { t } = useTranslation();

    return <LibraryTitle>
        {t('profile__account__change-password')}
    </LibraryTitle>;
};
