import { Constant } from '@hme-cloud/utility-common';
const { BuildVersions, DeviceType } = Constant;

export const findMaxVersion = (versions) => {
    if (!Array.isArray(versions) || versions.length === 0) {
        return '';
    }

    return versions.reduce((max, version) => {
        return BuildVersions.semverCompare(version, max, 'gt') ? version : max;
    }, versions[0]);
};