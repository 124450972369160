export const weekDays = [
    {
        value: 0,
        text: 'device-upgrades__section__form--sunday'
    },
    {
        value: 1,
        text: 'device-upgrades__section__form--monday'
    },
    {
        value: 2,
        text: 'device-upgrades__section__form--tuesday'
    },
    {
        value: 3,
        text: 'device-upgrades__section__form--wednesday'
    },
    {
        value: 4,
        text: 'device-upgrades__section__form--thursday'
    },
    {
        value: 5,
        text: 'device-upgrades__section__form--friday'
    },
    {
        value: 6,
        text: 'device-upgrades__section__form--saturday'
    }
];

export const daysOfWeekNames = [
    'common__sunday',
    'common__monday',
    'common__tuesday',
    'common__wednesday',
    'common__thursday',
    'common__friday',
    'common__saturday'
];
