import React, { useCallback, useRef } from 'react';
import { cond, T } from 'ramda';
import { useTranslation } from 'react-i18next';

import { useClickOutSide } from 'hooks/useClickOutSide';
import { Checkbox, CHECKBOX_VALUES } from 'library/Checkbox';
import { Label } from 'library/Label';

import './Popup.scss';

const defaultItems = [];
const defaultSelection = [];

const getAllSelectedValue = (items, selection) => {
    if (selection.length === 0) {
        return CHECKBOX_VALUES.UNCHECKED;
    }

    const isAllChecked = items.every(({ value }) => selection.includes(value));

    return isAllChecked ? CHECKBOX_VALUES.CHECKED : CHECKBOX_VALUES.PARTIAL;
}

const PopupComponent = ({
    items = defaultItems,
    selection = defaultSelection,
    allText,
    searchValue,
    noItemsFoundText,
    onSearchItemsChange,
    SearchItemsComponent,
    onHide,
    onChange,
}) => {
    const ref = useRef(null);
    const { t } = useTranslation();

    const outSideClickHandler = useCallback(() => {
        onHide && onHide();
    }, [onHide]);

    useClickOutSide(ref, outSideClickHandler);

    return (
        <div className='hme-grid-filter-cell-popup' ref={ref}>
            <div className='hme-grid-filter-cell-popup-header'>
                <span>{t('common__show-me')}</span>
                <span className='hme-grid-filter-cell-popup-close' onClick={onHide}>×</span>
            </div>
            {
                SearchItemsComponent &&
                <div className='hme-grid-filter-cell-popup-search'>
                    <SearchItemsComponent value={searchValue} onChange={onSearchItemsChange} />
                </div>
            }
            <div className='hme-grid-filter-cell-popup-body'>
                {/* Check if 'All' checkbox required*/}
                {
                    items.length ?
                        <div className='hme-grid-filter-cell-popup-row'>
                            <Checkbox
                                label={allText || t('common__all')}
                                checked={getAllSelectedValue(items, selection)}
                                onChange={onChange && (checked => {
                                    const newSelection = checked ? items.map(({ value }) => value) : [];
                                    onChange(newSelection);
                                })}
                            />
                        </div> :
                        <Label className='hme-grid-filter-cell-popup-no-items-found'>{noItemsFoundText}</Label>
                }
                {/* Get details for individual items */}
                {
                    items.map(({ text, value }) => (
                        <div
                            className={`
                                hme-grid-filter-cell-popup-row
                                hme-grid-filter-cell-popup-item
                            `}
                            key={value}
                        >
                            <Checkbox
                                label={text}
                                checked={selection.includes(value)}
                                onChange={onChange && ((checked, e) => {
                                    // May be make it in Checkbox by default
                                    e.stopPropagation();

                                    const newSelection = checked ?
                                        [...selection, value] :
                                        selection.filter(selectedItem => selectedItem !== value);

                                    onChange(newSelection);
                                })}
                            />
                            <span className='hme-grid-filter-cell-popup-only-label'>
                                <span onClick={onChange && (e => {
                                    e.stopPropagation();
                                    onChange([value]);
                                })}>{t('common__only')}</span>
                            </span>
                        </div>
                    ))
                }
            </div>

        </div>
    );
};

export const Popup = cond([
    [({ show }) => show, PopupComponent],
    [T, () => <div></div>]
]);
