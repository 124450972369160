import PropTypes from 'prop-types';

const columnType = PropTypes.shape({
    column: PropTypes.arrayOf(PropTypes.shape({
        property: PropTypes.string,
        text: PropTypes.string
    }))
});

export const mobileHeadersType = PropTypes.arrayOf(columnType);
