import React from 'react';
import { Section } from 'library/Section';
import { AccountOwnerInfo } from './AccountOwnerInfo';
import { AccountSettings } from './AccountSettings';

import './AccountSection.css';

export const AccountSection = ({
    accountOwnerInfoInitialValues,
    accountSettingsInitialValues,
    formErrors,
    onAccountOwnerInfoChange,
    onAccountSettingsChange,
    isReadOnly
}) => {
    return (
        <Section className="hme-section-first hme-section-sm">
            <AccountOwnerInfo
                initialValues={accountOwnerInfoInitialValues}
                formErrors={formErrors}
                onChange={onAccountOwnerInfoChange}
                isReadOnly={isReadOnly}
            />
            <AccountSettings
                initialValues={accountSettingsInitialValues}
                formErrors={formErrors}
                onChange={onAccountSettingsChange}
                isReadOnly={isReadOnly}
            />
        </Section>
    );
};
