import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PureButton } from 'library/PureButton';
import { Label } from 'library/Label';
import { ReactComponent as CloseIcon } from 'assets/icons/IconCloseNavigation.svg';

import './SidebarOverlay.scss';

export const SidebarOverlay = ({
    className,
    title = '',
    icon = null,
    isShown = false,
    toggleShown,
    variants = [],
    children = '',
}) => {
    const [isSidebarShown, setIsSidebarShown] = useState(false);

    const { t } = useTranslation();

    // show sliding sidebar column with delay after overlay wrapper is shown
    useEffect(() => {
        setIsSidebarShown(isShown);
    }, [isShown]);

    const onClose = () => {
        setIsSidebarShown(false);

        // wait for slide sidebar animation to finish
        setTimeout(() => toggleShown(false), 200);
    };

    return (
        <div
            className={classNames(
                'hme-sidebar-overlay',
                variants.map((variant) => `hme-sidebar-overlay--${variant}-variant`),
                className,
                {
                    'hme-sidebar-overlay--active': isShown,
                },
            )}
        >
            <div className="hme-sidebar-overlay__overlay" onClick={onClose}></div>
            <div
                className={classNames('hme-sidebar-overlay__sidebar', {
                    'hme-sidebar-overlay__sidebar--active': isSidebarShown,
                })}
            >
                <div className="hme-sidebar-overlay__sidebar__container">
                    <div className="hme-sidebar-overlay__sidebar__wrapper">
                        {title && (
                            <div className="hme-sidebar-overlay__sidebar__header">
                                {icon && <div className="hme-sidebar-overlay__sidebar__header-icon">{icon}</div>}
                                <Label>{t(title)}</Label>
                            </div>
                        )}
                        {isShown && <div className="hme-sidebar-overlay__sidebar__content">{children}</div>}
                    </div>
                </div>
                <PureButton className="hme-sidebar-overlay__sidebar__close-btn" onClick={onClose}>
                    <CloseIcon className="hme-sidebar-overlay__sidebar__close-icon" />
                </PureButton>
            </div>
        </div>
    );
};

// set prop types
SidebarOverlay.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.node,
    isShown: PropTypes.bool,
    toggleShown: PropTypes.func.isRequired,
    variants: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node.isRequired,
};
