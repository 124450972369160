import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccount } from 'hooks/account';
import { validEmailRegexp } from 'constants/email';
import { mobileRegExp, phoneRegExp } from 'constants/phoneNumber';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { updateUser } from 'services/Partner';
import { useAsync, ASYNC_STATUS } from 'hooks/useAsync';
import { trimValues } from 'helpers';
import { equals } from 'ramda';
import { trimFields } from 'pages/Partners/Common/constants';

const MIN_REQUIRED_LENGTH = /^.{3,}$/u;
const MIN_REQUIRED_LENGTH_LAST_NAME = /^.+$/u;
const INITIAL_PROFILE_DATA = {
    uuId: '',
    UserID: 0,
    firstName: '',
    lastName: '',
    roleName: '',
    userEmail: '',
    officeNumber: null,
    mobilePhone: '',
    isActive: false,
    isVerified: false,
    resetPassword: false,
    IsOwner: false,
    AccountTOSAgree: false,
    status: false
};

const trimValue = (val) => val?.trim?.() || val;

export const useProfileData = () => {
    const { t } = useTranslation();
    const { run: runUpdateAccount, status: updateAccountStatus } = useAsync();
    const { fetchedAccountData, isLoading, loadAccount, accountData, setAccountData } = useAccount(INITIAL_PROFILE_DATA);

    const [accountDataErrors, setAccountDataErrors] = useState({});

    const isAccountDataModified = useMemo(() => {
        if (fetchedAccountData) {
            fetchedAccountData.changeEmail = fetchedAccountData.userEmail;
        }
        return !equals(trimValues(accountData, trimFields), trimValues(fetchedAccountData, trimFields));
    }, [accountData, fetchedAccountData]);

    const handleSetAccountDataErrors = useCallback(() => {
        if (!accountData) return;

        const newErrors = {};

        const userEmail = trimValue(accountData.userEmail);
        const firstName = trimValue(accountData.firstName);
        const lastName = trimValue(accountData.lastName);
        const officeNumber = trimValue(accountData.officeNumber);
        const mobilePhone = trimValue(accountData.mobilePhone);

        if (!validEmailRegexp.test(userEmail)) {
            newErrors.userEmail = t('common__error--invalid-email');
        }

        if (!MIN_REQUIRED_LENGTH.test(firstName)) {
            newErrors.firstName = t('add-user__error--first-name-empty');
        }

        if (!MIN_REQUIRED_LENGTH_LAST_NAME.test(lastName)) {
            newErrors.lastName = t('add-user__error--last-name-empty');
        }

        if (officeNumber && !phoneRegExp.test(officeNumber)) {
            newErrors.officeNumber = t('common__error--invalid-mobile-number');
        }

        if (mobilePhone && !mobileRegExp.test(mobilePhone)) {
            newErrors.mobilePhone = t('common__error--invalid-phone-number');
        }

        setAccountDataErrors(newErrors);
    }, [accountData, t]);

    const handleAccountDataUpdate = useCallback(({ target }) => {
        setAccountData((currentData) => ({ ...currentData, [target.name]: target.value }));
    }, []);

    const updateAccountData = useCallback(async () => {
        runUpdateAccount(
                updateUser({
                    email: accountData.userEmail,
                    firstName: accountData.firstName,
                    lastName: accountData.lastName,
                    mobileNumber: accountData.mobilePhone,
                    officeNumber: accountData.officeNumber
                })
                        .then(() => {
                            addSuccessNotification('common__updated--success', { autoClose: 5000 });
                            loadAccount();
                        })
                        .catch(() => {
                            addErrorNotification('common__error--internal-server', { autoClose: 5000 });
                        }));
    }, [runUpdateAccount, accountData]);

    const updateAccountIsLoading = updateAccountStatus === ASYNC_STATUS.PENDING;

    useEffect(() => {
        loadAccount().catch(() => {
            addErrorNotification('common__error--internal-server');
        });
    }, [loadAccount, t]);

    useEffect(handleSetAccountDataErrors, [accountData]);

    return {
        isLoading,
        fetchedAccountData,
        accountData,
        handleAccountDataUpdate,
        updateAccountData,
        updateAccountIsLoading,
        accountDataErrors,
        isAccountDataModified
    };
};
