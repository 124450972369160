import React, { useState, useEffect, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Title as LibTitle } from 'library/Title';
import { prepareLinkedDevices } from 'pages/SystemStatus/AdminZOOM/DeviceStatus/DevicesSection/helpers';
import { ModelName } from 'pages/SystemStatus/Common/ModelName';
import { DEVICE_ACTIONS } from 'pages/SystemStatus/Common/constants';
import getDeviceType from 'helpers/Device/getDeviceType';
import { getAvailableDeviceUpgradeVersions } from '../../../Common/helpers';
import { TileDeviceList } from '../TileDeviceList';
import { Constant } from '@hme-cloud/utility-common';

const { BuildVersions } = Constant;

const prepareDevices = ({
    isNitro,
    device,
    availableDeviceUpgradeVersions,
    onLinkedDeviceReconnectTrigger,
    onLinkedDeviceRebootTrigger
}) => {
    const deviceType = getDeviceType(device);

    let latestAvailableDeviceUpgradeVersions = '';
    if (availableDeviceUpgradeVersions.length > 0) {
        latestAvailableDeviceUpgradeVersions =
            availableDeviceUpgradeVersions[availableDeviceUpgradeVersions.length - 1];
    }

    const isOnLatestVersion =
        !latestAvailableDeviceUpgradeVersions.Version ||
        (device.mainVersion &&
            BuildVersions.semverGte(
                    BuildVersions.formatVersion(device.mainVersion) || '0.0.0',
                    latestAvailableDeviceUpgradeVersions.Version
            )
        );

    const actionsComponent = '';

    const preparedDevice = {
        modelName: <ModelName device={device} modelName={deviceType.hardwareName} />,
        serialNumber: device.Device_SerialNumber,
        macAddress: device.Device_MacAddress,
        mainVersion: device.Device_MainVersion,
        latestFirmwareAvailable: latestAvailableDeviceUpgradeVersions.Version,
        onLatestVersion: isOnLatestVersion ? <Trans i18nKey="common__yes" /> : <Trans i18nKey="common__no" />,
        actionsComponent
    };

    return [
        preparedDevice,
        ...(isNitro ? prepareLinkedDevices({ device, onLinkedDeviceRebootTrigger, onLinkedDeviceReconnectTrigger }) : [])
    ];
};

const getGridOptions = ({ isNitro, linkedDevices = [] }) => ([
    {
        text: 'common__device',
        flex: 0.7,
        property: 'modelName',
        className: 'hme-grid-model-name',
        headerClassName: 'hme-grid-model-name'
    },
    {
        text: 'common__device__serial-number',
        flex: 1,
        property: 'serialNumber',
        tabletScreenText: 'common__device__serial-number--text'
    },
    {
        text: 'system-status__mac-address',
        flex: 1.2,
        property: 'macAddress'
    },
    {
        text: 'system-status__devices-grid__current-firmware',
        flex: 1.2,
        property: 'mainVersion',
        className: 'hme-grid-centered-cell',
        headerClassName: 'hme-grid-centered-cell'
    },
    {
        text: 'system-status__devices-grid__latest-firmware-available',
        flex: 1.5,
        property: 'latestFirmwareAvailable',
        className: 'hme-grid-centered-cell',
        headerClassName: 'hme-grid-centered-cell'
    },
    {
        text: 'system-status__devices-grid__on-latest-version',
        flex: 1.2,
        property: 'onLatestVersion',
        className: 'hme-grid-centered-cell hme-grid-cell--uppercase',
        headerClassName: 'hme-grid-centered-cell'
    },
    linkedDevices.length > 0 && isNitro ? {
        text: 'common__actions',
        flex: 1.4,
        property: 'actionsComponent',
        headerClassName: 'hme-grid-centered-cell hme-upgrade-actions',
        className: 'hme-grid-cell-with-outside-content hme-grid-centered-cell hme-upgrade-actions',
    } : null
]).filter(Boolean);

const getMobileGridOptions = (gridOptions) => ([
    {
        column: gridOptions.filter(({ property }) => property !== 'modelName') // removes "model-name" field for non desktop tile-list
    }
]);

export const useDevicesGrid = ({
    isNitro,
    device,
    deviceSettings,
    deviceUpgradeVersions,
    deviceScheduledUpgrade,
    onLinkedDeviceReconnect,
    onLinkedDeviceReboot
}) => {
    const { t } = useTranslation();
    const [rows, setRows] = useState([]);
    const [isConfirmShown, setConfirmShown] = useState(false);
    const [confirmWindowDetails, setConfirmWindowDetails] = useState({});

    const getActionDetails = useCallback(
        (action, { linkedDevice, device }) => {
            const actionDetails = {
                [DEVICE_ACTIONS.RECONNECT]: {
                    message: t('system-status__action__reconnect--confirm-message'),
                    handler: () => onLinkedDeviceReconnect(linkedDevice, device),
                },
                [DEVICE_ACTIONS.REBOOT]: {
                    message: t('system-status__action__reboot--confirm-message'),
                    handler: () => onLinkedDeviceReboot(linkedDevice, device),
                },
            };

            return actionDetails[action];
        },
        [onLinkedDeviceReconnect, onLinkedDeviceReboot],
    );

    const onConfirmHide = useCallback(() => {
        setConfirmShown(false);
    }, []);

    const showConfirm = useCallback(
        (action, data) => {
            setConfirmWindowDetails(getActionDetails(action, data));
            setConfirmShown(true);
        },
        [getActionDetails],
    );

    const onLinkedDeviceReconnectTrigger = useCallback(
        (data) => showConfirm(DEVICE_ACTIONS.RECONNECT, data),
        [showConfirm],
    );
    const onLinkedDeviceRebootTrigger = useCallback((data) => showConfirm(DEVICE_ACTIONS.REBOOT, data), [showConfirm]);

    useEffect(() => {
        if (!device || !deviceSettings || !deviceScheduledUpgrade) return;

        const availableDeviceUpgradeVersions = getAvailableDeviceUpgradeVersions({ device, deviceUpgradeVersions });

        const preparedDevices = prepareDevices({
            isNitro,
            device,
            availableDeviceUpgradeVersions,
            onLinkedDeviceReconnectTrigger,
            onLinkedDeviceRebootTrigger
        });

        setRows(preparedDevices);
    }, [isNitro, device, deviceSettings, deviceScheduledUpgrade, onLinkedDeviceReconnectTrigger, onLinkedDeviceRebootTrigger]);

    const Title = <LibTitle><Trans i18nKey="system-status__title__system-devices" /></LibTitle>;

    const headers = getGridOptions({ isNitro, linkedDevices: device?.LinkedDevices });
    const mobileHeaders = getMobileGridOptions(headers);

    return {
        Title,
        rows,
        rowKey: 'serialNumber',
        headers,
        mobileHeaders,
        tileProps: {
            ListItemComponent: TileDeviceList
        },
        isConfirmShown,
        confirmWindowDetails,
        onConfirmHide
    };
};
