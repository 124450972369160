import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LEADERBOARD_INVITE_STATUS } from 'constants/participant';
import { InfoBox, VARINATS as InfoBoxVariants, SvgInfoIcon } from 'library/InfoBox';
import { Separator, SEPARATOR_VARIANTS } from 'library/Separator';
import { Button, BUTTON_VARIANTS } from 'library/Button';
import { useGlobalState } from 'globalState';

import { BaseNotification } from '../BaseNotification';
import { useNotificationsCenterContext } from '../../../Context';

import './LeaderboardInvite.scss';

const infoBoxVariants = [InfoBoxVariants.INFO, InfoBoxVariants.FLEX];
const acceptButtonVariants = [BUTTON_VARIANTS.ACCEPT];
const declineButtonVariants = [BUTTON_VARIANTS.DECLINE];
const separatorVariants = [];
const mobileSeparatorVariants = [SEPARATOR_VARIANTS.HORIZONTAL];

const ExpandedContet = ({ participantId, invitor, status }) => {
    const { t } = useTranslation();
    const { screenType } = useGlobalState();

    const { leaderboardInvite } = useNotificationsCenterContext();

    const {
        onAcceptClick,
        onDeclineClick,
    } = leaderboardInvite;

    const onAccept = useCallback(() => {
        onAcceptClick(participantId);
    }, [onAcceptClick, participantId]);

    const onDecline = useCallback(() => {
        onDeclineClick(participantId);
    }, [onDeclineClick, participantId]);

    return (
        <>
            <InfoBox variants={infoBoxVariants} message={t('notifications-center__notification__expanded--leaderboard-invite__info', {
                invitor
            })} Icon={SvgInfoIcon} />
            <div className='hme-notifications-center__notification--leaderboard-invite__options'>
                <div className='hme-notifications-center__notification--leaderboard-invite__option hme-notifications-center__notification--leaderboard-invite__option--accept'>
                    <div className='hme-notifications-center__notification--leaderboard-invite__option__message'>
                        {t('notifications-center__notification__expanded--leaderboard-invite__message--accept', { invitor })}
                    </div>
                    <Button
                        className='hme-notifications-center__notification--leaderboard-invite__option__button'
                        variants={acceptButtonVariants}
                        disabled={status !== LEADERBOARD_INVITE_STATUS.PENDING}
                        onClick={onAccept}
                    >
                        {t('notifications-center__notification__expanded--leaderboard-invite__button--accept')}
                    </Button>
                </div>
                <Separator
                    className='hme-notifications-center__notification--leaderboard-invite__separator--options'
                    variants={screenType.isMobile ? mobileSeparatorVariants : separatorVariants}
                />
                <div className='hme-notifications-center__notification--leaderboard-invite__option hme-notifications-center__notification--leaderboard-invite__option--decline'>
                    <div className='hme-notifications-center__notification--leaderboard-invite__option__message'>
                        {t('notifications-center__notification__expanded--leaderboard-invite__message--decline')}
                    </div>
                    <Button
                        className='hme-notifications-center__notification--leaderboard-invite__option__button'
                        variants={declineButtonVariants}
                        disabled={status !== LEADERBOARD_INVITE_STATUS.PENDING}
                        onClick={onDecline}
                    >
                        {t('notifications-center__notification__expanded--leaderboard-invite__button--decline')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export const LeaderboardInvite = ({ notification, ...props }) => {
    const { t } = useTranslation();

    const data = useMemo(() => JSON.parse(notification.data), [notification.data]);
    const expandedContent = useMemo(
        () => <ExpandedContet invitor={data.invitor} status={data.status} participantId={data.participantId} />,
        [data.invitor, data.status, data.participantId]
    );

    return (
        <BaseNotification
            id={notification.id}
            className='hme-notifications-center__notification--leaderboard-invite'
            title='notifications-center__notification__title--leaderboard-invite'
            sentDate={notification.sentDate}
            isRead={!!notification.readDate}
            isRemovable={data.status !== LEADERBOARD_INVITE_STATUS.PENDING}
            body={t('notifications-center__notification__body--leaderboard-invite', {
                invitor: data.invitor
            })}
            expandedContent={expandedContent}
            {...props}
        />
    )
};
