import { useEffect, useMemo, useState, useCallback } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getQueryString } from 'helpers/distributor';
import { getDistributorRolesList, removeDistributorRole } from 'services/Roles';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { accountOwner } from '../../Common/constants/accountOwner';
import { headers } from '../constants/headers';
import { mobileHeaders } from '../constants/mobileHeaders';
import { roleToRow } from '../../Common/helpers/roleToRow';
import { INIT_PAGINATION_VALUES } from 'pages/DistributorsAccounts/constants/initialPaginationValues';

const load = async (accountUuid, setRoles, setIsLoading, setRecordsTotal) => {
    setIsLoading(true);

    try {
        const roles = await getDistributorRolesList({ accountUuid });
        const updatedRoles = [
            {
                ...accountOwner,
                Company_Name: roles.length > 0 ? roles[0].Company_Name : ''
            },
            ...roles
        ];
        setRoles(updatedRoles);
        setRecordsTotal(updatedRoles.length);
    } catch (err) {
        setRoles([{
            ...accountOwner,
            Company_Name: ''
        }]);
        addErrorNotification(err.message);
    }

    setIsLoading(false);
};

const remove = async (accountUuid, role, t) => {
    if (role.iosAccountOwner) {
        return false;
    }

    try {
        await removeDistributorRole({
            accountUuid,
            role
        });

        addSuccessNotification('add-role__success--deleted');
    } catch (err) {
        addErrorNotification(t(err.message, err.tOptions));

        return false;
    }

    return true;
};

export const useDistributorStatus = () => {
    const { t } = useTranslation();
    const { search } = useLocation();
    const [queryParams] = useSearchParams();
    const queryString = useMemo(() => getQueryString(search), [search]);
    const [isLoading, setIsLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [rows, setRows] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [paginationData, setPaginationData] = useState(INIT_PAGINATION_VALUES);
    const [originRows, setOriginRows] = useState([]);
    const auuid = useMemo(() => queryParams.get('auuid'), [queryParams]);

    const addRoleUrl = useMemo(
            () => `/accounts/roles/add-role${queryString}`,
            [queryString]
    );

    const baseViewLink = useMemo(() => `/accounts/roles/edit-role${queryString}`, [queryString]);

    const onRemove = useCallback(async (role) => {
        setIsLoading(true);

        const isRemoved = await remove(auuid, role, t);

        if (!isRemoved) {
            setIsLoading(false);

            return;
        }

        load(auuid, setRoles, setIsLoading, setRecordsTotal);
    }, [auuid, t, setRoles, setIsLoading]);

    const getRolesPagination = useCallback((filteredAccounts, paginationData) => {
        const { pageNumber: page, pageSize: recordsPerPage } = paginationData;
        const startIdx = page * recordsPerPage;
        const endIdx = (page + 1) * recordsPerPage;
        return filteredAccounts.slice(startIdx, endIdx >= 0 ? endIdx : undefined);
    }, []);

    useEffect(() => {
        if (originRows.length) {
            const  newShowedRows  = getRolesPagination(
                    originRows,
                    paginationData
            );
            setRows(newShowedRows);
        }
    }, [originRows, paginationData, setRows]);

    const onPaginateChange = useCallback(({ page, recordsPerPage }) => {
        const newPaginationData = { pageSize: recordsPerPage, pageNumber: page };
        setPaginationData(newPaginationData);
    }, [setPaginationData]);

    useEffect(() => {
        load(auuid, setRoles, setIsLoading, setRecordsTotal);
    }, [auuid, setRoles, setIsLoading, setRecordsTotal]);

    useEffect(() => {
        const newRows = roles.map((role) => roleToRow(role));
        setOriginRows(newRows);
    }, [roles, setOriginRows]);

    return {
        hasCreateAccess: true,
        withAccountEmail: true,
        addRoleUrl,
        isLoading,
        rows,
        headers,
        baseViewLink,
        mobileHeaders,
        hasRemoveAccess: true,
        hasViewAccess: true,
        onRemove,
        recordsTotal,
        paginationData,
        onPaginateChange
    };
};
