import React, { useCallback } from 'react';
import { cond, T } from 'ramda';

import { BrandForm } from 'library/BrandForm';
import { Toggle } from 'library/Toggle';
import { AccountForm } from './AccountForm';

export const SEARCH_TYPES = {
    NONE: 'NONE',
    BRAND: 'BRAND',
    ACCOUNT: 'ACCOUNT',
};

const SearchForm = cond([
    [({ searchType }) => searchType === SEARCH_TYPES.BRAND, BrandForm],
    [({ searchType }) => searchType === SEARCH_TYPES.ACCOUNT, AccountForm],
    [T, () => null]
]);

const searchOptions = [{
    text: 'Account',
    value: SEARCH_TYPES.ACCOUNT,
}, {
    text: 'Brand',
    value: SEARCH_TYPES.BRAND,
}];

export const FiltersForm = ({
    brands,
    countries,
    filters,
    onFiltersChange,
}) => {
    const onSearchTypeChange = useCallback(newSearchType => {
        onFiltersChange({
            ...filters,
            searchType: newSearchType,
            storeBrandId: '',
            storeCountryId: '',
            account: null,
        });
    }, [filters, onFiltersChange]);

    return (
        <>
            <Toggle label='Search by' value={filters.searchType} onChange={onSearchTypeChange}>
                {searchOptions}
            </Toggle>
            <SearchForm
                searchType={filters.searchType}
                brands={brands}
                countries={countries}
                filters={filters}
                onFiltersChange={onFiltersChange}
            />
        </>
    );
};
