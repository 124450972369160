import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from 'components/Common/Grid';
import { useAnnouncementCustomerNotification, usePastCustomerNotification } from 'library/CustomerNotificationBanner/hooks';
import { Modal } from 'library/Modal';
import { Button } from 'library/Button';

import '../Tiles.css';

export const headers = [
    {
        text: 'common__date',
        property: 'lastEnabledDate'
    },
    {
        text: 'admin__customer-notification__type--announcement',
        property: 'translatedText',
        flex: 4
    }
];

const BUTTON_VARIANTS = ['transparent'];

export const AnnouncementTile = () => {
    const { text } = useAnnouncementCustomerNotification();
    const { pastNotifications } = usePastCustomerNotification();
    const { t } = useTranslation();

    const [isPastAnnouncementShown, setIsPastAnnouncementShown] = useState(false);

    const handlePastAnnouncementOpen = useCallback(() => {
        setIsPastAnnouncementShown(true);
    }, []);

    const handlePastAnnouncementClose = useCallback(() => {
        setIsPastAnnouncementShown(false);
    }, []);

    const defaultText = `<p>${t('welcome__tile--announcements__default-text')}</p>`;

    return (
        <div className="tile-box announcement">
            <div className="tile__box-supporting-container">
                <h3 className="tile__title">{t('welcome__tile--announcements__title')}</h3>

                <div dangerouslySetInnerHTML={{ __html: text || defaultText }} />

                {(pastNotifications || []).length > 0 && (
                    <>
                        <Button variants={BUTTON_VARIANTS} onClick={handlePastAnnouncementOpen}>
                            {t('welcome__tile--announcements__show-past')}
                        </Button>
                        <Modal
                            show={isPastAnnouncementShown}
                            title={t('welcome__tile--announcements__past')}
                            onHide={handlePastAnnouncementClose}
                            dialogClassName="tile__box-modal"
                        >
                            <Grid
                                rows={pastNotifications}
                                headers={headers}
                                noRecordsMessage={t('admin__customer-notification__list__grid__no-records')}
                                rowKey="customerNotificationID"
                            />
                        </Modal>
                    </>
                )}
            </div>
        </div>
    );
};
