import React from 'react';
import classNames from 'classnames';

import { ReactComponent as SuccessIcon } from 'assets/icons/right.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/wrong.svg';

import './RuleMessage.scss';

export const RuleMessage = ({ message, isValid, isInvalid, className }) => {
    return (
        <div className={classNames('hme-reset-password__rule', {
            'hme-reset-password__rule--valid': isValid,
            'hme-reset-password__rule--invalid': isInvalid
        }, className)}>
            <div className='hme-reset-password__rule__icon--neutral'></div>
            <SuccessIcon className='hme-reset-password__rule__icon--valid' />
            <CrossIcon className='hme-reset-password__rule__icon--invalid' />
            <span className='hme-reset-password__rule__label'>{message}</span>
        </div>
    );
};
