export const findDepth = (tree, item, depth = 1) => {
    for (const node of tree) {
        if ( node?.item === item ) {
            return depth;
        }
        if (node.children) {
            const childDepth = findDepth(node.children, item, depth + 1);
            if (childDepth !== -1) {
                return childDepth;
            }
        }
    }
    return -1;
};
