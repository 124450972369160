import { checkAccess } from '../../../actions/permissions';
import { CommonConstants } from '../../../Constants'

// object with permissions for each tab
export const DeviceSettingsSubHeadersPermissions = {
    manageDeviceSettings: CommonConstants.adminPermissions.CreateSettingSnapshot,
    applyDeviceSettings: CommonConstants.adminPermissions.ApplyDeviceSettings,
    applyNexeoDeviceSettings: CommonConstants.adminPermissions.ApplyDeviceSettings,
    applyStoreSettings: CommonConstants.adminPermissions.ApplyDeviceSettings,
    mergeDevices: CommonConstants.adminPermissions.ApplyDeviceSettings,
};

// We need to store the tabs order
const tabsOrder = ['manageDeviceSettings', 'applyDeviceSettings', 'applyStoreSettings', 'mergeDevices'];

// Get available tabs for settings page by user permissions
export const getDeviceSettingsSubHeaders = () => {
    return tabsOrder
        .filter(tabName => checkAccess(DeviceSettingsSubHeadersPermissions[tabName]));
};
