/* eslint-disable react/prop-types */
import React from 'react';
import './SortingRadioGroup.scss';
import { empty } from 'ramda';
import { RadioGroup } from '../../../../../../../library/RadioGroup';

export const SortingRadioGroup = ({
    value,
    onChange = empty,
    options = []
}) => {
    return (
        <div className="hme-mobile-select">
            <RadioGroup
                items={options}
                variants={[]}
                radioVariants={[]}
                value={value}
                onChange={(changedValue) => {
                    onChange(changedValue);
                }}
            />
        </div>
    );
};

