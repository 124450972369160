import { useState, useEffect, useCallback } from 'react';
import { scheduledUpgradesApi } from 'api/scheduledUpgradesApi';
import { formatInstallScheduleResult } from '../helpers';

const { getScheduledUpgradesByID } = scheduledUpgradesApi;

export const useLoadUpgradeTask = (upgradeTaskID) => {
    const [upgradeTask, setUpgradeTask] = useState(null);
    const [isUpgradeTaskLoading, setIsUpgradeTaskLoading] = useState(false);
    const [upgradeTaskLoadingError, setUpgradeTaskLoadingError] = useState(null);

    const loadUpgradeTask = useCallback(async (scheduledUpgradeId) => {
        try {
            setIsUpgradeTaskLoading(true);
            setUpgradeTaskLoadingError(null);
            const upgradeTask = (await getScheduledUpgradesByID({ scheduledUpgradeId })) || {};

            // reformat install schedule to be consistent with the format of upgrade start/end time
            if (upgradeTask?.scheduledUpgrade?.InstallSchedule) {
                upgradeTask.scheduledUpgrade.InstallSchedule = formatInstallScheduleResult(
                    upgradeTask.scheduledUpgrade.InstallSchedule,
                );
            }

            setUpgradeTask(upgradeTask);
        } catch (e) {
            setUpgradeTask([]);
            setUpgradeTaskLoadingError(e);
        }

        setIsUpgradeTaskLoading(false);
    }, []);

    useEffect(() => { loadUpgradeTask(upgradeTaskID); }, [upgradeTaskID]);

    return { upgradeTask, isUpgradeTaskLoading, upgradeTaskLoadingError, loadUpgradeTask };
};
