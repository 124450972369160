export const redirectToSettingsPage = (navigate, accOwnerUID) => {
    const redirectURL = `${!accOwnerUID ? '/admin' : ''}/settings/users${accOwnerUID ? `?uuid=${accOwnerUID}` : ''}`;

    if (!accOwnerUID) {
        window.location = redirectURL;
        return;
    }

    navigate(redirectURL);
};
