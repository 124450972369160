import { useState, useEffect, useCallback } from 'react';
import { getCompanyInfo } from 'services/Account';

export const useLoadCompanyInfo = () => {
    const [companyInfo, setCompanyInfo] = useState(null);
    const [isCompanyInfoLoading, setIsCompanyInfoLoading] = useState(false);
    const [companyInfoLoadingError, setCompanyInfoLoadingError] = useState(null);

    const loadCompanyInfo = useCallback(async () => {
        try {
            setIsCompanyInfoLoading(true);
            setCompanyInfoLoadingError(null);
            const data = (await getCompanyInfo()) || null;

            setCompanyInfo(data);
        } catch (e) {
            setCompanyInfo(null);
            setCompanyInfoLoadingError(e);
        }

        setIsCompanyInfoLoading(false);
    }, []);

    useEffect(() => { loadCompanyInfo(); }, []);

    return { companyInfo, isCompanyInfoLoading, companyInfoLoadingError, loadCompanyInfo };
};
