export const AdminStoreProperties = {
    companyName: 'Company_Name',
    brandName: 'Brand_Name',
    storeNumber: 'Store_Number',
    storeName: 'Store_Name',
    storeAddress: 'Store_AddressLine1',
    userEmail: 'User_EmailAddress',
    userSubscription: 'Subscription_Name',
    deviceSerialNumber: 'Device_SerialNumber',
    deviceProductID: 'Device_Product_ID',
    deviceVersion: 'Device_MainVersion',
    deviceStatus: 'Device_IsActive',
    axAccountNumber: 'Store_AX_Customer_Account',
    customerNumberKey: 'customerNumberKey',
    storeRegistrationKey: 'Store_RegistrationKey',
};
