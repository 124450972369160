export const filterTypes = {
    BRAND: 'BRAND',
    ACCOUNT: 'ACCOUNT',
    STORE: 'STORE',
};

export const filterTypeOptions = [
    {
        text: 'common__brand',
        value: filterTypes.BRAND,
    },
    {
        text: 'common__account',
        value: filterTypes.ACCOUNT,
    },
    {
        text: 'common__store',
        value: filterTypes.STORE,
    },
];
