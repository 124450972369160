import { Config } from '../Config';
import { ArgumentError } from './ArgumentError';

/**
 * Class to build query string
 */
class ApiQuery {
  constructor({ serverAddress, endpoint, parameters }) {
    if (!serverAddress) {
      throw new ArgumentError('ApiQuery.serverAddress')
    }

    if (!endpoint) {
      throw new ArgumentError('ApiQuery.endpoint');
    }

    this.serverAddress = serverAddress;
    this.endpoint = endpoint;
    this.parameters = parameters || {};
  }

  toString() {
    let url = `${this.serverAddress}${this.endpoint}`;
    const parametersKeys = Object.keys(this.parameters);

    if (parametersKeys.length) {
      const parametersString = parametersKeys
        .map(key => `${key}=${this.parameters[key]}`)
        .join('&');

      url = `${url}?${parametersString}`;
    }

    return url;
  }
}

/**
 * Class to build query string for portal service api
 */
export class PortalServiceApiQuery extends ApiQuery {
  constructor({ endpoint, parameters }) {
    super({ endpoint, parameters, serverAddress: Config.apiBaseUrl });
  }
}
