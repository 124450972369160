/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from 'library/Button';

import './SaveFooter.scss';

export const SaveFooter = ({
    isSubmitEnabled = true,
    onCancel,
    onSave,
    additionalClass,
    okLabel,
    cancelLabel
}) => {
    const { t } = useTranslation();

    return (
        <div className={classNames('hme-save-footer', additionalClass)}>
            <Button
                onClick={onCancel}
            >
                {cancelLabel || t('common__cancel')}
            </Button>
            <Button
                variants={['submit']}
                disabled={!isSubmitEnabled}
                onClick={onSave}
            >
                {okLabel || t('common__save')}
            </Button>
        </div>
    );
};
