import React from 'react';

import { ViewEdit } from '../Common';
import { useDistributorCreate } from './hooks/useDistributorCreate';

export const DistributorCreate = () => {
    const state = useDistributorCreate();

    return (
        <ViewEdit {...state} />
    );
};
