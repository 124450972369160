import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Constant } from '@hme-cloud/utility-common';
import { SingleSelect as Select } from 'library/SingleSelect';
import { MultiSelect } from 'library/MultiSelect';
import { deviceTypes } from 'constants/device';
import { getDeviceTypeVersions, filterZOOMUpgradeVersionsOptions } from './helpers'
import { findMaxVersion } from '../../helpers';
import { NEXEO_BASE_STATION, NEXEO_PERIPHERAL_TYPES } from 'constants/NEXEOPeripheralTypes';

const { BuildVersions, DeviceType } = Constant;

const MIN_TWO_STEP_UPGRADE = '3.0.9999';

const makeVersionsUnique = (versions) => {
    if (!Array.isArray(versions) || !versions.length) {
        return [];
    }

    return [
        ...new Set(versions.map(({ value }) => (value)))
    ].map((version) => ({ text: version, value: version }));
};

export const DeviceVersions = ({
    targetDeviceType,
    initialValues,
    targetPeripheralDeviceType,
    isDisabled,
    allDeviceVersions,
    allUpgradeVersions,
    onTargetDeviceVersionsChange,
    onUpgradeVersionChange,
}) => {
    const { t } = useTranslation();
    const [initialTargetDeviceVersions, initialUpgradeVersion] = useMemo(() => {
        return [initialValues?.targetDeviceVersions || [], initialValues?.upgradeVersion || ''];
    }, [initialValues]);

    const [targetDeviceVersionsOptions, setTargetDeviceVersionsOptions] = useState([]);
    const [targetDeviceVersionsOptionsFiltered, setTargetDeviceVersionsOptionsFiltered] = useState([]);
    const [targetDeviceVersionsSearch, setTargetDeviceVersionsSearch] = useState('');
    const [targetDeviceVersionsSelected, setTargetDeviceVersionsSelected] = useState([]);
    const [targetDeviceVersionSingleSelected, setTargetDeviceVersionSingleSelected] = useState('');
    const [upgradeVersionSelected, setUpgradeVersionSelected] = useState(initialUpgradeVersion);

    const [upgradeDeviceVersionsOptions, setUpgradeDeviceVersionsOptions] = useState([]);
    const [upgradeDeviceVersionsOptionsFiltered, setUpgradeDeviceVersionsOptionsFiltered] = useState([]);

    const onTargetDeviceSingleVersionChange = useCallback((value) => {
        onTargetDeviceVersionsChange([value]);
        setTargetDeviceVersionSingleSelected(value);

        // only upgrade versions that are greater than the selected target version
        let newUpgradeDeviceVersionsOptionsFiltered = upgradeDeviceVersionsOptions
            .filter((version) => BuildVersions.semverCompare(version.value, value, 'gt'));

        if (targetDeviceType === DeviceType.ZOOM.camelCase) {
            newUpgradeDeviceVersionsOptionsFiltered = filterZOOMUpgradeVersionsOptions({
                targetDeviceVersion: value,
                upgradeVersions: newUpgradeDeviceVersionsOptionsFiltered,
            });
        }

        if (targetDeviceType === DeviceType.ZOOMNITRO.camelCase) {
            const nitroUpgradeType = BuildVersions.semverLt(value, BuildVersions.MIN_NITRO_UPGRADE_VERSION)
                ? BuildVersions.FULL : BuildVersions.PATCH;

            newUpgradeDeviceVersionsOptionsFiltered = newUpgradeDeviceVersionsOptionsFiltered
                .filter(({ upgradeType }) => (nitroUpgradeType === upgradeType));
        }

        // for the initial loading we might have duplicate versions for patch and full upgrades
        // but for rendering they should be unique
        setUpgradeDeviceVersionsOptionsFiltered(makeVersionsUnique(newUpgradeDeviceVersionsOptionsFiltered));
        setUpgradeVersionSelected(initialUpgradeVersion); // clear upgrade 'to version' when 'from version' changes
    }, [upgradeDeviceVersionsOptions]);

    const onTargetDeviceMultipleVersionChange = useCallback((values) => {
        const plainVersions = values.map(({ value }) => value);
        setTargetDeviceVersionsSelected(values);

        onTargetDeviceVersionsChange(plainVersions);

        if (values.length === 0) {
            setUpgradeDeviceVersionsOptionsFiltered(upgradeDeviceVersionsOptions);
            setTargetDeviceVersionsOptionsFiltered(targetDeviceVersionsOptionsFiltered
                .map((version) => ({ ...version, disabled: false })));
            setUpgradeVersionSelected(initialUpgradeVersion);
            setTargetDeviceVersionsSelected([]);
            return;
        }

        // only upgrade versions that are greater than the selected target version
        const maxTargetVersion = findMaxVersion(plainVersions);
        const newUpgradeDeviceVersionsOptionsFiltered = upgradeDeviceVersionsOptions
            .filter((version) => BuildVersions.semverCompare(version.value, maxTargetVersion, 'gt'));
        setUpgradeDeviceVersionsOptionsFiltered(newUpgradeDeviceVersionsOptionsFiltered);
        setUpgradeVersionSelected(initialUpgradeVersion); // clear 'to version' when 'from version' changes

        updateTargetDeviceVersionsOptionsFiltered(values);
    }, [upgradeDeviceVersionsOptions, targetDeviceVersionsOptionsFiltered, targetDeviceVersionsSelected]);

    const updateTargetDeviceVersionsOptionsFiltered = useCallback((selectedVersions) => {
        const maxTargetVersion = findMaxVersion(selectedVersions.map(({ value }) => value));

        let newTargetDeviceVersionsOptionsFiltered = targetDeviceVersionsOptions;
        if (maxTargetVersion) {
            const versionComparsionSign = BuildVersions.semverLt(MIN_TWO_STEP_UPGRADE, maxTargetVersion) ? 'lt' : 'gt';
            newTargetDeviceVersionsOptionsFiltered = newTargetDeviceVersionsOptionsFiltered
                .map((version) => ({
                    ...version,
                    disabled: BuildVersions.semverCompare(version.value, MIN_TWO_STEP_UPGRADE, versionComparsionSign),
                }));
        } else {
            newTargetDeviceVersionsOptionsFiltered = newTargetDeviceVersionsOptionsFiltered
                .map((version) => ({ ...version, disabled: false }));
        }

        if (targetDeviceVersionsSearch) {
            setTargetDeviceVersionsOptionsFiltered(newTargetDeviceVersionsOptionsFiltered.filter((version) => version.text.includes(targetDeviceVersionsSearch)))
        } else {
            // when search is empty, we need to update the whole list
            setTargetDeviceVersionsOptionsFiltered(newTargetDeviceVersionsOptionsFiltered);
        }
    }, [targetDeviceVersionsOptions, targetDeviceVersionsOptionsFiltered, , targetDeviceVersionsSearch]);

    const onTargetDeviceMultipleSearchChange = useCallback((value) => {
        setTargetDeviceVersionsSearch(value);
    }, []);

    const onUpgradeVersionChangeSelected = useCallback((value) => {
        setUpgradeVersionSelected(value);
    }, []);

    useEffect(() => {
        onUpgradeVersionChange(upgradeVersionSelected);
    }, [upgradeVersionSelected]);

    useEffect(() => {
        updateTargetDeviceVersionsOptionsFiltered(targetDeviceVersionsSelected);
    }, [targetDeviceVersionsSearch]);

    useEffect(() => {
        const { target, upgrade } = getDeviceTypeVersions({
            targetDeviceType,
            targetPeripheralDeviceType,
            allDeviceVersions,
            allUpgradeVersions,
        });
        // @todo would be better to use not mapped options format before it will be used
        // in actually Select component, like '{ version: "1.2.3" }' and map to options format
        // on the final level
        const targetDeviceVersions = target.map((version) => ({ text: version, value: version}));
        setTargetDeviceVersionsOptions(targetDeviceVersions);
        setTargetDeviceVersionsOptionsFiltered(targetDeviceVersions);

        const upgradeDeviceVersions = upgrade.map((upgradeVersion) => ({
            text: upgradeVersion.version,
            value: upgradeVersion.version,
            upgradeType: upgradeVersion.upgradeType,
        }));

        setUpgradeDeviceVersionsOptions(upgradeDeviceVersions);
        // we need to be sure that versions for select will be unique
        setUpgradeDeviceVersionsOptionsFiltered(makeVersionsUnique(upgradeDeviceVersions));

        setTargetDeviceVersionsSearch('');
        setTargetDeviceVersionsSelected(initialTargetDeviceVersions.map((version) => ({ text: version, value: version })));
        setTargetDeviceVersionSingleSelected(initialTargetDeviceVersions.length ? initialTargetDeviceVersions[0] : '');
        setUpgradeVersionSelected(initialUpgradeVersion);
    }, [targetDeviceType, targetPeripheralDeviceType]);

    return (<>
        <div
            className={classNames([
                'hme-create-device-upgrade-form-section',
            ])}
        >
            {(targetDeviceType === DeviceType.ZOOM.camelCase
                || targetDeviceType === DeviceType.ZOOMNITRO.camelCase
                || (targetDeviceType === deviceTypes.NEXEO && targetPeripheralDeviceType === NEXEO_PERIPHERAL_TYPES.IB7000.modelName)
            ) && (<>
                <Select
                    isDisabled={isDisabled}
                    label={t('device-upgrades__section__form--upgrade-from-version')}
                    variants={['label-inside']}
                    isRequired
                    items={targetDeviceVersionsOptionsFiltered}
                    value={targetDeviceVersionSingleSelected}
                    onChange={onTargetDeviceSingleVersionChange}
                />
            </>)}
            {targetDeviceType === deviceTypes.NEXEO
                && targetPeripheralDeviceType === NEXEO_BASE_STATION.modelName
                && (<>
                <MultiSelect
                    isDisabled={isDisabled}
                    isReadOnly={isDisabled}
                    isRequired
                    searchValue={targetDeviceVersionsSearch}
                    searchMinSymbols={0}
                    items={targetDeviceVersionsOptionsFiltered}
                    selections={targetDeviceVersionsSelected}
                    onSelectionChange={onTargetDeviceMultipleVersionChange}
                    onSearchValueChange={onTargetDeviceMultipleSearchChange}
                    variants={['search', 'label-inside']}
                    label="device-upgrades__section__form--upgrade-from-version"
                    placeholder="common__select-item"
                    noRecordsMessage="common__no-results"
                    shouldClearSearchOnFocus
                    withShowMore={false}
                />
            </>)}
        </div>
        <div
            className={classNames([
                'hme-create-device-upgrade-form-section',
            ])}
        >
            {targetDeviceType && (<Select
                isDisabled={isDisabled}
                label={t('device-upgrades__section__form--upgrade-to-version')}
                variants={['label-inside']}
                items={upgradeDeviceVersionsOptionsFiltered}
                isRequired
                value={upgradeVersionSelected}
                onChange={onUpgradeVersionChangeSelected}
                noResultsText={upgradeDeviceVersionsOptionsFiltered.length === 0 ? `common__no-new-upgrades` : 'common__no-results'}
            />)}
        </div>
    </>);
};