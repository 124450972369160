import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';
import { Button } from 'library/Button';

import './Header.scss';

export const Header = ({ onFeedCreate, onFeedLink }) => {
    const { t } = useTranslation();

    return (
        <div className="dxs-account-header">
            <div className="dxs-account-header-title-wrapper">
                <Title>{t('dxs-feeds__account__header__title')}</Title>
            </div>
            <div className="dxs-account-header-link">
                <Button onClick={onFeedLink}>{t('dxs-feeds__header__add-new-feed')}</Button>
                <Button onClick={onFeedCreate}>{t('dxs-feeds__header__create-new-feed')}</Button>
            </div>
        </div>
    );
};
