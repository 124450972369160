import React, { useCallback, useEffect, useRef } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { T, compose, cond } from 'ramda';
import { Trans, useTranslation } from 'react-i18next';

import { withHMELayout } from 'HOCs/withHMELayout';
import { CenterLoader } from 'components/Common/CenterLoader';
import { InputColorPicker } from 'components/Inputs/InputColorPicker/InputColorPicker';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { useAsync } from 'hooks/useAsync';
import { ASYNC_STATUS } from 'hooks/useAsync';
import { SaveFooter } from 'library/SaveFooter';
import { Title } from 'library/Title';
import { Select } from 'library/Select';
import { Button } from 'library/Button';
import { Section } from 'library/Section';
import { Label } from 'library/Label';
import { DaySingleSelect } from 'library/DaySingleSelect';
import { NotificationsList } from 'library/NotificationsList';
import { getProfile } from 'services/Auth';
import { CommonConstants } from 'Constants';
import { COMMON_ROUTES } from 'constants/routes';

import { Toggle } from './Toggle';
import { FieldTitle } from './FieldTitle';
import { getDisplaySettingsData, updateDisplaySettingsData } from './Controller';
import {
    DASHBOARD_VIEW_OPTIONS,
    METRIC_OPTIONS,
    PULLINS_OPTIONS,
    STORE_COLUMN_NAME_OPTIONS,
    TIME_FORMAT_OPTIONS
} from './constants';
import { useSettingsData } from './hooks';

import './Settings.scss';

const { subscription } = CommonConstants;

const SettingsUI = ({ displaySettings, onSubmit }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {
        state,
        handleMetricChange,
        handleDefaultViewChange,
        handleColorChange,
        handlePullinsChange,
        handleStoreColumnNameChange,
        handleTimeFormatChange,
        resetToDefault,
        handleStartDayChange
    } = useSettingsData(displaySettings);

    const containerRef = useRef();

    const handleDisplaySettingsUpdate = useCallback(() => {
        onSubmit(state);
    }, [state, onSubmit]);

    const handleCancelClick = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <>
            <div className="hme-settings-display__wrapper" ref={containerRef}>
                <div className="hme-settings-display__header">
                    <Title className="hme-settings-display__page-title">{t('sub-header--settings__display')}</Title>
                    <div className="hme-settings-display__select-metric">
                        <Select value={state.preferredMetric} label={t('common__metrics')} onChange={handleMetricChange}>
                            {METRIC_OPTIONS}
                        </Select>
                    </div>
                    <Button variants={['transparent']} onClick={resetToDefault}>
                        {t('common__reset-to-defaults')}
                    </Button>
                </div>

                <div className="hme-settings-display__main-content">
                    <Section
                        title="apply-device-settings-status__setting-name--dashboard-settings"
                        number={1}
                        showNumber={false}
                        className="hme-settings-display__section"
                    >
                        <div className="hme-settings-display__section-content">
                            <div className="hme-settings-display__section-column">
                                <Toggle
                                    tipText={
                                        <Trans i18nKey="display-settings__tip--default-view">
                                            <p>
                                                <span><Trans i18nKey="display-settings__tip--default-view__select-view" /></span>
                                                <span>*</span>
                                                <span><Trans i18nKey="display-settings__tip--default-view__this-will-be-your-view" /></span>
                                            </p>
                                            <p>
                                                <span>* </span>
                                                <span><Trans i18nKey="display-settings__tip--default-view__you-must-customize" /></span>
                                            </p>
                                        </Trans>
                                    }
                                    label={t('display-settings__title--dashboard')}
                                    value={state.preferredDefaultView}
                                    className="hme-settings-display__default-view-toggle"
                                    onChange={handleDefaultViewChange}
                                    containerRef={containerRef}
                                >
                                    {DASHBOARD_VIEW_OPTIONS}
                                </Toggle>

                                <div className="hme-settings-display__color-fields">
                                    <FieldTitle
                                        title={<Label>{t('display-settings__title--goal-colors')}</Label>}
                                        tipText={
                                            <Trans i18nKey="display-settings__tip--primary-goal">
                                                <p>
                                                    <span><Trans i18nKey="display-settings__tip--primary-goal__select-color" /></span>
                                                    <span>*</span>
                                                    <span><Trans i18nKey="display-settings__tip--primary-goal__these-colors" /></span>
                                                </p>
                                                <p>
                                                    <span>* </span>
                                                    <span><Trans i18nKey="display-settings__tip--primary-goal__you-can-enter" /></span>
                                                </p>
                                            </Trans>
                                        }
                                        container={containerRef}
                                    />

                                    <div className="hme-settings-display__color-fields-wrapper">
                                        <InputColorPicker
                                            value={state.preferredPrimeColors.colorA}
                                            label={`< ${t('common__goal--a')}`}
                                            onChange={handleColorChange('colorA')}
                                        />
                                        <InputColorPicker
                                            value={state.preferredPrimeColors.colorB}
                                            label={`< ${t('common__goal--b')}`}
                                            onChange={handleColorChange('colorB')}
                                        />
                                        <InputColorPicker
                                            value={state.preferredPrimeColors.colorC}
                                            label={`> ${t('common__goal--b')}`}
                                            onChange={handleColorChange('colorC')}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="hme-settings-display__section-column">
                                <Toggle
                                    value={state.preferredPullins}
                                    onChange={handlePullinsChange}
                                    tipText={
                                        <Trans i18nKey="display-settings__tip--pullins">
                                            <p>
                                                <span><Trans i18nKey="display-settings__tip--pullins__dashboard-reports" /></span>
                                                <span>* </span>
                                                <span><Trans i18nKey="data or exclude it" /></span>
                                            </p>
                                            <p>
                                                <span>* </span>
                                                <span><Trans i18nKey="display-settings__tip--pullins__pullins-is" /></span>
                                            </p>
                                        </Trans>
                                    }
                                    label={t('common__pull-ins')}
                                    containerRef={containerRef}
                                >
                                    {PULLINS_OPTIONS}
                                </Toggle>

                                <Toggle
                                    value={state.preferredStoreColumnName}
                                    onChange={handleStoreColumnNameChange}
                                    label={t('display-settings__title--store-name')}
                                    containerRef={containerRef}
                                >
                                    {STORE_COLUMN_NAME_OPTIONS}
                                </Toggle>
                            </div>
                        </div>
                    </Section>

                    <Section title="common__format" className="hme-settings-display__section">
                        <div className="hme-settings-display__section-content">
                            <div className="hme-settings-display__section-column">
                                <Toggle
                                    value={state.timeFormat}
                                    onChange={handleTimeFormatChange}
                                    label={t('common__time-format')}
                                    tipText={
                                        <Trans i18nKey="display-settings__tip--time-format">
                                            <p>
                                                <span><Trans i18nKey="display-settings__tip--time-format__select-time" /></span>
                                                <span>* </span>
                                                <span><Trans i18nKey="display-settings__tip--time-format__shown-your" /></span>
                                            </p>
                                            <p>
                                                <span>* </span>
                                                <span><Trans i18nKey="display-settings__tip--time-format__time-showing" /></span>
                                            </p>
                                        </Trans>
                                    }
                                    containerRef={containerRef}
                                >
                                    {TIME_FORMAT_OPTIONS}
                                </Toggle>
                            </div>
                        </div>
                    </Section>

                    <Section title="common__reports" className="hme-settings-display__section">
                        <DaySingleSelect
                            onChange={handleStartDayChange}
                            value={state.preferredStartDay}
                            label={t('display-settings__reports-weekday')}
                            variants={['no-label']}
                            containerRef={containerRef}
                            tipText={t('display-settings__tip--reports-weekday')}
                        />
                    </Section>
                </div>
            </div>

            <NotificationsList />

            <SaveFooter
                additionalClass="hme-settings-display__save-footer"
                onCancel={handleCancelClick}
                onSave={handleDisplaySettingsUpdate}
            />
        </>
    );
};

const SettingsComponent = cond([
    [
        ({ isLoading }) => isLoading,
        () => (
            <CenterLoader>
                <Trans i18nKey="common__loading" />
            </CenterLoader>
        )
    ],
    [T, (props) => <SettingsUI {...props} />]
]);

const SettingsLoader = () => {
    const { run, status, data: displaySettings } = useAsync();
    const { run: runDisplaySettingsUpdate, status: displaySettingsUpdateStatus } = useAsync();

    const isDisplaySettingsLoading = status === ASYNC_STATUS.IDLE || status === ASYNC_STATUS.PENDING;
    const isDisplaySettingsUpdateLoading = displaySettingsUpdateStatus === ASYNC_STATUS.PENDING;

    useEffect(() => {
        run(getDisplaySettingsData());
    }, [run]);

    const handleDisplaySettingsUpdate = useCallback(
            (data) => {
                if (isDisplaySettingsUpdateLoading) {
                    return;
                }

                runDisplaySettingsUpdate(
                        updateDisplaySettingsData(data)
                                .then((result) => {
                                    addSuccessNotification('display-settings__success--saved');

                                    return result;
                                })
                                .catch((error) => {
                                    addErrorNotification(error.message);
                                })
                );
            },
            [runDisplaySettingsUpdate, isDisplaySettingsUpdateLoading]
    );

    return (
        <SettingsComponent
            isLoading={isDisplaySettingsLoading}
            displaySettings={displaySettings}
            onSubmit={handleDisplaySettingsUpdate}
        />
    );
};


const Settings = cond([
    [
        () => getProfile().Subscription_ID === Number(subscription.nitroEssentials),
        () => <Navigate to={`/${COMMON_ROUTES.welcome}`} replace />
    ],
    [T, () => <SettingsLoader />]
]);


export const SettingsDisplay = compose(
        withHMELayout({
            contentClasses: ['hme-settings-display']
        })
)(Settings);
