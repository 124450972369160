import React from 'react';

import { SingleSelect } from 'library/SingleSelect';
import { FieldTitle } from 'pages/Settings/FieldTitle';

export const weekDaysOptions = [
    {
        value: 0,
        text: 'common__sunday'
    },
    {
        value: 1,
        text: 'common__monday'
    },
    {
        value: 2,
        text: 'common__tuesday'
    },
    {
        value: 3,
        text: 'common__wednesday'
    },
    {
        value: 4,
        text: 'common__thursday'
    },
    {
        value: 5,
        text: 'common__friday'
    },
    {
        value: 6,
        text: 'common__saturday'
    }
];

export const DaySingleSelect = ({ value, items = weekDaysOptions, label, tipText, containerRef, ...props }) => {
    return (<>
        <SingleSelect
            {...props}
            value={value}
            items={items}
            label={<FieldTitle title={label} tipText={tipText} container={containerRef} />}>
        </SingleSelect>
    </>);
};
