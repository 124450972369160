import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Constant } from '@hme-cloud/utility-common';

import { PeripheralActiveStatus } from 'pages/SystemStatus/AdminNexeo/DeviceStatus/DevicesSection/PeripheralActiveStatus';
import { Grid } from 'components/Common/Grid';

import './PeripheralDevicesList.scss';

const { BuildVersions } = Constant;

const subgridOptions = [
    {
        flex: 1.3,
        property: 'modelName',
        className: 'hme-grid-cell-show-all-content hme-grid-model-name'
    },
    {
        flex: 1,
        property: 'serialNumber',
        className: 'hme-grid-cell-show-all-content hme-grid-serial-col'
    },
    {
        text: 'common__device__product-id',
        flex: 1,
        property: 'productId',
        className: 'hme-grid-cell-show-all-content hme-grid-product-id-col'
    },
    {
        flex: 1,
        property: 'lane',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell'
    },
    {
        flex: 1,
        property: 'mainVersion',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell'
    },
    {
        flex: 1,
        property: 'onLatestVersion',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell hme-on-latest-version'
    }
];

const deviceToRow = ({ data, device, t }) => {
    const maxUpgradeVersion = data.isMultiple ? device.maxUpgradeVersion : data.maxUpgradeVersion;

    const onLatestVersion =
        !maxUpgradeVersion ||
        (device.mainVersion && BuildVersions.semverGte(device.mainVersion, maxUpgradeVersion))
            ? t('common__yes')
            : t('common__no');

    return {
        modelName: <div>
            <PeripheralActiveStatus device={device} isBaseStationActive={data.isBaseStationActive} />
            {device.modelName}
        </div>,
        serialNumber: device.serialNumber,
        lane: device.lane ? (t('common__lane') + ' ' + device.lane) : null,
        mainVersion: device.mainVersion,
        onLatestVersion
    };
};


export const PeripheralDevicesList = ({ data }) => {
    const { t } = useTranslation();

    const rows = useMemo(() => {
        return data.devices.map((device) => deviceToRow({ data, device, t }));
    }, [data]);

    return <Grid
        headers={subgridOptions}
        rows={rows}
        noRecordsMessage={t('common__device-peripheral-devices-absence')}
        className="hme-peripheral-devices-list"
    />;
};

