import React, { useEffect, useState } from 'react';
import { Section } from 'library/Section';
import { useTranslation } from 'react-i18next';
import { ErrorLabel } from 'library/ErrorLabel';
import { MessageArea } from './MessageArea';

const MAX_MESSAGE_LENGTH = 280;

export const MessageSection = ({ message, validationErrors, onMessageChange, disabled = false }) => {
    const { t } = useTranslation();

    const [messageValue, setMessageValue] = useState(message);

    useEffect(() => {
        onMessageChange(messageValue);
    }, [messageValue]);

    return (
        <Section
            number={2}
            className="text-connect__sections__section--message"
            title={t('text-n-connect_tabs__section__title--message')}
            disabled={disabled}
        >
            <MessageArea
                maxLength={MAX_MESSAGE_LENGTH}
                label={'common__message'}
                value={messageValue}
                onChange={setMessageValue}
                name="message"
                required={true}
            />
            {validationErrors?.invalidSpecialChars && (
                <ErrorLabel>
                    {t(validationErrors?.invalidSpecialChars.key, {
                        ...validationErrors?.invalidSpecialChars.params,
                        interpolation: { escapeValue: false },
                    })}
                </ErrorLabel>
            )}
        </Section>
    );
};
