import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getStoreDetailsUrl } from '../../helpers/getStoreDetailsUrl';

const Actions = ({ companyType, userUID }) => {
    const { t } = useTranslation();
    return <Link to={getStoreDetailsUrl(companyType, userUID)}>
        {t('stores__modal__view-details')}
    </Link>;
};

Actions.propTypes = {
    companyType: PropTypes.string.isRequired,
    userUID: PropTypes.string.isRequired
};

export default Actions;
