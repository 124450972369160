import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import cx from 'classnames';

import './SummaryReport.css';

/**
 * LongestTime
 */
class LongestTime extends Component {
    /**
     * This constructor is usd to set initial state
     * @param  {input} props
     */
    constructor(props) {
        super(props);
        this.state = {
            LongestTimes: [],
            eventList: []
        };
    }

    /**
     * Main render function
     * @return {JSX} component's template
     */
    render() {
        const { t } = this.props;
        if (this.props.LongestTimes.length > 0) {
            return (
                <section>
                    <table cellSpacing='0' className='table-layout daypart longest'>
                        <tbody>
                            <tr>
                                <td
                                    rowSpan='4'
                                    className='rshade boldin longTimes headerColumn'
                                >
                                    <span>{t('reports__longest')}</span>
                                    <br />
                                    <span>{t('reports__times')}</span>
                                    <br />
                                    <span className='longtime-timeSpan'>
                                         {this.props.formatType === 2 ? t('reports__minutes-format') : t('reports__seconds-format')}
                                    </span>
                                </td>
                            </tr>
                            {this.renderLongestTimes()}
                        </tbody>
                    </table>
                </section>
            );
        }

        return <div />;
    }

    /**
     * renderLongestTimes
     * @return  {JSX} render renderLongestTimes component
     */
    renderLongestTimes() {
        const LongestTimes = this.props.LongestTimes;
        return LongestTimes.slice(0, 3).map((LongestTime, index) => {
            return (
                <tr>
                    {this.renderLongestTimesCell(LongestTime, index)}
                    <td className='lastTd' />
                </tr>
            );
        });
    }

    /**
     * renderLongestTimesCell
     * @param  {input} LongestTimes
     * @param  {input} index
     * @return  {JSX} render renderLongestTimesCell component
     */
    renderLongestTimesCell(LongestTimes, index) {
        const headerEvents = this.props.reportData.eventList;
        // let headerEvents = this.state.eventList
        const colWidth = this.props.reportData.groupStoreColumns
      ? headerEvents.length - 3
      : headerEvents.length - 2;
        return headerEvents.map((headerItem) => {
            if (
                headerItem !== 'Day' &&
        headerItem !== 'Daypart' &&
        headerItem !== 'Week' &&
        headerItem !== 'Groups' &&
        headerItem !== 'Stores' &&
        headerItem !== 'Total Cars'
            ) {
                return (
                    <td
                        className={cx('reportTableAttributesHeading', colWidth, {
                            rshade: index % 2 === 0,
                            rnshade: index % 2 !== 0
                        })}
                    >
                        <span className='boldin'>
                            {LongestTimes[headerItem] !== undefined
                ? LongestTimes[headerItem].Value
                : 'N/A'}
                        </span>
                        <br />
                        <span className='longtime-timeSpan'>
                            {LongestTimes[headerItem] !== undefined
                ? LongestTimes[headerItem].Date
                : 'N/A'}
                            <br />
                            {LongestTimes[headerItem] !== undefined
                ? LongestTimes[headerItem].Time
                : 'N/A'}
                        </span>
                    </td>
                );
            }
        });
    }
}

export default compose(
    withTranslation()
  )(LongestTime);
