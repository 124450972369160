import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'library/Button';

import './Footer.scss';

export const Footer = ({ isSubmitEnabled, onSubmit, onCancel }) => {
    const { t } = useTranslation();
    return (
        <div className="company-info-footer">
            <Button onClick={onCancel}>{t('common__cancel')}</Button>
            <Button variants={['submit']} disabled={!isSubmitEnabled} onClick={onSubmit}>
                {t('common__save')}
            </Button>
        </div>
    );
};
