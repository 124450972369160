export const categoryKeys = {
    'smacktalk__category_boast': 'smack-talk__category-boast',
    'smacktalk__category_taunt': 'smack-talk__category-taunt',
    'smacktalk__category_congratulate': 'smack-talk__category-congratulate',
    'smacktalk__category_custom': 'smack-talk__category-custom'
};

export const typeKeys = {
    'custom': 'common__custom',
    'Predefined': 'common__predefined'
};

export const messageKeys = {
    'smacktalk__message_desc__boast_king': 'smack-talk__boast-king',
    'smacktalk__message_desc__boast_winning': 'smack-talk__boast-winning',
    'smacktalk__message_desc__boast_in_the_lead': 'smack-talk__boast-in-the-lead',
    'smacktalk__message_desc__boast_marshmallows': 'smack-talk__boast-marshmallows',
    'smacktalk__message_desc__boast_congratulate': 'smack-talk__boast-congratulate',
    'smacktalk__message_desc__taunt_pace': 'smack-talk__taunt-pace',
    'smacktalk__message_desc__taunt_catch_us': 'smack-talk__taunt-catch-us',
    'smacktalk__message_desc__taunt_laugh': 'smack-talk__taunt-laugh',
    'smacktalk__message_desc__taunt_is_that_all': 'smack-talk__taunt-is-that-all',
    'smacktalk__message_desc__taunt_ZZZZZZZ': 'smack-talk__taunt-ZZZZZZZ',
    'smacktalk__message_desc__taunt_broken_clock': 'smack-talk__taunt_broken-clock',
    'smacktalk__message_desc__taunt_valium': 'smack-talk__taunt-valium',
    'smacktalk__message_desc__taunt_slow': 'smack-talk__taunt-slow',
    'smacktalk__message_desc__taunt_zombies': 'smack-talk__taunt-zombies',
    'smacktalk__message_desc__taunt_backwards': 'smack-talk__taunt-backwards',
    'smacktalk__message_desc__taunt_do_you_even': 'smack-talk__taunt-do-you-even',
    'smacktalk__message_desc__congratulate_congratulations': 'smack-talk__congratulate-congratulations',
    'smacktalk__message_desc__congratulate_good_work': 'smack-talk__congratulate-good-work',
    'smacktalk__message_desc__congratulate_we_didnt_bet': 'smack-talk__congratulate-we-didnt-bet',
    'smacktalk__message_desc__congratulate_i_like_the_way': 'smack-talk__congratulate-i-like-the-way',
    'smacktalk__message_desc__congratulate_good_job': 'smack-talk__congratulate-good-job',
    'smacktalk__message_desc__congratulate_awesone': 'smack-talk__congratulate-awesone',
    'smacktalk__message_desc__congratulate_great_job': 'smack-talk__congratulate-great-job',
    'smacktalk__message_desc__taunt_were_coming': 'smack-talk__taunt-were-coming',
    'smacktalk__message_desc__taunt_mouth_shape': 'smack-talk__taunt-mouth-shape',
    'smacktalk__message_desc__taunt_bam': 'smack-talk__taunt-bam',
    'smacktalk__message_desc__taunt_uh_wow': 'smack-talk__taunt-uh-wow',
    'smacktalk__message_desc__taunt_watch_out': 'smack-talk__taunt-watch-out',
    'smacktalk__message_desc__taunt_going_down': 'smack-talk__taunt-going-down',
    'smacktalk__message_desc__taunt_back_hurts': 'smack-talk__taunt-back-hurts',
    'smacktalk__message_desc__taunt_me_awesome': 'smack-talk__taunt-me-awesome',
    'smacktalk__message_desc__taunt_ignore': 'smack-talk__taunt-ignore',
    'smacktalk__message_desc__taunt_under_estimate': 'smack-talk__taunt-under-estimate',
    'smacktalk__message_desc__taunt_jealous': 'smack-talk__taunt-jealous',
    'smacktalk__message_desc__taunt_delusions': 'smack-talk__taunt-delusions',
    'smacktalk__message_desc__taunt_wits': 'smack-talk__taunt-wits',

    'smacktalk__lb_text__boast_king': 'smack-talk__boast-king',
    'smacktalk__lb_text__boast_winning': 'smack-talk__boast-winning',
    'smacktalk__lb_text__boast_in_the_lead': 'smack-talk__boast-in-the-lead',
    'smacktalk__lb_text__boast_marshmallows': 'smack-talk__boast-marshmallows',
    'smacktalk__lb_text__boast_congratulate': 'smack-talk__boast-congratulate',
    'smacktalk__lb_text__taunt_pace': 'smack-talk__taunt-pace',
    'smacktalk__lb_text__taunt_catch_us': 'smack-talk__taunt-catch-us',
    'smacktalk__lb_text__taunt_laugh': 'smack-talk__taunt-laugh',
    'smacktalk__lb_text__taunt_is_that_all': 'smack-talk__taunt-is-that-all',
    'smacktalk__lb_text__taunt_ZZZZZZZ': 'smack-talk__taunt-ZZZZZZZ',
    'smacktalk__lb_text__taunt_broken_clock': 'smack-talk__taunt_broken-clock',
    'smacktalk__lb_text__taunt_valium': 'smack-talk__taunt-valium',
    'smacktalk__lb_text__taunt_slow': 'smack-talk__taunt-slow',
    'smacktalk__lb_text__taunt_zombies': 'smack-talk__taunt-zombies',
    'smacktalk__lb_text__taunt_backwards': 'smack-talk__taunt-backwards',
    'smacktalk__lb_text__taunt_do_you_even': 'smack-talk__taunt-do-you-even',
    'smacktalk__lb_text__congratulate_congratulations': 'smack-talk__congratulate-congratulations',
    'smacktalk__lb_text__congratulate_good_work': 'smack-talk__congratulate-good-work',
    'smacktalk__lb_text__congratulate_we_didnt_bet': 'smack-talk__congratulate-we-didnt-bet',
    'smacktalk__lb_text__congratulate_i_like_the_way': 'smack-talk__congratulate-i-like-the-way',
    'smacktalk__lb_text__congratulate_good_job': 'smack-talk__congratulate-good-job',
    'smacktalk__lb_text__congratulate_awesone': 'smack-talk__congratulate-awesone',
    'smacktalk__lb_text__congratulate_great_job': 'smack-talk__congratulate-great-job',
    'smacktalk__lb_text__taunt_were_coming': 'smack-talk__taunt-were-coming',
    'smacktalk__lb_text__taunt_mouth_shape': 'smack-talk__taunt-mouth-shape',
    'smacktalk__lb_text__taunt_bam': 'smack-talk__taunt-bam',
    'smacktalk__lb_text__taunt_uh_wow': 'smack-talk__taunt-uh-wow',
    'smacktalk__lb_text__taunt_watch_out': 'smack-talk__taunt-watch-out',
    'smacktalk__lb_text__taunt_going_down': 'smack-talk__taunt-going-down',
    'smacktalk__lb_text__taunt_back_hurts': 'smack-talk__taunt-back-hurts',
    'smacktalk__lb_text__taunt_me_awesome': 'smack-talk__taunt-me-awesome',
    'smacktalk__lb_text__taunt_ignore': 'smack-talk__taunt-ignore',
    'smacktalk__lb_text__taunt_under_estimate': 'smack-talk__taunt-under-estimate',
    'smacktalk__lb_text__taunt_jealous': 'smack-talk__taunt-jealous',
    'smacktalk__lb_text__taunt_delusions': 'smack-talk__taunt-delusions',
    'smacktalk__lb_text__taunt_wits': 'smack-talk__taunt-wits'
};
