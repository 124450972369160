import { cond, T } from 'ramda';
import { taskConfig } from 'constants/applyDeviceSettingsTask';
import {
    InProgressStatus,
    CompletedStatus,
    FailedStatus,
    DownloadInProgressStatus,
    DownloadCompletedStatus,
    DownloadFailedStatus,
    SnapshotOverwritenStatus, 
    ApplyInProgressStatus,
    ApplyFailedStatus,
    UnknownStatus
} from '../JobsList/StatusCells';

const {
    statuses: deviceJobStatuses,
    deviceInProgressStatuses,
} = taskConfig;

export const getStatusCell = cond([[
    taskStatus => deviceInProgressStatuses.includes(taskStatus),
    (props) => <InProgressStatus {...props} />
], [
    taskStatus => taskStatus === deviceJobStatuses.COMPLETED,
    (props) => <CompletedStatus {...props} />
], [
    taskStatus => taskStatus === deviceJobStatuses.FAILED,
    (props) => <FailedStatus {...props} />
], [
    taskStatus => taskStatus === deviceJobStatuses.DOWNLOAD_IN_PROGRESS,
    (props) => <DownloadInProgressStatus {...props} />
], [
    taskStatus => taskStatus === deviceJobStatuses.DOWNLOAD_COMPLETED,
    (props) => <DownloadCompletedStatus {...props} />
], [
    taskStatus => taskStatus === deviceJobStatuses.DOWNLOAD_FAILED,
    (props) => <DownloadFailedStatus {...props} />
], [
    taskStatus => taskStatus === deviceJobStatuses.SNAPSHOT_OVERWRITEN,
    (props) => <SnapshotOverwritenStatus {...props} />
], [
    taskStatus => taskStatus === deviceJobStatuses.APPLY_IN_PROGRESS,
    (props) => <ApplyInProgressStatus {...props} />
], [
    taskStatus => taskStatus === deviceJobStatuses.APPLY_FAILED,
    (props) => <ApplyFailedStatus {...props} />
], [
    T,
    (props) => <UnknownStatus {...props} />
]]);

export const getStatusCellValue = cond([[
    taskStatus => deviceInProgressStatuses.includes(taskStatus),
    () => deviceJobStatuses.IN_PROGRESS
], [
    taskStatus => taskStatus === deviceJobStatuses.COMPLETED,
    () => deviceJobStatuses.COMPLETED
], [
    taskStatus => taskStatus === deviceJobStatuses.FAILED,
    () => deviceJobStatuses.FAILED
], [
    taskStatus => taskStatus === deviceJobStatuses.DOWNLOAD_IN_PROGRESS,
    () => deviceJobStatuses.DOWNLOAD_IN_PROGRESS
], [
    taskStatus => taskStatus === deviceJobStatuses.DOWNLOAD_COMPLETED,
    () => deviceJobStatuses.DOWNLOAD_COMPLETED
], [
    taskStatus => taskStatus === deviceJobStatuses.DOWNLOAD_FAILED,
    () => deviceJobStatuses.DOWNLOAD_FAILED
], [
    taskStatus => taskStatus === deviceJobStatuses.SNAPSHOT_OVERWRITEN,
    () => deviceJobStatuses.SNAPSHOT_OVERWRITEN
], [
    taskStatus => taskStatus === deviceJobStatuses.APPLY_IN_PROGRESS,
    () => deviceJobStatuses.APPLY_IN_PROGRESS
], [
    taskStatus => taskStatus === deviceJobStatuses.APPLY_FAILED,
    () => deviceJobStatuses.APPLY_FAILED
], [
    T,
    () => -1
]]);
