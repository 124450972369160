import { isPartner } from 'services/Auth';

const MIN_PASS_LENGTH = 10;
const MAX_PASS_LENGTH = 16;

const MIN_PASS_LENGTH_PARTNER = 10;
const MAX_PASS_LENGTH_PARTNER = 50;

const RULE_STATUSES = {
    NEUTRAL: 'NEUTRAL',
    VALID: 'VALID',
    INVALID: 'INVALID'
};

const getIsStatus = (expected) => (status) => status === expected;

export const isNeutralStatus = getIsStatus(RULE_STATUSES.NEUTRAL);
export const isValidStatus = getIsStatus(RULE_STATUSES.VALID);
export const isInvalidStatus = getIsStatus(RULE_STATUSES.INVALID);

const lengthRule = (password) => password.length >= MIN_PASS_LENGTH && password.length <= MAX_PASS_LENGTH;
const lowerCaseRule = (password) => password.match(/[a-z]/u);
const upperCaseRule = (password) => password.match(/[A-Z]/u);
const numberRule = (password) => password.match(/[0-9]/u);
const noSpaceRule = (password) => !password.match(/[\s]/u);
const confirmRule = (password, confirm) => password === confirm;

// Rules for partner Users
const lengthRulePartner = (password) => password.length >= MIN_PASS_LENGTH_PARTNER && password.length <= MAX_PASS_LENGTH_PARTNER;
const specialCharacterRule = (password) => password.match(/[!@#$%^&*()_+]/u);
const noConsecutiveIdenticalCharactersRule = (password) => !password.match(/(.)\1\1/u);
const wrapRule = (rule) => (password, confirm) => {
    if (!password) {
        return RULE_STATUSES.NEUTRAL;
    }

    return rule(password, confirm) ?
        RULE_STATUSES.VALID :
        RULE_STATUSES.INVALID;
};

const publicLengthRule = {
    tKey: 'reset-pass__rule--length',
    rule: wrapRule(lengthRule),
};

const commonRules = [{
    tKey: 'reset-pass__rule--lower-case',
    rule: wrapRule(lowerCaseRule)
}, {
    tKey: 'reset-pass__rule--upper-case',
    rule: wrapRule(upperCaseRule)
}, {
    tKey: 'reset-pass__rule--number',
    rule: wrapRule(numberRule)
}, {
    tKey: 'reset-pass__rule--no-space',
    rule: wrapRule(noSpaceRule)
}, {
    tKey: 'reset-pass__rule--confirm',
    rule: wrapRule(confirmRule),
}];

function orderPartnerRules(rulesArray) {
    const order = [
        'reset-pass__partner-rule--length',
        'reset-pass__rule--lower-case',
        'reset-pass__rule--upper-case',
        'reset-pass__rule--number',
        'reset-pass__partner-rule--special-character',
        'reset-pass__partner-rule--no-consecutive-identical-characters',
        'reset-pass__rule--no-space',
        'reset-pass__rule--confirm'
    ];

    return rulesArray.sort((a, b) => order.indexOf(a.tKey) - order.indexOf(b.tKey));
}

const partnerRulesUnsorted = [
    {
        tKey: 'reset-pass__partner-rule--length',
        rule: wrapRule(lengthRulePartner)
    },
    ...commonRules,
    {
        tKey: 'reset-pass__partner-rule--special-character',
        rule: wrapRule(specialCharacterRule)
    },{
        tKey: 'reset-pass__partner-rule--no-consecutive-identical-characters',
        rule: wrapRule(noConsecutiveIdenticalCharactersRule)
    }
]

export const publicRules = [
    publicLengthRule,
    ...commonRules
]

export const partnerRules = orderPartnerRules(partnerRulesUnsorted)


