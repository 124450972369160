import { useState, useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { addErrorNotification } from 'services/Notifications';
import { PAGE_DEFAULT } from 'constants/paginationDefault';

const activeKey = 'active';

export const activeDefault = {
    startPage: PAGE_DEFAULT.page,
    activeId: null,
    removeActiveFromQuery: () => {}
};

export const useActive = ({ isLoaded, notifications }) => {
    const [query, setQuery] = useSearchParams();
    const [startPage, setStartPage] = useState(null);
    const [activeId, setActiveId] = useState(() => query.get(activeKey));

    const removeActiveFromQuery = useCallback(() => {
        setQuery((params) => {
            params.delete('active');

            return params;
        });
        setActiveId(null);
        setStartPage(null);
    }, []);

    useEffect(() => {
        if (!activeId || !isLoaded) {
            return;
        }

        const index = notifications.findIndex(({ id }) => id === activeId);

        if (index === -1) {
            addErrorNotification('notifications-center__error--not-found');
            removeActiveFromQuery();

            return;
        }

        const pageNumber = Math.floor(index / PAGE_DEFAULT.recordsPerPage);
        setStartPage(pageNumber);
    }, [notifications, activeId, removeActiveFromQuery, isLoaded]);

    return useMemo(() => ({
        startPage,
        activeId,
        removeActiveFromQuery,
    }), [startPage, activeId, removeActiveFromQuery]);
};
