export const vaioSettings = {
    audioModes: {
        continuous: 'Continuous',
        vehiclePresent: 'Vehicle Present',
    },
    locationTypes: {
        cloudBased: 'Cloud Based',
        onPremise: 'On Premise',
    },
    botAudioSourceTypes: {
        unprocessed: 1,
        semiProcessed: 2,
        processed: 3,
    },
    outboundAudioTypes: {
        variable: 'Variable',
        fixed: 'Fixed',
    },
    botChannelTypes: {
        '0': 0,
        '1': 1,
        '2': 2,
    }
};

export const gridHeaders = [
    {
        text: 'common__name',
        property: 'name',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    }, {
        text: 'common__key-expiration',
        property: 'keyExpirationDate',
        flex: 90,
        className: 'hme-grid-cell-show-all-content hme-grid-bold-font',
        sortable: true,
    }, {
        text: 'vaio-provider--location',
        property: 'locationType',
        flex: 80,
        className: 'hme-grid-cell-show-all-content',
    }, {
        text: 'vaio-provider--inbound-audio',
        property: 'audioMode',
        flex: 80,
        className: 'hme-grid-cell-show-all-content',
    }, {
        text: 'vaio-provider--outbound-audio',
        property: 'outboundAudio',
        flex: 80,
        className: 'hme-grid-cell-show-all-content',
    },  {
        text: 'vaio-provider--bot-channel',
        property: 'botChannels',
        flex: 80,
        className: 'hme-grid-cell-show-all-content',
    }, {
        text: 'vaio-provider--voice-agent-audio-source',
        property: 'botAudioSourceType',
        flex: 80,
        className: 'hme-grid-cell-show-all-content',
    }, {
        text: 'common__updated-date-n-time',
        property: 'updatedDate',
        flex: 90,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    }, {
        text: 'common__updated-by',
        property: 'updatedBy',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    }, {
        text: 'common__actions',
        property: 'Provider_Actions',
        flex: 80,
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    }
];
