import React, { useEffect, useState, useCallback } from 'react';
import { useTrigger } from 'hooks/useTrigger';
import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import { withAccess } from 'HOCs/withAccess';
import { isApplySettingsEnabled } from 'helpers/access';
import { getApplySettingsTaskHistory } from '../../ApplySettingsService';
import { Header } from './Header';
import { JobsList } from './JobsList';

import './StatusPage.scss';

const StatusPageComponent = () => {
    const [filters, setFilters] = useState({
        words: [],
    });
    const [jobs, setJobs] = useState([]);
    const [areJobsLoading, setAreJobsLoading] = useState(false);
    const [filtersCount, setFiltersCount] = useState(0);
    const resetFiltersTrigger = useTrigger();

    const onFiltersReset = useCallback(() => {
        resetFiltersTrigger.trigger();
    }, [resetFiltersTrigger]);

    const loadAllJobs = async () => {
        setAreJobsLoading(true);

        const tasks = await getApplySettingsTaskHistory();

        if (tasks.status) {
            setJobs(tasks.data);
        }
        setAreJobsLoading(false);
    };

    useEffect(() => {
        loadAllJobs();
    }, []);

    return (
        <div className="apply-device-settings-status-page hme-components permission-type-public">
            <Header
                filters={filters}
                onFiltersChange={setFilters}
                filtersCount={filtersCount}
                onFiltersReset={onFiltersReset}
            />
            <JobsList
                jobs={jobs}
                isLoading={areJobsLoading}
                filters={filters}
                resetFiltersTrigger={resetFiltersTrigger}
                filtersCount={filtersCount}
                onFiltersCountChange={setFiltersCount}
            />
        </div>
    );
};

export const StatusPage = compose(
    withHMELayout(),
    withAccess({
        checkAccess: isApplySettingsEnabled,
    }),
)(StatusPageComponent);
