import React from 'react';
import classNames from 'classnames';

import { useGeneratedId } from 'hooks/useGeneratedId';

import { Label } from '../../library/Label';

import './Radio.scss';

export const Radio = ({ checked, label, variants = [], disabled, onChange, name = '', value = '', id: customId = '', ...props }) => {
    const id = useGeneratedId(customId);

    return (
        <div
            className={classNames(
                'hme-radio',
                variants.map(variant => `hme-radio-${variant}-variant`),
                {
                    'hme-radio-checked': checked,
                    'hme-radio-disabled': disabled,
                }
            )}
            onClick={() => !disabled && onChange && onChange(true)}
        >
            <div className='hme-radio-icon'></div>
            <input type='radio' className='hme-radio-input' checked={checked} readOnly name={name} value={value} id={id} disabled={disabled} {...props} />
            <Label as="label" htmlFor={id}>{label}</Label>
        </div>
    );
};
