import { compose } from 'ramda';
import { withHMELayout } from 'HOCs/withHMELayout';
import { withAccess } from 'HOCs/withAccess';
import { isAdmin } from 'services/Auth';
import authenticate from 'components/Security/Authentication';
import { Systems } from '../../common/Systems';

const hasAccess = isAdmin();

export const AdminSystemsPage = () => <Systems />;

export const SystemsPage = compose(
        withHMELayout({
            contentClasses: ['systems-page'],
        }),
        authenticate,
        withAccess({
            checkAccess: () => hasAccess
        })
)(AdminSystemsPage);
