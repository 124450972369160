import { useState, useEffect, useCallback } from 'react';

import { CalendarView } from './CalendarView';

export const SingleCalendar = ({
    value,
    months,
    daysLimit,
    minDate = null,
    maxDate = null,
    onDateSelect,
    onChange,
    ...calendarProps
}) => {
    const [shownRange, setShownRange] = useState(null);

    const onDateClick = useCallback((date) => {
        onDateSelect && onDateSelect(date);
        onChange && onChange(date);
    }, [onChange, onDateSelect]);

    const onReset = useCallback(() => {
        onDateSelect && onDateSelect(null);
        onChange && onChange(null);
    }, [onDateSelect, onChange]);

    useEffect(() => {
        setShownRange(value && {
            startDate: value,
            endDate: value,
        });
    }, [value]);

    return (
        <CalendarView
            range={shownRange}
            months={months}
            isSelectionMode={false}
            minDate={minDate}
            maxDate={maxDate}
            onReset={onReset}
            onDateClick={onDateClick}
            {...calendarProps}
        />
    );
};
