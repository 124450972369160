/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tip } from 'library/Tip';
import { TreeInput } from 'library/TreeInput';
import { Label } from 'library/Label';

import { DaypartSelection } from '../DaypartSelection';
import { StoresHierarchyLevel } from '../StoresHierarchyLevel';
import { FieldsetTitle } from '../FieldsetTitle';

export const SectionThree = ({
    storesTree,
    assigned,
    setAssigned,
    isEdit,
    isSchedulerDataReady,
    hierarchyLevels,
    isStoresReady,
    errorMessage,
    availableDayparts,
    setFormValues,
    formValues
}) => {
    const { t } = useTranslation();
    const isDataReady = isEdit ? isSchedulerDataReady : isStoresReady;

    const onDaypartsChange = (dayparts)=> {
        setFormValues({
            daypartIDs: dayparts
        });
    };

    const isMultiStoreReport = formValues.reportType === 'multi';
    const isSingleStoreReport = formValues.reportType === 'single';

    return (
        <>
            <FieldsetTitle>{t('scheduler__create-header-three')}</FieldsetTitle>

            <div className="create-edit-scheduler__fields-wrapper">
                <div>
                    <Label className="create-edit-scheduler__field-label">{t('common__stores')}</Label>
                    {' '}
                    <Tip className="create-edit-scheduler__tooltip">{t('scheduler__report-stores-info-message')}</Tip>

                    {errorMessage && <p className="error">{errorMessage}</p>}

                    <div className="create-edit-scheduler__tree-selector-wrapper">
                        {isDataReady && (
                            <TreeInput
                                tree={storesTree}
                                value={assigned}
                                onChange={setAssigned}
                                className="hme-tree-input__box-scheduler"
                            />
                        )}
                    </div>
                </div>
                {(isMultiStoreReport || isSingleStoreReport) && formValues.timeMeasureID === 1 && (
                    <DaypartSelection
                        dayparts={availableDayparts}
                        onChange={onDaypartsChange}
                        currentDaypartSelection={formValues}
                    />
                )}
                {isMultiStoreReport && (
                    <StoresHierarchyLevel
                        isEdit={isEdit}
                        hierarchyLevels={hierarchyLevels}
                        formValues={formValues}
                        setFormValues={setFormValues}
                    />
                )}
            </div>
        </>
    );
};
