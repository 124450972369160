import { path } from 'ramda';
import { taskConfig } from 'constants/applyDeviceSettingsTask';
const { taskInProgressStatuses } = taskConfig;

export const getRowClassName = job => {
    const hasInProgress = job.destinationDevices.some(
        device => taskInProgressStatuses.includes(device.ApplySettingsStatus)
    );

    return hasInProgress ? '' : 'apply-job-device-row-completed';
}

export const getJobRowClassName = (property, jobSection) => {
    let className = '';

    if (property.startsWith('destinationDevices')) {
        const status = path([
            'destinationDevices',
            'ApplySettingsStatus'
        ], jobSection);

        className = taskInProgressStatuses.includes(status) ? '' : 'apply-completed-job-cell';
    }

    return className;
};