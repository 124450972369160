import React, { useCallback } from 'react';
import { compose } from 'ramda';
import { withHMELayout } from 'HOCs/withHMELayout';
import { NotificationsList } from 'library/NotificationsList';
import { Title } from 'library/Title';
import { Button } from 'library/Button';
import { addNotification, dismissNotification, NOTIFICATION_TYPES } from 'services/Notifications';
import { SearchInput } from 'components/Inputs';
import { Label } from 'library/Label';
import { Grid } from 'components/Common/Grid';
import './ManageBlockedEmails.scss';
import { useState } from 'react';
import { searchBlockedEmail, unblockEmail } from 'api/unblockEmailApi';
import { Modal } from 'library/Modal';
import { CenterLoader } from 'components/Common/CenterLoader';

const descriptionLength = 130;
const gridHeaders = [{
    text: 'RECIPIENT',
    property: 'Recipient',
    flex: 1
}, {
    text: 'DESCRIPTION',
    property: 'composedDescription',
    flex: 3
}, {
    text: 'TIMESTAMP',
    property: 'Timestamp',
    flex: 1
}, {
    text: '',
    property: 'Button',
    flex: 1.2
}];

// eslint-disable-next-line max-len
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ManageBlockedEmailsPage = () => {
    const [currentEmail, setCurrentEmail] = useState('');
    const [searchResults, setSearchResults] = useState();
    const [isUnblockingLoader, setIsUnblockingLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [emailSearchValue, setEmailSearchValue] = useState('');
    const onEmailChange = useCallback((value) => {
        const isValid = EMAIL_REGEX.test(value);
        setCurrentEmail(isValid ? value : '');
        setEmailSearchValue(value);
    }, [currentEmail, emailSearchValue]);
    const onUnblockEmailClick = async ({ target } = {}) => {
        dismissNotification();
        try {
            const { value: suppressionType } = target || {};
            setIsUnblockingLoader(true);
            const result = await unblockEmail({ email: currentEmail, suppressionType });
            if (result && result['IsDeleted']) {
                addNotification({
                    message: 'The email address was successfully unblocked.',
                    type: NOTIFICATION_TYPES.SUCCESS
                });
                return;
            }
            const message = result && result['Description'] || 'The email address cannot be unblocked.';
            addNotification({
                message,
                type: NOTIFICATION_TYPES.ERROR
            });
        } catch (e) {
            addNotification({
                message: 'The email address cannot be unblocked.',
                type: NOTIFICATION_TYPES.ERROR
            });
        } finally {
            setSearchResults();
            setCurrentEmail('');
            setEmailSearchValue('');
            setIsUnblockingLoader(false);
        }
    };

    const formatResults = (row) => {
        const type = row['SuppressionType'] || '';
        const description = row['Description'] || '';
        const opttionalDash = type && description ? ' - ' : '';
        const composedDescription = `${type}${opttionalDash}${description}`;
        const truncatedDescription =
            composedDescription.length > descriptionLength ?
            `${composedDescription.replace('\n', ' ').slice(0, descriptionLength).trim()}...` :
            composedDescription;
        row.composedDescription = truncatedDescription;
        row['Button'] = (
            <Button
                value={row['SuppressionType']}
                variants={['submit']}
                disabled={false}
                onClick={onUnblockEmailClick}
            >
            Unblock Email
            </Button>
        );
        return row;
    };
    const onSearch = async () => {
        dismissNotification();
        setIsLoading(true);
        try {
            const result = await searchBlockedEmail({ email: currentEmail });
            if (!result) {
                return setSearchResults([]);
            }
            const searchResult = formatResults(result);
            setSearchResults([searchResult]);
        } catch (e) {
            return setSearchResults([]);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="hme-page-component manage-blocked-emails-page">
            <Modal show={isUnblockingLoader} dialogClassName="manage-blocked-emails-page-modal">
                <CenterLoader>The email address is being unblocked…</CenterLoader>
            </Modal>
            <NotificationsList />
            <Title>Manage Blocked Emails</Title>
            <div className="search-block">
                <Label>EMAIL ADDRESS</Label>
                <SearchInput
                    onChange={onEmailChange}
                    onSearchIconClick={onSearch}
                    onKeyUp={({ key } = {}) => key === 'Enter' && onSearch()}
                    value={emailSearchValue}
                    placeholder="Type an email address"
                    autoFocus={true}
                />
                <Button
                    disabled={!currentEmail}
                    onClick={onSearch}
                >
                    Search for Email
                </Button>
            </div>
            {(isLoading || Boolean(searchResults)) && <div className="results-block">
                <Label>SEARCH RESULTS</Label>
                {(isLoading || searchResults.length) ?
                <Grid
                    rows={searchResults || []}
                    headers={gridHeaders}
                    rowKey="Recipient"
                    isLoading={isLoading}
                /> :
                <span className="not-found-message">No email addresses were found.</span>}
            </div>}
        </div>
    );
};

export const ManageBlockedEmails =compose(
        withHMELayout()
)(ManageBlockedEmailsPage);
