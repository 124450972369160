import React from 'react';

export const CountryOptions = ({ countryList, selectedCountryId }) => {
    if (countryList != null && countryList.length > 0) {
        return countryList.map((data, index) => {
            if (selectedCountryId === data.Id) {
                return <option key={index} value={data.Id} selected={selectedCountryId === data.Id}>{data.Name}</option>;
            } else {
                return <option key={index} value={data.Id}>{data.Name}</option>;
            }
        });
    }
};
