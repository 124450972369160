import { upgradeStatuses } from '../constants';

export const formatUpgradeStatus = ({
    DeviceUpgradedCount,
    CancelledCount,
    FailedCount,
    DeviceCount,
    ScheduledCount,
    PendingApprovalCount,
}) => {
    const sumOfProcessedDevices = DeviceUpgradedCount + CancelledCount + FailedCount;

    switch (true) {
        case ScheduledCount === DeviceCount:
            return upgradeStatuses.SCHEDULED;
        case CancelledCount === DeviceCount:
            return upgradeStatuses.CANCELLED;
        case FailedCount === DeviceCount:
            return upgradeStatuses.FAILED;
        case sumOfProcessedDevices === DeviceCount:
            return upgradeStatuses.COMPLETED;
        case ScheduledCount + CancelledCount === DeviceCount:
            return upgradeStatuses.SCHEDULED;
        case PendingApprovalCount === DeviceCount:
            return upgradeStatuses.PENDING_APPROVAL;
        default:
            return upgradeStatuses.IN_PROGRESS;
    }
};
