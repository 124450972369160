import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { types as companyTypes } from 'constants/company';
import { PUBLIC_ROUTES } from 'constants/routes';

// TODO: Add logic regarding 'Admin' pesmissions
export const SystemInformationAccountLink = ({ device, profile, className = '' }) => {
    if (!device || !profile) return null;

    const { Company_Type: userCompanyType, User_ID: userId, User_EmailAddress: userEmailAddress } = profile;
    const { Store_Account_ID: storeAccountId, User_EmailAddress: deviceEmailAddress, User_UID: deviceUserUID } = device;

    const isCopropation = userCompanyType === companyTypes.CORPORATION;
    const isDistributor = userCompanyType === companyTypes.DISTRIBUTOR;

    const isDeviceBelongsToCurrentUser = userId === storeAccountId;

    if (isDistributor && !isDeviceBelongsToCurrentUser) {
        return (
            <Link className={className} to={`/accounts/account?type=${userCompanyType}&uuid=${deviceUserUID}`}>
                {deviceEmailAddress}
            </Link>
        );
    }

    if (isCopropation && !isDeviceBelongsToCurrentUser) {
        return <span className={className}>{deviceEmailAddress}</span>;
    }

    return <Link to={`/${PUBLIC_ROUTES.account}`} className={className}>{userEmailAddress}</Link>;
};

SystemInformationAccountLink.propTypes = {
    device: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    className: PropTypes.string
};
