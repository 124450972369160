import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { commonErrors } from 'constants/errors';
import { createFilteredURLSearchParams, deleteDataAsync, getDataAsync, getSearchString, postDataAsync } from 'Api';

const { apiBaseUrl } = Config;
const { apiUrls } = CommonConstants;

const errors = {
    [commonErrors.INVALID_DATA]: 'common__error--invalid-data',
    [commonErrors.INTERNAL_SERVER]: 'common__error--internal-server',
    [commonErrors.NO_RECORDS_FOUND]: 'common__error--no-records-found'
};

export const fetchTokens = async () => {
    const url = `${Config.apiBaseUrl}${apiUrls.getManagementTokens}`;

    let response;

    try {
        response = await getDataAsync(url);
    } catch (_err) {
        throw new Error('common__error--internal-server');
    }

    if (!response.status) {
        const errorKey = errors[response.key] || 'common__error--internal-server';

        throw new Error(errorKey);
    }

    return response.tokens;
};

export const deleteToken = async (id) => {
    const url = `${Config.apiBaseUrl}${apiUrls.deleteManagementToken(id)}`;

    let response = {};

    try {
        response = await deleteDataAsync(url);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (response.status) {
        return;
    }

    const errorKey = errors[response.key] || 'common__error--internal-server';

    throw new Error(errorKey);
};

/**
 * Get Token by UID
 * @param  {string} tokenUID UID of the provider
 * @return {object} provider details
 */
export const fetchTokenByUID = async (tokenUID) => {
    const url = `${apiBaseUrl}${apiUrls.getAPITokenByUID(tokenUID)}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.token;
};

export const fetchPartnerToken = async () => {
    const url = `${apiBaseUrl}${apiUrls.getPartnerToken}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('Request failed');
    }
    return response.data;
};


export const fetchPartnerTokenByUID = async (tokenUID) => {
    const url = `${apiBaseUrl}${apiUrls.getPartnerToken}/${tokenUID}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('Request failed');
    }
    return response.data;
};


export const fetchPartnerTokens = async () => {
    const url = `${apiBaseUrl}${apiUrls.getPartnerToken}`;

    let response;

    try {
        response = await getDataAsync(url);
    } catch (_err) {
        throw new Error('common__error--internal-server');
    }

    if (!response.status) {
        const errorKey = errors[response.key] || 'common__error--internal-server';

        throw new Error(errorKey);
    }

    return response.tokens;
};

export const renewAPITokenPartner = (updatedTokenDetails = {}) => {
    const url = `${apiBaseUrl}${apiUrls.renewPartnerAPIToken}`;

    return postDataAsync(url, updatedTokenDetails);
};

/**
 * Create new API Token
 * @param  {object} newTokenDetails details of new provider
 * @return {object} response
 */
const errorKeys = {
    tokenNameExists: 'api-token-management__error--token-name-exists',
    missingParams: 'api-token-management__error--missing-params',
    partnerTypeIsNotAllowed: 'partner__form__error--not-allowed-partner-type'
};

export const createAPIToken = async (newTokenDetails) => {
    const url = `${apiBaseUrl}${apiUrls.createAPIToken}`;

    const response = await postDataAsync(url, newTokenDetails);

    if (!response.status) {
        let message = '';
        if (response.key in errorKeys) {
            message = errorKeys[response.key];
        } else {
            message = response.message || 'api-token-management__error--token-creation';
        }
        throw new Error(message);
    }
    return response.data;
};

/**
 * Update API Token details
 * @param  {object} updatedTokenDetails details of token
 * @return {object} response
 */
export const updateAPIToken = async ({ tokenUID, ...updatedTokenDetails }) => {
    const url = `${apiBaseUrl}${apiUrls.updateAPITokenByUID(tokenUID)}`;

    const response = await postDataAsync(url, updatedTokenDetails);

    return response.data;
};

/**
 * Renew Token api
 * @param  {object} updatedTokenDetails details of token
 * @return {object} response
 */
export const renewAPIToken = (updatedTokenDetails = {}) => {
    const url = `${apiBaseUrl}${apiUrls.renewAPIToken}`;

    return postDataAsync(url, updatedTokenDetails);
};

/**
 * Fetch Partner Users by Email substring
 * @param {Object} params - The parameters for fetching partner users
 * @param {string} params.emailSearchQuery - The email search query substring
 * @param {number} params.limit - The maximum number of users to fetch
 * @return {object} response - The response from the API
 */
export const fetchPartnerUsersByEmail = (queryParams = {}, { signal } = {}) => {
    const url = `${apiBaseUrl}${apiUrls.getPartnerUsersByEmail}?${createFilteredURLSearchParams(queryParams).toString()}`;

    return getDataAsync(url, {}, signal);
};

/**
 * Fetches the providers linked to a specific partner.
 *
 * @param {Object} params - The parameters for fetching providers.
 * @param {string} params.partnerUID - The unique identifier of the partner.
 * @param {Object} [options] - Additional options for the request.
 * @param {AbortSignal} [options.signal] - An AbortSignal to abort the request.
 * @return {Promise<any>} A promise that resolves with the data from the API response.
 */
export const fetchProvidersLinkedToPartner = ({ partnerUID }, { signal } = {}) => {
    const url = `${apiBaseUrl}${apiUrls.getTokenPartnersProviders}?${getSearchString({ partnerUID })}`;

    return getDataAsync(url, {}, signal);
};

/**
 * Get HME Cloud apis
 * @return {object} response
 */
export const fetchHMEApis = async () => {
    const url = `${apiBaseUrl}${apiUrls.getCloudAPI}`;

    const response = await getDataAsync(url);
    return response;
};
