/* eslint-disable consistent-return */
import React, { useEffect, useCallback, useState } from 'react';

import { isLoggedIn, isMasquerade, refresh, refreshIdToken, secondsUntilTokenExpires } from 'services/Auth';
import { Config } from 'Config';
import { Navigate } from 'react-router-dom';
import { COMMON_ROUTES } from 'constants/routes';

export const useRefreshToken = () => {
    const [refreshCount, setRefreshCount] = useState(0);
    const [adminRefreshCount, setAdminRefreshCount] = useState(0);

    const checkRefreshToken = useCallback(async () => {
        if (!isLoggedIn()) {
            return <Navigate to={COMMON_ROUTES.logout} />;
        }
        await refresh();
        setRefreshCount((prevCount) => prevCount + 1);
    }, []);

    /**
     * Check if the admin token needs to be refreshed
     * Admin token refreshed when in masquerade mode
     * @returns {Promise<void>}
    */
    const checkAdminRefreshToken = useCallback(async () => {
        if (!isLoggedIn() || !isMasquerade()) {
            return;
        }
        await refreshIdToken();
        setAdminRefreshCount((prevCount) => prevCount + 1);
    }, []);

    useEffect(() => {
        if (!isLoggedIn()) {
            return;
        }

        const secondsRemaining = secondsUntilTokenExpires();
        const delay = (secondsRemaining - Config.tokenRefresh) * 1000;

        if (delay > 0) {
            const timer = setTimeout(checkRefreshToken, delay);

            return () => clearTimeout(timer);
        } else {
            checkRefreshToken();
        }
    }, [checkRefreshToken, refreshCount]);

    // Using it to refresh id token when in masquerade mode
    useEffect(() => {
        if (!isLoggedIn() || !isMasquerade()) {
            return;
        }

        const adminSecondsRemaining = secondsUntilTokenExpires(true);
        const adminDelay = (adminSecondsRemaining - Config.tokenRefresh) * 1000;

        if (adminDelay > 0) {
            const adminTimer = setTimeout(checkAdminRefreshToken, adminDelay);

            return () => clearTimeout(adminTimer);
        } else {
            checkAdminRefreshToken();
        }
    }, [checkAdminRefreshToken, adminRefreshCount]);

    return {
        checkRefreshToken,
        checkAdminRefreshToken
    };
};
