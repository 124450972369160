export const upgradeStatuses = {
  SENT: 'Sent',
  IN_PROGRESS: 'In Progress',
  SCHEDULED: 'Scheduled',
  FAILED: 'Failed',
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed',
  PENDING_APPROVAL: 'Pending Approval',
};

export const nexeoUpgradeStatus = {
  0: 'Created',
  1: 'Sent',
  2: 'Downloading',
  3: 'Downloaded',
  4: 'Installing',
  5: 'Installed',
  6: 'Failed',
};
