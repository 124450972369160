import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnTrigger } from 'hooks/useTrigger';
import { Loader } from 'library/Loader';
import { useLoadCountBrandDevicesByCountryTimezone } from '../../../hooks/useLoadCountBrandDevicesByCountryTimezone';
import { BrandSelect } from './BrandSelect';
import { CountrySelect } from './CountrySelect';
import { TimezoneGrid } from './TimezoneGrid';
import { CommonConstants } from 'Constants';

const { deviceType } = CommonConstants;

import './BrandForm.css';

export const BrandForm = ({
    brands,
    deviceVersion,
    targetDeviceVersions,
    selectedDeviceType,
    selectedPeripheralDeviceType,
    brandFilters,
    onSelectDevices,
    onSelectTimezone,
    removeDevicesTrigger,
    changeUpgradeInfoTrigger,
}) => {
    const [brandID, setBrandID] = useState('');
    const [brandIDs, setBrandIDs] = useState([]);
    const [countryID, setCountryID] = useState('');
    const [selectedDeviceTimezones, setSelectedDeviceTimezones] = useState([]);
    const [selectedDeviceTypeId, setSelectedDeviceTypeId] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        if (!selectedDeviceType) {
            return;
        }

        setSelectedDeviceTypeId(deviceType[selectedDeviceType].id);
    }, [selectedDeviceType, selectedPeripheralDeviceType]);

    const { countryTimezoneDevicesCount, isCountryTimezoneDevicesCountLoading } =
        useLoadCountBrandDevicesByCountryTimezone({
            brandIDs,
            targetDeviceVersions,
            deviceType: selectedDeviceType,
            peripheralDeviceType: selectedPeripheralDeviceType
        });

    // filter unique countries
    const brandCountries = useMemo(
        () =>
            countryTimezoneDevicesCount.reduce(
                (acc, item) =>
                    acc.some(({ Store_Country_ID }) => Store_Country_ID === item.Store_Country_ID)
                        ? acc
                        : [...acc, item],
                [],
            ),
        [countryTimezoneDevicesCount],
    );

    const timezoneDevicesCountByCountry = useMemo(
        () => countryTimezoneDevicesCount.filter(({ Store_Country_ID }) => Store_Country_ID === +countryID),
        [countryTimezoneDevicesCount, countryID],
    );

    useEffect(() => {
        setCountryID('');
    }, [brandIDs]);

    useEffect(() => {
        setSelectedDeviceTimezones([]);
        onSelectDevices([]);
    }, [countryID]);

    useEffect(() => {
        onSelectTimezone({
            brandIDs,
            sourceVersion: deviceVersion,
            targetDeviceVersions,
            deviceType: selectedDeviceType,
            peripheralDeviceType: selectedPeripheralDeviceType,
            countryID,
            timezones: selectedDeviceTimezones,
        });
    }, [selectedDeviceTimezones]);

    useEffect(() => {
        const { excludedBrandDevicesByTimezone } = brandFilters;
        const filteredSelectedDeviceTimezones = selectedDeviceTimezones.filter(
            (deviceTimezone) =>
                deviceTimezone.quantity > (excludedBrandDevicesByTimezone[deviceTimezone.timezone]?.count || 0),
        );

        // uncheck timezone if all the devices have been excluded from it
        if (selectedDeviceTimezones.length !== filteredSelectedDeviceTimezones.length) {
            setSelectedDeviceTimezones(filteredSelectedDeviceTimezones);
        }
    }, [brandFilters]);

    const onDevicesRemove = useCallback(() => {
        setSelectedDeviceTimezones([]);
    }, []);

    const onChangeUpgradeType = useCallback(() => {
        setBrandID('');
        setBrandIDs([]);

        setCountryID('');
        setSelectedDeviceTimezones([]);
    }, []);

    useOnTrigger(removeDevicesTrigger, onDevicesRemove);
    useOnTrigger(changeUpgradeInfoTrigger, onChangeUpgradeType);

    const onBrandsChange = useCallback((brandOptions) => {
        setBrandIDs(brandOptions.map(({ value }) => value));
    }, []);

    return (
        <div className="hme-create-device-upgrade-brand-form">
            <div className="hme-create-device-upgrade-form-section">
                <BrandSelect
                    label={'common__brand'}
                    brands={brands}
                    value={brandID}
                    onChange={onBrandsChange}
                    deviceTypeID={selectedDeviceTypeId}
                />
            </div>

            {isCountryTimezoneDevicesCountLoading ? (
                <Loader variants={['invert']} />
            ) : (
                <>
                    {brandIDs.length > 0 && !countryTimezoneDevicesCount.length && (
                        <div className="hme-create-device-upgrade-devices-not-found">
                            {t('common__no-devices--found')}
                        </div>
                    )}

                    {brandIDs.length > 0 && countryTimezoneDevicesCount.length > 0 && (
                        <div className="hme-create-device-upgrade-form-section">
                            <CountrySelect
                                label={'common__country'}
                                countries={brandCountries}
                                value={countryID}
                                onChange={setCountryID}
                            />
                        </div>
                    )}

                    {brandIDs.length > 0 && countryID && countryTimezoneDevicesCount.length > 0 && (
                        <TimezoneGrid
                            countryTimezoneDevicesCount={timezoneDevicesCountByCountry}
                            selectedDeviceTimezones={selectedDeviceTimezones}
                            onDeviceTimezoneSelection={setSelectedDeviceTimezones}
                        />
                    )}
                </>
            )}
        </div>
    );
};
