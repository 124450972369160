import { DateLib } from '@hme-cloud/utility-common';

const defaultSort = (contests, sortByField, direction) => {
    const defaultSortFunc = (val1, val2) => {
        return direction *
        val1[sortByField].toLowerCase().localeCompare(val2[sortByField].toLowerCase());
    };
    return contests.toSorted(defaultSortFunc);
};

const sortByDate = (contests, sortBy, direction) => {
    const sortFunc = (val1, val2) => {
        const currentDate = new DateLib(val1[sortBy]);
        const nextDate = new DateLib(val2[sortBy]);

        return direction * (currentDate.getDate() - nextDate.getDate());
    };

    return contests.toSorted(sortFunc);
};

export const getSortedRows = (contests, sortSelection) => {
    const [[sortByField, sortDirection]] = Object.entries(sortSelection);

    switch (sortByField) {
        case 'FormattedStartDate': {
            return sortByDate(contests, 'StartDate', sortDirection);
        }
        case 'FormattedEndDate': {
            return sortByDate(contests, 'EndDate', sortDirection);
        }
        case 'ContestStatusComponent': {
            return sortByDate(contests, 'ContestStatus', sortDirection);
        }
        default:
            return defaultSort(contests, sortByField, sortDirection);
    }
};
