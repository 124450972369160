import React from 'react';
import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';

export const Header = () => {
    const { t } = useTranslation();

    return <div className="hme-partner-token-management__header">
        <Title>{t('partner__token-management')}</Title>
    </div>;
};
