import { formatRegistrationKey } from './formatRegistrationKey';

/**
 * Format AX Account number and Registration Key to a single string
 * @param {string} device AX Account / Customer number
 * @param {string} device Registration Key
 * @returns {string}
 */ 
export const formatDeviceCustomerNumberKey = (customerNumber, registrationKey) => {
    return [
        customerNumber || '-',
        formatRegistrationKey(registrationKey),
    ].join(' / ');
};
