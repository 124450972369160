export const vaioSettings = {
    audioModes: {
        continuous: 'Continuous',
        vehiclePresent: 'Vehicle Present',
    },
    locationTypes: {
        cloudBased: 'Cloud Based',
        onPremise: 'On Premise',
    },
    botAudioSourceTypes: {
        unprocessed: 1,
        semiProcessed: 2,
        processed: 3,
    },
    outboundAudioTypes: {
        variable: 'Variable',
        fixed: 'Fixed',
    },
    botChannelTypes: {
        '0': 0,
        '1': 1,
    }
};
