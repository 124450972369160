import { PARTNER_ROUTES } from 'constants/routes';
import { Button } from 'library/FormControllers';
import React from 'react';
import { Link } from 'react-router-dom';

const DeviceSerialNumber = ({ serialNumber, duid }) => <Button asChild variant="link">
    <Link to={`/${PARTNER_ROUTES.device(duid)}`}>{serialNumber}</Link>
</Button>;

export const StoreSerialNumbers = ({ devices = [] }) => {
    return (
        <div className="hme-stores-status--public__grid__device-serial-number">
            {devices.map((device) => <DeviceSerialNumber
                key={device.Device_SerialNumber}
                serialNumber={device.Device_SerialNumber}
                duid={device.Device_UID}
            />)}
        </div>
    );
};
