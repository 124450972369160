import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Constant } from '@hme-cloud/utility-common';
import { DeviceUpgradeStatus } from '../../DeviceUpgradeStatus';
import { getUpgradeStatusCell } from '../statuses';
import { Grid } from 'components/Common/Grid';
import { Tip } from 'library/Tip';
import { PeripheralActiveStatus } from '../PeripheralActiveStatus';

const { BuildVersions } = Constant;
import './PeripheralDevicesList.scss';

const gridOptions = [
    {
        flex: 1.1,
        property: 'modelName',
        className: 'hme-grid-cell-show-all-content hme-grid-model-name',
    },
    {
        flex: 1.1,
        property: 'serialNumber',
        className: 'hme-grid-cell-show-all-content hme-grid-serial-col',
    },
    {
        text: 'common__device__product-id',
        flex: 1,
        property: 'productId',
        className: 'hme-grid-cell-show-all-content hme-grid-product-id-col',
    },
    {
        flex: 1,
        property: 'lane',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
    {
        flex: 1,
        property: 'lastUpgradeData',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
    {
        flex: 1.1,
        property: 'mainVersion',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
    {
        flex: 1.1,
        property: 'onLatestVersion',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell hme-on-latest-version',
    },
    {
        flex: 1.1,
        property: 'upgradeStatus',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
    {
        flex: 1.1,
        property: 'currentUpgrade',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
    {
        flex: 1.4,
        property: 'actionsComponent',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-with-outside-content hme-grid-centered-cell',
    },
];

const deviceToRow = ({ data, device, t }) => {
    const { currentUpgradeTask, lastUpgradeTask } = data.isMultiple ? device.upgradeTasks : data.upgradeTasks;
    const maxUpgradeVersion = data.isMultiple ? device.maxUpgradeVersion : data.maxUpgradeVersion;
    const onLatestVersion =
        !maxUpgradeVersion ||
        (device.mainVersion && BuildVersions.semverGte(device.mainVersion, maxUpgradeVersion))
            ? t('common__yes')
            : t('common__no');
    const deviceSerialNum = device.serialNumber;
    const getUpgradeStatusFor = currentUpgradeTask || lastUpgradeTask ? (currentUpgradeTask || lastUpgradeTask) : '';
    return {
        modelName: <div>
            <PeripheralActiveStatus device={device} isBaseStationActive={data.isBaseStationActive} />
            {device.modelName}
        </div>,
        serialNumber: device.macAddress ? (
            <span className='hme-grid-serial-number'>
              {device.serialNumber}
              <Tip
                className='hme-admin-system-status-tooltip__tip'
                withCloseButton
                title={t('system-status__mac-address').toUpperCase()}>
                {device.macAddress}
              </Tip>
            </span>
          ) : device.serialNumber,
        macAddress: device.macAddress,
        lane: device.lane ? (t('common__lane') + ' ' + device.lane) : null,
        mainVersion: device.mainVersion,
        latestFirmwareAvailable: maxUpgradeVersion,
        onLatestVersion,
        lastUpgradeData: lastUpgradeTask ? (
            <DeviceUpgradeStatus
                title="admin-system-status__upgrade-title--latest"
                task={lastUpgradeTask}
                serialNumber={device.serialNumber}
                isPeripheralDevice={true}
            />
        ) : null,
        upgradeStatus:
           getUpgradeStatusFor ? getUpgradeStatusCell(getUpgradeStatusFor, deviceSerialNum) : '',
        currentUpgrade: currentUpgradeTask ? (
            <DeviceUpgradeStatus
                title="admin-system-status__upgrade-title--current"
                task={currentUpgradeTask}
                serialNumber={device.serialNumber}
                isPeripheralDevice={true}
            />
        ) : null,
    };
};

export const PeripheralDevicesList = ({ data }) => {
    const [rows, setRows] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        setRows(data.devices.map(device => deviceToRow({ data, device, t })));
    }, [data, setRows]);

    return (
        <Grid
            className='hme-peripheral-devices-list'
            rows={rows}
            headers={gridOptions}
            noRecordsMessage={t('common__device-peripheral-devices-absence')}
        />
    );
};
