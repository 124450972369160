import React from 'react';
import { compose } from 'ramda';
import { CommonConstants } from 'Constants';
import { withHMELayout } from 'HOCs/withHMELayout';
import { isAdmin } from 'services/Auth';
import { checkAccess } from 'actions/permissions';
import authenticate from 'components/Security/Authentication';
import { withAccess } from 'HOCs/withAccess';
import { accountTypes } from 'constants/accountTypes';
import { getApplySettingsTaskHistory } from '../../DeviceSettings/DeviceSettingsService';
import { DeviceSettings } from '../../DeviceSettings';

import './ApplyDeviceSettingsPage.scss';

const loadAllJobs = async (dateRange, setJobs, setAreJobsLoading) => {
    setAreJobsLoading(true);

    try {
        const tasks = await getApplySettingsTaskHistory({ dateRange });

        if (tasks.status) {
            setJobs(tasks.data || []);
        }
    } catch (err) {
        setJobs([]);
    }

    setAreJobsLoading(false);
};

const hasAccess = () => isAdmin() && checkAccess(CommonConstants.adminPermissions.CreateSettingSnapshot);

export const AdminApplyDeviceSettings = () => (
    <DeviceSettings permissionType={accountTypes.ADMIN} loadAllJobs={loadAllJobs} />
);

export const ApplyDeviceSettingsPage = compose(
    withHMELayout({ variants: ['extended'] }),
    authenticate,
    withAccess({
        checkAccess: hasAccess,
    }),
)(AdminApplyDeviceSettings);
