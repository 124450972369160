import React, { useState, useEffect } from 'react';

import { InfoField } from 'library/InfoField';

const getBrandName = (brandId, brands) => {
    const brand = brands.find(({ Id }) => Id === brandId) || {
        Name: 'Unknown'
    };

    return brand.Name;
};

const getCountryName = (countryId, countries) => {
    const country = countries.find(({ Id }) => Id === countryId) || {
        Name: 'Unknown'
    };

    return country.Name;
};

export const Brand = ({ task, brands, countries }) => {
    const [brand, setBrand] = useState('');
    const [country, setCountry] = useState('');

    useEffect(() => {
        const newBrand = getBrandName(task.AdditinionalParams.searchParams.storeBrandId, brands);
        setBrand(newBrand);
    }, [task.AdditinionalParams.searchParams.storeBrandId, brands, setBrand]);

    useEffect(() => {
        const newCountry = getCountryName(task.AdditinionalParams.searchParams.storeCountryId, countries);
        setCountry(newCountry);
    }, [task.AdditinionalParams.searchParams.storeCountryId, countries, setCountry]);

    return (
        <>
            <InfoField label='Search By'>
                Brand
            </InfoField>
            <InfoField label='Selected Brand'>
                {brand}
            </InfoField>
            <InfoField label='Selected Country'>
                {country}
            </InfoField>
        </>
    );
};
