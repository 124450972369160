/* eslint-disable react/prop-types */
import './TextArea.scss';
import React from 'react';

export const TextArea = ({
    onChange,
    rows = 4,
    cols = 53,
    maxLength = 100,
    hideResize = false,
    isRequired = false,
    children,
    showButton = false,
    buttonText = '',
    onButtonClick,
    ...props
}) => {
    return (
        <div className="textarea-container">
            <textarea
                rows={rows}
                onChange={onChange}
                cols={cols}
                className={`
                    leaderboard-desc-textbox 
                    hme-input-component 
                    ${hideResize ? 'textarea-hide-resize' : ''} 
                    ${showButton ? 'textarea-add-top-padding' : ''}
                `}
                maxLength={maxLength}
                {...props}
            >
                {children}
            </textarea>
            {isRequired && (
                <span className="hme-textarea-component-before">*</span>
            )}
            {showButton && (
                <button className="textarea-button" onClick={onButtonClick}>
                    {buttonText}
                </button>
            )}
        </div>
    );
};