import { Constant } from '@hme-cloud/utility-common';
import { CommonConstants } from 'Constants';
import { NEXEO_PERIPHERAL_TYPES } from 'constants/NEXEOPeripheralTypes';
import {
    DEVICE_ERROR_CODES,
    MIN_TEXT_CONNECT_NEXEO_VERSION,
    MIN_TEXT_CONNECT_VOICE_ENGINE_VERSION,
    VALID_TEXT_CONNECT_SYSTEM_TIERS,
} from '../constants';

const { BuildVersions } = Constant;
const { deviceType } = CommonConstants;

const excludeVersionBuildType = (version) => {
    const { major, minor, patch } = BuildVersions.parseVersion(version);

    return `${major}.${minor}.${patch}`;
}

/**
 * Check if a specific device has a valid voice engine
 *
 * @param {*} device
 * @returns { status: boolean, errorCode: string} status of voice engine
 */
const checkVoiceEngineStatus = (device) => {
    const voiceEngineStatuses = (device.peripheralDevices || [])
        .filter((peripheralDevice) => {
            const { deviceInfo = {} } = peripheralDevice;
            return deviceInfo.deviceType === NEXEO_PERIPHERAL_TYPES.VOICEENGINE.modelName
        })
        .map((peripheralDevice) => {
            const { deviceInfo = {} } = peripheralDevice;

            // check if the version is not empty and valid, then compare it without the build type
            const isValidVoiceEngineVersion =
                !!deviceInfo.deviceVersion &&
                !!BuildVersions.formatVersion(deviceInfo.deviceVersion) &&
                BuildVersions.semverGte(
                    excludeVersionBuildType(deviceInfo.deviceVersion),
                    MIN_TEXT_CONNECT_VOICE_ENGINE_VERSION,
                );

            const isVoiceEngineOnline = peripheralDevice?.isActive === 1;
            const isVoiceEngineEnabled = deviceInfo.status === 1;

            return {
                isValidVoiceEngineVersion,
                isVoiceEngineOnline,
                isVoiceEngineEnabled,
                isValidVoiceEngine: isValidVoiceEngineVersion && isVoiceEngineOnline && isVoiceEngineEnabled,
            };
        });

    const onlineVoiceEngine = voiceEngineStatuses.find((status) => status.isVoiceEngineOnline);

    // throw an error in case all VEs don't have a valid version or the online VE doesn't have a valid version
    if (
        !voiceEngineStatuses.some((status) => status.isValidVoiceEngineVersion) ||
        (onlineVoiceEngine && !onlineVoiceEngine.isValidVoiceEngineVersion)
    ) {
        return {
            status: false,
            errorCode: DEVICE_ERROR_CODES.INVALID_VOICE_VERSION,
        };
    }

    // throw an error in case all VEs are not available or the online VE doesn't have voice commands enabled
    if (
        !voiceEngineStatuses.some((status) => status.isVoiceEngineOnline && status.isVoiceEngineEnabled) ||
        (onlineVoiceEngine && !onlineVoiceEngine?.isVoiceEngineEnabled)
    ) {
        return {
            status: false,
            errorCode: DEVICE_ERROR_CODES.VOICE_COMMAND_DISABLED,
        };
    }

    return {
        status: true,
    };
};

/**
 * Check if any of the devices has a valid voice engine
 *
 * @param {*} devices
 * @returns { status: boolean, errorCode: string } status of voice engine
 */
const checkVoiceEngine = (devices) => {
    const voiceEngineStatuses = devices.map((device) => checkVoiceEngineStatus(device));
    const isValid = voiceEngineStatuses.some((voiceEngineStatus) => voiceEngineStatus.status);

    if (isValid) {
        return {
            status: true,
        };
    }

    return voiceEngineStatuses[0];
};

/**
 * Check if the store is valid for text connect usage
 *
 * @param {*} store
 * @returns { status: boolean, errorCode: string } - status of a store for text connect usage
 */
export const checkTextConnectStoreStatus = (store) => {
    // check if store has any valid system tier
    const hasValidSystemTier = store.SystemTiers.some((systemTier) =>
        VALID_TEXT_CONNECT_SYSTEM_TIERS.includes(systemTier.key),
    );

    if (!hasValidSystemTier) {
        return {
            status: false,
            errorCode: DEVICE_ERROR_CODES.INVALID_TIER,
        };
    }

    // check if store has any online nexeo devices
    const onlineNexeoDevices = store.Devices.filter(
        (device) => device.type === deviceType.nexeo.id && device.isActive === 1,
    );

    if (!onlineNexeoDevices.length) {
        return {
            status: false,
            errorCode: DEVICE_ERROR_CODES.OFFLINE,
        };
    }

    // check if store has any valid nexeo devices
    const validNexeoDevices = onlineNexeoDevices.filter(
        (device) => {
            // check if the version is not empty and valid, then compare it without the build type
            return (
                !!device.mainVersion &&
                !!BuildVersions.formatVersion(device.mainVersion) &&
                BuildVersions.semverGte(excludeVersionBuildType(device.mainVersion), MIN_TEXT_CONNECT_NEXEO_VERSION)
            );
        },
    );

    if (!validNexeoDevices.length) {
        return {
            status: false,
            errorCode: DEVICE_ERROR_CODES.INVALID_BASE_VERSION,
        };
    }

    return checkVoiceEngine(validNexeoDevices);
};
