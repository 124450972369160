import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';

export const PageTitle = ({ deviceTypeTranslation, storeName, storeNumber }) => {
    return (
        <>
            <Trans i18nKey={deviceTypeTranslation} />
            {storeName ? ` - ${storeName} ` : ''}
            {' '} - {storeNumber}
        </>
    );
};

PageTitle.propTypes = {
    deviceTypeTranslation: PropTypes.string.isRequired,
    storeName: PropTypes.string,
    storeNumber: PropTypes.string.isRequired
};
