import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Input } from 'pages/APIManagement/Common/Input';
import { AutoCompleteInput } from 'library/AutoCompleteInput';

import './PartnerEmailDropdown.scss';

const suggestionsHeaders = [{
    text: 'Email',
    property: 'email'
}];

export const PartnerEmailDropdown = ({
    email = '',
    partnerEmails = [],
    onSelect,
    onChange,
    className = '',
    isLoading = false,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <AutoCompleteInput
            value={email}
            onChange={onChange}
            Component={Input}
            suggestions={partnerEmails}
            onSuggested={onSelect}
            isLoading={isLoading}
            label={t('api-token-management__form--token-manager-email')}
            loadingMessage={t('autocomplete-input__no-suggestions')}
            noSuggestionText={t('common__error--no-email-found')}
            className={classNames('token-manager-email-input', className)}
            headers={suggestionsHeaders}
            rowKey={suggestionsHeaders[0].property}
            isRequired
            autoComplete="off"
            {...props}
        />
    );
};
