import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { cond, T, isEmpty, equals } from 'ramda';

import { Title as LibraryTitle } from 'library/Title';
import { SaveFooter } from 'library/SaveFooter';
import { Loader } from 'library/Loader';
import { NotificationsList } from 'library/NotificationsList';

import { useAccountData, useUdpateAccountData } from './hooks';
import { LoginDetails } from './LoginDetails';
import { Access } from './Access';
import { UserInfo } from './UserInfo';

import './AccountInfo.scss';

const AccountInfoComponent = ({
    account,
    accountDataErrors,
    isUpdateAccountPasswordInProgress,
    onAccountChange,
    onAccountUpdate,
    onPasswordUpdate
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleCancelClick = useCallback(() => {
        navigate('/welcome');
    }, [navigate]);

    const initialAccountInfoData = useRef(account);

    const isSubmitEnabled = useMemo(() => isEmpty(accountDataErrors), [accountDataErrors]);

    const isFormChanged = useMemo(() => {
        return !equals(account, initialAccountInfoData.current);
    }, [account]);

    return (
        <>
            <div className="hme-account-info">
                <div className="hme-account-info__main-content">
                    <LibraryTitle className="hme-account-info__page-title">{t('my-account__account-info')}</LibraryTitle>

                    <div className="hme-account-info__sections">
                        <div className="hme-account-info__section hme-account-info__section--1">
                            <LoginDetails
                                account={account}
                                accountDataErrors={accountDataErrors}
                                isPasswordChangeInProgress={isUpdateAccountPasswordInProgress}
                                onAccountChange={onAccountChange}
                                onPasswordUpdate={onPasswordUpdate}
                            />
                            <Access account={account} />
                        </div>

                        <div className="hme-account-info__section hme-account-info__section--2">
                            <UserInfo
                                account={account}
                                accountDataErrors={accountDataErrors}
                                onAccountChange={onAccountChange}
                            />
                        </div>
                    </div>
                </div>

                <SaveFooter
                    onCancel={handleCancelClick}
                    onSave={onAccountUpdate}
                    isSubmitEnabled={isSubmitEnabled && isFormChanged}
                    additionalClass="hme-account-info__footer"
                />
            </div>

            <NotificationsList />
        </>
    );
};

const AccountInfo = cond([
    [({ isLoading }) => isLoading, () => <Loader />],
    [T, (props) => <AccountInfoComponent {...props} />]
]);

export const Account = () => {
    const { fetchedAccountData, isLoadingAccountData, accountData, accountDataErrors, setAccountData } = useAccountData();
    const {
        isUpdateAccountInProgress,
        isUpdateAccountPasswordInProgress,
        handleUpdateAccountData,
        handleUpdateAccountPassword: passwordUpdateHandler
    } = useUdpateAccountData();

    const handleUpdateAccount = useCallback(() => handleUpdateAccountData(accountData), [accountData]);
    const handleUpdateAccountPassword = useCallback(
            () => passwordUpdateHandler({ ...fetchedAccountData, ...accountData }),
            [fetchedAccountData, accountData]
    );

    return (
        <AccountInfo
            isLoading={isLoadingAccountData || isUpdateAccountInProgress}
            account={accountData}
            isUpdateAccountPasswordInProgress={isUpdateAccountPasswordInProgress}
            accountDataErrors={accountDataErrors}
            onAccountChange={setAccountData}
            onAccountUpdate={handleUpdateAccount}
            onPasswordUpdate={handleUpdateAccountPassword}
        />
    );
};
