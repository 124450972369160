import { useState, useCallback } from 'react';
import { reboot } from '../../../../services/Device';

export const useRebootDevice = (loadDevice, loadPeripheralDevices) => {
    const [isDeviceRebooting, setIsDeviceRebooting] = useState(false);
    const [deviceRebootingError, setDeviceRebootingError] = useState(null);

    const rebootDevice = useCallback(async (duid) => {
        if (!duid) return;

        try {
            setIsDeviceRebooting(true);
            setDeviceRebootingError(null);

            const result = await reboot(duid);
            await Promise.all([loadDevice(duid), loadPeripheralDevices(duid)]);
            setIsDeviceRebooting(false);

            return result && result.data && result.data.data;
        } catch (e) {
            setDeviceRebootingError(e);
            setIsDeviceRebooting(false);
        }
    }, []);

    return { isDeviceRebooting, deviceRebootingError, rebootDevice };
};
