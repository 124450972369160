/* eslint-disable react/display-name */
import React from 'react';
import AuthenticationService from 'components/Security/AuthenticationService';
import { Config } from 'Config';

const authService = new AuthenticationService(Config.authBaseUrl);

export const withMasqueradeUser = () => (Component) => (props) => (
    <Component {...props } masqueradeUser={authService.getMasqueradeAdminUser()} profile={authService.getProfile()}/>
);
