import React, { useCallback, useEffect } from 'react';
import { $insertNodes } from 'lexical';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useIsFirstRender } from 'hooks/useIsFirstRender';

const parser = new DOMParser();

export const HtmlTransformerPlugin = ({ initialHtml, onHtmlChanged, ...props }) => {
    const [editor] = useLexicalComposerContext();
    const isFirstRender = useIsFirstRender();

    const handleChange = useCallback((editorState) => {
        editorState.read(() => {
            onHtmlChanged($generateHtmlFromNodes(editor));
        });
    }, [editor, onHtmlChanged]);

    useEffect(() => {
        if (!initialHtml || !isFirstRender) return;

        editor.update(() => {
            const dom = parser.parseFromString(initialHtml, 'text/html');
            const nodes = $generateNodesFromDOM(editor, dom);
            $insertNodes(nodes);
        });
    }, [editor, initialHtml, isFirstRender]);

    return (
        <OnChangePlugin
            onChange={handleChange}
            {...props}
        />
    );
};
