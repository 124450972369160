export const MOBILE_SORT_OPTIONS = [
    {
        label: 'account__sorting__email__asc',
        value: { User_EmailAddress: 1 }
    },
    {
        label: 'account__sorting__email__desc',
        value: { User_EmailAddress: -1 }
    },
    {
        label: 'account__sorting__company-name__asc',
        value: { Company_Name: 1 }
    },
    {
        label: 'account__sorting__company-name__desc',
        value: { Company_Name: -1 }
    },
    {
        label: 'account__sorting__company-type__asc',
        value: { Company_Type: 1 }
    },
    {
        label: 'account__sorting__company-type__desc',
        value: { Company_Type: -1 }
    },
    {
        label: 'account__sorting__country-name__asc',
        value: { Country_Name: 1 }
    },
    {
        label: 'account__sorting__country-name__desc',
        value: { Country_Name: -1 }
    },
    {
        label: 'account__sorting__subscription-type__asc',
        value: { Subscription_Name: 1 }
    },
    {
        label: 'account__sorting__subscription-type__desc',
        value: { Subscription_Name: -1 }
    },
    {
        label: 'account__sorting__account-status__asc',
        value: { isActive: -1 }
    },
    {
        label: 'account__sorting__account-status__desc',
        value: { isActive: 1 }
    }
];
