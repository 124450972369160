import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Title } from 'library/Title';
import { Button } from 'library/Button';
import { GridFiltersCount } from 'library/GridFiltersCount';
import { SearchInput } from 'components/Inputs/SearchInput';

import './Header.scss';

export const Header = ({ filters, filtersCount, onFiltersChange, onFiltersReset }) => {
    const [searchValue, setSearchValue] = useState('');

    const { t } = useTranslation();

    useEffect(() => {
        const words = searchValue.length < 3 ? [] : searchValue.split(' ');

        onFiltersChange && onFiltersChange({
            ...filters,
            words,
        });
    }, [searchValue, onFiltersChange]);

    return (
        <div className="apply-device-settings-taskbar">
            <Title>{t('common__apply-device-settings')}</Title>
            <div className='apply-device-settings-taskbar-separated-content apply-device-settings-taskbar-search-group'>
                <SearchInput
                    value={searchValue}
                    onChange={setSearchValue}
                />
            </div>
            <div className='apply-device-settings-taskbar-separated apply-device-settings-taskbar-filters-count'>
                <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} />
            </div>
            <div className='apply-device-settings-taskbar-link create-new-job-button'>
                <Button as={Link} to={`/settings/applyDeviceSettings`}>{t('common__create-new-task')}</Button>
            </div>
        </div>
    );
};
