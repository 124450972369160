import React, { useCallback } from 'react';

import { SourceDeviceSettings } from '../SourceDeviceSettings';
import { SourceDeviceInput } from '../SourceDeviceInput';

import './SerialNumberForm.scss';

export const SerialNumberForm = ({ params, onParamsChange }) => {
    const {
        sourceSettingsList: settingsList,
        sourceDevice: device,
    } = params;

    const onSourceDeviceChange = useCallback(device => {
        onParamsChange({
            ...params,
            sourceDevice: device,
            sourceSettingsList: device ? params.sourceSettingsList : null
        })
    }, [params, onParamsChange]);

    const setSettingsList = useCallback(sourceSettingsList => onParamsChange({
        ...params,
        sourceSettingsList,
    }), [onParamsChange, params]);

    return (
        <div className='admin-create-apply-device-settings-task-source-serial'>
            <SourceDeviceInput value={device} onChange={onSourceDeviceChange} />
            {
                device ?
                    <SourceDeviceSettings
                        values={settingsList}
                        onChange={setSettingsList}
                        isEditable
                    /> :
                    null
            }
        </div>
    );
};
