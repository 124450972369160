import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DateLib } from '@hme-cloud/utility-common';
import PropTypes from 'prop-types';
import { Title } from 'library/Title';
import { TileList } from 'library/TileList';
import { Paginate } from 'library/Paginate';
import { Grid } from 'components/Common/Grid';

import sortBySelection from 'helpers/sortBySelection';
import compareDates from 'helpers/sortComparators/date';
import compareTexts from 'helpers/sortComparators/text';
import compareNumbers from 'helpers/sortComparators/number';
import { applyPagination } from 'helpers';
import {
    ACTIVITY_LOG_HEADERS,
    MOBILE_ACTIVITY_LOG_HEADERS,
    PAGE_DATA,
    PAGE_DEFAULT,
    INIT_LOGS_SORT_SELECTION,
    MOBILE_SORT_OPTIONS,
} from '../constants';

import './ActivityLog.scss';

const getSortComparators = (sortSelection = {}) => ({
    lastLoginDateTime: (a, b) => compareDates(a.lastLogin, b.lastLogin, sortSelection.lastLogin),
    page: (a, b) => compareTexts(a.page, b.page, sortSelection.page),
    action: (a, b) => compareTexts(a.action, b.action, sortSelection.action),
    record: (a, b) => compareNumbers(a.record, b.record, sortSelection.record),
});

const logsToRows = (logs) =>
    logs?.map((log) => ({
        ...log,
        lastLoginDateTime: log.lastLogin ? new DateLib(new Date(log.lastLogin)).format(DateLib.FORMAT_TYPES.FULL_YEAR_DATE_AND_TIME) : '',
        record: +log.record,
    }));

const applyRawFieldsSorting = (value) => {
    const [sortCol = ''] = Object.keys(value) || [];

    switch (sortCol) {
        case 'lastLoginDateTime':
            return { lastLogin: value[sortCol] };
        default:
            return value;
    }
};

export const ActivityLog = ({ activityLogs }) => {
    const { t } = useTranslation();
    const [rows, setRows] = useState([]);
    const [pageData, setPageData] = useState(PAGE_DEFAULT);
    const [sortSelection, setSortSelection] = useState(INIT_LOGS_SORT_SELECTION);

    const activityLogsRows = useMemo(() => logsToRows(activityLogs), [activityLogs]);

    useEffect(() => {
        setRows(
            sortBySelection({
                list: activityLogsRows,
                sortSelection: applyRawFieldsSorting(sortSelection),
                comparators: getSortComparators(sortSelection),
            }),
        );
    }, [activityLogsRows, sortSelection]);

    const onFiltersAndSortChange = useCallback(({ selectedSortValue }) => {
        setSortSelection(selectedSortValue);
    }, []);

    return (
        <div className="hme-view-user__activity-log">
            <Title variants={['block']}>{t('add-user__activity-log__title')}</Title>
            <Grid
                rows={applyPagination(rows, pageData)}
                rowKey="record"
                headers={ACTIVITY_LOG_HEADERS}
                isLoading={false}
                loadingText={t('common__loading')}
                onSortChange={setSortSelection}
                sortSelection={sortSelection}
            />
            <TileList
                headers={MOBILE_ACTIVITY_LOG_HEADERS}
                rowKey="record"
                rows={applyPagination(rows, pageData)}
                sortButtonName="common__sort"
                onFiltersAndSortChange={onFiltersAndSortChange}
                sortSelection={sortSelection}
                sortOptions={MOBILE_SORT_OPTIONS}
                isSortingDropdown
            />
            {!!activityLogsRows?.length && (
                <Paginate
                    className="hme-view-user__activity-log__paginate hme-paginate__mobile"
                    page={pageData.page}
                    recordsPerPage={pageData.recordsPerPage}
                    total={activityLogsRows?.length}
                    onChange={setPageData}
                    pageSizes={PAGE_DATA.PAGE_SIZES}
                />
            )}
        </div>
    );
};

ActivityLog.propTypes = {
    activityLogs: PropTypes.arrayOf(
        PropTypes.shape({
            record: PropTypes.string.isRequired,
            lastLogin: PropTypes.string.isRequired,
            page: PropTypes.string.isRequired,
            action: PropTypes.string.isRequired,
        }),
    ).isRequired,
};
