import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CommonConstants } from 'Constants';
import { useAccess } from 'hooks/useAccess';
import { useOnTrigger } from 'hooks/useTrigger';
import {
    Grid,
    applySearchValue,
    useAvailableFilters,
    applyGridFilters,
    getFiltersCount,
    resetFilters,
} from 'components/Common/Grid';
import { applyPagination } from 'helpers/applyPagination';
import sortBySelection from 'helpers/sortBySelection';
import { FIRMWARE_MANAGEMENT_GRID_HEADERS, MIN_SEARCH_VALUE, SEARCH_PROPERTIES } from '../constants';
import { getSortComparators } from '../helpers';
import { DateLib } from '@hme-cloud/utility-common';
import { FORMAT_TYPES } from 'helpers/DateLib/constants';

import './BrandsList.scss';

const { adminPermissions } = CommonConstants;
const defaultSortComparators = getSortComparators();
const filterOptions = {
    IB7000Version: {
        allText: 'common__all-versions',
        sortComparator: defaultSortComparators.IB7000Version,
    },
    nexeoVersion: {
        allText: 'common__all-versions',
        sortComparator: defaultSortComparators.nexeoVersion,
    },
    zoomNitroVersion: {
        allText: 'common__all-versions',
        sortComparator: defaultSortComparators.zoomNitroVersion,
    },
    updatedBy: {
        allText: 'common__all-users',
        sortComparator: defaultSortComparators.updatedBy,
    },
};

const brandsToRows = (brands, hasManageFirmwareAccess, t) =>
    (brands || []).map((brand) => ({
        ...brand,
        updateDateTime: brand.updatedAt ? new DateLib(brand.updatedAt).format(FORMAT_TYPES.FULL_YEAR_DATE_AND_TIME) : '',
        actions: hasManageFirmwareAccess ? <Link to={`${brand.brandID}`}>{t('common__view-edit')}</Link> : '',
    }));

export const BrandsList = ({
    brands,
    isLoading,
    searchValue,
    sortSelection,
    paginationData,
    appliedFilters,
    availableFilters,
    resetFiltersTrigger,
    onFiltersChange,
    onAvailableFiltersChange,
    onFiltersCountChange,
    onRecordsTotalChange,
    onSortChange,
}) => {
    const { t } = useTranslation();
    const [rows, setRows] = useState([]);
    const [brandsRows, setBrandsRows] = useState([]);
    const [paginatedRows, setPaginatedRows] = useState([]);
    const [gridFilters, setGridFilters] = useState({});

    const hasManageFirmwareAccess = useAccess(adminPermissions.ManageBrandFirmwares);

    useEffect(() => {
        setBrandsRows(brandsToRows(brands, hasManageFirmwareAccess, t));
    }, [brands]);

    useAvailableFilters(filterOptions, brandsRows, setGridFilters, onAvailableFiltersChange);

    useEffect(() => {
        if (!Object.keys(appliedFilters)?.length) {
            return;
        }

        // set filters from the applied url filters if the params are present
        setGridFilters((prevGridFilters) =>
            Object.keys(prevGridFilters).reduce(
                (acc, key) => ({
                    ...acc,
                    [key]: appliedFilters[key] || prevGridFilters[key],
                }),
                {},
            ),
        );
    }, [appliedFilters, brandsRows]);

    useEffect(() => {
        const rowsFilteredBySearch = applySearchValue(brandsRows, SEARCH_PROPERTIES, searchValue, {
            minLength: MIN_SEARCH_VALUE,
        });

        const rowsFiltered = applyGridFilters(filterOptions, rowsFilteredBySearch, gridFilters);

        setRows(
            sortBySelection({
                list: applyGridFilters(filterOptions, rowsFilteredBySearch, gridFilters),
                sortSelection,
                comparators: getSortComparators(sortSelection),
            }),
        );
        onRecordsTotalChange && onRecordsTotalChange(rowsFiltered.length);
    }, [brandsRows, gridFilters, searchValue, sortSelection]);

    const onFiltersChangeHandler = useCallback(
        (filters) => {
            onFiltersChange(filters, availableFilters);
            setGridFilters(filters);
        },
        [onFiltersChange, availableFilters],
    );

    useEffect(() => {
        setPaginatedRows(applyPagination(rows, paginationData));
    }, [paginationData]);

    useEffect(() => {
        onFiltersCountChange && onFiltersCountChange(getFiltersCount(availableFilters, gridFilters));
    }, [availableFilters, gridFilters]);

    const onFiltersReset = useCallback(() => {
        resetFilters(availableFilters, setGridFilters);
    }, [availableFilters]);

    useOnTrigger(resetFiltersTrigger, onFiltersReset);

    return (
        <div className="firmware-management__list">
            <div className="firmware-management__list__content">
                <Grid
                    headers={FIRMWARE_MANAGEMENT_GRID_HEADERS}
                    rows={paginatedRows}
                    isLoading={isLoading}
                    rowKey="brandID"
                    noRecordsMessage={t('firmware-management__grid__brands-not-found')}
                    availableFilters={availableFilters}
                    filters={gridFilters}
                    sortSelection={sortSelection}
                    onFiltersChange={onFiltersChangeHandler}
                    onSortChange={onSortChange}
                />
            </div>
        </div>
    );
};
