export const filterOptions = {
    SettingsVersion: {
        allText: 'admin__stores__add-device__grid-filter__all-versions',
        property: 'SettingsVersion'
    },
    SourceLaneConfig: {
        allText: 'admin__stores__add-device__grid-filter__all-lane-types',
        property: 'SourceLaneConfig'
    },
    SourceSoftwareVersion: {
        allText: 'admin__stores__add-device__grid-filter__all-device-versions',
        property: 'SourceSoftwareVersion'
    }
};

export const searchByOptions = [
    {
        text: 'common__name',
        value: 'SettingName',
        placeholder: 'hme-view-edit-store__add-device-modal-name'
    }, {
        text: 'common__description',
        value: 'Description',
        placeholder: 'hme-view-edit-store__add-device-modal-description'
    }, {
        text: 'common__settings__version',
        value: 'SettingsVersion',
        placeholder: 'hme-view-edit-store__add-device-modal-settings-version'
    }, {
        text: 'common__device-serial_no',
        value: 'SourceSN',
        placeholder: 'hme-view-edit-store__add-device-modal-device-serial_no'
    }
];