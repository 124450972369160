import React from 'react';
import { Modal } from 'library/Modal';
import { useTranslation } from 'react-i18next';

import './CreateSnapshotModal.css';

export const CreateSnapshotModal = ({ onHide, children, ...modalProps }) => {
    const { t } = useTranslation();
    return (
        <Modal
            {...modalProps}
            title={t('settings_snapshots__create_snapshot__title')}
            onHide={onHide}
            dialogClassName="hme-create-snapshot-modal-dialog"
        >
            <div className="hme-create-snapshot-modal">{children}</div>
        </Modal>
    );
};
