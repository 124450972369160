import React from 'react';
import {compose} from "ramda";

import { StatusPage } from '../Common';
import { usePublic } from './hooks/usePublic';
import { withHMELayout } from 'HOCs/withHMELayout';

const PublicRolesComponent = () => {
    const state = usePublic();

    return (
        <StatusPage {...state} />
    );
};

export const PublicRoles = compose(withHMELayout())(PublicRolesComponent);
