/**
 * Given an array of objects, return a new array with cloned objects (Shallow Clone).
 *
 * WARNING: For deep cloning, use other alternatives because Object.assign() copies property values.
 * If the source value is a reference to an object, it only copies that reference value.
 * Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
 *
 * @param {array} a - An array of objects.
 * @return {array} Shallow cloned array of objects
 */
const shallowCloneArrayOfObjects = (a) => {
    return a.map((obj) => {
        return Object.assign({}, obj);
    });
};

module.exports = {
    shallowCloneArrayOfObjects
};
