import { weekDaysOptions } from 'library/DaySingleSelect';
import {
    DASHBOARD_ORDER_INTIAL,
    INITIAL_COLORS,
    JOINED_INITIAL_COLORS,
    PREFERENCE_DASHBOARD_SORT,
    PREFERENCE_DASH_INTIAL,
    PREFERENCE_DASH_METRIC_INITIAL,
    PREFERENCE_DEFAULT_VIEW_INTIAL,
    PULLINS_INITIAL,
    STORE_COLUMN_NAME_INTIAL,
    TIME_FORMAT_INITIAL
} from './constants';
import { isValidHexaCode } from './helpers';
import { DateLib } from '@hme-cloud/utility-common';

const adaptPreferencePrimeColorsToData = (preferredPrimeColors) => {
    const colors = preferredPrimeColors.split('|');

    return {
        colorA: colors[0],
        colorB: colors[1],
        colorC: colors[2]
    };
};

const adaptPrimeColorsToDTO = ({ colorA, colorB, colorC }) => {
    return [colorA, colorB, colorC].map((color, idx) => {
        return isValidHexaCode(color) ? color : INITIAL_COLORS[idx];
    }).join('|');
};

export const displaySettingsDataAdaptor = ({
    PreferencePrimeColors,
    PreferenceDashMetric,
    TimeFormat,
    PreferenceDashPullins,
    PreferenceDefaultView,
    PreferenceStoreColumnName,
    PreferenceStartDay,
    UserID,
    ...displaySettings
}) => ({
    preferredPrimeColors: adaptPreferencePrimeColorsToData(PreferencePrimeColors || JOINED_INITIAL_COLORS),
    preferredMetric: PreferenceDashMetric || PREFERENCE_DASH_METRIC_INITIAL,
    timeFormat: TimeFormat || TIME_FORMAT_INITIAL,
    preferredPullins: PreferenceDashPullins || PULLINS_INITIAL,
    preferredDefaultView: PreferenceDefaultView || PREFERENCE_DEFAULT_VIEW_INTIAL,
    preferredStoreColumnName: PreferenceStoreColumnName || STORE_COLUMN_NAME_INTIAL,
    preferredStartDay: PreferenceStartDay ? weekDaysOptions.find(({ text }) => text.includes(PreferenceStartDay?.toLowerCase())).value : weekDaysOptions[0].value,
    userId: UserID,
    ...displaySettings
});

export const displaySettingsToDTOAdaptor = ({
    preferredPrimeColors,
    preferredMetric,
    timeFormat,
    preferredPullins,
    preferredDefaultView,
    preferredStoreColumnName,
    preferredStartDay,
    userId,
    ...displaySettings
}) => ({
    DashboardOrder: displaySettings.DashboardOrder || DASHBOARD_ORDER_INTIAL,
    PreferenceDash: displaySettings.PreferenceDash || JSON.stringify(PREFERENCE_DASH_INTIAL),
    PreferencePrimeColors: adaptPrimeColorsToDTO(preferredPrimeColors),
    PreferenceDashMetric: preferredMetric,
    TimeFormat: timeFormat,
    PreferenceDashPullins: preferredPullins,
    PreferenceDashSort: displaySettings.PreferenceDashSort || PREFERENCE_DASHBOARD_SORT,
    PreferenceDashSortC: displaySettings.PreferenceDashSortC || PREFERENCE_DASHBOARD_SORT,
    PreferenceLanguage: displaySettings.PreferenceLanguage,
    PreferenceDefaultView: preferredDefaultView,
    PreferenceStoreColumnName: preferredStoreColumnName,
    PreferenceStartDay: new DateLib(new Date(), { locale: 'en-US' })
            .setDay(preferredStartDay)
            .format(DateLib.FORMAT_TYPES.FULL_DAY_OF_THE_WEEK),
    UserID: userId
});
