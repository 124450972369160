import React from 'react';
import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';
import { Button } from 'library/Button';
import { GridFiltersCount } from 'library/GridFiltersCount';
import { isCorporation, isFranchise } from 'services/Auth';

import { NameTooltip } from './NameTooltip';

import './Header.scss';

export const Header = ({
    filtersCount,
    onFiltersReset,
    onCreateShow,
}) => {
    const { t } = useTranslation();

    return (
        <div className='device-settings-taskbar'>
            <Title>{t('common__settings-snapshots')}</Title>
            {!(isCorporation() || isFranchise()) && (
                <div className='device-settings-taskbar__name-tip'>
                    <NameTooltip />
                </div>
            )}
            <div className='device-settings-taskbar-separated device-settings-taskbar-filters-count'>
                <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} />
            </div>
            <div className='device-settings-taskbar-link'>
                <Button onClick={onCreateShow}>{t('settings_snapshots__create_snapshot__form__create-snapshot')}</Button>
            </div>
        </div>
    )
};
