import { DateLib } from '@hme-cloud/utility-common';

export const defaultUser = {
    email: 'admin@hme.com',
    name: 'HME Admin'
};

export const timeFormat = {
    xl: DateLib.FORMAT_TYPES.SMACK_TALK_XL,
    s: DateLib.FORMAT_TYPES.SMACK_TALK_S
};

export const isDefaultUser = (field) => {
    return [defaultUser.name, defaultUser.email].includes(field);
};

export const formatDateTime = (createdDate, createdBy, format = 'xl', hideForDefault = true) => {
    if (hideForDefault && isDefaultUser(createdBy, defaultUser)) return '';
    return new DateLib(createdDate).format(timeFormat[format] || timeFormat.xl);
};
