import {useState, useEffect, useCallback, useMemo} from 'react';

import { Actions } from '../List/Actions';
import {PAGE_DEFAULT} from 'constants/paginationDefault';

export const useStatus = ({
    notifications,
    onEnable,
    onDisable,
    onRemove
}) => {
    const [rows, setRows] = useState([]);
    const [isEnablePopupShown, setIsEnablePopupShown] = useState(false);
    const [enablingNotification, setEnablingNotification] = useState(null);
    const [removingNotification, setRemovingNotification] = useState(null);
    const [isRemoveConfirmationShown, setIsRemoveConfirmationShown] = useState(false);

    const [pageNumber, setPageNumber] = useState(PAGE_DEFAULT.page);
    const [itemsPerPage, setItemsPerPage] = useState(PAGE_DEFAULT.recordsPerPage);
    const [totalRows, setTotalRows] = useState(0);

    const onPaginationChange = useCallback(({ page, recordsPerPage }) => {
        setPageNumber(page);
        setItemsPerPage(recordsPerPage);
    }, [setPageNumber, setItemsPerPage]);

    const paginatedRows = useMemo(()=> rows.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage), [ rows, itemsPerPage, pageNumber])

    const onEnableChange = useCallback((notification) => {
        setIsEnablePopupShown(true);
        setEnablingNotification(notification);
    }, []);

    const onEnableCancel = useCallback(() => {
        setIsEnablePopupShown(false);
    }, []);

    const onEnableConfirm = useCallback(() => {
        if (!enablingNotification) {
            return;
        }

        onEnable && onEnable(enablingNotification);
        setEnablingNotification(null);
    }, [enablingNotification, onEnable]);

    const onRemoveNotification = useCallback((notification) => {
        setIsRemoveConfirmationShown(true);
        setRemovingNotification(notification);
    }, []);

    const onRemoveCancel = useCallback(() => {
        setIsRemoveConfirmationShown(false);
    }, []);

    const onRemoveConfirm = useCallback(() => {
        if (!removingNotification) {
            return;
        }

        onRemove && onRemove(removingNotification);
        setRemovingNotification(null);
    }, [removingNotification, onRemove]);

    useEffect(() => {
        const newRows = notifications.map((notification) => ({
            ...notification,
            Actions: <Actions
                notification={notification}
                onEnable={onEnableChange}
                onDisable={onDisable}
                onRemove={onRemoveNotification}
            />
        }));

        setRows(newRows);
        setTotalRows(newRows.length)
    }, [notifications, onEnableChange, onRemoveNotification]);

    return {
        rows,
        isEnablePopupShown,
        onEnableConfirm,
        onEnableCancel,
        isRemoveConfirmationShown,
        onRemoveCancel,
        onRemoveConfirm,
        pageNumber,
        itemsPerPage,
        totalRows,
        onPaginationChange,
        paginatedRows
    };
};
