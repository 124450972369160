import React from 'react';
import { useTranslation } from 'react-i18next';

import { CenterErrorMessage } from 'components/Common/CenterMessages';

export const DeviceInfoError = () => {
    const { t } = useTranslation();

    return <CenterErrorMessage>{t('system-status__message__loading-device-error')}</CenterErrorMessage>;
};
