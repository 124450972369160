import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { omit } from 'lodash/fp';

import './SelectInput.css';

// HACK: HTMLSelectElement.selectedOptions not supported by IE browser [https://developer.mozilla.org/en-US/docs/Web/API/HTMLSelectElement/selectedOptions]
const getSelectValues = (select) => {
    const values = [];
    const options = select && select.options;
    let opt;
    for (let i = 0; i < options.length; i++) {
        opt = options[i];
        if (opt.selected) {
            values.push(opt.value);
        }
    }
    return values;
};

/**
 *
 */
class SelectInput extends Component {
    /**
     *
     */
    constructor() {
        super();
        this.onChange = this.onChange.bind(this);
        this.select = React.createRef();
    }

    /**
     *
     * @param {*} param0
     * @return {*}
     */
    onChange({ target: { value } }) {
        const { options, onChange, multiple } = this.props;

        if (multiple) {
            return onChange(getSelectValues(this.select.current));
        }

        let valueExists = options.find((option) => option.value === value);

        if (!valueExists) {
            valueExists = options.find((option) => option.value === Number(value));

            if (valueExists) {
                return onChange(Number(value));
            }
        }

        onChange(value);
    }

    /**
     *
     */
    componentDidMount() {
        const { ignorekeys } = this.props;

        if (ignorekeys && ignorekeys.length) {
            const { options } = this.select.current;
            if (!options || !options.length) return;
            for (let index = 0; index < options.length; index++) {
                const opt = options[index];
                opt.addEventListener('mousedown', (event) => {
                    ignorekeys && ignorekeys.map((keyName) => {
                        if (event[keyName]) {
                            event.stopPropagation();
                            event.preventDefault();
                        }
                    });
                });
            }
        }
    }

    /**
     *
     * @return {JSX}
     */
    render() {
        const { inputclassname, labelclassname, name, className, value, label, multiple, options, allowEmpty, size, emptyItemText, endatelevenpm} = this.props;

        return (
            <div className={classNames(className, 'hme-input select-input')}>
                {label && (
                    <label htmlFor={name} className={labelclassname}>{label}</label>
                )}
                <select
                    {...omit(['options', 'allowEmpty', 'emptyItemText', 'label', 'hiddenLabel', 'className', 'inputclassname', 'labelclassname'], this.props)}
                    className={inputclassname ? inputclassname : ''}
                    ref={this.select}
                    value={multiple ? value || [] : value}
                    multiple={multiple}
                    size={size}
                    onChange={this.onChange}
                    id={name}
                >
                    {(!multiple && allowEmpty) && <option value=''>{emptyItemText ? emptyItemText : '-'}</option>}

                    {options.map((opt, index) => {

                        const isSelectedItem = (index===23 && endatelevenpm ? true: null); // Used for selecting default option of 11PM for batch upgrade run time DDL
                        const isDefaultValue = (opt.label==='Select an Option' ? true: null); // Used for hiding the default value of deviceType DDL
                        return (<option selected={isSelectedItem} hidden={isDefaultValue} key={opt.value} value={opt.value}>{opt.label}</option>);

                    })}
                </select>
            </div>
        );
    }
}

SelectInput.propTypes = {
    ignorekeys: PropTypes.array,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    })).isRequired
};

export { SelectInput };
