import React from 'react';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import './Link.css';
import '../Button/Button.css';

export const Link = ({ className, to, label, icon, button, primary, secondary, disabled }) => (
  <RouterLink
    className={classNames(
      className,
      'hme-link',
      {
        'hme-button': button,
        'hme-button--primary': primary,
        'hme-button--secondary': secondary,
        'hme-button--disabled': disabled,
      },
    )}
    to={to}
  >
    {icon && <img className="hme-link__icon" src={icon} />}
    {label}
  </RouterLink>
);
