import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'library/Button';

import './Footer.scss';

export const Footer = ({ onCancel, onSave, isSubmitEnabled = false, onClose, isReadOnly = false }) => {
    const { t } = useTranslation();
    return (
        <div className="account-footer">
            {
                isReadOnly ?
                    <>
                        <Button onClick={onClose}>{t('common__close')}</Button>
                    </>
                    :
                    <>
                        <Button onClick={onCancel}>{t('common__cancel')}</Button>
                        <Button variants={['submit']} disabled={!isSubmitEnabled} onClick={onSave}>
                            {t('common__save')}
                        </Button>
                    </>
            }

        </div>
    );
};
