import { getDataAsync, postDataAsync } from 'Api';
import { CommonConstants } from 'Constants';
import { Config } from 'Config';

const { getDisplaySettings, saveDisplaySettings } = CommonConstants.apiUrls;

export const fetchSettings = () => {
    const url = `${Config.apiBaseUrl}${getDisplaySettings}`;

    return getDataAsync(url);
};

export const updateSettings = (data) => {
    const url = `${Config.apiBaseUrl}${saveDisplaySettings}`;

    return postDataAsync(url, data);
};
