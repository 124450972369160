import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const NVD = 'NVD';
const findNVD = (tier) => tier === NVD;

export const Subscriptions = ({ nitroSubcription, tierSubscriptions = [] }) => {
    const { t } = useTranslation();

    const NVDSubscription = tierSubscriptions.find(findNVD) ? t('common__subscription--nvd-full') : null;
    const tiers = tierSubscriptions.filter((tier) => !findNVD(tier));

    return (
        <div className="hme-stores-status__list__subscription">
            {[nitroSubcription, ...tiers, NVDSubscription].filter(Boolean).map((subscription) => (
                <div className="hme-stores-status__list__inner-cell" key={subscription}>
                    {subscription}
                </div>
            ))}
        </div>
    );
};

Subscriptions.propTypes = {
    nitroSubcription: PropTypes.string.isRequired,
    tierSubscriptions: PropTypes.arrayOf(PropTypes.string).isRequired
};
