import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIcon } from 'assets/icons/close-cross.svg';

import './InfoBlock.scss';

export const InfoBlock = ({ number, withPlus = true, example, children }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className='hme-device-settings-snapshots__name-tooltip__info'>
                <div className='hme-device-settings-snapshots__name-tooltip__info__number'>
                    {number}.
                </div>
                <div className='hme-device-settings-snapshots__name-tooltip__info__content'>
                    {children}
                    {
                        example &&
                        <div className='hme-device-settings-snapshots__name-tooltip__info__example'>
                            <span>{t('settings_snapshots__naming-tooltip__example')}</span> <b>{example}</b>
                        </div>
                    }
                </div>
            </div>
            {
                withPlus &&
                <PlusIcon className='hme-device-settings-snapshots__name-tooltip__info__plus' />
            }
        </>
    );
};
