import api from './api';

const base = 'smackTalk';

const paths = {
  getData: `${base}/getSmackTalkData`,
  saveMessage: `${base}/saveMessage`,
  getMessages: `${base}/message`,
};

const smacktalkApi = {
  getData() {
    return api.request({
      url: paths.getData,
      method: 'GET',
    });
  },
  getMessages() {
    return api.request({
      url: paths.getMessages,
      method: 'GET',
    });
  },
  saveMessage(data) {
    return api.request({
      url: paths.saveMessage,
      method: 'POST',
      data,
    });
  }
};

export default smacktalkApi;
