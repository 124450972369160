import { useTranslation } from 'react-i18next';

import { FilePlainInput } from 'library/FilePlainInput';
import { Button } from 'library/Button';

import './Import.scss';

const inputVariants = ['label-inside-mobile-only'];

export const Import = ({ value, onChange, onImport }) => {
    const { t } = useTranslation();

    return (
        <div className='hme-manage-hierarchy__file-manage__import'>
            <FilePlainInput
                label='common__import'
                value={value}
                onChange={onChange}
                extensions='csv,json'
                inputVariants={inputVariants}
            />
            <Button
                className='hme-manage-hierarchy__file-manage__import__upload'
                disabled={!value}
                onClick={onImport}
            >
                {t('file-select__upload')}
            </Button>
        </div>
    );
};
