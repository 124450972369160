/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import { __, curry } from 'ramda';
import classNames from 'classnames';

import { Row } from './Row';
import { ExpandedContent } from './ExpandedContent';

import './GridRow.scss';

// Separate property string by . to get full data path
const getPath = (property) => property.split('.');

// Extend headers with path property
const getRowOptions = (headers) => headers ?
    headers.map((header) => ({
        ...header,
        path: getPath(header.property)
    })) :
    [];

// Render Grid row or rows if the row is expanded
export const GridRow = ({
    rowKey,
    headers,
    data,
    selectable,
    expandable,
    isAllExpanded,
    ExpandComponent,
    selected,
    onSelectionChange,
    className,
    odd = false,
    onClick,
    onExpandChange,
    checkboxProps = {},
    showOverflow,
    isDisabled = false,
}) => {
    const [expanded, setExpanded] = useState(false);
    const [rowOptions, setRowOptions] = useState([]);
    const [hovered, setHovered] = useState(false);

    const onClickHandler = useCallback(() => {
        onClick && onClick(data);
    }, [onClick, data]);

    useEffect(() => {
        setRowOptions(getRowOptions(headers));
    }, [headers, setRowOptions]);

    useEffect(() => {
        if (ExpandComponent && !data.expandHidden) {
            setExpanded(isAllExpanded);
        }
    }, [isAllExpanded, data, ExpandComponent]);

    return (
        <>
            <Row
                data={data}
                expandHidden={!ExpandComponent || data.expandHidden}
                expanded={expanded}
                expandable={expandable}
                headers={rowOptions}
                selectable={!isDisabled && selectable}
                selected={selected}
                isSelectionEnabled={data.isSelectable}
                hovered={hovered}
                className={classNames(className, data.rowClassName,
                    expanded ? 'hme-grid-expanded-parent-row' : '',
                    isDisabled ? 'hme-grid-row-disabled' : ''
                )}
                /*
                    We need to pass all data as last argument of
                    getCellClassName function
                    but we don't want to send all data to Row component
                    so we just pass curred function with all data object
                */
                getCellClassName={
                    data.getCellClassName && curry(data.getCellClassName)(__, __, data)
                }
                odd={odd}
                clickable={onClick}
                onExpandChange={(isExpanded) => {
                    setExpanded(isExpanded);
                    if (onExpandChange) {
                        onExpandChange(rowKey, isExpanded);
                    }
                }}
                onSelectionChange={onSelectionChange}
                onHoverChange={isDisabled ?  () => {} : setHovered}
                onClick={isDisabled ? () => {} : onClickHandler}
                checkboxProps={checkboxProps}
                showOverflow={showOverflow}
            />
            <ExpandedContent
                expanded={expanded && expandable}
                data={data}
                onHoverChange={setHovered}
                hovered={hovered}
                odd={odd}
                ExpandComponent={ExpandComponent}
            />
        </>
    );
};
