import { baseAuthService } from '../components/Security/AuthenticationService';
import { Config } from '../Config';
import axios from 'axios';

const endpoint = {
    getInfo: '/pbi-embed-token',
    sendReport: '/pbi-send-report',
    addScheduler: '/pbi-add-schedule',
    getScheduler: '/pbi-get-schedule',
    getAllScheduler: '/pbi-get-all-schedules',
    deleteScheduler: '/pbi-delete-schedule',
    updateScheduler: '/pbi-update-schedule',
    sendMeasures: '/pbi-metrics',
    getAllReportTemplates: '/pbi-get-all-templates',
    saveReportTemplate: '/pbi-save-template',
    updateReportTemplate: '/pbi-update-template',
    deleteReportTemplate: '/pbi-delete-template',
    trackLastUsedReportTemplate: '/pbi-track-template-usage',
    initiateDownloadRequest: '/pbi-initiate-download-request',
    getDownloadReportStatus: '/pbi-get-download-status',
    getDownloadableFile: '/pbi-get-download-file',
    getReportsOverview: '/get-reports-overview',
    getStartOfTheWeek: '/displaysettings/getStartOfTheWeek'
};
const axiosConfig = {
    baseURL: `${Config.apiBaseUrl}api/`,
    headers: {
        'Content-Type': 'application/json'
    }
};
const axiosInstance = axios.create(axiosConfig);
const api = {
    request(options) {
        options.headers = Object.assign(options.headers || {}, {
            Authorization: `Bearer ${baseAuthService.getToken()}`
        });
        return axiosInstance(options)
                .then((res) => res.data);
    }
};

export const getEmbedConfig = async (data) => {
    try {
        const embedConfigResult = await api.request({
            url: `${endpoint.getInfo}`,
            method: 'POST',
            data
        });
        const result = embedConfigResult || {};
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const sendReport = async (data) => {
    try {
        const sendReportResult = await api.request({
            url: `${endpoint.sendReport}`,
            method: 'POST',
            data
        });
        return sendReportResult || {};
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const saveSchedule = async (data) => {
    try {
        const sendReportResult = await api.request({
            url: `${endpoint.addScheduler}`,
            method: 'POST',
            data
        });
        return sendReportResult || {};
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const updateSchedule = async (data) => {
    try {
        const sendReportResult = await api.request({
            url: `${endpoint.updateScheduler}`,
            method: 'PUT',
            data
        });
        return sendReportResult || {};
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const getSchedules = async (id) => {
    try {
        const sendReportResult = await api.request({
            url: `${endpoint.getScheduler}/?id_schedul=${id}`,
            method: 'GET'
        });
        return sendReportResult || {};
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getAllSchedules = async (params) => {
    try {
        const sendReportResult = await api.request({
            url: `${endpoint.getAllScheduler}`,
            method: 'GET',
            params
        });
        return sendReportResult || {};
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const deleteSchedules = async (data) => {
    try {
        const sendReportResult = await api.request({
            url: `${endpoint.deleteScheduler}?id_schedul=${data.id}`,
            method: 'DELETE',
            data
        });
        return sendReportResult || {};
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const sendReportsMeasures = async (data) => {
    try {
        const sendMeasureResult = await api.request({
            url: `${endpoint.sendMeasures}`,
            method: 'POST',
            data
        });
        return sendMeasureResult || {};
    } catch (error) {
        console.log(error);
        return null;
    }
};

// APIs for report templates

export const getAllReportTemplates = async (params) => {
    try {
        const reportTemplates = await api.request({
            url: `${endpoint.getAllReportTemplates}`,
            method: 'GET',
            params
        });
        return reportTemplates || {};
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const saveReportTemplate = async (data) => {
    try {
        const result = await api.request({
            url: `${endpoint.saveReportTemplate}`,
            method: 'POST',
            data
        });
        return result || {};
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateReportTemplate = async (data) => {
    try {
        const result = await api.request({
            url: `${endpoint.updateReportTemplate}`,
            method: 'POST',
            data
        });
        return result || {};
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const deleteReportTemplate = async (params) => {
    try {
        const result = await api.request({
            url: endpoint.deleteReportTemplate,
            method: 'DELETE',
            params
        });
        return result || {};
    } catch (error) {
        console.log(error);
        return null;
    }
};

/**
 * API call to track report template usage
 * @param {template_info} data -templateUID and reportType
 */
export const trackLastUsedReportTemplate = (data) => {
    try {
        api.request({
            url: endpoint.trackLastUsedReportTemplate,
            method: 'POST',
            data
        });
    } catch (error) {
        console.log(error);
    }
};

export const initiateDownloadRequest = async (data) => {
    try {
        const result = await api.request({
            url: endpoint.initiateDownloadRequest,
            method: 'POST',
            data
        });
        return result || {};
    } catch (error) {
        console.log(error);
        return error;
    }
};

export const getDownloadReportStatus = async (params) => {
    try {
        const result = await api.request({
            url: endpoint.getDownloadReportStatus,
            method: 'GET',
            params
        });
        return result || {};
    } catch (error) {
        console.log(error);
        return error;
    }
};

export const getDownloadableFile = async (params) => {
    try {
        const result = await api.request({
            url: endpoint.getDownloadableFile,
            method: 'GET',
            params
        });
        return result || {};
    } catch (error) {
        console.log(error);
        return error;
    }
};

export const getReportsOverview = async (params) => {
    try {
        const result = await api.request({
            url: `${endpoint.getReportsOverview}`,
            method: 'GET',
            params
        });
        const overview = result;
        return overview;
    } catch (error) {
        console.log(error);
        return {
            status: false,
            data: {},
            error
        };
    }
};

export const getStartOfTheWeek = async (params) => {
    try {
        const result = await api.request({
            url: `${endpoint.getStartOfTheWeek}`,
            method: 'GET',
            params
        });
        const startOfTheWeek = result;
        return startOfTheWeek;
    } catch (error) {
        console.log(error);
        return {
            status: false,
            data: {},
            error
        };
    }
};
