import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, BUTTON_VARIANTS } from 'library/Button';
import { GridActions } from 'library/GridActions';

const buttonVariant = [BUTTON_VARIANTS.TRANSPARENT];

export const Actions = ({ device, linkedDevice, onDeviceReboot, onDeviceReconnect }) => {
    const { t } = useTranslation();

    const isActionEnabled = !linkedDevice.Device_IsPreconfigured && linkedDevice.Device_IsActive;

    return (
        <GridActions>
            <Button
                disabled={!isActionEnabled}
                variants={buttonVariant}
                onClick={() => onDeviceReconnect({ linkedDevice, device })}
            >
                {t('system-status__action__reconnect')}
            </Button>

            <Button
                disabled={!isActionEnabled}
                variants={buttonVariant}
                onClick={() => onDeviceReboot({ linkedDevice, device })}
            >
                {t('system-status__action__reboot')}
            </Button>
        </GridActions>
    );
};
