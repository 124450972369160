export const tokenManagementBaseUrl = '/admin/tokenManagement';

export const PARTNER_TYPES = {
    VAIO: 'VAIO Provider',
    Nitro: 'Nitro Provider',
    LinkWear: 'LinkWear Provider',
    DXS: 'DXS Partner',
    DeliveryService: 'Delivery Service'
};

export const PARTNER_TYPE_IDS = {
    [PARTNER_TYPES.VAIO]: 1,
    [PARTNER_TYPES.Nitro]: 2,
    [PARTNER_TYPES.LinkWear]: 3,
    [PARTNER_TYPES.DXS]: 4,
    [PARTNER_TYPES.DeliveryService]: 5
};

export const DEFAULT_TOKEN_MANAGER = {
    id: null,
    uid: '',
    email: '',
    partnerId: null
};

export const tokenUsers = {
    hmeAccount: 'HME Account',
    partner: 'Partner'
};

export const HME_API_TYPES_ENUM = {
    VAIO: 'VAIO',
    DXS: 'DXS',
    GigDelivery: 'GigDelivery'
};

export const HME_API_ID_MAP = {
    1: HME_API_TYPES_ENUM.VAIO,
    2: HME_API_TYPES_ENUM.DXS,
    3: HME_API_TYPES_ENUM.GigDelivery
};

export const API_ID_TO_PARTNER_CATEGORY_MAP = {
    [HME_API_TYPES_ENUM.VAIO]: PARTNER_TYPE_IDS[PARTNER_TYPES.VAIO],
    [HME_API_TYPES_ENUM.DXS]: PARTNER_TYPE_IDS[PARTNER_TYPES.DXS],
    [HME_API_TYPES_ENUM.GigDelivery]: PARTNER_TYPE_IDS[PARTNER_TYPES.DeliveryService]
};

// This is to avoid showing test api values in the frontend
export const validHmeApiIds = [1, 2, 3];

const basicFormData = {
    tokenName: '',
    tokenExpiration: 6,
    apiType: HME_API_TYPES_ENUM.VAIO,
    tokenUser: tokenUsers.partner,
    tokenManager: DEFAULT_TOKEN_MANAGER,
    email2: '',
    email3: '',
    expiryDate: '',
    lastRenewedDate: ''
};

export const FORM_DEFAULTS = {
    VAIO: {
        ...basicFormData,
        vaioProvider: ''
    },
    DXS: {
        ...basicFormData,
        cloudAccounts: [],
        apiType: HME_API_TYPES_ENUM.DXS
    },
    GigDelivery: {
        ...basicFormData,
        vaioProvider: '',
        apiType: HME_API_TYPES_ENUM.GigDelivery
    }
};

export const VAIO_DEFAULTS = { ...FORM_DEFAULTS.VAIO };

export const REQUIRED_INPUTS = {
    VAIO: [
        'tokenName',
        'tokenExpiration',
        'apiType',
        'tokenUser',
        'tokenManager',
        'vaioProvider'
    ],
    DXS: [
        'tokenName',
        'tokenExpiration',
        'apiType',
        'tokenUser',
        'tokenManager',
        'cloudAccounts'
    ],
    GigDelivery: [
        'tokenName',
        'tokenExpiration',
        'apiType',
        'tokenUser',
        'tokenManager'
    ]
};

export const TOKEN_USER_TYPES = [
    { text: 'admin__token-management__token-user-type--hme-account', value: tokenUsers.hmeAccount },
    { text: 'admin__token-management__token-user-type--partner', value: tokenUsers.partner }
];

export const TOKEN_USER_TYPES_EDIT = [
    { text: 'admin__token-management__token-user-type--hme-account', value: tokenUsers.hmeAccount, disabled: true },
    { text: 'admin__token-management__token-user-type--partner', value: tokenUsers.partner }
];

export const TOKEN_INITIAL = {
    name: '',
    generatedToken: ''
};

export const HME_API_TYPES = {
    VAIO: {
        categoryID: 1,
        categoryName: 'VAIO Provider',
        genericLabel: 'api-token-management__form--hme-apis-vaio-value'
    },
    DXS: {
        categoryID: 2,
        categoryName: 'DXS Provider',
        genericLabel: 'api-token-management__form--hme-apis-dxs-value'
    },
    GigDelivery: {
        categoryID: 3,
        categoryName: 'Gig Delivery Webhook',
        genericLabel: 'api-token-management__form--hme-apis-gigdelivery-value'
    }
};

export const PARTNER_CATEGORIES_MAPPING = {
    VAIO: { categoryID: PARTNER_TYPE_IDS[PARTNER_TYPES.VAIO], categoryName: PARTNER_TYPES.VAIO },
    Nitro: { categoryID: PARTNER_TYPE_IDS[PARTNER_TYPES.Nitro], categoryName: PARTNER_TYPES.Nitro },
    LinkWear: { categoryID: PARTNER_TYPE_IDS[PARTNER_TYPES.LinkWear], categoryName: PARTNER_TYPES.LinkWear },
    DXS: { categoryID: PARTNER_TYPE_IDS[PARTNER_TYPES.DXS], categoryName: PARTNER_TYPES.DXS },
    DeliveryService: { categoryID: PARTNER_TYPE_IDS[PARTNER_TYPES.DeliveryService], categoryName: PARTNER_TYPES.DeliveryService }
};
