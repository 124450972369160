import React from 'react';
import { useTranslation } from 'react-i18next';
import withCharCounter from 'HOCs/withCharCounter';
import { TextareaInput } from 'library/TextareaInput';

import './MessageArea.scss';

const MessageCounterContent = ({ charsLeft = 0, maxLength }) => {
    const { t } = useTranslation();

    return maxLength ? (
        <>
            <span className="hme-focused">
                {t('common__textarea--characters-remaining', { count: charsLeft, max: maxLength })}
            </span>
            <span className="hme-blured">{t('common__textarea--characters-info', { max: maxLength })}</span>
        </>
    ) : null;
};

export const MessageArea = withCharCounter(TextareaInput, MessageCounterContent);
