import { CommonConstants } from 'Constants';
import { getProfile } from 'services/Auth';

const calculateSubscription = ({ Subscription_ID: userSubscriptionId } = {}, subscriptionId) => {
    return subscriptionId === userSubscriptionId;
};

export const checkNitroEssentials = (profile = getProfile()) => {
    return calculateSubscription(profile, Number(CommonConstants.subscription.nitroEssentials));
};
