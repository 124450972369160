import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CommonConstants } from 'Constants';
import { Header } from './Header';
import { Footer } from './Footer';
import { CenterLoader } from 'components/Common/CenterLoader';
import { CenterErrorMessage, CenterSuccessMessage } from 'components/Common/CenterMessages';
import { loadDevices, loadSnapshots, applySettings } from './Controller';
import { SourceSection } from './SourceSection';
import { TargetDevicesSection } from './TargetDevicesSection';

import './CreateTask.scss';

const REDIRECT_TIMEOUT = 3000;
const REDIRECT_URL = '/settings/applyDeviceSettingsStatus';
const { deviceType } = CommonConstants;

export const deviceTypes = {
    ZOOM_NITRO: deviceType.zoomNitro.camelCase,
    NEXEO: deviceType.nexeo.camelCase,
};

const defaultParams = {
    sourceDeviceType: null,
    sourceDevice: null,
    sourceDeviceSettingGroups: [],
    settingTemplateID: '',
    targetDevices: [],
};

const defaultSnapshots = {
    [deviceTypes.ZOOM_NITRO]: [],
    [deviceTypes.NEXEO]: [],
};

export const CreateTask = () => {
    const [sourceType, setSourceType] = useState(null);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [pageError, setPageError] = useState({});
    const [settingsSuccess, setSettingSuccess] = useState({});
    const [isApplyingSettings, setIsApplyingSettings] = useState(false);
    const [params, setParams] = useState(defaultParams);
    const [snapshots, setSnapshots] = useState(defaultSnapshots);
    const [devices, setDevices] = useState([]);
    const [sourceTypeDevices, setSourceTypeDevices] = useState([]);
    const [isDevicesLoading, setIsDevicesLoading] = useState(false);
    const [isSnapshotsLoading, setIsSnapshotsLoading] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const redirectToStatusPage = () => {
        setTimeout(() => {
            navigate(REDIRECT_URL);
        }, REDIRECT_TIMEOUT);
    };

    useEffect(() => {
        loadDevices({ setDevices, setIsDevicesLoading });
        loadSnapshots({ setSnapshots, setIsSnapshotsLoading });
    }, []);

    const clearData = () => {
        setSourceType(null);
        setParams({
            ...params,
            sourceDevice: null,
            settingTemplateID: null,
            sourceDeviceSettingGroups: null,
        });
        setSelectedDevices([]);
    };

    const onSourceTypeChange = useCallback(
        (type) => {
            clearData();
            setSourceType(type);
            const filteredDevices = (devices || []).filter((d) => d.Device_DeviceType_ID === deviceType[type]?.id);
            setSourceTypeDevices(filteredDevices);
            setParams({
                ...params,
                sourceDeviceType: type,
            })
        },
        [clearData, devices, deviceType],
    );

    const onSourceChange = useCallback(
        (newParams) => {
            // clear selected target devices on source device changing
            if (params.sourceDevice !== newParams.sourceDevice) {
                setSelectedDevices([]);
            }

            setParams(newParams);
        },
        [params],
    );

    const onApplySettings = useCallback(() => {
        applySettings({
            params,
            sourceType,
            selectedDevices,
            setIsApplyingSettings,
            setSettingSuccess,
            setPageError,
            redirectToStatusPage,
            t,
        });
    }, [params, sourceType, selectedDevices, redirectToStatusPage, t]);

    const isFooterShown =
        !settingsSuccess.status &&
        !pageError.status &&
        !isApplyingSettings &&
        (params.sourceDevice || params.settingTemplateID);

    const isTargetDeviceListShow = 
        // ZOOM Nitro DTDS by SN
        !!(params.sourceDeviceSettingGroups?.length
        // ZOOM Nitro IOT by Snapshot - should have groups
        || (params.sourceDeviceType === deviceType.zoomNitro.camelCase
            && params.settingTemplateID && params.sourceDeviceSettingGroups?.length)
        // NEXEO IOT by Snapshot - no groups defined for now
        || (params.sourceDeviceType === deviceType.nexeo.camelCase
            && params.settingTemplateID));

    if (isDevicesLoading || isSnapshotsLoading) {
        return (
            <div className="apply-settings-public-container">
                <CenterLoader>{t('common__loading')}</CenterLoader>
            </div>
        );
    }

    const selectedSnapshot = (snapshots[sourceType] || []).find(
        (snapshot) => snapshot.SettingTemplateID === params.settingTemplateID,
    );

    return (
        <div
            className={`apply-settings-public-wrapper hme-components ${
                isFooterShown ? 'apply-settings-public-wrapper-with-footer' : ''
            }`}
        >
            <div className="apply-settings-public-container">
                {isApplyingSettings ? <CenterLoader>{t('apply-device-settings-new__loading')}</CenterLoader> : null}
                {!pageError.status && !settingsSuccess.status && !isApplyingSettings && (
                    <div className="apply-settings-panel">
                        <div className='create-apply-device-settings-task-header'>
                            <Header />
                        </div>
                        <div className='create-apply-device-settings-task-sections'>
                            <SourceSection
                                params={params}
                                sourceType={sourceType}
                                devices={sourceTypeDevices}
                                snapshots={snapshots}
                                isSnapshotsLoading={isSnapshotsLoading}
                                onSourceChange={onSourceChange}
                                onSourceTypeChange={onSourceTypeChange}
                            />
                            <TargetDevicesSection
                                params={params}
                                selectedSnapshot={selectedSnapshot}
                                sourceType={sourceType}
                                selectedDevices={selectedDevices}
                                isTargetDeviceListShow={isTargetDeviceListShow}
                                onDeviceSelection={setSelectedDevices}
                            />
                        </div>
                    </div>
                )}
                {isFooterShown ? (
                    <Footer
                        onCancel={clearData}
                        onApply={onApplySettings}
                        isApplyable={selectedDevices.length > 0}
                    />
                ) : null}
                {settingsSuccess.status ? <CenterSuccessMessage>{settingsSuccess.message}</CenterSuccessMessage> : null}
                {pageError.status ? <CenterErrorMessage>{pageError.message}</CenterErrorMessage> : null}
            </div>
        </div>
    );
};

