import { useCallback, useEffect, useState } from 'react';

import { useAbortController } from 'hooks/useAbordController';
import { defaultMinValueLength } from 'library/AutoCompleteInput';

import { getPartnerEmails } from '../Controller/getPartnerEmails';

/**
 * Custom hook for managing partner emails.
 *
 * @param {string} defaultEmail - The default email value.
 * @return {Object} - An object containing the email, matched emails, and functions to handle email changes.
 */
export const usePartnerEmails = (defaultEmail = '') => {
    const [email, setEmail] = useState(defaultEmail);
    const [matchedEmails, setMatchedEmails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { abort, abortControllerRef } = useAbortController();

    const handleEmailChange = useCallback(async (newEmail = '', apiType = '') => {
        setIsLoading(true);
        setEmail(newEmail);

        abort();

        if (newEmail.length < defaultMinValueLength) {
            setMatchedEmails([]);
            return [];
        }

        const suggestedEmails = await getPartnerEmails(
                {
                    emailSearchQuery: newEmail,
                    apiType
                },
                { signal: abortControllerRef.current.signal }
        );
        setMatchedEmails(suggestedEmails);
        setIsLoading(false);

        return suggestedEmails;
    }, [abort]);

    useEffect(() => {
        return () => {
            abort();
        };
    }, []);

    return { handleEmailChange, setEmail, email, matchedEmails, isLoading };
};
