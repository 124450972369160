export const DASHBOARD_ORDER_INTIAL = 'Current|Day, Previous|Day';

export const PREFERENCE_DEFAULT_VIEW_INTIAL = 'Day';

export const COLOR_A_INTIAL = '#00b04c';
export const COLOR_B_INTIAL = '#dcba00';
export const COLOR_C_INTIAL = '#b40000';

export const INITIAL_COLORS = [COLOR_A_INTIAL, COLOR_B_INTIAL, COLOR_C_INTIAL];
export const JOINED_INITIAL_COLORS = INITIAL_COLORS.join('|');

export const TIME_FORMAT_INITIAL = 'ss';

export const PULLINS_INITIAL = '11';

export const STORE_COLUMN_NAME_INTIAL = 'Exclude';

export const PREFERENCE_DASH_METRIC_INITIAL = '2000';
export const PREFERENCE_DASH_METRIC_LANE_2 = '2001';

export const PREFERENCE_DASH_INTIAL = [
    {
        id: 't0',
        colOrder: ['StoreRank', 'Store', 'Store_Name', 'GoalA'],
        title: 'Stores'
    },
    {
        id: 't1',
        colOrder: ['t1Cars', 't1Avg', 't1Percent'],
        title: 'Current Day'
    },
    {
        id: 't2',
        colOrder: ['t2Cars', 't2Avg', 't2Percent'],
        title: 'Previous Day'
    }
];

export const PREFERENCE_DASHBOARD_SORT = 't1Avg | ASC';

export const DASHBOARD_VIEW_OPTIONS = [
    {
        value: 'Daypart',
        text: 'common__daypart'
    },
    {
        value: 'Day',
        text: 'common__day'
    },
    {
        value: 'Week',
        text: 'common__week'
    },
    {
        value: 'Month',
        text: 'common__month'
    },
    {
        value: 'Custom',
        text: 'common__custom'
    }
];

export const PULLINS_OPTIONS = [
    {
        value: '4',
        text: 'common__include'
    },
    {
        value: '11',
        text: 'common__exclude'
    }
];

export const STORE_COLUMN_NAME_OPTIONS = [
    {
        value: 'Include',
        text: 'common__include'
    },
    {
        value: 'Exclude',
        text: 'common__exclude'
    }
];

export const TIME_FORMAT_OPTIONS = [
    {
        value: 'mmss',
        text: 'common__minute-seconds'
    },
    {
        value: 'ss',
        text: 'common__seconds'
    }
];

export const METRIC_OPTIONS = [
    {
        value: PREFERENCE_DASH_METRIC_INITIAL,
        text: 'common__lane-total'
    },
    {
        value: PREFERENCE_DASH_METRIC_LANE_2,
        text: 'common__lane-total-2'
    }
];
