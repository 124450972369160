/* eslint-disable brace-style */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { compose } from 'ramda';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CommonConstants } from '../../Constants';
import Api from '../../Api';
import { Config } from '../../Config';
import NavigationService from '../Common/NavigationServices';
import { checkAccess, getPermission } from '../../actions/permissions';
import getDeviceType from '../../helpers/Device/getDeviceType';
import AuthFactory from '../../helpers/AuthFactory';
import { isDistributor } from 'services/Auth';
import { Constant } from '@hme-cloud/utility-common';

const { BuildVersions } = Constant;

import './Stores.css';
import { DateLib } from '@hme-cloud/utility-common';
import { FORMAT_TYPES } from 'helpers/DateLib/constants';

const {
    deviceType: {
        zoom: {
            id: zoomDeviceTypeId,
            entryVersion: zoomDeviceEntryVersion
        } = {},
        ion: {
            id: ionDeviceTypeId
        },
        eos: {
            id: eosDeviceTypeId
        },
        cib: {
            id: cibDeviceTypeId
        },
        nexeo: {
            id: nexeoDeviceTypeId
        }
    } = {}
} = CommonConstants;

const DEFAULT_UPGRADE_SUBVERSION = '2.x';
const DEFAULT_UPGRADE_VERSION_VALUE = 'Select Upgrade Version';

class RemoteSystemActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            systemActions: this.props.systemActions,
            isPerformDeviceRestart: false,
            deviceUpgradeVersions: null,
            devicePeripheralInfos: null,
            // List of device-type IDs that support version upgrade
            allowedDevicesUpgradeTypes: [zoomDeviceTypeId, nexeoDeviceTypeId],
            upgradeVersionValue: DEFAULT_UPGRADE_VERSION_VALUE,
        };
        this.navigate = new NavigationService();
        this.authService = AuthFactory.AuthService;
        this.permissionSpecification = AuthFactory.PermissionSpecification;
        this.state.token = this.authService.getToken();
        this.renderRemoteActionsForClientUser = this.renderRemoteActionsForClientUser.bind(this);
        this.confirm = this.confirm.bind(this);
        this.action = this.action.bind(this);
        this.props.getPermission();
        this.api = new Api();

        this.dynamicMethods = {
            'renderRebootButton': {
                onClick: this.confirm.bind(this, 'reboot'),
                caption: 'Reboot System',
                unavailableCaption: 'Reboot Currently Unavailable'
            },
            'renderReconnectButton': {
                onClick: this.confirm.bind(this, 'reconnect'),
                caption: 'Force Reconnect',
                unavailableCaption: 'Reconnect Currently Unavailable'
            },
        };
        Object.keys(this.dynamicMethods).forEach((method) => {
            const {
                onClick,
                caption,
                unavailableCaption
            } = this.dynamicMethods[method];
            // add dynamic method to the context
            this[method] = function(upgrading, upgradeTime) {
                if (!this.timeToUpgrade(upgrading, upgradeTime)) {
                    return <div className='ActionButtons' >{unavailableCaption}</div>;
                }
                return <div className='ActionButtons' onClick={onClick}> {caption} </div>;
            }.bind(this);
        }, this);
    }

    componentDidMount() {
        const {
            deviceUid,
            systemActions: {
                systemStatus: [
                    {
                        Device_DeviceType_ID
                    } = {}
                ] = []
            } = {}
        } = this.props;

        if (this.state.allowedDevicesUpgradeTypes.includes(Device_DeviceType_ID)) {
            // Fetch from Portal Service, upgrade versions for main (parent) device, along with any peripheral (child) devices
            const deviceType = getDeviceType({ Device_DeviceType_ID });
            let apiUrl = Config.apiBaseUrl + CommonConstants.apiUrls.getDeviceUpgradeVersions;
            let url = `${apiUrl}?DeviceTypeName=${deviceType.name}`;
            this.api.getData(url, (result) => {
                const data = result.status ? result.data : [];
                this.setState({ deviceUpgradeVersions: data });
            });

            // Fetch from Portal Service, current versions of any peripheral (child) devices the main (parent) device may have
            apiUrl = Config.apiBaseUrl + CommonConstants.apiUrls.getDevicePeripheralInfos;
            url = `${apiUrl}?DeviceUID=${deviceUid}`;
            this.api.getData(url, (result) => {
                const data = result.status ? result.data : [];
                this.setState({ devicePeripheralInfos: data });
            });
        } else {
            this.setState({ deviceUpgradeVersions: [],  devicePeripheralInfos: [] });
        }
    }

    isJsonString = (str) => {
        try { JSON.parse(str); }
        catch (e) { return false; }
        return true;
    }

    cancelConfirm(method) {
        switch (method) {
            case 'upgrade':
                this.setState({
                    upgradeVersionValue: DEFAULT_UPGRADE_VERSION_VALUE
                });
                break;
            default:
                break;
        }
    }

    confirm(method, value = '', upgradeTime) {
        let name = '';
        let modelName = '';
        let version = '';

        const { Device_DeviceType_ID } = this.props.systemStatus;
        const isNexeo = (Device_DeviceType_ID === nexeoDeviceTypeId);
        const { t } = this.props;

        let upgradeMessage;
        if (this.isJsonString(value)) {
            const values = JSON.parse(value);
            name = values.name;
            modelName = values.modelName;
            version = values.version;
            upgradeMessage = `Are you sure you would like to upgrade the selected device to ${name} ${modelName} ${version}?`;
            if (isNexeo) {
                upgradeMessage += ' While this upgrade is applied, you may upgrade other NEXEO components with the exception of the Base Station.';
            }
        } else {
            version = value;
            upgradeMessage = 'Are you sure you would like to initiate an upgrade on this system?';
        }

        const messages = {
            'upgrade': upgradeMessage,
            'reboot': 'Are you sure you would like to initiate a reboot system on this system?',
            'reconnect': 'Are you sure you would like to initiate a force reconnect on this system?'
        };
        const popupMessage = messages[method] || '';

        const captions = {
            'upgrade': 'Upgrade System Now',
            'reboot': 'Reboot Now',
            'reconnect': 'Force Reconnect Now'
        };
        const actionButtonCaption = captions[method] || 'Now';
        const isUpgrade = method === 'upgrade';

        let msg = 'This action cannot be undone.';
        if (!isNexeo) {
            msg += ` Your system will be offline during the upgrade process for approximately ${upgradeTime} minutes. `
                  + 'Please DO NOT initiate any upgrades to the same device until the action is complete or else it will be damaged.';
        }
        const upgradeWarning =
            <div className='Popup_message'>
                <span className='warningMsg'>
                    <strong className='warning'>
                        WARNING:&nbsp;
                    </strong>
                    {msg}
                </span>
            </div>;

        confirmAlert({
            customUI: ({ onClose }) =>
                <div className='popup'>
                    <p className='popup_Title'>{t('common__confirmation-required')}</p>
                    <p className='popup_message'>{popupMessage}</p>
                    {isUpgrade && upgradeWarning}
                    <div className='popup_buttons'>
                        <div className='buttonSet' />
                        <div className='popup-button'>
                            <button className='popup_buttons buttons' onClick={() => {
                                onClose();
                                this.action(method, modelName, version);
                            }}>{actionButtonCaption}</button>
                            <button className='popup_buttons buttons' onClick={() => {
                                onClose();

                                this.cancelConfirm(method);
                            }}>{t('common__close')}</button>
                        </div>
                    </div>
                </div>,
            onClickOutside: () => {
                this.cancelConfirm(method);
            },
            onKeypressEscape: () => {
                this.cancelConfirm(method);
            },
        });
    }

    // Render actions for Admin Portal
    renderRemoteActionsForAdmin() {
        const DEFAULT_UPGRADE_TIME = 15;
        const {
            deviceType = {}
        } = CommonConstants;

        const {
            systemActions: {
                systemStatus: [
                    { // systemStatus
                        Device_DeviceType_ID: deviceTypeID,
                        Device_IsActive,
                    } = {}
                ] = [],
                Device_LastUpgrade: deviceUpgradeDate,
                Server_CurrentTime: currentServerTime
            } = {}
        } = this.props;

        const [currentDevice = {}] = Object.keys(deviceType)
                .filter((key) => deviceType[key].id === deviceTypeID)
                .map((key) => deviceType[key]);
        const { upgradeTime = DEFAULT_UPGRADE_TIME } = currentDevice;
        const upgradeDate = deviceUpgradeDate || currentServerTime;
        const upgrading = new DateLib(upgradeDate.replace('Z', ''));
        const isMasterSettings = deviceTypeID === zoomDeviceTypeId;
        const isUpgradeMessageShown = Device_IsActive && deviceTypeID === nexeoDeviceTypeId;

        if (!this.permissionSpecification.hasEditDeviceAdvanced) {
            return <React.Fragment />; // TODO: React recommends returning null instead right?
        }

        return (
            <div className='RemoteSystemActions'>
                <div>
                    <h3 className='versions'>Remote System Actions</h3>
                    {
                        isUpgradeMessageShown &&
                        <p className='upgrade-message'>All device upgrades must be approved on the NEXEO base station</p>
                    }
                    {this.renderUpdgradeButton(upgrading, upgradeTime)}
                </div>
                <div id='reboot-content'>
                    {this.renderRebootButton(upgrading, upgradeTime)}
                </div>
                <div id='reconnect-content'>
                    {this.renderReconnectButton(upgrading, upgradeTime)}
                </div>
            </div>
        );
    }

    timeToUpgrade(upgrading, upgradeTime) {
        const { systemActions } = this.props;
        const currentServerTime = systemActions.Server_CurrentTime;
        const SECOND_TO_MILLISECOND = 60000;

        const localNow = new DateLib(currentServerTime.replace('Z', ''));
        let upgradeTimeElapsed = Math.floor(localNow.diffMilliseconds(upgrading)/SECOND_TO_MILLISECOND);
        const timeToUpgrade = upgradeTimeElapsed > upgradeTime || localNow.format(FORMAT_TYPES.DEFAULT) === upgrading.format(FORMAT_TYPES.DEFAULT);
        return timeToUpgrade;
    }

    /**
     * Get a list of only the upgradable device versions, which may include any peripheral devices.
     * NOTE: Upgrade list is only supported for ZOOM CU50, Janus and NEXEO devices.
     * CU40, CIB, EOS can only be upgraded to a default version.
     * If a device is not a ZOOM, NITRO (Janus), or NEXEO, the upgradable versions list should be empty.
     * @param {*} mainDeviceType
     * @param {*} mainDeviceCurrentVersion
     * @return {[]}
     */
    getUpgradableVersions = (mainDeviceType, mainDeviceCurrentVersion) => {
        const { deviceUpgradeVersions, devicePeripheralInfos } = this.state;

        // Filter-out invalid upgrade version entries such as "abc", null, "''".
        // NOTE: This is redundant since Portal Service already performs this validation
        const validVersions = deviceUpgradeVersions.filter((x) => BuildVersions.formatVersion(x.Version));

        // Filter keeping only those active peripherals with the lowest version of each type
        const lowestVersionPeripherals = this.getEarliestActiveVersions(devicePeripheralInfos);

        // Filter keeping only the versions greater than the current device version or greater than the lowest peripheral version of its type
        const upgradableVersions = validVersions.filter((x) => {
            const versionString = x.Version; // IMPORTANT: item.Version should match the DTDS 'upgrade path' config so it is not broken
            const versionObject = BuildVersions.parsedVersion(versionString);

            if (x.ID === mainDeviceType) {
                // Filter main device, returning only those greater than the current version
                if (mainDeviceCurrentVersion && versionObject && BuildVersions.semverGt(versionObject, mainDeviceCurrentVersion)) {
                    return x;
                }
            } else {
                // Filter peripheral device(s), returning only those greater than the lowest peripheral version of its type
                const p = lowestVersionPeripherals.filter((y) => x.ModelName === y.deviceType);
                if (p.length && p[0].deviceVersion) {
                    const pDeviceCurrentVersion = BuildVersions.parsedVersion(p[0].deviceVersion);
                    if (versionObject && BuildVersions.semverGt(versionObject, pDeviceCurrentVersion)) {
                        return x;
                    }
                }
            }
            return null;
        });

        return BuildVersions.sortModelVersions(upgradableVersions, 'ModelName', 'Version');
    };

    getEarliestActiveVersions = (devicePeripheralInfos) => {
        // Filter keeping only active peripherals and those that are devices (which excludes batteries since they have no version)
        const activePeripherals = devicePeripheralInfos.filter((x) => x.isActive === 1 && x.deviceVersion);

        // Sort array of version objects (1st by deviceType, 2nd by deviceVersion)
        const sortedActivePeripherals = BuildVersions.sortModelVersions(activePeripherals, 'deviceType', 'deviceVersion');

        // Filter keeping only those peripherals with the lowest version of each type
        return this.getEarliestVersions(sortedActivePeripherals);
    };

    /**
     * Get an array of the earliest version for each device deviceType.
     * @param {*} sortedVersions - Must be presorted array 1st by deviceType, 2nd by deviceVersion.
     * @return {[]}
     */
    getEarliestVersions = (sortedVersions) => {
        const lowestVersions = [];
        let currModelName = null;
        // Step thru pre-sorted array forwards grabbing only the lowest version for each deviceType encountered
        for (let index = 0; index < sortedVersions.length; index++) {
            const device = sortedVersions[index];
            if (device.deviceType !== currModelName) {
                currModelName = device.deviceType;
                lowestVersions.push(device);
            }
        }
        return lowestVersions;
    }

    /**
     * Get latest version
     * @param string[] versions All versions
     * @returns string Version value
    */
    getLatestVersion = (versions) => {
        const versionStrings = versions.map(({ Version }) => Version);

        return versionStrings.reduce((max, version) => {
            return BuildVersions.semverGt(version, max) ? version : max;
        }, versionStrings[0]) || '';
    };

    loadingButton = () =>
        <div className='ActionButtons'>
            {this.props.t('common__loading')}
        </div>;

    upgradeSystemButton = (version, upgradeTime) =>
        <div className='ActionButtons' onClick={() => { this.confirm('upgrade', version, upgradeTime); }}>
            Upgrade System
        </div>;

    upgradeUnavailableButton = () =>
        <div className='ActionButtons'>
            Upgrade Currently Unavailable
        </div>;

    systemAtLatestButton = () =>
        <div className='ActionButtons'>
            System at Latest Version
        </div>;

    // eslint-disable-next-line complexity
    renderUpdgradeButton(upgrading, upgradeTime) {
        const {
            systemActions: {
                systemStatus: [
                    { // systemStatus
                        Device_DeviceType_ID: deviceType,
                        Device_MainVersion: mainVersion = ''
                    } = {}
                ] = [],
            } = {},
            scheduledUpgrade = {}
        } = this.props;

        const { upgradeVersionValue, deviceUpgradeVersions } = this.state;

        const isScheduledUpgrade = scheduledUpgrade && (scheduledUpgrade.DeviceUpgradeStatus == 'In Progress' || scheduledUpgrade.DeviceUpgradeStatus == 'Scheduled');

        if (this.authService.isAdmin() && !checkAccess(CommonConstants.adminPermissions.PerformDeviceUpdate)) {
            return null;
        }

        // Loading state is true when both Portal Service fetches have not finished loading
        const isLoading = deviceUpgradeVersions === null || this.state.devicePeripheralInfos === null;
        if (isLoading) {
            return this.loadingButton();
        }

        // Get device version major value for the ZOOM series for comparison (i.e. 3)
        const zoomDeviceMajor = BuildVersions.parsedVersion(zoomDeviceEntryVersion).major;

        // Get the semver object from version
        const mainDeviceCurrentVersion = BuildVersions.parsedVersion(mainVersion);

        const upgradableVersions = this.getUpgradableVersions(deviceType, mainDeviceCurrentVersion);

        const isCib = deviceType === cibDeviceTypeId;
        const isIon = deviceType === ionDeviceTypeId;
        const isEos = deviceType === eosDeviceTypeId;
        const isZoom = deviceType === zoomDeviceTypeId;
        const isZoomCU40 = isZoom && mainDeviceCurrentVersion.major < zoomDeviceMajor;
        const isNexeo = deviceType === nexeoDeviceTypeId;
        const singleDevice = isCib || isIon || isEos || isZoom; // All projects that only support a single device

        // If upgrades are not supported for the device, then show upgrades are unavailable
        if (isIon) {
            return this.upgradeUnavailableButton();
        }

        // If device was recently upgraded, then do not allow another upgrade until the latest upgrade has finished
        if (!this.timeToUpgrade(upgrading, upgradeTime) || isScheduledUpgrade) {
            return this.upgradeUnavailableButton();
        }

        // Perform special treatment that only applies to a single (main) device that has no peripheral (component) devices
        if (singleDevice) {
            // If device is CIB or EOS, set upgrade version as DEFAULT_UPGRADE_SUBVERSION.
            // If device is CU40, do not display a upgrade versions list, but instead upgrade it to latest version DEFAULT_UPGRADE_SUBVERSION.
            if (isCib || isEos || isZoomCU40) {
                return this.upgradeSystemButton(DEFAULT_UPGRADE_SUBVERSION, upgradeTime);
            }

            // ZOOM devices with software version below 3.6.0 are not compatible with upgrade versions above 3.6.0.
            // Such devices must be first upgrade to 3.6.0 before upgrading them to latest version.
            const zoomUpgradeBackwardCompatibuilityThreshhold = BuildVersions.parsedVersion(Config.minimumBulkupgradableVersion);
            if (isZoom && BuildVersions.semverLt(mainDeviceCurrentVersion, zoomUpgradeBackwardCompatibuilityThreshhold)) {
                return this.upgradeSystemButton(zoomUpgradeBackwardCompatibuilityThreshhold.version, upgradeTime);
            }

            const latestVersion = this.getLatestVersion(deviceUpgradeVersions);

            // Display this element when:
            // a) Server did not respond with a valid mainVersion/deviceCurrentVersion.
            // b) If the versions list is empty or all the values in versions list are invalid.
            if (!latestVersion || !mainDeviceCurrentVersion) {
                return this.upgradeUnavailableButton();
            }

            // Do not allow any action from user when the system is at the latest version
            if (BuildVersions.semverGte(mainDeviceCurrentVersion, latestVersion)) {
                return this.systemAtLatestButton();
            }
        }

        // No greater versions available for upgrading
        if (!upgradableVersions.length) {
            return this.systemAtLatestButton();
        }

        return (
            <div className='ActionButtons'>
                <select
                    className='select-upgrade'
                    onChange={(e) => {
                        this.setState({
                            upgradeVersionValue: e.target.value,
                        });
                        this.confirm('upgrade', e.target.value, upgradeTime);
                    }}
                    value={upgradeVersionValue}
                >
                    <option disabled='disabled' value='Select Upgrade Version'>Select Upgrade Version</option>
                    {
                        upgradableVersions.map((item, index) => {
                            let optionLabel;
                            if (isNexeo) {
                                optionLabel = `${item.Name} ${item.ModelName} ${item.Description} v${item.Version}`;
                            } else if (isZoom) {
                                optionLabel = `${item.Name} ${item.ModelName} v${item.Version}`;
                            } else {
                                optionLabel = item.Version; // NOTE: item.Version should match the DTDS 'upgrade path' config so it is not broken
                            }
                            const optionValue = JSON.stringify({ name: item.Name, modelName: item.ModelName, version: item.Version }); // Use JSON for multiple option values
                            return <option value={optionValue} key={index}>{optionLabel}</option>;
                        })
                    }
                </select>
            </div>
        );
    }

    action(method, modelName, version) {
        window.location.href = this.navigate.getMenu(method, this.props.deviceUid, version, null, modelName);
    }

    // render actions for public portal
    renderRemoteActionsForClientUser() {
        // check if admin has perform device restart permission
        if (
            !checkAccess(CommonConstants.userPermissions.PerformDeviceRestart)
            && !(isDistributor() && checkAccess(CommonConstants.externalPermissions.ManageDevicesBasic))) {
            return <div />;
        }
        return (
            <div className='RemoteSystemActions' >
                <div className='client_remote_action_btn_space'>
                    <div className='client_remote_actionBtn' onClick={this.action.bind(this, 'reboot')}>
                        <Trans i18nKey='system-status__reboot'>
                            <span>Reboot</span>
                            <br /><span>System</span>
                        </Trans>
                    </div>
                </div>
                <div className='client_remote_action_btn_space'>
                    <div className='client_remote_actionBtn' onClick={this.action.bind(this, 'reconnect')}>
                        <Trans i18nKey='system-status__force'>
                            <span>Force</span>
                            <br /><span>Reconnect</span>
                        </Trans>
                    </div></div>
            </div>
        );
    }

    // Main render method.
    render() {
        return (
            <div>
                {this.authService.isAdmin() ? this.renderRemoteActionsForAdmin() : this.renderRemoteActionsForClientUser()}
            </div>
        );
    }
}

// Setting the state from a redux store.
function mapStateToProps(state) {
    return {
        storeViewDetails: state.viewDetails.storeViewDetails
    };
}
// Binding the actions.
function matchDispatchToProps(dispatch) {
    return bindActionCreators(
            {
                getPermission: getPermission
            }, dispatch
    );
}

export default compose(
        connect(mapStateToProps, matchDispatchToProps),
        withTranslation()
)(RemoteSystemActions);
