import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Section } from 'library/Section';
import { useTranslation } from 'react-i18next';
import { Radio } from 'library/Radio';
import { Paginate } from 'library/Paginate';
import { Grid } from 'components/Common/Grid';

import './SnapshotsSection.scss';

const gridHeaders = [
    {
        text: 'common__select',
        property: 'SelectSnapshot',
        flex: 30,
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-centered-cell',
    }, {
        text: 'common__name',
        property: 'SettingName',
        flex: 125,
        sortable: true,
    }, {
        text: 'common__description',
        property: 'Description',
        flex: 125,
        sortable: true,
    }, {
        text: 'common__settings__version',
        property: 'SettingsVersion',
        flex: 125,
        sortable: true,
    }, {
        text: 'common__lane-type',
        property: 'SourceLaneConfig',
        flex: 100,
        sortable: true,
    }, {
        text: 'common__device-version',
        property: 'SourceSoftwareVersion',
        flex: 80,
        sortable: true,
    }, {
        text: 'common__device-serial_no',
        property: 'SourceSN',
        flex: 100,
        sortable: true,
    },
];

const pageSizes = [10, 20, 50, 100];

export const SnapshotsSection = ({
    deviceType,
    snapshots,
    isLoading,
    disabled,
    onSnapshotSelected,
    pageNumber,
    setPageNumber,
    itemsPerPage,
    setItemsPerPage,
    snapshotCount,
    sortSelection,
    setSortSelection,
    availableFilters,
    gridFilters,
    setGridFilters
}) => {
    const { t } = useTranslation();
    const [selectedSnapshot, setSelectedSnapshot] = useState(null);

    const onSnapshotChosen = useCallback((snapshot) => {
        setSelectedSnapshot(snapshot);
        onSnapshotSelected(snapshot);
    }, []);

    const snapshotsToRows = useCallback((visibleSnapshots, chosenSnapshot) =>
        visibleSnapshots.map((snapshot) => {
            return {
                ...snapshot,
                SelectSnapshot: (
                    <Radio
                        key={snapshot.SnapshotUID}
                        variants={[]}
                        checked={
                            (chosenSnapshot && chosenSnapshot.SnapshotUID === snapshot.SnapshotUID) || false
                        }
                        onChange={() => onSnapshotChosen && onSnapshotChosen(snapshot)}
                    />
                )
            };
        }),
    [onSnapshotChosen]
    );

    const onPaginationChange = useCallback(({ page = 0, recordsPerPage = 10 } = {}) => {
        setPageNumber(page);
        setItemsPerPage(recordsPerPage);
    }, [setPageNumber, setItemsPerPage],
    );

    const snapshotRows = useMemo(() =>
        snapshotsToRows(snapshots, selectedSnapshot), [selectedSnapshot, snapshots]
    );

    useEffect(() => {
        setSelectedSnapshot(null);
        onSnapshotSelected(null);
        setPageNumber(0);
    }, [deviceType]);

    return (
        <Section
            number={2}
            title={t('common__select-a-snapshot')}
            disabled={disabled}
            className="add-store-device__snapshot-list"
        >
            <Grid
                isLoading={isLoading}
                rows={snapshotRows}
                headers={gridHeaders}
                noRecordsMessage={t('apply-device-settings-status__no-devices--found')}
                rowKey="SnapshotUID"
                availableFilters={availableFilters}
                filters={gridFilters}
                onFiltersChange={setGridFilters}
                onSortChange={setSortSelection}
                sortSelection={sortSelection}
            />
            <Paginate
                className="hme-systems__paginate"
                page={pageNumber}
                recordsPerPage={itemsPerPage}
                pageSizes={pageSizes}
                total={snapshotCount}
                onChange={onPaginationChange}
                hideSinglePage
            />
        </Section>
    );
};

SnapshotsSection.propTypes = {
    deviceType: PropTypes.string,
    snapshots: PropTypes.arrayOf(PropTypes.shape({})),
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    onSnapshotSelected: PropTypes.func,
    pageNumber: PropTypes.number,
    setPageNumber: PropTypes.func,
    itemsPerPage: PropTypes.number,
    setItemsPerPage: PropTypes.func,
    snapshotCount: PropTypes.number,
    sortSelection: PropTypes.shape({}),
    setSortSelection: PropTypes.func,
    availableFilters: PropTypes.arrayOf(PropTypes.shape({})),
    gridFilters: PropTypes.object,
    setGridFilters: PropTypes.func
};
