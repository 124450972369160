import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';
import { Button } from 'library/Button';
import { AutoCompleteInput } from 'library/AutoCompleteInput';
import { SearchInput } from 'components/Inputs';

import './Header.scss';

export const accountsResultHeaders = [
    {
        text: '',
        property: 'cloudAccountEmail',
    },
];

export const Header = ({
    cloudAccounts,
    feedSearchValue,
    onFeedSearch,
    isFeedSearchDisabled = false,
    onAccountSelected,
    onFeedCreate,
}) => {
    const [accountSearchValue, setAccountSearchValue] = useState('');
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        setFilteredAccounts(cloudAccounts.filter(({ cloudAccountEmail = '' } = {}) => {
            const accountEmail = cloudAccountEmail.trim().toLowerCase();
            const searchText = accountSearchValue.trim().toLowerCase();
            return accountEmail.includes(searchText);
        }));
    }, [accountSearchValue]);

    return (
        <div className="dxs-feeds-header">
            <div className="dxs-feeds-header-title-wrapper">
                <Title>{t('common__dxs-feeds')}</Title>
                <SearchInput
                    value={feedSearchValue}
                    onChange={onFeedSearch}
                    showClearIcon
                    isDisabled={isFeedSearchDisabled}
                    placeholder={'DXS Feed Name'}
                />
                <div className="dxs_feeds__account-search">
                    <AutoCompleteInput
                        value={accountSearchValue}
                        suggestions={filteredAccounts}
                        headers={accountsResultHeaders}
                        rowKey="cloudAccountID"
                        placeholder={t('common__account__email')}
                        isLoading={false}
                        noSuggestionText={t('accounts__no-accounts')}
                        Component={SearchInput}
                        onChange={setAccountSearchValue}
                        onSuggested={onAccountSelected}
                    />
                </div>
            </div>
            <div className="dxs-feeds-header-link">
                <Button onClick={onFeedCreate}>{t('dxs-feeds__header__create-new-feed')}</Button>
            </div>
        </div>
    );
};
