import { CommonConstants } from 'Constants';
import { Config } from 'Config';
import { getDataAsync, postDataAsync, deleteDataAsync, getSearchString } from 'Api';
import { getAdminProfile, isMasquerade, isAdmin, getProfile, getUUID } from './Auth';

const errors = {
    USER_EXISTS: 'userAlreadyExist',
};

export const getDistributorUsers = async ({ auuid }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDistributorAccountUsers}`;

    const params = {
        auuid,
        isAdmin: 0,
        pno: 0,
        Sortby: 'User_EmailAddress',
        sortType: 'ASC',
    };

    const search = getSearchString(params);

    const { usersList, status } = await getDataAsync(`${url}?${search}`);

    if (!status) {
        throw new Error('common__error--internal-server');
    }

    return {
        usersList,
    };
};

export const getDistributorUser = async ({ accountUuid, userUid }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDistributorAccountUser}`;

    const params = {
        auuid: accountUuid,
        uuId: userUid,
    };

    const search = getSearchString(params);

    const { data, status } = await getDataAsync(`${url}?${search}`);

    if (!status) {
        throw new Error('common__error--internal-server');
    }

    return data;
};

export const createDistributorUser = async ({ user, accountUuid, isAdmin = false }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.createDistributorUserForAccount}`;

    const params = {
        auuid: accountUuid,
    };

    const search = getSearchString(params);

    const payload = {
        uuId: null,
        firstName: user.firstName,
        lastName: user.lastName,
        userEmail: user.userName,
        isActive: user.isActive,
        userRole: user.role,
        storeIds: user.stores,
        groupIds: user.groups,
        adminUser: isAdmin ? getAdminProfile().unique_name : null,
        masqueradedBy: isMasquerade() ? getAdminProfile().unique_name : null,
    };

    let response;

    try {
        response = await postDataAsync(`${url}?${search}`, payload);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (!response.status) {
        switch (response.key) {
            case errors.USER_EXISTS:
                throw new Error('add-user__error--user-exists');

            default:
                throw new Error('common__error--internal-server');
        }
    }
};

export const editDistributorUser = async ({ user, accountUuid, isAdmin = false }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.editDistributorUserForAccount}`;

    const params = {
        auuid: accountUuid,
    };

    const search = getSearchString(params);

    const payload = {
        uuId: user.uid,
        firstName: user.firstName,
        lastName: user.lastName,
        userEmail: user.userName,
        isActive: user.isActive,
        userRole: user.role,
        storeIds: user.stores,
        groupIds: user.groups,
        adminUser: isAdmin ? getAdminProfile().unique_name : null,
        masqueradedBy: isMasquerade() ? getAdminProfile().unique_name : null,
    };

    let response;

    try {
        response = await postDataAsync(`${url}?${search}`, payload);
    } catch (err) {
        throw new Error('common__error--internal-server');
    }

    if (!response.status) {
        switch (response.key) {
            case errors.USER_EXISTS:
                throw new Error('add-user__error--user-exists');

            default:
                throw new Error('common__error--internal-server');
        }
    }
};

export const removeDistributorUser = ({ accountUuid, userUid }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.deleteDistributorUserFromAccount}`;

    const params = {
        auuid: accountUuid,
        uuId: userUid,
    };

    if (isMasquerade()) {
        params.adminUser = getAdminProfile().unique_name;
    }

    const search = getSearchString(params);

    return deleteDataAsync(`${url}?${search}`);
};

export const getListOfUsers = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getCompanyUsers}`;
    const isAdminUser = isAdmin();

    const params = isAdminUser ?
        {
            isAdmin: 1,
            uuid: getUUID()
        } :
        {
            isAdmin: 0
        };

    const search = getSearchString(params);

    try {
        const response = await getDataAsync(`${url}?${search}`);

        if (!response.status) {
            throw new Error('common__error--internal-server');
        }

        const { usersList } = response;

        if (isAdminUser) {

            return usersList;
        } else {
            // filter users for non-admin accounts
            const userDetails = getProfile();

            return usersList.filter((u) => u.User_UID !== userDetails.User_UID);
        }
    } catch (err) {
        throw new Error('common__error--internal-server');
    }
};

export const createUser = async ({ user }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.createUser}`;

    const payload = {
        uuId: null,
        firstName: user.firstName,
        lastName: user.lastName,
        userEmail: user.userName,
        isActive: user.isActive,
        userRole: user.role,
        storeIds: user.stores,
        groupIds: user.groups,
        adminUser: isAdmin() ? getAdminProfile().unique_name : null,
        masqueradedBy: isMasquerade() ? getAdminProfile().unique_name : null,
    };

    let response;

    try {
        response = await postDataAsync(url, payload);
    } catch (err) {
        response = err.response.data;

        if (response.key !== errors.USER_EXISTS) {
            throw new Error('common__error--internal-server');
        }
    }

    if (!response.status) {
        switch (response.key) {
            case errors.USER_EXISTS:
                throw new Error('add-user__error--user-exists');

            default:
                throw new Error('common__error--internal-server');
        }
    }
};

export const getUser = async ({ userUid }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getUser}`;

    const params = {
        uuId: userUid,
    };

    const search = getSearchString(params);

    let response;

    try {
        response = await getDataAsync(`${url}?${search}`);
    } catch (err) {
        // handle no data found
        const errorMessage =
            err.response?.data?.key === 'noDataFound'
                ? 'common__error--user-not-found'
                : 'common__error--internal-server';

        throw new Error(errorMessage);
    }

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};

export const editUser = async ({ user }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.updateUser}`;

    const payload = {
        uuId: user.uid,
        firstName: user.firstName,
        lastName: user.lastName,
        userEmail: user.userName,
        isActive: user.isActive,
        userRole: user.role,
        storeIds: user.stores,
        groupIds: user.groups,
        adminUser: isAdmin() ? getAdminProfile().unique_name : null,
        masqueradedBy: isMasquerade() ? getAdminProfile().unique_name : null,
    };

    let response;

    try {
        response = await postDataAsync(url, payload);
    } catch (err) {
        response = err.response.data;

        if (response.key !== errors.USER_EXISTS) {
            throw new Error('common__error--internal-server');
        }
    }

    if (!response.status) {
        switch (response.key) {
            case errors.USER_EXISTS:
                throw new Error('add-user__error--user-exists');

            default:
                throw new Error('common__error--internal-server');
        }
    }
};

export const removeUser = ({ userUid }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.deleteUser}`;

    const params = {
        uuId: userUid,
    };

    if (isMasquerade()) {
        params.adminUser = getAdminProfile().unique_name;
    }

    const search = getSearchString(params);

    return deleteDataAsync(`${url}?${search}`);
};


export const getActivity = async ({ userUid }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getAudit}`;

    const params = {
        uuId: userUid,
    };

    const search = getSearchString(params);

    let response;

    try {
        response = await getDataAsync(`${url}?${search}`);
    } catch (err) {
        return [];
    }

    return response.data;
};
