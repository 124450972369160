import * as users from '../actionTypes/Users/Users'
import Api from '../Api'
import { Config } from '../Config'
import { CommonConstants } from '../Constants'
import AuthenticationService from '../components/Security/AuthenticationService'
const _ = require('underscore')

function getUsersSuccess (stores) {
  return {
    type: users.GET_USERS,
    usersDetails: stores
  }
}
function getActivitySuccess (activity) {
  return {
    type: users.GET_ACTIVITY,
    activity: activity
  }
}

// This function returns list of activities
export const getActivity = (uuid, callback) => {
  let url = Config.apiBaseUrl + CommonConstants.apiUrls.getAudit + '?uuId=' + uuid
  return (dispatch, getState) => {
    const api = new Api()
    const state = getState()
    if (state.users.sortParamsActivity) {
      url = url + '&sortBy=' + (state.users.sortParamsActivity.sortBy ? state.users.sortParamsActivity.sortBy : '') + '&sortType=' + (state.users.sortParamsActivity.sortType ? state.users.sortParamsActivity.sortType : '')
    }

    api.getData(url, data => {
      let activity = data.data
      dispatch(getActivitySuccess(activity))
      callback()
    })
  }
}

// save new user
export const SaveUsers = (data, callback, url) => {
  return (dispatch, getState) => {
    const api = new Api()
    api.postData(url, data, result => {
      dispatch(getUsersSuccess(data))
      callback(null, result)
    }, error => {
      dispatch(getUsersSuccess(data))
      callback(error)
    })
  }
}

// set sort parametes for user listing page in redux
export const sortActivity = (sortParams) => {
  return {
    type: users.SET_SORT_PARAMS_ACTIVITY,
    sortParams: sortParams
  }
}

// set sort parametes for user listing page in redux
export const sortUsers = (sortParams) => {
  return {
    type: users.SET_SORT_PARAMS_USERS,
    sortParams: sortParams
  }
}

// set search parametes for user listing page in redux
export const setSearchParams = (searchParams) => {
  return {
    type: users.SET_SEARCH_PARAMS_USERS,
    searchParams: searchParams
  }
}

export const setUUID = (uuid) => {
  return {
    type: users.SET_USERS_UUID,
    uuid: uuid
  }
}
// Forgot password post call
export const forgotPassword = (data, callback) => {
    const api = new Api();
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.forgotPassword;
    return (dispatch, getState) => {
        api.postData(url, data, (result) => {
            callback(result);
        });
    };
};
// Reset password expiry post call
export const checkResetPasswordLinkExpiry = (data, callback) => {
  let url = Config.apiBaseUrl + CommonConstants.apiUrls.checkResetPasswordLinkExpiry
  return (dispatch, getState) => {
    const api = new Api()
    api.postData(url, data, result => {
      callback(result)
    })
  }
}
// Reset password post call
export const resetPassword = (data, callback) => {
  let url = Config.apiBaseUrl + CommonConstants.apiUrls.resetPassword
  return (dispatch, getState) => {
    const api = new Api()
    api.postData(url, data, result => {
      callback(result)
    })
  }
}
