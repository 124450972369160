import { aasFilterTableName } from 'constants/pbiReports';

export const startOfTheWeekFilter = (startOfTheWeek, reportType) => ({
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
        table: aasFilterTableName[reportType] || 'Dates_Filter',
        column: 'Start_Day'
    },
    operator: 'In',
    values: [
        startOfTheWeek,
        null
    ]
});
