import React from 'react';

import { Modal } from 'library/Modal';
import { List } from 'library/List';

import './SettingsInfo.scss';

export const SettingsInfo = props => (
    <Modal
        dialogClassName='hme-zoom-settings-info'
        title={
            <>
                <span className='icon-info'></span>
                <span className='hme-zoom-settings-info-title-label'>
                    ZOOM Device Settings Information
                </span>
            </>
        }
        {...props}
    >
        <List title='Dashboard Layout' items={[
            'Customize dashboard layout and metric settings.'
        ]} />
        <List title='Total Time Definition' items={[
            'Customize when your Total Time starts and stops (Total Time for Lane, Total 2 Time for Lane).'
        ]} />
        <List title='Daypart Times' items={[
            'Choose start times for Dayparts 1-12.'
        ]} />
        <List title='Shift Times' items={[
            'Choose start and end times for Shifts.'
        ]} />
        <List title='Master Goals' items={[
            'Customize Master Goals, which includes any period of time outside of a Daypart.',
            'Master Goals cannot be applied at the same time as Daypart Goals. Daypart Goals will be overridden when Master Goals are applied.'
        ]} />
        <List title='Daypart Goals' items={[
            'Customize Daypart Goals for Dayparts 1-12 for each day of the week.',
            'Daypart Goals cannot be applied at the same time as Master Goals.  Daypart Goals will be overridden when Master Goals are applied.'
        ]} />
    </Modal>
);
