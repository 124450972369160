import { useCallback } from 'react';

import { changeAccountData } from 'services/Account';

export const prepareAccountDataDTO = (accountData) => ({
    ...accountData,
    status: true
});

export const useUdpateAccount = () => {
    const updateAccountData = useCallback((accountData) => {
        const accountDataDTO = prepareAccountDataDTO(accountData);

        return changeAccountData(accountDataDTO);
    }, []);

    return { updateAccountData };
};
