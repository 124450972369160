import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Constant } from '@hme-cloud/utility-common';
import { useParams, useNavigate } from 'react-router-dom';
import { compose } from 'ramda';
import { useTranslation } from 'react-i18next';

import { withHMELayout } from 'HOCs/withHMELayout';
import { NotificationsList } from 'library/NotificationsList';

import { DeviceStatus } from './DeviceStatus/DeviceStatus';
import { SendUpgradeConfirmPopup } from './SendUpgradeConfirmPopup';
import { notificationActions, showNotification, getStoreCountryName } from './helpers';

import { useLoadDevice } from './hooks/useLoadDevices';
import { useLoadPeripheralDevices } from './hooks/useLoadPeripheralDevices';
import { useReconnectDevice } from './hooks/useReconnectDevice';
import { useRebootDevice } from './hooks/useRebootDevice';
import { useUpgradeDevice } from './hooks/useUpgradeDevice';
import { useLoadCountries } from './hooks/useLoadCountries';
import { useLoadDeviceScheduledUpgrade } from '../hooks/useLoadDeviceScheduledUpgrade';
import { ADMIN_SYSTEM_NOT_FOUND_URL } from '../Common/constants';

import './SystemStatus.scss';

const { DeviceType } = Constant;

const SystemStatus = () => {
    const { t } = useTranslation();
    const { deviceUID } = useParams();
    const navigate = useNavigate();
    const [upgradeTaskData, setUpgradeTaskData] = useState({});
    const [isSendUpgradeConfirmShown, setIsSendUpgradeConfirmShown] = useState(false);
    const [isScheduledUpgradeChanged, setIsScheduledUpgradeChanged] = useState(false);

    const { countries, isCountriesLoading } = useLoadCountries();
    const { device, isDeviceLoading, deviceLoadingError, loadDevice } = useLoadDevice(deviceUID);
    const { peripheralDevices, isPeripheralDevicesLoading, peripheralDevicesLoadingError, loadPeripheralDevices } =
        useLoadPeripheralDevices(deviceUID);

    const { isDeviceReconnecting, reconnectDevice } = useReconnectDevice(
        loadDevice,
        loadPeripheralDevices,
    );

    const { isDeviceRebooting, rebootDevice } = useRebootDevice(
        loadDevice,
        loadPeripheralDevices,
    );

    const { isDeviceUpgrading, upgradeDevice } = useUpgradeDevice(
        loadDevice,
        loadPeripheralDevices,
    );

    const { deviceScheduledUpgrade, isDeviceScheduledUpgradeLoading } =
        useLoadDeviceScheduledUpgrade(deviceUID);

    const onSendUpgrade = useCallback(
        (upgradeTask) => {
            setUpgradeTaskData(upgradeTask);
            setIsSendUpgradeConfirmShown(true);
        },
        [setUpgradeTaskData, setIsSendUpgradeConfirmShown],
    );

    const onSendUpgradeConfirmHide = useCallback(() => {
        setIsSendUpgradeConfirmShown(false);
    }, [setIsSendUpgradeConfirmShown]);

    const onSendUpgradeConfirm = useCallback(async () => {
        const { status, message } = await upgradeDevice(upgradeTaskData);

        // TODO move all the possible createUpgradeTask error messages to the constants in utility-common
        const action =
            message === 'UPGRADE_IN_PROGRESS' 
                ? notificationActions.UPGRADE_IN_PROGRESS
                : notificationActions.UPGRADE_DEVICE;

        showNotification({ action, status, t });
    }, [upgradeTaskData, deviceUID]);

    const onDeviceReboot = useCallback(async (device) => {
        const status = await rebootDevice(device.Device_UID);
        showNotification({ action: notificationActions.REBOOT_DEVICE, status, t });
    }, []);

    const onDeviceReconnect = useCallback(async (device) => {
        const status = await reconnectDevice(device.Device_UID);
        showNotification({ action: notificationActions.RECONNECT_DEVICE, status, t });
    }, []);

    const isDataLoading =
        isDeviceLoading ||
        isPeripheralDevicesLoading ||
        isDeviceReconnecting ||
        isDeviceRebooting ||
        isDeviceUpgrading ||
        isDeviceScheduledUpgradeLoading ||
        isCountriesLoading;

    const error =
        deviceLoadingError ||
        peripheralDevicesLoadingError;

    const storeCountry = useMemo(() => getStoreCountryName(device, countries), [device, countries]);

    useEffect(() => {

        if (device && device.Device_DeviceType_ID !== DeviceType.NEXEO.typeId) {

            navigate(ADMIN_SYSTEM_NOT_FOUND_URL);
        }
    }, [device]);

    return (
        <div className="hme-admin-system-status-wrapper">
            <NotificationsList />
            <DeviceStatus
                device={device}
                peripheralDevices={peripheralDevices}
                storeCountry={storeCountry}
                isLoading={isDataLoading}
                error={error}
                onDeviceVersionUpgrade={onSendUpgrade}
                onDeviceReboot={onDeviceReboot}
                onDeviceReconnect={onDeviceReconnect}
                deviceScheduledUpgrade={deviceScheduledUpgrade}
                isScheduledUpgradeChanged={isScheduledUpgradeChanged}
                onScheduledUpgradeChanged={setIsScheduledUpgradeChanged}
            />
            <SendUpgradeConfirmPopup
                show={isSendUpgradeConfirmShown}
                onHide={onSendUpgradeConfirmHide}
                onConfirm={onSendUpgradeConfirm}
                upgradeTaskData={upgradeTaskData}
            />
        </div>
    );
};

export const AdminNexeoSystemStatus = compose(
    withHMELayout({
        contentClasses: ['admin-system-status-page'],
    }),
)(SystemStatus);
