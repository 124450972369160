import React from 'react';
import classNames from 'classnames';

import { Loader } from 'library/Loader';

import './OverlayLoader.scss';

export const OverlayLoader = ({ className }) => {
    return (
        <div className={classNames('hme-overlay-loader', className)}>
            <div className="hme-overlay-loader__wrapper">
                <Loader />
            </div>
        </div>
    );
};
