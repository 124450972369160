import React from 'react';
import { compose } from 'ramda';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { withHMELayout } from 'HOCs/withHMELayout';
import { NotificationsList } from 'library/NotificationsList';
import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';
import { Paginate } from 'library/Paginate';
import { Header } from '../Common/Header';
import { List } from '../Common/List';
import { useUsersList } from './hooks/useUsersList';
import { USERS_LIST_HEADERS } from './helpers';
import { Footer } from '../Common/Footer';

import './UsersList.scss';

const PAGE_SIZES = [10, 20, 50, 100];

const UsersListComponent = () => {
    const { t } = useTranslation();
    const {
        users,
        rows,
        sortSelection,
        isLoading,
        paginationData,
        searchOptions,
        isConfirmModalOpen,
        onSortChange,
        onPaginateChange,
        onCancelRemoveUser,
        onConfirmRemoveUser
    } = useUsersList();

    const recordsTotal = users?.[0]?.Total || 0;
    const shouldFooterBeShown = !!(users?.length && recordsTotal > PAGE_SIZES[0]);

    return (
        <div className={classNames('hme-page-component', 'hme-users--wrapper')}>
            <div className="hme-users--list">
                <NotificationsList />
                <Header searchOptions={searchOptions} />
                <List
                    rows={rows}
                    headers={USERS_LIST_HEADERS}
                    isLoading={isLoading}
                    sortSelection={sortSelection}
                    onSortChange={onSortChange}
                />
                {shouldFooterBeShown && (
                    <Footer>
                        <Paginate
                            className="hme-users-list__paginate"
                            total={recordsTotal}
                            page={paginationData.page}
                            onChange={onPaginateChange}
                            recordsPerPage={paginationData.recordsPerPage}
                            pageSizes={PAGE_SIZES}
                        />
                    </Footer>
                )}
                <ConfirmPopupComponentGeneric
                    show={isConfirmModalOpen}
                    title={t('common__double-checking')}
                    message={t('add-user__confirm--remove')}
                    className={'admin-users-list__confirm-popup'}
                    actions={[
                        {
                            children: t('common__popup--cancel-action'),
                            onClick: onCancelRemoveUser,
                        },
                        {
                            children: t('common__popup--confirm-action'),
                            variants: ['submit'],
                            onClick: onConfirmRemoveUser,
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export const UsersList = compose(
    withHMELayout({
        contentClasses: ['admin-users-page'],
    }),
)(UsersListComponent);
