export const GRID_HEADERS = [
    {
        text: 'common__account',
        property: 'User_EmailAddress',
        className: 'hme-grid-cell-show-all-content',
        flex: 2,
    },
    {
        text: 'common__company-type',
        property: 'Company_Type',
        flex: 1.2,
    },
    {
        text: 'common__brand',
        property: 'Brand_Name',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__device__serial-number',
        property: 'Device_SerialNumber',
        className: 'hme-grid-cell-show-all-content',
        flex: 1.5,
    },
    {
        text: 'common__store__number',
        property: 'Store_Number',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__store__name',
        property: 'Store_Name',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__lane-config',
        property: 'Device_LaneConfig_Name',
    },
    {
        text: 'common__device-server-type',
        property: 'DeviceServerType',
    },
    {
        text: 'common__device__version',
        property: 'Device_MainVersion',
        className: 'hme-grid-cell-show-all-content',
        headerClassName: 'admin-create-apply-device-settings-task-target-version-cell',
    },
];

export const NEXEO_GRID_HEADERS = [
    {
        text: 'common__account',
        property: 'User_EmailAddress',
        className: 'hme-grid-cell-show-all-content',
        flex: 2,
    },
    {
        text: 'common__company-type',
        property: 'Company_Type',
        flex: 1.2,
    },
    {
        text: 'common__brand',
        property: 'Brand_Name',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__device__serial-number',
        property: 'Device_SerialNumber',
        className: 'hme-grid-cell-show-all-content',
        flex: 1.5,
    },
    {
        text: 'common__device__product-id',
        property: 'Device_Product_ID',
        className: 'hme-grid-cell-show-all-content',
        flex: 1.5,
    },
    {
        text: 'common__store__number',
        property: 'Store_Number',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__store__name',
        property: 'Store_Name',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__lane-config',
        property: 'Device_LaneConfig_Name',
    },
    {
        text: 'common__device__version',
        property: 'Device_MainVersion',
        className: 'hme-grid-cell-show-all-content',
        headerClassName: 'admin-create-apply-device-settings-task-target-version-cell',
    },
    {
        text: 'common__settings__version',
        property: 'Device_SettingVersion',
        headerClassName: 'apply-device-settings__settings__version__header',
    },
];

export const STORE_GRID_HEADERS = [
    {
        text: 'common__account',
        property: 'User_EmailAddress',
        className: 'hme-grid-cell-show-all-content',
        flex: 2,
    },
    {
        text: 'common__company-type',
        property: 'Company_Type',
        flex: 1.2,
    },
    {
        text: 'common__brand',
        property: 'Brand_Name',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__device__serial-number',
        property: 'Device_SerialNumber',
        className: 'hme-grid-cell-show-all-content',
        flex: 1.5,
    },
    {
        text: 'common__store__number',
        property: 'Store_Number',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__store__name',
        property: 'Store_Name',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__lane-config',
        property: 'Device_LaneConfig_Name',
    },
    {
        text: 'common__device-server-type',
        property: 'DeviceServerType',
    },
    {
        text: 'common__device__version',
        property: 'Device_MainVersion',
        className: 'hme-grid-cell-show-all-content',
        headerClassName: 'admin-create-apply-device-settings-task-target-version-cell',
    },
    {
        text: 'common__actions',
        property: 'actions',
    },
];

export const STORE_NEXEO_GRID_HEADERS = [
    {
        text: 'common__account',
        property: 'User_EmailAddress',
        className: 'hme-grid-cell-show-all-content',
        flex: 2,
    },
    {
        text: 'common__company-type',
        property: 'Company_Type',
        flex: 1.2,
    },
    {
        text: 'common__brand',
        property: 'Brand_Name',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__device__serial-number',
        property: 'Device_SerialNumber',
        className: 'hme-grid-cell-show-all-content',
        flex: 1.5,
    },
    {
        text: 'common__device__product-id',
        property: 'Device_Product_ID',
        className: 'hme-grid-cell-show-all-content',
        flex: 1.5,
    },
    {
        text: 'common__store__number',
        property: 'Store_Number',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__store__name',
        property: 'Store_Name',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__lane-config',
        property: 'Device_LaneConfig_Name',
    },
    {
        text: 'common__device__version',
        property: 'Device_MainVersion',
        className: 'hme-grid-cell-show-all-content',
        headerClassName: 'admin-create-apply-device-settings-task-target-version-cell',
    },
    {
        text: 'common__settings__version',
        property: 'Device_SettingVersion',
        headerClassName: 'apply-device-settings__settings__version__header',
    },
    {
        text: 'common__actions',
        property: 'actions',
    },
];

