import { accountTypes } from 'constants/accountTypes';

export const DEVICE_SETTINGS_GRID_OPTIONS = [
    {
        text: 'common__name',
        property: 'SettingName',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__description',
        property: 'Description',
        flex: 169,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
        accountTypes: [accountTypes.ADMIN, accountTypes.DISTRIBUTOR],
    },
    {
        text: 'common__snapshot__version',
        property: 'SettingsVersion',
        flex: 80,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__brand',
        property: 'SourceBrand',
        className: 'hme-grid-cell-show-all-content',
        flex: 80,
        sortable: true,
    },
    {
        text: 'settings_snapshots__grid__header-title--brand-approved',
        property: 'IsBrandApproved',
        className: 'hme-grid-cell-show-all-content',
        flex: 80,
        accountTypes: [accountTypes.ADMIN, accountTypes.DISTRIBUTOR],
        sortable: true,
    },
    {
        text: 'settings_snapshots__grid__header-title--test-snapshot',
        property: 'IsTestSnapshot',
        className: 'hme-grid-cell-show-all-content',
        flex: 80,
        accountTypes: [accountTypes.ADMIN, accountTypes.DISTRIBUTOR],
    },
    {
        text: 'common__account',
        property: 'SourceAccount',
        flex: 255,
        className: 'device-settings-snapshots-account-cell',
        sortable: true,
        accountTypes: [accountTypes.ADMIN, accountTypes.DISTRIBUTOR],
    },
    {
        text: 'settings_snapshots__grid__header-title--source-type',
        property: 'SourceDeviceType',
        className: 'hme-grid-cell-show-all-content',
        flex: 65,
        sortable: true,
    },
    {
        text: 'settings_snapshots__grid__header-title--source-settings-version',
        property: 'SourceSettingsVersion',
        className: 'hme-grid-cell-show-all-content',
        flex: 65,
        sortable: true,
    },
    {
        text: 'settings_snapshots__grid__header-title--source-version',
        property: 'SourceSoftwareVersion',
        className: 'hme-grid-cell-show-all-content',
        flex: 70,
        sortable: true,
    },
    {
        text: 'settings_snapshots__grid__header-title--serial-number',
        property: 'SourceSN',
        className: 'hme-grid-cell-show-all-content',
        flex: 100,
        sortable: true,
    },
    {
        text: 'common__device__product-id',
        property: 'SourceDeviceProductID',
        className: 'hme-grid-cell-show-all-content',
        flex: 100,
        sortable: true,
    },
    {
        text: 'common__lane',
        property: 'SourceLaneConfig',
        className: 'hme-grid-cell-show-all-content',
        headerClassName: 'hme-grid-filter-align-right',
        flex: 65,
        sortable: true,
    },
    {
        text: 'common__last-used--date-time',
        property: 'AppliedDate',
        flex: 150,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
        sortDirections: [0, -1, 1],
    },
    {
        text: 'common__created-by',
        property: 'CreatedBy',
        flex: 170,
        className: 'hme-grid-cell-show-all-content device-settings-snapshots-created-by-cell',
        headerClassName: 'hme-grid-filter-align-right',
        sortable: true,
    },
    {
        text: 'common__status',
        property: 'Status',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        headerClassName: 'hme-grid-filter-align-right',
        sortable: true,
    },
    {
        text: 'common__actions',
        property: 'Actions',
        className: 'hme-grid-action-cell',
        headerClassName: 'device-settings-snapshots-action-header',
        flex: 55,
    },
];
