import React from 'react';
import classNames from 'classnames';

import './CenterMessage.scss';

export const CenterMessage = ({ icon = null, variants = [], children = null }) => (
    <div className={classNames(
        'hme-center-message',
        variants.map(variant => `hme-center-message-${variant}`)
    )}>
        <div className='hme-center-message-body'>
            {icon}
            { children ? <h2>{children}</h2> : null }
        </div>
    </div>
);
