import {
    parseISO,
    parse,
    format,
    getDay,
    getHours,
    addHours,
    addMilliseconds,
    addWeeks,
    addDays,
    addMonths,
    addMinutes,
    startOfDay,
    startOfWeek,
    startOfMonth,
    startOfYear,
    endOfYear,
    endOfMonth,
    endOfWeek,
    endOfDay,
    isAfter,
    isBefore,
    isValid,
    isSameMonth,
    isSameDay,
    differenceInMilliseconds,
    setYear,
    setMonth,
    setHours,
    differenceInDays,
    addSeconds,
    setDay,
    subMonths,
    getUnixTime,
    differenceInMinutes
} from 'date-fns';
import * as locales from 'date-fns/locale';
import { getTimezoneOffset, format as tzFormat, utcToZonedTime } from 'date-fns-tz';
import setDefaultOptions from 'date-fns/setDefaultOptions';

import { capitalize } from 'helpers/string/capitalize';

import {FORMAT_TYPES} from './constants';

const lngs = {
    'en-US': locales.enUS,
    'de': locales.de,
    'es': locales.es,
    'es-LA': locales.es,
    'fr': locales.fr,
    'fr-CA': locales.frCA,
    'it': locales.it,
    'ja': locales.ja,
    'pl': locales.pl,
    'pt-BR': locales.ptBR,
    'zh-TW': locales.zhTW,
};

const getDate = (date) => {
    if (!date && date !== 0) {
        return new Date();
    }

    return typeof date === 'string' ? parseISO(date) : new Date(date);
};

export class DateLib {
    constructor(date, options = {}) {
        this.date = getDate(date);
        this.locale = options.locale ? lngs[options.locale] : null;
    }

    // #region Base methods
    clone() {
        return new this.constructor(this.date, { loclale: this.locale });
    }

    getDate() {
        return this.date;
    }

    getDay() {
        return this.date.getDay();
    }

    static toDateLib(date) {
        if (date instanceof DateLib) {
            return date;
        }

        return new DateLib(date);
    }

    static parse(dateStr, format) {
        return new DateLib(parse(dateStr, format, new Date()));
    }
    // #endregion

   // #region Locale
   static setLocale(lng) {
        setDefaultOptions({
            locale: lngs[lng] || lngs['en-US'],
        });
    };

    setLocale(locale) {
        this.locale = locale ? lngs[locale] || null : null;

        return this;
    }
    //#endregion 
    
    // #region days in a week
    static AMOUNT_OF_WEEK_DAYS = 7;
    //#endregion 
    
    // #region Format
    static FORMAT_TYPES = FORMAT_TYPES

    getFormatOptions() {
        const options = {};

        if (this.locale) {
            options.locale = this.locale;
        }

        return options;
    }

    format(formatType = DateLib.FORMAT_TYPES.DEFAULT) {
        const formatOptions = this.getFormatOptions();

        return format(this.date, formatType, formatOptions);
    }
    
    formatInTimeZone(formatType = DateLib.FORMAT_TYPES.DEFAULT, tz = 'UTC') {
        const formatOptions = this.getFormatOptions();

        return tzFormat(
            utcToZonedTime(this.date, tz),
            formatType,
            {
                ...formatOptions,
                timeZone: tz
            }
        );
    }
    
    formatInUTC(formatType = DateLib.FORMAT_TYPES.DEFAULT) {
        return this.formatInTimeZone(formatType);
    };

    toISOString() {
        return this.date.toISOString();
    }
    //#endregion

    //#region Add Time
    addHours(amount) {
        this.date = addHours(this.date, amount);

        return this;
    };
    
    addMilliseconds(amount) {
        this.date = addMilliseconds(this.date, amount);

        return this;
    };
    
    addWeeks(amount) {
        this.date = addWeeks(this.date, amount);

        return this;
    };
    
    addDays(amount) {
        this.date = addDays(this.date, amount);

        return this;
    };
    
    addMonths(amount) {
        this.date = addMonths(this.date, amount);

        return this;
    };
    
    addMinutes(amount) {
        this.date = addMinutes(this.date, amount);

        return this;
    };

    addSeconds(amount) {
        this.date = addSeconds(this.date, amount)

        return this;
    };

    add(unit, amount) {
        const name = `add${capitalize(unit)}`;

        if (typeof this[name] !== 'function') {
            throw new Error('Unknown unit');
        }

        return this[name](amount);
    }
    //#endregion

    subMonths(amount) {
        this.date = subMonths(this.date, amount);

        return this;
    }

    //#region StartOf
    startOfDay() {
        this.date = startOfDay(this.date);

        return this;
    }

    startOfWeek() {
        this.date = startOfWeek(this.date);

        return this;
    };

    startOfMonth() {
        this.date = startOfMonth(this.date);

        return this;
    };

    startOfYear() {
        this.date = startOfYear(this.date);

        return this;
    }

    startOf(unit) {
        const name = `startOf${capitalize(unit)}`;

        if (typeof this[name] !== 'function') {
            throw new Error('Unknown unit');
        }

        return this[name]();

    }
    //#endregion

    // #region EndOf
    endOfYear() {
        this.date = endOfYear(this.date);

        return this;
    }

    endOfMonth() {
        this.date = endOfMonth(this.date);

        return this;
    }

    endOfWeek() {
        this.date = endOfWeek(this.date);

        return this;
    }

    endOfDay() {
        this.date = endOfDay(this.date);

        return this;
    }

    endOf(unit) {
        const name = `endOf${capitalize(unit)}`;

        if (typeof this[name] !== 'function') {
            throw new Error('Unknown unit');
        }

        return this[name]();
    }
    // #endregion

    // #region Differences
    diffMilliseconds(date) {
        if (!date) {
            return this.date.getTime();
        }

        const dateLib = DateLib.toDateLib(date);

        return differenceInMilliseconds(this.date, dateLib.getDate());
    }

    diffDays(date) {
        if (!date) {
            return this.date.getTime();
        }

        const dateLib = DateLib.toDateLib(date);

        return differenceInDays(this.date, dateLib.getDate());
    }

    diffMinutes(date) {
        if (!date) {
            return this.date.getTime();
        }

        const dateLib = DateLib.toDateLib(date);

        return differenceInMinutes(this.date, dateLib.getDate());
    }

    diff(date, unit = 'milliseconds') {
        const name = `diff${capitalize(unit)}`;

        if (typeof this[name] !== 'function') {
            throw new Error('Unknown unit');
        }

        return this[name](date);
    }

    // #endregion

    // #region Getters
    get dayOfWeek() {
        return getDay(this.date);
    }

    getHours() {
        return getHours(this.date);
    }
    // #endregion

    // #region Setters
    setYear(year) {
        this.date = setYear(this.date, year);

        return this;
    }

    setMonth(month) {
        this.date = setMonth(this.date, month);

        return this;
    }

    setDay(dayNumber) {
        this.date = setDay(this.date, dayNumber);

        return this;
    }

    setHours(hour) {
        this.date = setHours(this.date, hour);

        return this;
    }

    set(number, unit) {
        const name = `set${capitalize(unit)}`;

        if (typeof this[name] !== 'function') {
            throw new Error('Unknown unit');
        }

        return this[name](number);
    }
    // #endreion

    // #region Copmare
    isAfter(date, unit = '') {
        if (!date) {
            return false;
        }

        const dateLib = DateLib.toDateLib(date);

        if (unit) {
            const start = dateLib.clone().startOf(unit);
            const clone = this.clone().startOf(unit);

            return clone.isAfter(start);
        }

        return isAfter(this.date, dateLib.getDate());
    }

    isBefore(date, unit = '') {
        if (!date) {
            return false;
        }

        const dateLib = DateLib.toDateLib(date);

        if (unit) {
            const start = dateLib.clone().startOf(unit);
            const clone = this.clone().startOf(unit);

            return clone.isBefore(start);
        }

        return isBefore(this.date, dateLib.getDate());
    }

    isSameMonth(date) {
        if (!date) {
            return false;
        }

        const dateLib = DateLib.toDateLib(date);

        return isSameMonth(this.date, dateLib.getDate());
    }

    isSameDay(date) {
        if (!date) {
            return false;
        }

        const dateLib = DateLib.toDateLib(date);

        return isSameDay(this.date, dateLib.getDate());
    }

    isSame(date, unit = '') {
        if (!date) {
            return false;
        }

        if (!unit) {
            const dateLib = DateLib.toDateLib(date);

            return this.date.getTime() === dateLib.getDate().getTime();
        }

        const name = `isSame${capitalize(unit)}`;

        if (typeof this[name] !== 'function') {
            throw new Error('Unknown unit');
        }

        return this[name](date);
    }

    isBetween(date1, date2, unit, inclusivity = '()') {
        if (!['()', '[]', '(]', '[)'].includes(inclusivity)) {
            throw new Error('Inclusivity parameter must be one of (), [], (], [)');
        }

        if (!date1 || !date2) {
            return false;
        }

        const isLeftIncluded = inclusivity[0] === '[';
        const isRightIncluded = inclusivity[1] === ']';

        if (isLeftIncluded && this.isSame(date1, unit)) {
            return true;
        }

        if (isRightIncluded && this.isSame(date2, unit)) {
            return true;
        }

        return this.isAfter(date1, unit) && this.isBefore(date2, unit);
    }

    static max(date1, date2) {
        if (!date2) {
            return DateLib.toDateLib(date1);
        }

        if (!date1) {
            return DateLib.toDateLib(date2);
        }

        const dateLib1 = DateLib.toDateLib(date1);
        const dateLib2 = DateLib.toDateLib(date2);

        if (dateLib1.isSame(dateLib2)) {
            return dateLib1;
        }

        return dateLib1.isBefore(dateLib2) ? dateLib2 : dateLib1;
    }

    static min(date1, date2) {
        if (!date2) {
            return DateLib.toDateLib(date1);
        }

        if (!date1) {
            return DateLib.toDateLib(date2);
        }

        const dateLib1 = DateLib.toDateLib(date1);
        const dateLib2 = DateLib.toDateLib(date2);

        if (dateLib1.isSame(dateLib2)) {
            return dateLib1;
        }

        return dateLib1.isBefore(dateLib2) ? dateLib1 : dateLib2;
    }
    // #endregion

    // #region UTC
    toUTC() {
        this.date = addMinutes(this.date, this.date.getTimezoneOffset());

        return this;
    }
    // #endregion

    // #region UTC
    /** 
     * @docs https://github.com/marnusw/date-fns-tz#gettimezoneoffset
    */
    static getTimezoneOffset(tz = 'Etc/GMT') {
       return getTimezoneOffset(tz);
    }
    // #endregion
    
    // #region Validation
    isValid() {
        return !Number.isNaN(this.date.getTime());
    }
    // #endregion

    valueOf() {
        return this.date.valueOf();
    }

    getUnixTime() {
        return getUnixTime(this.date);
    }
};
