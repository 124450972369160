import * as leaderboard from '../actionTypes/Leaderboard/Leaderboard';
import Api from '../Api';
import { Config } from '../Config';
import { CommonConstants } from '../Constants';
import AuthenticationService from '../components/Security/AuthenticationService';

// This function returns get leaderboard success state
function getLeaderboardPreferenceSuccess(leaderBoardPreference) {
    return {
        type: leaderboard.GET_LEADERBOARDPREFERENCE,
        leaderBoardPreference: leaderBoardPreference
    };
}
// This function returns update leaderboard success state
function updateLeaderboardPreferenceSuccess(leaderBoardPreference) {
    return {
        type: leaderboard.UPDATE_LEADERBOARDPREFERENCE,
        leaderBoardPreference: leaderBoardPreference
    };
}
// This function returns get LBVersion  success state
function getCompanyLBVersionSuccess(lbVersion) {
    return {
        type: leaderboard.GET_COMPANYLBVERSION,
        companyLBVersion: lbVersion
    };
}
// This function returns get Account Subscription
function getAccountSubscriptionTypeSuccess(subsType) {
    return {
        type: leaderboard.GET_ACCOUNTSUBSCRIPTIONTYPE,
        accountSubscriptionType: subsType
    };
}
// This function using call get leaderboard api  and return the results
export const GetLeaderboardPreference = () => {
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.getLeaderboard;
    return (dispatch, getState) => {
        const api = new Api();
        api.getData(url, (data) => {
            const leaderboardPreferenceData = data;
            dispatch(getLeaderboardPreferenceSuccess(leaderboardPreferenceData));
        });
    };
};
// This function using call update leaderboard api  and return the results
export const UpdateLeaderboardPreference = (data, callback) => {
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.saveLeaderboard;
    const authService = new AuthenticationService(Config.authBaseUrl);

    const masqueradeUser = authService.getMasqueradeAdminUser();
    if (masqueradeUser) data.masqueradeUser = masqueradeUser;

    return (dispatch, getState) => {
        const api = new Api();
        api.postData(url, data, (result) => {
            if (result.status) {
                dispatch(updateLeaderboardPreferenceSuccess(data));
                callback(result);
            }
        });
    };
};
// This function using call get Company LBVersion api and return the results
export const getCompanyLBVersion = (callback) => {
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.getCompanyLBVersion;
    return (dispatch, getState) => {
        const api = new Api();
        api.getData(url, (data) => {
            const companyLBVersion = data.data;
            dispatch(getCompanyLBVersionSuccess(companyLBVersion));
            callback();
        });
    };
};
// This function using call get Account SubscriptionType api and return the results
export const getAccountSubscriptionType = (callback) => {
    const authService = new AuthenticationService(Config.authBaseUrl);
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.getAdminAcount + '?uuid=' + authService.getUUID();
    return (dispatch, getState) => {
        const api = new Api();
        api.getData(url, (data) => {
            const accountSubscriptionType = data.data[0].Account_Subscription_ID;
            dispatch(getAccountSubscriptionTypeSuccess(accountSubscriptionType));
            callback();
        });
    };
};
