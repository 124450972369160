import { curry, sort, compose, cond, toLower, prop, equals, type } from 'ramda';

const toLowerIfString = cond([[compose(equals('String'), type), toLower]]);

const emptyValues = ['', undefined, null];

// custom sorting functionality considering empty & nullable items
// source as an example: https://github.com/ramda/ramda/blob/v0.27.0/source/ascend.js
const ascendSort = curry(function (fn, a, b) {
    const aa = fn(a);
    const bb = fn(b);

    if (emptyValues.some(v => v === aa)) return 1;
    if (emptyValues.some(v => v === bb)) return -1;

    if (aa === bb) return 0;

    return aa < bb ? -1 : 1;
});

const descendSort = curry(function (fn, a, b) {
    const aa = fn(a);
    const bb = fn(b);

    if (emptyValues.some(v => v === aa)) return -1;
    if (emptyValues.some(v => v === bb)) return 1;
    if (aa === bb) return 0;

    return aa > bb ? -1 : 1;
});

// the helper almost duplicates the sortBySelection function from components/DeviceUpgrades/helpers
// but implements case insensitive sorting functionality and can be used for DeviceUpgrades in the future
export default ({ list = [], sortSelection = {}, changedSortedProperties = {}, comparators = {} }) => {
    let [colName = ''] = Object.keys(sortSelection) || [];
    const property = changedSortedProperties[colName] || colName;
    const sortType = String(sortSelection[colName]);

    if (comparators[property]) {
        return sort(comparators[property], list || []);
    }

    const sortFunctions = {
        1: ascendSort,
        '-1': descendSort,
    };

    if (!property || !sortFunctions[sortType]) {
        return list;
    }

    const sortByColumn = sortFunctions[sortType](compose(toLowerIfString, prop(property)));

    return sort(sortByColumn, list || []);
};
