import { getAccountStoresByUserUID, getUsers, getAccounts } from 'services/Account';
import { getPeripheralDevices, getPeripheralDevicesFiltered, getAllDevices } from 'services/Device';

export const searchAccountsByEmail = async ({ searchValue, setAccounts, setIsAccountsLoading, onDone }) => {
    let accounts = [];

    try {
        setIsAccountsLoading(true);
        accounts = await getUsers({
            searchValue,
            selectValue: 'User_EmailAddress',
        });

        setAccounts(accounts);
    } catch (e) {
        setAccounts([]);
    }

    setIsAccountsLoading(false);
    onDone && onDone(accounts);
};

export const loadAccounts = async ({ searchValue, setAccounts, setIsAccountsLoading, onDone }) => {
    let accounts = [];

    try {
        setIsAccountsLoading(true);
        accounts = await getAccounts({
            searchUserEmail: searchValue,
            limit: 10,
        });


        setAccounts(accounts);
    } catch (e) {
        setAccounts([]);
    }

    setIsAccountsLoading(false);
    onDone && onDone(accounts);

};

export const loadAccountPeripheralDevices = async ({
    storeAccountId,
    deviceTypeId,
    setIsAccountStoresLoading,
    setAccountStores,
    filters,
    filterDevices,
}) => {
    try {
        setIsAccountStoresLoading(true);
        const devices = await getPeripheralDevicesFiltered({ storeAccountId, hideScheduledDevices: 1, deviceTypeId, });
        const filteredDevices = filterDevices(devices, filters);
        setAccountStores(filteredDevices);
    } catch (e) {
        setAccountStores([]);
    }

    setIsAccountStoresLoading(false);
};

export const loadAccountDevices = async ({
    storeAccountId,
    deviceTypeId,
    setIsAccountStoresLoading,
    setAccountStores,
    filters,
    filterDevices,
}) => {
    try {
        setIsAccountStoresLoading(true);
        const devices = await getAllDevices({ storeAccountId, hideScheduledDevices: 1, deviceTypeId, });
        const filteredDevices = filterDevices(devices, filters);
        setAccountStores(filteredDevices);
    } catch (e) {
        setAccountStores([]);
    }

    setIsAccountStoresLoading(false);
};

export const loadAccountStores = async ({
    userUID,
    selectedDeviceType,
    setIsAccountStoresLoading,
    setAccountStores,
}) => {
    try {
        setIsAccountStoresLoading(true);

        const stores = await getAccountStoresByUserUID(userUID, selectedDeviceType);
        setAccountStores(stores);
    } catch (e) {
        setAccountStores([]);
    }

    setIsAccountStoresLoading(false);
};
