const host = window.location.hostname;

const settings = {
    tokenPath: 'api/auth-service',
    storageUrl: 'https://www.hme.com/qsr/cloud/link',
    hmeURL: 'https://www.hme.com',
    idleTimeBeforeAutologout: 1740000, // 29min in milliseconds
    autologoutModalWaitTime: 60000, // 60sec in milliseconds
    autoRefreshTime: 300000,
    tokenRefresh: 1800, // 30 minutes in seconds
    coldFusionPages: [],
    ga4Key: '',
    /*
        As per Timer team we should not upgrade devices with software version below 3.6.0 to an upgrade version above 3.6.0.
        To be able to upgarde such devices to latest software versions, first upgarde them to 3.6.0 using individual upgarde
        feature of admin portal.
        minimumBulkupgradeVersion parameter is used to restrict such upgrades in bulk upgarde section.
    */
    minimumBulkupgradableVersion: '3.6.0',
    infiniteScrollPageSize: 500,
    contestNACutoffDate: '2023-03-03',
    clientID: '9fd0bbec-e275-4bf6-9220-d1eff2b4c963',
    authority: 'https://login.microsoftonline.com/hme.com'
};

if (host.indexOf('dev1-') > -1 || host.indexOf('engdev.hmedtcloud') > -1) { // DEV Environment
    settings.adminColdFusionUrl = 'https://dev1-portal.hmecloud.com/admin/';
    settings.coldFusionUrl = 'https://dev1-portal.hmecloud.com/';
    settings.apiBaseUrl = 'https://dev1-service.hmecloud.com/';
    settings.authBaseUrl = 'https://dev-auth.hmecloud.com/';
    settings.trainingPortalBaseUrl = 'https://uathme.hme.com/training';
    settings.trainingPortalBaseDocURL = 'https://www.hme.com/training';
    settings.leaderboard3XBaseUrl = 'https://hme-dev-leaderboard.hmecloud.com/?all&storeUID=';
    settings.leaderboard4XBaseUrl = 'https://hme-dev-nitro-leaderboard.hmecloud.com/?allstoreview=true&storeUID=';
    settings.blobNitroStorageBaseUrl = 'https://hme-dev-nitro-cdn.hmecloud.com';
    settings.nitroContestServiceBaseUrl = 'https://hme-dev-nitro-contest-service.azurewebsites.net';
} else if (host.indexOf('dev2-') > -1) { // DEV 2 Environment
    settings.adminColdFusionUrl = 'http://devcloudadmin.hme.com/';
    settings.coldFusionUrl = 'https://engdev.hmedtcloud.com/';
    settings.apiBaseUrl = 'https://dev-epic-cloud-portal-service.azurewebsites.net/';
    settings.authBaseUrl = 'https://dev-auth.hmecloud.com/';
    settings.trainingPortalBaseUrl = 'https://uathme.hme.com/training';
    settings.trainingPortalBaseDocURL = 'https://www.hme.com/training';
    settings.leaderboard3XBaseUrl = 'https://hme-dev-leaderboard.hmecloud.com/?all&storeUID=';
    settings.leaderboard4XBaseUrl = 'https://hme-dev-nitro-leaderboard.hmecloud.com/?allstoreview=true&storeUID=';
    settings.blobNitroStorageBaseUrl = 'https://hme-dev-nitro-cdn.hmecloud.com';
    settings.nitroContestServiceBaseUrl = 'https://hme-dev-nitro-contest-service.azurewebsites.net';
} else if (host.indexOf('dev3-') > -1) { // DEV 3 Environment
    settings.adminColdFusionUrl = 'https://dev3-portal.hmecloud.com/admin/';
    settings.coldFusionUrl = 'https://dev3-portal.hmecloud.com/';
    settings.apiBaseUrl = 'https://dev-portal-service.hmecloud.com/';
    settings.authBaseUrl = 'https://dev-auth.hmecloud.com/';
    settings.trainingPortalBaseUrl = 'https://uathme.hme.com/training';
    settings.trainingPortalBaseDocURL = 'https://www.hme.com/training';
    settings.leaderboard3XBaseUrl = 'https://hme-dev-leaderboard.hmecloud.com/?all&storeUID=';
    settings.leaderboard4XBaseUrl = 'https://hme-dev-nitro-leaderboard.hmecloud.com/?allstoreview=true&storeUID=';
    settings.blobNitroStorageBaseUrl = 'https://hme-dev-nitro-cdn.hmecloud.com';
    settings.nitroContestServiceBaseUrl = 'https://hme-dev-nitro-contest-service.azurewebsites.net';
} else if (host.indexOf('uat1-') > -1 || host.indexOf('uat.hmedtcloud') > -1 || host.indexOf('stg-portal.') > -1) { // UAT environment
    settings.adminColdFusionUrl = 'https://uatadmin.hme.com/hmecloud/';
    settings.coldFusionUrl = 'https://uat.hmedtcloud.com/';
    settings.apiBaseUrl = 'https://stg-portal-service.hmecloud.com/';
    settings.authBaseUrl = 'https://uat-auth.hmecloud.com/';
    settings.trainingPortalBaseUrl = 'https://www.hme.com/training';
    settings.trainingPortalBaseDocURL = 'https://www.hme.com/training';
    settings.leaderboard3XBaseUrl = 'https://hme-uat2-leaderboard.hmecloud.com/?all&storeUID=';
    settings.leaderboard4XBaseUrl = 'https://hme-uat-nitro-leaderboard.hmecloud.com/?allstoreview=true&storeUID=';
    settings.nitroContestServiceBaseUrl = 'https://hme-uat-nitro-contest-service.hmecloud.com';
    settings.blobNitroStorageBaseUrl = 'https://hme-uat-nitro-cdn.hmecloud.com';
} else if (host.indexOf('uat2-') > -1) { // UAT environment
    settings.adminColdFusionUrl = 'https://uatadmin.hme.com/hmecloud/';
    settings.coldFusionUrl = 'https://uat.hmedtcloud.com/';
    settings.apiBaseUrl = 'https://uat-epic-cloud-portal-service.azurewebsites.net/';
    settings.authBaseUrl = 'https://uat-auth.hmecloud.com/';
    settings.trainingPortalBaseUrl = 'https://uathme.hme.com/training';
    settings.trainingPortalBaseDocURL = 'https://www.hme.com/training';
    settings.leaderboard3XBaseUrl = 'https://hme-uat2-leaderboard.hmecloud.com/?all&storeUID=';
    settings.leaderboard4XBaseUrl = 'https://hme-uat-nitro-leaderboard.hmecloud.com/?allstoreview=true&storeUID=';
    settings.nitroContestServiceBaseUrl = 'https://hme-uat-nitro-contest-service.hmecloud.com';
    settings.blobNitroStorageBaseUrl = 'https://hme-uat-nitro-cdn.hmecloud.com';
} else if (host.indexOf('dev-cloud-iotintegration') > -1) {
    settings.adminColdFusionUrl = 'http://devcloudadmin.hme.com/';
    settings.coldFusionUrl = 'https://engdev.hmedtcloud.com/';
    settings.apiBaseUrl = 'https://dev-iotintegration-cloud-portal-service.azurewebsites.net/';
    settings.authBaseUrl = 'https://dev-auth.hmecloud.com/';
    settings.trainingPortalBaseUrl = 'https://uathme.hme.com/training';
    settings.trainingPortalBaseDocURL = 'https://www.hme.com/training';
    settings.leaderboard3XBaseUrl = 'https://hme-dev-leaderboard.hmecloud.com/?all&storeUID=';
    settings.leaderboard4XBaseUrl = 'https://hme-dev-nitro-leaderboard.hmecloud.com/?allstoreview=true&storeUID=';
    settings.blobNitroStorageBaseUrl = 'https://hme-dev-nitro-cdn.hmecloud.com';
    settings.nitroContestServiceBaseUrl = 'https://hme-dev-nitro-contest-service.azurewebsites.net';
} else if (host.indexOf('uat-cloud-iotintegration') > -1) {
    settings.adminColdFusionUrl = 'https://uatadmin.hme.com/hmecloud/';
    settings.coldFusionUrl = 'https://uat.hmedtcloud.com/';
    settings.apiBaseUrl = 'https://uat-iotintegration-cloud-portal-service.azurewebsites.net/';
    settings.authBaseUrl = 'https://uat-auth.hmecloud.com/';
    settings.trainingPortalBaseUrl = 'https://uathme.hme.com/training';
    settings.trainingPortalBaseDocURL = 'https://www.hme.com/training';
    settings.leaderboard3XBaseUrl = 'https://hme-uat2-leaderboard.hmecloud.com/?all&storeUID=';
    settings.leaderboard4XBaseUrl = 'https://hme-uat-nitro-leaderboard.hmecloud.com/?allstoreview=true&storeUID=';
    settings.nitroContestServiceBaseUrl = 'https://hme-uat-nitro-contest-service.hmecloud.com';
    settings.blobNitroStorageBaseUrl = 'https://hme-uat-nitro-cdn.hmecloud.com';
    settings.ga4Key = 'G-57L2N36ZKX';
} else if (host.indexOf('uat3-') > -1 || host.indexOf('uat-portal.') > -1 || host.indexOf('uat-cloud-portal') > -1) { // UAT environment
    settings.adminColdFusionUrl = 'https://uatadmin.hme.com/hmecloud/';
    settings.coldFusionUrl = 'https://uat.hmedtcloud.com/';
    settings.apiBaseUrl = 'https://uat-portal-service.hmecloud.com/';
    settings.authBaseUrl = 'https://uat-auth.hmecloud.com/';
    settings.trainingPortalBaseUrl = 'https://uathme.hme.com/training';
    settings.trainingPortalBaseDocURL = 'https://www.hme.com/training';
    settings.leaderboard3XBaseUrl = 'https://hme-uat2-leaderboard.hmecloud.com/?all&storeUID=';
    settings.leaderboard4XBaseUrl = 'https://hme-uat-nitro-leaderboard.hmecloud.com/?allstoreview=true&storeUID=';
    settings.nitroContestServiceBaseUrl = 'https://hme-uat-nitro-contest-service.hmecloud.com';
    settings.blobNitroStorageBaseUrl = 'https://hme-uat-nitro-cdn.hmecloud.com';
    settings.ga4Key = 'G-57L2N36ZKX';
} else if (host.indexOf('azuat-') > -1) { // UAT environment
    settings.adminColdFusionUrl = 'https://uatadmin.hme.com/hmecloud/';
    settings.coldFusionUrl = 'https://uat.hmedtcloud.com/';
    settings.apiBaseUrl = 'https://uat-portal-service.hmecloud.com/';
    settings.authBaseUrl = 'https://uat-auth.hmecloud.com/';
    settings.trainingPortalBaseUrl = 'https://uathme.hme.com/training';
    settings.trainingPortalBaseDocURL = 'https://www.hme.com/training';
    settings.leaderboard3XBaseUrl = 'https://azuat-leaderboard.hmecloud.com/?all&storeUID=';
    settings.leaderboard4XBaseUrl = 'https://azuat-nitro-leaderboard.hmecloud.com/?allstoreview=true&storeUID=';
    settings.nitroContestServiceBaseUrl = 'https://azuat-nitro-contest-service.hmecloud.com';
    settings.blobNitroStorageBaseUrl = 'https://hme-uat-nitro-cdn.hmecloud.com';
} else if (host.indexOf('-datawarehouse') > -1) { // UAT environment
    settings.apiBaseUrl = 'https://uat-dw-cloud-portal-service.azurewebsites.net/';
    settings.authBaseUrl = 'https://uat-auth.hmecloud.com/';
    settings.trainingPortalBaseUrl = 'https://uathme.hme.com/training';
    settings.trainingPortalBaseDocURL = 'https://www.hme.com/training';
    settings.leaderboard3XBaseUrl = 'https://azuat-leaderboard.hmecloud.com/?all&storeUID=';
    settings.leaderboard4XBaseUrl = 'https://azuat-nitro-leaderboard.hmecloud.com/?allstoreview=true&storeUID=';
    settings.nitroContestServiceBaseUrl = 'https://azuat-nitro-contest-service.hmecloud.com';
    settings.blobNitroStorageBaseUrl = 'https://hme-uat-nitro-cdn.hmecloud.com';
    settings.ga4Key = 'G-0YZ8PNF7JX';
} else if (host.indexOf('-live-') > -1 || host.indexOf('hmedtcloud') > -1 || host.indexOf('hmecloud') > -1 || host.indexOf('cloud-portal-app') > -1) { // LIVE Environment
    settings.adminColdFusionUrl = 'https://admin.hme.com/hmecloud/';
    settings.coldFusionUrl = 'https://hmedtcloud.com/';
    settings.apiBaseUrl = 'https://portal-service.hmecloud.com/';
    settings.authBaseUrl = 'https://auth.hmecloud.com/';
    settings.adminColdFusionUrl = 'https://admin.hme.com/hmecloud/';
    settings.trainingPortalBaseUrl = 'https://www.hme.com/training';
    settings.trainingPortalBaseDocURL = 'https://www.hme.com/training';
    settings.leaderboard3XBaseUrl = 'https://leaderboard.hmecloud.com/?all&storeUID=';
    settings.leaderboard4XBaseUrl = 'https://hme-live-nitro-leaderboard.hmecloud.com/?allstoreview=true&storeUID=';
    settings.blobNitroStorageBaseUrl = 'https://hme-live-nitro-cdn.hmecloud.com';
    settings.nitroContestServiceBaseUrl = 'https://hme-nitro-contest-service.hmecloud.com';
    settings.ga4Key = 'G-2Y0X71WPR1';
} else if (host.indexOf('dev-cloud-nitro-iot.azurewebsites.net') > -1) {
    settings.adminColdFusionUrl = 'http://devcloudadmin.hme.com/';
    settings.coldFusionUrl = 'https://engdev.hmedtcloud.com/';
    settings.apiBaseUrl = 'https://dev-cloud-portal-service-nitro-iot.azurewebsites.net/';
    settings.authBaseUrl = 'https://dev-auth.hmecloud.com/';
    settings.trainingPortalBaseUrl = 'https://uathme.hme.com/training';
    settings.trainingPortalBaseDocURL = 'https://www.hme.com/training';
    settings.leaderboard3XBaseUrl = 'https://hme-dev-leaderboard.hmecloud.com/?all&storeUID=';
    settings.leaderboard4XBaseUrl = 'https://hme-dev-nitro-leaderboard.hmecloud.com/?allstoreview=true&storeUID=';
    settings.blobNitroStorageBaseUrl = 'https://hme-dev-nitro-cdn.hmecloud.com';
    settings.nitroContestServiceBaseUrl = 'https://hme-dev-nitro-contest-service.azurewebsites.net';
} else if (host.indexOf('uat-cloud-nitro-iot.azurewebsites.net') > -1) {
    settings.adminColdFusionUrl = 'http://devcloudadmin.hme.com/';
    settings.coldFusionUrl = 'https://engdev.hmedtcloud.com/';
    settings.apiBaseUrl = 'https://uat-cloud-portal-service-nitro-iot.azurewebsites.net/';
    settings.authBaseUrl = 'https://uat-auth.hmecloud.com/';
    settings.trainingPortalBaseUrl = 'https://uathme.hme.com/training';
    settings.trainingPortalBaseDocURL = 'https://www.hme.com/training';
    settings.leaderboard3XBaseUrl = 'https://hme-uat2-leaderboard.hmecloud.com/?all&storeUID=';
    settings.leaderboard4XBaseUrl = 'https://hme-uat-nitro-leaderboard.hmecloud.com/?allstoreview=true&storeUID=';
    settings.blobNitroStorageBaseUrl = 'https://hme-uat-nitro-cdn.hmecloud.com';
    settings.nitroContestServiceBaseUrl = 'https://hme-uat-nitro-contest-service.hmecloud.com';
} else { // LOCALHOST
    settings.adminColdFusionUrl = 'http://devcloudadmin.hme.com/';
    settings.coldFusionUrl = 'https://engdev.hmedtcloud.com/';
    settings.apiBaseUrl = 'http://localhost:7071/';
    settings.authBaseUrl = 'https://dev-auth.hmecloud.com/';
    settings.trainingPortalBaseUrl = 'https://uathme.hme.com/training';
    settings.trainingPortalBaseDocURL = 'https:/www.hme.com/training';
    settings.leaderboard3XBaseUrl = 'https://hme-dev-leaderboard.hmecloud.com/?all&storeUID=';
    settings.leaderboard4XBaseUrl = 'https://hme-dev-nitro-leaderboard.hmecloud.com/?allstoreview=true&storeUID=';
    settings.blobNitroStorageBaseUrl = 'https://hme-uat-nitro-cdn.hmecloud.com';
    settings.nitroContestServiceBaseUrl = 'https://hme-uat-nitro-contest-service.hmecloud.com';
}

export const Config = settings;
