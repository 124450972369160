import React from 'react';
import PropTypes from 'prop-types';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import { shallowCloneArrayOfObjects } from '../../../helpers/clone';
import './CoffeeTable.css';

// Sort-types available for 'tableHeader' property's 'sortType' property
const SORT_STRING = 'SORT_STRING';
const SORT_NUMBER = 'SORT_NUMBER';
const SORT_DATE = 'SORT_DATE';
const SORT_VERSION = 'SORT_VERSION';
const SORT_DEFAULT = SORT_STRING;

const SORT_ORDER_DEFAULT = true; // Ascending

const applyRawFieldsSorting = (sortColumn) => (sortColumn === 'CreatedDate' ? 'CreatedDateRaw' : sortColumn);

/**
 * Highly caffeinated, :) customizable, data-driven table Component featuring sortable columns.
 * Optionally, the designated column can be radio button inputs.
 * Optionally, the table can be initially sorted according to given parameters.
 *
 * Properties:
 *   tableHeaders: Array of objects defining the table's columns. Each object defines a column's properties:
 *                   - columnId: The ID of a header column. Used for radio button and table header column 'onClick' sorting handler.
 *                   - label: The string displayed in a header column.
 *                   - sortType: [Optional] One of the sort-type constants. If undefined, uses SORT_DEFAULT if sorting enabled.
 *   tableRows: Array of objects where each object represents a row of column values, where the keys match the 'label' values in tableHeaders.
 *   sortable: [Optional] If given, enables table sorting.
 *                   - presort: [Optional] If given, enables the table to be initially sorted by the given columnId.
 *                         - columnId: Initially sort the table by this header column ID.
 *                         - ascending: [Optional] Defines whether the presort is ascending (true) or descending (false).
 *   radioButton: [Optional] object if passed causes the designated column to be a radio button input based on these props:
 *                   - replaceColumnId: The columnId of the column whose table cell is replaced with an input radio button.
 *                   - selectionHandler: The parent's handler for the radio button 'onClick' event.
 *                   - selectionId: Pre-select a radio button by setting to a value previously handled from a radio button 'onClick' event.
 *                                  May be null (for no selection).
 *
 * @example <caption>EXAMPLE USAGE</caption>
 *     <CoffeeTable
 *         tableHeaders={tableHeaders}
 *         tableRows={settings}
 *         sortable
 *          OR
 *         sortable={{presort: {columnId: 'SettingName', ascending: true}}}
 *         radioButton={{
 *             replaceColumnId: 'SettingTemplateID',
 *             selectionHandler: this.handleInputRadio,
 *             selectionId: selectedOption
 *         }}
 *     />
 *
 *     const tableHeaders = [
 *       {columnId: 'SettingTemplateID', label: '', sortType: undefined}, // NOTE: This column to be designated for selection radio buttons
 *       {columnId: 'SettingName', label: 'Name', sortType: SORT_STRING},
 *       {columnId: 'Description', label: 'Description', sortType: SORT_STRING},
 *       {columnId: 'SourceSoftwareVersion', label: 'Software Version', sortType: SORT_VERSION},
 *     ];
 *
 * @return {Object}
 */
class CoffeeTable extends React.Component {
    /**
     * @param {*} props
     */
    constructor(props) {
        super(props);
        this.state = {
            tableRows: []
        };
    }

    /**
     * Life-cycle method to return state variables based on the next property object
     * @param {Object} nextProps
     * @param {Object} prevState
     * @return {Object}
     */
    /* eslint-disable-next-line consistent-return, require-jsdoc */
    static getDerivedStateFromProps(nextProps, prevState) {
        const { tableHeaders, tableRows, sortable } = nextProps;

        // If no row data provided yet, then do nothing
        // When rows are empty array we should clean rows in the state
        if (!Array.isArray(tableRows)) return null;

        if (sortable) {
            const { presort } = sortable;
            const { sortColumnId, sortAscending } = prevState;

            if (sortColumnId) {
                const appliedRawSortColumnId = applyRawFieldsSorting(sortColumnId);
                // Sort the table
                const sortedData = CoffeeTable.sortRows(tableHeaders, tableRows, appliedRawSortColumnId, sortAscending);
                return { tableRows: sortedData };
            } else if (presort && presort.columnId) {
                // With pre-sort value(s) provided, initialize state so the table will be initially sorted
                const ascending = 'ascending' in presort ? presort.ascending : SORT_ORDER_DEFAULT;
                return { sortColumnId: presort.columnId, sortAscending: ascending, tableRows: tableRows };
            }
        } else {
            // No sorting, so just initialize state with un-sorted table row data (and cause table to render)
            return { tableRows: tableRows };
        }
    }

    /**
     * @return {*} JSX
     */
    render() {
        const { tableRows } = this.state;
        return (
            <Table className='coffee-table'>
                <Thead>
                    <Tr className='theader clear'>
                        {this.renderHeader()}
                    </Tr>
                </Thead>
                <Tbody>
                    {tableRows.length > 0 && this.renderBody()}
                </Tbody>
            </Table>
        );
    }


    //
    // NOTE: Arrow functions must appear below, which is AFTER React Life Cycle functions:
    //

    /**
     * Render all table header columns.
     * @return {*} JSX
     */
    renderHeader = () => {
        const { tableHeaders, sortable, radioButton } = this.props;
        return tableHeaders.map((col, i) => {
            if (radioButton && col.columnId === radioButton.replaceColumnId || col.action) {
                return (
                    <Th key={i} className='coffee-table-radio-btn'>
                        <span />
                    </Th>
                );
            } else if (sortable) {
                return (
                    <Th key={i} className={this.getArrowClassName(col.columnId)}>
                        <a><span id={col.columnId} onClick={this.sortTable}>{col.label}</span></a>
                    </Th>
                );
            } else {
                return <Th key={i}>{col.label}</Th>;
            }
        });
    }

    renderCell(rowData, col, key) {
        const { radioButton, actions } = this.props;

        if (radioButton && col.columnId === radioButton.replaceColumnId) {
            const radioButtonId = rowData[radioButton.replaceColumnId];
            return (
                <Td key={key} className='coffee-table-radio-btn'>
                    <input
                        type='radio'
                        value={`radio_${radioButtonId}`}
                        onChange={() => radioButton.selectionHandler(radioButtonId)}
                        checked={`radio_${radioButton.selectionId}` === `radio_${radioButtonId}`} />
                </Td>
            );
        }

        if (col.action && typeof actions[col.action] === 'function') {
            return <Td key={key} className={`td-action-${col.action}`}>{actions[col.action](rowData)}</Td>
        }

        return (
            <Td key={key}><span>{rowData[col.columnId]}</span></Td>
        );
    }

    /**
     * Render all table rows.
     * @return {*} JSX
     */
    renderBody = () => {
        const { tableRows } = this.state;
        const { tableHeaders } = this.props;

        return tableRows.map((rowData, i) => {
            return (
                <Tr key={i} className='tdata clear'>
                    {tableHeaders.map((col, j) => {
                        return this.renderCell(rowData, col, j);
                    })}
                </Tr>
            );
        });
    };

    /**
     * Table header on-click handler for sorting table.
     * @param {*} e
     */
    sortTable = (e) => {
        const nextSortColumnId = e.target.id;
        const { sortColumnId, sortAscending } = this.state;

        // If same column then toggle sort-order, else switched to different column so reset sort-order to default (ASCENDING)
        const isAscending = nextSortColumnId === sortColumnId ? !sortAscending : SORT_ORDER_DEFAULT;

        // Set state so table will be sorted by this column and sort-order
        this.setState({ sortColumnId: nextSortColumnId, sortAscending: isAscending });
    }

    /**
     * Sort the row-data array by the given sort-column, sort-type, and sort-order.
     * @param {*} tableHeaders
     * @param {*} tableRows
     * @param {*} sortColumnId
     * @param {boolean} sortAscending
     * @return {Array}
     */
    static sortRows = (tableHeaders, tableRows, sortColumnId, sortAscending = true) => {
        // Determine sortType from tableHeaders prop
        const col = tableHeaders.filter((x) => x.columnId === sortColumnId);
        const sortType = col && col[0] && col[0].sortType ? col[0].sortType : SORT_DEFAULT;

        const clonedData = shallowCloneArrayOfObjects(tableRows);
        const sortedData = clonedData.sort((a, b) => {
            let compare;
            switch (sortType) {
                case SORT_DATE: compare = CoffeeTable.compareDates; break;
                case SORT_VERSION: compare = CoffeeTable.compareVersions; break;
                case SORT_NUMBER: compare = CoffeeTable.compareNumbers; break;
                case SORT_STRING:
                default:
                    compare = CoffeeTable.compareStrings;
                    break;
            }
            return compare(a[sortColumnId], b[sortColumnId], sortAscending);
        });

        // Return sorted table rows
        return sortedData;
    }

    /**
     * Array sort comparison function - for sorting strings alphanumerically
     * @param {Object} s1
     * @param {Object} s2
     * @param {boolean} ascending
     * @return {number}
     */
    static compareStrings = (s1, s2, ascending = true) => {
        const a = s1 ? s1.toLowerCase() : '';
        const b = s2 ? s2.toLowerCase() : '';
        let x = a.localeCompare(b);
        if (!ascending) x *= -1; // If descending, then reverse result
        return x;
    }

    /**
     * Array sort comparison function - for sorting software versions numerically.
     * Reference: "Use the sort compare callback function"
     * https://stackoverflow.com/questions/40201533/sort-version-dotted-number-strings-in-javascript/40201629
     * @param {Object} v1
     * @param {Object} v2
     * @param {boolean} ascending
     * @return {number}
     */
    static compareVersions = (v1, v2, ascending = true) => {
        const a = v1 ? v1.toLowerCase() : '';
        const b = v2 ? v2.toLowerCase() : '';
        let res = a.localeCompare(b, { numeric: true });
        if (!ascending) res *= -1; // If descending, then reverse result
        return res;
    }

    /**
     * Array sort comparison function - for sorting numbers (numerically)
     * @param {Object} n1
     * @param {Object} n2
     * @param {boolean} ascending
     * @return {number}
     */
    static compareNumbers = (n1, n2, ascending = true) => {
        const a = Number(n1); // NOTE: Use Number() to convert to number if necessary
        const b = Number(n2);
        return ascending ? a - b : b - a;
    }

    /**
     * Array sort comparison function - for sorting dates.
     * @param {Object} d1
     * @param {Object} d2
     * @param {boolean} ascending
     * @return {number}
     */
    static compareDates = (d1, d2, ascending = true) => {
        const a = d1 instanceof Date ? d1 : new Date(d1);
        const b = d2 instanceof Date ? d2 : new Date(d2);
        return ascending ? a - b : b - a;
    }

    /**
     * @param {*} columnId
     * @return {string}
     */
    getArrowClassName = (columnId) => {
        const { sortColumnId, sortAscending } = this.state;
        if (sortColumnId === columnId) {
            return sortAscending ? 'actcol' : 'actcold';
        } else {
            return '';
        }
    }
}

CoffeeTable.propTypes = {
    tableHeaders: PropTypes.array.isRequired,
    tableRows: PropTypes.array.isRequired,
    sortable: PropTypes.object,
    radioButton: PropTypes.object
};

CoffeeTable.defaultProps = {
    sortable: null,
    radioButton: null
};

export {
    CoffeeTable,
    SORT_STRING,
    SORT_NUMBER,
    SORT_DATE,
    SORT_VERSION
};
