import { Constant } from '@hme-cloud/utility-common';
const { BuildVersions, DeviceType } = Constant;

const MIN_ZOOM_VERSION = '3.9999.9999';
const MIN_AVAILABLE_NEXEO_VERSION = '1.5.9999';
const ZOOM_INTERMEDIATE_VERSION = '3.6.11';
export const TARGET_VERSION_SELECT_TYPE = {
    SINGLE: 'SINGLE',
    MULTIPLE: 'MULTIPLE',
};

export const filterZOOMUpgradeVersionsOptions = ({ targetDeviceVersion, upgradeVersions }) => {
    // ZOOM devices should have only versions that are less than the intermediate version
    if (BuildVersions.semverLt(targetDeviceVersion, ZOOM_INTERMEDIATE_VERSION)) {
        return [{ text: ZOOM_INTERMEDIATE_VERSION, value: ZOOM_INTERMEDIATE_VERSION }];
    }

    return upgradeVersions;
};

const formatDeviceVersions = (versions) => {
    return [...new Set(versions.map(({ Version }) => (Version)))]
        .sort((a, b) => BuildVersions.semverGt(a, b) ? 1 : -1);
};
const formatUpgradeVersions = (versions) => {
    return [...new Set(versions
        .map(({ Version, UpgradeType }) => ({ version: Version, upgradeType: UpgradeType})))]
        .sort((a, b) => BuildVersions.semverGt(a.version, b.version) ? 1 : -1);
};

export const getDeviceTypeVersions = ({
    targetDeviceType,
    targetPeripheralDeviceType,
    allDeviceVersions,
    allUpgradeVersions
}) => {
    const deviceType = DeviceType.getAll().find((type) => type['camelCase'] === targetDeviceType);

    if (!deviceType) {
        return {
            target: [],
            upgrade: [],
            targetSelectType: TARGET_VERSION_SELECT_TYPE.SINGLE,
        };
    }

    if (targetDeviceType === DeviceType.ZOOM.camelCase) {
        return {
            target: formatDeviceVersions(allDeviceVersions
                .filter(version => version.DeviceTypeID === DeviceType.ZOOM.id
                    && BuildVersions.semverCompare(version.Version, MIN_ZOOM_VERSION, 'lte'))),
            upgrade: formatUpgradeVersions(allUpgradeVersions
                .filter(version => version.ID === DeviceType.ZOOM.id
                    && BuildVersions.semverCompare(version.Version, MIN_ZOOM_VERSION, 'lte'))),
            targetSelectType: TARGET_VERSION_SELECT_TYPE.SINGLE,
        };
    }

    if (targetDeviceType === DeviceType.ZOOMNITRO.camelCase) {
        return {
            target: formatDeviceVersions(allDeviceVersions
                .filter(version => version.DeviceTypeID === DeviceType.ZOOMNITRO.id
                    && BuildVersions.semverCompare(version.Version, BuildVersions.MIN_NITRO_DEVICE_VERSION, 'gt'))),
            upgrade: formatUpgradeVersions(allUpgradeVersions
                .filter(version => version.ID === DeviceType.ZOOM.id
                    && BuildVersions.semverCompare(version.Version, MIN_ZOOM_VERSION, 'gt'))),
            targetSelectType: TARGET_VERSION_SELECT_TYPE.SINGLE,
        };
    }

    if (targetDeviceType === DeviceType.NEXEO.camelCase && targetPeripheralDeviceType === DeviceType.IB7000.type) {
        return {
            target: formatDeviceVersions(allDeviceVersions
                .filter(version => version.DeviceTypeID === DeviceType.IB7000.typeId)),
            upgrade: formatUpgradeVersions(allUpgradeVersions
                .filter(version => version.ID === DeviceType.IB7000.typeId)),
            targetSelectType: TARGET_VERSION_SELECT_TYPE.MULTIPLE,
        };
    }

    if (targetDeviceType === DeviceType.NEXEO.camelCase) {
        return {
            target: formatDeviceVersions(allDeviceVersions
                .filter(version => version.DeviceTypeID === DeviceType.NEXEO.typeId
                    && BuildVersions.semverCompare(version.Version, MIN_AVAILABLE_NEXEO_VERSION, 'gt'))),
            upgrade: formatUpgradeVersions(allUpgradeVersions
                .filter(version => version.ID === DeviceType.NEXEO.typeId)),
            targetSelectType: TARGET_VERSION_SELECT_TYPE.MULTIPLE,
        };
    }

    return [];
};