import React, { useState, useEffect } from 'react';
import { invertObj } from 'ramda';

import { Section } from 'library/Section';
import { InfoField } from 'library/InfoField';
import { taskConfig } from 'constants/applyStoreSettingsTask';
import { DateLib } from '@hme-cloud/utility-common';

import { SearchParams } from './SearchParams'

import './InfoSection.scss';

const statuses = invertObj(taskConfig.statuses);
const statusTexts = {
    WAITING_FOT_UPLOAD: 'Waiting for Upload',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    FAILED: 'Completed with Failures',
    CANCELED: 'Canceled',
};

export const InfoSection = ({ task, brands, countries }) => {
    const [createdTime, setCreatedTime] = useState('');

    useEffect(() => {
        setCreatedTime(new DateLib(task.CreatedAt).format(DateLib.FORMAT_TYPES.SHORT_DATE_TIME));
    }, [task.CreatedAt, setCreatedTime]);

    return (
        <Section number={1} className='hme-view-store-settings-task-info-block'>
            <InfoField label='Status' className='hme-view-store-settings-task-info-block-status'>
                {statusTexts[statuses[task.Task_Status]]}
            </InfoField>
            <InfoField label='Created By' className='hme-view-store-settings-task-info-block-creted'>
                <span className='hme-view-store-settings-task-info-block-creted-by'>{task.CreatedBy_Email}</span>
                <span className='hme-view-store-settings-task-info-block-creted-date'>{createdTime}</span>
            </InfoField>
            <InfoField label='Task Name'>
                {task.Task_Name}
            </InfoField>
            <SearchParams task={task} brands={brands} countries={countries}/>
        </Section>
    );
};
