import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'library/Button';
import { Sorting } from './Sorting';
import { Filters } from './Filters';

import { availableFiltersType } from '../types/availableFiltersType';
import { useSortFilterModal } from '../hooks/useSortFilterModal';
import { sortOptionsType } from '../types/sortOptionsType';

import './SortFilterModal.scss';

export const SortFilterModal = ({
    isSortModalOpen,
    onSortModalClose,
    availableFilters,
    filters,
    onFiltersChange,
    filtersOptions,
    sortSelection,
    onSortChange,
    sortOptions,
    isSortingDropdown,
    onFiltersAndSortChange,
    onFiltersAndSortReset
}) => {
    const { t } = useTranslation();
    const {
        selectedFilters,
        onFilterSelect,
        onFiltersApply,
        needToShowSorting,
        needToShowFilters,
        onModalClose,
        selectedSortValue,
        onSortValueChange,
        isApplyButtonDisabled
    } = useSortFilterModal(
            availableFilters,
            filters,
            onFiltersChange,
            onSortModalClose,
            sortSelection,
            sortOptions,
            onSortChange,
            onFiltersAndSortChange,
            onFiltersAndSortReset
    );

    return <Modal
        show={isSortModalOpen}
        onHide={onModalClose}
        className="hme-tile-list__sort-filter-modal"
        centered
    >
        <ModalHeader closeButton />
        <ModalBody>
            {needToShowSorting &&
                <Sorting
                    isDropdown={isSortingDropdown}
                    sortOptions={sortOptions}
                    sortSelection={selectedSortValue}
                    onSortChange={onSortValueChange}
                />}
            {needToShowFilters &&
                <Filters
                    availableFilters={availableFilters}
                    filtersOptions={filtersOptions}
                    filters={selectedFilters}
                    onFiltersChange={onFilterSelect}
                />}
        </ModalBody>
        <ModalFooter>
            <Button onClick={onModalClose}>{t('common__cancel')}</Button>
            <Button
                variants={['submit']}
                onClick={onFiltersApply}
                disabled={isApplyButtonDisabled}
            >{t('common__apply')}</Button>
        </ModalFooter>
    </Modal>;
};

SortFilterModal.propTypes = {
    isSortModalOpen: PropTypes.bool,
    onSortModalClose: PropTypes.func,
    availableFilters: availableFiltersType,
    filters: PropTypes.shape({}),
    onFiltersChange: PropTypes.func,
    filtersOptions: PropTypes.shape({}),
    sortSelection: PropTypes.shape({}),
    onSortChange: PropTypes.func,
    sortOptions: sortOptionsType,
    onFiltersAndSortReset: PropTypes.func
};
