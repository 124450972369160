import { useTranslation } from 'react-i18next'

import { Title } from 'library/Title';
import { Config } from 'Config';

import './VaioPartnerGuide.scss';

const url = Config.trainingPortalBaseDocURL;
const titleVariants = ['section'];

export const VaioPartnerGuide = () => {
    const { t } = useTranslation();

    return (
        <div className='hme-contact'>
            <div className='hme-contact__labels'>
                <Title variants={titleVariants}>{t('admin__partner__form__type--vaio')}</Title>
                <a
                    href={t('help__partner__vaio-provider__user-guide__url', {url})}
                    className='hme-contact__value hme-vaio-guide__url hme-s-size'
                >{t('reports__docs')}</a>
            </div>
            <div className='hme-contact__values hme-l-size'>
                <a
                    href={t('help__partner__vaio-provider__user-guide__url', {url})}
                    className='hme-contact__value hme-vaio-guide__url'
                >{t('reports__docs')}</a>
            </div>
        </div>
    );
};
