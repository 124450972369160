import React from 'react';
import classNames from 'classnames';

// We already have IconCloseSelection icon but we need to have some modifications there
import { ReactComponent as CloseIcon } from 'assets/icons/IconCloseNavigation.svg';

import './Hamburger.scss';

export const Hamburger = ({ onClick, isOpen, className }) => {
    return (
        <div onClick={onClick} className={classNames('hme-hamburger', className)}>
            {
                isOpen ?
                    <CloseIcon className='hme-hamburger__icon--open' /> :
                    <div className='hme-hamburger__icon--closed'>
                        <div className='hme-hamburger__icon__line'></div>
                        <div className='hme-hamburger__icon__line'></div>
                        <div className='hme-hamburger__icon__line'></div>
                    </div>
            }
        </div>
    );
};
