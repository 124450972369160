const laneTypes = [
    'Single Lane',
    'Dual Lane',
    'Y Lane',
    'Tandem Lane',
    'Mobile - Outside Lane',
    'Mobile - Inside Lane',
    'Curbside Only',
];

export const laneConfigKeys = {
    'Single Lane': 'common__device__lane-config--single',
    'Dual Lane': 'common__device__lane-config--dual',
    'Y Lane': 'common__device__lane-config--y-lane',
    'Tandem Lane': 'common__device__lane-config--tandem',
    'Mobile - Outside Lane': 'common__device__lane-config--mobile-inside',
    'Mobile - Inside Lane': 'common__device__lane-config--mobile-outside',
    'Curbside Only': 'common__device__lane-config--curbside-only',
};

export const deviceConfig = {
    maxSerialNumberLength: 30,
    validRegExp: /[^a-zA-Z0-9]/g,
    zoomNitro: {
        applySettingsMinVersion: '4.4.10',
    },
    zoom: {
        applySettingsMinVersion: '3.13.15',
        applySettingsMaxVersion: '4.0.0',
    },
    getLaneType: laneTypeId => laneTypes[laneTypeId - 1],
};
