import React from 'react';
import classNames from 'classnames';

import { InputComponent } from 'components/Inputs';

export const Input = ({ error, variants = [], className = '', label, ...props }) => {
    const newVariants = React.useMemo(() => {
        return [...variants, error ? 'invalid' : ''];
    }, [error, variants]);

    return (
        <div className="hme-api-management-input">
            <InputComponent
                variants={newVariants}
                label={label}
                className={classNames('hme-api-management-input__wrapper', className)}
                {...props}
            />
        </div>
    );
};
