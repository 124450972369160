import { useEffect } from 'react';

import { useAsync } from 'hooks/useAsync';

import { fetchProviders } from '../../Controller';

export const useProvidersLoader = () => {
    const { run, isLoading, data: providers } = useAsync();

    useEffect(() => {
        run(fetchProviders());
    }, []);

    return {
        providers,
        isLoading
    };
};
