import React from 'react';
import { TextArea } from 'library/TextArea';
import { ErrorLabel } from 'library/ErrorLabel';
import { useTranslation } from 'react-i18next';

import './DeclineMessage.scss';

const DECLINE_MESSAGE_MAX_LENGTH = 200;

export const DeclineMessage = ({ formErrors, onMessageChange }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="view-device-upgrade__confirm-popup__message">
                {t('device-upgrades__popup__decline-upgrade--message')}
            </div>
            <div className="view-device-upgrade__confirm-popup__textarea">
                <TextArea
                    maxLength={DECLINE_MESSAGE_MAX_LENGTH}
                    placeholder={t('device-upgrades__popup__decline-upgrade--reason-placeholder')}
                    onChange={({ target }) => onMessageChange(target.value)}
                />

                {formErrors.message && <ErrorLabel>{t(formErrors.message)}</ErrorLabel>}
            </div>
            <div className="view-device-upgrade__confirm-popup__warning">
                {t('device-upgrades__popup__decline-upgrade--warning')}
            </div>
        </>
    );
};
