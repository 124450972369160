import React from "react";
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import classNames from 'classnames';
import {
  CheckboxInput
} from '../Inputs';
import { ParticipatingStoresTree } from "./ParticipatingStoresTree";
import { PartticipatingStoresCheckboxList } from "./PartticipatingStoresCheckboxList";

class LBGroupStoresList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isViewMyStoresOnlyChecked: false
    };
    this.viewMystoresOnly = this.viewMystoresOnly.bind(this);
  }

    /**
     *
     * @param {*} item
     * @return {String}
     */
    renderLable(item) {
        return item.StoreName ? item.StoreNumber + ' - ' + item.StoreName : item.StoreNumber;
    }

    /**
     *
     */
    viewMystoresOnly() {
        this.setState({ isViewMyStoresOnlyChecked: !this.state.isViewMyStoresOnlyChecked });
    }

  render() {
    const {
      type,
      isDefault,
      items,
      isAllSelected,
      disabled,
      company,
      onChange,
      isContestsPage,
      t,
      isHidden
    } = this.props;
    const selectAllDisabled = (isContestsPage && (!items || !items.length)) || disabled;
    return (
      <div className={classNames('lbGroup',
          {'leaderboard-default-participating-stores': isDefault },
          {'leaderboard-participating-stores': !isDefault },
          { 'hme-leaderboard-participating-stores__with__margin': !isHidden }
      )}>
        <b className="leaderboard-input-label">{this.props.title}</b>
        <div className="lbGroup--new-groups col-12 leaderboard-store-selection-border new-groups">
          <div className="lbGroup--new-groups--controllers ">
            <CheckboxInput
              disabled={selectAllDisabled}
              className={isDefault ? ' hidden' : ''}
              name="selectAll"
              hidden={isDefault}
              id={type}
              label={t('common__select-all')}
              value={isAllSelected}
              onChange={checked => this.props.selectAll({ target: { checked } }, items)}
            />
            <CheckboxInput
              disabled={disabled}
              className=""
              name="viewMyStoresOnly"
              value={this.state.isViewMyStoresOnlyChecked}
              label={t('add-leaderboard__view-my-stores')}
              onChange={this.viewMystoresOnly}
            />
          </div>
          <div className="lbGroupTable">
            <table >
              <tbody className="list-body">
                {isContestsPage
                  ? <ParticipatingStoresTree
                      items={items}
                      disabled={disabled}
                      company={company}
                      isViewMyStoresOnlyChecked={this.state.isViewMyStoresOnlyChecked}
                      onChange={onChange} />
                  : <PartticipatingStoresCheckboxList
                      isViewMyStoresOnlyChecked={this.state.isViewMyStoresOnlyChecked}
                      isDefault={isDefault}
                      company={company}
                      toggle={onChange}
                      items={items}
                      disabled={disabled}
                  />}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(
  withTranslation()
)(LBGroupStoresList);
