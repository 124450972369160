import { upgradeStatuses } from '../constants/upgradeStatuses';

const mapDeviceUpgradeStatusesCount = (devices) => {
    const initialCountMap = {
        [upgradeStatuses.SCHEDULED]: 0,
        [upgradeStatuses.FAILED]: 0,
        [upgradeStatuses.CANCELLED]: 0,
        [upgradeStatuses.COMPLETED]: 0,
        [upgradeStatuses.SENT]: 0,
        [upgradeStatuses.IN_PROGRESS]: 0,
        [upgradeStatuses.PENDING_APPROVAL]: 0,
    };

    return devices.reduce((acc, { Status, RetryCount }) => {
        let status = '';
        switch (true) {
            case Status === upgradeStatuses.SCHEDULED && RetryCount === 0:
                status = upgradeStatuses.SCHEDULED;
                break;

            case Status === upgradeStatuses.FAILED && RetryCount >= 3:
                status = upgradeStatuses.FAILED;
                break;

            case Status === upgradeStatuses.CANCELLED:
                status = upgradeStatuses.CANCELLED;
                break;

            case Status === upgradeStatuses.COMPLETED:
                status = upgradeStatuses.COMPLETED;
                break;

            case ((Status === upgradeStatuses.SENT || Status === upgradeStatuses.SCHEDULED) &&
                RetryCount > 0 &&
                RetryCount <= 3) ||
                (Status === upgradeStatuses.FAILED && RetryCount > 0 && RetryCount < 3):
                status = upgradeStatuses.IN_PROGRESS;
                break;

            case Status === upgradeStatuses.PENDING_APPROVAL:
                status = upgradeStatuses.PENDING_APPROVAL;
                break;

            default:
                break;
        }

        if (status) {
            acc[status]++;
        }

        return acc;
    }, initialCountMap);
};

export const getUpgradeStatus = (devices) => {
    const upgradeStatusesMap = mapDeviceUpgradeStatusesCount(devices);

    switch (true) {
        case upgradeStatusesMap[upgradeStatuses.PENDING_APPROVAL] > 0:
            return upgradeStatuses.PENDING_APPROVAL;

        case upgradeStatusesMap[upgradeStatuses.CANCELLED] === devices.length:
            return upgradeStatuses.CANCELLED;

        case upgradeStatusesMap[upgradeStatuses.FAILED] === devices.length:
            return upgradeStatuses.FAILED;

        case upgradeStatusesMap[upgradeStatuses.COMPLETED] +
            upgradeStatusesMap[upgradeStatuses.FAILED] +
            upgradeStatusesMap[upgradeStatuses.CANCELLED] ===
            devices.length:
            return upgradeStatuses.COMPLETED;

        case upgradeStatusesMap[upgradeStatuses.SCHEDULED] === devices.length ||
            upgradeStatusesMap[upgradeStatuses.SCHEDULED] + upgradeStatusesMap[upgradeStatuses.CANCELLED] ===
                devices.length:
            return upgradeStatuses.SCHEDULED;

        default:
            return upgradeStatuses.IN_PROGRESS;
    }
};
