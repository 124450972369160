import { validateAXAccountNumber } from 'helpers/Store/validateAXAccountNumber';

const getFormErrorsConditions = ({ brandId, storeNumber, countryId, axCustomerAccountNumber }) => [
    {
        key: 'brandId',
        condition: !brandId,
        message: 'stores__error--empty-brand',
    },
    {
        key: 'storeNumber',
        condition: !storeNumber,
        message: 'stores__error--empty-store-number',
    },
    {
        key: 'countryId',
        condition: !countryId,
        message: 'stores__error--empty-country',
    },
    {
        key: 'axCustomerAccountNumber',
        condition: !axCustomerAccountNumber,
        message: 'stores__error--empty-ax-customer-account-number',
    },
    {
        key: 'axCustomerAccountNumberInvalid',
        condition:
            (axCustomerAccountNumber && axCustomerAccountNumber.length < 6) ||
            !validateAXAccountNumber(axCustomerAccountNumber),
        message: 'stores__error--invalid-ax-account-number',
    },
];

// format error messages for all fields
export const formDataValidator = (fields) =>
    getFormErrorsConditions(fields).reduce(
        (errors, el) => (el.condition && ((errors[el.key] = el.message), (errors.status = true)), errors),
        { status: false },
    );

// format error messages for individual field
export const formFieldDataValidator = (field, value) => {
    const validationConditions = validationFieldsMessagesMap[field] || [];
    const conditions = getFormErrorsConditions({ [field]: value });

    const conditionsToValidate = conditions.filter((condition) => validationConditions.includes(condition.key));

    return conditionsToValidate.reduce(
        (errors, el) => (el.condition && ((errors[el.key] = el.message), (errors.status = true)), errors),
        { status: false },
    );
};

// set empty error messages
export const clearFormErrors = (field) =>
    (validationFieldsMessagesMap[field] || []).reduce(
        (errors, errorKey) => ({
            ...errors,
            [errorKey]: '',
        }),
        {},
    );

// form fields mapped to multiple validation messages
export const validationFieldsMessagesMap = {
    brandId: ['brandId'],
    storeNumber: ['storeNumber'],
    countryId: ['countryId'],
    axCustomerAccountNumber: ['axCustomerAccountNumber', 'axCustomerAccountNumberInvalid'],
};
