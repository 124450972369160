import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { T, compose, cond, isEmpty } from 'ramda';
import classNames from 'classnames';

import { Paginate } from 'library/Paginate';
import { Title } from 'library/Title';
import { GridFiltersCount } from 'library/GridFiltersCount';
import { Button } from 'library/Button';
import { SearchOptionable } from 'library/SearchOptionable';
import { SEPARATOR_VARIANTS, Separator } from 'library/Separator';
import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';
import { LoadingModal } from 'library/LoadingModal';
import { NotificationsList } from 'library/NotificationsList';
import { applyPagination } from 'helpers';
import { Grid, applySearchValue, getFiltersCount, getAvailableFilters } from 'components/Common/Grid';
import { TileList } from 'library/TileList';
import { CenterLoader } from 'components/Common/CenterLoader';
import { PAGE_DATA } from 'constants/paginationDefault';
import { PUBLIC_ROUTES } from 'constants/routes';
import { useScreenType } from 'globalState/GlobalStateProvider';
import { useStatusQueryParams } from 'hooks/useStatusQueryParams';
import { useSearchWithDebounce } from 'hooks/useSearchWithDebounce';

import { getSortedRows } from '../../Common/utils';
import { withGamificaionMarketingLayout } from '../../Common/HOCs';
import { Certificate } from '../Certificate';
import {
    useContests,
    CONTESTS_FILTER_OPTIONS,
    LIST_HEADERS,
    MOBILE_LIST_HEADERS,
    MOBILE_FILTER_OPTIONS,
    MOBILE_SORT_OPTIONS,
    DEFAULT_SORT_SELECTIONS
} from './hooks';

import './StatusPage.scss';

const INIT_FILTER_VALUES = { selectValue: 'ContestName', searchValue: '' };
const INIT_PAGINATION_VALUES = { pageSize: 10, pageNumber: 0 };
const separatorVariants = [SEPARATOR_VARIANTS.PURE];

const isNumber = (value) => {
    return !Number.isNaN(Number(value));
};

const convertToNumber = (value) => {
    return isNumber(value) ? Number(value) : value;
};

const mapAvailableFiltersToFilters = (availableFilters = []) => {
    return availableFilters.reduce((result, filter) => {
        result[filter.property] = filter.items.map(({ value }) => convertToNumber(value));

        return result;
    }, {});
};

const mapAppliedFiltersToFilters = (filters = {}) => {
    return Object.entries(filters).reduce((result, [key, value]) => {
        result[key] = value.map(convertToNumber);

        return result;
    }, {});
};

const searchOptions = [
    {
        text: 'common__name',
        value: 'ContestName',
        placeholder: 'contest__search--name-placeholder'
    },
    {
        text: 'common__description',
        value: 'ContestDesc',
        placeholder: 'contest__search--description-placeholder'
    }
];

const allSearchFields = searchOptions.map(({ value }) => value);

const filterAndSort = ({ rows, filters, selectValue, searchValue, sortSelection }) => {
    const fields = selectValue ? [selectValue] : allSearchFields;
    const foundedContests = applySearchValue(rows, fields, searchValue, { minLength: 1 });
    const sortedContests = getSortedRows(foundedContests, sortSelection);

    if (isEmpty(filters)) {
        return sortedContests;
    }

    return sortedContests.filter(({ ContestStatus }) => {
        return filters?.ContestStatusComponent.map(Number).includes(ContestStatus);
    });
};

const StatusPage = ({
    contests,
    setContests,
    contestUIDToCancel,
    setContestUIDToCancel,
    cancelContest,
    isLoadingModalShown
}) => {
    const contestListRef = useRef(null);
    const { t } = useTranslation();
    const screenType = useScreenType();

    const {
        page,
        perPage,
        setPagination,
        searchParams,
        setSearchParams,
        sortSelection,
        setSortSelection,
        appliedFilters,
        setAppliedFilters
    } = useStatusQueryParams({
        page: INIT_PAGINATION_VALUES.pageNumber,
        perPage: INIT_PAGINATION_VALUES.pageSize,
        searchParams: INIT_FILTER_VALUES,
        sortSelection: DEFAULT_SORT_SELECTIONS,
        appliedFilters: {}
    });

    const [searchValue, setSearchValue] = useState(searchParams);
    const [filtersCount, setFiltersCount] = useState(0);

    const availableFilters = useMemo(() => getAvailableFilters(CONTESTS_FILTER_OPTIONS, contests), [contests]);
    const allAvailableFilters = useMemo(() => mapAvailableFiltersToFilters(availableFilters), [availableFilters]);
    const filters = useMemo(
        () =>
            !isEmpty(appliedFilters)
                ? mapAppliedFiltersToFilters(appliedFilters)
                : allAvailableFilters,
        [appliedFilters, allAvailableFilters]
    );

    const filteredSortedContests = filterAndSort({ rows: contests, filters: filters, ...searchParams, sortSelection });

    const handleFiltersChange = useCallback((newFilters) => {
        setAppliedFilters(newFilters, allAvailableFilters);
    }, [allAvailableFilters]);

    const resetFilters = useCallback(() => {
        setFiltersCount(0);
        setContests((currentContests) => [...currentContests]);
        setSortSelection(DEFAULT_SORT_SELECTIONS);
        setAppliedFilters({}, allAvailableFilters);
    }, [contests, allAvailableFilters]);

    const handleSortChange = useCallback((newSorting) => {
        if (newSorting === DEFAULT_SORT_SELECTIONS) {
            setSortSelection(newSorting);

            return;
        }
        setSortSelection(newSorting);
    }, []);

    const handlePaginationChange = ({ page, recordsPerPage }) => {
        if (contestListRef.current) {
            contestListRef.current.scrollTop = 0;
        }
        setPagination({ page, perPage: recordsPerPage });
    };

    const rowsToShow = useMemo(() => applyPagination(filteredSortedContests, { page, recordsPerPage: perPage }), [filteredSortedContests, page, perPage]);

    const onSearchParamsChange = useCallback((newSearchParams) => {
        setSearchParams(newSearchParams);
    }, []);

    const { onSearchChangeHandler, onSearchHandler } = useSearchWithDebounce(onSearchParamsChange, setSearchValue, { searchValueSetter: onSearchParamsChange });

    useEffect(() => {
        setFiltersCount(getFiltersCount(availableFilters, filters));
    }, [availableFilters, filters]);

    return (
        <div className={
            classNames('hme-contests__content', { 'hme-contests__content--reset-filter-shown': sortSelection !== DEFAULT_SORT_SELECTIONS })
        }>
            <div className="hme-contests__header">
                <Title as="h2" className="hme-contests__header-title">{t('common__contests')}</Title>

                <SearchOptionable
                    value={searchValue}
                    onChange={onSearchChangeHandler}
                    onSearch={onSearchHandler}
                    searchSelectOptions={searchOptions}
                />

                {screenType.isDesktop && (
                    <div className="hme-contests__filters-count-wrapper">
                        <Separator variants={separatorVariants} className="hme-contests__header-separator" />

                        <GridFiltersCount
                            count={filtersCount}
                            onFiltersReset={resetFilters}
                            className="hme-contests__filters-count"
                        />
                    </div>
                )}

                <Button as={Link} to={PUBLIC_ROUTES.newContest} className="hme-contests__add-contest">
                    {t('contest__add-contest')}
                </Button>
            </div>

            {screenType.isDesktop ? (
                <Grid
                    rows={rowsToShow}
                    headers={LIST_HEADERS}
                    filters={filters}
                    availableFilters={availableFilters}
                    onFiltersChange={handleFiltersChange}
                    rowKey="ContestUID"
                    noRecordsMessage={t('common__error--no-records-found')}
                    className="hme-contests__list"
                    sortSelection={sortSelection}
                    onSortChange={handleSortChange}
                />
            ) : (
                <TileList
                    rows={rowsToShow}
                    headers={MOBILE_LIST_HEADERS}
                    rowKey="ContestUID"
                    noRecordsMessage={t('common__error--no-records-found')}
                    availableFilters={availableFilters}
                    filtersCount={filtersCount}
                    onFiltersChange={handleFiltersChange}
                    onFiltersReset={resetFilters}
                    filters={filters}
                    sortOptions={MOBILE_SORT_OPTIONS}
                    sortSelection={sortSelection}
                    onSortChange={handleSortChange}
                    filtersOptions={MOBILE_FILTER_OPTIONS}
                    className="hme-contests__list"
                    ref={contestListRef}
                />
            )}
            <Paginate
                page={page}
                recordsPerPage={perPage}
                total={filteredSortedContests.length}
                pageSizes={PAGE_DATA.PAGE_SIZES_PUBLIC}
                onChange={handlePaginationChange}
            />

            <ConfirmPopupComponentGeneric
                show={Boolean(contestUIDToCancel)}
                title={t('common__double-checking')}
                message={t('contest__warning__cancel-contest')}
                actions={[
                    {
                        children: t('common__popup--cancel-action'),
                        onClick: () => setContestUIDToCancel('')
                    },
                    {
                        children: t('common__popup--confirm-action'),
                        variants: ['submit'],
                        onClick: cancelContest
                    }
                ]}
            />

            <LoadingModal show={isLoadingModalShown} />
        </div>
    );
};

const StatusListUI = cond([
    [
        ({ isLoading }) => isLoading,
        () => (
            <CenterLoader>
                <Trans i18nKey="common__loading" />
            </CenterLoader>
        )
    ],
    [T, (props) => <StatusPage {...props} />]
]);

const StatusListLoader = () => {
    const {
        isLoading,
        contests,
        setContests,
        contestUIDToCancel,
        setContestUIDToCancel,
        cancelContest,
        isLoadingModalShown,
        hideCertificateModal,
        certificateToShow
    } = useContests();

    return (
        <>
            <NotificationsList />
            <StatusListUI
                isLoading={isLoading}
                contests={contests}
                setContests={setContests}
                contestUIDToCancel={contestUIDToCancel}
                setContestUIDToCancel={setContestUIDToCancel}
                cancelContest={cancelContest}
                isLoadingModalShown={isLoadingModalShown}
            />

            {certificateToShow && <Certificate
                contestUID={certificateToShow}
                onHide={hideCertificateModal}
            />}
        </>
    );
};

export const StatusListPage = compose(
    withGamificaionMarketingLayout({
        className: ['hme-contests']
    })(StatusListLoader)
);
