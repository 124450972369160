import { useEffect } from 'react';

import { useAsync } from 'hooks/useAsync';
import { getEnabledCustomerNotifications, getPassedCustomerNotificationsByTypeId } from 'services/CustomerNotification';
import { CUSTOMER_NOTIFICATION_TYPE_IDS } from 'constants/customerNotifications';

import { getDateWithMonthOffset } from '../helpers';

const MONTH_OFFSET = 6;

const DEFAULT_VALUE = { data: [] };

const CATCH_FN = () => {
    // Do nothing when no enabled notifications found
};

export const useCustomerNotificationLoader = ({ onStartLoading, onEndLoading }) => {
    const {
        isLoading: isenabledNotificationsLoading,
        isRejected,
        data: enabledNotifications,
        run: runEnabledNotifications
    } = useAsync(DEFAULT_VALUE);
    const {
        isLoading: isPastNotificationsLoading,
        isRejected: isPastNotificationsRejected,
        data: pastNotifications,
        run: runPastNotifications
    } = useAsync(DEFAULT_VALUE);

    useEffect(() => {
        runEnabledNotifications(
                getEnabledCustomerNotifications().catch(CATCH_FN)
        );
    }, []);

    useEffect(() => {
        const date = new Date();
        const dateFromNow = getDateWithMonthOffset(date, MONTH_OFFSET);

        runPastNotifications(
                getPassedCustomerNotificationsByTypeId({
                    typeId: CUSTOMER_NOTIFICATION_TYPE_IDS.announcement,
                    dateFromNow
                }).catch(CATCH_FN)
        );
    }, []);

    const isLoading = isenabledNotificationsLoading || isPastNotificationsLoading;

    useEffect(() => {
        if (isLoading) {
            onStartLoading();
            return;
        }

        onEndLoading();
    }, [isLoading]);

    return {
        isLoading,
        isRejected: isPastNotificationsRejected || isRejected,
        enabledNotifications,
        pastNotifications
    };
};
