import { addNotification, NOTIFICATION_TYPES } from 'services/Notifications';
import AuthenticationService from 'components/Security/AuthenticationService';
import { Config } from 'Config';
import { createAPIToken, updateAPIToken } from 'services/TokenManagement';
import { tokenManagementBaseUrl } from './constants';

const tokenManagerToDTO = ({ id, email } = {}) => {
    return {
        id,
        email
    };
};

const tokenInfoToDTO = ({ tokenManager, emails, ...tokenInfo }) => {
    return {
        ...tokenInfo,
        tokenManager: tokenManagerToDTO(tokenManager)
    };
};

/**
 * Create new Token
 * @param {Object} formValues
 * @param {useNavigate} navigate
 * @param {function} setIsLoading
 * @param {string} tokenUID
 * @param {t} t
 * @return {*} responds with notification
 */
export const createToken = async (formValues, navigate, setIsLoading, tokenUID, t) => {
    const authService = new AuthenticationService(Config.authBaseUrl);
    const userEmail = authService.getProfile().unique_name;
    const navigateTo = tokenManagementBaseUrl;

    try {
        setIsLoading(true);

        let result = {};
        const tokenInfoDTO = tokenInfoToDTO(formValues);

        if (tokenUID) {
            tokenInfoDTO.updatedBy = userEmail;
            await updateAPIToken({ ...tokenInfoDTO, tokenUID });
        } else {
            tokenInfoDTO.createdBy = userEmail;
            result = await createAPIToken(tokenInfoDTO);
        }

        if (tokenUID) {
            setTimeout(() => {
                addNotification({
                    message: t('api-token-management__form--token-edit-success'),
                    autoClose: true,
                    type: NOTIFICATION_TYPES.SUCCESS
                });
            }, 1000);

            navigate(navigateTo);
        } else {
            if (!result.token) {
                return;
            }

            /**
             * TODO:
             * split creation and edition functionality into two different functions according to single responsibility principle
             */
            // eslint-disable-next-line consistent-return
            return result;
        }
    } catch (e) {
        addNotification({
            message: t(e.response ? e.response.data.message : e.message),
            autoClose: true,
            type: NOTIFICATION_TYPES.ERROR
        });
        setIsLoading(false);
    }
};
