export const MOBILE_SNAPSHOTS_HEADERS = [
    {
        column: [
            { text: 'common__name', property: 'SettingName' },
            { text: 'common__snapshot__version', property: 'SettingsVersion' },
            { text: 'common__brand', property: 'SourceBrand' },
            { text: 'settings_snapshots__grid__header-title--source-settings-version', property: 'SourceSettingsVersion' },
            { text: 'settings_snapshots__grid__header-title--source-version', property: 'SourceSoftwareVersion' },
        ],
    },
    {
        column: [
            { text: 'common__device-serial_no', property: 'SourceSN' },
            { text: 'common__device__product-id', property: 'SourceDeviceProductID' },
            { text: 'common__lane-type', property: 'SourceLaneConfig' },
            { text: 'common__created', property: 'CreatedDate' },
            { text: 'common__created-by', property: 'CreatedBy' },
        ],
    },
];
