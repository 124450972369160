
import { companyTypes } from './constants';

export const formDataValidator = ({
    accountOwnerInfo,
    companyInfo,
    subscriptionType: subscriptionTypeForm,
}) => {
    const { firstName, lastName, emailAddress, emailAddressConfirm } = accountOwnerInfo;
    const { companyName, companyCountryId, companyRegionId, region, companyType, brandUid } = companyInfo;
    const { subscriptionType } = subscriptionTypeForm;

    const formErrorsConditions = [
        {
            key: 'firstName',
            condition: !firstName,
            message: 'admin__account__section__form--validation--first-name',
        },
        {
            key: 'lastName',
            condition: !lastName,
            message: 'admin__account__section__form--validation--last-name',
        },
        {
            key: 'emailAddress',
            condition: !emailAddress,
            message: 'admin__account__section__form--validation--email',
        },
        {
            key: 'emailAddressIsValid',
            condition: !/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(emailAddress),
            message: 'admin__account__section__form--validation--email-incorrect',
        },
        {
            key: 'emailAddressConfirm',
            condition: !emailAddressConfirm,
            message: 'admin__account__section__form--validation--email-confirm',
        },
        {
            key: 'emailAddressConfirmEqual',
            condition: emailAddress !== emailAddressConfirm,
            message: 'admin__account__section__form--validation--email-equal',
        },
        {
            key: 'companyName',
            condition: !companyName,
            message: 'admin__account__section__form--validation--company-name',
        },
        {
            key: 'companyCountryId',
            condition: !companyCountryId,
            message: 'admin__account__section__form--validation--country',
        },
        {
            key: 'companyRegionId',
            condition: !companyRegionId && !region,
            message: 'admin__account__section__form--validation--state',
        },
        {
            key: 'brandUid',
            condition: companyType === companyTypes.CORPORATION && !brandUid,
            message: 'admin__account__section__form--validation--brand',
        },
        {
            key: 'subscriptionType',
            condition: !subscriptionType,
            message: 'admin__account__section__form--validation--subscription-type',
        },
    ];

    const formError = formErrorsConditions.reduce(
        (errors, el) => (el.condition && ((errors[el.key] = el.message), (errors.status = true)), errors),
        { status: false },
    );

    return formError;
};

// form fields mapped to multiple validation messages
export const validationFieldsMessagesMap = {
    firstName: ['firstName'],
    lastName: ['lastName'],
    emailAddress: ['emailAddress', 'emailAddressIsValid'],
    emailAddressConfirm: ['emailAddressConfirm', 'emailAddressConfirmEqual'],
    companyName: ['companyName'],
    companyCountryId: ['companyCountryId'],
    companyRegionId: ['companyRegionId'],
    brandUid: ['brandUid'],
    subscriptionStartDate: ['subscriptionStartDate'],
    subscriptionEndDate: ['subscriptionEndDate', 'subscriptionEndCompareDates', 'subscriptionEndDateMin'],
    subscriptionBillingCycle: ['subscriptionBillingCycle'],
    subscriptionType: ['subscriptionType'],
};
