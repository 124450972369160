/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';

import './Button.scss';

export const BUTTON_VARIANTS = {
    SUBMIT: 'submit',
    TRANSPARENT: 'transparent',
    ACCEPT: 'accept',
    DECLINE: 'decline'
};

export const Button = ({
    variants = [],
    type = 'button',
    disabled = false,
    onClick,
    className = '',
    children,
    preview,
    as: Component = 'button',
    ...props
}) => (
    <Component
        type={Component !== 'button' ? null : type}
        className={classNames(
                'hme-button-component',
                {
                    'hme-button-disabled-component': disabled && !variants.includes('transparent'),
                    'hme-button-disabled-link-component': disabled && variants.includes('transparent'),
                    'hme-button-preview-component': disabled && preview && variants.includes('submit')
                },
                variants.map((variant) => `hme-${variant}-button-component`),
                className
        )}
        onClick={onClick && ((e) => {
            if (disabled) {
                return;
            }

            onClick(e);
        })}
        disabled={disabled}
        {...props}
    >
        {children}
    </Component>
);
