const { CommonConstants } = require('Constants');

/**
 * Checks if the given subscription ID is part of the allowed NVD subscriptions.
 *
 * @param {string} subscriptionId - The ID of the subscription to check.
 * @return {boolean} - Returns true if the subscription ID is in the list of allowed subscriptions, otherwise false.
 */
const displayNVDSubscriptionToggle = (subscriptionId) => {
    const {  subscription: {
        nitroData,
        nitroLeaderboard,
        nitroGamification
    } } = CommonConstants;

    const allowedSubscriptions = [nitroData, nitroLeaderboard, nitroGamification];

    return allowedSubscriptions.includes(subscriptionId);
};

export default displayNVDSubscriptionToggle;
