import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useTrigger } from 'hooks/useTrigger';
import { Modal } from 'library/Modal';
import { SnapshotsList } from './SnapshotsList';
import { Header } from './Header';

import './SelectSnapshotModal.scss';

export const SelectSnapshotModal = ({ snapshots, deviceType, isSnapshotsLoading = false, onSnapshotSelect, onHide, ...modalProps }) => {
    const [filtersCount, setFiltersCount] = useState(0);
    const resetFiltersTrigger = useTrigger();
    const onFiltersReset = useCallback(() => {
        resetFiltersTrigger.trigger();
    }, [snapshots, resetFiltersTrigger]);
    const { t } = useTranslation();
    return (
        <Modal
            {...modalProps}
            onHide={onHide}
            actions={[
                {
                    onClick: onHide,
                    children: t('common__close'),
                },
            ]}
            dialogClassName="hme-select-snapshot-modal-dialog"
        >
            <div className="hme-select-snapshot-modal">
                <Header onFiltersReset={onFiltersReset} filtersCount={filtersCount} deviceType={deviceType} />
                <SnapshotsList
                    snapshots={snapshots}
                    onSnapshotSelect={onSnapshotSelect}
                    filtersCount={filtersCount}
                    onFiltersCountChange={setFiltersCount}
                    resetFiltersTrigger={resetFiltersTrigger}
                    isSnapshotsLoading={isSnapshotsLoading}
                />
            </div>
        </Modal>
    );
};
