/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import classNames from 'classnames';
import './TitledRowSelect.scss';
import { RowTitle } from 'library/RowTitle';
import { ReactComponent as LinkIcon } from 'assets/icons/link-solid.svg';
import { Label } from 'library/Label';
import { ReactComponent as DropdownIcon } from 'assets/icons/down.svg';

export const TitledRowSelect = ({ title, label, className, list = [] }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={classNames('hme-titled-row-select', { 'no-hme-select-icon': !list || !list.length }, className)}>
            <RowTitle>{title}</RowTitle>
            <LinkIcon className="hme-titled-row-linc-icon"/>
            <div
                className={classNames('hme-select', {
                    'hme-select-opened': isOpen
                })}
            >
                <Label>{label}</Label>
                <DropdownIcon
                    className="hme-select-icon"
                    onClick={() => setIsOpen(!isOpen)}
                />
                <ul className="hme-select-dropdown">
                    {
                        list.map(({ value: optionValue, text }) => (
                            <li
                                key={optionValue}
                                className="hme-select-dropdown-item"
                            >
                                {text}
                            </li>
                        ))
                    }
                </ul>
            </div>


        </div>
    );
};
