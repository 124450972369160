import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingModal } from 'library/LoadingModal';
import { NotificationsList } from 'library/NotificationsList';
import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';
import { CenterLoader } from 'components/Common/CenterLoader';
import { SettingsForm } from 'pages/StoreFeatures/VaioProviderForm';

import { useProviderDetails } from './hooks';

export const ViewEdit = () => {
    const { t } = useTranslation();

    const {
        isVaioProviderLoading,
        vaioProvider: settings,
        handleVaioProviderPreUpdate,
        cancelProviderEditiing,
        isConfirmationPopupShown,
        closeConfirmationPopup,
        handleVaioProviderUpdate,
        isUpateInProgress
    } = useProviderDetails();

    const actions = useMemo(() => {
        return [
            {
                children: t('common__popup--cancel-action'),
                onClick: closeConfirmationPopup
            },
            {
                children: t('common__popup--confirm-action'),
                variants: ['submit'],
                onClick: handleVaioProviderUpdate
            }
        ];
    }, [t, closeConfirmationPopup, handleVaioProviderUpdate]);

    if (isVaioProviderLoading) {
        return <CenterLoader>{t('common__loading')}</CenterLoader>;
    }

    const {
        vaioProviderChangeHistory,
        email
    } = settings;

    return <div className="hme-components new-vaio-provider-wrapper">
        <SettingsForm
            title={t('common__provider-settings')}
            isViewChangeHistoryEnabled
            linkedPartner={email}
            providerChangeHistory={vaioProviderChangeHistory}
            settings={settings}
            isPartnerMode
            onCancel={cancelProviderEditiing}
            onSubmit={handleVaioProviderPreUpdate}
        />

        <ConfirmPopupComponentGeneric
            show={isConfirmationPopupShown}
            title={t('common__double-checking')}
            message={t('partner__provider-settings--vaio-update-message')}
            actions={actions}
            onHide={closeConfirmationPopup}
        />

        <LoadingModal show={isUpateInProgress} />

        <NotificationsList />
    </div>;
};
