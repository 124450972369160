import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccount } from 'hooks/account';
import { addErrorNotification } from 'services/Notifications';

const EMAIL = new RegExp(
        /^([a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-])+@(([0-2]?[0-5]?[0-5]\.[0-2]?[0-5]?[0-5]\.[0-2]?[0-5]?[0-5]\.[0-2]?[0-5]?[0-5])|((([a-zA-Z0-9-])+\.)+([a-zA-Z-])+))$/u
);

const MIN_REQUIRED_LENGTH = /^.{3,}$/u;
const MIN_REQUIRED_LENGTH_LAST_NAME = /^.+$/u;

export const useAccountData = () => {
    const { t } = useTranslation();
    const { isLoading: isLoadingAccountData, fetchedAccountData, accountData, loadAccount, setAccountData } = useAccount();

    const [accountDataErrors, setAccountDataErrors] = useState({});

    const handleSetAccountDataErrors = useCallback(() => {
        if (!accountData) return;

        const newErrors = {};

        if (!EMAIL.test(accountData.changeEmail)) {
            newErrors.changeEmail = t('common__error--invalid-email');
        }

        if (!MIN_REQUIRED_LENGTH.test(accountData.firstName)) {
            newErrors.firstName = t('add-user__error--first-name-empty');
        }

        if (!MIN_REQUIRED_LENGTH_LAST_NAME.test(accountData.lastName)) {
            newErrors.lastName = t('add-user__error--last-name-empty');
        }

        setAccountDataErrors(newErrors);
    }, [accountData, t]);

    useEffect(() => {
        loadAccount().catch(() => {
            addErrorNotification(t('common__error--internal-server'));
        });
    }, [loadAccount, t]);

    useEffect(handleSetAccountDataErrors, [accountData]);

    return {
        isLoadingAccountData,
        fetchedAccountData,
        accountData,
        accountDataErrors,
        setAccountData
    };
};
