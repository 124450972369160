import { DateLib } from '@hme-cloud/utility-common';

const defaultSort = (accountsList, sortBy, direction) => {
    const defaultSortFunc = (val1, val2) => direction *
        val1[sortBy].toLowerCase().localeCompare(val2[sortBy].toLowerCase());
    return [...accountsList.sort(defaultSortFunc)];
};

const sortByStatus = (accountsList, sortBy, direction) => {
    const sortFunc = (val1, val2) => {
        const currentDate = new DateLib(val1[sortBy]).diffMilliseconds();
        const nextDate = new DateLib(val2[sortBy]).diffMilliseconds();

        return direction * (currentDate - nextDate);
    };

    return [...accountsList.sort(sortFunc)];
};

export const getSortedRows = (accountsList, sortSelection) => {
    const [[sortBy, sortDirection]] = Object.entries(sortSelection);

    switch (sortBy) {
        case 'expiryDate':
            return sortByStatus(accountsList, 'originalExpiryDate', sortDirection);
        default:
            return defaultSort(accountsList, sortBy, sortDirection);
    }
};
