export const translationKeysMap = {
    'storeRank': 'dashboard__store-rank',
    'storeName': 'dashboard__store-name',
    'storeNumber': 'dashboard__store-number',
    'Rank': 'dashboard__store-rank',
    'Name': 'dashboard__store-name',
    'Stores': 'common__stores',
    'Store': 'common__store',    
    'Cars': 'common__cars',
    'Avg': 'common__avg',
    'Goal': 'common__goal',
    'Goal%': 'common__goal--percent',
    'Current Day': 'common__current-period--day',
    'Previous Day': 'common__previous-period--day',
    'Current Month': 'common__current-period--month',
    'Previous Month': 'common__previous-period--month',
    'Current Week': 'common__current-period--week',
    'Previous Week': 'common__previous-period--week',
    'Current Daypart': 'common__current-period--daypart',
    'Previous Daypart': 'common__previous-period--daypart',
    'removecolumm': 'common__remove-column',
    'setdefaultsort': 'common__set-ranking-metric',
};
