export const groupRounds = (rounds) => {
    if (!rounds) {
        return [];
    }

    return rounds[0].roundsData.map((_, index) => {
        const result = {
            round: index + 1
        };

        result.stores = rounds.map(({ roundsData, store, totalPoints }) => {
            const currentStoreRound = roundsData[index];

            const data = {
                store,
                metric: currentStoreRound.metric,
                points: currentStoreRound.points,
                totalPoints: totalPoints,
                aggregateFailedCount: currentStoreRound.aggregateFailedCount
            }

            return data;
        });

        result.stores = result.stores.sort(({ points: points1 }, { points: points2 }) => points2 - points1);

        result.stores = result.stores.map((store, storeIndex) => ({
            ...store,
            rank: storeIndex + 1,
        }))

        return result;
    });
};
