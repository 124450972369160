import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isDistributor as isProfileDistributor } from 'services/Auth';

import { CommonConstants } from 'Constants';
import { isAccountOwner as isProfileAccountOwner } from 'services/Auth';

const { ProfilePage, WelcomePage } = CommonConstants;

const TABS = {
    ACCOUNT: 'account',
    COMPANY: 'company',
    BRANDS: 'brand-sharing',
};

const isDistributor = isProfileDistributor();
const isAccountOwner = isProfileAccountOwner();

const hasBrandsTab = isAccountOwner;

const allowedTabs = [TABS.ACCOUNT, TABS.COMPANY];

if (hasBrandsTab) {
    allowedTabs.push(TABS.BRANDS);
}

export const useProfile = () => {
    const navigate = useNavigate();
    const { activeTab } = useParams();

    const handleActiveTabChange = useCallback((newTab) => {
        const url = `${ProfilePage}/${newTab}`;
        navigate(url);
    }, [navigate]);

    useEffect(() => {
        if (!allowedTabs.includes(activeTab)) {
            navigate(WelcomePage);
        }
    }, [activeTab, navigate]);

    return {
        activeTab,
        hasBrandsAccess: hasBrandsTab,
        isDistributor,
        isAccountOwner,
        handleActiveTabChange
    };
};
