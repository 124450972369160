import React, { Component } from 'react'
import { Buffer } from 'buffer';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import { translationKeysMap } from './translationsMap';
import { Row, Col } from 'reactstrap'
import './Dashboard.css'
import CommonLoader from '../Alerts/CommonLoader'
import Api from '../../Api'
import { Config } from '../../Config'
import { CommonConstants } from '../../Constants'
import AuthenticationService from '../../components/Security/AuthenticationService'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as dashboard from '../../actions/dashboard'
import { DateLib } from '@hme-cloud/utility-common';
import { SimpleToolTip } from '../Common/SimpleTooltip'

var fileDownload = require('js-file-download')
let arrowAsc = require('../../images/arrow_ASC.png')
let arrowDesc = require('../../images/arrow_DESC.png')
let hmeLogo = require('../../images/HMELogo.png')

class DashboardComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      timeMeasureType: '',
      eventType: 2000,
      showDownloadOptions: false,
      currentSortBy: '',
      autoRefresh: false,
      defaultLoad: true,
      currentPeriod: 'current day',
      showLoader: true,
      currentDate: new Date(),
      refreshTimer: {},
      ResponseType: '',
      sortParams: {},
      interval: null
    }
    this.api = new Api()
    this.getTimemeasureDetails = this.getTimemeasureDetails.bind(this)
    this.GetTimeMeasureFromProps = this.GetTimeMeasureFromProps.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.onLinkClick = this.onLinkClick.bind(this)
    this.downloadDashboard = this.downloadDashboard.bind(this)
    this.getTimemeasureDetails(this.state.ResponseType, this.state.eventType, this.state.defaultLoad)
  }

  onLinkClick (e) {
    e.preventDefault()
    this.getTimemeasureDetails(e.target.id, this.state.eventType, 'onclick')
  }

  getTimemeasureDetails (timeMeasureType, eventType, onInitLoad) {
    let sortParams = {}
    if (timeMeasureType !== '' && timeMeasureType !== 'custom') {
      sortParams.period = 'current ' + timeMeasureType
      sortParams.sortType = 'ASC'
      sortParams.sortBy = 'avg'
      this.props.setSortParams(sortParams)
    }
    this.GetTimeMeasureFromProps(timeMeasureType, eventType, onInitLoad, sortParams)
    if (onInitLoad === null) {
      this.setState({ defaultLoad: true })
    } else {
      this.setState({ defaultLoad: false, currentDate: new Date(), autoRefresh: false, showLoader: true })
    }
  }

  GetTimeMeasureFromProps (timeMeasureType, eventType, onInitLoad, sortParams) {
    const DashboardMetric = window.localStorage.getItem('DashboardMetric')
    eventType = DashboardMetric || eventType
    this.setState({ showLoader: true })
    this.props.getTimeMeasure(timeMeasureType, eventType, onInitLoad, sortParams, data => {
      const dashboardData = data.dashboard
      const key = data.key
      let defaultDashboardMetric = data.userPreferences.eventTypeId
      eventType = (DashboardMetric !== null ? DashboardMetric : defaultDashboardMetric)
      let sortParamslocal = this.props.sortParams === undefined ? sortParams : this.props.sortParams
      if ((dashboardData !== undefined && dashboardData !== null && dashboardData.length > 0) ||
        key === 'noAccess' || key === 'noStores' || key === 'noDataFound') {
        if (this.state.showLoader) {
          this.setState({ showLoader: false, ResponseType: this.props.reportResponseType, sortParams: sortParamslocal, eventType })
        }
      }
    })
  }

  handleSort (headerItem, obj) {
    let sortParams = this.state.sortParams
    if (obj.title === sortParams.period && headerItem === sortParams.sortBy) {
      sortParams.sortType = sortParams.sortType === 'ASC' ? 'DESC' : 'ASC'
    } else {
      if (sortParams.period !== undefined) {
        sortParams.sortBy = headerItem
        sortParams.period = obj.title
        sortParams.sortType = 'ASC'
      }
      this.props.setSortParams(sortParams)
    }
    this.GetTimeMeasureFromProps(this.props.reportResponseType, this.state.eventType, this.state.defaultLoad, sortParams)
    this.setState({ showLoader: true })
  }

  eventTypeChange (e) {
    const { value } = e.target
    window.localStorage.setItem('DashboardMetric', value)
    this.setState({
      eventType: value
    })
    this.GetTimeMeasureFromProps(this.props.reportResponseType, value, this.state.defaultLoad, this.props.sortParams)
  }
  downloadOptions () {
    this.setState({
      showDownloadOptions: true
    })
  }
  hideDownloadOptions () {
    this.setState({
      showDownloadOptions: false
    })
  }
  downloadDashboard (e) {
    const ext = e.target.title
    let authService = new AuthenticationService(Config.authBaseUrl)
    let sortParams = this.props.sortParams
    let query = `?download=${ext}&isAdmin=${authService.isAdmin() ? '1' : '0'}&reportType=${this.props.reportResponseType}&eventType=${this.state.eventType}${authService.getUUID() ? '&uuid=' + authService.getUUID() : ''}${sortParams ? '&sortBy=' + sortParams.sortBy + '&sortType=' + sortParams.sortType + '&period=' + sortParams.period : ''}&hidePreconfiguredDevices=1`
    let url = Config.apiBaseUrl + CommonConstants.apiUrls.downloadReport + query
    // Loader Function start
    this.setState({ showLoader: true })
    this.api.getData(url, data => {
      let output = null
      if (ext === 'csv') {
        output = Buffer.from(data[`csvBuffer`].data, 'utf16le')
      } else {
        output = Buffer.from(data[`xlsBuffer`].data, 'binary')
      }
      // Loader Function End
      this.setState({ showLoader: false })
      fileDownload(output, `hmeCloudDashboard.${ext}`)
    })
  }

  enableAutoRefresh () {
    let self = this
    let interval = setInterval(function () {
      self.setState({ showLoader: true })
      self.GetTimeMeasureFromProps(self.props.reportResponseType, self.state.eventType, self.state.defaultLoad, null)
    }, Config.autoRefreshTime)
    this.setState({ interval, autoRefresh: true })
  }

  disableAutoRefresh () {
    clearInterval(this.state.interval)
    this.setState({ autoRefresh: false })
  }

  render () {
    const { t, i18n } = this.props;
    let showLoader = this.state.showLoader
    let ltGoalAColor, ltGoalBColor, gtGoalBColor
    let Preferences = this.props.userPreferences
    const currentStartDate = this.props.currentDayData && this.props.currentDayData.CurrentStartDate ? this.props.currentDayData.CurrentStartDate : '';
    const  currentEndDate = this.props.currentDayData && this.props.currentDayData.CurrentEndDate ? this.props.currentDayData.CurrentEndDate : '';
    let startDate = '';
    const endDate = currentEndDate ? new DateLib(currentEndDate).format(DateLib.FORMAT_TYPES.MONTH_DATE) : '';
      if (this.props.reportResponseType === 'week' || this.props.reportResponseType==='month' || (this.props.reportResponseType === 'custom' && currentStartDate !== currentEndDate)) {
        startDate = currentStartDate ? new DateLib(currentStartDate).toUTC().format(DateLib.FORMAT_TYPES.MONTH_DATE) : '';
      } else {
        startDate = currentStartDate ? new DateLib(currentStartDate).format(DateLib.FORMAT_TYPES.MONTH_DATE) : '';
      }
    if (this.props.userPreferences && this.props.userPreferences.colors) {
      ltGoalAColor = { backgroundColor: this.props.userPreferences.colors.split('|')[0] }
      ltGoalBColor = { backgroundColor: this.props.userPreferences.colors.split('|')[1] }
      gtGoalBColor = { backgroundColor: this.props.userPreferences.colors.split('|')[2] }
    }
    const dashboardData = this.props.dashboardData
    const realTime = this.props.realTime

    if (this.props.reason === 'noStores') {
      return (
        <div className='noStores'>
          <div>{t('common__register-zoom')}</div>
        </div>
      )
    } else if (this.props.reason === 'noAccess') {
      return (
        <div className='col1'>
          <div className='forms clear'>
            <h3 className='up_head'>{t('common__upgrade-account-to-premium')}</h3>
            <h5 className='up_sub'>{t('common__call-to-upgrade')}</h5>
            <table className='up_num'>
              <tr>
                  <td>{t('common__domestic-sales--title')}</td>
                  <td>{t('common__domestic-sales--info')}</td>
              </tr>
              <tr>
                  <td>{t('common__international-sales--title')}</td>
                  <td>{t('common__international-sales--info-regions')}</td>
              </tr>
              <tr>
                <td />
                <td>
                  {t('common__international-sales--info-other-countries')}
                </td>
              </tr>
            </table>
          </div>
        </div>
      )
    } else {
      return (
        <div className='section-content'>
          <div className={showLoader ? 'show' : 'hide'}>
            <CommonLoader showLoader={showLoader} message="dashboard__loading-please-wait" isDashboard />
          </div>
          <div className={'dashboard-content ' + (showLoader ? 'hide' : 'show')}>
            <div className='dashboard-type'>
              <div className='dashboard-types pull-left'>
                <ul className='time-measure-list'>
                  <li><a id='rollinghour' onClick={this.onLinkClick} key='rollinghour' className={this.props.reportResponseType === 'rollinghour' ? 'active-time-measure' : ''}>{t('common__rollinghour')}</a> | </li>
                  <li><a id='hour' onClick={this.onLinkClick} key='hour' className={this.props.reportResponseType === 'hour' ? 'active-time-measure' : ''}>{t('common__hour')}</a> | </li>
                  <li><a id='daypart' onClick={this.onLinkClick} key='daypart' className={this.props.reportResponseType === 'daypart' ? 'active-time-measure' : ''}>{t('common__daypart')}</a> |  </li>
                  <li><a id='day' onClick={this.onLinkClick} key='day' className={this.props.reportResponseType === 'day' ? 'active-time-measure' : ''}>{t('common__day')}</a> |  </li>
                  <li><a id='week' onClick={this.onLinkClick} key='week' className={this.props.reportResponseType === 'week' ? 'active-time-measure' : ''}>{t('common__week')}</a>  |  </li>
                  <li><a id='month' onClick={this.onLinkClick} key='month' className={this.props.reportResponseType === 'month' ? 'active-time-measure' : ''}>{t('common__month')}</a></li>
                  { (Preferences.customHeaders != null)
                    ? <li> | <a id='custom' onClick={this.onLinkClick} key='custom' className={this.props.reportResponseType === 'custom' ? 'active-time-measure' : ''}>{t('common__custom')}</a></li>
                    : <li />
                  }
                </ul>
                <ul className='current-time'>
                  <li><a key='customizedashboard' href='/dashboard/customizedashboard'>{t('dashboard__customize-dashboard')}</a></li>
                </ul>
              </div>
              <div className='current-date-time pull-right'>
              <h4>{startDate} - {endDate}</h4>
                { !realTime
                  ? <h4 className='auto-refresh-header'>
                    <span>
                      <a className={(this.state.autoRefresh ? 'hidden' : '')} onClick={this.enableAutoRefresh.bind(this)}>{t('dashboard__enable-auto-refresh')}</a>
                      <a className={(!this.state.autoRefresh ? 'hidden' : '')} onClick={this.disableAutoRefresh.bind(this)}>{t('dashboard__disable-auto-refresh')}</a>
                    </span>
                  </h4>
                  : <h4 />
                }
              </div>
            </div>
            <div className='download-content-table'>
              <div className='download-btn-section downloadOptionsSection zoom-download'
                onMouseOver={this.downloadOptions.bind(this)} onMouseOut={this.hideDownloadOptions.bind(this)}>
                <div className='btn download-summaryreport-btn' >
                  <div>{t('common__download')}</div>
                </div>
                <div className={(this.state.showDownloadOptions) ? 'downloadOptions show' : 'downloadOptions hidden'}>
                  <div><a title='xls' onClick={this.downloadDashboard}>.xls</a></div>
                  <div><a title='csv' onClick={this.downloadDashboard}>.csv</a></div>
                </div>
              </div>
              <div className='metrics-select pull-right'>
                <label htmlFor='metrics'>{t('common__metrics')}</label>
                <select name='timeMeasure' className='metrics' onChange={this.eventTypeChange.bind(this)}>
                  <option selected={this.state.eventType === '2000'} value='2000'>{t('common__lane-total')}</option>
                  <option selected={this.state.eventType === '2001'} value='2001'>{t('common__lane-total-2')}</option>
                </select>
              </div>
              <div className=''>
                <Row>
                  <Col lg='4' sm='12'>
                    <img src={hmeLogo} width='54' height='25' alt='HMElogo' />
                  </Col>
                  <Col lg='4' sm='12' className='dashboard-header'>
                    <span> {!realTime ? t('dashboard__hme-cloud-dashbord') : t('dashboard__hme-dashboard-header-real-time')} </span>
                  </Col>
                  <Col lg='4' sm='12' className='ranking-text-header'>
                    {t('dashboard__ranking-accurate', { rankingTime: new Date().toLocaleString(), interpolation: { escapeValue: false } })}
                  </Col>
                </Row>
                <Row className='zeroMargin dashboard_Table'>
                  <Dashboard dashboardData={dashboardData}
                    Preferences={this.props.userPreferences}
                    sortParams={this.props.sortParams}
                    handleSort={this.handleSort}
                    ResponseType={this.props.reportResponseType}
                    eventType={this.state.eventType}
                    realTime={this.props.realTime}
                    t={t} />
                </Row>
                <div className='dashboard-goals-list' style={{  }} >
                  <div className='dashboard-goal'>
                    <span>
                      <div style={ltGoalAColor} className='circle inline-block' />
                    </span>
                    <span className='dashboard-goals'>
                      {t('common__goal')} &lt; A
                    </span>
                  </div>
                  <div className='dashboard-goal'>
                    <span>
                      <div style={ltGoalBColor} className='circle inline-block' />
                    </span>
                    <span className='dashboard-goals'>
                      {t('common__goal')} &lt; B
                    </span>
                  </div>
                  <div className='dashboard-goal'>
                    <span>
                      <div style={gtGoalBColor} className='circle inline-block' />
                    </span>
                    <span className='dashboard-goals'>
                      {t('common__goal')} &gt;= B
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

class DashboardHeaders extends React.Component {
  render () {
    const { t } = this.props;
    let table = this.props.DashboardItem
    const disable = this.props.disable
    const sortParams = this.props.sortParams
    const Preferences = this.props.Preferences
    const tableIndex = this.props.tableIndex
    let value = ''
    return table.columns.map((header) => {
      if (sortParams && sortParams.sortType) {
        if (sortParams.sortType.toUpperCase() === 'ASC') {
          value = ((table.title === sortParams.period && header.toLowerCase() === sortParams.sortBy.toLowerCase()) ? arrowAsc : '')
        } else {
          value = ((table.title === sortParams.period && header.toLowerCase() === sortParams.sortBy.toLowerCase()) ? arrowDesc : '')
        }
      }
      if (tableIndex === 0 && header === 'percent') {
        header = 'goal'
      }
      return (
        <th className={'StoreRankingSubHeading fullWidth ' + (header === 'storeName' && Preferences.storeNameColumn === 'Exclude' ? 'hide-table-cell ' : '')} >
          {
            (!disable)
              ? <a onClick={() => this.props.handleSort(header, table)} className='dashboardHeaders'>
                <span>{t(translationKeysMap[header] || header)}</span>
                <img src={value} className={'sortArrows ' + (!value ? 'hidden' : '')} alt='arrow' />
              </a>
              : <span>{t(translationKeysMap[header] || header)}</span>
          }
        </th>)
    })
  }
}

class DashboardContent extends React.Component {
  renderTableCell (item, columns) {
    const { t } = this.props;
    const tableIndex = this.props.tableIndex
    const Preferences = this.props.Preferences
    const realTime = this.props.realTime
    const responseType = this.props.ResponseType
    const eventType = this.props.eventType
    const timeformat = Preferences.timeFormat === 'mmss' ? DateLib.FORMAT_TYPES.MINUTES_SECONDS : DateLib.FORMAT_TYPES.SECONDS;
    const colorPreferences = (this.props.Preferences && this.props.Preferences.colors ? this.props.Preferences.colors.split('|') : '')
    const foramtText = (text) => {
      if (text) {
        if (text.length > 8) {
          let formattedText = text.substring(0, 8) + '...'
          return formattedText
        } else {
          return text
        }
      } else {
        return text
      }
    }
    return columns.map((col) => {
      let value = ''
      let storeRankURL = ''
      let textColor = { backgroundColor: '', color: '' }
      if ((col !== 'storeNumber' && col !== 'storeName') && item['storeRank'] !== 'offline') {
        if (responseType === 'custom') {
          /// Custom zoomdashboard handle coloring
          if (tableIndex === 1) {
            if (col === 'avg' && item[col] < item.GoalA) {
              textColor = { backgroundColor: colorPreferences[0], color: '#fff' }
            } else if (col === 'avg' && item[col] < item.GoalB) {
              textColor = { backgroundColor: colorPreferences[1], color: '#fff' }
            } else if (col === 'avg' && item[col] >= item.GoalB) {
              textColor = { backgroundColor: colorPreferences[2], color: '#fff' }
            }
          } else {
            if (col === 'avg' && item[col] < item.GoalA) {
              textColor = { color: colorPreferences[0] }
            } else if (col === 'avg' && item[col] < item.GoalB) {
              textColor = { color: colorPreferences[1] }
            } else if (col === 'avg' && item[col] >= item.GoalB) {
              textColor = { color: colorPreferences[2] }
            }
          }
        } else if (realTime) {
          if (item.type === 'previous') {
            if (col === 'avg') {
              textColor = { color: item.goalColor }
            }
          } else if (item.type === 'current') {
            textColor = { backgroundColor: item.goalColor, color: '#fff' }
          }
        } else {
          /// if not custom then zoom dashboard coloring
          if (item.type === 'previous') {
            if (col === 'avg' && item[col] < item.GoalA) {
              textColor = { color: colorPreferences[0] }
            } else if (col === 'avg' && item[col] < item.GoalB) {
              textColor = { color: colorPreferences[1] }
            } else if (col === 'avg' && item[col] >= item.GoalB) {
              textColor = { color: colorPreferences[2] }
            }
          } else if (item.type === 'current') {
            if (item['avg'] && (item['avg'] < item.GoalA)) {
              textColor = { backgroundColor: colorPreferences[0], color: '#fff' }
            } else if (item['avg'] && (item['avg'] < item.GoalB)) {
              textColor = { backgroundColor: colorPreferences[1], color: '#fff' }
            } else if (item['avg'] && (item['avg'] >= item.GoalB)) {
              textColor = { backgroundColor: colorPreferences[2], color: '#fff' }
            }
          }
        }
        value = item[col]
      } else {
        if (item['storeRank'] !== 'offline' && item['storeRank'] !== 'Unavailable' && (col === 'storeNumber' || col === 'storeName')) {
          storeRankURL = `/dashboard/storeRanking?suid=${item.storeUID}&report=${responseType}&metrics=${eventType}`
        }
        
        if (col === 'storeRank') {
          value = t(item[col]);
        } else if (col === 'storeName') {
          value = foramtText(item[col])
        } else {
          value = item[col]
          textColor = { backgroundColor: '', color: 'black' }
        }
      }

      // storeRank online/offline statuses should be translated for any case 
      if (col === 'storeRank') {
        if (item['storeRank'] === 'online') {
          value = t('common__device__status--online');
        }

        if (item['storeRank'] === 'offline') {
          value = t('common__device__status--offline');
        }
      }
      
      if (col === 'avg' || (tableIndex === 0 && col === 'percent')) {
        value = timeformat === DateLib.FORMAT_TYPES.MINUTES_SECONDS ? new DateLib(item[col] * 1000).toUTC().format(timeformat) : item[col];
      }
      const selected = (col === 'avg')
      const hidecell = (col === 'storeName' && Preferences.storeNameColumn === 'Exclude')
      let className = (hidecell ? 'hide-table-cell ' : '') + (selected ? 'sort_col ' : '')
      if (storeRankURL !== '') {
        className = 'strong ' + (hidecell ? 'hide-table-cell ' : '') + (selected ? 'sort_col ' : '')
      }
      if (storeRankURL !== '') {
        return (
          <td style={textColor}
            className={className} >
              <SimpleToolTip className="dashboard-tooltip" text={item[col]}>
                <a href={storeRankURL} >
                  <span >{value}</span>
                </a>
              </SimpleToolTip>
          </td>
        )
      } else {
        return (
          <td style={textColor} className={className} >
            <span>{value}</span>
          </td>
        )
      }
    })
  }

  render () {
    let dashboardItem = this.props.DashboardItem
    let columns = dashboardItem.columns
    return dashboardItem.rows.map((item) => {
      return (<tr >
        {this.renderTableCell(item, columns)}
      </tr>)
    })
  }
}

class DashboardTable extends React.Component {
  render () {
    const { t } = this.props;
    let dashboardItem = this.props.dashboardItem
    let Preferences = this.props.Preferences
    let sortParam = this.props.sortParams
    const tableIndex = this.props.tableIndex
    const ResponseType = this.props.ResponseType
    const tablecount = this.props.tablecount
    const eventType = this.props.eventType
    const realTime = this.props.realTime
    let coltable = tablecount <= 3 ? 4 : 3
    let selectedIndex = 1
    dashboardItem.columns.map((item, index) => {
      if (item === sortParam.sortBy) {
        selectedIndex = index
      }
    })
    function capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
    let title = '';
    if (ResponseType === 'custom') {
      title = (tableIndex === 0 ? 'common__stores' : translationKeysMap[dashboardItem.title] || dashboardItem.title)
    } else {
      if (tableIndex === 1) {
        title = `common__current-period--${ResponseType}`;
        dashboardItem.title = title;
      } else if (tableIndex === 2) {
        title = `common__previous-full-period--${ResponseType}`;
        dashboardItem.title = title;
      } else {
        title = 'common__stores'
        dashboardItem.title = title;
      }
    }
    let selected = (sortParam.period === dashboardItem.title.trim().toLowerCase())
    const colGroup = (tableIndex) => {
      if (tableIndex === 0) {
        return (
          <colgroup>
            <col className='notSortCol' />
            <col className='notSortCol' />
            <col className='notSortCol' />
            <col className='notSortCol' />
          </colgroup>
        )
      } else {
        return (
          <colgroup>
            <col className={selected && selectedIndex === 0 ? 'thisSortCol' : 'notSortCol'} />
            <col className={selected && selectedIndex === 1 ? 'thisSortCol' : 'notSortCol'} />
            <col className={selected && selectedIndex === 2 ? 'thisSortCol' : 'notSortCol'} />
          </colgroup>
        )
      }
    }

    return (
      <Col
        // sm={(Preferences.storeNameColumn === 'Include' && dashboardItem.title === 'stores' && coltable === 4) ? '5' : (Preferences.storeNameColumn === 'Include' && dashboardItem.title !== 'stores' && coltable === 4 ? '3-5' : coltable)}
        // xs={(Preferences.storeNameColumn === 'Include' && dashboardItem.title === 'stores' && coltable === 4) ? '5' : (Preferences.storeNameColumn === 'Include' && dashboardItem.title !== 'stores' && coltable === 4 ? '3-5' : coltable)}
        // lg={(Preferences.storeNameColumn === 'Include' && dashboardItem.title === 'stores' && coltable === 4) ? '5' : (Preferences.storeNameColumn === 'Include' && dashboardItem.title !== 'stores' && coltable === 4 ? '3-5' : coltable)}
        // md={(Preferences.storeNameColumn === 'Include' && dashboardItem.title === 'stores' && coltable === 4) ? '5' : (Preferences.storeNameColumn === 'Include' && dashboardItem.title !== 'stores' && coltable === 4 ? '3-5' : coltable)}
        className={'border zeroPadding ' + (selected ? 'currentSelected' : '') + ((Preferences.storeNameColumn === 'Include' && dashboardItem.title === 'stores' && coltable === 4) ? ' include_StoreName' : (Preferences.storeNameColumn === 'Include' && dashboardItem.title !== 'stores' && coltable === 4 ? ' no_StoreName' : ' dashboard_Tables'))}>
        <div className='zeroPadding dashboard-title StoreRankingSubHeader'>{t(title, { period: capitalizeFirstLetter(ResponseType || '')})}</div>
        <table className='chartTable zoomDashboard'>
          {colGroup(tableIndex)}
          <thead>
            <tr className=''>
              <DashboardHeaders DashboardItem={dashboardItem}
                sortParams={sortParam}
                disable={this.props.disable} Preferences={Preferences}
                selected handleSort={this.props.handleSort} tableIndex={tableIndex}
                t={t} />
            </tr>
          </thead>
          <DashboardContent DashboardItem={dashboardItem} disable={this.props.disable}
            Preferences={Preferences} selected sortParams={sortParam} tableIndex={tableIndex}
            ResponseType={ResponseType} eventType={eventType} realTime={realTime} t={t} />
        </table>
      </Col>
    )
  }
}

class Dashboard extends React.Component {
  getSortParam () {
    let sortParams = {}
    if (this.props.ResponseType === 'custom' && this.props.sortParams === undefined && this.props.sortParams === null) {
      const Preference = this.props.Preferences
      sortParams.sortType = Preference.defaultCustomAscType ? Preference.defaultCustomAscType.split(' | ')[1] : ''
      sortParams.sortBy = Preference.defaultCustomAscType ? Preference.defaultCustomAscType.split(' | ')[0].substr(2).toLowerCase() : ''
      const id = Preference.defaultCustomAscType ? Preference.defaultCustomAscType.split(' | ')[0].substr(0, 2) : ''
      Preference.columnOrder.map((item) => {
        if (item.id === id) {
          sortParams.period = item.title.toLowerCase()
        }
      })
    } else if (!this.props.sortParams.period || this.props.sortParams.period === 'undefined') {
      sortParams.period = `current ${this.props.ResponseType}`
      sortParams.sortBy = 'avg'
      sortParams.sortType = 'ASC'
    } else {
      sortParams = this.props.sortParams
    }
    return sortParams
  }
  render () {
    const { t } = this.props;
    const sortParams = this.getSortParam()
    let dashboardData = this.props.dashboardData
    let ResponseType = this.props.ResponseType
    const eventType = this.props.eventType
    const realTime = this.props.realTime
    if (dashboardData && dashboardData.length > 0) {
      return dashboardData.map((item, i) => {
        if (item.title !== null) {
          return <DashboardTable dashboardItem={item}
            sortParams={sortParams}
            tableIndex={i}
            disable={(i === 0)}
            tablecount={dashboardData.length}
            Preferences={this.props.Preferences}
            handleSort={this.props.handleSort}
            ResponseType={ResponseType}
            eventType={eventType}
            realTime={realTime}
            t={t} />
        } else {
          return <div />
        }
      })
    } else return <div />
  }
}

function mapStateToProps (state) {
  return {
    sortParams: state.dashboard.sortParams,
    dashboardData: state.dashboard.dashboard,
    userPreferences: state.dashboard.userPreferences,
    reportResponseType: state.dashboard.reportResponseType,
    currentDayData: state.dashboard.currentDayData,
    reason: state.dashboard.key,
    realTime: state.dashboard.realTime,
    rtIncludePullins: state.dashboard.IncludePullIns
  }
}

function matchDispatchToProps (dispatch) {
  return bindActionCreators({
    setSortParams: dashboard.setSortParams,
    getTimeMeasure: dashboard.getTimeMeasure
  }, dispatch)
}

const DashboardComponentWithTranslation = compose(
  withTranslation()
)(DashboardComponent);

export default connect(mapStateToProps, matchDispatchToProps)(DashboardComponentWithTranslation)
