import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useOnTrigger } from 'hooks/useTrigger';

import { formatDeviceCustomerNumberKey } from 'helpers/formatDeviceCustomerNumberKey';
import { Grid, resetFilters, getFiltersCount } from 'components/Common/Grid';

import { Actions } from './Actions';
import { Subscriptions } from './Subscriptions';
import { UserEmails } from '../../../Common/UserEmails';
import { StoreSerialNumbers } from '../../../StatusPage/Common/List/StoreSerialNumbers';
import { Statuses } from './Statuses';
import { DeviceVersions } from './DeviceVersions';
import { DeviceProductIDs } from './DeviceProductIDs';
import { adminStoresHeaders } from '../constants/adminStoresHeaders';

import './List.scss';

const getRows = (storeData) =>  ({
    ...storeData,
    User_EmailAddress: <UserEmails store={storeData} />,
    Subscription_Name: <Subscriptions nitroSubcription={storeData.Subscription_Name} tierSubscriptions={storeData.System_Tiers} />,
    Actions: <Actions suid={storeData.Store_UID} />,
    Device_SerialNumber: <StoreSerialNumbers devices={storeData.Device_Details} />,
    Device_MainVersion: <DeviceVersions devices={storeData.Device_Details} />,
    Device_IsActive: <Statuses devices={storeData.Device_Details} />,
    Device_Product_ID: <DeviceProductIDs devices={storeData.Device_Details} />,
    customerNumberKey: formatDeviceCustomerNumberKey(storeData.Store_AX_Customer_Account, storeData.Store_RegistrationKey),
});

export const List = ({
    stores,
    isLoading,
    sortSelection,
    onSortChange,
    filters,
    availableFilters,
    resetFiltersTrigger,
    onFiltersChange,
    onFiltersCountChange
}) => {
    const [rows, setRows] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (stores.length) {
            const newRows = stores.map((store) => getRows(store));
            setRows(newRows);
        } else {
            setRows([]);
        }
    }, [stores, setRows]);

    const onGridFiltersChange = useCallback((newFilters) => {
        onFiltersChange?.(newFilters);
    }, [onFiltersChange]);

    const onFiltersReset = useCallback(() => {
        resetFilters(availableFilters, onFiltersChange);
    }, [availableFilters, onFiltersChange]);

    useOnTrigger(resetFiltersTrigger, onFiltersReset);

    useEffect(() => {
        onFiltersCountChange && onFiltersCountChange(getFiltersCount(availableFilters, filters));
    }, [availableFilters, filters]);

    return (
        <Grid
            headers={adminStoresHeaders}
            rows={rows}
            rowKey="Store_ID"
            className="hme-stores-status--admin__grid"
            noRecordsMessage={t('common__no-stores--found')}
            isLoading={isLoading}
            onSortChange={onSortChange}
            sortSelection={sortSelection}
            filters={filters}
            availableFilters={availableFilters}
            onFiltersChange={onGridFiltersChange}
        />
    );
};

List.propTypes = {
    stores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isLoading: PropTypes.bool,
    sortSelection: PropTypes.shape({}).isRequired,
    onSortChange: PropTypes.func.isRequired,
    filters: PropTypes.shape({}).isRequired,
    onFiltersChange: PropTypes.func.isRequired
};
