import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tip } from 'library/Tip';

import { ReactComponent as AlertSign } from 'assets/icons/alert.svg';
import { ReactComponent as ErrorSign } from 'assets/icons/errorIcon.svg';

const ExpiredDateTokenCell = ({ expiryDate }) => {
    const { t } = useTranslation();

    return (
        <div className="hme-token-management__expiration-cell hme-token-management__expiration-cell--expired">
            {expiryDate}
            <Tip clickable={false} Icon={ErrorSign} iconClassName="hme-token-management__cell-icon">
                {t('admin__token-management__token-expiration-date--expired')}
            </Tip>
        </div>
    );
};

const WithinMonthBeforeExpirationDateTokenCell = ({ expiryDate }) => {
    const { t } = useTranslation();

    return (
        <div className="hme-token-management__expiration-cell hme-token-management__expiration-cell--within-month-expired">
            {expiryDate}
            <Tip clickable={false} Icon={AlertSign} iconClassName="hme-token-management__cell-icon">
                {t('admin__token-management__token-expiration-date--about-to-expire')}
            </Tip>
        </div>
    );
};

const expirationDateTokenCellTypes = {
    'expired': ExpiredDateTokenCell,
    'within-month-before-expiration': WithinMonthBeforeExpirationDateTokenCell
};

export const ExpiryDateCell = ({ expirationType, expiryDate }) => {
    const DateCell = expirationDateTokenCellTypes[expirationType];

    if (DateCell) return <DateCell expiryDate={expiryDate} />;

    return <div className="hme-token-management__expiration-cell">{expiryDate} <div className="hme-token-management__cell-icon" /></div>;
};
