import React from 'react';
import { useTranslation } from 'react-i18next';

import { Config } from 'Config';
import { Title } from 'library/Title';
import { Button, BUTTON_VARIANTS } from 'library/Button';
import { InfoBox, VARINATS } from 'library/InfoBox';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import './Header.scss';

const buttonVariants = [BUTTON_VARIANTS.TRANSPARENT];
const infoBoxVariants = [VARINATS.INFO];

export const Header = ({ title }) => {
    const { t } = useTranslation();

    return (
        <div className="hme-manage-hierarchy__header">
            <div className="hme-manage-hierarchy__header__content-container">
                <Title>{t(title)}</Title>
                <Button
                    className="hme-manage-hierarchy__header__user-guide"
                    as="a"
                    variants={buttonVariants}
                    href={t('stores-hierarchy__store-hierarchy-guide-url',  { url: Config.trainingPortalBaseDocURL })}
                    target="_blank"
                    rel="noopener"
                >
                    {t('stores-hierarchy__store-hierarchy-guide')}
                </Button>
            </div>
            <InfoBox
                Icon={InfoIcon}
                className="hme-manage-hierarchy__header__info-box"
                variants={infoBoxVariants}
                message="stores-hierarchy__store-hierarchy-guide-info"
            />
        </div>
    );
};
