import { useCallback, useEffect, useMemo } from 'react';

import { useLocalStorage } from 'hooks/useLocalStorage';
import { CUSTOMER_NOTIFICATION_TYPE_IDS, LOCAL_STORAGE_KEYS } from 'constants/customerNotifications';

import { useCurrentText } from './useCurrentText';
import { getNotificationByTypeId } from '../helpers';
import { useCustomerNotificationContext } from '../context';

const { CUSTOMER_NOTIFICATION } = LOCAL_STORAGE_KEYS;

const INITIAL_CUSTOMER_NOTIFICATION_STATE = {
    isHidden: false,
    closedNotificationUpdatedAt: 0
};

export const useBannerCustomerNotification = () => {
    const { enabledNotifications } = useCustomerNotificationContext();
    const [bannerState, setBannerState] = useLocalStorage(CUSTOMER_NOTIFICATION, INITIAL_CUSTOMER_NOTIFICATION_STATE);

    const { isHidden, closedNotificationUpdatedAt } = bannerState;

    const bannerNotification = useMemo(() => {
        if (!enabledNotifications) return null;

        return getNotificationByTypeId({
            notificationTypeId: Number(CUSTOMER_NOTIFICATION_TYPE_IDS.banner),
            notifications: enabledNotifications
        });
    }, [enabledNotifications]);

    const text = useCurrentText(bannerNotification);

    const hideBannerWithNoData = useCallback(() => {
        setBannerState((currentBannerState) => ({
            ...currentBannerState,
            isHidden: true
        }));
    }, []);

    const handleHideBanner = useCallback(() => {
        setBannerState((currentBannerState) => ({
            ...currentBannerState,
            closedNotificationUpdatedAt: new Date(bannerNotification.updatedAt).getTime(),
            isHidden: true
        }));
    }, [bannerNotification]);

    useEffect(() => {
        // when request is finished and no enable notification found
        if (!bannerNotification) {
            hideBannerWithNoData();

            return;
        }

        const updatedAtTimestamp = new Date(bannerNotification.updatedAt).getTime();
        if (updatedAtTimestamp > closedNotificationUpdatedAt) {
            setBannerState(INITIAL_CUSTOMER_NOTIFICATION_STATE);
        }
    }, [closedNotificationUpdatedAt, bannerNotification]);

    return {
        text,
        isHidden,
        handleHideBanner
    };
};
