/* eslint-disable complexity */
import React, { useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';
import { Section } from 'library/Section';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';
import { Grid } from 'components/Common/Grid';
import { gridOptions } from './constants';
import { DEVICE_ACTIONS } from '../../../Common/constants';
import { prepareDevices, getAvailableDeviceUpgradeVersions, checkScheduledUpgrade } from './helpers';

import './DevicesSection.scss';

export const DevicesSection = ({
    device,
    deviceSettings,
    deviceRecentUpgradeTasks,
    deviceUpgradeVersions,
    onDeviceVersionUpgrade,
    deviceScheduledUpgrade,
    onLinkedDeviceReboot,
    onLinkedDeviceReconnect,
}) => {
    const { t } = useTranslation();
    const [rows, setRows] = useState([]);
    const [isConfirmShown, setConfirmShown] = useState(false);
    const [confirmWindowDetails, setConfirmWindowDetails] = useState({});

    const getActionDetails = useCallback(
        (action, { linkedDevice, device }) => {
            const actionDetails = {
                [DEVICE_ACTIONS.RECONNECT]: {
                    message: t('system-status__action__reconnect--confirm-message'),
                    handler: () => onLinkedDeviceReconnect(linkedDevice, device),
                },
                [DEVICE_ACTIONS.REBOOT]: {
                    message: t('system-status__action__reboot--confirm-message'),
                    handler: () => onLinkedDeviceReboot(linkedDevice, device),
                },
            };

            return actionDetails[action];
        },
        [onLinkedDeviceReconnect, onLinkedDeviceReboot],
    );

    const onConfirmHide = useCallback(() => {
        setConfirmShown(false);
    }, [setConfirmShown]);

    const showConfirm = useCallback(
        (action, data) => {
            setConfirmWindowDetails(getActionDetails(action, data));
            setConfirmShown(true);
        },
        [getActionDetails],
    );

    const onLinkedDeviceReconnectTrigger = useCallback(
        (data) => showConfirm(DEVICE_ACTIONS.RECONNECT, data),
        [showConfirm],
    );
    const onLinkedDeviceRebootTrigger = useCallback((data) => showConfirm(DEVICE_ACTIONS.REBOOT, data), [showConfirm]);

    useEffect(() => {
        const availableDeviceUpgradeVersions = getAvailableDeviceUpgradeVersions({ device, deviceUpgradeVersions });
        const preparedDevices = prepareDevices({
            device,
            deviceSettings,
            availableDeviceUpgradeVersions,
            deviceRecentUpgradeTasks,
            onDeviceVersionUpgrade,
            deviceScheduledUpgrade,
            onLinkedDeviceRebootTrigger,
            onLinkedDeviceReconnectTrigger,
            t,
        });
        setRows(preparedDevices);
    }, [device, setRows]);

    return (
        <Section className="hme-admin-zoom-system-status-devices">
            <div className="hme-admin-zoom-system-status-devices-headline">
                <Title>{t('system-status__title__system-devices')}</Title>
            </div>
            <Grid
                rows={rows}
                rowKey="serialNumber"
                isLoading={false}
                headers={gridOptions}
                noRecordsMessage={t('system-status__title__system-devices__no-devices--found')}
                expandable={true}
            />
            <ConfirmPopupComponent
                show={isConfirmShown}
                message={confirmWindowDetails.message}
                onHide={onConfirmHide}
                onConfirm={() => confirmWindowDetails.handler()}
            />
        </Section>
    );
};
