import React from 'react';

import { storeHierarchyNodeTypes } from 'constants/storeHierarchy';
import { Trigger } from 'hooks/useTrigger';

import { TreeText } from '../TreeText';

export const getStoresTree = (response, TextComponent = TreeText) => {
    return response.map((item) => {
        const result = {
            text: <TextComponent item={item} />,
            item,
            onExpand: new Trigger(),
        };

        if (item.Type === storeHierarchyNodeTypes.STORE) {
            result.value = item.StoreUid;
            result.key = item.StoreUid;
        }

        if (item.Type === storeHierarchyNodeTypes.GROUP) {
            result.key = `Group_${item.Id}`;
        }

        if (item.Children && item.Children.length) {
            result.children = getStoresTree(item.Children, TextComponent);
        }

        return result;
    });
};
