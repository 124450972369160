import React from 'react';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';

import './DeviceUpgradeStatusCell.scss';

export const DeviceUpgradeStatusCell = ({ status = '', className = '' }) => {
    const { t } = useTranslation();

    return (<span className={classNames('admin-system-status-cell', className)}>{t(status)}</span>);
};
