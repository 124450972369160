import { useParams } from 'react-router-dom';

import { CommonConstants } from 'Constants';

import { ContestDownloadButton } from '../../../../Common/ContestDownloadButton';

import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';

import './Results.scss';

export const Results = ({ contestName }) => {
    const { uid } = useParams();

    return (
        <div className='hme-view-edit-contest--results__header__results'>
            <UploadIcon className='hme-view-edit-contest--results__header__download-icon' />
            <ContestDownloadButton
                className='hme-contest-actions__download-results'
                formats={CommonConstants.fileFormats}
                uid={uid}
                contestName={contestName}
            />
        </div>
    )
};
