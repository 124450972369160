import { useCallback, useEffect, useMemo, useState } from 'react';

import { getDistributorAccounts } from 'services/DistributorAccounts';
import { getAccountsRows } from '../helpers/getAccountsRows';
import { getFiltersCount, useAvailableFilters } from 'components/Common/Grid';
import { useSearchWithDebounce } from 'hooks/useSearchWithDebounce';

import { ACCOUNTS_FILTER_OPTIONS } from '../constants/accountsFilterOptions';
import { INIT_PAGINATION_VALUES } from '../constants/initialPaginationValues';
import { getShowedRows } from '../helpers/getShowedRows';
import { INITIAL_SORT_SELECTION } from '../../constants/initialSortSelection';
import { ACCOUNTS_SEARCH_PROPERTIES } from '../constants/accountsSearchProperties';

const INIT_SEARCH_VALUES = { selectValue: 'User_EmailAddress', searchValue: '' };

const getAccountsFromApi = async (setAccountsLoading, setAccountsList, setRecordsTotal) => {
    try {
        setAccountsLoading(true);
        const listOfAccountsFromDB = await getDistributorAccounts();
        setAccountsList(listOfAccountsFromDB);
        setRecordsTotal(listOfAccountsFromDB.length);
    } catch (error) {
        setAccountsList([]);
    }

    setAccountsLoading(false);
};

export const useDistributorsAccounts = () => {
    const [accountsList, setAccountsList] = useState([]);
    const [rows, setRows] = useState([]);
    const [showedRows, setShowedRows] = useState([]);
    const [isAccountsLoading, setAccountsLoading] = useState(false);
    const [sortSelection, setSortSelection] = useState(INITIAL_SORT_SELECTION);
    const [filters, setFilters] = useState({});
    const [availableFilters, setAvailableFilters] = useState([]);
    const [searchParamsBy, setSearchParamsBy] = useState(INIT_SEARCH_VALUES);
    const [searchParams, onSearchParamsChange] = useState(INIT_SEARCH_VALUES);
    const [filtersCount, setFiltersCount] = useState(0);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [paginationData, setPaginationData] = useState(INIT_PAGINATION_VALUES);

    useEffect( () => {
        getAccountsFromApi(setAccountsLoading, setAccountsList, setRecordsTotal);
    }, []);

    useEffect(() => {
        const accountsToRow = getAccountsRows(accountsList);
        setRows(accountsToRow);
    }, [accountsList, setRows]);

    useEffect(() => {
        const { total, newShowedRows } = getShowedRows(
            rows,
            {
                searchParamsBy,
                filters,
                sortSelection,
                paginationData,
                filtersOptions: ACCOUNTS_FILTER_OPTIONS,
                searchProperties: ACCOUNTS_SEARCH_PROPERTIES
            }
        );
        setShowedRows(newShowedRows);
        setRecordsTotal(total);
    }, [rows, searchParamsBy, filters, sortSelection, paginationData, setRecordsTotal, setShowedRows]);

    const onSearch = useCallback((newSearchParams) => {
        setSearchParamsBy(newSearchParams);
        setPaginationData(INIT_PAGINATION_VALUES);
    }, [setPaginationData]);

    useEffect(() => {
        const newFiltersCount = getFiltersCount(availableFilters, filters);
        setFiltersCount(newFiltersCount);
    }, [availableFilters, filters]);

    const isDisabled = useMemo(() => {
        return isAccountsLoading || !rows.length;
    }, [isAccountsLoading, rows]);

    const onSortChange = useCallback((newSortData) => {
        if (isAccountsLoading) {
            return;
        }

        setSortSelection(newSortData);
    }, [isAccountsLoading, setSortSelection]);

    const onPaginateChange = useCallback(({ page, recordsPerPage }) => {
        const newPaginationData = { pageSize: recordsPerPage, pageNumber: page };
        setPaginationData(newPaginationData);
    }, [setPaginationData]);

    const onFiltersReset = useCallback(() => {
        setFiltersCount(0);
        const accountsToRow = getAccountsRows(accountsList);
        setRows(accountsToRow);
    }, [setFiltersCount, accountsList, setAccountsList, setRows]);

    const { onSearchChangeHandler, onSearchHandler } = useSearchWithDebounce(onSearch, onSearchParamsChange);

    const onFiltersChange = useCallback((newFilters) => {
        setFilters(newFilters);
    }, [setFilters]);

    useAvailableFilters(ACCOUNTS_FILTER_OPTIONS, rows, onFiltersChange, setAvailableFilters);

    const onFiltersAndSortReset = useCallback(() => {
        onSortChange(INITIAL_SORT_SELECTION);
        onFiltersReset();
    }, [onSortChange, onFiltersReset]);

    return {
        onSearchHandler,
        isDisabled,
        sortSelection,
        onSortChange,
        rows: showedRows,
        isAccountsLoading,
        availableFilters,
        filters,
        onFiltersChange,
        searchParams,
        onSearchChangeHandler,
        recordsTotal,
        paginationData,
        onPaginateChange,
        filtersCount,
        onFiltersReset,
        onFiltersAndSortReset
    };
};
