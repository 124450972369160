import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Label } from 'library/Label';
import { Button } from 'library/Button';


import './Admin.scss';

export const Admin = ({ className, ...loginFormProps }) => {
    const { t, i18n } = useTranslation();
    const { onLogin } = loginFormProps;
    const onFormSubmit = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();

        onLogin && onLogin();
    }, [onLogin]);

    useEffect(() => {
        i18n.changeLanguage('en-US');
    }, [i18n]);

    return (
        <div className={classNames('hme-admin-login-page', className)}>
            <Button
                className="hme-submit-button-component hme-admin-login-page__submit"
                onClick={onFormSubmit}
            >
                {t('admin__login__button')}
            </Button>
            <Label className="hme-admin-login-page__notification">{t('login__admin__notification')}</Label>
        </div>
    );
};
