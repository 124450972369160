import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { deleteDataAsync, getDataAsync, getSearchString } from 'Api';
import AuthenticationService from '../components/Security/AuthenticationService';

const authService = new AuthenticationService(Config.authBaseUrl);

export const getDistributorAccounts = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDistributorAccounts}`;

    const response = await getDataAsync(url);
    const { status, accounts } = response;

    if (!status) {
        throw new Error('Request failed');
    }

    return accounts;
};

export const getDistributorAccountInfo = async (params) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDistributorAccount}`;

    const searchString = getSearchString(params);

    const response = await getDataAsync(`${url}?${searchString}`);
    const { status, data } = response;

    if (!status) {
        throw new Error('Request failed');
    }

    return data[0];
};

export const removeDistributorUser = async (params) => {
    const searchString = getSearchString(params);
    const isMasquerade = authService.isMasquerade();
    let adminUser = '';
    if (isMasquerade) {
        const adminUserName = authService.getAdminProfile().unique_name;
        adminUser = `&adminUser=${adminUserName}`;
    }
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.deleteDistributorUserFromAccount}?${searchString}${adminUser}`;
    const response = await deleteDataAsync(url, params);
    const { status, key } = response;

    if (!status) {
        throw new Error('Request failed');
    }

    return key;
};
