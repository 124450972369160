import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { navigationService } from '../../Common/NavigationServices';
import { getAdminReportsTab } from './getAdminReportsTab';
import { ReportsMenu } from '../ReportsMenu/ReportsMenu';
import { speedOfServiceMenuItems } from '../ReportsMenu/MenuItems';
import { ReactComponent as DropdownIcon } from 'assets/icons/down.svg';

const AdminReportsSubHeader = () => {
    const { t } = useTranslation();
    const [showSpeedOfServiceMenu, setShowSpeedOfServiceMenu] = useState(false);
    const getSelectedMenu = navigationService.getSelectedMenu();
    const menus = getAdminReportsTab();
    const captions = {
        'outliersReports': t('admin__hme-reports__sub-header--outliers'),
        'ghostCarsReport': t('admin__hme-reports__sub-header--ghost-cars'),
        'speedOfService': t('admin__hme-reports__sub-header--speed-of-service'),
        'storeStatus': t('admin__hme-reports__sub-header--store-status')
    };
    const active = (menu) => getSelectedMenu === menu ? 'active_tab_sub' : '';

    return (menus.includes(getSelectedMenu) &&
        <div>
            <div className="subMenu menuBar ">
                <div className="subHeaderSection">
                    <ul className="subHeaders">
                        {menus.map((menu, index) => (
                            menu === 'speedOfService' ?
                            <li key={index}
                                onMouseEnter={() => setShowSpeedOfServiceMenu(true)}
                                onMouseLeave={() => setShowSpeedOfServiceMenu(false)}
                            > <a className={'headerMenu ' + active(menu)}
                                >
                                    {captions[menu]}
                                    <DropdownIcon className="hme-single-select__box__icon" />
                                </a>
                                { showSpeedOfServiceMenu && <ReportsMenu menuItems={speedOfServiceMenuItems}/> }
                            </li>
                            : <li key={index}>
                                <a
                                    className={'headerMenu ' + active(menu)}
                                    href={navigationService.getSubMenuUrl(menu)}
                                >{captions[menu]}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AdminReportsSubHeader;
