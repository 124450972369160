const distributorOnlyProperties = ['registrationKey'];
export const allSearchOptions = [
    {
        text: 'common__brand',
        value: 'Brand_Name',
        placeholder: 'stores__search-placeholder--brand'
    },
    {
        text: 'common__store__number',
        value: 'Store_Number',
        placeholder: 'stores__search-placeholder--store-number'
    },
    {
        text: 'common__store__name',
        value: 'Store_Name',
        placeholder: 'stores__search-placeholder--store-name'
    },
    {
        text: 'common__registration-key',
        value: 'registrationKey',
        placeholder: 'stores__search-placeholder--registration-key',
    },
    {
        text: 'common__device__serial-number',
        value: 'DeviceSerialNumbers',
        placeholder: 'stores__search-placeholder--serial-number'
    },
    {
        text: 'common__device__product-id',
        value: 'DeviceProductIDs',
        placeholder: 'admin__systems__search__placeholder--product-id'
    },
    {
        text: 'common__system__version',
        value: 'DeviceVersions',
        placeholder: 'stores__search-placeholder--system-version'
    },
    {
        text: 'common__all',
        value: '',
        placeholder: 'stores__search-placeholder--all'
    }
];

export const searchOptionsUser = allSearchOptions.filter(option => !distributorOnlyProperties.includes(option.value));
export const searchOptionsDistributor = allSearchOptions;