import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'library/Button';
import { Grid } from 'components/Common/Grid';

import './FeedsSection.scss';

const gridOptions = [
    {
        text: 'common__dxs-feeds',
        property: 'serviceAccountName',
        flex: 40,
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__brand',
        property: 'brandName',
        flex: 40,
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__actions',
        property: 'Actions',
        flex: 10,
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
];

const feedsToRows = ({ feeds, onFeedUnlink, t }) =>
    feeds.map((feed) => ({
        ...feed,
        serviceAccountName: <Link to={`./../../feeds/${feed.serviceAccountID}`}>{feed.serviceAccountName}</Link>,
        Actions: (
            <Button onClick={() => onFeedUnlink(feed.serviceAccountID)} variants={['transparent']}>
                {t('common__remove')}
            </Button>
        ),
    }));

export const FeedsSection = ({ feeds = [], isLoading, onFeedUnlink }) => {
    const [rows, setRows] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        setRows(feedsToRows({ feeds, onFeedUnlink, t }));
    }, [feeds]);

    return (
        <div className="dxs-account-feeds-section">
            <Grid
                headers={gridOptions}
                rows={rows}
                isLoading={isLoading}
                rowKey="serviceAccountID"
                noRecordsMessage={t('dxs_feeds__no-feeds-found')}
            />
        </div>
    );
};
