import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from 'library/Section';
import { InfoField } from 'library/InfoField';

export const HmeDetails = ({ infoData, distributors }) => {
    const { distributorId } = infoData;

    const { t } = useTranslation();

    const distributor = useMemo(
        () =>
            distributorId
                ? (distributors || []).find(({ Company_ID }) => Company_ID === distributorId)?.Company_Name
                : null,
        [distributorId],
    );

    return (
        <Section className="company-info-view-hme-details-section" title="my-account__hme-details">
            <div className="hme-company-info-view-sections">
                {distributor && (
                    <div className="hme-company-info-view-section">
                        <InfoField label={t('common__distributor')}>{distributor}</InfoField>
                    </div>
                )}
            </div>
        </Section>
    );
};
