import React from 'react';

import { Certificate as CertificateModal } from '../../Common/CertificateContainer';
import { useContestResult } from '../../Common/hooks';

export const Certificate = ({ contestUID, onHide }) => {
    const { isLoading, contestResult, stores, selectedStores, setSelectedStores, generatePDF } = useContestResult({ uid: contestUID });

    return <CertificateModal
        isLoading={isLoading}
        storeName={contestResult?.ContestName}
        stores={stores}
        selectedStores={selectedStores}
        setSelectedStores={setSelectedStores}
        onSubmit={generatePDF}
        onHide={onHide}
    />;
};
