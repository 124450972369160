import React, { useCallback, useEffect, useState } from 'react';
import { NotificationsList } from 'library/NotificationsList';
import { Paginate } from 'library/Paginate/Paginate';
import { PAGE_DATA } from 'constants/paginationDefault';
import { Header } from './Header';
import { Footer } from './Footer';
import { UpgradesList } from './UpgradesList';
import { getScheduledDeviceUpgrades } from '../../../services/Device';
import { INIT_PAGINATION_VALUES } from '../constants';
import { scheduledUpgradesApi } from '../../../api/scheduledUpgradesApi';

import './DeviceUpgradesList.css';
import { getFiltersCount } from 'components/Common/Grid';

const loadDeviceUpgrades = async ({ setDeviceUpgrades, setIsLoading }) => {
    setIsLoading(true);

    let deviceUpgrades;
    try {
        deviceUpgrades = await getScheduledDeviceUpgrades();
    } catch (e) {
        deviceUpgrades = [];
    }

    setDeviceUpgrades(deviceUpgrades);
    setIsLoading(false);
};

export const DeviceUpgradesList = () => {
    const [search, setSearch] = useState('');
    const [deviceUpgrades, setDeviceUpgrades] = useState([]);
    const [filteredDeviceUpgrades, setFilteredDeviceUpgrades] = useState([]);
    const [paginationData, setPaginationData] = useState(INIT_PAGINATION_VALUES);
    const [isLoading, setIsLoading] = useState(true);
    const [filtersCount, setFiltersCount] = useState(0);
    const [availableFilters, setAvailableFilters] = useState([]);
    const [gridFilters, setGridFilters] = useState({});

    useEffect(() => {
        loadDeviceUpgrades({ setDeviceUpgrades, setIsLoading });
    }, []);

    const onCancelUpgrade = useCallback(async (cancelUpgradeID) => {
        setIsLoading(true);
        await scheduledUpgradesApi.cancelScheduledUpgrade(cancelUpgradeID);

        loadDeviceUpgrades({ setDeviceUpgrades, setIsLoading });
    }, []);

    const onPaginateChange = useCallback(
        ({ page, recordsPerPage }) => {
            const newPaginationData = { pageSize: recordsPerPage, pageNumber: page };
            setPaginationData(newPaginationData);
        },
        [filteredDeviceUpgrades],
    );

    const onFilterUpgrades = useCallback((upgrades) => {
        setPaginationData((oldPaginationData) => ({ ...oldPaginationData, pageNumber: INIT_PAGINATION_VALUES.pageNumber }));
        setFilteredDeviceUpgrades(upgrades);
    }, []);

    const onSearch = useCallback((value) => {
        setPaginationData((oldPaginationData) => ({ ...oldPaginationData, pageNumber: INIT_PAGINATION_VALUES.pageNumber }));
        setSearch(value);
    }, []);

    const onFiltersReset = () => {
        setFilteredDeviceUpgrades(deviceUpgrades);
        const resetFilters = { ...gridFilters };
        for (const filter of availableFilters) {
            resetFilters[filter.property] = filter.items.map((item) => item.value);
        }
        setGridFilters(resetFilters);
    };

    useEffect(() => {
        setFiltersCount(getFiltersCount(availableFilters, gridFilters));
    }, [availableFilters, gridFilters]);

    return (
        <div className="hme-page-component device-upgrade-list-wrapper">
            <NotificationsList />
            <Header onSearch={onSearch} filtersCount={filtersCount} onFiltersReset={onFiltersReset} />
            <UpgradesList
                search={search}
                deviceUpgrades={deviceUpgrades}
                filteredDeviceUpgrades={filteredDeviceUpgrades}
                isLoading={isLoading}
                onFilterUpgrades={onFilterUpgrades}
                onCancelUpgrade={onCancelUpgrade}
                paginationData={paginationData}
                gridFilters={gridFilters}
                setGridFilters={setGridFilters}
                availableFilters={availableFilters}
                setAvailableFilters={setAvailableFilters}
            />
            {!isLoading && (
                <Footer>
                    <Paginate
                        className="device-upgrades-list__paginate"
                        page={paginationData.pageNumber}
                        recordsPerPage={paginationData.pageSize}
                        pageSizes={PAGE_DATA.PAGE_SIZES_ADMIN}
                        total={filteredDeviceUpgrades.length}
                        onChange={onPaginateChange}
                        hideSinglePage
                    />
                </Footer>
            )}
        </div>
    );
};
