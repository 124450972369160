import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { InputComponent } from 'components/Inputs';
import { Title } from 'library/Title';
import { Toggle } from 'library/Toggle';
import { userProps } from '../types/userProps';
import { ErrorLabel } from 'library/ErrorLabel';
import { Label } from 'library/Label';

import './General.scss';

const inputVariants = ['label-inside-mobile-only'];

export const General = ({
    user,
    showStatus,
    isAdminView,
    onUserChange,
    validationErrors
}) => {
    const { t } = useTranslation();

    const onUserNameChange = useCallback((userName) => {
        onUserChange && onUserChange({
            ...user,
            userName
        });
    }, [user, onUserChange]);

    const onFistNameChange = useCallback((firstName) => {
        onUserChange && onUserChange({
            ...user,
            firstName
        });
    }, [user, onUserChange]);

    const onLastNameChange = useCallback((lastName) => {
        onUserChange && onUserChange({
            ...user,
            lastName
        });
    }, [user, onUserChange]);

    const onStatusChange = useCallback((isActive) => {
        onUserChange && onUserChange({
            ...user,
            isActive
        });
    }, [user, onUserChange]);

    return (
        <div className="hme-view-user__general">
            <Title variants={['block']}>{t('common__general')}</Title>
            <div className="input-container__with-error">
                <InputComponent
                    label={isAdminView ? 'add-user__label--username' : 'common__email-address'}
                    variants={inputVariants}
                    value={user.userName}
                    isRequired={true}
                    onChange={onUserNameChange}
                />
                {validationErrors.invalidEmailFormat &&
                <ErrorLabel>
                    {t(validationErrors.invalidEmailFormat)}
                </ErrorLabel>
                }
            </div>
            <InputComponent
                label="common__user__first-name"
                variants={inputVariants}
                isRequired={true}
                value={user.firstName}
                onChange={onFistNameChange}
            />
            <InputComponent
                label="common__user__last-name"
                variants={inputVariants}
                isRequired={true}
                value={user.lastName}
                onChange={onLastNameChange}
            />
            { showStatus &&
                <div className="hme-toggle-container">
                    <Label>{t('common__status')}</Label>
                    <Toggle value={user.isActive} onChange={onStatusChange}>{
                        [{
                            text: 'common__active',
                            value: 1
                        }, {
                            text: 'common__inactive',
                            value: 0
                        }]
                    }</Toggle>
                </div>
            }
        </div>
    );
};

General.propTypes = {
    user: userProps,
    showStatus: PropTypes.bool,
    isAdminView: PropTypes.bool,
    onUserChange: PropTypes.func,
    validationErrors: PropTypes.shape({
        invalidEmailFormat: PropTypes.string
    }) || {}
};
