import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';
import { Button } from 'library/Button';
import { GridFiltersCount } from 'library/GridFiltersCount';

import './Header.scss';

export const Header = ({ filtersCount, deviceType, onFiltersReset }) => {
    const { t } = useTranslation();
    return (
        <div className="select-snapshot-taskbar">
            <Title>Available {deviceType} Device Snapshots</Title>
            <div className="select-snapshot-taskbar-separated select-snapshot-taskbar-filters-count">
                <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} />
            </div>
        </div>
    );
};
