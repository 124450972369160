import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, BUTTON_VARIANTS } from 'library/Button';

const buttonVariant = [BUTTON_VARIANTS.TRANSPARENT];

export const Actions = ({ uid }) => {
    const { t } = useTranslation();

    return (
        <Button as={Link} variants={buttonVariant} to={uid}>{t('common__view-edit')}</Button>
    );
};
