export const PAGE_DATA = {
    INIT_PAGE: 0,
    INIT_RESULTS_PER_PAGE: 10, // Must match minimum pageSizes below
    PAGE_SIZES_ADMIN: [10, 20, 50, 100],
    PAGE_SIZES_PUBLIC: [10, 20, 50, 100, -1]
};

export const PAGE_DEFAULT = {
    page: PAGE_DATA.INIT_PAGE,
    recordsPerPage: PAGE_DATA.INIT_RESULTS_PER_PAGE
};
