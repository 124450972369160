import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { ADMIN_DEVICE_UPGRADES_URL } from '../constants';
import { scheduledUpgradesApi } from '../../../api/scheduledUpgradesApi';
import { formatInstallSchedulePayload } from '../helpers';

export const updatedUpgradeTask = async ({ upgradeData, upgradeTask, setIsUpgradeUpdating, navigate }) => {
    const { installRunDate, installScheduleDays, installTo, installFrom, ...upgradePayload } = upgradeData;

    const upgradeTaskData = {
        ...upgradePayload,
        upgradeStores: upgradeTask.deviceUpgrade,
        installSchedule: formatInstallSchedulePayload({
            installRunDate,
            installScheduleDays,
            installTo,
            installFrom,
        }),
    };

    setIsUpgradeUpdating(true);
    const upgradeResult = await scheduledUpgradesApi.updateDeviceUpgrade(upgradeTaskData);

    if (!(upgradeResult && upgradeResult.status)) {
        setIsUpgradeUpdating(false);
        addErrorNotification('device-upgrades__notification__upgrade-failed');

        return;
    }

    setIsUpgradeUpdating(false);
    navigate(ADMIN_DEVICE_UPGRADES_URL);
    setTimeout(() => {
        addSuccessNotification('device-upgrades__notification__upgrade-succesful');
    }, 1000);
};

export const sendCancelDeviceUpgrade = async ({ deviceUpgradeIDs, setIsDeviceUpgradeCancelling }) => {
    setIsDeviceUpgradeCancelling(true);
    const upgradeResult = await scheduledUpgradesApi.cancelDeviceUpgrade({ deviceIds: deviceUpgradeIDs });

    if (!(upgradeResult && upgradeResult.status)) {
        setIsDeviceUpgradeCancelling(false);
        addErrorNotification('device-upgrades__notification__upgrade-cancellation-failed');

        return;
    }

    setIsDeviceUpgradeCancelling(false);
};

export const declineUpgradeTask = async ({ upgradeId, message, setIsDeviceUpgradeDeclining, navigate }) => {
    setIsDeviceUpgradeDeclining(true);
    const declineResult = await scheduledUpgradesApi.declineScheduledUpgrade(upgradeId, message);

    if (!declineResult?.status) {
        setIsDeviceUpgradeDeclining(false);
        addErrorNotification('device-upgrades__notification__upgrade-decline-failed');

        return;
    }

    setIsDeviceUpgradeDeclining(false);
    navigate(ADMIN_DEVICE_UPGRADES_URL);
    setTimeout(() => {
        addSuccessNotification('device-upgrades__notification__upgrade-successfully-declined');
    }, 1000);
};

export const approveUpgradeTask = async ({ upgradeId, setIsDeviceUpgradeApproving, navigate }) => {
    setIsDeviceUpgradeApproving(true);
    const approveResult = await scheduledUpgradesApi.approveScheduledUpgrade(upgradeId);

    if (!approveResult?.status) {
        setIsDeviceUpgradeApproving(false);
        addErrorNotification('device-upgrades__notification__upgrade-approve-failed');

        return;
    }

    setIsDeviceUpgradeApproving(false);
    navigate(ADMIN_DEVICE_UPGRADES_URL);
    setTimeout(() => {
        addSuccessNotification('device-upgrades__notification__upgrade-successfully-approved');
    }, 1000);
};
