export const MOBILE_ZOOM_SNAPSHOTS_HEADERS = [
    {
        column: [
            { text: 'common__name', property: 'SettingName' },
            { text: 'common__description', property: 'Description' },
            { text: 'common__device-server-type', property: 'DeviceServerType' },
            { text: 'common__snapshot__version', property: 'SettingsVersion' },
            { text: 'common__brand', property: 'SourceBrand' },
            { text: 'common__account', property: 'SourceAccount' },
        ],
    },
    {
        column: [
            { text: 'settings_snapshots__grid__header-title--source-settings-version', property: 'SourceSettingsVersion' },
            { text: 'settings_snapshots__grid__header-title--source-version', property: 'SourceSoftwareVersion' },
            { text: 'settings_snapshots__grid__header-title--serial-number', property: 'SourceSN' },
            { text: 'common__lane', property: 'SourceLaneConfig' },
            { text: 'common__created-date-n-time', property: 'CreatedDate' },
            { text: 'common__created-by', property: 'CreatedBy' },
            { text: 'common__last-used', property: 'AppliedDate' },
            { text: 'common__last_applied_by', property: 'AppliedBy' },
        ],
    },
];

export const MOBILE_NEXEO_SNAPSHOTS_HEADERS = [
    {
        column: [
            { text: 'common__name', property: 'SettingName' },
            { text: 'common__description', property: 'Description' },
            { text: 'common__device-server-type', property: 'DeviceServerType' },
            { text: 'common__snapshot__version', property: 'SettingsVersion' },
            { text: 'common__brand', property: 'SourceBrand' },
            { text: 'common__account', property: 'SourceAccount' },
            { text: 'settings_snapshots__grid__header-title--source-settings-version', property: 'SourceSettingsVersion' },
        ],
    },
    {
        column: [
            { text: 'settings_snapshots__grid__header-title--source-version', property: 'SourceSoftwareVersion' },
            { text: 'settings_snapshots__grid__header-title--serial-number', property: 'SourceSN' },
            { text: 'common__lane', property: 'SourceLaneConfig' },
            { text: 'common__created-date-n-time', property: 'CreatedDate' },
            { text: 'common__created-by', property: 'CreatedBy' },
            { text: 'common__last-used', property: 'AppliedDate' },
            { text: 'common__last_applied_by', property: 'AppliedBy' },
        ],
    },
];
