import React, { useMemo } from 'react';
import { DateLib } from '@hme-cloud/utility-common';

import { getInnerTextFromHTMLstring } from 'library/CustomerNotificationBanner/helpers';

const dateFormat = 'dd/MM/yy h:mm a';

const getFormattedDate = (date) => date ? new DateLib(date).format(dateFormat) : '';

const Text = ({ notification }) => {
    const engItem = useMemo(() => notification.customerNotificationTexts.find(
            ({ CustomerNotificationLanguage }) => CustomerNotificationLanguage === 'en-us'
    ), [notification]);

    if (!engItem) {
        return <span />;
    }

    const parsedHtmlDocument = getInnerTextFromHTMLstring(engItem.CustomerNotificationText);

    return parsedHtmlDocument;
};

export const notificationToRow = ({
    notification
}) => ({
    ...notification,
    engText: <Text notification={notification} />,
    createdDate: getFormattedDate(notification.createdAt),
    lastEnabledDate: getFormattedDate(notification.enabledAt),
    lastDisabledDate: getFormattedDate(notification.disabledAt)
});
