import { METRICS, METRIC_DETAILS } from './metric';
import { CONTEST_TYPE_VALUES, CONTEST_STATUS_NAMES } from './contestTypes';
import { currentTimeZone } from './currentTimeZone';

export const defaultContest = {
    name: '',
    description: '',
    metric: METRICS[0].value,
    metricDetails: METRIC_DETAILS[0].value,
    status: CONTEST_TYPE_VALUES[CONTEST_STATUS_NAMES.SCHEDULED],
    reward: '',
    rewardImg: null,
    timeZone: {
        value: currentTimeZone,
        text: ''
    },
    scheduledTimePeriod: {
        from: '',
        to: '',
        periods: []
    },
    startDate: '',
    endDate: '',
    recurrenceDays: [],
    contestStores: [],
    contestStoresSearchQuery: ''
};
