import React from 'react';
import { compose } from 'ramda';
import { CommonConstants } from 'Constants';
import { withHMELayout } from 'HOCs/withHMELayout';
import { withAccess } from 'HOCs/withAccess';
import { isAdmin } from 'services/Auth';
import authenticate from 'components/Security/Authentication';
import { checkAccess } from 'actions/permissions';
import { ViewDeviceUpgrade } from '../../ViewDeviceUpgrade';

import './ViewDeviceUpgradePage.css';

const hasAccess = isAdmin() && checkAccess(CommonConstants.adminPermissions.PerformDeviceUpdate);

export const AdminViewDeviceUpgrade = () => <ViewDeviceUpgrade />;

export const ViewDeviceUpgradePage = compose(
        withHMELayout({ variants: ['view-device-upgrade-page'] }),
        authenticate,
        withAccess({
            checkAccess: () => hasAccess
        })
)(AdminViewDeviceUpgrade);
