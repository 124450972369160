export const validateGridAccountTypeObject = ((ob, accountType) => (!ob.accountTypes || ob.accountTypes.includes(accountType)));

export const filterAccountTypeColumns = ({ accountType, columns }) => (columns.filter(col => validateGridAccountTypeObject(col, accountType)));

export const filterMobileAccountTypeColumns = ({ accountType, columns }) => {
    return columns.reduce((acc, part) => {
        if (part.column) {
            acc.push({ column: filterAccountTypeColumns({ accountType, columns: part.column }) });
        }

        return acc;
    }, [])
}