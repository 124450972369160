import React from 'react';
import classNames from 'classnames';

import { Section as LibrarySection } from 'library/Section';

import './Section.scss';

export const Section = ({ className = '', children, ...props }) => {
    return (
        <LibrarySection {...props} className={classNames('hme-partner-api-management-section', className)}>
            <div className="hme-partner-api-management-section__content">{children}</div>
        </LibrarySection>
    );
};
