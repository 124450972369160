import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { InfoField } from 'library/InfoField';
import { Section } from 'library/Section';

import './InfoSection.scss';

export const InfoSection = ({ Fields = [], className }) => {
    const { t } = useTranslation();

    return (
        <Section className={classNames('hme-system-status-info', className)} title={t('system-status__title__system-information')}>
            <div className="hme-system-status-info__fields-wrapper">
                {Fields.map((props, idx) => (
                    <InfoField className="hme-system-status-info__field" key={idx} {...props} />
                ))}
            </div>
        </Section>
    );
};

InfoSection.propTypes = {
    Fields: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        className: PropTypes.string,
        children: PropTypes.elementType
    })).isRequired,
    className: PropTypes.string
};
