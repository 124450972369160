import React, { useState, useEffect } from 'react';
import { HistoryListPage } from './HistoryListPage';
import customSmackTalkApi from 'api/customSmackTalkApi';

export const MessagesHistory = () => {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);

    const getMessagesData = async () => {
        setLoader(true);
        const result  = await customSmackTalkApi.messageHistory() || {};
        setData(result);
        setLoader(false);
    };

    useEffect(() => {
        if (!data.length) {
            getMessagesData();
        }
    }, []);

    return (
        <HistoryListPage
            data={data}
            loader={loader}
        />
    );
};
