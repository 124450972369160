import React, { useCallback, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Select } from 'library/Select';
import { Label } from 'library/Label';
import { usePagination } from 'hooks/usePagination';

import './Paginate.scss'

const defaultPageSizes = [10, 20, 50, -1];

export const Paginate = ({
    total,
    page,
    recordsPerPage: defaultRecordsPerPage,
    className,
    onChange,
    pageSizes = defaultPageSizes,
    hideSinglePage = true,
    sectionWidth = 0
}) => {

    const {
        pageRangeDisplayed,
        marginPagesDisplayed,
        isNeedToCenteredPaginationBlock,
        recordsPerPage,
        setRecordsPerPage
    } = usePagination(total, defaultRecordsPerPage, page, sectionWidth, onChange);

    const minPerPage = useMemo(() => pageSizes
        .filter(value => value > 0)
        .sort((a, b) => a - b)[0], [pageSizes]);

    const show = useMemo(() => total > minPerPage && hideSinglePage, [total, minPerPage, hideSinglePage]);

    const { t } = useTranslation();

    const selectItems = useMemo(() => pageSizes.map(value => ({
        value,
        text: value === -1 ? t('common__all') : value,
    })), [pageSizes, t]);
    const pageCount = useMemo(() => Math.ceil(total / recordsPerPage), [total, recordsPerPage]);
    const firstItemNumber = useMemo(() => page * recordsPerPage, [page, recordsPerPage]);
    const lastItemNumber = useMemo(() => recordsPerPage === -1 || total - firstItemNumber < recordsPerPage ?
        total :
        firstItemNumber + recordsPerPage, [recordsPerPage, total, firstItemNumber]);

    const onPageChange = useCallback(({ selected }) => {
        onChange && onChange({
            page: Number.parseInt(selected),
            recordsPerPage,
        });
    }, [onChange, recordsPerPage]);

    const onSelectChange = useCallback((value) => {
        setRecordsPerPage && setRecordsPerPage(parseInt(value));
    }, [onChange, recordsPerPage]);

    return (
        <div className={classNames('hme-paginate', className, {
            'hme-paginate--hidden': !show,
        })}>
            <div className='hme-paginate__dropdown'>
                <Label>{t('paginate__select__show')}</Label>
                <Select value={recordsPerPage} onChange={onSelectChange} children={selectItems} />
                <Label>{t('paginate__select__items')}</Label>
            </div>
            <div className='hme-paginate__buttons'>
                <ReactPaginate
                    previousLabel=''
                    nextLabel=''
                    breakLabel='...'
                    previousClassName='paginate-previous fa fa-arrow-left'
                    nextClassName='paginate-next fa fa-arrow-right'
                    breakClassName='paginate-break-me'
                    containerClassName={classNames(
                        'paginate-list',
                        {
                            'paginate-list-hidden': pageCount <= 1 && hideSinglePage,
                            'hme-paginate__buttons__centred': isNeedToCenteredPaginationBlock
                        })}
                    subContainerClassName='pages paginate-list'
                    activeClassName='paginate-active'
                    pageCount={pageCount}
                    forcePage={page}
                    marginPagesDisplayed={marginPagesDisplayed}
                    pageRangeDisplayed={pageRangeDisplayed}
                    onPageChange={onPageChange}
                />
            </div>
            <div className="hme-paginate__results">
                <Label>{t('paginate__results', {
                    first: firstItemNumber + 1,
                    last: lastItemNumber,
                    total,
                })}</Label>
            </div>
        </div>
    )
};
