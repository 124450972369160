import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'library/Button';

import './ActivationEmailLink.css';

export const ActivationEmailLink = ({ isDisabled, onResendActivationEmail }) => {
    const { t } = useTranslation();

    return (
        <Button disabled={isDisabled} variants={['transparent']} onClick={onResendActivationEmail}>{t('admin__user__resend-activation-email')}</Button>
    );
};
