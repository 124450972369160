import React from 'react';
import { empty } from 'ramda';
import classNames from 'classnames';

import { Expand } from 'library/Icons';

import './TileExpander.scss';

export const TileExpander = ({
    title,
    isExpanded = false,
    as: Component = 'button',
    onClick = empty,
    className = ''
}) => {
    return <Component
        className={classNames(
                'tile-expander',
                { 'tile-expander--expanded': isExpanded },
                className
        )}
        onClick={onClick}
    >
        <h2 className="tile-expander__title">{title}</h2>

        {onClick !== empty && (
            <Expand className="tile-expander__icon" />
        )}
    </Component>;
};

