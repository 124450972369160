import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Title } from 'library/Title';
import { Button } from 'library/Button';
import { GridFiltersCount } from 'library/GridFiltersCount';
import { AccountEmailHeader } from 'library/AccountEmailHeader';
import { SearchOptionable } from 'library/SearchOptionable';

import './Header.scss';


export const Header = ({ hasCreateUserAccess, addUserUrl, filtersCount, searchOptions, shouldShowAccountEmail = true, onFiltersReset }) => {
    const { t } = useTranslation();

    const { searchParams, searchSelectOptions, isSearchDisabled, searchRef, onSearchParamsChange, onSearch } = searchOptions || {};

    return (
        <div className="hme-users__header__wrapper">
            <div className="hme-users__header">
                <div className="hme-users__header__title-with-filters-count">
                    <Title>{t('common__users')}</Title>
                    {searchOptions && (
                        <SearchOptionable
                            value={searchParams}
                            onChange={onSearchParamsChange}
                            onSearch={onSearch}
                            searchSelectOptions={searchSelectOptions}
                            isDisabled={isSearchDisabled}
                            isSearchButtonDisabled={isSearchDisabled}
                            searchRef={searchRef}
                        />
                    )}
                    {onFiltersReset && (
                        <div className="hme-users__header__filters-count">
                            <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} />
                        </div>
                    )}
                </div>
                {shouldShowAccountEmail && <AccountEmailHeader />}
            </div>
            {hasCreateUserAccess && addUserUrl && (
                <Link to={addUserUrl}>
                    <Button>{t('users__header__add-user')}</Button>
                </Link>
            )}
        </div>
    );
};

Header.propTypes = {
    hasCreateUserAccess: PropTypes.bool,
    addUserUrl: PropTypes.string,
    filtersCount: PropTypes.number,
    onFiltersReset: PropTypes.func
};
