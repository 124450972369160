import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CenterLoader } from 'components/Common/CenterLoader';
import { SettingsForm, VAIO_DEFAULTS } from 'pages/StoreFeatures/VaioProviderForm';
import { ASYNC_STATUS, useAsync } from 'hooks/useAsync';
import { getLoggedInUserEmail } from 'services/Auth';
import { errorKeys, toCreateDTO } from 'pages/StoreFeatures/VaioProviderForm/helpers';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { PARTNER_ROUTES } from 'constants/routes';
import { NotificationsList } from 'library/NotificationsList';
import { createVaioProvider } from 'services/Partner';

export const Create = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userEmail = getLoggedInUserEmail();
    const filledInValues = useRef(VAIO_DEFAULTS);
    filledInValues.current.email = userEmail;

    const { status, run } = useAsync();

    const onSubmit = (values) => {
        filledInValues.current = values;

        const filteredFormValues = toCreateDTO(values, userEmail);

        run(
                createVaioProvider(filteredFormValues).then(() => {
                    navigate(`/${PARTNER_ROUTES.settingsFull}`);

                    setTimeout(() => {
                        addSuccessNotification('partner__provider-setting-saved', { autoClose: 5000 });
                    }, 1000);
                }).catch((err) => {
                    let message = '';
                    if (err.response.data.message === 'Provider name already exists') {
                        message = 'partner__provider-setting-name-exists';
                    } else {
                        message = 'partner__provider-setting-save-failed';
                    }
                    addErrorNotification( message, { autoClose: 5000 }
                    );
                })
        );
    };

    const onCancel = () => {
        navigate(`/${PARTNER_ROUTES.settingsFull}`);
    };

    const isLoading = status === ASYNC_STATUS.PENDING;

    if (isLoading) {
        return <CenterLoader>{t('common__loading')}</CenterLoader>;
    }

    return (<>
        <NotificationsList />
        <SettingsForm
            title={t('new-vaio-provider-settings_title')}
            onSubmit={onSubmit}
            onCancel={onCancel}
            settings={filledInValues.current}
            linkedPartner={filledInValues.current.email}
            isPartnerMode
            providerNameInput={t('new-vaio-provider__form--provider-settings-name')}
        />
    </>
    );
};
