import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Constant } from '@hme-cloud/utility-common';
import PropTypes from 'prop-types';

import { createSystemPageURI } from 'helpers/createSystemPageURI';

const { DeviceType, BuildVersions } = Constant;

export const ViewDeviceLink = ({ device }) => {
    const { t } = useTranslation();

    const hasLinkedDevice = Boolean(device.LinkedDeviceUID);

    const {
        Device_UID: deviceUID,
        LinkedDeviceUID: linkedDeviceUID,
        Device_DeviceType_ID: deviceTypeID,
        Device_MainVersion: deviceMainVersion
    } = device;

    const redirectUrl = useMemo(() => {
        return createSystemPageURI({
            deviceUID: hasLinkedDevice ? linkedDeviceUID : deviceUID,
            deviceTypeID: hasLinkedDevice ? DeviceType.ZOOMNITRO.typeId : deviceTypeID,
            deviceMainVersion: hasLinkedDevice ? BuildVersions.MIN_NITRO_UPGRADE_VERSION : deviceMainVersion
        });
    }, [deviceUID, deviceTypeID, deviceMainVersion, hasLinkedDevice]);

    return (
        <Link to={redirectUrl}>{t('stores__modal__view-details')}</Link>
    );
};

ViewDeviceLink.propTypes = {
    device: PropTypes.shape({
        Device_UID: PropTypes.string,
        LinkedDeviceUID: PropTypes.string,
        Device_DeviceType_ID: PropTypes.number,
        Device_MainVersion: PropTypes.string
    }).isRequired
};
