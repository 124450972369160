import { getTabs } from './constants';
import { flatten } from 'ramda';
import { CommonConstants } from '../../Constants';
import { checkAccess } from '../../actions/permissions';

const deviceName = 'Device_Name';
const modals = {
    addStore: [
        'addStore'
    ],
    viewStore: [
        'viewStore',
        'devices'
    ],
    editStore: [
        'editStore',
        'devices',
        'addDevice'
    ],
    notAvailable: [
        'viewStore',
        'addDevice'
    ]
};

export const StorePopupType = Object.freeze({
    addStore: 'addStore',
    viewStore: 'viewStore',
    editStore: 'editStore',
    notAvailable: 'notAvailable'
});


const distinct = (value, index, self) => self.findIndex((el) => el.component === value.component && el.device === value.device) === index;
const deviceMapper = (device) => {
    const {
        Device_DeviceType_ID: deviceTypeID,
        Device_MainVersion: deviceMainVersion = ''
    } = device || {};
    const {
        deviceType: {
            zoomNitro: {
                name: zoomNitroName,
                minMainVersion
            } = {}
        } = {}
    } = CommonConstants;
    const isZoom = deviceTypeID === 1;
    const majorVersion = Number(deviceMainVersion.split('.')[0]);
    const isNitro = majorVersion >= minMainVersion;
    const isZoomNitro = isZoom && isNitro;
    const systemName = isZoomNitro ? zoomNitroName : device[deviceName];
    return getTabs && getTabs()[systemName] || {};
};

export const getModalStructure = (name, devices = [], isAdmin = false) => {
    const tabs = getTabs() || [];
    if (!name) return [];
    const modal = modals[name].slice() || [];
    if (name === 'viewStore' && checkAccess(CommonConstants.userPermissions.AddDevice) && isAdmin) {
        modal.push('addDevice');
    }
    const modalTabs = modal.map((tabName) => {
        if (tabName === 'devices') {
            const receivedDevices = devices.map(deviceMapper).filter(distinct);
            return receivedDevices;
        }
        return tabs[tabName] || [];
    });

    return flatten(modalTabs).filter((el) => el) || [];
};
