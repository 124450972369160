import { addNotification, NOTIFICATION_TYPES } from 'services/Notifications';
import { saveAdminAccount } from '../../../services/Account';
import { mapFormDataToAccount } from './mapAccountForm';
import { ACCOUNTS_PAGE_URL } from './constants';

export const saveAccount = async ({ formData, regions, setIsSaving, navigate, t }) => {
    const account = mapFormDataToAccount({ formData, regions });

    try {
        setIsSaving(true);
        await saveAdminAccount(account);

        navigate(ACCOUNTS_PAGE_URL);
        setTimeout(() => {
            addNotification({
                message: t('admin__account__notification--account-successfully-created'),
                type: NOTIFICATION_TYPES.SUCCESS,
            });
        }, 1000);
    } catch (err) {
        addNotification({
            message: t(err.message || 'admin__account__notification--account-creation-error'),
            type: NOTIFICATION_TYPES.ERROR,
        });
    }
    setIsSaving(false);
};
