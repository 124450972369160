import React, { useCallback } from 'react';

import { Section } from 'library/Section';
import { InputComponent as Input } from 'components/Inputs';
import { FiltersForm } from './FiltersForm';

import './SearchSection.scss';

export const SearchSection = ({
    brands,
    countries,
    sectionNumber,
    disabled,
    params,
    filters,
    isFilterSectionShown,
    onParamsChange,
    onFiltersChange,
}) => {
    const onTaskNameChange = useCallback(newTaskName => {
        onParamsChange({
            ...params,
            taskName: newTaskName,
        });
    }, [filters, onParamsChange]);

    return (
        <Section
            number={sectionNumber}
            title='Name the task and search for ZOOM devices'
            disabled={disabled}
            className='admin-create-apply-store-settings-task-search'
        >
            <Input
                className='admin-create-apply-store-settings-task-search-task-name'
                label='Task Name'
                value={params.taskName}
                maxLength={30}
                onChange={onTaskNameChange}
            />
            {
                isFilterSectionShown &&
                <FiltersForm
                    brands={brands}
                    countries={countries}
                    filters={filters}
                    onFiltersChange={onFiltersChange}
                />
            }
        </Section>
    );
};
