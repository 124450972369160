import React, { useCallback } from 'react';
import { empty } from 'ramda';

import { Button } from 'library/Button';
import { Tile } from 'library/Tile';

import { TileItemField } from '../../../Common/TileItemField';
import { TileExpander } from '../../../Common/TileExpander';
import { TilePeripheralDeviceItem } from '../TilePeripheralDeviceItem';

export const TileDeviceList = (props) => {
    const { expandedDevices = {}, item = {}, headers = [], onExpandChange } = props;
    const { isMultiple, deviceTypeID, modelName = '' } = item;

    const isExpanded = isMultiple ? expandedDevices[deviceTypeID] : false;

    const handleExpanderClick = useCallback(() => {
        if (!isMultiple) {
            return;
        }

        onExpandChange(deviceTypeID, !isExpanded);
    }, [isMultiple, isExpanded]);

    return (
        <Tile className="hme-system-status-devices-grid__tile">
            <TileExpander
                title={modelName}
                as={isMultiple ? Button : 'div'}
                onClick={isMultiple ? handleExpanderClick : empty}
                isExpanded={isExpanded}
            />

            {headers.map(({ column }, index) => {
                if (isMultiple) {
                    return (
                        <div key={index} className="hme-system-status-devices-grid__tile-peripheral-device-list">
                            {isExpanded && <TilePeripheralDeviceItem data={item} />}
                        </div>
                    );
                }

                return (
                    <div key={index} className="hme-system-status-devices-grid__tile-peripheral-device">
                        {column.map(({ text, tabletScreenText = '', property }) => (
                            <TileItemField
                                key={property}
                                device={item}
                                property={property.split('.')}
                                text={tabletScreenText || text}
                            />
                        ))}
                    </div>
                );
            })}
        </Tile>
    );
};
