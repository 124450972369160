import { CommonConstants } from 'Constants';

const externalPermissions = CommonConstants.externalPermissions;

export const permissionsTKey = {
    'ViewEditContests': 'common__permission--view-edit-contests',
    'ManageUser': 'common__permission--manage-user',
    'EditStoreBasic': 'common__permission--edit-store-basic',
    'RemoteConnect': 'common__permission--remote-connect',
    'ManageRole': 'common__permission--manage-role',
    'RemoveRole': 'common__permission--remove-role',
    'ApplyDeviceSettings': 'common__manage-device-settings',
    'EditDeviceSettings': 'common__permission--edit-device-settings',
    'EditLeaderboard': 'common__permission--edit-leaderboard',
    'EditStoreAdvanced': 'common__permission--edit-store-advanced',
    'PerformDeviceRestart': 'common__permission--perform-device-restart',
    'SmackTalk': 'common__permission--send-smack-talk',
    'SendSmackTalkMessage': 'common__permission--send-smack-talk',
    'SendTextConnectMessage': 'common__permission--send-text-n-connect',
    'ViewAllStores': 'common__permission--view-all-stores',
    'ViewEditAwards': 'common__permission--view-edit-awards',
    'ViewLeaderboard': 'common__permission--view-leaderboard',
    'CustomizeSmackTalk': 'common__permission--customize-smack-talk',

    [externalPermissions.ManageDevicesBasic]: 'common__permission--ext-manage-devices-basic',
    [externalPermissions.ManageDevicesAdvanced]: 'common__permission--ext-manage-devices-advanced',
    [externalPermissions.ManageRoles]: 'common__permission--ext-manage-roles',
    [externalPermissions.ManageUsers]: 'common__permission--ext-manage-users',
    [externalPermissions.ManageStoresBasic]: 'common__permission--ext-manage-stores-basic',
    [externalPermissions.ManageStoresAdvanced]: 'common__permission--ext-manage-stores-advanced',
};
