import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'ramda';

import { CommonConstants } from 'Constants';
import { withAccess } from 'HOCs/withAccess';
import { checkAccess } from 'actions/permissions';
import { DistributorCreate } from 'pages/Roles/ViewEdit/DistributorCreate';
import { DistributorEdit } from 'pages/Roles/ViewEdit/DistributorEdit';
import { DistributorStatus as RolesList } from 'pages/Roles/Status/DistributorStatus';

import './DistributorRoles.scss'

const DistributorsRolesComponent = ({ accountUuid }) => {
    return (
        <Routes>
            <Route path="/add-role" element={<DistributorCreate />} />
            <Route path="/edit-role" element={<DistributorEdit />} />
            <Route index path="/" element={
                <RolesList accountUuid={accountUuid} />
            } />
        </Routes>
    );
};

DistributorsRolesComponent.propTypes = {
    accountUuid: PropTypes.string.isRequired
};

const hasAccess = checkAccess(CommonConstants.externalPermissions.ManageRoles);

export const DistributorsRoles = compose(
    withAccess({
        checkAccess: () => hasAccess,
    })
)(DistributorsRolesComponent);
