import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAsync } from 'hooks/useAsync';
import { addSuccessNotification, addErrorNotification } from 'services/Notifications';
import { prepareAccountDataDTO, useUdpateAccount } from 'hooks/account';

import { verifyCurrentPassword, changePassword } from '../Controller';
import { ERRORS_ENUM, ERRORS_MAP } from '../constants';

export const useUdpateAccountData = () => {
    const { t } = useTranslation();
    const { run: runUpdateAccountData, status: updateAccountDataStatus } = useAsync();
    const { run: runUpdateAccountPassword, status: updateAccountPasswordStatus } = useAsync();

    const { updateAccountData } = useUdpateAccount();

    const handleUpdateAccountData = useCallback(
            async (accountData) => {
            // we need to extract following fields from "accountData" in order to separate "password update"
            // and "account data update" functionalities

                // eslint-disable-next-line no-unused-vars
                const { currentPassword, newPassword, confirmNewPassword, ...accountDataWithoutPassword } = accountData;

                try {
                    await runUpdateAccountData(updateAccountData(accountDataWithoutPassword));
                    addSuccessNotification(t('my-account__success__save'));
                } catch (err) {
                    addErrorNotification(t(ERRORS_MAP.get(err.message) || 'common__error--internal-server'));
                }
            },
            [updateAccountData, runUpdateAccountData, t]
    );

    const handleUpdateAccountPassword = useCallback(
            async (accountData) => {
                try {
                    await runUpdateAccountPassword(
                            verifyCurrentPassword({ uuId: accountData.uuId, currentPassword: accountData.currentPassword })
                                    .then((res) => {
                                        if (!res.validPassword) {
                                            throw new Error(ERRORS_ENUM.INVALID_CREDENTIALS);
                                        }

                                        const preparedUserDTO = prepareAccountDataDTO(accountData);
                                        return changePassword({ changeEmail: preparedUserDTO.userEmail, ...preparedUserDTO });
                                    })
                    );

                    addSuccessNotification(t('profile__account__password-update--success'));
                } catch (err) {
                    addErrorNotification(t(ERRORS_MAP.get(err.message) || 'common__error--internal-server__p1'));

                    throw err;
                }
            },
            [updateAccountData, runUpdateAccountPassword, t]
    );

    const isUpdateAccountInProgress = updateAccountDataStatus === 'pending';
    const isUpdateAccountPasswordInProgress = updateAccountPasswordStatus === 'pending';

    return {
        isUpdateAccountInProgress,
        isUpdateAccountPasswordInProgress,
        handleUpdateAccountData,
        handleUpdateAccountPassword
    };
};
