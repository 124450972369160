import React from 'react';

import { Tile } from 'library/Tile';

import { TileItemField } from '../../../Common/TileItemField';
import { TileExpander } from '../../../Common/TileExpander';

export const TileDeviceList = (props) => {
    const { item = {}, headers = [] } = props;

    return (
        <Tile className="hme-system-status-devices-grid__tile">
            <TileExpander title={item.modelName} as="div" />

            {headers.map(({ column }, index) => {
                return (
                    <div
                        key={index}
                        className="hme-system-status-devices-grid__tile-peripheral-device"
                    >
                        {column.map(({ text, tabletScreenText = '', property }) => (
                            <TileItemField
                                key={property}
                                device={item}
                                property={property.split('.')}
                                text={tabletScreenText || text}
                            />
                        ))}
                    </div>
                );
            })}
        </Tile>
    );
};
