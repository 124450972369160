import React from 'react';
import { compose } from 'ramda';

import { checkAccess } from 'actions/permissions';
import { CommonConstants } from 'Constants';
import { withHMELayout } from 'HOCs/withHMELayout';
import { withAccess } from 'HOCs/withAccess';
import { useEditUser } from './hooks/useEditUser';
import { ViewEdit } from '../Common/ViewEdit';

const AdminEditUserComponent = () => {
    const state = useEditUser();

    return (
        <ViewEdit className="hme-view-user--admin" {...state} />
    );
};

const hasAccess = checkAccess(CommonConstants.userPermissions.ManageUser);

export const AdminEditUser = compose(
        withHMELayout(),
        withAccess({
            checkAccess: () => hasAccess
        })
)(AdminEditUserComponent);
