import { useState, useEffect, useCallback } from 'react';
import { getCountries } from '../../../../services/Countries';

export const useLoadCountries = () => {
    const [countries, setCountries] = useState([]);
    const [isCountriesLoading, setIsCountriesLoading] = useState(false);
    const [countriesLoadingError, setCountriesLoadingError] = useState(null);

    const loadCountries = useCallback(async () => {
        try {
            setIsCountriesLoading(true);
            setCountriesLoadingError(null);
            const countryList = (await getCountries()) || [];

            setCountries(countryList);
        } catch (e) {
            setCountries([]);
            setCountriesLoadingError(e);
        }

        setIsCountriesLoading(false);
    }, []);

    useEffect(() => { loadCountries(); }, []);

    return { countries, isCountriesLoading, countriesLoadingError, loadCountries };
};
