import React, {useState, useCallback} from 'react';
import classNames from 'classnames';

import { useTrigger } from 'hooks/useTrigger';

import { Header, DEVICE_SEARCH_TYPES } from './Header';
import { JobsList } from './JobsList';

import './DeviceSettings.css';

export const DeviceSettings = ({ permissionType, loadAllJobs }) => {
    const [jobs, setJobs] = useState([]);
    const [areJobsLoading, setAreJobsLoading] = useState(false);
    const [words, setWords] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [deviceSearchType, setDeviceSearchType] = useState(DEVICE_SEARCH_TYPES.ALL);
    const [filtersCount, setFiltersCount] = useState(0);

    const resetFiltersTrigger = useTrigger();

    const onWordsChange = useCallback(words => {
        setWords(words);
        setIsSearching(false);
    }, [setWords, setIsSearching]);

    const onSearchStart = useCallback(() => {
        setIsSearching(true);
    }, [setIsSearching]);

    const onDateRangeChange = useCallback((range) => {
        loadAllJobs(range, setJobs, setAreJobsLoading);
    }, [loadAllJobs, setJobs, setAreJobsLoading]);

    const onFiltersReset = useCallback(() => {
        resetFiltersTrigger.trigger();
    }, [resetFiltersTrigger]);

    return (
        <div
            className={classNames(
                "apply-device-settings-status-page",
                "hme-components",
                permissionType ? `permission-type-${permissionType.toLowerCase()}` : "",
            )}
        >
            <Header
                permissionType={permissionType}
                isDateRangeDisabled={areJobsLoading}
                deviceSearchType={deviceSearchType}
                onWordsChange={onWordsChange}
                onSearchStart={onSearchStart}
                onFiltersReset={onFiltersReset}
                onDateRangeChange={onDateRangeChange}
                onDeviceSearchTypeChange={setDeviceSearchType}
                filtersCount={filtersCount}
            />
            <JobsList
                permissionType={permissionType}
                jobs={jobs}
                isLoading={areJobsLoading}
                words={words}
                isSearching={isSearching}
                deviceSearchType={deviceSearchType}
                resetFiltersTrigger={resetFiltersTrigger}
                filtersCount={filtersCount}
                onFiltersCountChange={setFiltersCount}
            />
        </div>
    );
};
