export const translationKeysMap = {
    'current rollinghour': 'common__current-period--rollinghour',
    'current hour': 'common__current-period--hour',
    'current daypart': 'common__current-period--daypart',
    'current day': 'common__current-period--day',
    'current week': 'common__current-period--week',
    'current month': 'common__current-period--month',
    'current custom': 'common__current-period',
    'previous rollinghour': 'common__previous-period--rollinghour',
    'previous hour': 'common__previous-period--hour',
    'previous daypart': 'common__previous-period--daypart',
    'previous day': 'common__previous-period--day',
    'previous week': 'common__previous-period--week',
    'previous month': 'common__previous-period--month',
    'previous custom': 'common__previous-period',
    'storeRank': 'dashboard__store-rank',
    'storeName': 'dashboard__store-name',
    'storeNumber': 'dashboard__store-number',
    'goal': 'common__goal',
    'percent': 'common__goal--percent',
    'cars': 'common__cars',
    'avg': 'common__avg',
    'Store': 'common__store',
    'stores': 'common__stores',
    'Pre Warning1': 'dashboard__event--pre-warning-1',
    'Pre Warning2': 'dashboard__event--pre-warning-2',
    'Menu1': 'dashboard__event--menu-1',
    'Menu2': 'dashboard__event--menu-2',
    'Queue': 'dashboard__event--queue',
    'Order Point1': 'common__order-point-1',
    'Order Point2': 'common__order-point-2',
    'Greet': 'common__greet',
    'Greet 1': 'common__greet-1',
    'Greet 2': 'common__greet-2',
    'Cashier': 'common__cashier',
    'Presenter': 'common__presenter',
    'Lane Queue': 'common__lane-queue',
    'Lane Queue 2': 'common__lane-queue-2',
    'Menu Board': 'common__menu-board',
    'Lane Total': 'common__lane-total',
    'Lane Total 2': 'common__lane-total-2',
    'Lane 1 Total': 'common__lane-1-total-1',
    'Lane 1 Total 2': 'common__lane-1-total-2',
    'Lane 2 Total': 'common__lane-2-total-1',
    'Lane 2 Total 2': 'common__lane-2-total-2'
};
