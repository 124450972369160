import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PureRadioGroup } from 'library/RadioGroup';
import { Button } from 'library/Button';
import { validEmailRegexp } from 'constants/email';
import { CenterLoader } from 'components/Common/CenterLoader';
import { Modal } from '../Modal';
import { getRadioOptions } from './RenewTokenOptions';
import { Input } from '../Input';

import { formDataValidator, renewTokenTypes } from '../../helpers';

import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg';


import './RenewTokenModal.scss';
const RENEW_TOKEN_BTN_VARIANTS = ['submit'];

const DEFAULT_ERRORS = {};

export const RenewTokenModal = ({ isShown, onCancel, onSubmit, token, isOptionsHidden, isLoading, recipientEmails, hasTokenExpired, ...props }) => {
    const { t } = useTranslation();

    const [renewTokenType, setRenewTokenType] = useState(renewTokenTypes.ORIGINAL_EXP_DATE);

    const [specificDate, setSpecificDate] = useState(null);

    const [email2, setEmail2] = useState('');

    const [email3, setEmail3] = useState('');

    const [formErrors, setFormErrors] = useState(DEFAULT_ERRORS);

    useEffect(() => {
        if ( recipientEmails[0] !== email2) {
            setEmail2(recipientEmails[0]);
        }
        if (recipientEmails[1] !== email3) {
            setEmail3(recipientEmails[1]);
        }
    }, [recipientEmails]);

    const isDatepickerDisabled = renewTokenType !== renewTokenTypes.SPECIFIC_EXP_DATE;

    const isSubmitDisabled = renewTokenType === renewTokenTypes.SPECIFIC_EXP_DATE && !specificDate;

    const formValidations = () => ({
        email2: email2?.trim() && !validEmailRegexp.test(email2?.trim()),
        email3: email3?.trim() && !validEmailRegexp.test(email3?.trim())
    });

    const handleSubmit = useCallback(
            (evt) => {
                evt.preventDefault();

                if (isSubmitDisabled || !onSubmit) {
                    return;
                }

                const errors = formDataValidator(formValidations(), t);

                if (Object.keys(errors).length > 0) {
                    setFormErrors(errors);
                    return;
                } else {
                    setFormErrors(DEFAULT_ERRORS);
                }

                onSubmit(isOptionsHidden ? null : renewTokenType, email2, email3, { specificDate });
            },
            [renewTokenType, specificDate, isSubmitDisabled, isOptionsHidden, onSubmit, email2, email3]
    );

    return (
        <Modal show={isShown} className="hme-partner-api-management-renew-token-modal" onHide={onCancel} {...props}>
            <div className="hme-partner-api-management-renew-token-modal__content">
                {isLoading ? <CenterLoader /> : (
                    <>
                        <div className="hme-partner-api-management-renew-token-modal__header">
                            <AlertIcon className="hme-partner-api-management-renew-token-modal__alert-icon" />
                            <h2 className="hme-partner-api-management-renew-token-modal__title">{t('api-token-management__renew-token')}</h2>
                        </div>

                        <form className="hme-partner-api-management-renew-token-modal__form" onSubmit={handleSubmit}>

                            {
                                isOptionsHidden ?
                                    <p className="hme-partner-api-management-renew-token-modal__description">{t('api-token-management__renew-create-token-description')}</p>
                                    : <>
                                        <ol>
                                            <li value={1} className="hme-partner-api-management-renew-token-modal__description">
                                                {t('api-token-management__renew-create-token-description2')}</li>
                                        </ol>

                                        <PureRadioGroup
                                            value={renewTokenType}
                                            onValueChange={setRenewTokenType}
                                            name="renewTokenAlgorithm"
                                            className="hme-partner-api-management-renew-token-modal__options"
                                        >
                                            {getRadioOptions({
                                                isDatepickerDisabled,
                                                tokenExpirationDate: token?.expiryDate,
                                                onDateChange: setSpecificDate,
                                                date: specificDate
                                            })}
                                        </PureRadioGroup>

                                        <ol>
                                            <li value={2} className="hme-partner-api-management-renew-token-modal__description">{'Confirm or change the email addresses where this token renewal will be sent.'}</li>
                                        </ol>

                                        <div className="email-container">
                                            <Input
                                                label={t('api-token-management__form--email2')}
                                                className="email-fields"
                                                name="email2"
                                                error={formErrors.email2}
                                                value={email2}
                                                maxLength={50}
                                                onChange={setEmail2}
                                                variants={['label-inside']}
                                            />
                                            <Input
                                                label={t('api-token-management__form--email3')}
                                                className="email-fields"
                                                name="email3"
                                                error={formErrors.email3}
                                                value={email3}
                                                maxLength={50}
                                                onChange={setEmail3}
                                                variants={['label-inside']}
                                            />
                                        </div>
                                    </>
                            }

                            <div className="hme-partner-api-management-renew-token-modal__btns-container">
                                <Button onClick={onCancel}>{t('common__cancel')}</Button>

                                <Button variants={RENEW_TOKEN_BTN_VARIANTS} disabled={isSubmitDisabled} type="submit">
                                    {t('api-token-management__renew-token')}
                                </Button>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </Modal>
    );
};
