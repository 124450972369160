import React, { useCallback, useState, useEffect } from 'react';
import { assocPath } from 'ramda';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Tip } from 'library/Tip';

import { Row } from '../GridRow/Row';
import { TitlesRow } from '../TitlesRow';
import { Sort } from './Sort'
import { FilterCell } from './FilterCell';

import './GridHeader.scss';

const getFilterable = availableFilters => availableFilters.map(({ property }) => property);
const getFilterCellProps = (availableFilters, property) => {
    const filter = availableFilters
        .find(({ property: filterProperty }) => filterProperty === property);

    return filter ? {
        items: filter.items,
        allText: filter.allText,
        SearchItemsComponent: filter.SearchItemsComponent,
        onSearchItemsChange: filter.onSearchItemsChange,
        defaultSearchValue: filter.defaultSearchValue,
        noItemsFoundText: filter.noItemsFoundText,
    } : {};
};

const isFilterApplied = (property, availableFilters, filters) => {
    const items = getFilterCellProps(availableFilters, property).items || [];
    const filtersItems = filters[property] || [];

    return items.length !== filtersItems.length;
};

const getRowHeaders = headers => headers.map(({
    text,
    property,
    lastInBlock,
    flex,
    headerClassName
}) => ({
    text,
    property,
    lastInBlock,
    flex,
    className: headerClassName,
    path: property.split('.'), // @todo move this logic to Grid component
}));

const defaultAvailableFilters = [];
const defaultFilters = {};
const defaultSortSelection = {};

// Render Grid header component
export const GridHeader = ({
    headers,
    titles,
    selectable,
    expandable,
    isAllSelected,
    odd = false,
    hovered = false,
    className = '',
    availableFilters = defaultAvailableFilters,
    filters = defaultFilters,
    onHoverChange,
    onAllSelectedChange,
    onFiltersChange,
    onSortChange,
    sortSelection = defaultSortSelection,
}) => {
    const [data, setData] = useState([]);
    const [rowHeaders, setRowHeaders] = useState([]);

    const onAllSelectedChangeHandler = useCallback(checked => {
        onAllSelectedChange && onAllSelectedChange(checked);
    }, [onAllSelectedChange]);

    const { t } = useTranslation();

    useEffect(() => {
        const filterable = getFilterable(availableFilters);

        // Prepare data for rendering as GridRow component
        setData(headers.reduce(
            (val, { text, property, sortable = false, sortDirections, infoText = '' }) => {
                const translatedText = typeof text === 'string' ? t(text) : text;
                const onChangeHandler = ({ value }) => {
                    return onSortChange({ [property]: value })
                }

                const [selectedProperty] = Object.keys(sortSelection);
                const sortCell = sortable ?
                <Sort
                    value={sortSelection[property]}
                    sortDirections={selectedProperty === property ? [-1, 1] : sortDirections}
                    onChange={onChangeHandler}
                >{translatedText}</Sort>
                : translatedText;

                const cellContent = filterable.includes(property) ?
                    <span className={classNames({
                        'hme-grid-cell-filter-applied': isFilterApplied(property, availableFilters, filters),
                    })}>
                        {sortCell}
                        <FilterCell
                            selection={filters[property]}
                            onChange={newSelection => {
                                onFiltersChange && onFiltersChange({
                                    ...filters,
                                    [property]: newSelection,
                                })
                            }}
                            {...getFilterCellProps(availableFilters, property)}
                        />
                    </span>:
                    sortCell;

                const cellContentWithInfoText = infoText ? (
                    <>
                        {cellContent}
                        <Tip clickable={false}>{t(infoText)}</Tip>
                    </>
                ) : (
                    cellContent
                );

                return assocPath(property.split('.'), cellContentWithInfoText, val);
            },
            {}
        ))
    }, [headers, availableFilters, filters, t, onFiltersChange, setData, sortSelection, onSortChange]);

    useEffect(()  => {
        setRowHeaders(getRowHeaders(headers));
    }, [headers, availableFilters]);

    return (
        <div className='hme-grid-header'>
            <TitlesRow
                titles={titles}
                selectable={selectable}
                expandable={expandable}
                onHoverChange={onHoverChange}
                hovered={hovered}
                odd={odd}
            />
            <Row
                data={data}
                expandHidden
                expandable={expandable}
                expanded={false}
                headers={rowHeaders}
                selectable={selectable}
                selected={isAllSelected}
                onSelectionChange={onAllSelectedChangeHandler}
                odd={odd}
                hovered={hovered}
                className={classNames(className, data.rowClassName, 'hme-grid-header-row')}
                onHoverChange={onHoverChange}
            />
            {/* move to separate HeaderRow*/}
        </div>
    );
};
