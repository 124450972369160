import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { formatFilters, paginationFilter, sortProvidersByColumn } from 'pages/StoreFeatures/VaioProviders/helpers';
import { INIT_SORT_SELECTION, sortColumns } from 'pages/StoreFeatures/VaioProviders';

import { prepareRow } from '../../../Common/ProvidersSettings/helpers';

const initialGridFilters = {
    name: [],
    outboundAudio: [],
    audioMode: [],
    createdBy: [],
    updatedBy: [],
    botAudioSourceType: []
};

// Data coming from Database is already sorted by keyExpiration
const INIT_RESULTS_PER_PAGE = 10; // Must match minimum pageSizes in Paginate component

export const useProvidersList = ({ providers: originalProviders = [] }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [providers, setProviders] = useState([]);
    const [filteredProviders, setFilteredProviders] = useState([]);
    const [availableFilters, setAvailableFilters] = useState([]);
    const [gridFilters, setGridFilters] = useState(initialGridFilters);
    const [visibleProviders, setVisibleProviders] = useState([]); // Filtered Providers to show w/ pagination
    const [sortSelection, setSortSelection] = useState(INIT_SORT_SELECTION);
    const [filtersCount, setFiltersCount] = useState(0);
    const [resultsPerPage, setResultsPerPage] = useState(INIT_RESULTS_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(0);

    const onSearchItemsChange = useCallback((items, searchValue) => {
        const { searchText } = searchValue;

        const filteredResults = searchText ? items.filter((item) => {
            return item.text ? item.text.toLowerCase().includes(searchText.toLowerCase()) : false;
        }) : items;

        return filteredResults;
    }, []);

    // sortByColumn is like {name: 1}
    // -1 = ascending & 1 = descending
    const onSortChange = useCallback((sortByColumn) => {
        let [columnName] = Object.keys(sortByColumn);
        const sortDirection = sortByColumn[columnName];

        // handle overriden column names for sorting
        columnName = sortColumns[columnName] || columnName;

        const sortedProviders = sortProvidersByColumn(filteredProviders, columnName, sortDirection);

        setFilteredProviders(sortedProviders);
        setSortSelection(sortByColumn);
        setVisibleProviders(resultsPerPage === -1 ? sortedProviders : sortedProviders.slice(0, resultsPerPage));
        setCurrentPage(0);
    }, [filteredProviders, resultsPerPage]);

    const onFiltersReset = useCallback(() => {
        setFilteredProviders(providers);
        const resetFilters = { ...gridFilters };
        for (const filter of availableFilters) {
            resetFilters[filter.property] = filter.items.map((item) => item.value);
        }
        setGridFilters(resetFilters);
    }, [providers, gridFilters, availableFilters]);

    const setFilters = useCallback((formattedProviders) => {
        const format = formatFilters(formattedProviders, onSearchItemsChange, t);
        setAvailableFilters(format.formattedAvailableFilters);
        setGridFilters(format.formattedGridFilters);
    }, [onSearchItemsChange, t]);

    const originalProvidersRef = useRef(null);

    const resetProviders = useCallback(() => {
        const formattedRows = originalProviders.map((provider) => prepareRow(provider));
        const shownProviders = paginationFilter(formattedRows, currentPage, resultsPerPage);

        originalProvidersRef.current = formattedRows;

        setProviders(formattedRows);
        setFilteredProviders(formattedRows);
        setVisibleProviders(shownProviders);
        setFilters(formattedRows);
        setSortSelection(INIT_SORT_SELECTION);
    }, [originalProviders, currentPage, resultsPerPage, setFilters, navigate]);

    const onPaginationChange = useCallback(({ page, recordsPerPage }) => {
        const shownProviders = paginationFilter(filteredProviders, page, recordsPerPage);
        setCurrentPage(page);
        setResultsPerPage(recordsPerPage);
        setVisibleProviders(shownProviders);
    }, [filteredProviders]);

    useEffect(() => resetProviders(), []);

    useEffect(() => {
        // Get providers that match all filters
        const filtered = providers.filter((provider) => {
            return Object.keys(gridFilters).every((key) => gridFilters[key].includes(provider[key]));
        });

        const count = availableFilters.reduce((countAcc, filter) => {
            if (filter.items.length !== gridFilters[filter.property].length) {
                return countAcc + 1;
            }

            return countAcc;
        }, 0);

        setFilteredProviders(filtered);
        setVisibleProviders(resultsPerPage === -1 ? filtered : filtered.slice(0, resultsPerPage));
        setCurrentPage(0);
        setFiltersCount(count);
    }, [gridFilters, resultsPerPage, providers, availableFilters]);

    return {
        rows: providers,
        visibleProviders,
        availableFilters,
        gridFilters,
        filtersCount,
        currentPage,
        resultsPerPage,
        filteredProviders,
        setGridFilters,
        onFiltersReset,
        onSortChange,
        onPaginationChange,
        sortSelection
    };
};
