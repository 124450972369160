import React from 'react';
import { ToastContainer } from 'react-toastify';
import classNames from 'classnames';

import './NotificationsList.scss';

export const NotificationsList = ({ className, ...containerProps }) => (
    <ToastContainer
        className={classNames('hme-notifications-list', className)}
        position='top-center'
        autoClose={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        {...containerProps}
    />
);
