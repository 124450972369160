/* eslint-disable react/prop-types */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const PartnerWelcomeTile = ({ partnerName }) => {
    const { t } = useTranslation();
    const supportEmail = t('contact-hme__email');
    const supportPhone = t('contact-hme__phone');
    return (
        <div className="tile-box">
            <div className="tile__box-supporting-container">
                <h3 className="tile__title">
                    {t('welcome__tile--partner__title', { partnerName, interpolation: { escapeValue: false } })}
                </h3>
                <p className="tile__paragraph">{t('welcome__tile--partner__line1')}</p>
                <p className="tile__paragraph">{t('welcome__tile--partner__line2')}</p>
                <p className="tile__paragraph">{t('welcome__tile--partner__bullet-title')}</p>
                <ul>
                    <li className="tile__paragraph">{t('welcome__tile--partner__bullet1')}</li>
                    <li className="tile__paragraph">{t('welcome__tile--partner__bullet2')}</li>
                    <li className="tile__paragraph">{t('welcome__tile--partner__bullet3')}</li>
                </ul>
                <h2 className="tile__title">
                    {t('welcome__tile--partner__need-help')}
                </h2>
                <p className="tile__paragraph">
                    <Trans
                        i18nKey="welcome__tile--partner__contact-info"
                        values={{ supportPhone, supportEmail }}
                        components={{ Link: <a href={`mailto:${supportPhone}`} />, Phone: <React.Fragment /> }}
                    />
                </p>
                <p className="tile__paragraph">{t('welcome__tile--partner__footnote')}</p>
            </div>
        </div>
    );
};

export { PartnerWelcomeTile };
