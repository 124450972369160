import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button';

import { isAdmin } from 'services/Auth';

const isAdminUser = isAdmin();

export const RemoveDeviceButton = ({ device, onRemove }) => {
    const { t } = useTranslation();

    const {
        Device_UID: deviceUid,
        Device_IsActive: isOnline
    } = device;

    const onButtonClick = useCallback(() => onRemove(deviceUid), [deviceUid, onRemove]);

    const needToShowRemoveButton = useMemo(() => isAdminUser && !isOnline, [isOnline]);

    return <>
        {needToShowRemoveButton && <div className="hme-remove-device-button">
            <Button
                variants={['transparent']}
                onClick={onButtonClick}
            >
                {t('common__remove')}
            </Button>
        </div>}
    </>;
};

RemoveDeviceButton.propTypes = {
    device: PropTypes.shape({
        Device_UID: PropTypes.string,
        Device_IsActive: PropTypes.number
    }).isRequired,
    onRemove: PropTypes.func.isRequired
};
