import { deviceTypes } from 'constants/device';
import { nexeoRestrictions } from 'constants/upgradeRestrictions';
import { Constant } from '@hme-cloud/utility-common';
import { NEXEO_PERIPHERAL_TYPES } from 'constants/NEXEOPeripheralTypes';

const { BuildVersions } = Constant;

export const checkIsNexeoTwoStepUpgrade = ({ deviceVersion, deviceType, peripheralDeviceType }) => {
    // two step upgrade is only for nexeo base station greater than minTwoStepUpgradeVersion (3.1+)
    const isTwoStepUpgrade = deviceVersion
        && deviceType === deviceTypes.NEXEO
        // for nexeo base station peripheral device type might be not provided
        && (!peripheralDeviceType || peripheralDeviceType !== NEXEO_PERIPHERAL_TYPES.IB7000.modelName)
        && BuildVersions.semverGt(deviceVersion, nexeoRestrictions.minTwoStepUpgradeVersion);
    return isTwoStepUpgrade;
}
