export const DEVICE_ERROR_CODES = {
    OFFLINE: 'OFFLINE',
    INVALID_TIER: 'INVALID_TIER',
    INVALID_BASE_VERSION: 'INVALID_BASE_VERSION',
    VOICE_COMMAND_DISABLED: 'VOICE_COMMAND_DISABLED',
    INVALID_VOICE_VERSION: 'INVALID_VOICE_VERSION',
};

export const DEVICE_ERROR_TEXTS = {
    [DEVICE_ERROR_CODES.OFFLINE]: 'text-n-connect__info-message__error--status',
    [DEVICE_ERROR_CODES.INVALID_TIER]: 'text-n-connect__info-message__error--tier',
    [DEVICE_ERROR_CODES.INVALID_BASE_VERSION]: 'text-n-connect__info-message__error--base-version',
    [DEVICE_ERROR_CODES.VOICE_COMMAND_DISABLED]: 'text-n-connect__info-message__error--voice-command-status',
    [DEVICE_ERROR_CODES.INVALID_VOICE_VERSION]: 'text-n-connect__info-message__error--voice-engine-version',
};
