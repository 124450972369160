import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Label } from 'library/Label';

import './TextareaInput.scss';

export const TextareaInput = ({ label, isDisabled, required, onChange, variants, ...props }) => {
    const { t } = useTranslation();

    const onTextChange = useCallback(({ target: { value } }) => onChange && onChange(value), [onChange]);

    return <div className={classNames('hme-textarea',
            variants.map((variant) => `hme-input-${variant}-variant`),
            {
                'hme-textarea--disabled': isDisabled,
                'hme-textarea--required': required
            })}>
        { label &&
            <Label>{t(label)}</Label>
        }
        <textarea {...props} className="hme-textarea__input" disabled={isDisabled} onChange={onTextChange} />
    </div>;
};

TextareaInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    rows: PropTypes.number,
    cols: PropTypes.number,
    maxLength: PropTypes.number,
    variants: PropTypes.arrayOf(PropTypes.string)
};

TextareaInput.defaultProps = {
    variants: []
};
