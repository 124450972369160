export const DEVICE_SETTINGS_FILTER_OPTIONS = {
    SettingsVersion: {
        title: 'common__snapshot__version',
        allText: 'common__all-snapshot-versions',
    },
    SourceBrand: {
        title: 'common__brand',
        allText: 'common__all-brands',
    },
    SourceDeviceType: {
        title: 'settings_snapshots__grid__header-title--source-type',
        allText: 'settings_snapshots__grid__filter-all-source-types',
    },
    SourceSoftwareVersion: {
        title: 'settings_snapshots__grid__header-title--source-version',
        allText: 'settings_snapshots__grid__filter-all-source-versions',
    },
    SourceLaneConfig: {
        title: 'common__lane',
        allText: 'common__all-lane-types',
    },
    CreatedBy: {
        title: 'common__created-by',
        allText: 'common__all-users',
    },
    IsBrandApproved: {
        title: 'settings_snapshots__grid__header-title--brand-approved',
        allText: 'common__all',
    },
    Status: {
        title: 'common__status',
        allText: 'common__all-statuses',
    },
};
