import React from 'react';
import classNames from 'classnames';

import { Title } from 'library/Title';

import './PageTitle.scss';

export const PageTitle = ({ children, className = '' }) => {
    return (
        <Title as="h2" className={classNames('hme-contest-page-title', className)}>{children}</Title>
    );
};
