export const mapCompanyInfo = ({
    companyName,
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    city,
    countryId,
    state,
    zipcode,
    phoneNumber,
    faxNumber,
    distributorId,
}) => ({
    companyName,
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    city,
    countryId,
    state,
    zipcode,
    phoneNumber,
    faxNumber,
    distributorId,
});
