export const adminAccountsSelectSearchOptions = [
    {
        value: 'User_EmailAddress',
        text: 'common__account__email',
        placeholder: 'admin__accounts__search__placeholder--account-email'
    },
    {
        value: 'Company_Name',
        text: 'common__company__name',
        placeholder: 'admin__accounts__search__placeholder--company-name'
    },
    {
        value: 'distributor',
        text: 'common__distributors',
        placeholder: 'admin__accounts__search__placeholder--distributor-name'
    },
    {
        value: '',
        text: 'common__all',
        placeholder: 'admin__accounts__search__placeholder--search-criteria'
    },
];
