import { Constant } from '@hme-cloud/utility-common';
import semver from 'semver';
import { CommonConstants } from 'Constants';

const { BuildVersions } = Constant;
const {
    upgradeType: { full },
    deviceType: { zoomNitro },
} = CommonConstants;

export const getAvailableDeviceUpgradeVersions = ({ device, deviceUpgradeVersions }) => {
    const deviceVersionSemver = BuildVersions.formatVersion(device.Device_MainVersion) || '0.0.0';
    const zoomNitroMinSemver = BuildVersions.formatVersion(zoomNitro.entryVersion);
    const isNitro = semver.gt(deviceVersionSemver, zoomNitroMinSemver);

    const avaialableUpgradeVersions = deviceUpgradeVersions.reduce((acc, v) => {
        const versionSemver = BuildVersions.formatVersion(v.Version);

        if (!isNitro) {
            // if zoom, only show upgrades up to 3.99.99, or full (conversion) upgrades to zoom nitro
            if (v.UpgradeType === full) {
                acc[v.Version] = v;
            } else if (semver.lt(versionSemver, zoomNitroMinSemver) && semver.gt(versionSemver, deviceVersionSemver)) {
                acc[v.Version] = v;
            }
        } else {
            // For Nitros, use the patch upgrade type
            if (semver.gt(versionSemver, deviceVersionSemver) && v.UpgradeType !== full) {
                acc[v.Version] = v;
            }
        }

        return acc;
    }, {});

    return Object.values(avaialableUpgradeVersions).sort((a, b) => {
        return semver.gt(a.Version, b.Version) ? 1 : -1;
    });
};
