import { createSettingsTask } from 'services/Store';
import { getAllDevices } from 'services/Device';
import { SEARCH_TYPES } from './SearchSection/FiltersForm';

export { getCountries } from 'services/Countries';
export { getBrands } from 'services/Brands';

const taskNameMask = /[a-zA-Z0-9]/;

export const isTaskNameValid = taskName => taskName && taskNameMask.test(taskName);

export const createStoreSettingsTask = (params, {
    searchType,
    storeBrandId,
    storeCountryId,
    account,
}) => createSettingsTask({
    ...params,
    searchType,
    searchParams: {
        storeBrandId,
        storeCountryId,
        accountId: account && account.Account_ID,
        accountUserEmail: account && account.User_EmailAddress,
    },
});

const getDeviceFilter = ({
    searchType,
    storeBrandId,
    storeCountryId,
    account,
    deviceMinMainVersion,
    deviceMaxMainVersion,
    deviceTypeId,
    deviceIsActive,
}) => {
    const filters = {
        deviceMinMainVersion,
        deviceMaxMainVersion,
        deviceTypeId,
        deviceIsActive,
    };

    switch (searchType) {
        case SEARCH_TYPES.BRAND:
            filters.storeBrandId = storeBrandId;
            filters.storeCountryId = storeCountryId;

            break;
        case SEARCH_TYPES.ACCOUNT:
            filters.accountId = account ? account.Account_ID : '';

            break;

        default:
            break;
    }

    return filters;
};

export const getDevices = (filters) => {
    return getAllDevices(getDeviceFilter(filters));
};
