export class EventEmitter {
    constructor() {
        this.handlers = {};
    }

    trigger(eventName, ...args) {
        const eventHandlers = this.handlers[eventName];

        if (!eventHandlers || eventHandlers.length === 0) {
            return;
        }

        eventHandlers.forEach(handler => handler(...args));
    }

    on(eventName, handler) {
        this.handlers[eventName] = this.handlers[eventName] || [];
        this.handlers[eventName].push(handler);
    }

    off(eventName, handler) {
        if (!this.handlers[eventName]) {
            return;
        }

        this.handlers[eventName] = this.handlers[eventName]
            .filter(eventHandler => eventHandler !== handler);
    }
}
