import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { cond, T } from 'ramda';
import { useLoadCountries } from 'hooks/useLoadCountries';
import { useLoadRegions } from 'hooks/useLoadRegions';
import { useLoadDistributors } from 'hooks/useLoadDistributors';
import { NotificationsList } from 'library/NotificationsList';
import { Loader } from 'library/Loader';
import { useLoadCompanyInfo } from './hooks';
import { Header } from './Header';
import { Footer } from './Footer';
import { CompanyInfoForm } from './CompanyInfoForm';
import { CompanyInfoView } from './CompanyInfoView';
import { companyInfoValidator, saveCompanyInfo, mapCompanyInfo } from './helpers';
import _ from 'underscore';
import './CompanyInfo.scss';

const HOME_URL = '/welcome';
const FORM_FIELD_MIN_LENGTH = 3;

const CompanyInfoSection = cond([
    [({ isAccountOwner }) => isAccountOwner, (props) => <CompanyInfoForm {...props} />],
    [T, (props) => <CompanyInfoView {...props} />],
]);

export const CompanyInfo = ({ isDistributor, isAccountOwner }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const { companyInfo, isCompanyInfoLoading } = useLoadCompanyInfo();
    const { countries, isCountriesLoading } = useLoadCountries();
    const { distributors, isDistributorsLoading } = useLoadDistributors({ shouldLoadDistributors: !isDistributor });
    const { regions, isRegionsLoading } = useLoadRegions(1);

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!_.isEmpty(companyInfo)) {
            let form = mapCompanyInfo(companyInfo);
            if (!_.isEmpty(regions)) {
                const companyRegion = regions.find(({ Abbreviation }) => companyInfo.state === Abbreviation);
                form = {
                    ...form,
                    regionId: companyRegion?.Id || null
                };
            }
            setFormData(form);
        }
    }, [companyInfo, regions]);

    const onCancel = useCallback(() => {
        navigate(HOME_URL);
    }, [navigate]);

    const onContactInfoChange = (data) => {
        setFormData({
            ...formData,
            ...data,
        });
    };

    const onHmeDetailsChange = (data) => {
        setFormData({
            ...formData,
            ...data,
        });
    };

    const onCompanyInfoSave = useCallback(async () => {
        const errors = companyInfoValidator(formData);

        if (errors.status) {
            setFormErrors(errors);
            return;
        }

        setFormErrors({});

        await saveCompanyInfo({ formData, companyInfo, regions, setIsSaving, t });
    }, [formData, regions, t]);

    const isLoading =
        isCompanyInfoLoading || isCountriesLoading || isDistributorsLoading || isRegionsLoading || isSaving;

    const isSubmitEnabled = useMemo(() => {
        if (!formData) {
            return false;
        }

        const { countryId, regionId, regionName } = formData;
        const areRegionFieldsSet = !!(countryId && (regionId || regionName));

        const fieldsToCheck = ['companyName', 'addressLine1', 'city', 'zipcode', 'phoneNumber'];
        const areFieldsSet = fieldsToCheck.reduce(
            (result, field) => result && formData[field] && formData[field].length >= FORM_FIELD_MIN_LENGTH,
            true,
        );

        return areRegionFieldsSet && areFieldsSet;
    }, [formData]);

    const shouldShowDistributors = !isDistributor;

    return (
        <div className="hme-company-info">
            <NotificationsList />
            {isLoading ? (
                <Loader />
            ) : (
                <div className="company-info">
                    <div className="hme-components company-info-content">
                        <Header />
                        <div className="company-info-sections">
                            <CompanyInfoSection
                                isAccountOwner={isAccountOwner}
                                formData={formData}
                                infoData={formData}
                                formErrors={formErrors}
                                countries={countries}
                                regions={regions}
                                distributors={distributors}
                                shouldShowDistributors={shouldShowDistributors}
                                onContactInfoChange={onContactInfoChange}
                                onHmeDetailsChange={onHmeDetailsChange}
                            />
                        </div>
                    </div>
                    {isAccountOwner && (
                        <Footer
                            onSubmit={onCompanyInfoSave}
                            onCancel={onCancel}
                            isSubmitEnabled={isSubmitEnabled && !isSaving}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
