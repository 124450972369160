import { Status as CommonStatus } from '../Common';
import { useStatus } from './hooks/useStatus';

export const Status = () => {
    const {
        rows,
        isLoading,
        noRowsMessage,
        sorting,
        onSortingChange,
        onSearch,
        pagination,
        onPaginationChange
    } = useStatus();

    return (
        <CommonStatus
            partners={rows}
            isLoading={isLoading}
            noRowsMessage={noRowsMessage}
            pagination={pagination}
            sorting={sorting}
            onSortingChange={onSortingChange}
            onSearch={onSearch}
            onPaginationChange={onPaginationChange}
        />
    );
};