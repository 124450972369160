/* eslint-disable react/prop-types */
import classNames from 'classnames';
import { empty } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { gridFiltersCounter } from '../../../../../../helpers/filters';
import { Button } from '../../../../../../library/Button';
import { JoinedFilters } from '../../../../../../library/JoinedFilters';
import { FilterFooter } from '../../../../../../library/JoinedFilters/FilterFooter';
import { Label } from '../../../../../../library/Label';
import { SpanSeparator } from '../../../../../../library/SpanSeparator';
import { SortingRadioGroup } from './SortingRadioGroup';
import { ReactComponent as DropdownIcon } from 'assets/icons/down.svg';
import './TilesHeader.scss';
import { useTranslation } from 'react-i18next';

const options = [
    {
        value: 'smack-talk__tiles-header--new-to-old',
        text: 'smack-talk__tiles-header--new-to-old',
        property: 'dateTime',
        sorting: -1
    },
    {
        value: 'smack-talk__tiles-header--old-to-new',
        text: 'smack-talk__tiles-header--old-to-new',
        property: 'dateTime',
        sorting: 1
    },
    {
        value: 'smack-talk__tiles-header--a-to-z',
        text: 'smack-talk__tiles-header--a-to-z',
        property: 'message',
        sorting: 1
    },
    {
        value: 'smack-talk__tiles-header--z-to-a',
        text: 'smack-talk__tiles-header--z-to-a',
        property: 'message',
        sorting: -1
    }
];
const defaultSortValue = options[0].value;

export const TilesHeader = ({
    isLoading,
    filters,
    filterLables = {},
    filtersCounter,
    resetFilters,
    resetSorts,
    availableFilters,
    onFiltersChange = empty,
    sortSelection = {},
    onSortChange
}) => {
    const [isApplyEnabled, setIsApplyEnabled] = useState(false);
    const [newSelection, setNewSelection] = useState({});
    const [isPopupShown, setIsPopupShown] = useState(false);
    const [newSortingValue, setNewSortingValue] = useState(defaultSortValue);
    const [sortValue, setSortValue] = useState(defaultSortValue);
    const [showHeaderInfo, setShowHeaderInfo] = useState(false);
    const [filterLablesFragment, setFilterLablesFragment] = useState();
    const [sections, setSections] = useState({});
    const { t } = useTranslation();

    const findSortValue = () => {
        const [[property, sorting] = []] = Object.entries(sortSelection);
        const option = options.find(
                (el) => el.property === property && el.sorting === sorting
        ) || {};
        return option.value;
    };

    const onCaretClick = (section) => (e) =>{
        const newSections = { ...sections };
        newSections[section] = sections[section] || {};
        newSections[section].hidden = !newSections[section].hidden;
        setSections(newSections);
    };

    useEffect(() => {
        const fragment = Object.keys(filterLables).reduce((acc, k) => {
            const el = { ...filterLables[k] };
            el.hidden = sections[k] && sections[k].hidden;
            el.fragment = <div className="hme-filter-section-collapsable">
                <Label>{el.title}</Label>
                <DropdownIcon
                    className={classNames({ collapsed: el.hidden })}
                    onClick={onCaretClick(k)}
                />
            </div>;
            acc[k] = el;
            return acc;
        }, {});
        setFilterLablesFragment(fragment);
    }, [filterLables, sections]);

    useEffect(() => {
        const value = findSortValue();
        if (value) {
            setSortValue(value);
            setNewSortingValue(value);
        }
    }, [sortSelection, t]);

    useEffect(() => {
        setNewSelection(filters);
    }, [filters, t]);

    useEffect(() => {
        const counter = gridFiltersCounter(availableFilters, filters, newSelection);
        setIsApplyEnabled(counter > 0 || newSortingValue !== sortValue);
    }, [availableFilters, filters, newSelection, newSortingValue]);

    useEffect(() => {
        setShowHeaderInfo(Boolean(filtersCounter) || sortValue !== defaultSortValue);
    }, [filters, newSortingValue, filtersCounter, sortSelection]);

    const onFilterChange = (newFilter) => {
        const filtered = { ...newSelection, ...newFilter };
        setNewSelection(filtered);
    };

    const popupToggle = () => {
        setIsPopupShown(!isPopupShown);
    };

    const clickFilters = () => {
        popupToggle();
    };

    const resetNew = () => {
        const value = sortValue;
        if (value) {
            setNewSortingValue(value);
        }
        setNewSelection(filters);
        setSections({});
    };

    const onHide = () => {
        if (isPopupShown) {
            setIsPopupShown(false);
        }
        setNewSortingValue(sortValue);
        resetNew();
    };

    const resetFiltersAndSorts = () => {
        resetFilters();
        resetSorts();
    };

    const onApply = () => {
        onFiltersChange(newSelection);
        const { property, sorting } =
            options.find((el) => el.value === newSortingValue) || {};
        onSortChange({ [property]: sorting });
        onHide();
    };

    const FooterComponent = (
        <FilterFooter
            isApplyEnabled={isApplyEnabled}
            onApply={onApply}
            onHide={onHide}
            okLabel="common__apply-filters"
        />
    );

    const SortComponent = (
        <>
            <SortingRadioGroup
                value={newSortingValue}
                options={options}
                onChange={setNewSortingValue}
            />
            <Label className="title-label">{t('common__filter').toUpperCase()}</Label>
        </>
    );
    return (
        <div className="hme-history-tiles-header">
            <Modal
                show={isPopupShown}
                dialogClassName={classNames('hme-history-popup-wrapper', { opened: isPopupShown })}
                className="hme-history-popup-modal"
                backdropClassName="hme-history-popup-backdrop"
                onHide={onHide}
            >
                <JoinedFilters
                    show={isPopupShown}
                    availableFilters={availableFilters}
                    selection={newSelection}
                    onChange={onFilterChange}
                    onHide={onHide}
                    sectionLables={filterLablesFragment}
                    header={<div className="hme-joined-filters-popup-header">
                        <Label className="title-label">{t('common__sort').toUpperCase()}</Label>
                        <span className="hme-joined-filters-popup-close" onClick={onHide}>×</span>
                    </div>}
                    body={SortComponent}
                    footer={FooterComponent}
                />
            </Modal>
            {!isLoading && <div className="hme-history-header-container">
                <Button
                    className={classNames({ 'hme-submit-button-component': showHeaderInfo })}
                    variants={['tile-rounded']}
                    onClick={clickFilters}
                >
                    <span>{t('smack-talk__tiles-header--sort-n-filter').toUpperCase()}</span>&nbsp;
                </Button>
            </div>}
            {showHeaderInfo && <div className="hme-history-header-info">
                <span className="hme-history-header-sorting-selection">{t(newSortingValue)}</span>
                <SpanSeparator />
                <span className="hme-history-header-filters-counter">
                    <strong>{filtersCounter}</strong> {t('common__filters-applied')}
                </span>
                <SpanSeparator />
                <span className="hme-history-header-reset history-reset-filters" onClick={resetFiltersAndSorts}>{t('common__reset')}</span>
            </div>}
        </div>
    );
};
