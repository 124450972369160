import React from 'react';
import { compose, cond, T } from 'ramda';
import { CommonConstants } from 'Constants';
import { withHMELayout } from 'HOCs/withHMELayout';
import { withPageState } from 'HOCs/withPageState';
import { withAccess } from 'HOCs/withAccess';
import { isApplySettingsEnabled } from 'helpers/access';
import { isDistributor } from 'services/Auth';
import { checkAccess } from 'actions/permissions';
import authenticate from 'components/Security/Authentication';
import { accountTypes } from 'constants/accountTypes';
import { CreateTask as PublicCreateTask } from '../CreateTask';
import { CreateTask } from '../../CreateTask';

import './CreateTaskPage.scss';

const REDIRECT_URL = '/settings/applyDeviceSettingsStatus';
const hasDistributorAccess = () =>
    isDistributor() && checkAccess(CommonConstants.externalPermissions.ManageDevicesAdvanced);
const DistributorCreateTask = ({ setPageState }) => (
    <CreateTask setPageState={setPageState} permissionType={accountTypes.DISTRIBUTOR} redirectUrl={REDIRECT_URL} />
);

export const CreateTaskPage = cond([
    [
        isDistributor,
        compose(
            withHMELayout({
                contentClasses: ['public-create-apply-device-settings-task-page'],
            }),
            authenticate,
            withAccess({
                checkAccess: hasDistributorAccess,
            }),
            withPageState({
                loaderText: 'apply-device-settings-new__loading',
                successText: 'apply-device-settings-new__success',
                errorText: 'apply-device-settings-new__failed',
            }),
        )(DistributorCreateTask),
    ],
    [
        T,
        compose(
            withHMELayout(),
            withAccess({
                checkAccess: isApplySettingsEnabled,
            }),
        )(PublicCreateTask),
    ],
]);
