/* eslint-disable react/prop-types */

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Section } from 'library/Section';
import { Toggle } from 'library/Toggle';
import { Tip } from 'library/Tip';
import { InputComponent } from 'components/Inputs';
import { TitledRow } from 'library/TitledRow';
import { Label } from 'library/Label';
import { TitledRowSelect } from 'library/TitledRowSelect';
import { format, FORMAT_TYPES } from 'services/Date';

import './GeneralSection.scss';

const toggles = {
    STORE_NAME: 'StoreName',
    STORE_NUMBER: 'StoreNumber'
};
const tooltipText = 'tooltipText';

export const GeneralSection = ({
    template: {
        TemplateName: templateName,
        StoreLabel: storeLabel,
        CreatedAt: createdAt,
        CreatedBy: createdBy,
        LastUpdateAt: lastUpdateAt,
        LastUpdatedBy: lastUpdatedBy
    } = {},
    onChange,
    usedBy = [],
    isEditing,
    col,
    number,
    disabled = false,
    title,
    className,
    isPreview,
    ...rest
}) => {
    const { t } = useTranslation();

    const sectionEl = useRef(null);

    const onInputChange = ( value ) => {
        onChange({ 'TemplateName': value || null });
    };
    const onToggleChange = ( value ) => {
        onChange({ 'StoreLabel': value });
    };
    return (
        <Section
            col={col}
            number={number}
            disabled={disabled}
            title = {t(title)}
            className = {className}
            ref={sectionEl}
            {...rest}
        >
            <InputComponent
                label={t('common__template-name').toUpperCase()}
                value={templateName}
                onChange={onInputChange}
                disabled={isPreview}
                isReadOnly={isPreview}
            />

            <Toggle value={storeLabel} onChange={onToggleChange} isDisabled={isPreview} isPreview={isPreview} label={
                <>
                    {t('common__store-display').toUpperCase()}&nbsp;
                    <Tip className="leaderboard-templates-general-toggle-tip z-index-300" container={sectionEl}>
                        {t('leaderboard__template__store-display-tooltip')}
                    </Tip>
                </>
            }>
                {[{
                    text: 'common__store__name',
                    value: toggles.STORE_NAME
                }, {
                    text: 'common__store__number--text',
                    value: toggles.STORE_NUMBER
                }]}
            </Toggle>
            {isEditing && <>
                <Label>{t('common__summary').toUpperCase()}</Label>
                <TitledRow
                    title={t('common__created-by').toUpperCase()}
                >
                    <span className="col-left" title={createdBy}>{createdBy}</span>
                    <span className="col-right">{format(FORMAT_TYPES.DATE_AND_TIME, createdAt)}</span>
                </TitledRow>
                <TitledRow
                    title={t('common__updated-by').toUpperCase()}
                >
                    <span className="col-left" title={lastUpdatedBy}>{lastUpdatedBy}</span>
                    <span className="col-right">{format(FORMAT_TYPES.DATE_AND_TIME, lastUpdateAt)}</span>
                </TitledRow>
                <TitledRowSelect
                    title={t('common__used-by').toUpperCase()}
                    label={(<span><strong>{usedBy.length}</strong> {t('common__leaderboards')}</span>)}
                    list={usedBy.map((el) => ({ value: el, text: el }))}
                    className="leaderboard-templates__row--used-by"
                />
            </>}
        </Section>
    );
};

