export const replaceNode = (tree, oldNodeItem, newNode) => {
    return tree.map((treeNode) => {
        if (treeNode.item === oldNodeItem) {
            return newNode;
        }

        if (!treeNode.children || treeNode.children.length === 0) {
            return treeNode;
        }

        return {
            ...treeNode,
            children: replaceNode(treeNode.children, oldNodeItem, newNode),
        };
    });
};
