import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { InputComponent } from 'components/Inputs/InputComponent';
import { Button } from 'library/Button';
import { useAccess } from 'hooks/useAccess';
import { CommonConstants } from 'Constants';

import { Section } from '../Common/Section';
import { StoreAccess } from './StoreAccess';

import './Access.scss';

const { userPermissions } = CommonConstants;

const BUTTON_VARIANTS = ['transparent'];

export const Access = ({ account }) => {
    const { t } = useTranslation();
    const hasViewAllStoresAccess = useAccess(userPermissions.ViewAllStores);

    const storesAccess = useMemo(() => {
        if (account.IsOwner || hasViewAllStoresAccess) {
            return t('profile__account__info-store-access--has-access');
        }

        if (account.store && account.store.stores) {
            return account.store.stores.split(',').join(', ');
        }

        return t('profile__account__info-store-access--no-access');
    }, [account.IsOwner, account.store, hasViewAllStoresAccess, t]);

    return (
        <Section title={t('add-user__access__title')} className="hme-user-access">
            <InputComponent
                value={account.roleName}
                valueClassName="hme-user-access__role-name"
                label="common__role"
                isReadOnly
                className="hme-user-access__role"
            >
                <Button
                    as={Link}
                    variants={BUTTON_VARIANTS}
                    to="/settings/roles"
                    className="hme-user-access__role-link"
                >
                    {t('profile__account__access__roles-link-label')}
                </Button>
            </InputComponent>

            <StoreAccess>{storesAccess}</StoreAccess>
        </Section>
    );
};
