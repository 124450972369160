import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { CommonConstants } from 'Constants';
import { accountTypes } from 'constants/accountTypes';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { getProfile } from 'services/Auth';
import { getStores, filterStoresByDeviceType } from 'services/Snapshots';
import { Loader } from 'library/Loader';
import { InfoBox } from 'library/InfoBox';
import { SettingsInfoSection } from './SettingsInfoSection/SettingsInfoSection';
import { SourceDeviceSection } from './SourceDeviceSection/SourceDeviceSection';
import {
    searchAccountsByEmail,
    loadAccountStores,
    loadDeviceSettingsName,
    searchDevices,
    searchDevicesDebounced,
} from './Controller';
import { INITIAL_DEVICE_SETTINGS_NAME } from './constants';

import './CreateSnapshotForm.scss';

const initialSettingsInfo = {
    name: '',
    nameSuffix: '',
    description: '',
    version: '',
    isBrandApproved: false,
    isTestSnapshot: false,
};

const { deviceType } = CommonConstants;
const SEARCH_ACCOUNT_DEBOUNCE_TIMEOUT = 500;
const SEARCH_MIN_SYMBOLS_AUTOSEARCH = 3;

const INITIAL_DEVICE_IDENTIFIER = {
    value: '',
    serialNumber: '',
    productId: '',
}

const searchAccountsByEmailDebounced = debounce(searchAccountsByEmail, SEARCH_ACCOUNT_DEBOUNCE_TIMEOUT);

const devicesList = [deviceType.zoomNitro.camelCase, deviceType.nexeo.camelCase];
const devicesOptions = devicesList.map(deviceListName => {
    const device = deviceType[deviceListName];
    return {
        value: deviceListName,
        text: device.name,
    };
});

export const CreateSnapshotForm = ({ permissionType, isSnapshotCreationLoading, formErrors, onChange }) => {
const [selectedDeviceType, setSelectedDeviceType] = useState(devicesList[0]);
    const [deviceIdentifier, setDeviceIdentifier] = useState(INITIAL_DEVICE_IDENTIFIER);
    const [deviceSettingsName, setDeviceSettingsName] = useState(INITIAL_DEVICE_SETTINGS_NAME);
    const [accounts, setAccounts] = useState([]);
    const [devices, setDevices] = useState([]);
    const [accountSearch, setAccountSearch] = useState('');
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [storeNumberSearch, setStoreNumberSearch] = useState('');
    const [accountStores, setAccountStores] = useState([]);
    const [filteredAccountStores, setFilteredAccountStores] = useState([]);
    const [settingsInfo, setSettingsInfo] = useState(initialSettingsInfo);
    const [selectedStoreNumber, setSelectedStoreNumber] = useState('');
    const [isAccountsLoading, setIsAccountsLoading] = useState(false);
    const [isAccountLoading, setIsAccountLoading] = useState(false);
    const [isDevicesLoading, setIsDevicesLoading] = useState(false);

    const { t } = useTranslation();

    const isPublic = permissionType === accountTypes.PUBLIC;

    const fullDeviceSettingsName = useMemo(() => {
        if (isPublic) {
            return settingsInfo.name;
        }

        // generate a pattern name for non public accounts
        const { nameSuffix, isBrandApproved } = settingsInfo;
        let generatedName = deviceSettingsName.settingsName || '';

        if (nameSuffix) {
            generatedName = `${generatedName}_${nameSuffix}_`;
        }

        if (isBrandApproved) {
            generatedName = nameSuffix ? `${generatedName}A` : `${generatedName}_A`;
        }

        return generatedName;
    }, [settingsInfo, deviceSettingsName]);

    const filterPublicStores = (stores, selectedDeviceType) => {
        if (selectedDeviceType === deviceType.nexeo.camelCase) {
            return stores;
        }

        if (selectedDeviceType === deviceType.zoomNitro.camelCase) {
            return stores.filter((store) => store.IoTDevice_ID !== null);
        }
    }

    const onDeviceChange = useCallback(
        async (value) => {
            const { User_UID: userUID } = selectedAccount || {};
            const stores = userUID ? await getStores(userUID, value) : [];
            let accountStores = filterStoresByDeviceType(stores, value);

            if (isPublic) {
                accountStores = filterPublicStores(accountStores, value);
            }

            setSelectedDeviceType(value);
            setStoreNumberSearch('');
            setAccountStores(accountStores);

            setDeviceSettingsName(INITIAL_DEVICE_SETTINGS_NAME);
            setDeviceIdentifier(INITIAL_DEVICE_IDENTIFIER);
            setDevices([]);
        },
        [selectedAccount, filterStoresByDeviceType, getStores],
    );

    const onAccountSelected = useCallback(
        async (account) => {
            if (!account || !selectedDeviceType) {
                return;
            }

            setAccountSearch(account.User_EmailAddress);
            loadAccountStores({ account, selectedDeviceType, setAccountStores, setSelectedAccount, setIsAccountLoading });
        },
        [selectedDeviceType, loadAccountStores],
    );

    const onAccountsFound = useCallback((foundAccounts, value) => {
        // handle case when user types full email without selecting it from the list
        const foundAccount = foundAccounts.find((acc) => acc['User_EmailAddress'] === value);

        if (!foundAccount) {
            setAccountStores([]);
            setStoreNumberSearch('');
        }

        setSelectedAccount(foundAccount);
        onAccountSelected(foundAccount);
    }, [onAccountSelected]);

    const resetSearchResults = useCallback(() => {
        searchAccountsByEmailDebounced.cancel();

        setAccounts([]);
        setAccountStores([]);
        setSelectedAccount(null);
        setSelectedStoreNumber('');
        setStoreNumberSearch('');
    }, []);

    const onAccountChange = useCallback(
        (searchValue) => {
            setAccountStores([]);
            setSelectedStoreNumber('');
            setStoreNumberSearch('');

            searchValue.length < SEARCH_MIN_SYMBOLS_AUTOSEARCH
                ? resetSearchResults()
                : searchAccountsByEmailDebounced({
                      searchValue,
                      setAccounts,
                      setIsAccountsLoading,
                      onDone: (foundAccounts) => onAccountsFound(foundAccounts, searchValue),
                  });

            setAccountSearch(searchValue);
        },
        [onAccountsFound, resetSearchResults],
    );

    const onStoreNumberSelected = useCallback(
        (store) => {
            setDeviceIdentifier({
                value: store.Device_SerialNumber || store.Device_Product_ID,
                serialNumber: store.Device_SerialNumber,
                productId: store.Device_Product_ID,
            });
            setSelectedStoreNumber(store.Store_Number);
            setStoreNumberSearch(store.Store_Number);

            searchDevices({
                deviceIdentifier: store.Device_SerialNumber || store.Device_Product_ID,
                deviceType: selectedDeviceType,
                setDevices,
                setIsDevicesLoading
            });

            loadDeviceSettingsName({ deviceSerialNumber: store.Device_SerialNumber, setDeviceSettingsName });
        },
        [loadDeviceSettingsName, selectedDeviceType],
    );

    const onStoreNumberChange = useCallback(
        (value) => {

            setFilteredAccountStores(accountStores.filter((store) => store.Store_Number.includes(value)));
            setStoreNumberSearch(value);
        },
        [accountStores, onStoreNumberSelected],
    );

    const onDeviceIdentifierChange = useCallback(
        (value) => {
            setDeviceIdentifier({
                value,
                serialNumber: '',
                productId: '',
            });

            if (!value) {
                setDeviceSettingsName(INITIAL_DEVICE_SETTINGS_NAME);
                return;
            }

            if (value.length < SEARCH_MIN_SYMBOLS_AUTOSEARCH) {
                return;
            }

            searchDevicesDebounced({
                deviceIdentifier: value,
                deviceType: selectedDeviceType,
                setDevices,
                setIsDevicesLoading
            });
        },
        [searchDevicesDebounced, selectedDeviceType],
    );

    const onDeviceSelected = useCallback(
        ({ serialNumber, productId }) => {
            setDeviceIdentifier({
                value: serialNumber || productId,
                serialNumber,
                productId,
            });
            loadDeviceSettingsName({ deviceSerialNumber: serialNumber, setDeviceSettingsName });
        },
        [loadDeviceSettingsName],
    );

    useEffect(() => {
        if (isPublic) {
            const profile = getProfile();
            onAccountSelected(profile);
        }
    }, [selectedDeviceType]);

    useEffect(() => {
        const snapshotPayload = {
            sourceDeviceSerialNumber: deviceIdentifier.serialNumber,
            sourceDeviceProductId: deviceIdentifier.productId,
            settingName: fullDeviceSettingsName,
            description: settingsInfo.description,
            settingsVersion: deviceSettingsName.settingsVersionName,
            storeNumber: selectedStoreNumber,
            selectedDeviceType,
            isBrandApproved: settingsInfo.isBrandApproved,
            isTestSnapshot: settingsInfo.isTestSnapshot,
        };

        onChange({ snapshotPayload, selectedAccount, accountSearch });
    }, [
        deviceIdentifier,
        fullDeviceSettingsName,
        settingsInfo,
        deviceSettingsName,
        selectedStoreNumber,
        selectedDeviceType,
        selectedAccount,
        accountSearch,
    ]);

    if (isSnapshotCreationLoading) {
        return <Loader variants={['invert']} />;
    }

    return (
        <div className="create-snapshot hme-components">
            <form>
                <div className="create-snapshot-sections">
                    <SourceDeviceSection
                        sectionNumber={1}
                        permissionType={permissionType}
                        deviceTypes={devicesOptions}
                        onDeviceChange={onDeviceChange}
                        selectedDeviceType={selectedDeviceType}
                        accounts={accounts}
                        accountSearch={accountSearch}
                        selectedAccount={selectedAccount}
                        isAccountsLoading={isAccountsLoading}
                        isAccountLoading={isAccountLoading}
                        isDevicesLoading={isDevicesLoading}
                        onAccountChange={onAccountChange}
                        onAccountSelected={onAccountSelected}
                        accountStores={filteredAccountStores}
                        storeNumberSearch={storeNumberSearch}
                        formErrors={formErrors}
                        onStoreNumberChange={onStoreNumberChange}
                        onStoreNumberSelected={onStoreNumberSelected}
                        deviceIdentifier={deviceIdentifier}
                        devices={devices}
                        onDeviceIdentifierChange={onDeviceIdentifierChange}
                        onDeviceSelected={onDeviceSelected}
                    />
                    <SettingsInfoSection
                        permissionType={permissionType}
                        deviceSettingsName={fullDeviceSettingsName}
                        deviceSettingsVersionName={deviceSettingsName.settingsVersionName}
                        sectionNumber={3}
                        settingsInfo={settingsInfo}
                        formErrors={formErrors}
                        onSettingsInfoChange={setSettingsInfo}
                    />
                </div>
                <InfoBox
                    message={'settings_snapshots__create_snapshot__form__warning--save-note'}
                    variants={['info']}
                />
            </form>
        </div>
    );
};
