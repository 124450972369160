import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { compose, equals } from 'ramda';
import { DateLib } from '@hme-cloud/utility-common';

import { NotificationsList } from 'library/NotificationsList';
import { CenterLoader } from 'components/Common/CenterLoader';
import { getCurrentUTCTimestamp } from 'services/Date';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { withHMELayout } from 'HOCs/withHMELayout';
import { getTokenExpirationType, expirationTypes, tokenTypes } from 'constants/token';

import { RenewTokenModal } from './Common/RenewTokenModal';
import { CopyTokenModal } from './Common/CopyTokenModal';
import { TokenFormMap } from './TokenForm';
import { getAPIToken, renewToken } from '../../Controller';
import { VAIO_DEFAULTS, prepareISODateString, renewTokenTypes } from './helpers';

import './EditApiManagementForm.scss';

const DEFAULT_ERRORS = {};
const ALLOWED_RENEW_PERIOD = 15 * 60 * 1000;

export const EditTokenForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const uid = searchParams.get('uid');

    const [formValues, setFormValues] = useState(VAIO_DEFAULTS);
    const [isLoading, setIsLoading] = useState(true);
    const [isRenewTokenInProgress, setIsRenewTokenInProgress] = useState(false);
    const [existingFormValues, setExistingFormValues] = useState(DEFAULT_ERRORS);
    const [isRenewTokenShown, setIsRenewTokenShown] = useState(false);
    const [tokenUID, setTokenUID] = useState('');
    const [email2, setEmail2] = useState('');
    const [email3, setEmail3] = useState('');
    const [isCopyTokenModalShown, setIsCopyTokenModalShown] = useState(false);

    const isTokenExpired = useMemo(() => {
        return getTokenExpirationType(formValues?.expiryDate) === expirationTypes.expired;
    }, [formValues?.expiryDate]);

    useEffect(() => {
        const getToken = async () => {
            setIsLoading(true);
            const token = await getAPIToken(uid);
            setExistingFormValues(token);
            setFormValues(token);

            if (!token) {
                setIsLoading(false);
                return;
            }

            const reciepentEmails = token.emails.filter(m => m.isTokenManager === false) ;
            setEmail2(reciepentEmails[0]?.email);
            setEmail3(reciepentEmails[1]?.email);
            setTokenUID(token.uid)
            setIsLoading(false);
        };
        getToken();
    }, []);

    const closeCopyPopup = useCallback(() => {
        setIsCopyTokenModalShown(false)
        navigate(-1);
    }, []);

    const openRenewTokenModal = useCallback(() => {
        setIsRenewTokenShown(true);
    }, []);

    const closeRenewTokenModal = useCallback(() => {
        setIsRenewTokenShown(false);
    }, []);

    const onRenewToken = useCallback( async (tokenType, updatedEmail2, updatedEmail3,  { specificDate }) => {
        setIsRenewTokenInProgress(true);

        let updateValues = {};

        if(email2 !== updatedEmail2) {
            updateValues['email2'] = updatedEmail2
        }

        if(email3 !== updatedEmail3) {
            updateValues['email3'] = updatedEmail3
        }

        const tokenToSend = {
            tokenUID,
            disablePreviousToken: false,
            actionType: tokenType,
            prevTokenExpirationDate: prepareISODateString(),
            updateValues,
            email2,
            email3
        };

        switch (tokenType) {
            case renewTokenTypes.ORIGINAL_EXP_DATE:
                tokenToSend.prevTokenExpirationDate = formValues.expiryDate;
                break;
            case renewTokenTypes.TODAY_EXP_DATE:
                tokenToSend.disablePreviousToken = true;
                tokenToSend.prevTokenExpirationDate = prepareISODateString();
                break;
            case renewTokenTypes.SPECIFIC_EXP_DATE:
                tokenToSend.prevTokenExpirationDate = specificDate.format(DateLib.FORMAT_TYPES.YEAR_MONTH_DATE + ' 00:00:00.000');
                break;
            default:
                break;
        }

        try {
            const { status, token: newToken } = await renewToken(tokenToSend);

            if (!status) return;

            setFormValues((currentFormValues) => ({
                ...currentFormValues,
                lastRenewedDate: tokenToSend.prevTokenExpirationDate,
                generatedToken: newToken.token.generatedToken
            }));

            closeRenewTokenModal();
            setIsCopyTokenModalShown(true);
            addSuccessNotification('api-token-management__form--token-renew-success', { autoClose: 5000 });
        } catch (err) {
            addErrorNotification('api-token-management__form--token-renew-failed', { autoClose: 5000 });
        } finally {
            setIsRenewTokenInProgress(false);
        }
    }, [formValues, email2, email3]);

    const isRenewTokenDisabled = useMemo(() => {
        if (!formValues || !equals(formValues, existingFormValues) || (existingFormValues.tokenType !== tokenTypes.GIG_DELIVERY && !(formValues.vaioProvider || formValues.cloudAccounts))) {
            return true;
        }

        if (!formValues.lastRenewedDate) {
            return false;
        }

        const lastRenewedDate = new Date(formValues.lastRenewedDate).getTime();
        const newDate = new Date(getCurrentUTCTimestamp()).getTime();

        return newDate - lastRenewedDate < ALLOWED_RENEW_PERIOD;
    }, [formValues?.lastRenewedDate, formValues?.vaioProvider, formValues, existingFormValues]);

    const TokenFormMapComponent = TokenFormMap.get(existingFormValues.tokenType);

    if (isLoading) {
        return <CenterLoader>{t('common__loading')}</CenterLoader>;
    }

    return (
        <>
            <TokenFormMapComponent
                formValues={formValues}
                closeCopyPopup={closeCopyPopup}
                closeRenewTokenModal={closeRenewTokenModal}
                isCopyTokenModalShown={isCopyTokenModalShown}
                isRenewTokenDisabled={isRenewTokenDisabled}
                isRenewTokenInProgress={isRenewTokenInProgress}
                isRenewTokenShown={isRenewTokenShown}
                isTokenExpired={isTokenExpired}
                onOpenRenewTokenModal={openRenewTokenModal}
                onRenewToken={onRenewToken}
            />

            <RenewTokenModal
                isShown={isRenewTokenShown}
                token={formValues}
                recipientEmails={[email2, email3]}
                isOptionsHidden={isTokenExpired}
                isLoading={isRenewTokenInProgress}
                onSubmit={onRenewToken}
                onCancel={closeRenewTokenModal}
            />

            <CopyTokenModal
                tokenName={formValues.tokenName}
                tokenValue={formValues.generatedToken}
                isShown={isCopyTokenModalShown}
                onClose={closeCopyPopup}
            />

            <NotificationsList/>
        </>
    );
};
export const EditAPIManagementFormPartner =
    compose(withHMELayout())(EditTokenForm);
