import React from 'react';
import { T, cond } from 'ramda';
import { useTranslation } from 'react-i18next';

import { CenterLoader } from 'components/Common/CenterLoader';
import { PageContentLayout } from 'library/PageContentLayout';
import { GridFiltersCount } from 'library/GridFiltersCount';
import { SEPARATOR_VARIANTS, Separator } from 'library/Separator';
import { NotificationsList } from 'library/NotificationsList';
import { gridHeaders } from 'pages/StoreFeatures/VaioProviders/helpers';

import { ProvidersList as CommonProvidersList } from '../../../Common/ProvidersSettings';
import { useProvidersList, useProvidersLoader } from '../hooks';

import './Providers.scss';
import { Link } from 'react-router-dom';
import { Button } from 'library/Button';
import { PARTNER_ROUTES } from 'constants/routes';

const separatorVariants = [SEPARATOR_VARIANTS.PURE];

const ProvidersList = ({ providers }) => {
    const { t } = useTranslation();

    const {
        gridFilters,
        visibleProviders,
        availableFilters,
        filtersCount,
        currentPage,
        resultsPerPage,
        filteredProviders,
        sortSelection,
        setGridFilters,
        onFiltersReset,
        onSortChange,
        onPaginationChange
    } = useProvidersList({ providers });

    return (
        <>
            <PageContentLayout.Layout>
                <PageContentLayout.Content className="hme-partner-providers__content">
                    <PageContentLayout.PageTitle title={t('common__provider-settings')} className="hme-partner-providers__header">
                        <Separator variants={separatorVariants} className="hme-partner-providers__header-separator" />
                        <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} />
                        <Link
                            type="button"
                            to={PARTNER_ROUTES.createProviderSettings}
                            className="hme-partner-providers__header-create-provider"
                        >
                            <Button>{`${t('common__create')} ${t('common__settings')}`}</Button>
                        </Link>
                    </PageContentLayout.PageTitle>

                    <div className="hme-partner-providers__main-content">
                        <CommonProvidersList
                            headers={gridHeaders}
                            rows={visibleProviders}
                            filters={gridFilters}
                            availableFilters={availableFilters}
                            onFiltersChange={setGridFilters}
                            onSortChange={onSortChange}
                            sortSelection={sortSelection}
                            page={currentPage}
                            recordsPerPage={resultsPerPage}
                            total={filteredProviders.length}
                            onPaginationChange={onPaginationChange}
                        />
                    </div>
                </PageContentLayout.Content>
            </PageContentLayout.Layout>

            <NotificationsList />
        </>
    );
};

const ProvidersLoader = cond([
    [({ isLoading }) => isLoading, () => {
        const { t } = useTranslation();

        return <CenterLoader>{t('common__loading')}</CenterLoader>;
    }],
    [T, (props) => <ProvidersList {...props} />]
]);

export const Providers = () => {
    const loadingData = useProvidersLoader();

    return <ProvidersLoader {...loadingData} />;
};
