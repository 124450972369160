import { useState, useEffect, useCallback } from 'react';
import { getUpgradeVersions } from 'services/Device';

export const useLoadUpgradeVersions = () => {
    const [upgradeVersions, setUpgradeVersions] = useState([]);
    const [isUpgradeVersionsLoading, setIsUpgradeVersionsLoading] = useState(false);
    const [upgradeVersionsLoadingError, setUpgradeVersionsLoadingError] = useState(null);

    const loadUpgradeVersions = useCallback(async () => {
        try {
            setIsUpgradeVersionsLoading(true);
            setUpgradeVersionsLoadingError(null);
            const versions = (await getUpgradeVersions()) || [];

            setUpgradeVersions(versions);
        } catch (e) {
            setUpgradeVersions([]);
            setUpgradeVersionsLoadingError(e);
        }

        setIsUpgradeVersionsLoading(false);
    }, []);

    useEffect(() => { loadUpgradeVersions(); }, []);

    return { upgradeVersions, isUpgradeVersionsLoading, upgradeVersionsLoadingError, loadUpgradeVersions };
};
