export const DEVICE_SETTINGS_FILTER_OPTIONS = {
    SettingsVersion: {
        title: 'common__snapshot__version',
        allText: 'common__all-snapshot-versions',
    },
    SourceBrand: {
        title: 'common__brand',
        allText: 'common__all-brands',
    },
    SourceSoftwareVersion: {
        title: 'common__snapshot__version',
        allText: 'settings_snapshots__grid__filter-all-source-versions',
    },
};
