import React from 'react'
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import '../Footer/Footer.css'
import './action.css'
import { Config } from '../../Config'
import AuthenticationService, { baseAuthService } from '../Security/AuthenticationService'
import ActionLoader from '../Alerts/ActionLoader'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { checkDevice, connectRemote } from '../../actions/actions'
import Error from '../Actions/Error'
import { withReactRouter } from 'HOCs/withReactRouter';
import { withAccess } from 'HOCs/withAccess';
import { isAdmin } from 'services/Auth';
import { checkAccess } from 'actions/permissions';
import { CommonConstants } from 'Constants';
import PropTypes from 'prop-types';
import PermissionSpecification from 'helpers/PermissionSpecification';

const permissionSpecification = new PermissionSpecification(baseAuthService);

const hasDistributorManageDeviceBasic = permissionSpecification.hasDistributorManageDeviceBasic;

class Connect extends React.Component {
  constructor (props) {
    super(props)
    this.authService = new AuthenticationService(Config.authBaseUrl);
    const { queryParams } = this.props;
    const duid = queryParams.get('duid');

    this.state = {
      showCommonLoader: true,
      deviceError: false,
      connectError: false,
      remoteUrl: '',
      token: this.authService.getToken(),
      errorMessage: '',
      duid
    }
  }

  UNSAFE_componentWillMount () {
    if (this.state.duid) {
      let duid = this.state.duid
      let self = this
      this.props.checkDevice(duid, (device) => {
        if (device.status && device.data.DevicePresent === 1) {
          try {
            self.props.connectRemote(duid, (connectData) => {
              if (connectData.data) {
                let url = connectData.data.data
                // url = url.replace('dev', 'uat')
                this.setState({ remoteUrl: url, showCommonLoader: false })
              } else {
                this.setState({
                  errorMessage: 'device-connect__error--zomm-unreachable',
                  connectError: true
                })
              }
            })
          } catch (error) {
            this.setState({
              errorMessage: 'device-connect__error--zomm-unreachable',
              connectError: true
            })
          }
        } else {
          this.setState({
            errorMessage: 'device-connect__error--bad-id',
            connectError: true
          })
        }
      })
    }
  }

  render () {
    return (<section>
      {this.renderConnect()}
      {<Error error={this.state.deviceError} />}
      {this.renderServiceError()}
    </section>)
  }

  renderServiceError () {
    const { t } = this.props;

    if (this.state.connectError) {
      return (<div className='col1'>
        <div className='forms clear'>
          <div className='error redbox_err_all redbox_marg'>
            <ul>
              <li>{t(this.state.errorMessage)}</li>
            </ul>
          </div>
        </div>
      </div>)
    } else {
      return (<div />)
    }
  }

  renderConnect () {
    // if (!this.state.deviceError && !this.state.connectError) {
    return (
      <section>
        <ActionLoader showLoader={this.state.showCommonLoader} message='device-connect__loading' />
        <div className={'fteaser ' + (!this.state.showCommonLoader ? '' : 'hidden')}>
          <div className='embed-container'>
            {this.renderEmbededUrl()}
          </div>
        </div>
      </section>)
    // } else {
    //   return (<div />)
    // }
  }

  renderEmbededUrl () {
    if (this.state.remoteUrl) {
      return (
        <iframe src={this.state.remoteUrl} allowFullScreen/>
      )
    } else {
      return (<div />)
    }
  }
}

Connect.propTypes = {
    queryParams: PropTypes.object
};

function mapStateToProps (state) {
  return {
    headers: state.headers,
    permissions: state.permissions
  }
}
function matchDispatchToProps (dispatch) {
  return bindActionCreators(
    {
      checkDevice: checkDevice,
      connectRemote: connectRemote
    }, dispatch
  )
}

const hasConnectAccess = hasDistributorManageDeviceBasic || isAdmin() || checkAccess(CommonConstants.userPermissions.RemoteConnect);

export default compose(
        connect(mapStateToProps, matchDispatchToProps),
        withTranslation(),
        withReactRouter,
        withAccess({
          checkAccess: () => hasConnectAccess
        })
)(Connect);
