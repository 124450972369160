import { useState, useEffect, useCallback } from 'react';
import { getPeripheralDevices } from '../Controller';

export const useLoadPeripheralDevices = (deviceUID) => {
    const [peripheralDevices, setPeripheralDevices] = useState(null);
    const [isPeripheralDevicesLoading, setIsPeripheralDevicesLoading] = useState(true);
    const [peripheralDevicesLoadingError, setPeripheralDevicesLoadingError] = useState(null);

    const loadPeripheralDevices = useCallback(async (duid) => {
        if (!duid) return;

        try {
            setIsPeripheralDevicesLoading(true);
            setPeripheralDevicesLoadingError(null);
            const devices = (await getPeripheralDevices(duid)) || null;

            setPeripheralDevices(devices);
        } catch (e) {
            console.error('Error while loading peripheral device', e);
            setPeripheralDevices(null);
            setPeripheralDevicesLoadingError(e);
        }

        setIsPeripheralDevicesLoading(false);
    }, []);

    useEffect(() => { loadPeripheralDevices(deviceUID); }, [deviceUID]);

    return { peripheralDevices, isPeripheralDevicesLoading, peripheralDevicesLoadingError, loadPeripheralDevices };
};
