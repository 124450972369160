import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AccountEmailHeader } from 'library/AccountEmailHeader';
import { Title } from 'library/Title';
import { Button } from 'library/Button';

import './Header.scss';

export const Header = ({ hasCreateAccess, addRoleUrl, withAccountEmail }) => {
    const { t } = useTranslation();

    return (
        <div className='hme-roles__header'>
            <Title>{t('common__roles')}</Title>
            { hasCreateAccess && addRoleUrl &&
                <Link to={addRoleUrl} className='hme-roles__header__create-button'>
                    <Button>{t('roles__create-role-btn')}</Button>
                </Link>
            }
            { withAccountEmail &&
                 <AccountEmailHeader />
            }
        </div>
    );
};

Header.propTypes = {
    hasCreateAccess: PropTypes.bool,
    withAccountEmail: PropTypes.bool,
    addRoleUrl: PropTypes.string,
};
