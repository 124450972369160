import { DEVICE_SEARCH_TYPES } from '../Header';

export const getRowValues = (rows, deviceSearchType) => {
    return rows.map(({
        destinationDevices,
        sourceDevice = {},
        sourceDeviceSN,
        accountEmail,
    }, index) => {
        const {
            Store_Name = '',
            Store_Number = '',
            Device_SerialNumber = '',
            User_EmailAddress = '',
            Device_Product_ID = '',
        } = sourceDevice;

        const targetDeviceValues = deviceSearchType === DEVICE_SEARCH_TYPES.SOURCE ?
            '' :
            destinationDevices.map(({
                Device_SerialNumber,
                Device_Product_ID,
                User_EmailAddress,
                Store_Name,
                Store_Number,
            }) => [
                Device_SerialNumber,
                Device_Product_ID,
                User_EmailAddress,
                Store_Name,
                Store_Number
            ].join(' ')).join(' ');

        const sourceDevicesValues = deviceSearchType === DEVICE_SEARCH_TYPES.TARGET ?
            '' :
            [
                sourceDeviceSN,
                Device_SerialNumber,
                Device_Product_ID,
                User_EmailAddress,
                accountEmail,
                Store_Name,
                Store_Number,
            ].join('');

        return {
            value: [
                sourceDevicesValues,
                targetDeviceValues,
            ].join(' ').toLowerCase(),
            index,
        };
    });
};
