import { vaioSettings } from '../../VaioProviders/helpers';

export const toDTO = (values) => {
    const isOnPremiseLocationType = values.locationType === vaioSettings.locationTypes.onPremise;

    // If location type is on-premise, set key expiration and vaio key to null
    // Database expects ports to be integers. Value is passed to device as empty string if value is 0
    return {
        ...values,
        keyExpiration: !isOnPremiseLocationType ?
            (typeof values.keyExpiration === 'object' ? values.keyExpiration.getDate() : values.keyExpiration) : null,
        vaioKey: !isOnPremiseLocationType ? values.vaioKey : null,
        httpsPort: Number(values.httpsPort) || 0,
        wssPort: Number(values.wssPort) || 0
    };
};

export const toCreateDTO = (values, email) => {
    return {
        ...toDTO(values),
        createdBy: email
    };
};

export const toUpdateDTO = (values, email) => {
    return {
        ...toDTO(values),
        updatedBy: email
    };
};
