import React, { useState, useRef, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import {
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    REDO_COMMAND,
    UNDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    FORMAT_TEXT_COMMAND,
    COMMAND_PRIORITY_LOW,
    $getSelection,
    $isRangeSelection
} from 'lexical';
import { $isParentElementRTL } from '@lexical/selection';
import { $isHeadingNode } from '@lexical/rich-text';
import { $isListNode, ListNode } from '@lexical/list';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';

import { BLOCK_TYPES, SUPPORTED_BLOCK_TYPES } from '../../constants';
import { getSelectedNode } from '../../utils';
import { BlockOptionsDropdownList } from './BlockOptionsDropdownList';
import { FloatingLinkEditor } from '../../Common/FloatingLinkEditor';
import { ToolbarButton } from './ToolbarButton';

import { ReactComponent as UndoIcon } from 'assets/icons/undo.svg';
import { ReactComponent as RedoIcon } from 'assets/icons/redo.svg';
import { ReactComponent as ParagraphIcon } from 'assets/icons/paragraph.svg';
import { ReactComponent as BoldIcon } from 'assets/icons/bold.svg';
import { ReactComponent as ItalicIcon } from 'assets/icons/italic.svg';
import { ReactComponent as UnderlineIcon } from 'assets/icons/underline.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';

export const ToolbarPlugin = () => {
    const [editor] = useLexicalComposerContext();

    const toolbarRef = useRef(null);
    const tollbarActionsDropdowRef = useRef(null);

    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [blockType, setBlockType] = useState('paragraph');
    const [, setSelectedElementKey] = useState(null);
    const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState(false);
    const [, setIsRTL] = useState(false);
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);

            if (elementDOM !== null) {
                setSelectedElementKey(elementKey);

                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                    const type = parentList ? parentList.getTag() : element.getTag();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType();
                    setBlockType(type);
                }
            }

            // Update text format
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(selection.hasFormat('underline'));
            setIsRTL($isParentElementRTL(selection));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();

            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
                editor.registerUpdateListener(({ editorState }) => {
                    editorState.read(() => {
                        updateToolbar();
                    });
                }),
                editor.registerCommand(
                        SELECTION_CHANGE_COMMAND,
                        () => {
                            updateToolbar();
                            return false;
                        },
                        COMMAND_PRIORITY_LOW
                ),
                editor.registerCommand(
                        CAN_UNDO_COMMAND,
                        (payload) => {
                            setCanUndo(payload);
                            return false;
                        },
                        COMMAND_PRIORITY_LOW
                ),
                editor.registerCommand(
                        CAN_REDO_COMMAND,
                        (payload) => {
                            setCanRedo(payload);
                            return false;
                        },
                        COMMAND_PRIORITY_LOW
                )
        );
    }, [editor, updateToolbar]);

    const insertLink = useCallback(() => {
        if (!isLink) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
        } else {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
    }, [editor, isLink]);

    const makeBold = useCallback(() => {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
    }, [editor]);

    const makeItalic = useCallback(() => {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
    }, [editor]);

    const makeUnderline = useCallback(() => {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
    }, [editor]);

    const undo = useCallback(() => {
        editor.dispatchCommand(UNDO_COMMAND);
    }, [editor]);

    const redo = useCallback(() => {
        editor.dispatchCommand(REDO_COMMAND);
    }, [editor]);

    const toggleDropdown = useCallback(() => {
        setShowBlockOptionsDropDown((prevShowBlock) => !prevShowBlock);
    }, []);

    return (
        <div className="toolbar" ref={toolbarRef}>
            <ToolbarButton
                disabled={!canUndo}
                onClick={undo}
                className="spaced"
            >
                <UndoIcon className="format" />
            </ToolbarButton>
            <ToolbarButton
                disabled={!canRedo}
                onClick={redo}
            >
                <RedoIcon className="format" />
            </ToolbarButton>
            <Divider />
            {SUPPORTED_BLOCK_TYPES.has(blockType) && (
                <div ref={tollbarActionsDropdowRef}>
                    <ToolbarButton
                        className="block-controls"
                        onClick={toggleDropdown}
                    >
                        <ParagraphIcon className={classNames('icon', blockType)} />
                        <span className="text">{BLOCK_TYPES[blockType]}</span>
                        <ChevronDownIcon className="chevron-down" />
                    </ToolbarButton>

                    {showBlockOptionsDropDown &&
                        createPortal(
                                <BlockOptionsDropdownList
                                    editor={editor}
                                    blockType={blockType}
                                    toolbarRef={toolbarRef}
                                    toolbarActionsDropdowRef={tollbarActionsDropdowRef}
                                    setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
                                />,
                                document.body
                        )}
                    <Divider />
                </div>
            )}
            <ToolbarButton
                onClick={makeBold}
                className="spaced"
                active={isBold}
            >
                <BoldIcon className="format" />
            </ToolbarButton>
            <ToolbarButton
                onClick={makeItalic}
                className="spaced"
                active={isItalic}
            >
                <ItalicIcon className="format italic" />
            </ToolbarButton>
            <ToolbarButton
                onClick={makeUnderline}
                className="spaced"
                active={isUnderline}
            >
                <UnderlineIcon className="format underline" />
            </ToolbarButton>
            <ToolbarButton
                onClick={insertLink}
                className="spaced"
                active={isLink}
            >
                <LinkIcon className="format link" />
            </ToolbarButton>
            {isLink && createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
        </div>
    );
};

const Divider = () => {
    return <div className="divider" />;
};
