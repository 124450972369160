import * as React from 'react';
import CommonStoreMethod from './CommonStoreMethod';
import AuthFactory from '../../helpers/AuthFactory';
import DeviceStatusImage from './DeviceStatusImage';

/**
  * Function component to render device status in the table
  *
  * @param {object} props - contains device data and allow remote connect flag
  *
  * @return {DeviceStatus} component with image and text which identifying current state of the device
  */
export const DeviceStatus = (props) => {
    const { hasRemoteConnectPermission } = AuthFactory.PermissionSpecification;
    const { device, allowRemoteConnect } = props;

    const Status = () => new CommonStoreMethod().renderStatus(device, hasRemoteConnectPermission && allowRemoteConnect);
    return (
        <td>
            <DeviceStatusImage isActive={Boolean(device.Device_IsActive)} />
            <span style={{ padding: '0 5px' }}>
                <Status />
            </span>
        </td>
    );
};
