import { SingleSelect, SINGLE_SELECT_VARIANTS } from 'library/SingleSelect';

import { useLoadClassifications } from './hooks/useLoadClassifications';
import { useTranslation } from 'react-i18next';

const defaultVariants = [SINGLE_SELECT_VARIANTS.NO_LABEL]

export const StoreClassification = ({
    value,
    label = 'admin__store__classification__label',
    onChange
}) => {
    const { classifications, isLoading } = useLoadClassifications();
    const { t } = useTranslation();

    return (
        <SingleSelect
            value={value}
            label={t(label)}
            items={classifications}
            scrollOnOpen={true}
            onChange={onChange}
            isDisable={isLoading}
            variants={defaultVariants}
        />
    );
};
