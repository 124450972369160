import React from 'react';
import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import { NotificationsList } from 'library/NotificationsList';

import { DeviceStatus } from './DeviceStatus';
import { useZoomNexeoSystemStatus, useDevicesGrid } from './hooks';

import './SystemStatus.scss';

const PublicSystemStatusComponent = () => {
    const {
        device,
        peripheralDevices,
        deviceSettings,
        deviceScheduledUpgrade,
        StatusInfoFields,
        isDataLoading,
        error,
        onDeviceReboot,
        onDeviceReconnect
    } = useZoomNexeoSystemStatus();

    const deviceGridProps = useDevicesGrid({ device, peripheralDevices, deviceScheduledUpgrade });

    return (
        <>
            <NotificationsList />
            <DeviceStatus
                device={device}
                deviceSettings={deviceSettings}
                StatusInfoFields={StatusInfoFields}
                deviceGridProps={deviceGridProps}
                isLoading={isDataLoading}
                error={error}
                onDeviceReboot={onDeviceReboot}
                onDeviceReconnect={onDeviceReconnect}
            />
        </>
    );
};

export const PublicSystemStatus = compose(
        withHMELayout({
            contentClasses: ['public-zoom-nexeo-system-status-page']
        })
)(PublicSystemStatusComponent);
