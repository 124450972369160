import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cond, T } from 'ramda';
import classNames from 'classnames';

import { useClickOutSide } from 'hooks/useClickOutSide';
import { Checkbox, CHECKBOX_VALUES } from 'library/Checkbox';
import { Loader } from 'library/Loader';
import { Label } from 'library/Label';
import { itemProps } from '../types/itemProps';

import './SelectDropdown.scss';

const SelectDropdownComponent = ({ items = [], selections = [], withSelectAll = false, isLoading = false, noRecordsMessage = 'common__error--no-data-found', onHide, onChange }) => {
    const ref = useRef(null);
    const { t } = useTranslation();

    const outSideClickHandler = useCallback(() => {
        onHide && onHide();
    }, [onHide]);

    useClickOutSide(ref, outSideClickHandler);

    const onItemToggle = (checked, item) => {
        const newSelection = checked ? [...selections, item] : selections.filter((selectedItem) => selectedItem.value !== item.value);

        onChange && onChange(newSelection);
    };

    const isAllSelected = useMemo(() => {
        if (selections.length === 0) {
            return CHECKBOX_VALUES.UNCHECKED;
        }

        const isAllChecked = items.length === selections.length

        return isAllChecked ? CHECKBOX_VALUES.CHECKED : CHECKBOX_VALUES.PARTIAL;
    }, [items, selections]);

    const selectionsValueTextMap = selections.reduce((acc, { value, text }) => {
        acc[value] = text;
        return acc;
    }, {});

    if (isLoading) {
        return (
            <div className="hme-multi-select__select-dropdown" ref={ref}>
                <div className="hme-auto-complete-suggestions-loader">
                    <Loader variants={['invert']} />
                    <Label>{t('common__loading')}</Label>
                </div>
            </div>
        );
    }

    return (
        <div className={
            classNames('hme-multi-select__select-dropdown', {
                'hme-multi-select__select-dropdown--with-select-all': withSelectAll,
            })
        } ref={ref}>
            {items.length ? (
                <>
                    {
                        withSelectAll && (
                            <Checkbox
                                label={t('common__select-all')}
                                checked={isAllSelected}
                                onChange={(checked) => onChange(checked ? items : [])}
                            />
                        )
                    }
                    <div className="hme-multi-select__select-dropdown__items">
                        {items.map(({ value, text, disabled }) => (
                            <Checkbox
                                disabled={disabled}
                                key={value}
                                label={text}
                                checked={!!selectionsValueTextMap[value]}
                                onChange={(checked) => onItemToggle(checked, { value, text })}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <>{t(noRecordsMessage)}</>
            )}
        </div>
    );
};

export const SelectDropdown = cond([
    [({ show }) => show, SelectDropdownComponent],
    [T, () => <div></div>],
]);

SelectDropdownComponent.propTypes = {
    items: PropTypes.arrayOf(itemProps).isRequired,
    selections: PropTypes.arrayOf(itemProps).isRequired,
    isLoading: PropTypes.bool,
    noRecordsMessage: PropTypes.string,
    onHide: PropTypes.func,
    onChange: PropTypes.func,
};
