import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Grid, applySearchValue } from 'components/Common/Grid';
import { Button } from 'library/Button';
import { getSubscription } from 'constants/subscriptions';

import './AccountsSection.scss';

const gridOptions = [
    {
        text: 'common__account__email',
        property: 'accountEmail',
        flex: 40,
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__active',
        property: 'isActive',
        flex: 20,
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__subscription',
        property: 'subscriptionType',
        flex: 40,
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__actions',
        property: 'Actions',
        flex: 10,
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
];

const searchByProps = ['cloudAccountEmail'];

const accountsToRows = ({ accounts, onAccountUnlink, t }) =>
    accounts.map((account) => {
        const subscription = getSubscription(account.subscriptionID, account.subscriptionIsNitro);

        return {
            ...account,
            accountEmail: <Link to={`./../../accounts/${account.cloudAccountID}`}>{account.cloudAccountEmail}</Link>,
            subscriptionType: subscription.name,
            isActive: account.isActive ? t('common__active') : t('common__inactive'),
            Actions: (
                <Button onClick={() => onAccountUnlink(account.cloudAccountID)} variants={['transparent']}>
                    {t('common__remove')}
                </Button>
            ),
        };
    });

export const AccountsSection = ({ accounts = [], searchValue, isLoading, onAccountUnlink }) => {
    const [rows, setRows] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        setRows(
            accountsToRows({ accounts: applySearchValue(accounts, searchByProps, searchValue), onAccountUnlink, t }),
        );
    }, [accounts, searchValue, searchByProps]);

    return (
        <div className="dxs-feed-accounts-section">
            <Grid
                headers={gridOptions}
                rows={rows}
                isLoading={isLoading}
                rowKey="cloudAccountID"
                noRecordsMessage={t('accounts__no-accounts')}
            />
        </div>
    );
};
