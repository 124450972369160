// device upgrades with full & patch upgrade types have same version names but different ids,
// so we need to add missing ids to selected versions
export const collectMissingUpgradeVersionIds = (selectedUpgradeVersions, allUpgradeVersions) => {
    const versionsByDeviceType = allUpgradeVersions.reduce(
        (acc, curr) => ({
            ...acc,
            [curr.deviceTypeID]: curr.versions,
        }),
        {},
    );

    // add missing version ids to selected upgrade versions
    return selectedUpgradeVersions.map((deviceUpgrade) => {
        const { deviceTypeID, versionIDs } = deviceUpgrade;
        const allDeviceTypeVersions = versionsByDeviceType[deviceTypeID];

        // get version names from selected ids
        const selectedVersionNames = allDeviceTypeVersions
            .filter(({ id }) => versionIDs.includes(id))
            .map(({ version }) => version);

        // collect missing version ids from selected version names
        const selectedVersionIDs = allDeviceTypeVersions
            .filter(({ version }) => selectedVersionNames.includes(version))
            .map(({ id }) => id);

        return {
            ...deviceUpgrade,
            versionIDs: selectedVersionIDs,
        };
    });
};
