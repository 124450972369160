/* eslint-disable complexity */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NotificationsList } from 'library/NotificationsList';
import { useTranslation } from 'react-i18next';
import { Grid } from 'components/Common/Grid';
import { formatFilters, formatRows, gridHeaders, paginationFilter, sortProvidersByColumn } from './helpers';
import { getVaioProviders, removeVaioProvider } from 'services/StoreFeatures';
import { Loader } from 'library/Loader';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';
import { Header } from './Header';
import { accountTypes } from 'constants/accountTypes';
import { Paginate } from 'library/Paginate';
import { PAGE_DATA } from 'constants/paginationDefault';

import './VaioProviders.scss';

// Data coming from Database is already sorted by keyExpiration
export const INIT_SORT_SELECTION = { updatedDate: 1 };
const INIT_RESULTS_PER_PAGE = 10; // Must match minimum pageSizes in Paginate component

const errorKeys = {
    PROVIDER_NOT_FOUND: 'vaio_provider_provider-not-found',
    PROVIDER_NOT_DELETED: 'vaio_grid_notification-error-removing-provider',
    PROVIDER_KEY_NOT_DELETED: 'vaio_grid_notification-error-removing-provider--key',
    PROVIDER_WITH_ACTIVE_DEVICES_NOT_DELETED: 'vaio_grid_notification-error-removing-provider--active-devices',
};

export const sortColumns = {
    keyExpirationDate: 'keyExpiration',
    updatedDate: 'originalUpdatedDate'
};

export const VaioProviders = ({ permissionType = accountTypes.PUBLIC }) => {
    const { t } = useTranslation();
    const [providers, setProviders] = useState([]); // Maintains full list
    const [filteredProviders, setFilteredProviders] = useState([]); // Work with filtered list
    const [visibleProviders, setVisibleProviders] = useState([]); // Filtered Providers to show w/ pagination
    const [isLoading, setIsLoading] = useState(true);
    const [providerToRemove, setProviderToRemove] = useState(null);
    const [showRemovePopup, setShowRemovePopup] = useState(false);
    const [availableFilters, setAvailableFilters] = useState([]);
    const [sortSelection, setSortSelection] = useState(INIT_SORT_SELECTION);
    const [filtersCount, setFiltersCount] = useState(0);
    const [resultsPerPage, setResultsPerPage] = useState(INIT_RESULTS_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(0);
    const [gridFilters, setGridFilters] = useState({
        name: [],
        outboundAudio: [],
        audioMode: [],
        createdBy: [],
        updatedBy: [],
        botAudioSourceType: []
    });

    const handlePopup = (provider) => {
        setShowRemovePopup(!showRemovePopup);
        setProviderToRemove(provider);
    };

    const onSearchItemsChange = (items, searchValue) => {
        const { searchText } = searchValue;

        const filteredResults = searchText ? items.filter((item) => {
            return item.text ? item.text.toLowerCase().includes(searchText.toLowerCase()) : false;
        }) : items;

        return filteredResults;
    };

    // sortByColumn is like {name: 1}
    // -1 = ascending & 1 = descending
    const onSortChange = (sortByColumn) => {
        let [columnName] = Object.keys(sortByColumn);
        const sortDirection = sortByColumn[columnName];

        // handle overriden column names for sorting
        columnName = sortColumns[columnName] || columnName;

        const sortedProviders = sortProvidersByColumn(filteredProviders, columnName, sortDirection);
        setFilteredProviders(sortedProviders);
        setSortSelection(sortByColumn);
        setVisibleProviders(resultsPerPage === -1 ? sortedProviders : sortedProviders.slice(0, resultsPerPage));
        setCurrentPage(0);
    };

    const onFiltersReset = () => {
        setFilteredProviders(providers);
        const resetFilters = { ...gridFilters };
        for (const filter of availableFilters) {
            resetFilters[filter.property] = filter.items.map((item) => item.value);
        }
        setGridFilters(resetFilters);
    };

    const setFilters = (formattedProviders) => {
        const format = formatFilters(formattedProviders, onSearchItemsChange, t);
        setAvailableFilters(format.formattedAvailableFilters);
        setGridFilters(format.formattedGridFilters);
    };

    const resetProviders = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await getVaioProviders();
            const formattedRows = formatRows(response, handlePopup, t);
            const shownProviders = paginationFilter(formattedRows, currentPage, resultsPerPage);

            setProviders(formattedRows);
            setFilteredProviders(formattedRows);
            setVisibleProviders(shownProviders);
            setFilters(formattedRows);
            setSortSelection(INIT_SORT_SELECTION);
        } catch (e) {
            addErrorNotification(t('vaio_provider__grid__error-failed-to-retrieve-providers'));
        }
        setIsLoading(false);
    }, [currentPage, resultsPerPage]);

    // Get all providers on load
    useEffect(() => {
        resetProviders();
    }, []);

    useEffect(() => {
        setVisibleProviders(resultsPerPage === -1 ? filteredProviders : filteredProviders.slice(0, resultsPerPage));
    },[resultsPerPage]);

    const handleRemove = useCallback(async () => {
        try {
            const { uid: providerUID, name: providerName } = providerToRemove;
            await removeVaioProvider(providerUID, providerName);
            await resetProviders();
            addSuccessNotification(t('vaio_grid_notification-successfully-removed', { providerName }));
        } catch (e) {
            addErrorNotification(
                e.response.data.message
                    ? t(errorKeys[e.response.data.message] || e.response.data.message)
                    : t('vaio_grid_notification-error-removing-provider'),
            );
        }
        setShowRemovePopup(false);
    }, [providerToRemove]);

    useEffect(() => {
        let count = 0;

        // Get providers that match all filters
        const filtered = providers.filter((provider) => {
            for (const key of Object.keys(gridFilters)) {
                if (!gridFilters[key].includes(provider[key])) {
                    return false;
                }
            }
            return true;
        });

        for (const filter of availableFilters) {
            if (filter.items.length !== gridFilters[filter.property].length) {
                count += 1;
            }
        }

        setFilteredProviders(filtered);
        setVisibleProviders(resultsPerPage === -1 ? filtered : filtered.slice(0, resultsPerPage));
        setCurrentPage(0);
        setFiltersCount(count);
    }, [gridFilters]);

    const onPaginationChange = ({ page, recordsPerPage }) => {
        const shownProviders = paginationFilter(filteredProviders, page, recordsPerPage);
        setCurrentPage(page);
        setResultsPerPage(recordsPerPage);
        setVisibleProviders(shownProviders);
    };

    const popupMessage = (
        <>
            <p className="vaio-providers-popup-confirm-line">
                {t('vaio_grid_remove-popup-message')}
            </p>
            <p className="vaio-providers-popup-confirm-line-bold">
                {providerToRemove && providerToRemove.name}
            </p>
        </>
    );

    return (
        <div className="hme-page-component vaio-providers">
            <NotificationsList />
            <ConfirmPopupComponentGeneric
                show={showRemovePopup}
                onHide={() => handlePopup(null)}
                title={t('common__double-checking')}
                message={popupMessage}
                actions={[
                    {
                        children: t('common__cancel'),
                        onClick: () => handlePopup(null)
                    },
                    {
                        children: t('vaio_grid_remove-popup-delete-button'),
                        variants: ['submit'],
                        onClick: handleRemove
                    }
                ]}
            />
            <div className="vaio-providers-page">
                <Header filtersCount={filtersCount} onFiltersReset={onFiltersReset} />
                {isLoading ?
                    <Loader />
                    :
                    <>
                        <Grid
                            headers={gridHeaders}
                            rows={visibleProviders}
                            isLoading={isLoading}
                            noRecordsMessage={t('vaio_grid_no-providers-found')}
                            filters={gridFilters}
                            availableFilters={availableFilters}
                            onFiltersChange={setGridFilters}
                            onSortChange={onSortChange}
                            sortSelection={sortSelection}
                            className="vaio-providers__grid"
                        />
                        <Paginate
                            className="vaio-providers__paginate"
                            page={currentPage}
                            recordsPerPage={resultsPerPage}
                            total={filteredProviders.length}
                            onChange={onPaginationChange}
                            pageSizes={PAGE_DATA.PAGE_SIZES_ADMIN}
                        />
                    </>
                }

            </div>
        </div>
    );
};

// set prop types
VaioProviders.propTypes = {
    permissionType: PropTypes.string
};
