import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'library/Radio';
import { Grid } from 'components/Common/Grid';
import { TileList } from 'library/TileList';
import { deviceResultHeaders, deviceResultMobileHeaders } from '../../constants/gridHeaders';

import './StoreDevices.css';

export const StoreDevices = ({
    selectedDevice,
    storeDevices,
    isLoading,
    onDeviceSelection,
    checkDisabled,
    checkSelected,
}) => {
    const { t } = useTranslation();

    const storesToRows = useCallback(
        (foundStoreDevices, device, onDeviceChosen) =>
            foundStoreDevices.map((storeDevice) => {
                return {
                    ...storeDevice,
                    SelectDevice: (
                        <Radio
                            key={storeDevice.Device_ID}
                            variants={[]}
                            checked={(device && device.Device_ID === storeDevice.Device_ID) || false}
                            onChange={() => onDeviceChosen && onDeviceChosen(storeDevice)}
                            disabled={checkDisabled && checkDisabled(storeDevice)}
                            label={t('common__select')}
                        />
                    ),
                };
            }),
        [checkDisabled],
    );

    const rowsWithRadioButtons = useMemo(
        () => storesToRows(storeDevices, selectedDevice, onDeviceSelection),
        [storeDevices, selectedDevice, onDeviceSelection],
    );

    return (
        <div className="merge-devices__store-devices-results">
            <Grid
                rows={rowsWithRadioButtons}
                isLoading={isLoading}
                headers={deviceResultHeaders}
                noRecordsMessage={t('common__no-devices--found')}
                checkDisabled={checkDisabled}
            />
            <TileList
                headers={deviceResultMobileHeaders}
                rows={rowsWithRadioButtons}
                radioColumn="SelectDevice"
                isLoading={isLoading}
                noRecordsMessage="common__no-devices--found"
                checkSelected={checkSelected}
                checkDisabled={checkDisabled}
            />
        </div>
    );
};
