import React from 'react';
import { SourceDeviceInput } from '../SourceDeviceInput';
import { SourceDeviceSettings } from '../SourceDeviceSettings';

export const SerialNumberForm = ({ params, onParamsChange, devices, deviceType }) => {
    const { sourceDevice, allStores, sourceDeviceSettingGroups } = params;

    const onSourceDeviceChanged = (sourceDevice) => {
        onParamsChange({
            ...params,
            sourceDevice,
            sourceDeviceSettingGroups: [],
            targetDevices: [],
            settingTemplateID: '',
        });
    };

    const onSourceDeviceSettingGroupChanged = (settingGroupsToApply) => {
        onParamsChange({
            ...params,
            sourceDeviceSettingGroups: settingGroupsToApply,
            targetDevices: devices.filter((d) => d.Device_UID && d.Device_UID !== sourceDevice.Device_UID),
        });
    };

    return (
        <>
            <div className="inputs-wrapper">
                <SourceDeviceInput
                    value={sourceDevice}
                    onChange={onSourceDeviceChanged}
                    devices={devices}
                    deviceType={deviceType}
                />
            </div>
            {sourceDevice && (
                <div>
                    <SourceDeviceSettings
                        devices={allStores}
                        values={sourceDeviceSettingGroups}
                        onChange={onSourceDeviceSettingGroupChanged}
                    />
                </div>
            )}
        </>
    );
};