import { useCallback, useState } from 'react';

export const useFilterComponentSection = ({ initialIsOpen = false } = {}) => {
    const [isSectionOpen, setSectionOpen] = useState(initialIsOpen);

    const changeSectionVisibility = useCallback(() => {
        setSectionOpen(!isSectionOpen);
    }, [isSectionOpen, setSectionOpen]);

    return {
        isSectionOpen,
        changeSectionVisibility
    };
};
