import React, { useState, useEffect } from 'react';

import { Grid } from 'components/Common/Grid';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';

import './ErrorsList.scss';

const errorCodes = {
    INVALID_DEVICE_EMAIL: {
        field: 'Device Email',
        description: 'Missing or incorrect Device Email. You must enter a valid Device Email that is connected to an HME CLOUD account.',
    },
    INVALID_ACCOUNT_EMAIL: {
        field: 'Account Email',
        description: 'Missing or incorrect Account Email. You must enter a valid Account Email that is connected to an HME CLOUD account.',
    },
    INVALID_STORE_NUMBER: {
        field: 'Store Number',
        description: 'Missing or incorrect Store Number. You must enter a valid Store Number. Store Numbers can only contain up to 8 numerical characters.',
    },
    INVALID_COUNTRY_NAME: {
        field: 'Country',
        description: 'Missing or invalid Country. The Country name must match with a name on the list of Countries on the ZOOM device.',
    },
    INVALID_DEVICE: {
        field: 'Device UID',
        description: 'Missing or invalid DeviceUID. ' +
            'Check that you have not modified or removed any of the DeviceUID values listed in ' +
            'the original downloaded CSV file',
    },
};

const unknownError = {
    field: 'Unknown',
    description: 'Unknown Error',
}

const gridHeaders = [{
    text: '',
    property: 'icon',
    flex: 'none',
    headerClassName: 'apply-store-settings-status-upload-error-rows-modal-grid-error-icon-header'
}, {
    text: 'Device Serial #',
    property: 'Device_Serial_Number',
}, {
    text: 'Field',
    property: 'field',
}, {
    text: 'Error Description',
    property: 'description',
    flex: 2.5,
}];

const errorToRow = (error) => ({
    ...error,
    icon: <ErrorIcon className='apply-store-settings-status-upload-error-rows-modal-grid-error-icon' />,
    ...unknownError,
    ...errorCodes[error.code],
    key: `${error.line}_${error.code}`,
});

export const ErrorsList = ({ errors }) => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(errors.map((error) => errorToRow(error)));
    }, [errors, setRows]);

    return (
        <Grid rows={rows} headers={gridHeaders} rowKey='key' className='apply-store-settings-status-upload-error-rows-modal-grid' />
    );
};
