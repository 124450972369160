import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Section } from 'library/Section';
import { useTranslation } from 'react-i18next';
import { InfoBox } from 'library/InfoBox';
import { accountTypes } from 'constants/accountTypes';
import { StoreSearchForm } from '../StoreSearchForm';
import { StoreDevices } from '../StoreDevices';

import './DestinationDeviceSection.css';

const filterLimit = 100;

export const DestinationDeviceSection = ({
    destinationStore,
    sourceDevice,
    destinationDevice,
    loadDevices,
    onStoreSelection,
    onDeviceSelection,
    isDisabled,
    onLoadDevices,
    permissionType,
    sourceStore,
    checkSelected,
}) => {
    const isDistributor = permissionType === accountTypes.DISTRIBUTOR;

    const [isLoading, setIsLoading] = useState(false);
    const [storeDevices, setStoreDevices] = useState([]);
    const [destinationDevicesFilter, setDestinationDevicesFilter] = useState(null);
    const [destinationStoresFilter, setDestinationStoresFilter] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (destinationStore) {
            loadDevices({
                deviceStoreID: destinationStore.Store_ID,
                setIsLoading,
                deviceFilter: destinationDevicesFilter,
                setStoreDevices,
                onLoadDevices,
                t,
            });
        }
    }, [destinationStore]);

    useEffect(() => {
        // reset destination devices on source device change
        setStoreDevices([]);

        if (sourceDevice) {
            setDestinationStoresFilter({
                limit: filterLimit,
                deviceTypeId: sourceDevice.Device_DeviceType_ID
            });
            setDestinationDevicesFilter({ deviceTypeId: sourceDevice.Device_DeviceType_ID });
        }
    }, [sourceDevice]);

    const checkDisabled = useCallback((row) => {
        if (isDistributor && sourceStore) {
            if (row.Store_Account_ID !== sourceStore.Store_Account_ID) return true;
        }
        return !!row.Device_IsActive
    }, [sourceStore]);
    
    return (
        <div className="merge-devices-destination-device-section">
            <Section number={2} title={'merge_devices__destination-device__title--simple'} disabled={isDisabled}>
                <InfoBox message={isDistributor ? 'merge_devices__destination-Public-device__warning'
                    : 'merge_devices__destination-Admin-device__warning'}
                    // TODO: Use variant info instead that calssName
                    className="hme-info-box-blue"
                />
                <StoreSearchForm
                    onStoreSelection={onStoreSelection}
                    storeFilter={destinationStoresFilter}
                    checkDisabled={checkDisabled}
                    sourceDevice={sourceDevice}
                />
                {destinationStore && (
                    <StoreDevices
                        selectedDevice={destinationDevice}
                        storeDevices={storeDevices}
                        isLoading={isLoading}
                        onDeviceSelection={onDeviceSelection}
                        checkDisabled={checkDisabled}
                        checkSelected={checkSelected}
                    />
                )}
            </Section>
        </div>
    );
};
