import { Constant } from '@hme-cloud/utility-common';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { deviceConfig } from 'constants/device';
import { AutoCompleteInput } from 'library/AutoCompleteInput';
import regExpStringFilter from '../../../../helpers/regExpStringFilter';
import { translateGridOptions } from 'components/Common/Grid';

import './SourceDeviceInput.scss';

const { DeviceType } = Constant;

const {
    validRegExp: NON_ALPHANUMERIC_REGEXP,
    maxSerialNumberLength: SERIAL_NUMBER_MAX_LENGTH,
    getLaneType,
} = deviceConfig;

const prepareValue = value => {
    return regExpStringFilter(value, NON_ALPHANUMERIC_REGEXP);
};

const suggestionsHeaders = [{
    text: 'apply-device-settings-new__source-input__header__device-serial',
    property: 'Device_SerialNumber',
    flex: 1.5,
}, {
    text: 'apply-device-settings-new__source-input__header__store-number',
    property: 'Store_Number',
}, {
    text: 'common__brand',
    property: 'Brand_Name',
}, {
    text: 'common__store__name',
    property: 'Store_Name',
}, {
    text: 'apply-device-settings-new__source-input__header__lane-type',
    property: 'laneType',
}];

const defaultDevices = [];

export const suggestionsMobileHeaders = [
    {
        column: [
            { text: 'common__device-serial_no', property: 'Device_SerialNumber', },
            { text: 'common__brand', property: 'Brand_Name', },
            { text: 'common__lane-type', property: 'laneType', },
        ],
    },
    {
        column: [
            { text: 'common__store-no', property: 'Store_Number', },
            { text: 'common__store__name', property: 'Store_Name', },            
        ],
    },
];

const deviceToRow = device => ({
    ...device,
    laneType: getLaneType(device.Device_LaneConfig_ID),
});

export const SourceDeviceInput = ({
    devices = defaultDevices,
    value,
    label = 'apply-device-settings-new__source-input__default-label',
    onChange,
    deviceType = DeviceType.ZOOMNITRO.camelCase,
}) => {
    const [deviceSn, setDeviceSn] = useState('');
    const [suggestedDevices, setSuggestedDevices] = useState([]);
    const [rows, setRows] = useState([]);
    const { t } = useTranslation();

    // TODO remove the translation when AutoCompleteInput is translated for all components
    const translatedOptions = useMemo(() => translateGridOptions(suggestionsHeaders, t), [t]);

    useEffect(() => {
        if (deviceSn.length < 3) {
            return;
        }

        const suggestions = devices.filter(device => {
            return device.Device_SerialNumber.toLowerCase().includes(deviceSn.toLowerCase());
        });

        setSuggestedDevices(suggestions);

        if (!value || deviceSn !== value.Device_SerialNumber) {
            onChange(null);
        }
    }, [deviceSn, devices, setSuggestedDevices]);

    useEffect(() => {
        const suggestedRows = suggestedDevices.map(device => deviceToRow(device));

        setRows(suggestedRows);
    }, [suggestedDevices]);

    useEffect(() => {
        if (value) {
            setDeviceSn(value.Device_SerialNumber);
        }
    }, [value]);

    const checkDisabled = (row, type) => {
        return type === DeviceType.ZOOMNITRO.camelCase ? row?.iotDeviceId !== null : false;
    };

    return (
        <div className='hme-source-device-input'>
            <AutoCompleteInput
                label={t(label)}
                maxLength={SERIAL_NUMBER_MAX_LENGTH}
                success={value !== null}
                value={deviceSn}
                suggestions={rows}
                headers={translatedOptions}
                mobileHeaders={suggestionsMobileHeaders}
                noSuggestionText={t('apply-device-settings-status__no-devices--found')}
                rowKey='Device_UID'
                onChange={newSn => {
                    setDeviceSn(prepareValue(newSn));
                }}
                onSuggested={onChange}
                checkDisabled={(row) => checkDisabled(row, deviceType)}
            />
        </div>
    );
};
