import React, { useEffect, useState } from 'react';
import { Section } from 'library/Section';
import { DevicesSearch } from './DevicesSearch';
import { DevicesList } from './DevicesList';

import './TargetDevicesSection.css';

export const TargetDevicesSection = ({
    filterType,
    devices,
    brandDevicesTotal,
    brandFilters,
    selectedDeviceType,
    selectedPeripheralDeviceType,
    sectionNumber,
    col,
    disabled = false,
    onLoadBrandDevices,
    onRemoveAllDevices,
    onRemoveDevice,
    onRemoveBrandDevice,
}) => {
    const [search, setSearch] = useState('');

    return (
        <Section
            number={sectionNumber}
            col={col}
            title="device-upgrades__section__title--review-manage-selected-devices"
            className="create-device-upgrade-target"
            disabled={disabled}
        >
           <DevicesSearch
                devices={devices}
                brandDevicesTotal={brandDevicesTotal}
                search={search}
                onSearchChange={setSearch}
                onRemoveAll={onRemoveAllDevices}
            />
            <DevicesList
                filterType={filterType}
                devices={devices}
                brandFilters={brandFilters}
                search={search}
                selectedDeviceType={selectedDeviceType}
                selectedPeripheralDeviceType={selectedPeripheralDeviceType}
                onLoadBrandDevices={onLoadBrandDevices}
                onRemoveDevice={onRemoveDevice}
                onRemoveBrandDevice={onRemoveBrandDevice}
            />
        </Section>
    );
};
