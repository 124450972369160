import React from 'react';
import { compose, cond, T } from 'ramda';
import { CommonConstants } from 'Constants';
import { withHMELayout } from 'HOCs/withHMELayout';
import { withPageState } from 'HOCs/withPageState';
import { isAdmin, isDistributor } from 'services/Auth';
import { checkAccess } from 'actions/permissions';
import authenticate from 'components/Security/Authentication';
import { withAccess } from 'HOCs/withAccess';
import { accountTypes } from 'constants/accountTypes';
import { CreateTask } from '../../CreateTask';

import './CreateTaskPage.scss';

const REDIRECT_URL = '/admin/applyDeviceSettingsStatus';
const hasAccess = () => isAdmin() && checkAccess(CommonConstants.adminPermissions.CreateSettingSnapshot);
const AdminCreateTask = ({ setPageState }) => (
    <CreateTask setPageState={setPageState} permissionType={accountTypes.ADMIN} redirectUrl={REDIRECT_URL} />
);

export const CreateTaskPage = compose(
    withHMELayout(),
    authenticate,
    withAccess({
        checkAccess: hasAccess,
    }),
    withPageState({
        loaderText: 'apply-device-settings-new__loading',
        successText: 'apply-device-settings-new__success',
        errorText: 'apply-device-settings-new__failed',
    }),
)(AdminCreateTask);
