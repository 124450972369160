import { updateContest } from 'services/LeaderboardContests';

import { prepareContestDTO } from '../utils/prepareContestDTO';

export const updateLeaderboardContest = (uid, {
    name,
    description,
    scheduledTimePeriod,
    recurrenceDays,
    contestStores,
    metric,
    metricDetails,
    reward,
    rewardImg,
    timeZone,
    endDate,
    startDate
}) => {
    const payloadValues = prepareContestDTO({
        name,
        description,
        scheduledTimePeriod,
        recurrenceDays,
        contestStores,
        metric,
        metricDetails,
        reward,
        rewardImg,
        timeZone,
        endDate,
        startDate
    });

    return updateContest(uid, payloadValues);
};
