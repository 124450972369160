import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { permissionsTKey } from 'constants/permissions/translations';
import { Checkbox } from 'library/Checkbox';
import { permissionProp } from '../../../types/permissionsProps';
import { subPermission } from '../../constants/sections';

import './Permission.scss';

export const Permission = ({ permission, checked, isDisabled, onChange }) => {
    const { t } = useTranslation();

    const className = useMemo(() => classNames({
        'hme-view-role__permission--sub': subPermission.includes(permission.name),
    }), [permission.name]);

    const onCheckChange = useCallback((checked) => {
        onChange && onChange(permission, checked);
    }, [permission, onChange]);

    return (
        <Checkbox
            className={className}
            label={t(permissionsTKey[permission.text])}
            disabled={isDisabled}
            checked={checked}
            onChange={onCheckChange}
        />
    );
}

Permission.propTypes = {
    checked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    permission: permissionProp,
};
