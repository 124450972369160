import React from 'react';
import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';

import './Header.scss';

export const Header = () => {
    const { t } = useTranslation();
    return (
    <div className="create-apply-device-settings-task-header-row">
        <Title>{t('common__create-new-task')}</Title>
    </div>
    )
};
