import React from 'react';

import { Button } from 'library/Button';

import './Footer.scss';
import { useTranslation } from 'react-i18next';

export const Footer = ({ isSubmitEnabled, onSave, onCancel }) => {
    const { t } = useTranslation();
    return (
        <div className="firmware-management-view-footer">
            <Button onClick={onCancel}>{t('common__cancel')}</Button>
            <Button variants={['submit']} disabled={!isSubmitEnabled} onClick={onSave}>
                {t('common__save')}
            </Button>
        </div>
    );
};
