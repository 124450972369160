import { invertObj } from 'ramda';

export const DeviceUpgradeStatuses = {
    CREATED: 0,
    SENT: 1,
    DOWNLOAD_FIRMWARE_STARTED: 2,
    DOWNLOAD_FIRMWARE_COMPLETED: 3,
    FIRMWARE_UPDATE_STARTED: 4,
    FIRMWARE_UPDATE_COMPLETED: 5,
    UPDATE_FAILED: 6,
};

export const DeviceUpgradeStatusesMap = invertObj(DeviceUpgradeStatuses);
