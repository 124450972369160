import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SuccessIcon } from 'assets/icons/check.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/infoIcon.svg';

import './NotificationMessage.scss';

export const NotificationMessage = ({ message, isFailure = false }) => {
    const { t } = useTranslation();

    return (
        <div className="hme-notification-message">
            {!isFailure &&
            <div className="hme-notification-message-icon-block">
                <SuccessIcon className="hme-notification-message-icon" />
            </div> }
            {isFailure &&
            <div className="hme-notification-warning-icon-block">
                <InfoIcon className="hme-notification-warning-icon" />
            </div> }
            <div className="hme-notification-message-text">{t(message)}</div>
        </div>
    );
};
