import React from 'react';
import PropTypes from 'prop-types';

const TimeZoneDropdown = (props) => {
    const { timezones, selectedZone = '', isDisabled, defaultOptionTitle = '', onChange = () => {} } = props;
    const items = [<option key={`-1-default`} value=''>{defaultOptionTitle}</option>];
    if (timezones && timezones.length > 0) {
        timezones.forEach((data, index) => {
            const params = {
                key: `${index}-${data.Name}`,
                value: data.Name,
                selected: data.Name === selectedZone
            };
        items.push(<option {...params}>{data.Name}</option>);
      });
    };

    return <select name='Store_TZ' id='Store_TZ' value={selectedZone} disabled={isDisabled} onChange={onChange}>
        {items}
    </select>;
};

TimeZoneDropdown.propTypes = {
    timezones: PropTypes.array.isRequired,
    selectedZone: PropTypes.string,
    isDisabled: PropTypes.bool,
    defaultOptionTitle: PropTypes.string,
    onChange: PropTypes.func
}

export default TimeZoneDropdown;
