import React from 'react';
import { cond, T } from 'ramda';
import { upgradeStatuses } from '../../../../constants/upgradeStatuses';
import { ReactComponent as IconCompleted } from 'images/check-green.svg';
import { ReactComponent as IconScheduled } from 'images/icon-scheduled-grey.svg';

import './StatusIcon.css';

const Icon = cond([
    [({ upgradeStatus }) => upgradeStatus === upgradeStatuses.COMPLETED, (props) => <IconCompleted {...props} />],
    [({ upgradeStatus }) => upgradeStatus === upgradeStatuses.SCHEDULED, (props) => <IconScheduled {...props} />],
    [T, () => null],
]);

export const StatusIcon = ({ upgradeStatus }) => {
    return (
        <span className="view-device-upgrade-info-status-icon">
            <Icon upgradeStatus={upgradeStatus} />
        </span>
    );
};
