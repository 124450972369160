import Api from '../Api'
import { Config } from '../Config'
import { CommonConstants } from '../Constants'
import * as leaderboardGroups from "../actionTypes/Leaderboard/Leaderboard.js";
import AuthenticationService from '../components/Security/AuthenticationService'
import * as lbgroups from "../actionTypes/Leaderboard/Leaderboard";
const _ = require('underscore')

export const save = (data, callBack) => {
  const api = new Api();
  let url = Config.apiBaseUrl + CommonConstants.apiUrls.saveLBGroup;
  return (dispatch, getState) => {
    api.postData(url, data, data => {
      callBack(data)
    }, error => {
      console.log(error)
    })
  }
}

export const get = (GroupUID, callBack) => {
  const api = new Api();
  let url = Config.apiBaseUrl + CommonConstants.apiUrls.getLBgroup + '?GroupUID=' + GroupUID;
  return (dispatch, getState) => {
    api.getData(url, data => {
      callBack(data)
    })
  }
}

export const remove = (input, callBack) => {
  const api = new Api();
  let url = Config.apiBaseUrl + CommonConstants.apiUrls.removeGroup + '/' + input.GroupUID + '/' + input.CompanyUID;
  return (dispatch, getState) => {
    api.deleteData(url, data => {
      callBack(data);
    }, error => {
      console.log(error);
    })
  }
}


export const removeTemplate = (input, callBack) => {
  const api = new Api();
  const authService = new AuthenticationService(Config.authBaseUrl);

  let url = Config.apiBaseUrl + CommonConstants.apiUrls.removeTemplate + '/' + input.TemplateID + '/' + input.CompanyUID;
  if (authService.getMasqueradeAdminUser()) url = url + '/' + authService.getMasqueradeAdminUser();
  return (dispatch, getState) => {
    api.deleteData(url, data => {
      console.log('recieved removeTemplate request result')
      callBack(data);
    }, error => {
      console.log(error);
    })
  }
}

export const getCompanyDetail = (callBack) => {
  const api = new Api();
  let url = Config.apiBaseUrl + CommonConstants.apiUrls.getCompany;
  return (dispatch, getState) => {
    api.getData(url, data => {
      let companyData = data;
      callBack(companyData);
    });
  };
};

export const setInviteDetails = inviteParams => {
  return {
    type: lbgroups.SET_INVITEDETAILS,
    inviteParams: inviteParams
  };
};

export const inviteLBGroupUser = (callBack) => {
  const api = new Api();
  const url = Config.apiBaseUrl + CommonConstants.apiUrls.inviteLBGroupUser;
  return (dispatch, getState) => {
    const state = getState();
    if (!state.leaderboardGroups.inviteParams.UserEmail) {
      state.lbgroups.userEmail = "Email Address is required!";
      return;
    }
    
      api.postData(url, state.leaderboardGroups.inviteParams, result => {
        callBack(result);
      });
  };
};


function getLeaderBoardUsersSuccess (groupUserDetails) {
  return {
    type: leaderboardGroups.GET_LEADERBOARDGROUPUSERS,
    groupUserDetails: groupUserDetails
  }
}

export const getLBGroupUsers = (GroupUID, callback) => {
  const api = new Api();
  let url =
    Config.apiBaseUrl +
    CommonConstants.apiUrls.getLBGroupUsers +
    "?GroupUID=" +
    GroupUID;

  return (dispatch, getState) => {
    api.getData(url, data => {
      let List = data;
      dispatch(getLeaderBoardUsersSuccess(List))
      callback(List);
    });
  };
};

export const deleteLBGroupUser = (input, callBack) => {
  const api = new Api();
  let url = Config.apiBaseUrl + CommonConstants.apiUrls.deleteLBGroupUser + '?GroupUID=' + input.GroupUID + '&userUID=' + input.userUID ;
  return (dispatch, getState) => {
    api.deleteData(url, data => {
      callBack(data);  
    }, error => {
      console.log(error);
    })
  }
}


export const getLBSubscription = (input, callBack) => {
  const api = new Api();
  let url = Config.apiBaseUrl + CommonConstants.apiUrls.getLBSubscription + '/' + input.AccountID ;
  return (dispatch, getState) => {
    api.getData(url, data => {
      callBack(data);  
    }, error => {
      console.log(error);
    });
  };
};