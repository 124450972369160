import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'library/Button';
import { Tree, TREE_VARIANTS } from 'library/Tree';
import { SearchInput } from 'components/Inputs/SearchInput';

import { HierarchyItemContext } from './HierarchyItem';

import './HierarchyBlock.scss';

const treeVariants = [TREE_VARIANTS.BORDERED];

export const HierarchyBlock = ({
    isAddDisabled,
    isEditDisabled,
    isRemoveDisabled,
    isMoveDisabled,
    tree,
    onAddStart,
    onAddCancel,
    onEditStart,
    onEditCancel,
    onAddGroup,
    onMove,
    onEditGroup,
    selectedItems,
    searchString,
    emptyTreeMessage,
    onItemSelect,
    onSearchChange,
    onRemove,
}) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState(searchString);
    const providerValue = useMemo(() => ({
        selectedItems,
        onItemSelect,
        searchString,
        onItemAddCancel: onAddCancel,
        onItemEditCancel: onEditCancel,
        onItemAdd: onAddGroup,
        onItemEdit: onEditGroup,
    }), [selectedItems, searchString, onItemSelect, onAddGroup, onEditGroup, onAddCancel, onEditCancel]);

    const onSearchValueChange = useCallback((newValue) => {
        setSearchValue(newValue);

        if (newValue.length === 0 || newValue.length > 2) {
            onSearchChange && onSearchChange(newValue);
        }
    }, [onSearchChange]);

    const onSearchKeyPressed = useCallback((event) => {
        if (event.key === 'Enter') {
            onSearchChange(searchValue);
        }
    }, [searchValue, onSearchChange]);

    return (
        <div className="hme-manage-hierarchy__hierarchy">
            <div className="hme-manage-hierarchy__hierarchy__sub-header">
                <SearchInput
                    value={searchValue}
                    placeholder={t('stores-hierarchy__search__placeholder')}
                    showClearIcon={true}
                    onChange={onSearchValueChange}
                    onKeyPress={onSearchKeyPressed}
                />
                <Button disabled={isEditDisabled} onClick={onEditStart}>{t('stores-hierarchy__btn--edit-group')}</Button>
                <Button disabled={isAddDisabled} onClick={onAddStart}>{t('stores-hierarchy__btn--add-group')}</Button>
                <Button disabled={isRemoveDisabled} onClick={onRemove}>{t('common__remove')}</Button>
                <Button disabled={isMoveDisabled} onClick={onMove}>{t('stores-hierarchy__btn--move')}</Button>
            </div>
            <div className="hme-manage-hierarchy__hierarchy__tree-input__wrapper">
                <HierarchyItemContext.Provider value={providerValue}>
                    <Tree
                        variants={treeVariants}
                        tree={tree}
                        isAllExpanded={searchString.length !== 0}
                        emptyTreeMessage={emptyTreeMessage}
                    />
                </HierarchyItemContext.Provider>
            </div>
        </div>
    );
};
