import { useCallback, useMemo, useState } from 'react';

export const useTileList = (rows, availableFilters, sortOptions) => {
    const [isSortModalOpen, setSortModalOpen] = useState(false);

    const onSortModalOpen = useCallback(() => setSortModalOpen(true), [isSortModalOpen, setSortModalOpen]);
    const onSortModalClose = useCallback(() => setSortModalOpen(false), [isSortModalOpen, setSortModalOpen]);

    const isListExist = useMemo(() => Boolean(rows.length), [rows]);
    const needToShowFilterCount = useMemo(() => Boolean(availableFilters), [availableFilters]);
    const hasFilterSort = useMemo(() => Boolean(availableFilters || sortOptions), [availableFilters, sortOptions]);

    return {
        isSortModalOpen,
        onSortModalOpen,
        onSortModalClose,
        isListExist,
        needToShowFilterCount,
        hasFilterSort
    };
};
