import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Popup } from './Popup';

import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import './FilterCell.scss';

export const FilterCell = ({
    items,
    selection,
    allText,
    defaultSearchValue,
    noItemsFoundText,
    SearchItemsComponent,
    onSearchItemsChange,
    onChange
}) => {
    const [isPopupShown, setIsPopupShown] = useState(false);
    const [searchValue, setSearchValue] = useState(defaultSearchValue);
    const [shownItems, setShownItems] = useState(items);

    const { t } = useTranslation();

    const onSearchItemsChangeHandler = useCallback((filters) => {
        setSearchValue(filters);
    }, [setSearchValue, onSearchItemsChange]);

    useEffect(() => {
        if (!onSearchItemsChange) {
            setShownItems(items);

            return;
        }

        const newItems = onSearchItemsChange(items, searchValue);
        setShownItems(newItems);
    }, [items, searchValue, onSearchItemsChange, setShownItems]);

    return (
        <span className={classNames('hme-grid-filter-cell', {
            'hme-grid-filter-cell-opened': isPopupShown,
            'hme-grid-filter-cell-empty': items.length < 2,
        })}>
            <FilterIcon
                className='hme-grid-filter-cell-icon'
                onClick={() => setIsPopupShown(!isPopupShown)}
            />
            <Popup
                show={isPopupShown}
                items={shownItems}
                selection={selection}
                allText={t(allText)}
                onSearchItemsChange={onSearchItemsChangeHandler}
                noItemsFoundText={noItemsFoundText}
                SearchItemsComponent={SearchItemsComponent}
                searchValue={searchValue}
                onChange={onChange}
                onHide={() => setIsPopupShown(false)}
            />
        </span>
    );
};
