import { T, cond } from 'ramda';
import './DeviceActiveStatus.scss';

const StatusOnline = () => {
    return (
        <span className="hme-device-individual-active-status">
            <span className="hme-status-circle hme-status-circle--online"></span>
        </span>
    );
};

const StatusOffline = () => {
    return (
        <span className="hme-device-individual-active-status">
            <span className="hme-status-circle hme-status-circle--offline"></span>
        </span>
    );
};

export const DeviceActiveStatus = cond([
    [({ device }) => device.Device_IsActive == 1, StatusOnline],
    [T, StatusOffline],
]);
