import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { MoreItem } from './MoreIem';

export const MoreDropdown = ({ menuItems }) => {
    const { t } = useTranslation();

    const title = (
        <span className="headerMenu">
          {t('main-header__item--more')}
          <span className="fa fa-chevron-down" />
        </span>
      )
      return (
        <span className="more-M">
            <NavDropdown title={title} id="mainMore">
                {menuItems.map(({ name, ...item }) => <MoreItem {...item} key={name} id={`mainMore-${name}-item`} />)}
            </NavDropdown>
        </span>
      )
};
