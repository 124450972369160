import React from 'react';

import { Button } from 'library/Button';

import './Footer.scss';

export const Footer = ({ isSubmitEnabled, onCancel, onApply }) => (
    <div className='admin-create-apply-store-settings-task-footer'>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
            variants={['submit']}
            disabled={!isSubmitEnabled}
            onClick={onApply}
        >
            Download File &amp; Create Task
        </Button>
    </div>
);
