import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationsList } from 'library/NotificationsList';
import { Title } from 'library/Title';
import { Loader } from 'library/Loader';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';

import { HierarchyBlock } from './HierarchyBlock';
import { FileManageBlock } from './FileManageBlock';
import { MoveModal } from './MoveModal';

import './ManageHierarchy.scss';
import {Header} from "./Header";

export const ManageHierarchy = ({
    isLoading,
    isEditDisabled,
    isRemoveDisabled,
    isMoveDisabled,
    tree,
    selectedItems,
    searchString,
    onItemSelect,
    exportFormat,
    isExportDisabled,
    emptyTreeMessage,
    importedFile,
    onExportFormatChange,
    onExport,
    onImportChange,
    onRemove,
    onImport,
    isAddDisabled,
    onSearchChange,
    onAddStart,
    onAddCancel,
    onEditCancel,
    onAddGroup,
    onEditGroup,
    onEditStart,
    onMove
}) => {
    const { t } = useTranslation();

    const [showImportConfrm, setShowImportConfirm] = useState(false);
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
    const [showMovePopup, setShowMovePopup] = useState(false);

    const onShowRemoveConfirmation = useCallback(() => setShowRemoveConfirmation(true), []);
    const onHideRemoveConfirmation = useCallback(() => setShowRemoveConfirmation(false), []);

    const onMoveShow = useCallback(() => setShowMovePopup(true), []);
    const onMoveHide = useCallback(() => setShowMovePopup(false), []);

    const onRemoveConfirm = useCallback(() => {
        onRemove && onRemove(selectedItems[0]);
    }, [selectedItems, onRemove]);

    const onImportConfirmHide = useCallback(() => setShowImportConfirm(false), []);
    const onImportConfirmShow = useCallback(() => setShowImportConfirm(true), []);

    if (isLoading) {
        return (
            <div className="hme-manage-hierarchy">
                <Loader />
            </div>
        );
    }

    return (
        <div className="hme-manage-hierarchy">
            <NotificationsList />
            <Header title={t('stores-hierarchy__title')}/>
            <HierarchyBlock
                isAddDisabled={isAddDisabled}
                isEditDisabled={isEditDisabled}
                isRemoveDisabled={isRemoveDisabled}
                isMoveDisabled={isMoveDisabled}
                tree={tree}
                onAddStart={onAddStart}
                onAddCancel={onAddCancel}
                onEditStart={onEditStart}
                onEditCancel={onEditCancel}
                onAddGroup={onAddGroup}
                onEditGroup={onEditGroup}
                selectedItems={selectedItems}
                searchString={searchString}
                emptyTreeMessage={emptyTreeMessage}
                onItemSelect={onItemSelect}
                onSearchChange={onSearchChange}
                onRemove={onShowRemoveConfirmation}
                onMove={onMoveShow}
            />
            <FileManageBlock
                exportFormat={exportFormat}
                isExportDisabled={isExportDisabled}
                onExportFormatChange={onExportFormatChange}
                onExport={onExport}
                importedFile={importedFile}
                onImportChange={onImportChange}
                onImport={onImportConfirmShow}
            />
            {/* Import confirm popup */}
            <ConfirmPopupComponent
                show={showImportConfrm}
                title={t('common__double-checking')}
                message={t('manage-hierarchy__backup-reminder')}
                onHide={onImportConfirmHide}
                onConfirm={onImport}
                confirmationVerb={t('common__ok')}
            />
            {/* Confirmation popup for remove a group */}
            <ConfirmPopupComponent
                show={showRemoveConfirmation}
                onHide={onHideRemoveConfirmation}
                onConfirm={onRemoveConfirm}
                message={t('stores-hierarchy__remove__confirm')}
                confirmationVerb={t('common__remove')}
            />
            <MoveModal
                selectedItems={selectedItems}
                tree={tree}
                show={showMovePopup}
                onHide={onMoveHide}
                onMove={onMove}
            />
        </div>
    );
};
