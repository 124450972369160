import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ClockIcon } from 'assets/icons/icon-clock.svg';
import { Title } from 'library/Title';
import { Button } from 'library/Button';

import './Header.scss';

export const Header = ({
    title = '',
    isConfigurationHistoryLinkShown = false,
    isConfigurationHistoryLinkDisabled = false,
    onHistoryConfigurationShow,
}) => {
    const { t } = useTranslation();

    return (
        <div className="new-vaio-provider__header">
            <Title>{t(title)}</Title>
            {isConfigurationHistoryLinkShown && (
                <div className="new-vaio-provider__header__links">
                    <div className="new-vaio-provider__header__links__box">
                        <Button
                            disabled={isConfigurationHistoryLinkDisabled}
                            variants={['transparent']}
                            className="new-vaio-provider__header__link new-vaio-provider__header__link--history-configuration"
                            onClick={onHistoryConfigurationShow}
                        >
                            <ClockIcon className="new-vaio-provider__header__link__icon" />
                            {t('vaio-provider__configuration-history')}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
