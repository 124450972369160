import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PUBLIC_ROUTES } from 'constants/routes';
import { Button, BUTTON_VARIANTS } from 'library/Button';

import { BaseNotification } from '../BaseNotification';

const buttonVariants = [BUTTON_VARIANTS.TRANSPARENT];

const ExpandedContet = ({ groupUid, invitor }) => {
    const { t } = useTranslation();

    return (
        <Button
            variants={buttonVariants}
            as='a'
            href={`/${PUBLIC_ROUTES.lbGroup}?GroupUID=${groupUid}&IsDefault=0&ia=false&invite=true`}
            target='_blank'
            rel="noreferrer"
        >{t('notifications-center__notification__expanded--leaderboard-accepted__link__text', { invitor })}</Button>
    );
};

export const LeaderboardAccepted = ({ notification, ...props }) => {
    const { t } = useTranslation();
    const data = useMemo(() => JSON.parse(notification.data), [notification.data]);
    const expandedContent = useMemo(() => (
        <ExpandedContet invitor={data.invitor} groupUid={data.groupUid} />
    ), [data.groupUid, data.invitor]);

    return (
        <BaseNotification
            id={notification.id}
            className='hme-notifications-center__notification--leaderboard-accepted'
            title='notifications-center__notification__title--leaderboard-accepted'
            sentDate={notification.sentDate}
            isRead={!!notification.readDate}
            body={t('notifications-center__notification__body--leaderboard-accepted', {
                invitor: data.invitor
            })}
            expandedContent={expandedContent}
            {...props}
        />
    )
};
