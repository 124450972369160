import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { Grid } from 'components/Common/Grid';
import { debounce } from 'lodash';
import { Button } from 'library/Button';
import { Paginate } from 'library/Paginate';
import { useTranslation } from 'react-i18next';
import { useLoadDevicesByBrand } from '../../../hooks/useLoadDevicesByBrand';
import { CommonConstants } from 'Constants';

const baseGridHeaders = [
    {
        text: 'device-upgrades__grid__header--time-zone',
        property: 'timezone',
        className: 'hme-grid-cell-show-all-content',
        flex: 124,
    },
    {
        text: 'device-upgrades__grid__header--account',
        property: 'userEmailAddress',
        className: 'hme-grid-cell-show-all-content',
        flex: 188,
    },
    {
        text: 'device-upgrades__grid__header--store-number',
        property: 'storeNumber',
        className: 'hme-grid-cell-show-all-content',
        flex: 106,
    },
    {
        text: 'device-upgrades__grid__header--store-name',
        property: 'storeName',
        className: 'hme-grid-cell-show-all-content',
        flex: 100,
    },
    {
        text: 'common__device__serial-number',
        property: 'deviceSerial',
        className: 'hme-grid-cell-show-all-content',
        flex: 116,
    },
    {
        text: 'common__device__product-id',
        property: 'deviceProductID',
        className: 'hme-grid-cell-show-all-content',
        flex: 110,
        sortable: true,
    },
    {
        text: 'device-upgrades__grid__header--actions',
        property: 'actions',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
        flex: 74,
    },
];

const getGridHeaders = (deviceType) => {
    return deviceType === CommonConstants.deviceType.nexeo.name.toLowerCase()
        ? baseGridHeaders
        : baseGridHeaders.filter((header) => header.property !== 'deviceProductID');
};

const INIT_PAGE_NUMBER = 0;
const INIT_ITEMS_PER_PAGE = 10;
const PAGE_SIZES = [10, 20, 50, 100];
const SEARCH_DEBOUNCE_TIMEOUT = 1000;

export const BrandDevicesGrid = ({
    search,
    brandFilters,
    selectedDeviceType,
    onLoadBrandDevices,
    onRemoveBrandDevice,
}) => {
    const { t } = useTranslation();
    const [pageNumber, setPageNumber] = useState(INIT_PAGE_NUMBER);
    const [itemsPerPage, setItemsPerPage] = useState(INIT_ITEMS_PER_PAGE);
    const gridHeaders = getGridHeaders(selectedDeviceType);
    const isMountedDevicesRef = useRef(false);

    const { devices, totalDevices, isDevicesLoading, loadDevicesByBrand } = useLoadDevicesByBrand({
        brandFilters,
        search,
        pagenumber: pageNumber + 1,
        perpage: itemsPerPage,
    });

    useEffect(() => {
        onLoadBrandDevices(devices);
    }, [devices]);

    // wrapping into useRef for storing the debounced function across renders
    const loadDevicesByBrandDebounced = useRef(debounce(loadDevicesByBrand, SEARCH_DEBOUNCE_TIMEOUT)).current;

    useEffect(() => {
        loadDevicesByBrand({
            brandFilters,
            search,
            pagenumber: pageNumber + 1,
            perpage: itemsPerPage,
        });
    }, [brandFilters]);

    useEffect(() => {
        if (isMountedDevicesRef.current) {
            setPageNumber(INIT_PAGE_NUMBER);

            if (search?.length > 0 && search?.length < 2) {
                loadDevicesByBrandDebounced.cancel();
                return;
            }

            loadDevicesByBrandDebounced({
                brandFilters,
                search,
                pagenumber: 1,
                perpage: itemsPerPage,
            });
        } else {
            isMountedDevicesRef.current = true;
        }
    }, [search]);

    const onRemove = useCallback(({ Device_ID, WindowsTimeZone }) => {
        onRemoveBrandDevice({
            Device_ID,
            WindowsTimeZone,
        });
        if (pageNumber > 0 && devices.length <= 1) {
            setPageNumber(0);
        }
    }, [pageNumber, devices]);

    const rows = useMemo(
        () =>
            devices.map(
                ({
                    WindowsTimeZone,
                    AccountOwner_EmailAddress,
                    Store_Number,
                    Store_Name,
                    Device_SerialNumber,
                    Device_Product_ID,
                    Device_MainVersion,
                    Device_ID,
                }) => ({
                    timezone: WindowsTimeZone,
                    userEmailAddress: AccountOwner_EmailAddress,
                    storeNumber: Store_Number,
                    storeName: Store_Name,
                    deviceSerial: Device_SerialNumber,
                    deviceProductID: Device_Product_ID,
                    sourceVersion: Device_MainVersion,
                    actions: (
                        <Button
                            variants={['transparent']}
                            onClick={() =>
                                onRemove({
                                    Device_ID,
                                    WindowsTimeZone,
                                })
                            }
                        >
                            {t('common__remove')}
                        </Button>
                    ),
                }),
            ),
        [devices],
    );

    const onPaginationChange = useCallback(({ page = 0, recordsPerPage = 10 } = {}) => {
        setPageNumber(page);
        setItemsPerPage(recordsPerPage);

        loadDevicesByBrand({
            brandFilters,
            search,
            pagenumber: page + 1,
            perpage: recordsPerPage,
        });
    }, [brandFilters, search, loadDevicesByBrand]);

    return (
        <div className="upgrade-devices-list hme-components">
            <Grid
                headers={gridHeaders}
                rows={rows}
                isLoading={isDevicesLoading}
                rowKey="deviceSerial"
                noRecordsMessage={t('common__no-devices--found')}
            />

            <Paginate
                className="hme-device-upgrades__target-devices__paginate"
                page={pageNumber}
                recordsPerPage={itemsPerPage}
                pageSizes={PAGE_SIZES}
                total={totalDevices}
                onChange={onPaginationChange}
                hideSinglePage
                sectionWidth={978}
            />
        </div>
    );
};
