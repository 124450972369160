import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { DefaultIcon } from './Icons';

import './InfoBox.scss';

export const VARINATS = {
    WARNING: 'warning',
    INFO: 'info', // TODO: Use this variant instead of className `hme-info-box-blue`
    FLEX: 'flex',
};

const defaultVariants = [];

const baseClassName = 'hme-info-box';

export const InfoBox = ({ message, className, variants = defaultVariants , Icon = DefaultIcon}) => {
    const { t } = useTranslation();
    return (
        <div className={classNames(baseClassName, variants.map((variant) => `${baseClassName}--${variant}`))}>
            <div className={classNames(className, `${baseClassName}__content`)}>
                <div className="hme-info-box-message">
                    <Icon />
                    {t(message)}
                </div>
            </div>
        </div>
    );
};
