import React, { useEffect, useRef } from 'react';
import { compose } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ASYNC_STATUS, useAsync } from 'hooks/useAsync';
import { getProfile, isAdmin } from 'services/Auth';
import { checkAccess } from 'actions/permissions';
import { CommonConstants } from 'Constants';
import { withAccess } from 'HOCs/withAccess';
import authenticate from 'components/Security/Authentication';
import { CenterLoader } from 'components/Common/CenterLoader';
import { NotificationsList } from 'library/NotificationsList';
import { ADMIN_ROUTES } from 'constants/routes';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { createVaioProvider, getVaioProviderByUID, updateVaioProvider } from 'services/StoreFeatures';
import { withHMELayout } from 'HOCs/withHMELayout';

import { SettingsForm, VAIO_DEFAULTS } from '../VaioProviderForm';
import { errorKeys, toUpdateDTO, toCreateDTO } from '../VaioProviderForm/helpers';

const hasAccess = isAdmin() && checkAccess(CommonConstants.adminPermissions.CreateVaioProvider);

const ServiceProviderSettingsWrapper = ({ children }) => {
    return (
        <div className="hme-components new-vaio-provider-wrapper">
            <NotificationsList />
            {children}
        </div>
    );
};

const CreateServiceProviderSettings = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const filledInValues = useRef(VAIO_DEFAULTS);

    const { status, run } = useAsync();

    const onSubmit = (values) => {
        filledInValues.current = values;
        const { unique_name: userEmail } = getProfile();

        const filteredFormValues = toCreateDTO(values, userEmail);

        run(
            createVaioProvider(filteredFormValues).then(() => {
                navigate(ADMIN_ROUTES.serviceProviders);

                setTimeout(() => {
                    addSuccessNotification('admin__account__vaio__provider-created', { autoClose: 5000 });
                }, 1000);
            }).catch((err) => {
                addErrorNotification(
                    errorKeys[err.response.data.message] || err.response.data.message,
                    { autoClose: 5000 }
                );
            })
        );
    };

    const onCancel = () => {
        navigate(ADMIN_ROUTES.serviceProviders);
    };

    const isLoading = status === ASYNC_STATUS.PENDING;

    if (isLoading) {
        return <CenterLoader>{t('common__loading')}</CenterLoader>;
    }

    return (
        <SettingsForm
            title={t('new-vaio-provider_title')}
            onSubmit={onSubmit}
            onCancel={onCancel}
            settings={filledInValues.current}
            linkedPartner={filledInValues.current.email}
        />
    );
};

const CreateServiceProviderSettingsWrapper = () => {
    return <ServiceProviderSettingsWrapper>
        <CreateServiceProviderSettings />
    </ServiceProviderSettingsWrapper>;
};

export const CreateServiceProviderSettingsPage = compose(
        authenticate,
        withAccess({
            checkAccess: () => hasAccess
        }),
        withHMELayout({
            contentClasses: ['new-vaio-provider-page']
        })
)(CreateServiceProviderSettingsWrapper);

const EditServiceProviderSettings = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const providerUID = searchParams.get('puid');

    const { run, data: settings, isLoading: isSettingsLoading } = useAsync();
    const { status: saveStatus, run: runSaveSettings } = useAsync();

    useEffect(() => {
        run(
            getVaioProviderByUID(providerUID)
                .then((response) => ({
                    ...response,
                    httpsPort: response.httpsPort ? String(response.httpsPort) : VAIO_DEFAULTS.httpsPort,
                    wssPort: response.wssPort ? String(response.wssPort) : VAIO_DEFAULTS.wssPort,
                    isTest: Boolean(response.isTest),
                    keyExpiration: response.keyExpiration || VAIO_DEFAULTS.keyExpiration,
                    vaioKey: response.vaioKey || VAIO_DEFAULTS.vaioKey,
                }))
                .catch((err) => {
                    navigate(ADMIN_ROUTES.serviceProviders);
                    setTimeout(() => {
                        addErrorNotification(
                            errorKeys[err.response.data.message] || 'common__error--internal-server',
                            { autoClose: 5000 }
                        );
                    }, 1000);

                    return {};
                })
        );
    }, []);

    const onSubmit = (values) => {
        const { unique_name: userEmail } = getProfile();

        const filteredFormValues = toUpdateDTO(values, userEmail);

        runSaveSettings(
            updateVaioProvider(filteredFormValues)
                .then(() => {
                    navigate(ADMIN_ROUTES.serviceProviders);
                    setTimeout(() => {
                        addSuccessNotification('admin__account__vaio__provider-updated', { autoClose: 5000 });
                    }, 1000);
                })
                .catch((err) => {
                    addErrorNotification(
                        errorKeys[err.response.data.message] || err.response.data.message,
                        { autoClose: 5000 }
                    );
                })
        );
    };

    const onCancel = () => {
        navigate(ADMIN_ROUTES.serviceProviders);
    };

    const isLoading = isSettingsLoading || saveStatus === ASYNC_STATUS.PENDING;

    if (isLoading) {
        return <CenterLoader>{t('common__loading')}</CenterLoader>;
    }

    const {
        vaioProviderChangeHistory,
        email
    } = settings;

    return (
        <SettingsForm
            title={t('edit-vaio-provider_title')}
            isViewChangeHistoryEnabled
            linkedPartner={email}
            providerChangeHistory={vaioProviderChangeHistory}
            settings={settings}
            onCancel={onCancel}
            onSubmit={onSubmit}
        />
    );
};

const EditServiceProviderSettingsWrapper = () => {
    return <ServiceProviderSettingsWrapper>
        <EditServiceProviderSettings />
    </ServiceProviderSettingsWrapper>;
};

export const EditServiceProviderSettingsPage = compose(
    authenticate,
    withAccess({
        checkAccess: () => hasAccess
    }),
    withHMELayout({
        contentClasses: ['new-vaio-provider-page']
    })
)(EditServiceProviderSettingsWrapper);
