import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { getQueryString } from 'helpers/distributor';

export const useDistributorsAccount = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { tabName } = useParams();
    const [queryParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState('');
    const searchString = useMemo(() => getQueryString(location.search), [location.search]);

    useEffect(() => {
        setActiveTab(tabName);
    }, []);

    const onActiveTabChange = useCallback((newTab) => {
        setActiveTab(newTab);
        const url = `/accounts/${newTab}${searchString}`;
        navigate(url);
    }, [searchString, setActiveTab, navigate]);

    const addUserRedirectionURL = useMemo(() =>`/accounts/users/add-user${searchString}`, [searchString]);
    const editUserRedirectionURL = useMemo(() =>`/accounts/users/edit-user${searchString}`, [searchString]);

    const accountUuid = useMemo(() => queryParams.get('auuid'), [queryParams]);

    return {
        activeTab,
        onActiveTabChange,
        addUserRedirectionURL,
        editUserRedirectionURL,
        accountUuid
    };
};
