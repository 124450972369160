import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';

import { Tip } from 'library/Tip';

import { InfoBlock } from './InfoBlock';

import './NameTooltip.scss';

const placement = 'bottom';

const LinkIcon = ({ className, ...props }) => {
    const { t } = useTranslation();

    return (
        <span className={classNames('hme-device-settings-snapshots__name-tooltip__icon')} {...props}>
            {t('settings_snapshots__naming-tooltip__title')}
        </span>
    );
};

export const NameTooltip = () => {
    const { t } = useTranslation();

    return (
        <div className='hme-device-settings-snapshots__name-tooltip'>
            <LinkIcon />
            <Tip
                title={t('settings_snapshots__naming-tooltip__title')}
                className='hme-device-settings-snapshots__name-tooltip__tip'
                placement={placement}
                withCloseButton={true}
            >
                <div className='hme-device-settings-snapshots__name-tooltip__text'>
                    {t('settings_snapshots__naming-tooltip__desc')}
                </div>
                <div className='hme-device-settings-snapshots__name-tooltip__grid'>
                    <InfoBlock number={1}>
                        <Trans i18nKey='settings_snapshots__naming-tooltip__account'>
                            <p className='hme-device-settings-snapshots__name-tooltip__p'>
                                The first letter of the <b>Account Type</b> of the creator:
                            </p>
                            <div>
                                <b>C</b> = Corporate user
                            </div>
                            <div>
                                <b>F</b> = Franchise user
                            </div>
                            <div>
                                <b>D</b> = Distributor user
                            </div>
                        </Trans>
                    </InfoBlock>
                    <InfoBlock number={2} example='McD'>
                        <Trans i18nKey='settings_snapshots__naming-tooltip__brand'>
                            <span>The <b>Brand</b> Abbreviation using 2-4 letters.</span>
                        </Trans>
                    </InfoBlock>
                    <InfoBlock number={3} example='USA'>
                        <Trans i18nKey='settings_snapshots__naming-tooltip__country'>
                            <span>The <b>Country</b> Code</span>
                        </Trans>
                    </InfoBlock>
                    <InfoBlock number={4} example='Dual Lane'>
                        <Trans i18nKey='settings_snapshots__naming-tooltip__lane'>
                            <span>The <b>Lane</b> Configuration</span>
                        </Trans>
                    </InfoBlock>
                    <InfoBlock number={5} example='5.1.0'>
                        <Trans i18nKey='settings_snapshots__naming-tooltip__device-version'>
                            <span>The Source Device <b>Version</b></span>
                        </Trans>
                    </InfoBlock>
                    <InfoBlock number={6}>
                        <Trans i18nKey='settings_snapshots__naming-tooltip__name-suffix'>
                            <p>An optional <b>Name Suffix</b></p>
                            <div>This is optionally provided by the user during creation of the snapshot.</div>
                        </Trans>
                    </InfoBlock>
                    <InfoBlock number={7} withPlus={false}>
                        <Trans i18nKey='settings_snapshots__naming-tooltip__brand-approval'>
                            <p><b>Brand Approval</b></p>
                            <div>Snapshots that are brand approved display an <b>“A”</b> at the end of the name.</div>
                        </Trans>
                    </InfoBlock>
                </div>
            </Tip>
        </div>
    );
};
