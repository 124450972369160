import AuthenticationService from '../components/Security/AuthenticationService';
import { Config } from '../Config';
import PermissionSpecification from './PermissionSpecification';

/**
 * Class which creates instances of classes related to Auth
 */
export default class AuthFactory {
    /**
     * Creates instance of AuthService
     *
     * @return  {AuthenticationService} instance of Auth Service
     */
    static get AuthService() {
        return new AuthenticationService(Config.authBaseUrl);
    }
    /**
     * Create instance of PermissionSpecification
     *
     * @return  {PermissionSpecification} instance of Permission Spec
     */
    static get PermissionSpecification() {
        return new PermissionSpecification(AuthFactory.AuthService);
    }
}