/* eslint-disable react/prop-types */
import './SendMessage.scss';
import React, { useEffect } from 'react';
import { SaveFooter } from 'library/SaveFooter';
import { useState } from 'react';
import { SenderSection } from './SenderSection';
import { ReceiverSection } from './ReceiverSection';
import { CategorySection } from './CategorySection';
import { compose } from 'ramda';
import { withMasqueradeUser } from 'HOCs/withMasqueradeUser';
import customSmackTalkApi from 'api/customSmackTalkApi';
import { addNotification, dismissNotification, NOTIFICATION_TYPES } from 'services/Notifications';
import { useTranslation } from 'react-i18next';
import { messageKeys } from '../constants';
import { ConfirmPopupComponent } from '../../../library/ConfirmPopup';

const MIN_MSG_DISPLAY_TIME = 24;
const displayTime = MIN_MSG_DISPLAY_TIME * 60 * 60;

const SendMessageTab = ({ profile, smacktalkData }) => {
    const [store, setStore] = useState('');
    const [sender, setSender] = useState('user');
    const [selections, setSelections] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState();
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [disableSecond, setDisableSecond] = useState(false);
    const [disableThird, setDisableThird] = useState(true);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const { t } = useTranslation();

    const prepareStores = (storeUIDs) => {
        if (!smacktalkData) {
            return storeUIDs;
        }
        return storeUIDs.map((storeUID) => {
            const store = { storeUID };
            for (const group of smacktalkData.groups) {
                const storeDescription = group.Stores.find((storeEntry) => storeEntry.StoreUID === storeUID);
                if (storeDescription) {
                    store.groupID = storeDescription.GroupID;
                    break;
                }
            }
            return store;
        });
    };
    const serializeMessage = () => {
        const ownedStores = smacktalkData.stores.filter((s) =>
            s.requestCompanyUID === s.storeCompanyUID);

        const senderStore = store && ownedStores[Number(store)];
        // eslint-disable-next-line no-void
        const from = senderStore && senderStore.value || void(0);
        const firstName = from ? null : profile['User_FirstName'];
        const lastName = from ? null : profile['User_LastName'];

        const message = {
            messageID: Number(selectedMessage['SmackTalkPredefinedMessageID']) || void(0),
            leaderboardDisplayText: t(messageKeys[selectedMessage['LeaderboardDisplayText']]) || selectedMessage['LeaderboardDisplayText'],
            displayTime,
            from,
            stores: prepareStores(selections),
            firstName,
            lastName
        };
        return message;
    };

    const resetData = () => {
        setStore('');
        setSender('user');
        setSelections([]);
        setSelectedMessage();
        setDisableSecond(false);
        setDisableThird(true);
    };

    const onSenderChange = (e) => {
        if (!e || (e.sender !== 'user' && !e.store)) {
            setDisableThird(true);
            return setDisableSecond(true);
        }
        setDisableSecond(false);
        setSelections([]);
    };
    useEffect(() => {
        if (selections && selections.length) {
            return setDisableThird(false);
        }
        setDisableThird(true);
    }, [selections]);

    const getSubmitStatus = () =>
        Boolean(smacktalkData && profile && (selections && selections.length) && selectedMessage);

    const onSave = async () => {
        dismissNotification();
        setSubmitEnabled(false);
        try {
            if (!smacktalkData || !profile || isNaN(Number(store))) {
                return;
            }
            const message = serializeMessage();
            const result = await customSmackTalkApi.sendMessage(message);
            if (result !== 'Ok') {
                return;
            }
            setShowConfirmPopup(true);
        } catch (error) {
            console.error(error);
            addNotification({
                message: t('smack-talk__tab__send-message--send-failed'),
                type: NOTIFICATION_TYPES.ERROR
            });
            setSubmitEnabled(true);
        }
    };
    const onCancel = () => {
        resetData();
    };

    useEffect(() => {
        setSubmitEnabled(getSubmitStatus());
    }, [smacktalkData, profile, selections, selectedMessage]);

    return (
        <div className="hme-components send-smacktalk-message">
            <ConfirmPopupComponent
                show={showConfirmPopup}
                title={t('smack-talk__tab__send-message--message-sent')}
                dialogClassName="confirm-popup custom-smacktalk-page-confirm-popup"
                message={<>
                    <div className="custom-smacktalk-confirm-line">
                        {t('smack-talk__tab__send-message--send-another-message-to-group')}
                    </div>
                </>}
                onHide={() =>{
                    setShowConfirmPopup(false);
                }}
                actions={[{
                    children: t('common__no-done'),
                    onClick: () => {
                        resetData();
                        setShowConfirmPopup(false);
                    }
                }, {
                    children: t('common__yes'),
                    variants: ['submit'],
                    onClick: () => {
                        setSelectedMessage();
                        setShowConfirmPopup(false);
                    }
                }]}
            />
            <div className="send-smacktalk-message-sections">
                <SenderSection
                    number="1"
                    key="sender-1"
                    title={t('smack-talk__tab__send-message--sender-store')}
                    className={`send-smacktalk-message-1-section`}
                    profile={profile}
                    smacktalkData={smacktalkData}
                    onChange={onSenderChange}
                    store={store}
                    setStore={setStore}
                    sender={sender}
                    setSender={setSender}
                />
                <ReceiverSection
                    number="2"
                    key="receiver-2"
                    title={t('smack-talk__tab__send-message--receiver-store')}
                    className={`send-smacktalk-message-2-section`}
                    disabled={disableSecond}
                    profile={profile}
                    smacktalkData={smacktalkData}
                    selections={selections}
                    setSelections={setSelections}
                />
                <CategorySection
                    number="3"
                    key="category-3"
                    title={t('smack-talk__tab__send-message--select-category')}
                    className={`send-smacktalk-message-3-section`}
                    disabled={disableThird}
                    profile={profile}
                    smacktalkData={smacktalkData}
                    selectedMessage={selectedMessage}
                    setSelectedMessage={setSelectedMessage}
                />
            </div>
            <SaveFooter
                isSubmitEnabled={submitEnabled}
                onCancel={onCancel}
                onSave={onSave}
                okLabel={t('smack-talk__tab__send-message--send-message')}
                cancelLabel={t('common__cancel')}
            />
        </div>
    );
};
export const SendMessage = compose(
        withMasqueradeUser()
)(SendMessageTab);
