import React from 'react';

import { storeHierarchyNodeTypes } from 'constants/storeHierarchy';
import { Trigger } from 'hooks/useTrigger';

import { checkTextConnectStoreStatus } from '../helpers/checkTextConnectStoreStatus';
import { TreeText } from '../TreeText';

export const getStoresTree = (items, TextComponent = TreeText) => {
    return items.map((item) => {
        const isStore = item.Type === storeHierarchyNodeTypes.STORE;
        const isGroup = item.Type === storeHierarchyNodeTypes.GROUP;

        const storeStatus = isStore ? checkTextConnectStoreStatus(item) : null;

        const itemData = {
            ...item,
            storeStatus,
        };

        const result = {
            text: <TextComponent item={itemData} />,
            item,
            onExpand: new Trigger(),
            disabled: isStore ? !storeStatus?.status : false,
            isExpanded: true,
        };

        if (isStore) {
            result.value = item.StoreUid;
            result.key = item.StoreUid;
        }

        if (isGroup) {
            result.key = `Group_${item.Id}`;
        }

        if (item.Children?.length) {
            result.children = getStoresTree(item.Children, TextComponent);
        }

        return result;
    });
};
