// We can't use options from SourceDeviceSettings component
// because we can turn off/remove some options.
// But we need to display that names in the future

export const settingsGroupValues = {
    // ZOOM Nitro settings groups
    ALL_SETTINGS: 'All Settings',
    DASHBOARD_SETTINGS: 'Dashboard Settings',
    DAYPARTS_AND_SHIFTS: 'Dayparts and Shifts',
    LANE_SETTINGS: 'Lane Settings',
    SPEED_GOALS: 'Speed Goals',
    STORE_HOURS: 'Store Hours',
    TIME_FORMAT: 'Time Format',
    // ZOOM settings groups
    DASHBOARD_LAYOUT: 'Dashboard Layout',
    TOTAL_TIME_DEFENITION: 'Total Time Definition',
    MASTER_GOALS: 'Master Goals',
    DAYPART_TIMES: 'Daypart Times',
    SHIFT_TIMES: 'Shift Times',
    DAYPART_GOALS: 'Daypart Goals',
    // legacy settings groups
    DATA_OPTION: 'Data Option',
    TRANSACTION_GOALS: 'Transaction Goal',
    DASHBOARD: 'Dashboard',
};
