import React, { useState, useEffect, useCallback } from 'react';
import { pluck, intersection } from 'ramda';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAGE_STATE } from 'HOCs/withPageState';
import { filterSnapshots } from 'services/Snapshots';
import { deviceConfig, deviceTypes } from 'constants/device';
import { snapshotConfig } from 'constants/manageDeviceSettings';
import { Header } from './Header';
import { SourceSection } from './SourceSection';
import { FilterSection, filterTypes } from './FilterSection';
import { TargetDevicesSection } from './TargetDevicesSection';
import { Footer } from './Footer';
import {
    getAllSnapshots,
    getBrands,
    getCountries,
    applySettings,
} from './CreateTaskController';
import { LoaderByAccount } from './loaders/LoaderByAccounts';
import { LoaderByBrands } from './loaders/LoaderByBrands';
import { LoaderByStore } from './loaders/LoaderByStore';
import { checkSelectable } from '../helpers/checkSelectable';

import './CreateTask.scss';

const REDIRECT_TIMEOUT = 3000;

const {
    zoomNitro: {
        applySettingsMinVersion: applySettingsMinVersionNitro,
    },
    zoom: {
        applySettingsMinVersion: applySettingsMinVersionZoom,
        applySettingsMaxVersion: applySettingsMaxVersionZoom,
    }
} = deviceConfig;

const loadSnapshots = async (setSnapshots, setIsLoading) => {
    setIsLoading(true);
    const snapshotsRaw = await getAllSnapshots();
    const snapshots = {};
    snapshots.zoomNitro = filterSnapshots(snapshotsRaw, {
        type: 'ZOOM Nitro',
        minVersion: deviceConfig.zoomNitro.applySettingsMinVersion,
        status: snapshotConfig.statuses.CREATED,
    });
    snapshots.nexeo = filterSnapshots(snapshotsRaw, {
        type: 'NEXEO',
        status: snapshotConfig.statuses.CREATED,
    });

    setSnapshots(snapshots);
    setIsLoading(false);
};

const loadBrands = async setBrands => {
    setBrands(await getBrands());
};

const loadCountries = async setCountries => {
    setCountries(await getCountries());
};

const getLoadingFilters = (filters, params) => {
    const result = { ...filters };

    switch(params.selectedDeviceType) {
        case 'zoom':
            result.deviceMaxMainVersion = applySettingsMaxVersionZoom;
            result.deviceMinMainVersion = params.sourceDevice ?
                params.sourceDevice.Device_MainVersion :
                applySettingsMinVersionZoom;
            result.showIsBusyDevices = 1;
            result.showIsSameDetectorsOf = params.sourceDevice ? params.sourceDevice.Device_UID : '';

            break;
        case 'zoomNitro':
            result.deviceMinMainVersion = applySettingsMinVersionNitro;
            result.showIsBusyDevices = 1;

            break;
        case 'nexeo':
            result.deviceTypeId = 5;

            break;
        default:

            break;
    }

    return result;
};

const apply = ({
    targetDevices,
    sourceDevice,
    sourceSettingsList,
    settingTemplateID,
    selectedDeviceType
}) => {
    const settingParams = {
        targetDeviceUIDs: targetDevices.map(({ Device_UID }) => Device_UID),
        settingTemplateID,
        selectedDeviceType,
        sourceDeviceSettingGroups: sourceSettingsList,
    };

    if (sourceDevice) {
        settingParams.sourceDeviceUID = sourceDevice.Device_UID;
    }

    return applySettings(settingParams);
}

const isSourceFilled = ({ sourceDevice, sourceSettingsList, settingTemplateID, selectedDeviceType, isIOT }) => {
    // ZOOM(Nitro) non IOT devices should have selected settings groups when applying settings from device to device
    if (sourceDevice && sourceSettingsList?.length) {
        return true;
    }

    // all NEXEO devices are already IOT
    if (selectedDeviceType === deviceTypes.NEXEO && settingTemplateID) {
        return true;
    }

    // new ZOOM Nitro device starting from version 6.x will be IOT and should have selected snapshot settings groups
    if (selectedDeviceType === deviceTypes.ZOOM_NITRO && settingTemplateID && isIOT === 1 && sourceSettingsList?.length) {
        return true;
    }

    // old ZOOM devices before version 6.x will be not IOT and should not have selected snapshot settings groups
    if (selectedDeviceType === deviceTypes.ZOOM_NITRO && settingTemplateID && isIOT === 0) {
        return true;
    }

    return false;
};

const isFiltersFilled = ({ storeBrandId, storeCountryId, accountBrand, storeIds }) => (
        storeBrandId && storeCountryId
    ) ||
    (accountBrand.length !== 0) || storeIds.length;

const defaultParams = {
    sourceType: '',
    sourceDevice: null,
    selectedDeviceType: '',
    sourceSettingsList: null,
    settingTemplateID: '',
    targetDevices: [],
    isIOT: false,
};

const defaultFilters = {
    storeBrandId: '',
    storeCountryId: '',
    accountBrand: [],
    deviceIsActive: 1,
    deviceTypeId: 1,
    filterType: filterTypes.BRAND,
    storeIds: [],
};

const getNewSelection = (selectedDevices, targetDevices) => {
    const selectedDeviceUids = pluck('Device_UID', selectedDevices);
    const allDeviceUids = pluck('Device_UID', targetDevices);
    const oldSelected = intersection(selectedDeviceUids, allDeviceUids);

    return selectedDevices.filter(({ Device_UID }) => oldSelected.includes(Device_UID));
}

export const CreateTask = ({ setPageState, permissionType, redirectUrl }) => {
    const [snapshots, setSnapshots] = useState({ nexeo: [], zoomNitro: [] });
    const [isSnapshotsLoading, setIsSnapshotsLoading] = useState(false);
    const [brands, setBrands] = useState([]);
    const [countries, setCountries] = useState([]);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);
    const [filters, setFilters] = useState(defaultFilters);
    const [params, setParams] = useState(defaultParams);
    const [targetDevicesList, setTargetDevicesList] = useState([]);
    const [isTargetDevicesLoading, setIsTargetDevicesLoading] = useState(false);
    const [filterSectionDisabled, setFilterSectionDisabled] = useState(true);
    const [targetDevicesSectionDisabled, setTargetDevicesSectionDisabled] = useState(true);
    const [loaderByBrands, setLoaderByBrands] = useState(null);
    const [loaderByAccount, setLoaderByAccount] = useState(null);
    const [loaderByStore, setLoaderByStore] = useState(null);
    const [currentLoader, setCurrentLoader] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const selectedSnapshot = (snapshots[params.selectedDeviceType] || []).find(
        (snapshot) => snapshot.SettingTemplateID === params.settingTemplateID,
    );

    const onLoaderStateChange = useCallback(() => {
        const loading = currentLoader.isLoading();
        setIsTargetDevicesLoading(loading);
        let devices = currentLoader.getDevices();

        if (params.sourceDevice) {
            devices = devices.filter(
                ({ Device_UID }) => Device_UID !== params.sourceDevice.Device_UID
            );
        }

        // remove duplications in case the devices loaded from different accounts
        const uniqueDevices = [...new Map(devices.map((d) => [d['Device_ID'], d])).values()];

        setParams({
            ...params,
            targetDevices: getNewSelection(params.targetDevices, uniqueDevices),
        });

        setTargetDevicesList(uniqueDevices);
    }, [currentLoader, params, setParams, setIsTargetDevicesLoading, setTargetDevicesList]);

    const onStoreLoaderChange = useCallback(() => {
        const devices = loaderByStore.getDevices();
        setParams({
            ...params,
            targetDevices: devices,
        });
    }, [params, loaderByStore, setParams]);

    useEffect(() => {
        const brandLoader = loaderByBrands || new LoaderByBrands();
        const accountLoader = loaderByAccount || new LoaderByAccount();
        const storeLoader = loaderByStore || new LoaderByStore();
        const current = currentLoader || brandLoader;

        setLoaderByBrands(brandLoader);
        setLoaderByAccount(accountLoader);
        setLoaderByStore(storeLoader);
        setCurrentLoader(current);

        brandLoader.on('changed', onLoaderStateChange);
        accountLoader.on('changed', onLoaderStateChange);
        storeLoader.on('changed', onLoaderStateChange);
        storeLoader.on('changed', onStoreLoaderChange);

        return () => {
            brandLoader.off('changed', onLoaderStateChange);
            accountLoader.off('changed', onLoaderStateChange);
            storeLoader.off('changed', onLoaderStateChange);
            storeLoader.off('changed', onStoreLoaderChange);
        }
    }, [
        loaderByBrands,
        loaderByAccount,
        loaderByStore,
        currentLoader,
        onLoaderStateChange,
        setLoaderByBrands,
        setLoaderByAccount,
        setCurrentLoader,
        setIsTargetDevicesLoading,
        setTargetDevicesList
    ]);

    useEffect(() => {
        const isSourceSectionFilled = isSourceFilled(params);
        const isFiltersSectionFilled = isFiltersFilled(filters);

        setFilterSectionDisabled(!isSourceSectionFilled);
        setTargetDevicesSectionDisabled(!isSourceSectionFilled || !isFiltersSectionFilled);
    }, [params, filters, setFilterSectionDisabled, setTargetDevicesSectionDisabled], setIsSnapshotsLoading);

    useEffect(() => {
        loadSnapshots(setSnapshots, setIsSnapshotsLoading);
    }, [setSnapshots, setIsSnapshotsLoading]);

    useEffect(() => {
        loadBrands(setBrands);
    }, [setBrands]);

    useEffect(() => {
        loadCountries(setCountries);
    }, [setCountries]);

    useEffect(() => {
        setIsSubmitEnabled(
            !targetDevicesSectionDisabled && params.targetDevices.length && !checkAllDisabled(params.targetDevices),
        );
    }, [targetDevicesSectionDisabled, filters, params.targetDevices, setIsSubmitEnabled]);

    useEffect(() => {
        if (filterSectionDisabled) {
            loaderByBrands && loaderByAccount.reset();
            loaderByAccount && loaderByBrands.reset();
            loaderByStore && loaderByStore.reset();
            setIsTargetDevicesLoading(false);
            setTargetDevicesList([]);
            setFilters({ ...defaultFilters });
        }
    }, [
        filterSectionDisabled,
        loaderByAccount,
        loaderByBrands,
        setIsTargetDevicesLoading,
        setTargetDevicesList,
        setFilters
    ]);

    const redirectToStatusPage = () => {
        setTimeout(() => {
            navigate(redirectUrl);
        }, REDIRECT_TIMEOUT);
    };

    const checkAllDisabled = useCallback(
        (devices) => {
            // check if devices are disabled
            return (
                devices.length > 0 &&
                devices.reduce(
                    (result, device) =>
                        result && !checkSelectable({ device, selectedSnapshot, sourceDevice: params?.sourceDevice }),
                    true,
                )
            );
        },
        [selectedSnapshot, params],
    );

    const onCancel = useCallback(() => {
        setParams(defaultParams);
        setFilters(defaultFilters);

        navigate(redirectUrl);
    }, [setParams, setFilters]);

    const onFiltersClear = useCallback(() => {
        setParams({
            ...params,
            targetDevices: [],
        });
        setFilters(defaultFilters);
    }, [params, setParams, setFilters]);

    const onApply = useCallback(async () => {
        setPageState(PAGE_STATE.LOADING);
        try {
            await apply(params);
            setPageState(PAGE_STATE.SUCCESS);
            redirectToStatusPage();
        } catch(err) {
            setPageState(PAGE_STATE.ERROR);
            redirectToStatusPage();
        }
    }, [params, location, setPageState]);

    const onSourceChange = useCallback(newParams => {
        setParams({
            ...params,
            ...newParams,
            targetDevices: [],
        });
    }, [params, setParams]);

    const onFilterBlockChange = useCallback(type => {
        const newFilters = {
            ...filters,
            storeBrandId: '',
            storeCountryId: '',
            accountBrand: [],
            storeIds: [],
            filterType: type,
        };
        setParams({
            ...params,
            targetDevices: [],
        });
        setTargetDevicesList([]);
        setFilters(newFilters);

        currentLoader.reset();

        switch (type) {
            case filterTypes.BRAND:
                setCurrentLoader(loaderByBrands)

                break;
            case filterTypes.ACCOUNT:
                setCurrentLoader(loaderByAccount);

                break;
            case filterTypes.STORE:
                setCurrentLoader(loaderByStore);

                break;
            default:

                break;
        }
    }, [params, filters, loaderByBrands, loaderByAccount, currentLoader, setParams, setCurrentLoader, setFilters]);

    const onFiltersChange = useCallback(newFilters => {
        setParams({
            ...params,
            targetDevices: getNewSelection(params.targetDevices, targetDevicesList),
        });
        setFilters(newFilters);

        if (isFiltersFilled(newFilters)) {
            const loadingFilters = getLoadingFilters(newFilters, params);

            currentLoader.update(loadingFilters);
        }
    }, [params, targetDevicesList, currentLoader, setParams, setFilters]);

    const onRemove = useCallback((device) => {
        setTargetDevicesList(targetDevicesList.filter((item) => item.Device_UID !== device.Device_UID));
        setParams({
            ...params,
            targetDevices: params.targetDevices.filter((item) => item.Device_UID !== device.Device_UID),
        })
    }, [filters, params, currentLoader, setFilters]);

    return (
        <div className='hme-components admin-create-apply-device-settings-task-wrapper'>
            <div className='admin-create-apply-device-settings-task'>
                <div className="admin-create-apply-device-settings-task-content">
                    <div className='admin-create-apply-device-settings-task-header'>
                        <Header />
                    </div>
                    <div className='admin-create-apply-device-settings-task-sections'>
                        <div className='admin-create-apply-device-settings-task-source-area'>
                            <SourceSection
                                params={params}
                                sectionNumber={1}
                                snapshots={snapshots}
                                isSnapshotsLoading={isSnapshotsLoading}
                                onParamsChange={onSourceChange}
                            />
                            <FilterSection
                                filters={filters}
                                brands={brands}
                                countries={countries}
                                sourceDevice={params.sourceDevice}
                                selectedDeviceType={params.selectedDeviceType}
                                isDevicesSelected={!targetDevicesSectionDisabled}
                                onFilterBlockChange={onFilterBlockChange}
                                onFiltersChange={onFiltersChange}
                                sectionNumber={2}
                                disabled={filterSectionDisabled}
                            />
                        </div>
                        <TargetDevicesSection
                            params={params}
                            selectedSnapshot={selectedSnapshot}
                            searchType={filters.filterType}
                            targetDevices={targetDevicesList}
                            selectedDeviceType={params.selectedDeviceType}
                            isLoading={isTargetDevicesLoading}
                            sectionNumber={3}
                            disabled={targetDevicesSectionDisabled}
                            onDeviceRemove={onRemove}
                            onParamsChange={setParams}
                            onFiltersClear={onFiltersClear}
                            permissionType={permissionType}
                        />
                    </div>
                </div>
                <Footer
                    isSubmitEnabled={isSubmitEnabled}
                    onCancel={onCancel}
                    onApply={onApply}
                />
            </div>
        </div>
    );
};


