import { useState, useCallback } from 'react';
import { reconnect } from '../../../../services/Device';

export const useReconnectDevice = (...actions) => {
    const [isDeviceReconnecting, setIsDeviceReconnecting] = useState(false);
    const [deviceReconnectingError, setDeviceReconnectingError] = useState(null);

    const reconnectDevice = useCallback(async (duid) => {
        if (!duid) return;

        try {
            setIsDeviceReconnecting(true);
            setDeviceReconnectingError(null);

            const result = await reconnect(duid);
            await Promise.all(actions.map((action) => action(duid)));
            setIsDeviceReconnecting(false);

            return result && result.data && result.data.data;
        } catch (e) {
            setDeviceReconnectingError(e);
            setIsDeviceReconnecting(false);
        }
    }, []);

    return { isDeviceReconnecting, deviceReconnectingError, reconnectDevice };
};
