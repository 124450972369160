export const MOBILE_SCHEDULE_HEADERS = [
    {
        column: [
            { text: 'scheduler__name', property: 'schedule_name' },
            { text: 'scheduler__create-recipient-email', property: 'schedule_mails' },
            { text: 'scheduler__subject', property: 'schedule_subject' },
            { text: 'common__type', property: 'schedule_type' }
        ]
    },
    {
        column: [
            { text: 'scheduler__recurrence', property: 'schedule_recurrence' },
            { text: 'common__timezone', property: 'schedule_timezone' },
            { text: 'common__format', property: 'schedule_format' }
        ]
    }
];
