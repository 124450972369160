/* eslint-disable react/prop-types */
import classNames from 'classnames';
import { Header } from 'components/Common/Header/Header';
import React from 'react';
import './HeaderMenu.scss';

export const HeaderMenu = ({ title, menuItems, onClick, loading, disablePDF }) => {
    return (
        <div className="hme-header-menu">
            <Header type="normal">
                {title}
            </Header>
            <ul className="awards-resettable-tabs">
                {
                    menuItems.map(({ label, Icon, name }, i) => (
                        <li
                            className={classNames(
                                `awards-resettable-tab-${name} ${disablePDF && name == 'pdf' ? ' hide' : ''}`,
                                { 'loading': loading && ['csv', 'pdf'].includes(name) }
                            )}
                            key={label + i}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                return ['csv', 'pdf'].includes(name) && loading ? null : onClick(name);
                            }}
                        >
                            <Icon className="awards-resettable-icon"/>
                            <span
                                className="headerMenu active_tab_sub"
                            >
                                {label}
                            </span>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
};
