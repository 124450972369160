import { AdminStoreProperties } from './adminStoreProperties';

export const searchSelectOptions = [
    {
        text: 'common__account__email',
        value: AdminStoreProperties.userEmail,
        placeholder: 'admin__stores__search-placeholder--account-email'
    },
    {
        text: 'common__brand',
        value: AdminStoreProperties.brandName,
        placeholder: 'stores__search-placeholder--brand'
    },
    {
        text: 'common__store__number',
        value: AdminStoreProperties.storeNumber,
        placeholder: 'stores__search-placeholder--store-number'
    },
    {
        text: 'common__store__name',
        value: AdminStoreProperties.storeName,
        placeholder: 'stores__search-placeholder--store-name'
    },
    {
        text: 'common__device__serial-number',
        value: AdminStoreProperties.deviceSerialNumber,
        placeholder: 'stores__search-placeholder--serial-number'
    },
    {
        text: 'common__device__product-id',
        value: AdminStoreProperties.deviceProductID,
        placeholder: 'admin__systems__search__placeholder--product-id'
    },
    {
        text: 'common__system__version',
        value: AdminStoreProperties.deviceVersion,
        placeholder: 'stores__search-placeholder--system-version'
    },
    {
        text: 'common__customer-number',
        value: AdminStoreProperties.axAccountNumber,
        placeholder: 'stores__search-placeholder--customer--number'
    },
    {
        text: 'common__registration-key',
        value: AdminStoreProperties.storeRegistrationKey,
        placeholder: 'stores__search-placeholder--registration-key'
    },
    {
        text: 'common__all',
        value: '',
        placeholder: 'stores__search-placeholder--all'
    }
];
