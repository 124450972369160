/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../components/Common/Button/Button';

import './Tabs.css';

class Tabs extends Component {
    render() {
        const { children, activeTab, tabs, onTabChange, divider = '|' } = this.props;

        return (
            <div className="hme-tabs-container">
                <div className="hme-tabs-container__tabs">
                    {tabs.map((tab, index) => (
                        <Fragment key={tab.value}>
                            <Button
                                label={tab.label}
                                onClick={() => onTabChange(tab.value)}
                                bold={tab.value === activeTab}
                            />
                            {index + 1 !== tabs.length && divider}
                        </Fragment>
                    ))}
                </div>
                <div className="hme-tabs-container__active-tab-content">
                    {children(activeTab)}
                </div>
            </div>
        );
    }
}

Tabs.propTypes = {
    tabs: PropTypes.array.isRequired,
    onTabChange: PropTypes.func
};

export { Tabs };
