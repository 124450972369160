/* eslint-disable react/prop-types */
import './LandingSubHeader.scss';
import React from 'react';
import classNames from 'classnames';
import { empty } from 'ramda';
import { Checkbox } from '../../../../../library/Checkbox';
import { useTranslation } from 'react-i18next';

export const LandingSubHeader = ({
    filtersCounter,
    resetFilters = empty,
    gridFilters = { status: [] },
    setGridFilters,
    isManageMessages=false,
    children
}) => {
    const { t } = useTranslation();
    return (<>
        <div className="hme-messages-landing-header">
            <div className="hme-messages-landing-filters">
                <span className={classNames('hme-messages-landing-header-filter-counter', { applied: filtersCounter > 0 })}>
                    <strong>{filtersCounter}</strong>&nbsp;{t('common__filters-applied').toLowerCase()}&nbsp;
                </span>
                {
                    Boolean(filtersCounter) &&
                    <span className="hme-messages-landing-header-reset" onClick={resetFilters}>
                        {t('common__reset-all-filters')}
                    </span>
                }
            </div>
            {isManageMessages && <><div className="hme-messages-landing-header-divider"></div>
            <Checkbox
                label={t('smack-talk__tab__messages-landing--hide-disabled')}
                checked={gridFilters.status.includes('hide')}
                onChange={(checked, e) => {
                    e.stopPropagation();
                    const newSelection = checked ?
                ['hide'] : [];
                    setGridFilters({ ...gridFilters, 'status': newSelection });
                }}
            /></>}
            {children}
        </div>
    </>);
};
