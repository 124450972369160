import { Config } from '../Config';
import { CommonConstants } from '../Constants';
import { getDataAsync } from '../Api';

export const getLBGroupNames = async (templateId) => {
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.getLBGroupNames;

    const response = await getDataAsync(`${url}?templateId=${templateId}`);

    return response.status ? response.data : [];
};
