export const headers = [{
    text: 'common__role',
    property: 'Role_Name',
    flex: 0.75,
}, {
    text: 'common__company',
    property: 'Company_Name',
    flex: 0.75,
}, {
    text: 'common__description',
    property: 'Role_Description',
    flex: 1.25,
}, {
    text: 'common__users',
    property: 'UserName',
    flex: 1.5,
}, {
    text: 'common__actions',
    property: 'Actions',
    className: 'hme-grid-centered-cell',
    headerClassName: 'hme-grid-centered-cell',
    flex: 1.25,
}];
