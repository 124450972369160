import * as React from 'react';

import './HMECheckbox.css';

export const HMECheckbox = ({ onChange, title, value, isSelectedPartially }) => {
    return (
        <label className={`hme-checkbox ${isSelectedPartially ? 'partially-selected': ''}`}> {title}
            <input
                type='checkbox'
                onChange={({ target: { checked } }) => onChange(checked)}
                checked={value}
            />
            <span className='checkmark'></span>
            <span className='dash'></span>
        </label>
    );
};
