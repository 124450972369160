import { useState, useEffect, useCallback } from 'react';

import { useAsync, ASYNC_STATUS } from 'hooks/useAsync';
import { isLoggedIn, isPublic } from 'services/Auth';
import { DateLib } from 'helpers/DateLib';
import { NotificationCenterService } from 'services/NotificationCenter';
import { LOCAL_STORAGE_KEYS } from 'constants/userNotifications';

const reloadTime = 2 * 60 * 1000;

export const useUnreadNotifications = () => {
    const [hasUnreadNotifications, setHasUnreadNotifications] = useState(
        () => localStorage.getItem(LOCAL_STORAGE_KEYS.UNREAD_KEY) === 'true'
    );
    // we need it for masquarad. It is the best solution that I could find
    const [reseted, setReseted] = useState(false);

    const {
        run: fetchUnread,
        isLoading,
    } = useAsync({
        status: ASYNC_STATUS.RESOLVED
    });

    const resetUnread = useCallback(() => {
        setReseted((value) => !value)
    }, []);

    const changeHasUnread = useCallback((newValue) => {
        setHasUnreadNotifications(newValue);
        localStorage.setItem(LOCAL_STORAGE_KEYS.UNREAD_KEY, newValue)
    }, []);

    const loadUnread = useCallback(async () => {
        const hasUnread = await fetchUnread(NotificationCenterService.hasUnread());
        changeHasUnread(hasUnread);
        localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_TIME, new Date().toISOString());
    }, []);

    useEffect(() => {
        if (!isLoggedIn() || !isPublic()) {
            return;
        }

        const lastTime = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_TIME);
        const nextTime = lastTime ? reloadTime - (new DateLib().diffMilliseconds(new DateLib(lastTime))) : 0;
        let interval;

        const timer = setTimeout(() => {
            loadUnread();

            interval = setInterval(() => loadUnread(), reloadTime);
        }, nextTime);

        return () => {
            clearTimeout(timer);
            interval && clearInterval(interval);
        };
    }, [loadUnread, location, reseted]);

    return {
        hasUnreadNotifications,
        isUreadNotificationsLoading: isLoading,
        changeHasUnread,
        loadUnread,
        resetUnread
    };
};
