import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';
import { Button } from 'library/Button';
import { roleProp } from '../types/roleProps';
import { permissionsProp } from '../types/permissionsProps';
import { PermissionsSection } from './PermissionsSection';
import { permissionSections, dependencies } from './constants/sections';
import { permissionTypes } from 'constants/permissionTypes';
import AuthenticationService from 'components/Security/AuthenticationService';
import { Config } from 'Config';

import './Permissions.scss';

const getNewPermissionList = (rolePermissions, permissions, permission, checked) => {
    const permissionsList = [permission.name];

    if (checked) {
        if (dependencies[permission.name]) {
            permissionsList.push(...dependencies[permission.name]);
        }
    } else {
        const dependentPermission = Object.keys(dependencies).reduce((val, key) => {
            const depends = dependencies[key];

            if (depends.includes(permission.name)) {
                val.push(key);
            }

            return val;
        }, []);

        permissionsList.push(...dependentPermission);
    }

    const permissionUids = permissionsList.map((name) => {
        const foundPermission = permissions.find((item) => item.name === name);

        return foundPermission ? foundPermission.value : null;
    }).filter((value) => !!value);

    const newPermissions = rolePermissions.filter((value) => !permissionUids.includes(value));

    if (checked) {
        newPermissions.push(...permissionUids);
    }

    return newPermissions;
};

const getSectionData = (permissions, isAccountDistributor) => {
    const authService = new AuthenticationService(Config.authBaseUrl);
    return permissionSections.map((section) => ({
        title: section.title,
        type: section.type,
        permissions:
            permissions.filter(({ type }) => (section.type === permissionTypes.EXTERNAL && authService.isAdmin())  ?
                ( isAccountDistributor && type === section.type ) : (type === section.type) )
    })).filter((section) => Boolean(section.permissions.length));
};

const selectLinkVariants = ['transparent'];

export const Permissions = ({ permissions, isOwner, role, onRoleChange, isAccountDistributor }) => {
    const { t } = useTranslation();

    const sectionsData = useMemo(() => getSectionData(permissions, isAccountDistributor), [permissions, isAccountDistributor]);

    const onPermissionChange = useCallback((permission, checked) => {
        const newPermissions = getNewPermissionList(role.permissions, permissions, permission, checked);

        onRoleChange && onRoleChange({
            ...role,
            permissions: newPermissions,
        });
    }, [role, permissions]);

    const onDeselectAll = useCallback(() => {
        onRoleChange({
            ...role,
            permissions: [],
        });
    }, [role, onRoleChange]);

    const onSelectAll = useCallback(() => {
        const allPermissionsValue = permissions.map(({ value }) => value);

        onRoleChange({
            ...role,
            permissions: allPermissionsValue,
        });
    }, [permissions, role, onRoleChange]);

    return (
        <div className='hme-view-role__permissions'>
            <Title variants={['block']}>{t('common__permissions')}</Title>
            { !isOwner &&
                <div className='hme-view-role__permissions__select-links'>
                    <Button variants={selectLinkVariants} onClick={onSelectAll}>
                        {t('common__select-all')}
                    </Button>
                    <Button
                        variants={selectLinkVariants}
                        onClick={onDeselectAll}
                        className='hme-view-role__permissions__select-links__deselect-all'
                    >
                        {t('common__deselect-all')}
                    </Button>
                </div>
            }
            <div className='hme-view-role__permissions-section__sections'>
                {
                    sectionsData.map((section, index) => (
                        <PermissionsSection
                            key={section.type}
                            title={section.title}
                            permissions={section.permissions}
                            isFirst={index === 0}
                            isDisabled={isOwner}
                            role={role}
                            type={section.type}
                            onChange={onPermissionChange}
                        />
                    ))
                }
            </div>
        </div>
    );
};

Permissions.propTypes = {
    permissions: permissionsProp,
    role: roleProp,
    isOwner: PropTypes.bool,
    onRoleChange: PropTypes.func,
    isAccountDistributor: PropTypes.bool
};
