import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CommonConstants } from 'Constants';

import { useAccess } from 'hooks/useAccess';

import { Tip } from 'library/Tip';
import { Title } from 'library/Title';
import { Button } from 'library/Button';

import './Header.scss';

const buttonVariants = ['transparent'];
const { adminPermissions } = CommonConstants;

export const Header = ({
    title,
    hasRemove = false,
    hasMasquerade = false,
    hasResendEmail = false,
    masqueradeWarnings = [],
    isSendingEmail = false,
    isMasquerading = false,
    onRemoveClick,
    onMasquerade,
    onResendEmail,
}) => {
    const { t } = useTranslation();

    const hasMasqueradeAccess = useAccess(adminPermissions.Masquerade);

    return (
        <div className="hme-view-user__header">
            <Title>{title}</Title>
            {hasRemove && (
                <Button variants={buttonVariants} className="hme-view-user__header__link" onClick={onRemoveClick}>
                    {t('add-user__remove-btn')}
                </Button>
            )}
            {hasMasqueradeAccess && onMasquerade && (
                <>
                    <Button
                        variants={buttonVariants}
                        className="hme-view-user__header__link hme-view-user__header__link--masquerade"
                        onClick={onMasquerade}
                        disabled={!hasMasquerade || isMasquerading}
                    >
                        {t('add-user__masquerade-as-user')}
                    </Button>
                    {!hasMasquerade && masqueradeWarnings?.length && (
                        <Tip className="hme-view-user__header__tip" placement="right">
                            {masqueradeWarnings.map((warning, index) => (<div key={index}>{t(warning)}</div>))}
                        </Tip>
                    )}
                </>
            )}
            {hasResendEmail && onResendEmail && (
                <Button
                    variants={buttonVariants}
                    className="hme-view-user__header__link hme-view-user__header__link--resend-email"
                    onClick={onResendEmail}
                    disabled={isSendingEmail}
                >
                    {t('admin__user__resend-activation-email')}
                </Button>
            )}
        </div>
    );
};

Header.propTypes = {
    title: PropTypes.string.isRequired,
    hasMasquerade: PropTypes.bool,
    hasResendEmail: PropTypes.bool,
    hasRemove: PropTypes.bool,
    onRemoveClick: PropTypes.func,
    onMasquerade: PropTypes.func,
    onResendEmail: PropTypes.func,
};
