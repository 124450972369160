import React from 'react';
import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import { withAccess } from 'HOCs/withAccess';
import { CommonConstants } from 'Constants';
import { checkAccess } from 'actions/permissions';
import authenticate from 'components/Security/Authentication';
import { Status } from '../Status';
import { useAdminStatus } from './hooks/useAdminStatus';

const AdminStatusComponent = () => {
    const state = useAdminStatus();

    return (
        <Status {...state} />
    );
};

const hasAccess = checkAccess(CommonConstants.adminPermissions.CustomerNotification);

export const AdminStatus = compose(
    withHMELayout(),
    withAccess({
        checkAccess: () => hasAccess
    }),
    authenticate
)(AdminStatusComponent);
