import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import { NotificationsList } from 'library/NotificationsList';
import { Grid } from 'components/Common/Grid';
import { Paginate } from 'library/Paginate';

import { headers } from './constants/headers';
import { Header } from './Header';

import './Status.scss';

const StatusComponent = ({
    partners,
    isLoading,
    noRowsMessage,
    sorting,
    onSortingChange,
    onSearch,
    pagination,
    onPaginationChange
}) => {
    return (
        <div className='hme-partners-status'>
            <NotificationsList />
            <Header onSearch={onSearch} />
            <Grid
                rows={partners}
                headers={headers}
                isLoading={isLoading}
                rowKey='PartnerUID'
                noRecordsMessage={noRowsMessage}
                sortSelection={sorting}
                onSortChange={onSortingChange}
            />
            <Paginate {...pagination} onChange={onPaginationChange} />
        </div>
    );
};

export const Status = compose(
    withHMELayout()
)(StatusComponent);
