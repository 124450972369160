import { useState, useEffect, useCallback } from 'react';
import { scheduledUpgradesApi } from 'api/scheduledUpgradesApi';

const { countBrandDevicesByCountryTimezone } = scheduledUpgradesApi;

export const useLoadCountBrandDevicesByCountryTimezone = (input) => {
    const { brandIDs, targetDeviceVersions, deviceType, peripheralDeviceType } = input;
    const [countryTimezoneDevicesCount, setCountryTimezoneDevicesCount] = useState([]);
    const [isCountryTimezoneDevicesCountLoading, setIsCountryTimezoneDevicesCountLoading] = useState(false);
    const [countryTimezoneDevicesCountLoadingError, setCountryTimezoneDevicesCountLoadingError] = useState(null);

    const loadCountBrandDevicesByCountryTimezone = useCallback(
        async ({ brandIDs, targetDeviceVersions, deviceType, peripheralDeviceType }) => {
            try {
                setIsCountryTimezoneDevicesCountLoading(true);
                setCountryTimezoneDevicesCountLoadingError(null);

                if (brandIDs && brandIDs.length > 0
                    && targetDeviceVersions && targetDeviceVersions.length > 0 && deviceType
                ) {
                    const brandDevicesByCountryTimezoneCount = await countBrandDevicesByCountryTimezone({
                        brandIDs: brandIDs.join(','),
                        targetDeviceVersions: targetDeviceVersions.join(','),
                        deviceType,
                        peripheralDeviceType,
                    });

                    setCountryTimezoneDevicesCount(brandDevicesByCountryTimezoneCount);
                }
            } catch (e) {
                setCountryTimezoneDevicesCount([]);
                setCountryTimezoneDevicesCountLoadingError(e);
            }

            setIsCountryTimezoneDevicesCountLoading(false);
        },
        [brandIDs, targetDeviceVersions, deviceType, peripheralDeviceType],
    );

    useEffect(
        () => {
            loadCountBrandDevicesByCountryTimezone({ brandIDs, targetDeviceVersions, deviceType, peripheralDeviceType });
        },
        [brandIDs, targetDeviceVersions, deviceType, peripheralDeviceType],
    );

    return {
        countryTimezoneDevicesCount,
        isCountryTimezoneDevicesCountLoading,
        countryTimezoneDevicesCountLoadingError,
        loadCountBrandDevicesByCountryTimezone,
    };
};
