import { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PUBLIC_ROUTES } from 'constants/routes';
import { useAsync, ASYNC_STATUS } from 'hooks/useAsync';
import { getAccountData, saveViewFranchise } from 'services/Account';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';

export const useCorporate = () => {
    const { data: originViewFranchise, isLoading: isDataLoading, run: load } = useAsync({ data: false });
    const { run: save, status: savingStatus } = useAsync();
    const [viewFranchise, setViewFranchise] = useState(false);
    const isSaveEnabled = useMemo(() => originViewFranchise !== viewFranchise, [originViewFranchise, viewFranchise]);
    const isSaving = savingStatus === ASYNC_STATUS.PENDING;
    const navigate = useNavigate();

    const onLoad = useCallback(() => {
        return load(getAccountData()
                .then((data) => {
                    setViewFranchise(Boolean(data.ViewFranchise));

                    return Boolean(data.ViewFranchise);
                }).catch(() => {
                    addErrorNotification('common__error--internal-server');
                }));
    }, []);

    const onSave = useCallback(() => {
        save(saveViewFranchise(viewFranchise)
                .then(() => onLoad())
                .then(() => addSuccessNotification('my-account__success__save'))
                .catch(() => addErrorNotification('common__error--changes-unsaved')));
    }, [viewFranchise, onLoad]);

    const onCancel = useCallback(() => {
        navigate(`/${PUBLIC_ROUTES.account}`);
    }, [navigate]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    return {
        title: 'profile__brand-sharing__title_corporate',
        isLoading: isDataLoading || isSaving,
        viewFranchise,
        isSaveEnabled,
        onViewFranchiseChange: setViewFranchise,
        onSubmit: onSave,
        onCancel
    };
};
