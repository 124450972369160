import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Title } from 'library/Title';
import { Button } from 'library/Button';

import { STORE_DETAILS_TYPE } from '../../types/storeDetailsType';

import './Header.scss';

export const Header = ({ storeDetails, isRemoveStoreButtonVisible, onStoreDeleteClick }) => {
    const { t } = useTranslation();
    const { Store_Name: storeName = '', Store_Number: storeNumber = '' } = storeDetails;

    return <div className="hme-admin-store-details__header">
        <Title>{t('stores__view--edit--store__title', { storeName, storeNumber })}</Title>
        <div className="hme-admin-store-details__header__remove-store">
            {isRemoveStoreButtonVisible &&
                <Button
                    variants={['transparent']}
                    onClick={onStoreDeleteClick}
                >
                    {t('stores__modal__remove')}
                </Button>
            }
        </div>
    </div>;
};

Header.propTypes = {
    storeDetails: STORE_DETAILS_TYPE.isRequired,
    isRemoveStoreButtonVisible: PropTypes.bool.isRequired,
    onStoreDeleteClick: PropTypes.func.isRequired
};
