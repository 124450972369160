import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { uniq, uniqBy, pluck } from 'ramda';
import sortBySelection from 'helpers/sortBySelection';
import compareVersions from 'helpers/sortComparators/version';
import { applyPagination } from 'helpers';
import { Grid, getFiltersCount, resetFilters } from 'components/Common/Grid';
import { TileList } from 'library/TileList';
import { Checkbox } from 'library/Checkbox';
import { Paginate } from 'library/Paginate';
import { deviceTypes, deviceServerTypes } from 'constants/device';
import { PAGE_DATA, PAGE_DEFAULT } from 'constants/paginationDefault';
import { TargetDeviceHeader } from './TargetDeviceHeader';
import { checkSelectable } from '../../../../helpers/checkSelectable';
import {
    TARGET_DEVICES_TILE_FILTER_OPTIONS,
    NEXEO_TARGET_DEVICES_TILE_FILTER_OPTIONS,
    GRID_HEADERS,
    NEXEO_GRID_HEADERS,
    MOBILE_GRID_HEADERS,
    MOBILE_NEXEO_GRID_HEADERS,
    ALL_BRANDS_FILTER_TEXT,
    ALL_VERSIONS_FILTER_TEXT,
    MOBILE_SORT_OPTIONS
} from '../../constants';
import { deviceConfig } from 'constants/device';
import { CommonConstants } from 'Constants';
const { deviceType } = CommonConstants;

import './TargetDeviceList.scss';

const {
    PAGE_SIZES_PUBLIC,
} = PAGE_DATA;

const getPropertiesList = (isNexeoSourceType) =>
    (isNexeoSourceType ? NEXEO_GRID_HEADERS : GRID_HEADERS).map(({ property }) => property);

const { getLaneType } = deviceConfig;
const INIT_ZOOM_SORT_SELECTION = { Device_SerialNumber: 1 };
const INIT_NEXEO_SORT_SELECTION = { Store_Number: 1 };
const getSortComparators = (sortSelection = {}) => ({
    Device_MainVersion: (a, b) =>
        compareVersions(a.Device_MainVersion, b.Device_MainVersion, sortSelection.Device_MainVersion),
    Device_SettingVersion: (a, b) =>
        compareVersions(a.Device_SettingVersion, b.Device_SettingVersion, sortSelection.Device_SettingVersion),
});

const defaultSortComparators = getSortComparators();

export const TargetDeviceList = ({
    devices,
    sourceType,
    selectedSnapshot,
    selection = [],
    isLoading = false,
    onSelectionChange,
    params,
}) => {
    const [filter, setFilter] = useState('');
    const [filteredDevices, setFilteredDevices] = useState(devices);
    const [filtersCount, setFiltersCount] = useState(0);
    const [gridFilters, setGridFilters] = useState({
        Brand_Name: [],
        Device_MainVersion: [],
        Device_SettingVersion: [],
        Device_LaneConfig_Name: [],
    });
    const [availableFilters, setAvailableFilters] = useState([]);
    const [sortSelection, setSortSelection] = useState(INIT_ZOOM_SORT_SELECTION);
    const [isDisabledHidden, setIsDisabledHidden] = useState(false);
    const [pageData, setPageData] = useState(PAGE_DEFAULT);

    const { t } = useTranslation();

    const targetDeviceUIDs = useMemo(() => selection.map((td) => td.Device_UID), [selection]);

    const sourceDevice = params?.sourceDevice;
    const isNexeoSourceType = sourceType === deviceTypes.NEXEO;
    const checkSelected = (row) => targetDeviceUIDs.includes(row.Device_UID);

    const checkDisabled = (device) => !checkSelectable({ device, selectedSnapshot, sourceDevice });

    const filteredDeviceRows = useMemo(
        () =>
            isDisabledHidden
                ? filteredDevices.filter((device) => checkSelectable({ device, selectedSnapshot, sourceDevice }))
                : filteredDevices,
        [filteredDevices, isDisabledHidden, selectedSnapshot, sourceDevice, checkSelectable],
    );

    const mobileDeviceRows = useMemo(() => {
        return filteredDeviceRows.map((row) => {
            const checked = checkSelected(row);
            const isDisabled = !checkSelectable({ device: row, selectedSnapshot, sourceDevice });

            return {
                ...row,
                selectColumn: (
                    <Checkbox
                        label={t('common__select')}
                        checked={checked}
                        onChange={(value) => onSelectDevice(row, value)}
                        disabled={isDisabled}
                    />
                ),
            };
        });
    }, [filteredDeviceRows, targetDeviceUIDs, selectedSnapshot, sourceDevice, checkSelectable]);

    useEffect(() => {
        const brands = devices.map(({ Brand_ID: value, Brand_Name: text }) => ({
            text,
            value,
        }));

        const uniqBrands = uniqBy(({ value }) => value, brands)
            .sort(({ text: text1 }, { text: text2 }) => {
                return text1 < text2 ? -1 : (text1 > text2 ? 1 : 0);
            });

        const laneConfigs = devices.map(({ Device_LaneConfig_ID: value }) => ({
            text: getLaneType(value),
            value,
        }));

        const uniqLaneConfigs = uniqBy(({ value }) => value, laneConfigs)
            .sort(({ text: text1 }, { text: text2 }) => {
                return text1 < text2 ? -1 : (text1 > text2 ? 1 : 0);
            });
            
        const versions = pluck('Device_MainVersion', devices);
        const uniqVersions = uniq(versions).sort();
        const settingsVersions = pluck('Device_SettingVersion', devices);
        const uniqSettingsVersions = uniq(settingsVersions).sort();

        setGridFilters({
            Brand_Name: pluck('value', uniqBrands),
            Device_MainVersion: uniqVersions,
            Device_SettingVersion: uniqSettingsVersions,
            Device_LaneConfig_Name: pluck('value', uniqLaneConfigs),
        });

        setAvailableFilters([
            {
                property: 'Brand_Name',
                allText: ALL_BRANDS_FILTER_TEXT,
                items: uniqBrands,
            },
            {
                property: 'Device_MainVersion',
                allText: ALL_VERSIONS_FILTER_TEXT,
                items: uniqVersions.map((version) => ({
                    text: version,
                    value: version,
                })),
                sortComparator: defaultSortComparators.Device_MainVersion,
            },
            {
                property: 'Device_SettingVersion',
                allText: ALL_VERSIONS_FILTER_TEXT,
                items: uniqSettingsVersions.map((version) => ({
                    text: version,
                    value: version,
                })),
                sortComparator: defaultSortComparators.Device_SettingVersion,
            },
            {
                property: 'Device_LaneConfig_Name',
                allText: 'common__all-lane-configs',
                items: uniqLaneConfigs,
            },
        ]);
    }, [devices, t, setGridFilters, setAvailableFilters]);

    useEffect(() => {
        // Filter by Brand_Name, Device version, Device_SettingVersion
        let newFilteredDevices = devices.filter(
            ({ Brand_ID, Device_MainVersion, Device_SettingVersion, Device_LaneConfig_ID }) =>
                gridFilters.Brand_Name.includes(Brand_ID) &&
                gridFilters.Device_MainVersion.includes(Device_MainVersion) &&
                gridFilters.Device_SettingVersion.includes(Device_SettingVersion) &&
                gridFilters.Device_LaneConfig_Name.includes(Device_LaneConfig_ID)
        );

        // Split for words
        const words = filter
            .split(' ')
            .map(word => word.toLowerCase());

        // Find all shown values
        const propertiesList = getPropertiesList(isNexeoSourceType);
        const values = newFilteredDevices.map((device) =>
            propertiesList.map((key) => device[key]).filter((value) => value)
        );

        // We use values variable to avoid N^3 complexity
        newFilteredDevices = newFilteredDevices.filter((_, index) =>
            words.every((word) => values[index].some((value) => value.toString().toLowerCase().indexOf(word) !== -1)),
        );

        const list = newFilteredDevices.map((device) => {
            const isSelectable = checkSelectable({ device, selectedSnapshot, sourceDevice });

            return {
                ...device,
                DeviceServerType: device.iotDeviceId ? t('common__service-type__iot') : t('common__service-type__legacy'),
                isSelectable,
                rowClassName: !isSelectable ? 'hme-grid-row-disabled' : '',
                Device_LaneConfig_Name: getLaneType(device.Device_LaneConfig_ID),
            };
        })

        setFilteredDevices(sortBySelection({
            list,
            sortSelection,
            comparators: getSortComparators(sortSelection),
        }));
    }, [devices, filter, gridFilters, sortSelection, selectedSnapshot, sourceDevice, setFilteredDevices]);

    useEffect(() => {
        setFiltersCount(getFiltersCount(availableFilters, gridFilters));
    }, [availableFilters, gridFilters]);

    useEffect((newSourceType) => {
        setSortSelection(isNexeoSourceType ? INIT_NEXEO_SORT_SELECTION : INIT_ZOOM_SORT_SELECTION);
    }, [sourceType])

    const onFiltersReset = useCallback(() => {
        setFiltersCount(0);
        resetFilters(availableFilters, setGridFilters);
    }, [availableFilters, setGridFilters]);

    const onSelectAllDevices = useCallback((value) => {
        const selectedDevices = value
            ? [...selection, ...filteredDevices]
            : selection.filter((sd) => !filteredDevices.includes(sd));

        onSelectionChange(selectedDevices);
    }, [selection, filteredDevices, onSelectionChange]);   

    const onSelectDevice = useCallback((row, value) => {
        const newTargetDevices = value
            ? [...selection, row]
            : [...selection].filter((d) => d.Device_UID !== row.Device_UID);

        onSelectionChange(newTargetDevices);
    }, [onSelectionChange, selection]);

    const onFiltersAndSortChangeHandler = useCallback(({ selectedFilters, selectedSortValue }) => {
        setGridFilters(selectedFilters);
        setSortSelection(selectedSortValue);
    }, []);

    return (
        <div className="hme-target-device-list">
            <TargetDeviceHeader
                devices={devices}
                gridFiltersCount={filtersCount}
                searchValue={filter}
                isDisabledHidden={isDisabledHidden}
                onDisabledViewChange={setIsDisabledHidden}
                onSearchChange={setFilter}
                onFiltersReset={onFiltersReset}
                isNexeoSourceType={isNexeoSourceType}
            />
            <Grid
                rows={applyPagination(filteredDeviceRows, pageData)}
                headers={isNexeoSourceType ? NEXEO_GRID_HEADERS : GRID_HEADERS}
                selection={selection}
                noRecordsMessage={t('apply-device-settings-status__no-devices--found')}
                rowKey="Device_UID"
                availableFilters={availableFilters}
                filters={gridFilters}
                onFiltersChange={setGridFilters}
                onSelectionChange={onSelectionChange}
                onSortChange={setSortSelection}
                checkSelected={checkSelected}
                checkDisabled={checkDisabled}
                sortSelection={sortSelection}
                selectable
            />
            <TileList
                headers={isNexeoSourceType ? MOBILE_NEXEO_GRID_HEADERS : MOBILE_GRID_HEADERS}
                rows={applyPagination(mobileDeviceRows, pageData)}
                isLoading={isLoading}
                noRecordsMessage="common__no-devices--found"
                radioColumn={'selectColumn'}
                checkSelected={checkSelected}
                checkDisabled={checkDisabled}
                filtersOptions={
                    isNexeoSourceType ? NEXEO_TARGET_DEVICES_TILE_FILTER_OPTIONS : TARGET_DEVICES_TILE_FILTER_OPTIONS
                }
                availableFilters={availableFilters}
                filters={gridFilters}
                filtersCount={filtersCount}
                sortSelection={sortSelection}
                sortOptions={MOBILE_SORT_OPTIONS}
                onFiltersAndSortReset={onFiltersReset}
                onFiltersReset={onFiltersReset}
                onSelectAll={onSelectAllDevices}
                onFiltersAndSortChange={onFiltersAndSortChangeHandler}
                selectable
                isSortingDropdown
            />
            <Paginate
                className="hme-target-device-list__paginate hme-paginate__mobile"
                page={pageData.page}
                recordsPerPage={pageData.recordsPerPage}
                total={filteredDeviceRows.length}
                onChange={setPageData}
                pageSizes={PAGE_SIZES_PUBLIC}
            />
        </div>
    );
}

TargetDeviceList.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    onSelectionChange: PropTypes.func,
    selection: PropTypes.array
};
