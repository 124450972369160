import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Tile } from "library/Tile";
import { Label } from "library/Label";
import { Loader } from "library/Loader";
import { TileListItem } from "./TileListItem";

export const TilesList = ({ stores, isLoading }) => {
    const { t } = useTranslation();

    return (
        <div className="hme-s-size hme-stores-status--partner__tile-list">
            {isLoading && <Loader />}
            {!isLoading && !stores.length && (
                <Label className="hme-stores-status--partner__tile-list__no-records">
                    {t("common__no-stores--found")}
                </Label>
            )}
            {stores.map((store) => {
                const {
                    Brand_Name: brandName = "",
                    Store_Name: storeName = "",
                    Store_AddressLine1: storeAddress = "",
                    cityString: cityAndState = "",
                    Store_UID: storeUUID = "",
                    Store_Number: storeNumber = "",
                    Group_Name: leaderboard = "",
                    GroupName: reportGroup = "",
                    Status: { props: { devices = [] } = {} } = {},
                } = store;
                return (
                    <Tile key={storeUUID}>
                        <TileListItem
                            storeNumber={storeNumber}
                            leaderboard={leaderboard}
                            reportGroup={reportGroup}
                            devices={devices}
                            brandName={brandName}
                            storeName={storeName}
                            storeAddress={storeAddress}
                            cityAndState={cityAndState}
                            storeUUID={storeUUID}
                        />
                    </Tile>
                );
            })}
        </div>
    );
};

TilesList.propTypes = {
    stores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
