import React, { useEffect } from 'react';
import { Constant } from '@hme-cloud/utility-common';
import { useParams, useNavigate } from 'react-router-dom';
import { compose } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Header } from './Header';
import { useLoadDevice } from './hooks/useLoadDevices';
import { withHMELayout } from 'HOCs/withHMELayout';
import { formatDisplayData } from './helpers';
import CommonLoader from 'components/Alerts/CommonLoader';
import { CenterErrorMessage } from 'components/Common/CenterMessages';
import DeviceDetails from './DeviceDetails/DeviceDetails';
import { ADMIN_SYSTEM_NOT_FOUND_URL } from '../Common/constants';

import './SystemStatus.scss';

const { DeviceType } = Constant;

const SystemStatus = () => {
    const { t } = useTranslation();
    const { deviceUID } = useParams();
    const navigate = useNavigate();
    const { device, isDeviceLoading, deviceLoadingError } = useLoadDevice(deviceUID);

    const formattedDisplay = formatDisplayData(device);

    if (deviceLoadingError) {
        return <CenterErrorMessage>{t('system-status__message__loading-device-error')}</CenterErrorMessage>;
    }

    useEffect(() => {

        if (device && device.Device_DeviceType_ID !== DeviceType.LINKWEAR.typeId) {

            navigate(ADMIN_SYSTEM_NOT_FOUND_URL);
        }
    }, [device]);

    return (
        <>
            {isDeviceLoading ?
                <CommonLoader showLoader={true} message='common__loading' /> :
                <>
                    <Header device={device} />
                    <div className='hme-admin-system-status-linkwear-section-wrapper'>
                        <DeviceDetails formattedDisplay={formattedDisplay} device={device} />
                    </div>
                </>
            }
        </>
    );
};

export const AdminLinkwearSystemStatus = compose(
    withHMELayout({
        contentClasses: ['admin-system-status-linkwear-page'],
    }),
)(SystemStatus);
