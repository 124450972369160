import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { useLoadSnapshots } from 'hooks/useLoadSnapshots';
import { useLoadFilters } from '../hooks/useLoadFilters';
import { SnapshotsSection } from './SnapshotsSection';
import { Header } from './Header';

import './AddDevice.scss';

const SEARCH_DEFAULT = { selectValue: 'SettingName', searchValue: '' };
const MIN_SEARCH_VALUE = 3;
const SEARCH_DEBOUNCE_TIME_MS = 500;
const INIT_PAGE_NUMBER = 0;
const INIT_ITEMS_PER_PAGE = 10;
const INIT_SORT_SELECTION = { SettingName: 1 };

const handleSearch = ({
    searchData,
    getVisibleDevices,
    setPageNumber
}) => {
    const { selectValue: searchField, searchValue: searchText } = searchData;

    if (searchData.searchValue.length >= MIN_SEARCH_VALUE) {
        setPageNumber(INIT_PAGE_NUMBER);
        getVisibleDevices(searchText, searchField, INIT_PAGE_NUMBER);
    }
};

const handleSearchDebounce = debounce(handleSearch, SEARCH_DEBOUNCE_TIME_MS);

export const AddDevice = ({ deviceType, availableDevices, onSnapshotSelected, onDeviceTypeSelected }) => {
    const [searchValue, setSearchValue] = useState(SEARCH_DEFAULT);
    const [pageNumber, setPageNumber] = useState(INIT_PAGE_NUMBER);
    const [itemsPerPage, setItemsPerPage] = useState(INIT_ITEMS_PER_PAGE);
    const [sortSelection, setSortSelection] = useState(INIT_SORT_SELECTION);
    const [gridFiltersCount, setGridFiltersCount] = useState(0);

    const { snapshots, snapshotCount, isLoading, loadSnapshots } = useLoadSnapshots();
    const { availableFilters, setAvailableFilters, gridFilters, setGridFilters, loadFilters, isFiltersLoading } = useLoadFilters();

    const resetGridFilters = useCallback(() => {
        const resetFilters = {};
        for (const filter of availableFilters) {
            const filterValues = filter.items.map((item) => item.value);
            resetFilters[filter.property] = filterValues;
        }
        setGridFilters(resetFilters);
    }, [availableFilters]);

    const getVisibleDevices = useCallback((searchText, searchField, pageNum) => {
        if (Object.keys(gridFilters).length > 0) { // Prevent initial page load from calling API
            const queryFilters = {
                deviceType,
                pageNumber: pageNum,
                itemsPerPage,
                sortSelection,
                gridFilters,
                searchValue: searchText,
                searchField: searchField
            };

            loadSnapshots(queryFilters);
        }
    }, [deviceType, pageNumber, itemsPerPage, sortSelection, gridFilters]);

    const onFiltersReset = () => {
        resetGridFilters();
        setGridFiltersCount(0);
        // changing page number will trigger event that will reload page data
        setPageNumber(INIT_PAGE_NUMBER);
    };

    const onSearchChange = useCallback((searchData) => {
        const handleSearchInput = {
            searchData,
            getVisibleDevices,
            setPageNumber
        };
        setSearchValue(searchData);
        handleSearchDebounce.cancel();
        handleSearchDebounce(handleSearchInput);
    }, [getVisibleDevices, gridFiltersCount, handleSearchDebounce, searchValue]);

    const onSearchSubmit = useCallback(() => {
        const { selectValue: searchField, searchValue: searchText } = searchValue;
        getVisibleDevices(searchText, searchField, pageNumber);
    }, [searchValue, getVisibleDevices]);

    useEffect(() => {
        if (deviceType) {
            loadFilters(deviceType);
            setSearchValue(SEARCH_DEFAULT);
            setPageNumber(INIT_PAGE_NUMBER);
            setGridFiltersCount(0);
        }
    }, [deviceType]);

    useEffect(() => {
        const { selectValue: searchField, searchValue: searchText } = searchValue;
        if (pageNumber !== INIT_PAGE_NUMBER) { // Prevent pageNumber useEffect from running 2nd API Call
            setPageNumber(INIT_PAGE_NUMBER);
        }

        getVisibleDevices(searchText, searchField, INIT_PAGE_NUMBER);

        const filterCount = availableFilters.reduce((acc, filter) => {
            return filter.items.length === gridFilters[filter.property].length ? acc : acc + 1;
        }, 0);

        setGridFiltersCount(filterCount);
    }, [itemsPerPage, sortSelection, gridFilters]);

    useEffect(() => {
        const { selectValue: searchField, searchValue: searchText } = searchValue;
        getVisibleDevices(searchText, searchField, pageNumber);
    }, [pageNumber]);

    return (
        <div className="add-store-device">
            <Header
                deviceType={deviceType}
                availableDevices={availableDevices}
                onDeviceTypeSelected={onDeviceTypeSelected}
                searchValue={searchValue}
                onSearchChange={onSearchChange}
                onSearchSubmit={onSearchSubmit}
                gridFiltersCount={gridFiltersCount}
                onFiltersReset={onFiltersReset}
            />

            <SnapshotsSection
                deviceType={deviceType}
                disabled={!deviceType}
                snapshots={snapshots}
                isLoading={isLoading || isFiltersLoading}
                onSnapshotSelected={onSnapshotSelected}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                snapshotCount={snapshotCount}
                sortSelection={sortSelection}
                setSortSelection={setSortSelection}
                availableFilters={availableFilters}
                setAvailableFilters={setAvailableFilters}
                gridFilters={gridFilters}
                setGridFilters={setGridFilters}
            />
        </div>
    );
};

AddDevice.propTypes = {
    deviceType: PropTypes.string,
    existingDevices: PropTypes.arrayOf(PropTypes.object),
    onDeviceTypeSelected: PropTypes.func,
    onSnapshotSelected: PropTypes.func,
    storeDetails: PropTypes.object
};
