import React from 'react';
import { useTranslation } from 'react-i18next';

import './StatusCell.scss';

export const Created = () => {
    const { t } = useTranslation();

    return (<span className='hme-snapshot-settings-status-grey'>{t('common__created')}</span>);
};
