import { useEffect, useCallback } from "react";
import { getBrandsDeviceUpgradeVersions } from "services/Brands";
import { useAsync } from "hooks/useAsync";

export const useLoadBrandsDeviceUpgradeVersions = () => {
    const {
        run: runLoadBrandsDeviceUpgradeVersions,
        isLoading: isBrandsDeviceUpgradeVersionsLoading = false,
        data: brandsDeviceUpgradeVersions,
        error: brandsDeviceUpgradeVersionsLoadingError,
    } = useAsync();

    const loadBrandsDeviceUpgradeVersions = useCallback(() => {
        runLoadBrandsDeviceUpgradeVersions(getBrandsDeviceUpgradeVersions());
    }, []);

    useEffect(() => { loadBrandsDeviceUpgradeVersions(); }, []);

    return {
        brandsDeviceUpgradeVersions,
        isBrandsDeviceUpgradeVersionsLoading,
        brandsDeviceUpgradeVersionsLoadingError,
        loadBrandsDeviceUpgradeVersions,
    };
};
