import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'library/Button';

import './Footer.scss'

export const Footer = ({ onCancel, onApply, isApplyable }) => {
    const { t } = useTranslation();

    return (
        <div className='public-apply-settings-footer'>
            <Button onClick={onCancel}>{t('common__cancel')}</Button>
            <Button
                variants={['submit']}
                disabled={!isApplyable}
                onClick={onApply}
            >
                {t('common__apply-settings')}
            </Button>
        </div>
    );
};

