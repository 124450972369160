import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import 'url-search-params-polyfill';

import { LOCAL_STORAGE_KEYS } from 'constants/customerNotifications';
import { ADMIN_ROUTES, PARTNER_ROUTES, PUBLIC_ROUTES } from 'constants/routes';

import { Config } from '../../Config';
import Api from '../../Api';
import { CommonConstants } from '../../Constants';
import NavigationServices from '../Common/NavigationServices';
import { checkAccess } from '../../actions/permissions';
import { setInviteResponseFromCloud, inviteResponseFromCloud } from '../../actions/headers';
import { AdminHeader } from './AdminHeader';
import { ClientHeader } from './ClientHeader';
import { PartnerHeader } from './PartnerHeader';
import {
    getAdminProfile,
    getPermission,
    getProfile,
    getUUID,
    isAdmin,
    isLoggedIn,
    isMasquerade,
    isPartner,
    logOutAdminUser,
    revoke,
    setPermission
} from 'services/Auth';

import 'font-awesome/css/font-awesome.min.css';
import './Header.css';
import './Header-mobile.css';

const _ = require('underscore');

const { CUSTOMER_NOTIFICATION } = LOCAL_STORAGE_KEYS;

/**
 * Component design to handle complete Header like(logo, LoggedIn username, logout button, Main Menu and cog menu)
 */
class HmeHeader extends React.Component {
    /**
      * Set initial state
      * @param {*} props
    */
    constructor(props) {
        super(props);
        this.navigation = new NavigationServices();
        this.state = {
            CompanyUID: null,
            InviteDetails: {},
            valueOfUUIDOnLogin: ''
        };
        this.apiMediator = new Api();
    }

    /**
     * component lifecycle event to component mount Register mouse event
    */
    componentDidMount() {
        this.mainNavFullScreenDrop();
        const permissions = getPermission();
        const profile = getProfile();

        const isCurrentlyLoggedIn = isLoggedIn();

        if (Array.isArray(permissions) && permissions.length > 0) {
            if (profile.IsAccountOwner === 1 ||
            (profile.SL === 2 || profile.SL === 6) &&
            checkAccess(CommonConstants.userPermissions.EditLeaderboard)) {
                if (!profile.Company_UID) {
                    const companyUrl = Config.apiBaseUrl + CommonConstants.apiUrls.getCompany;
                    this.apiMediator.getData(companyUrl, (data) => {
                        this.setState({ CompanyUID: data.companyUId });
                    });
                }
            }
        } else {
            if (isCurrentlyLoggedIn) {
                this.setState({ valueOfUUIDOnLogin: getUUID() });
                this.apiGetPermissions();
            }
        }

        if (isCurrentlyLoggedIn) {
            this.setUserContext(); // to-do: remove this once its set @ cfm-app
        }
    }

    /**
     * Get the available space on mobile to render the nav menu properly
    */
    mainNavFullScreenDrop() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    getPaginationStorageKey() {
        const userDetails = getProfile();
        const userUID = isAdmin() ? userDetails.puid : userDetails.User_UID;
        return `PAGINATION_${userUID}`;
    }

    /**
     * Get permissions based on subscription level.
    */
    apiGetPermissions() {
        if (!isAdmin()) {
            let url = Config.apiBaseUrl + CommonConstants.apiUrls.getPermissions;
            const uuid = getUUID();
            if (uuid) {
                url += `?uuid=${  uuid}`;
            }
            this.apiMediator.getData(url, (data) => {
                const newPermissions = _.pluck(data.data[0], 'Permission_Name');
                setPermission(newPermissions);
                const { IsAccountOwner, SL: subscriptionLevel } = getProfile();
                if (
                    IsAccountOwner === 1 ||
                    (subscriptionLevel === 2 || subscriptionLevel === 6) &&
                    _.contains(newPermissions, CommonConstants.userPermissions.EditLeaderboard)
                ) {
                    const companyUrl = Config.apiBaseUrl + CommonConstants.apiUrls.getCompany;
                    if (!this.state.CompanyUID) {
                        this.apiMediator.getData(companyUrl, (companyData) => {
                            this.setState({ CompanyUID: companyData.companyUId });
                        });
                    }
                }
            });
        }
    }

    /**
     * setting user context from authenticated token
    */
    setUserContext() {
        // handling masquered condition to show logged in username
        const isCurrentlyMasquerade = isMasquerade();
        const user = isCurrentlyMasquerade ? getAdminProfile() : getProfile();
        let userName = user.name ? user.name : `${user.User_FirstName} ${user.User_LastName}`;

        if (!isCurrentlyMasquerade) {
            const url = Config.apiBaseUrl +
                (isPartner() ?
                CommonConstants.apiUrls.getPartnerUserDetails :
                CommonConstants.apiUrls.getAccountInfo);

            this.apiMediator.getData(url, (data) => {
                if (data && data.firstName && data.lastName) {
                    userName = `${data.firstName} ${data.lastName}`;
                    this.setState({ userName });
                    if (data.SSOAccountID) {
                        localStorage.setItem('isAccountSSO', true);
                    }
                }
            });
        } else {
            console.log('User is masquerade');
        }

        this.setState({ userName });
    }

    /**
   * Display header.
   * @return {JSX}
   */
    render() {
        const { userName } = this.state;
        this.navigation.setSelectedMenu();

        return isAdmin() ? (
            <AdminHeader userName={userName} onLogout={() => this.logout() } />
        ) :
        isPartner() ?
            <PartnerHeader
                userName={userName}
                onLogout={() => this.logout()}
            />
            : (
        <ClientHeader
            userName={userName}
            onLogout={() => this.logout()}
        />
        );
    }

    /**
   * Clear local storage and redirect user to login page.
   */
    logout() {
        const isCurrentAdmin = isAdmin();
        const isCurrentPartner = isPartner();
        const paginationStorageKey = this.getPaginationStorageKey();
        let url = isCurrentAdmin ? ADMIN_ROUTES.logout : isCurrentPartner ? PARTNER_ROUTES.logout : PUBLIC_ROUTES.logout;
        revoke();
        if (isCurrentAdmin) {
            logOutAdminUser();
        }
        // ensure the session is terminated for both the http and https version of the site.
        if (window.location.protocol === 'http:') {
            // Switch to the https to ensure the https is logged out
            url = window.origin + url;
            url = url.replace('http:', 'https:');
        }
        // clear the local storage for the http version
        const currentLanguage = window.localStorage.getItem('userLanguage');
        const currentPaginationData = window.localStorage.getItem(paginationStorageKey);
        window.localStorage.clear();
        window.sessionStorage.clear();

        window.localStorage.setItem('userLanguage', currentLanguage);
        window.localStorage.setItem(paginationStorageKey, currentPaginationData);

        // If display maintenance banner is activated and the user clear the notification, it will pop up again on login
        window.localStorage.removeItem(CUSTOMER_NOTIFICATION);
        if (!isCurrentAdmin) {
            window.location.href = url;
        }
    }
}

/**
 * Map redux state to props
 * @param {*} state
 * @return {object} new fields in state
 */
function mapStateToProps(state) {
    return {
        roles: state.roles
    };
}

/**
 * Wrap redux actions in dispatch
 * @param {*} dispatch
 * @return {*} actions with wrapped dispatcher
 */
function matchDispatchToProps(dispatch) {
    return bindActionCreators(
            {
                setInviteResponseFromCloud,
                inviteResponseFromCloud
            },
            dispatch
    );
}

export default compose(
        connect(
                mapStateToProps,
                matchDispatchToProps
        ),
        withTranslation()
)(HmeHeader);
