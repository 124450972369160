import React from 'react';

const offlineImage = require('../../images/connection_offline.png')
const onlineImage = require('../../images/connection_online.png')

/**
 * Generic component to draw device status image (red | green circle).
 * No need to import those images manually everywhere
 * @param {{isActive: boolean, secondaryClassName?: string}} props
 */
const DeviceStatusImage = (props) => {
  const {isActive, secondaryClassName = ''} = props;

  const imageParams = {
    src: isActive ? onlineImage : offlineImage,
    alt: isActive ? 'Device Online' : 'Device Offine'
  }

  const classNames = `cstat ${secondaryClassName}`

  return <img className={classNames} {...imageParams} />;
}

export default DeviceStatusImage;
