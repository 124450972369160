import React, { useCallback, useState } from 'react';

import { getTokenExpirationType } from 'constants/token';
import { ExpiryDateCell } from '../../ExpiredDateCell';
import { APISCell } from '../../ApisCell';
import { Actions } from '../Actions';
import { DateLib } from '@hme-cloud/utility-common';
import { FORMAT_TYPES } from 'helpers/DateLib/constants';

const getFormattedDate = (date, dateFormat = FORMAT_TYPES.DEFAULT) => date ? new DateLib(date).format(dateFormat) : '';

const expirationTypeRowClassNames = {
    'expired': 'hme-token-management__row--expired',
    'within-month-before-expiration': 'hme-token-management__row--within-month-expired'
};

export const useTokensGrid = ({ tokens, onRemoveToken }) => {
    const [isRemoveConfirmationShown, setIsRemoveConfirmationShown] = useState(false);
    const [isLoadingModalShown, setIsLoadingModalShown] = useState(false);
    const [tokenToRemove, setTokenToRemove] = useState();

    const onRemoveCancel = useCallback(() => {
        setIsRemoveConfirmationShown(false);
    }, []);

    const handleRemoveClick = useCallback((tokenId) => {
        setIsRemoveConfirmationShown(true);
        setTokenToRemove(tokenId);
    }, []);

    const handleConfirmRemoveClick = useCallback(async () => {
        onRemoveCancel();
        setIsLoadingModalShown(true);
        await onRemoveToken(tokenToRemove);
        setIsLoadingModalShown(false);
    }, [tokenToRemove, onRemoveCancel, onRemoveToken]);

    const rows = tokens.map((token) => {
        const expirationType = getTokenExpirationType(token.expiryDate);
        const formattedExpiryDate = getFormattedDate(token.expiryDate, FORMAT_TYPES.DATE_SELECTOR_DEFAULT);

        return {
            ...token,
            originalExpiryDate: token.expiryDate,
            expiryDate: <ExpiryDateCell expirationType={expirationType} expiryDate={formattedExpiryDate} />,
            createdAt: getFormattedDate(token.createdAt, FORMAT_TYPES.DATE_FIRST_DATE_TIME),
            updatedAt: getFormattedDate(token.updatedAt, FORMAT_TYPES.DATE_FIRST_DATE_TIME),
            apis: <APISCell apis={token.apiName} />,
            Actions: <Actions tokenId={token.id} tokenUID={token.uid} onRemove={handleRemoveClick} />,
            rowClassName: expirationTypeRowClassNames[expirationType]
        };
    });

    return {
        rows,

        isRemoveConfirmationShown,
        onRemoveCancel,
        handleConfirmRemoveClick,
        isLoadingModalShown
    };
};
