export const BRAND_ACCOUNT_SEARCH_PROPERTIES = [
    'User_EmailAddress',
    'Company_Type',
    'Brand_Name',
    'Device_SerialNumber',
    'Store_Number',
    'Store_Name',
    'Device_MainVersion',
    'Device_LaneConfig_Name',
];
