import { DateLib } from '@hme-cloud/utility-common';

export const getMonthKey = (date) => date.format(DateLib.FORMAT_TYPES.MONTH_YEAR);

export const getDayKey = (date) => date.format(DateLib.FORMAT_TYPES.CALENDAR_DAY);

export const normalizeDateRange = (range) => range && (range.startDate.isBefore(range.endDate) ? range : {
    startDate: range.endDate,
    endDate: range.startDate,
});
