import React, { useCallback } from 'react';

import { Button } from 'library/Button';

export const Cancel = ({ task, onCancel }) => {
    const handler = useCallback(() => onCancel(task), [task, onCancel]);

    return (
        <Button variants={['transparent']} onClick={handler}>Cancel</Button>
    );
};
