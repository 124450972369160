import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'library/Button';

export const Delete = ({ snapshot, onDelete }) => {
    const { t } = useTranslation();
    const handler = useCallback(() => onDelete(snapshot), [snapshot, onDelete]);

    return (
        <Button variants={['transparent']} onClick={handler}>
            {t('common__delete')}
        </Button>
    );
};
