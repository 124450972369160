import React, { createContext, useContext } from 'react';

const CustomerNotificationContext = createContext();
CustomerNotificationContext.displayName = 'CustomerNotificationContext';

import { useCustomerNotificationLoader } from '../hooks';

export const CustomerNotificationProvider = ({ onStartLoading, onEndLoading, ...props }) => {
    const { isLoading, isRejected, enabledNotifications, pastNotifications } = useCustomerNotificationLoader({
        onStartLoading,
        onEndLoading
    });

    return (
        <CustomerNotificationContext.Provider
            {...props}
            value={{ isLoading, isRejected, enabledNotifications, pastNotifications }}
        />
    );
};

export const useCustomerNotificationContext = () => useContext(CustomerNotificationContext);
export const CustomerNotificationConsumer = CustomerNotificationContext.Consumer;
