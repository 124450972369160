import { Rest } from 'helpers/Rest';
import { CommonConstants } from 'Constants';
import { LEADERBOARD_INVITE_STATUS } from 'constants/participant';

const errorTKeys = {
    'Invitation denied': 'notifications-center__error--declined'
};

class ParticipantRest extends Rest {
    decline(participantId) {
        return this.command('inviteResponseFromCloud', {
            ParticipantID: participantId,
            Status: LEADERBOARD_INVITE_STATUS.DENIED,
        });
    }

    accept(participantId) {
        return this.command('inviteResponseFromCloud', {
            ParticipantID: participantId,
            Status: LEADERBOARD_INVITE_STATUS.ACCEPTED,
        });
    }
};

export const ParticipantService = new ParticipantRest(CommonConstants.apiUrls.lbGroupBase, { errorTKeys });
