import { useEffect } from 'react';

import { useAsync } from 'hooks/useAsync';

import { getTokenManagementList } from '../Controller';

export const useTokensLoader = () => {
    const { run, status, data: fetchedTokens, isLoading } = useAsync();

    useEffect(() => {
        run(getTokenManagementList());
    }, []);

    return {
        status,
        fetchedTokens,
        isLoading
    };
};
