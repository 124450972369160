import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoField } from 'library/InfoField';

export const InfoSection = ({ feed }) => {
    const { t } = useTranslation();

    return (
        <div className="dxs-feed-form-section">
            <div className="hme-feed-form-section">
                <InfoField label={t('common__service-account-name')}>{feed.serviceAccountName}</InfoField>
            </div>
            <div className="hme-feed-form-section">
                <InfoField label={t('common__auth-token')}>{feed.serviceAccountToken}</InfoField>
            </div>
            <div className="hme-feed-form-section">
                <InfoField label={t('common__brand')}>{feed.serviceBrandName}</InfoField>
            </div>
            <div className="hme-feed-form-section">
                <InfoField label={t('dxs-feeds__data-delay-time')}>{feed.minimumRetrievalTime} {t('common__minute-s')}</InfoField>
            </div>
        </div>
    );
};
