import React from 'react';
import { compose } from 'ramda';
import { withHMELayout } from 'HOCs/withHMELayout';
import { withAccess } from 'HOCs/withAccess';
import { accountTypes } from 'constants/accountTypes';
import { isAdmin } from 'services/Auth';
import { checkAccess } from 'actions/permissions';
import authenticate from 'components/Security/Authentication';
import { CommonConstants } from 'Constants';
import { VaioProviders } from '../VaioProviders';


const hasAccess = isAdmin() && checkAccess(CommonConstants.adminPermissions.CreateVaioProvider);

const AdminVaioProvidersPage = () => (
    <VaioProviders permissionType={accountTypes.ADMIN} />
);

export const AdminVaioProviders = compose(
        withHMELayout(),
        authenticate,
        withAccess({
            checkAccess: () => hasAccess,
        }),
)(AdminVaioProvidersPage);