import { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Expand } from 'library/Icons/Expand';
import { Separator } from 'library/Separator';
import { Button, BUTTON_VARIANTS } from 'library/Button';
import { DateLib } from '@hme-cloud/utility-common';
import { FlexBreaker } from 'library/FlexBreaker';
import { useGlobalState } from 'globalState';

import { useNotificationsCenterContext } from '../../../Context';

import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';

import './BaseNotification.scss';

const removeButtonVariants = [BUTTON_VARIANTS.TRANSPARENT];

export const BaseNotification = ({
    Icon = MailIcon,
    className,
    title,
    sentDate,
    isRead,
    id,
    body,
    expandedContent,
    isRemovable = true,
    onRemove,
    isReadable = true,
}) => {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const sentDateString = useMemo(() => new DateLib(sentDate).format(DateLib.FORMAT_TYPES.DATE_AND_TIME), [sentDate]);
    const { screenType } = useGlobalState();

    const ref = useRef(null);

    const {
        removeNotification: removeNotificationContext,
        readNotification: readNotificationContext,
        active,
    } = useNotificationsCenterContext();

    const isActive = active.activeId === id;

    const onRead = useCallback(() => {
        readNotificationContext.onRead(id);
    }, [id, readNotificationContext.onRead]);

    const onExpandChange = useCallback(() => {
        if (isReadable && !isRead && !readNotificationContext.isReading) {
            onRead();
        }
        setIsExpanded((oldValue) => !oldValue);
    }, [onRead, isReadable, isRead, readNotificationContext.isReading]);

    const onRemoveClick = useCallback(() => {
        onRemove ? onRemove(notification.id) : removeNotificationContext.onRemoveClick(id);
    }, [onRemove, removeNotificationContext.onRemoveClick, id]);

    useEffect(() => {
        if (!isActive) {
            return;
        }

        onExpandChange();
        requestAnimationFrame(() => {
            ref.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'end'
            });
        });
        active.removeActiveFromQuery();
    }, [ref, isActive, active.removeActiveFromQuery, onExpandChange]);

    return (<div className={classNames('hme-notifications-center__notification', className, {
        'hme-notifications-center__notification--read': !!isRead
    })} ref={ref}>
        <div className='hme-notifications-center__notification__title'>
            {Icon && <Icon className='hme-notifications-center__notification__icon' />}
            <span className='hme-notifications-center__notification__name'>
                {typeof title === 'string' ? t(title) : title}
            </span>
            {
                isRemovable && !screenType.isMobile &&
                <>
                    <Button
                        className='hme-notifications-center__notification__remove'
                        variants={removeButtonVariants}
                        onClick={onRemoveClick}
                    >{t('common__remove')}</Button>
                    <Separator className='hme-notifications-center__notification__separator--remove' />
                </>

            }
            { !screenType.isMobile && <span className='hme-notifications-center__notification__sent-date'>{sentDateString}</span> }
            { screenType.isMobile && expandedContent && <Expand expanded={isExpanded} onExpandChange={onExpandChange}/> }
        </div>
        <div className='hme-notifications-center__notification__content'>
            <div className='hme-notifications-center__notification__body'>
                <div>
                    {body}
                </div>
                {
                    !screenType.isMobile && expandedContent && <Expand expanded={isExpanded} onExpandChange={onExpandChange}/>
                }
                <FlexBreaker />
                {
                    isExpanded &&
                    <div className='hme-notifications-center__notification__expanded-content'>
                        {expandedContent}
                    </div>
                }
            </div>
        </div>
        {
            screenType.isMobile &&
            <div className='hme-notifications-center__notification__footer'>
                <span className='hme-notifications-center__notification__sent-date'>{sentDateString}</span>
                { isRemovable && <Button
                    className='hme-notifications-center__notification__remove'
                    variants={removeButtonVariants}
                    onClick={onRemoveClick}
                >{t('common__remove')}</Button> }
            </div>
        }
    </div>);
};
