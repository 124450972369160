import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Grid } from 'components/Common/Grid';

import { ACCOUNTS_HEADERS } from '../constants/accountsHeaders';

import './List.scss';

export const List = ({
    rows,
    sortSelection,
    onSortChange,
    isLoading,
    availableFilters,
    filters,
    onFiltersChange
}) => {
    const { t } = useTranslation();

    return (
        <div className="hme-distributors-accounts__list__wrapper">
            <Grid
                headers={ACCOUNTS_HEADERS}
                rows={rows}
                isLoading={isLoading}
                loadingText={t('common__loading')}
                rowKey="User_ID"
                noRecordsMessage={t('accounts__no-accounts')}
                onSortChange={onSortChange}
                sortSelection={sortSelection}
                availableFilters={availableFilters}
                filters={filters}
                onFiltersChange={onFiltersChange}
            />
        </div>
    );
};

List.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSortChange: PropTypes.func.isRequired,
    sortSelection: PropTypes.shape({}).isRequired,
    isLoading: PropTypes.bool.isRequired,
    availableFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    filters: PropTypes.shape({}).isRequired,
    onFiltersChange: PropTypes.func.isRequired
};
