import i18next from 'i18next';

import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { INTERNAL_SERVER_ERROR_KEY, commonErrorTranslations } from 'constants/errors';
import { downloadBlobRequest, getDataAsync, getSearchString, postDataAsync, putDataAsync } from 'Api';
import { CONTEST_TYPE_VALUES } from 'pages/LeaderboardContests/Common/Constants';
import AuthenticationService from 'components/Security/AuthenticationService';
import { DateLib } from '@hme-cloud/utility-common';

const { apiUrls } = CommonConstants;

const CONTESTS_URL = `${Config.apiBaseUrl}${apiUrls.leaderboardContests}`;

export const fetchContests = async ({ companyUID, status, sort, order }) => {
    try {
        const queryParams = decodeURIComponent(
            getSearchString({
                companyUID,
                status,
                sort,
                order
            })
        );

        const response = await getDataAsync(`${CONTESTS_URL}?${queryParams}`);

        if (!response.status) {
            throw new Error(response.key);
        }

        return response.data;
    } catch (err) {
        throw new Error(
            err.message in commonErrorTranslations ? commonErrorTranslations[err.message] : INTERNAL_SERVER_ERROR_KEY
        );
    }
};

export const cancelContest = async (uid) => {
    try {
        const response = await putDataAsync(
            `${CONTESTS_URL}/${uid}`,
            {
                ContestStatus: CONTEST_TYPE_VALUES.CANCELED
            }
        );

        if (!response.status) {
            throw new Error(response.key);
        }

        return response.data;
    } catch (err) {
        throw new Error(
            err.message in commonErrorTranslations ? commonErrorTranslations[err.message] : INTERNAL_SERVER_ERROR_KEY
        );
    }
};

export const fetchGroupedStores = async () => {
    try {
        const response = await getDataAsync(`${Config.apiBaseUrl}${apiUrls.getGroupedStores}`);

        if (!response.status) {
            throw new Error(response.key);
        }

        return response.data;
    } catch (err) {
        throw new Error(
            err.message in commonErrorTranslations ? commonErrorTranslations[err.message] : INTERNAL_SERVER_ERROR_KEY
        );
    }
};

export const createContest = async (values = {}) => {
    const response = await postDataAsync(`${Config.apiBaseUrl}${apiUrls.createLeaderboardContests}`, values);

    if (!response.status) {
        throw new Error(response.key);
    }

    return response;
};

export const fetchContestResult = async (uid) => {
    try {
        const response = await getDataAsync(`${Config.apiBaseUrl}${apiUrls.getContestResult(uid)}`);

        if (!response.status) {
            throw new Error(response.key);
        }

        return response.data;
    } catch (err) {
        throw new Error(
            err.message in commonErrorTranslations ? commonErrorTranslations[err.message] : INTERNAL_SERVER_ERROR_KEY
        );
    }
};

export const fetchContestSessionResult = async (uid) => {
    try {
        const response = await getDataAsync(`${Config.apiBaseUrl}${apiUrls.getContestSessionResult(uid)}`);

        if (!response.status) {
            throw new Error(response.key);
        }

        return response.data;
    } catch (err) {
        throw new Error(
            err.message in commonErrorTranslations ? commonErrorTranslations[err.message] : INTERNAL_SERVER_ERROR_KEY
        );
    }
};

export const generateContestPDF = async ({ contestUID, storeUIDs, contestName }) => {
    try {
        const authService = new AuthenticationService(Config.authBaseUrl);

        await downloadBlobRequest(
            `${Config.apiBaseUrl}${apiUrls.generatePDF}`,
            {
                data: {
                    contestUID,
                    storeUIDs
                },
                template: 'contest-certificate'
            },
            `${contestName}.pdf`,
            {
                'Content-Language': i18next.resolvedLanguage,
                'Authorization': `Bearer ${authService.getToken()}`
            }
        );
    } catch (err) {
        throw new Error(
            err.message in commonErrorTranslations ? commonErrorTranslations[err.message] : INTERNAL_SERVER_ERROR_KEY
        );
    }
};

export const fetchContest = async (uid) => {
    try {
        const response = await getDataAsync(`${Config.apiBaseUrl}${apiUrls.getContest(uid)}`);

        if (!response.status || !response.data) {
            throw new Error(response.key);
        }

        return response.data;
    } catch (err) {
        throw new Error(
            err.message in commonErrorTranslations ? commonErrorTranslations[err.message] : INTERNAL_SERVER_ERROR_KEY
        );
    }
};

export const downloadContestResult = async ({ contestUID, fileName, format }) => {
    try {
        const downloadEndpoint = format==='PDF'? apiUrls.generatePDF : apiUrls.generateXLS;
        await downloadBlobRequest(
            `${Config.apiBaseUrl}${downloadEndpoint}`,
            {
                data: {
                    ContestUID: contestUID,
                    utcOffset: new DateLib().getDate().getTimezoneOffset() * -1
                },
                template: 'contest-results'
            },
            fileName
        );
    } catch (err) {
        throw new Error(
            err.message in commonErrorTranslations ? commonErrorTranslations[err.message] : INTERNAL_SERVER_ERROR_KEY
        );
    }
};

export const updateContest = async (uid, data) => {
    try {
        const response = await putDataAsync(`${Config.apiBaseUrl}${apiUrls.getContest(uid)}`, data);

        if (!response.status) {
            throw new Error(response.key);
        }

        return response.data;
    } catch (err) {
        throw new Error(
            err.message in commonErrorTranslations ? commonErrorTranslations[err.message] : INTERNAL_SERVER_ERROR_KEY
        );
    }
};

export const checkIsStoresAvailable = async ({
    ContestStores,
    StartDate,
    EndDate,
    ZoneName,
    ContestScheduleDayTimePeriods,
    ContestScheduleDays,
    ContestUID = ''
}, requestOptions) => {
    try {
        const response = await postDataAsync(`${Config.apiBaseUrl}${apiUrls.isStoresAvailableLeaderboardContests}`, {
            ContestStores,
            StartDate,
            EndDate,
            ZoneName,
            ContestScheduleDayTimePeriods,
            ContestScheduleDays,
            ContestUID
        }, {}, requestOptions);

        if (!response.status) {
            throw new Error(response.key);
        }

        return response.data;
    } catch (err) {
        throw new Error(
            err.message in commonErrorTranslations ? commonErrorTranslations[err.message] : INTERNAL_SERVER_ERROR_KEY
        );
    }
};
