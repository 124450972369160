import React from 'react';
import { StoresSection } from './StoresSection';
import { MessageSection } from './MessageSection';

export const SendMessage = ({
    storesSearch,
    groupedStores,
    selectedStores,
    message,
    validationErrors,
    isOnlySelectedStoresShown,
    toggleSelectedStoresOnly,
    onGroupStoreChange,
    onMessageChange,
    onInfoClick,
    onSearchChange,
}) => {
    return (
        <div className="text-connect__sections">
            <StoresSection
                storesSearch={storesSearch}
                groupedStores={groupedStores}
                isOnlySelectedStoresShown={isOnlySelectedStoresShown}
                toggleSelectedStoresOnly={toggleSelectedStoresOnly}
                selectedStores={selectedStores}
                onGroupStoreChange={onGroupStoreChange}
                onInfoClick={onInfoClick}
                onSearchChange={onSearchChange}
            />
            <MessageSection
                message={message}
                validationErrors={validationErrors}
                onMessageChange={onMessageChange}
                disabled={!selectedStores.length}
            />
        </div>
    );
};
