import { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { trimValues } from 'helpers';
import { getQueryString } from 'helpers/distributor';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { getDistributorRole, updateDistributorRole, removeDistributorRole } from 'services/Roles';
import { getPermissionsList } from 'services/Permissions';
import { getPermissionItems } from '../../Common/helpers/getPermissionItems';
import { isRoleValid, areRolesEqual, trimmedKeys } from '../../Common/validators/role';
import { accountOwner } from '../../../Status/Common/constants/accountOwner';

const load = async (accountUuid, roleUid, isOwner, setOriginRole, setPermissions, setIsLoading) => {
    setIsLoading(true);

    try {
        const rolePromise = isOwner ?
            Promise.resolve({
                roles: {
                    ...accountOwner,
                    Role_Description: '',
                }
            }) : getDistributorRole({ accountUuid, roleUid });
        const permissionsPromise = getPermissionsList({ accountUuid });
        const [role, permissions] = await Promise.all([rolePromise, permissionsPromise]);

        setOriginRole({
            uid: isOwner ? null : roleUid,
            name: role.roles.Role_Name,
            description: role.roles.Role_Description,
            permissions: isOwner ?
                permissions.map(({ Permission_UID }) => Permission_UID) :
                role.Permission.map(({ Permission_UID }) => Permission_UID),
        });

        setPermissions(getPermissionItems(permissions));
    } catch (err) {
        addErrorNotification(err.message);
    }

    setIsLoading(false);
};

const save = async (accountUuid, role, queryParams, navigate, setIsLoading) => {
    setIsLoading(true);

    try {
        await updateDistributorRole({
            accountUuid,
            role: trimValues(role, trimmedKeys),
        });

        const search = getQueryString(queryParams);

        navigate(`/accounts/roles${search}`);
        setTimeout(() => {
            addSuccessNotification('add-role__success--updated');
        }, 1000);
    } catch(err) {
        addErrorNotification(err.message);
        setIsLoading(false);
    }
};

const remove = async (accountUuid, role, queryParams, navigate, t, setIsLoading) => {
    setIsLoading(true);

    try {
        await removeDistributorRole({
            accountUuid,
            role,
        });

        const search = getQueryString(queryParams);

        navigate(`/accounts/roles${search}`);
        setTimeout(() => {
            addSuccessNotification('add-role__success--deleted');
        }, 1000);
    } catch(err) {
        addErrorNotification(t(err.message, err.tOptions));
        setIsLoading(false);
    }
};

export const useDistributorEdit = () => {
    const { t } = useTranslation();
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [originRole, setOriginRole] = useState(null);
    const [role, setRole] = useState({
        name: '',
        description: '',
        permissions: [],
    });
    const auuid = useMemo(() => queryParams.get('auuid'), [queryParams]);
    const roleUid = useMemo(() => queryParams.get('roleuid'), [queryParams]);
    const isOwner = useMemo(() => queryParams.get('isAccountOwner'), [queryParams]);

    const title = useMemo(() => {
        if (!originRole) {
            return '';
        }

        const tKey = isOwner ? 'add-role__header--edit__title--owner' : 'add-role__header--edit__title';

        return t(tKey, {
            roleName: originRole.name,
        });
    }, [originRole, t, isOwner]);

    const isSaveButtonDisabled = useMemo(() => !isRoleValid(role) || areRolesEqual(
        trimValues(role, trimmedKeys),
        trimValues(originRole, trimmedKeys)
    ), [role, originRole]);

    const onCancel = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const onSave = useCallback(() => {
        save(auuid, role, queryParams, navigate, setIsLoading);
    }, [auuid, role, queryParams, navigate, setIsLoading]);

    const onRemove = useCallback(() => {
        remove(auuid, {
            ...role,
            Role_Name: originRole.name,
        }, queryParams, navigate, t, setIsLoading);
    }, [auuid, role, originRole, queryParams, t, navigate, setIsLoading]);

    useEffect(() => {
        load(auuid, roleUid, isOwner, setOriginRole, setPermissions, setIsLoading);
    }, [auuid, roleUid, setOriginRole, setIsLoading]);

    useEffect(() => {
        if (!originRole) {
            return;
        }

        setRole({
            ...originRole,
        });
    }, [originRole, setRole]);

    useEffect(() => {
        if (!isOwner) {
            return;
        }

        setRole({
            ...originRole,
            description: t('roles__grid__account-owner-desription'),
        });
    } ,[t, isOwner, originRole, setRole]);

    return {
        title,
        role,
        isLoading,
        isSaveButtonDisabled,
        permissions,
        isOwner,
        hasRemove: true,
        onCancel,
        onRemove,
        onSave,
        onRoleChange: setRole,
    };
};
