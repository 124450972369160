import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { taskConfig } from 'constants/applyStoreSettingsTask';
import { PAGE_DATA, PAGE_DEFAULT } from 'constants/paginationDefault';
import {
    Grid,
    useAvailableFilters,
    applyGridFilters,
    resetFilters,
    getFiltersCount
} from 'components/Common/Grid';
import { Paginate } from 'library/Paginate';
import { useOnTrigger } from 'hooks/useTrigger';
import { DateLib } from '@hme-cloud/utility-common';

import { StatusCell } from './StatusCell';
import { Actions } from './Actions';

import './TasksList.scss';

const { statuses } = taskConfig;

const ALL_CREATORS_FILTER_TEXT = 'All Users';
const ALL_STATUSES_FILTER_TEXT = 'All Statuses';

const gridOptions = [{
    text: 'Name',
    property: 'Task_Name',
}, {
    text: 'Devices Downloaded',
    property: 'Downloaded_Count',
}, {
    text: 'Devices Uploaded',
    property: 'uploadedCount',
}, {
    text: 'Created Date & Time',
    property: 'createdAtTime',
}, {
    text: 'Created By',
    property: 'CreatedBy_Email',
    flex: 1.5,
}, {
    text: 'Task Status',
    property: 'taskStatus',
}, {
    text: 'Completed Date & Time',
    property: 'completedAtTime',
}, {
    text: 'Actions',
    property: 'actions',
    className: 'hme-grid-centered-cell',
    headerClassName: 'hme-grid-centered-cell',
}];

const statusTexts = {
    WAITING_FOT_UPLOAD: 'Waiting for Upload',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    FAILED: 'Completed with Failures',
    CANCELED: 'Canceled',
};

const filterOptions = {
    CreatedBy_Email: {
        allText: ALL_CREATORS_FILTER_TEXT,
    },
    taskStatus: {
        allText: ALL_STATUSES_FILTER_TEXT,
        items: Object.keys(statuses).map(statusCode => ({
            text: statusTexts[statusCode],
            value: statuses[statusCode],
        })),
        valuePropName: 'Task_Status',
    },
};

const showUploadStatuses = [statuses.IN_PROGRESS, statuses.COMPLETED, statuses.FAILED];

const getUploadedCount = ({ Task_Status, Uploaded_Count }) => showUploadStatuses.includes(Task_Status) ?
    Uploaded_Count :
    null;

const tasksToRows = (tasks, onUpload, onCancel) => tasks.map(task => ({
    ...task,
    taskStatus: <StatusCell status={task.Task_Status} />,
    uploadedCount: getUploadedCount(task),
    actions: <Actions status={task.Task_Status} task={task} onUpload={onUpload} onCancel={onCancel} />,
    createdAtTime: new DateLib(task.CreatedAt).format(DateLib.FORMAT_TYPES.FULL_YEAR_DATE_AND_TIME),
    completedAtTime: task.CompletedAt && new DateLib(task.CompletedAt).format(DateLib.FORMAT_TYPES.FULL_YEAR_DATE_AND_TIME),
}));

export const TasksList = ({
    tasks,
    isLoading,
    onUpload,
    onCancel,
    resetFiltersTrigger,
    onFiltersCountChange,
}) => {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [availableFilters, setAvailableFilters] = useState([]);
    const [gridFilters, setGridFilters] = useState({});

    const [pageNumber, setPageNumber] = useState(PAGE_DEFAULT.page);
    const [itemsPerPage, setItemsPerPage] = useState(PAGE_DEFAULT.recordsPerPage);

    const paginatedPages = useMemo(()=> filteredRows
        .slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage), [filteredRows, pageNumber, itemsPerPage])

    const onPaginationChange = useCallback(({ page, recordsPerPage }) => {
        setPageNumber(page);
        setItemsPerPage(recordsPerPage);
    }, []);

    const onFiltersReset = useCallback(() => {
        resetFilters(availableFilters, setGridFilters);
    }, [availableFilters, setGridFilters]);

    useOnTrigger(resetFiltersTrigger, onFiltersReset);

    useEffect(() => {
        onFiltersCountChange && onFiltersCountChange(getFiltersCount(availableFilters, gridFilters));
    }, [availableFilters, gridFilters]);

    useEffect(() => {
        setRows(tasksToRows(tasks, onUpload, onCancel));
    }, [tasks, onUpload, onCancel, setRows]);

    useEffect(() => {
        const newFilteredDevices = applyGridFilters(filterOptions, rows, gridFilters);

        setFilteredRows(newFilteredDevices);
    }, [rows, gridFilters, setFilteredRows]);

    useAvailableFilters(filterOptions, rows, setGridFilters, setAvailableFilters);

    return (
        <div className='apply-store-settings-status-tasks'>
            <Grid
                headers={gridOptions}
                rows={paginatedPages}
                isLoading={isLoading}
                rowKey='Task_ID'
                noRecordsMessage="You don't have any tasks now"
                availableFilters={availableFilters}
                filters={gridFilters}
                onFiltersChange={setGridFilters}
            />
            <Paginate
                page={pageNumber}
                recordsPerPage={itemsPerPage}
                pageSizes={PAGE_DATA.PAGE_SIZES_ADMIN}
                total={filteredRows.length}
                onChange={onPaginationChange}
                hideSinglePage
            />
        </div>
    );
};
