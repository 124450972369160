import { fetchPartnerUsersByEmail } from 'services/TokenManagement';

export const getPartnerEmails = async ({ emailSearchQuery, limit = 5, apiType = null }, requestOptions = {}) => {
    const { data, status } = await fetchPartnerUsersByEmail(
            {
                emailSearchQuery,
                limit,
                apiType
            }, requestOptions);

    if (!status) {
        throw new Error('common__error--unknown');
    }

    return data.suggestedPartners;
};
