import { useCallback, useMemo } from 'react';
import { equals } from 'ramda';

export const useSortingItem = (sortSelection, onSortChange, value) => {
    const checked = useMemo(() => equals(sortSelection, value), [sortSelection, value]);
    const onChange = useCallback(() => onSortChange(value), [onSortChange, value]);

    return {
        checked,
        onChange
    };
};
