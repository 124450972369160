/* eslint-disable complexity */
/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, {Component, useCallback, useMemo} from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConfirmPopupComponent } from '../../library/ConfirmPopup/ConfirmPopup.js';

import { ToastContainer, toast, Slide } from 'react-toastify';
import { Config } from '../../Config';
import Api from '../../Api';
import { getTemplateList, sortLeaderBoard } from '../../actions/leaderBoardGroups';
import AuthenticationService from '../Security/AuthenticationService';
import { get, save, removeTemplate, getCompanyDetail } from '../../actions/leaderboardGroup';
import { CommonConstants } from '../../Constants';
import { Link } from '../Common/Link/Link';
import { Header } from '../Common/Header/Header';
import { Button } from '../Common/Button/Button';
import { Grid } from '../Common/Grid';
import Modal from '../Common/Popover/CustomPopover';
import miniClockImgInactive from '../../images/icon-clock.svg';
import miniClockImgActive from '../../images/icon-clock-active.svg';
import '../Users/Users.css';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './LBGroup.css';
import './leaderBoard.css';
import { TemplatePreview } from 'components/LeaderBoard/ProcessTemplates';
import { CenterLoader } from 'components/Common/CenterLoader';
import { NotificationMessage } from 'library/NotificationMessage';
import PropTypes from 'prop-types';
import { withReactRouter } from 'HOCs/withReactRouter';
import { format, FORMAT_TYPES } from 'services/Date';
import { LeaderBoardTooltip } from './LeaderBoardTooltip';

import {Paginate} from "library/Paginate";
import { PAGE_DATA , PAGE_DEFAULT} from "constants/paginationDefault";
import { applyPagination } from 'helpers/applyPagination.js';

const accessLevels = {
    modify: CommonConstants.userPermissions.EditLeaderboard,
    view: CommonConstants.userPermissions.ViewLeaderboard
};

const timeConfigurationTKeys = {
    'Current Hour': 'common__current-period--hour',
    'Previous Hour': 'common__previous-period--hour',
    'Current Day': 'common__current-period--day',
    'Previous Day': 'common__previous-period--day',
    'Current Daypart': 'common__current-period--daypart',
    'Previous Daypart': 'common__previous-period--daypart',
    'Current Rolling Hour': 'common__current-period--rollinghour',
    'Previous Rolling Hour': 'common__previous-period--rollinghour'
};

class LBTemplatesList extends Component {
    constructor(props) {
        super(props);
        const authService = new AuthenticationService(Config.authBaseUrl);

        this.state = {
            ascending: true,
            showCommonLoader: false,
            groups: [],
            companyDetails: {},
            showTemplatePreview: false,
            lbTemplateUID: null,
            pageNumber: PAGE_DEFAULT.page,
            itemsPerPage: PAGE_DEFAULT.recordsPerPage
        };
        this.api = new Api();
        this.isAdmin = authService.isAdmin();

        this.unitTests = {
            initialized: null,
            componentLoaded: null,
            tableInitialized: null,
            tableHasData: null
        };

        this.onPaginationChange = this.onPaginationChange.bind(this);
    }

    showLoadedTemplates(data = { companyUId: this.state.companyDetails.companyUId }, showCancelSuccess=true) {
        this.props.getTemplateList(() => {
            const { leaderBoardGroups: groups } = this.props;
            this.setState({ groups, showCommonLoader: false });
            this.setState({
                totalRows: this.props.TemplatesList?.list?.length,
                paginatedRows:  this.props.TemplatesList?.list?.length ? applyPagination(this.props.TemplatesList.list, { page: this.state.pageNumber, recordsPerPage: this.state.itemsPerPage }) : []
            });
            if (showCancelSuccess) {
                toast.dismiss();
                toast.success(
                        ( <NotificationMessage message={'leaderboard__template__delete-success'} isFailure={false}/>),
                        {
                            position: `top-center`,
                            transition: Slide,
                            autoClose: false,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: 0,
                            style: { height: '100px' }
                        });
            }
        }, data.companyUId);
    }

    onHide(fieldName) {
        this.setState({ lbTemplateUID: null, [fieldName]: false });
    }

    UNSAFE_componentWillMount() {
        this.setState({ showCommonLoader: true });
        const { queryParams } = this.props;
        const statusId = queryParams.get('statusId') ? Number(queryParams.get('statusId')) : null;
        const isEdit = queryParams.get('isEdit') ? queryParams.get('isEdit') === 'true' : null;
        toast.dismiss();
        if (statusId) {
            if (statusId === 1 ) {
                toast.success(
                        ( <NotificationMessage message={isEdit ? 'leaderboard__template__edit-success' : 'leaderboard__template__create-success'} isFailure={false}/>),
                        {
                            transition: Slide,
                            hideProgressBar: true,
                            closeOnClick: true,
                            progress: 0
                        }
                );
            }
        }

        this.props.getCompanyDetail((data) => {
            this.setState({ companyDetails: data });

            this.showLoadedTemplates(data, false);
        });

        this.unitTests.componentLoaded = true;
    }

    handleClick(did) {
        let url = `/settings/users/user/${did}`;
        if (this.isAdmin) {
            url = `/admin/settings/users/user?uuid=${did}`;
        }
        window.location.href = url;
    }


    translatedTimeFormatSetting(timeformat) {
        return (timeformat === 'minutes:seconds' || timeformat === 'Minutes:Seconds') ? 'common__minute-seconds' : 'common__seconds';
    }

    translatedMetricSetting(metricformat) {
        return (metricformat === 'Lane Total 1' || metricformat === 'Lane Total' || metricformat === 'LaneTotal') ? 'common__lane-total' : 'common__lane-total-2';
    }

    translatedTimebucketsString(timebucketsString) {
        const { t } = this.props;
        const templateHasMultipleTimebuckets = timebucketsString.includes(' | ');

        // If only one timebucket, no need for extra formatting. Just directly translate 'timebucketsString'
        if (!templateHasMultipleTimebuckets) {
            return t(timeConfigurationTKeys[timebucketsString]) || timebucketsString;
        }

        const timebucketsArray = timebucketsString.split(' | ');
        // If parsing array didn't work for some reason just return original input
        if (!timebucketsArray.length || !timebucketsArray[0] || !timebucketsArray[1] || !t(timeConfigurationTKeys[timebucketsArray[0]]) || !t(timeConfigurationTKeys[timebucketsArray[1]])) {
            return timebucketsString;
        }

        // Return final formatted string
        return `${t(timeConfigurationTKeys[timebucketsArray[0]])} | ${t(timeConfigurationTKeys[timebucketsArray[1]])}`;
    }

    onPaginationChange( { page, recordsPerPage } )
    {
        this.setState({
            pageNumber: page,
            itemsPerPage: recordsPerPage,
            paginatedRows: applyPagination(this.props.TemplatesList.list, { page, recordsPerPage })
        })
    }

    renderPage() {
        const { companyDetails, filterSelection, availableFilters, showTemplatePreview, lbTemplateUID, paginatedRows } = this.state;
        const { accessLevel, removeTemplate, t, navigate } = this.props;
        const hasAccessModify = accessLevel && accessLevel.includes(accessLevels['modify']);
        const { list: leaderBoards } = this.props.TemplatesList;
        this.unitTests.tableInitialized = true;


        const headers = [{
            text: 'common__name',
            property: 'TemplateName',
            sortable: false
        }, {
            text: 'common__leaderboards',
            property: 'NumberOfAssociatedLBGroups',
            sortable: false
        }, {
            text: 'leaderboard__template__configuration',
            property: 'Config',
            sortable: false
        }, {
            text: 'common__time-format',
            property: 'TimeFormat',
            sortable: false
        }, {
            text: 'common__metrics',
            property: 'Metric',
            sortable: false
        }, {
            text: 'common__history',
            property: 'Group_HistoryModal',
            sortable: false
        }, {
            text: 'common__actions',
            property: 'Group_LBActions',
            sortable: false
        }];

        if (!leaderBoards || leaderBoards.length <= 0) {
            this.unitTests.tableHasData = false;
            return <div />;
        }

        const tableRows = paginatedRows.map((LBTemplate) => {
            const isNitroLeaderboard = this.props.isNitro === 1 && LBTemplate.IsNitroLeaderboard === 1;
            const { t } = this.props;
            const leaderboardUrl = isNitroLeaderboard ? Config.leaderboard4XBaseUrl : Config.leaderboard3XBaseUrl;
            const isLastSelectedRow = LBTemplate.LBSettingTemplateUID === this.state.lastSelectedInfoMessage;
            const isAccountsDefaultTemplate = companyDetails.companyUId === LBTemplate.CompanyUID && LBTemplate.IsDefaultLBTemplate;
            const templateName = (<span className="hme-grid-cell-content">{LBTemplate.TemplateName} {isAccountsDefaultTemplate ? <em>({t('leaderboard__template__default-template')})</em>:''}</span>);

            const metricName = LBTemplate.Metric === 2 ? 'Lane Total 2' : 'Lane Total 1';
            const configName = `${LBTemplate.DisplayColumnOneType}${LBTemplate.DisplayColumnTwoType && LBTemplate.DisplayColumnTwoType !== 'null' ? ' | ' + LBTemplate.DisplayColumnTwoType : ''}`;
            const historyIcon = <LeaderBoardTooltip t={t} LBGroup={LBTemplate}></LeaderBoardTooltip>;

            const isDefault = LBTemplate.IsDefaultLBTemplate;
            const isAbleToDelete = !isDefault && companyDetails.companyUId === LBTemplate.CompanyUID || false;

            const actions = (
                <div className="fleft lbactions-textAlign actions-leaderboard hme-grid-cell-content">
                    <Button
                        onClick={() => {
                            this.setState({ lbTemplateUID: LBTemplate.LBSettingTemplateUID, showTemplatePreview: true });
                        }}
                        label={t('common__preview')}
                        className={`bigger-link-text`}
                    />
                    {isAbleToDelete && hasAccessModify? ' | ': ''}
                    {(isAbleToDelete && hasAccessModify) &&
                    <Button
                        onClick={() => {
                            this.setState({ templateIDToCancel: LBTemplate.LBSettingTemplateID, showCancelModal: true });
                        }}
                        disabled={!isAbleToDelete || !hasAccessModify}
                        label={t('common__remove')}
                        className={`bigger-link-text ${!isAbleToDelete ? ' hidden': ''}`}
                    />
                    }
                    {hasAccessModify ? ' |   ' : '   '}
                    <Link
                        to={`/leaderBoards/LBTemplatesList/${LBTemplate.LBSettingTemplateUID}`}
                        label={t('common__edit')}
                        disabled={!hasAccessModify}
                        className={`bigger-link-text ${!hasAccessModify ? ' hidden': ''}`}
                    />

                </div>
            );

            return {
                'TemplateName': templateName,
                'NumberOfAssociatedLBGroups': String(LBTemplate.NumberOfAssociatedLBGroups) || '0',
                'Config': this.translatedTimebucketsString(configName),
                'TimeFormat': t(this.translatedTimeFormatSetting(LBTemplate.TimeFormat)),
                'Metric': t(this.translatedMetricSetting(metricName)),
                'Group_HistoryModal': historyIcon,
                'Group_LBActions': actions
            };
        }
        );
        const cancelMessageWarning = (<div className="text-dark text-lg">
            {t('leaderboard__template__delete-warning')}
            <br/><br/>{t('leaderboard__template__delete-confirm-message')}?
        </div>);


        return (
            <div className="settings forms lbtemplates-grid-new">
                <div className="settings_plug clear">
                    <Header>{t('sub-header--lb__templates')}</Header>
                    <ToastContainer
                        className="toast-container-custom mt-5"
                        position="top-center"
                        autoClose={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                    />
                    {lbTemplateUID && <TemplatePreview
                        templateUID={lbTemplateUID}
                        show={showTemplatePreview}
                        onHide={this.onHide.bind(this, `showTemplatePreview`)}
                    />}
                    <ConfirmPopupComponent
                        show={this.state.showCancelModal}
                        message={cancelMessageWarning}
                        useContinueInsteadOfConfirm={true}
                        confirmationVerb={t('common__remove')}
                        onConfirm={() => {
                            this.setState({ showCommonLoader: true });

                            removeTemplate({ TemplateID: this.state.templateIDToCancel, CompanyUID: this.state.companyDetails.companyUId }, (data) => {
                                const { status, data: msg } = data;
                                if (status === true && msg) {
                                    // now server returns only 'status' key in response, but this redirect optionally left for a while
                                    setTimeout(() => {
                                        navigate('/leaderBoards/LBTemplatesList');
                                    }, 3000);
                                }

                                this.setState({ templateIDToCancel: null, showCancelModal: false });
                                this.showLoadedTemplates();
                            });
                        }}
                        onHide={() => this.setState({ templateIDToCancel: null, showCancelModal: false })}
                    />
                    <Link
                        to="/leaderBoards/LBTemplatesList/new"
                        className={`pull-right pick-me-up list-view-btn-grey button-shape mb-3${!hasAccessModify ? ' hidden': ''}`}
                        label={t('leaderboard__template__create-new-template')}
                        primary
                        button
                        disabled={!hasAccessModify}
                    />
                </div>
                <div className="settings_list lbgroups-grid-wrapper extra-padding-on-bottom lbtemplates-new-improved">
                    <Grid
                        rows={tableRows}
                        headers={headers}
                        showOverflow={true}
                    />
                    <Paginate
                        className="leaderboard-paginate"
                        page={this.state.pageNumber}
                        recordsPerPage={this.state.itemsPerPage}
                        pageSizes={PAGE_DATA.PAGE_SIZES_PUBLIC}
                        total={this.state.totalRows}
                        onChange={this.onPaginationChange}
                        hideSinglePage
                    />
                </div>
            </div>
        );
    }

    // Renders the table container, its header columns, and the add group button
    render() {
        const { showCommonLoader } = this.state;
        const { t } = this.props;
        this.unitTests.initialized = true;

        return (
            <div>
                <section className="users leaderboards">
                    { showCommonLoader && <CenterLoader>{t('common__loading')}</CenterLoader>}
                    { !showCommonLoader && this.renderPage() }
                </section>
            </div>
        );
    }
}

LBTemplatesList.propTypes = {
    navigate: PropTypes.func,
    queryParams: PropTypes.object
};

function mapStateToProps(state) {
    return {
        TemplatesList: state.leaderboardGroups.leaderBoardTemplates,
        permissions: state.permissions
    };
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators(
            {
                getCompanyDetail: getCompanyDetail,
                getTemplateList,
                sortLeaderBoard,
                save,
                removeTemplate,
                get
            },
            dispatch
    );
}
export default compose(
        connect(
                mapStateToProps,
                matchDispatchToProps
        ),
        withTranslation(),
        withReactRouter
)(LBTemplatesList);
