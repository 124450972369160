import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';
import { BUTTON_VARIANTS } from 'library/Button';

import { useNotificationsCenterContext } from '../../Context';

const confirmButtonVariants = [BUTTON_VARIANTS.SUBMIT];

export const Remove = () => {
    const { t } = useTranslation();

    const {
        removeNotification,
    } = useNotificationsCenterContext();

    const {
        isRemoveModalShown,
        onRemoveConfirm,
        onRemoveCancel
    } = removeNotification;

    const removeActions = useMemo(() => [{
        children: t('common__popup--cancel-action'),
        onClick: onRemoveCancel
    }, {
        children: t('common__popup--confirm-action'),
        variants: confirmButtonVariants,
        onClick: onRemoveConfirm
    }], [onRemoveConfirm, onRemoveConfirm, t]);

    return (
        <>
            <ConfirmPopupComponentGeneric
                show={isRemoveModalShown}
                title={t('merge_devices__popup__title')}
                message={t('notifications-center__modals--remove__confirm')}
                className="notifications-center"
                onHide={onRemoveCancel}
                actions={removeActions}
            />
        </>
    );
};
