import { useEffect, useCallback } from 'react';
import { useAsync } from 'hooks/useAsync';
import { getStoreSystemTiers } from 'services/Store';

export const useLoadStoreSystemTiers = () => {
    const {
        run: runLoadStoreSystemTiers,
        isLoading: isStoreSystemTiersLoading = false,
        data: storeSystemTiers,
        error: storeSystemTiersLoadingError,
    } = useAsync();

    const loadStoreSystemTiers = useCallback(async () => {
        runLoadStoreSystemTiers(getStoreSystemTiers());
    }, []);

    useEffect(() => { loadStoreSystemTiers(); }, []);

    return { storeSystemTiers, isStoreSystemTiersLoading, storeSystemTiersLoadingError, loadStoreSystemTiers };
};
