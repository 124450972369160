import { useEffect, useMemo } from 'react';

import { useAsync } from 'hooks/useAsync';
import { storeClassification } from 'services/StoreClassification';

const defaultClassifications = [];

const tKeys = {
    'Customer Store': 'admin__store__classification__option--customer-store',
    'Customer Test': 'admin__store__classification__option--customer-test',
    'Demo': 'admin__store__classification__option--demo',
    'Partner Lab': 'admin__store__classification__option--partner-lab',
    'Brand Lab': 'admin__store__classification__option--brand-lab',
    'Development': 'admin__store__classification__option--development',
    'Field Test': 'admin__store__classification__option--field-test',
};

export const useLoadClassifications = () => {
    const {
        data: classifications,
        isLoading,
        run
    } = useAsync();

    const items = useMemo(() => classifications?.map(({ type, id }) => ({
        value: id,
        text: tKeys[type],
    })), [classifications]);

    useEffect(() => {
        run(storeClassification.fetchAll());
    }, []);

    return {
        classifications: items || defaultClassifications,
        isLoading,
    };
};
