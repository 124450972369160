import { useTranslation } from 'react-i18next';

import { Grid } from 'components/Common/Grid';
import { headers as defaultHeaders } from './headers';

export const List = ({
    rows,
    isLoading,
    headers = defaultHeaders,
    noRecordsText = 'admin__customer-notification__list__grid__no-records',
    rowKey = 'CustomerNotificationID',
}) => {
    const { t } = useTranslation();

    return (
        <Grid
            rows={rows}
            headers={headers}
            noRecordsMessage={t(noRecordsText)}
            rowKey={rowKey}
            isLoading={isLoading}
        />
    );
};
