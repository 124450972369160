import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Grid, applySearchValue } from 'components/Common/Grid';
import { Button } from 'library/Button';
import { useTranslation } from 'react-i18next';
import { CommonConstants } from 'Constants';

const baseGridHeaders = [
    {
        text: 'device-upgrades__grid__header--brand',
        property: 'Brand_Name',
        className: 'hme-grid-cell-show-all-content',
        flex: 124,
    },
    {
        text: 'device-upgrades__grid__header--account',
        property: 'User_EmailAddress',
        className: 'hme-grid-cell-show-all-content',
        flex: 188,
    },
    {
        text: 'device-upgrades__grid__header--store-number',
        property: 'Store_Number',
        className: 'hme-grid-cell-show-all-content',
        flex: 106,
    },
    {
        text: 'device-upgrades__grid__header--store-name',
        property: 'Store_Name',
        className: 'hme-grid-cell-show-all-content',
        flex: 100,
    },
    {
        text: 'common__device__serial-number',
        property: 'Device_SerialNumber',
        className: 'hme-grid-cell-show-all-content',
        flex: 116,
    },
    {
        text: 'common__device__product-id',
        property: 'Device_Product_ID',
        className: 'hme-grid-cell-show-all-content',
        flex: 110,
        sortable: true,
    },
    {
        text: 'device-upgrades__grid__header--actions',
        property: 'actions',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
        flex: 74,
    },
];

const getGridHeaders = (deviceType) => {
    return deviceType === CommonConstants.deviceType.nexeo.name.toLowerCase()
        ? baseGridHeaders
        : baseGridHeaders.filter((header) => header.property !== 'Device_Product_ID')
};

const searchProperties = [
    'AccountOwner_EmailAddress',
    'Store_Number',
    'Store_Name',
    'Device_SerialNumber',
    'Device_MainVersion',
    'Brand_Name',
];

export const AccountDevicesGrid = ({
        devices,
        search,
        selectedDeviceType,
        selectedPeripheralDeviceType,
        onRemoveDevice
    }) => {
    const [filteredDevices, setFilteredDevices] = useState(devices);
    const { t } = useTranslation();
    const gridHeaders = getGridHeaders(selectedDeviceType);

    useEffect(() => {
        const devicesFilteredBySearch = applySearchValue(devices, searchProperties, search, {
            minLength: 1,
        });

        setFilteredDevices(devicesFilteredBySearch);
    }, [devices, search, applySearchValue, searchProperties]);

    const onRemove = useCallback(
        (deviceID) => {
            setFilteredDevices(filteredDevices.filter((d) => d.Device_ID !== deviceID));
            onRemoveDevice(deviceID);
        },
        [filteredDevices, onRemoveDevice],
    );

    const rows = useMemo(
        () =>
            filteredDevices.map((device) => ({
                ...device,
                userEmailAddress: device.AccountOwner_EmailAddress,
                actions: (
                    <Button variants={['transparent']} onClick={() => onRemove(device.Device_ID)}>
                        {t('common__remove')}
                    </Button>
                ),
            })),
        [filteredDevices],
    );

    return (
        <div className="upgrade-devices-list hme-components">
            <Grid
                headers={gridHeaders}
                rows={rows}
                isLoading={false}
                rowKey="Device_ID"
                noRecordsMessage={t('common__no-devices--found')}
            />
        </div>
    );
};
