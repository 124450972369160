import * as R from 'ramda';
export default (list = [], sortSelection = {}, changedSortedProperties = {}) => {
  const [colName = ''] = Object.keys(sortSelection) || [];
  const property = changedSortedProperties[colName] || colName;
  const sortFunctions = {
      '1': R.ascend,
      '-1': R.descend,
  }
  const sortType = String(sortSelection[colName]);
  if (!property || !sortFunctions[sortType]) {
    return list;
  }
  const sortByColumn = sortFunctions[sortType](R.prop(property));
  return R.sort(sortByColumn, list || [])
}