/* eslint-disable react/prop-types */
import React, { useCallback, useRef } from 'react';
import { cond, empty, T } from 'ramda';
import { useClickOutSide } from 'hooks/useClickOutSide';
import { FilterSection } from './FilterSection';
import './JoinedFilters.scss';
import { Label } from 'library/Label';
import { useTranslation } from 'react-i18next';

const defaultAvailableFilters = [];

const JoinedFiltersComponent = ({
    availableFilters = defaultAvailableFilters,
    selection,
    noItemsFoundText,
    onHide,
    onChange = empty,
    sectionLables,
    header,
    subheader,
    body,
    footer
}) => {
    const ref = useRef(null);
    const { t } = useTranslation();

    const outSideClickHandler = useCallback(() => {
        onHide();
    }, [onHide]);

    useClickOutSide(ref, outSideClickHandler);

    const onFilterChange = (newFilter) => {
        const filtered = { ...selection, ...newFilter };
        onChange(filtered);
    };


    return (
        <div className="hme-joined-filters-popup" ref={ref}>
            {header || <div className="hme-joined-filters-popup-header">
                <span>{t('common__show-me')}</span>
                <span className="hme-joined-filters-popup-close" onClick={onHide}>×</span>
            </div>}
            {subheader}
            <div className="hme-joined-filters-popup-body">
                {body}
                {
                    availableFilters && availableFilters.map(({
                        property,
                        items
                    }) => (
                        <React.Fragment key={property}>
                            {sectionLables &&
                                sectionLables[property].fragment || <Label>{sectionLables[property].title}</Label>}
                            {!sectionLables[property].hidden &&
                            <FilterSection
                                items={items}
                                selection={selection[property]}
                                allText={sectionLables && sectionLables[property].allText || t('common__all')}
                                noItemsFoundText={noItemsFoundText}
                                onChange={onFilterChange}
                                property={property}
                            />}
                        </React.Fragment>)
                    )
                }
            </div>
            {footer}
        </div>
    );
};

export const JoinedFilters = cond([
    [({ show }) => show, JoinedFiltersComponent],
    [T, () => <></>]
]);
