import React from 'react';
import { cond, T } from 'ramda';
import classNames from 'classnames';

import './RowText.scss';

export const RowText = cond([
    [
        ({ children }) => children,
        ({ className, children }) => (
            <div className={classNames('hme-row-text', className)}>{children}</div>
        )
    ],
    [T, () => null]
]);
