export const VAIO_DEFAULTS = {
    tokenName: '',
    tokenExpiration: 6,
    apiType: 'VAIO',
    tokenManager: '',
    email2: '',
    email3: '',
    vaioProvider: '',
    expiryDate: '',
    lastRenewedDate: '',
    cloudAccounts: null
};

export const renewTokenTypes = {
    ORIGINAL_EXP_DATE : 'originalExpDate',
    TODAY_EXP_DATE : 'todayExpDate',
    SPECIFIC_EXP_DATE : 'specificExpDate',
}
