export const mobileHeaders = [
    { column: [
        { text: 'common__role', property: 'Role_Name' },
        { text: 'common__company', property: 'Company_Name' }
    ] },
    { column: [
        { text: 'common__description', property: 'Role_Description' },
        { text: 'common__users', property: 'UserName' }
    ] }
];
