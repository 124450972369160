/* eslint-disable complexity */
import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Label } from 'library/Label';
import { RemainingCount } from 'library/RemainingCount';
import { useGeneratedId } from 'hooks/useGeneratedId';

import { ReactComponent as ClearInputIcon } from 'assets/icons/close-cross.svg';

import './InputComponent.scss';

export const INPUT_VARIANTS = {
    LABEL_INSIDE: 'label-inside',
    LABEL_INSIDE_MOBILE_ONLY: 'label-inside-mobile-only',
    LABEL_INLINE: 'label-inline',
    VALID: 'valid',
    INVALID: 'invalid',
    UNDERLINED: 'underlined',
    CLOCK: 'clock'
};

export const InputComponent = ({
    value,
    label = '',
    variants = [],
    className,
    onClick,
    onChange,
    isRequired,
    isReadOnly = false,
    isDisabled,
    maxLength,
    showRemaining = false,
    children,
    showClearIcon = false,
    inputRef = null,
    universalOnChange=false,
    fromDatePicker=false,
    valueClassName = '',
    isTypingDisabled = false, // don't show cursor in input, but not disabled,
    ...inputProps
}) => {
    const { t } = useTranslation();
    const id = useGeneratedId(inputProps.id);

    // showRemaining can break clear input icon styles
    const needToShowClearIcon = (showClearIcon && value) && (!isReadOnly && !showRemaining);

    const onClearInputClick = useCallback(() => {
        if (onChange && !isDisabled) {
            onChange('');
        }
    }, [onChange, isDisabled]);

    const isRequiredLabel = useMemo(() => isRequired && !isDisabled, [isRequired, isDisabled]);

    return (
        <div
            className={classNames(
                    'hme-input-container',
                    className,
                    variants.map((variant) => `hme-input-${variant}-variant`),
                    {
                        'hme-input-disabled-container': isDisabled,
                        'hme-input--with-clear-button': needToShowClearIcon
                    }
            )}
            onClick={onClick}
        >
            <Label as="label" htmlFor={id}>{t(label)}</Label>
            <div className="hme-input-wrapper">
                <span className={`hme-input-component-before ${isRequiredLabel ? '' : 'hide'} ${fromDatePicker && 'input-component-from-date-picker'}`}>*</span>
                <input
                    className={`hme-input-component ${isReadOnly ? 'hide' : ''} ${isTypingDisabled ? 'typing-disabled' : ''}`}
                    onChange={universalOnChange ? onChange : onChange && (({ target: { value } }) => onChange(value))}
                    disabled={isDisabled}
                    value={value}
                    maxLength={maxLength}
                    ref={inputRef}
                    readOnly={isReadOnly}
                    {...inputProps}
                    id={id}
                />
                {
                    needToShowClearIcon &&
                        <ClearInputIcon onClick={onClearInputClick} className="hme-input__clear-button" />
                }
                {
                    showRemaining && <RemainingCount value={value} maxLength={maxLength}/>
                }
                <span
                    className={classNames(valueClassName, {
                        'hide': !isReadOnly,
                        'input-component-from-date-picker': fromDatePicker
                    })}
                >
                    {value}
                </span>
                { children }
            </div>
        </div>
    );
};
