/* eslint-disable require-jsdoc */
import React from 'react'
import { compose } from 'ramda';
import '../Footer/Footer.css'
import { Config } from '../../Config'
import AuthenticationService from '../Security/AuthenticationService'
import ActionLoader from '../Alerts/ActionLoader'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { checkDevice, unBind, unBindEmail, upgrade } from '../../actions/actions'
import Error from '../Actions/Error'
import PropTypes from 'prop-types';
import { withReactRouter } from 'HOCs/withReactRouter';

class Upgrade extends React.Component {
    constructor (props) {
        super(props)
        this.authService = new AuthenticationService(Config.authBaseUrl);
        const { queryParams } = this.props;
        const duid = queryParams.get('duid');

        this.state = {
            showCommonLoader: true,
            deviceError: false,
            upgradeError: false,
            token: this.authService.getToken(),
            errorMessage: '',
            duid
        }
    }

    UNSAFE_componentWillMount () {
        if (this.state.duid) {
            let isDevice = 0
            let duid = this.state.duid
            let self = this
            this.props.checkDevice(duid, (device) => {
                if (device.status) {
                    isDevice = device.data.DevicePresent
                    if (isDevice === 1) {
                        self.props.unBind(duid, (data) => {
                            if (data.status) {
                                self.props.unBindEmail(duid, () => { })
                            }
                        })
                        self.props.upgrade(duid, (upgradeData) => {
                            if (!upgradeData.status) {
                                this.setState({
                                    upgradeError: true,
                                    errorMessage: upgradeData.message,
                                    showCommonLoader: false
                                })
                            }
                        })
                    } else {
                        this.setState({
                            showCommonLoader: false,
                            deviceError: true
                        })
                    }
                } else {
                    this.setState({
                        showCommonLoader: false,
                        deviceError: true
                    })
                }
            })
        }
    }

    render () {
        return (<section>
            {this.renderUpgrade()}
            {<Error error={this.state.deviceError} />}
            {this.renderServiceError()}
        </section>)
    }

    renderServiceError () {
        if (this.state.upgradeError) {
            return (<div className='col1'>
                <div className='forms clear'>
                    <div className='error redbox_err_all redbox_marg'>
                        <ul>
                            <li>{this.state.errorMessage}</li>
                        </ul>
                    </div>
                </div>
            </div>)
        } else {
            return (<div />)
        }
    }

    renderUpgrade () {
        if (!this.state.deviceError && !this.state.upgradeError) {
            return (<section>
                <ActionLoader showLoader={this.state.showCommonLoader} message='device-upgrade__loading' />
            </section>)
        } else {
            return (<div />)
        }
    }
}

Upgrade.propTypes = {
    queryParams: PropTypes.object
};

function mapStateToProps (state) {
    return {
        headers: state.headers,
        permissions: state.permissions
    }
}
function matchDispatchToProps (dispatch) {
    return bindActionCreators(
            {
                checkDevice: checkDevice,
                unBind: unBind,
                unBindEmail: unBindEmail,
                upgrade: upgrade
            }, dispatch
    )
}
export default compose(
        connect(mapStateToProps, matchDispatchToProps),
        withReactRouter
)(Upgrade);
