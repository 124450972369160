export const MOBILE_USERS_SORT_OPTIONS = [
    {
        label: 'distributor-users__sorting__user-name__asc',
        value: { User_EmailAddress: 1 }
    },
    {
        label: 'distributor-users__sorting__user-name__desc',
        value: { User_EmailAddress: -1 }
    },
    {
        label: 'distributor-users__sorting__first-name__asc',
        value: { User_FirstName: 1 }
    },
    {
        label: 'distributor-users__sorting__first-name__desc',
        value: { User_FirstName: -1 }
    },
    {
        label: 'distributor-users__sorting__last-name__asc',
        value: { User_LastName: 1 }
    },
    {
        label: 'distributor-users__sorting__last-name__desc',
        value: { User_LastName: -1 }
    },
    {
        label: 'distributor-users__sorting__role__asc',
        value: { RoleText: 1 }
    },
    {
        label: 'distributor-users__sorting__role__desc',
        value: { RoleText: -1 }
    }
];
