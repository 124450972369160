import React from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'ramda';

import { withGamificaionMarketingLayout } from '../../Common/HOCs';
import { ViewEditForm } from '../../Common/ViewEdit';
import { PageTitle } from '../../Common/PageTitle';
import { useCreate } from './hooks/useCreate';

import './CreatePage.scss';

const Create = () => {
    const { t } = useTranslation();
    const state = useCreate();

    return (
        <div className="create-contest">
            <PageTitle className="create-contest__page-title">{t('contest__add-contest')}</PageTitle>

            <ViewEditForm {...state} />
        </div>
    );
};

export const CreatePage = compose(
    withGamificaionMarketingLayout({
        className: ['hme-create-contest']
    })
)(Create);
