import React from "react";
import { Config } from "../../Config";
import "./InviteLandingPage.css";
import t from "../Language/language";
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import { withReactRouter } from 'HOCs/withReactRouter';

class LeaderBoardLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusId: null
    };
  }
  UNSAFE_componentWillMount() {
    const { queryParams } = this.props;
    const statusId = queryParams.get('statusId') ? Number(queryParams.get('statusId')) : null;
    this.setState({ statusId });
  }
  render() {
    let statusId = this.state.statusId;
    let language;
    if (statusId === 1 || statusId === 3) {
      language = 1;
    } else {
      language = 0;
    }

    return (
      <div className={"landingText"}>
        <div className={statusId == 1 || statusId == 2 ? "show" : "hidden"} align="center">
          <h3>{t[language].LBInviteSuccess1}</h3>
          <h3>{t[language].LBInviteSuccess2}</h3>
          <h3>
            <u>
              <a href={Config.coldFusionUrl}>{t[language].LBInviteLoginNew}</a>
            </u>
          </h3>
        </div>

        <div className={statusId == 3 || statusId == 4 ? "show" : "hidden"} align="center">
          <h3>{t[language].LBInviteReject}</h3>
        </div>

        <div className={statusId == 5 ? "show" : "hidden"} align="center">
          <h3>{t[language].LBInviteInvalidURL}</h3>
          <h3>
            <u>
              <a href={Config.coldFusionUrl}>{t[language].LBInviteLoginCurrent}</a>
            </u>
          </h3>
        </div>
      </div>
    );
  }
}

LeaderBoardLanding.propTypes = {
    queryParams: PropTypes.object
};

export default compose(
        withReactRouter
)(LeaderBoardLanding);
