import React from 'react';

import { SourceDeviceInput } from '../SourceDeviceInput';
import { DeviceSettings } from './DeviceSettings';

export const ZoomForm = ({ params, onParamsChange }) => {
    const {
        sourceSettingsList: settingsList,
        sourceDevice: device,
    } = params;

    const onSourceDeviceChange = (sourceDevice) => {
        onParamsChange({
            ...params,
            sourceDevice,
            sourceSettingsList: device ? params.sourceSettingsList : []
        })
    };

    const setSettingsList = (sourceSettingsList) => {onParamsChange({
        ...params,
        sourceSettingsList,
    })};

    return (
        <div className='admin-create-apply-device-settings-task-source-serial'>
            <SourceDeviceInput value={device} onChange={onSourceDeviceChange} type='zoom'/>
            {
                device ?
                    <DeviceSettings
                        values={settingsList}
                        onChange={setSettingsList}
                        isEditable
                    /> :
                    null
            }
        </div>
    );
};
