import { checkIsNexeoDeviceType } from '../helpers/checkIsNexeoDeviceType';

export const formDataValidator = (
    indexOfInput,
    sourceDeviceSerialNumber,
    sourceDeviceProductId,
    selectedDeviceType,
    storeNumber,
    settingName,
    account,
    settingsVersion,
) => {
    const isNexeoDeviceType = checkIsNexeoDeviceType(selectedDeviceType);

    const formErrorsConditions = [
        {
            key: 'settingName',
            condition: !settingName,
            message: 'settings_snapshots__create_snapshot__form__validation--setting-name',
        },
        {
            key: 'settingsVersion',
            condition: !settingsVersion,
            message: 'settings_snapshots__create_snapshot__form__validation--setting-version',
        },
        {
            key: 'sourceDeviceIdentifier',
            condition: !isNexeoDeviceType && !sourceDeviceSerialNumber && (!account || !storeNumber),
            message: 'settings_snapshots__create_snapshot__form__validation--store-device-serial-number',
        },
        {
            key: 'sourceDeviceIdentifier',
            condition: isNexeoDeviceType && !sourceDeviceSerialNumber && !sourceDeviceProductId && (!account || !storeNumber),
            message: 'settings_snapshots__create_snapshot__form__validation--store-device-serial-number-product-id',
        },
        {
            key: 'account',
            condition: !indexOfInput && account,
            message: 'settings_snapshots__create_snapshot__form__validation--account',
        },
    ];
    const formError = formErrorsConditions.reduce(
        (errors, el) => (el.condition && ((errors[el.key] = el.message), (errors.status = true)), errors),
        { status: false },
    );
    return formError;
};
