const statuses = {
    WAITING_FOT_UPLOAD: 0,
    IN_PROGRESS: 1,
    COMPLETED: 2,
    FAILED: 3,
    CANCELED: 4,
};

const zoom = {
    applySettingsMinVersion: '3.13.15',
    applySettingsMaxVersion: '4.0.0',
};

export const taskConfig = {
    statuses,
    zoom,
};
