import PropTypes from 'prop-types';

export const STORE_DETAILS_TYPE = PropTypes.shape({
    Store_Name: PropTypes.string,
    Brand_Name: PropTypes.string,
    Store_Number: PropTypes.string,
    GroupName: PropTypes.string,
    Store_PhoneNumber: PropTypes.string,
    Store_FaxNumber: PropTypes.string,
    Store_AddressLine1: PropTypes.string,
    Store_AddressLine2: PropTypes.string,
    Store_AddressLine3: PropTypes.string,
    Store_AddressLine4: PropTypes.string
});
