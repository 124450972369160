import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'library/Button';
import { upgradeStatuses, ADMIN_DEVICE_UPGRADES_URL } from '../../../constants';

import './Actions.css';

export const Actions = ({ upgradeID, status, onCancelSelected }) => {
    const { t } = useTranslation();

    const shouldShowCancel = [upgradeStatuses.IN_PROGRESS, upgradeStatuses.SCHEDULED, upgradeStatuses.PENDING_APPROVAL].includes(status);

    const onClick = useCallback(() => {
        onCancelSelected(upgradeID);
    }, [upgradeID, onCancelSelected]);

    return (
        <div className="hme-grid-actions">
            {shouldShowCancel && (
                <div className="hme-grid-action">
                    <Button variants={['transparent']} onClick={onClick}>
                        {t('common__cancel')}
                    </Button>
                </div>
            )}
            <div className="hme-grid-action">
                <Link to={`${ADMIN_DEVICE_UPGRADES_URL}/${upgradeID}`}>
                    {t('device-upgrades__grid__actions__view-details')}
                </Link>
            </div>
        </div>
    );
};

Actions.propTypes = {
    upgradeID: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    onCancelSelected: PropTypes.func.isRequired,
};
