import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { omit } from 'ramda';

import { useLoadCountries } from 'hooks/useLoadCountries';
import { useLoadRegions } from 'hooks/useLoadRegions';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';

import { useSavePartner } from './useSavePartner';
import { useLoadPartnersType } from '../../../../Common/hooks/useLoadPartnersType';

const errorTKeys = {
    'Bad Request': 'common__error--invalid-data',
    'userAlreadyExist': 'admin__partner__form__error--user-exists'
};

export const useCreatePartner = (partner) => {
    const navigate = useNavigate()

    const {
        countries,
        isCountriesLoading,
        countriesLoadingError
    } = useLoadCountries();

    const {
        regions,
        isRegionsLoading,
        regionsLoadingError,
    } = useLoadRegions(partner.countryId);

    const {
        save,
        isSaving,
        saveError,
    } = useSavePartner();

    const {
        isLoading: isTypesLoading,
        types,
        error: typesError
    } = useLoadPartnersType();

    const hasNetworkError = !!countriesLoadingError || !!regionsLoadingError || !!typesError;

    useEffect(() => {
        if (hasNetworkError) {
            addErrorNotification('common__error--unknown');
        }
    }, [hasNetworkError]);

    const onCancel = useCallback(() => {
        navigate('/admin/partners');
    }, [navigate]);

    const onSave = useCallback(async () => {
        try {
            const partnerData = omit(['isActive'], partner);

            await save(partnerData);

            navigate('/admin/partners');

            setTimeout(() => {
                addSuccessNotification('admin__partner__form__success--save');
            }, 500);
            
        } catch(err) {
            addErrorNotification(errorTKeys[err.message] || 'common__error--network-error');
        }

    }, [partner, navigate]);

    return {
        countries,
        regions,
        isLoading: isCountriesLoading || isSaving || isTypesLoading,
        isRegionsLoading,
        types,
        onCancel,
        onSave,
    };
};