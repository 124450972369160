import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';
import { Tip } from 'library/Tip';

import './Section.scss';

export const Section = forwardRef(({
    col,
    number,
    disabled,
    title = '',
    className,
    showNumber = true,
    children,
    infoIcon,
}, ref) => {
    const { t } = useTranslation();

    return <div
        className={classNames('hme-section', className, {
            [`col-${col}`]: col,
            'hme-section-disabled': disabled,
            'hme-section-first': number === 1,
            'hme-section-without-title': !title
        })}
        ref={ref}
    >
        <Title>
            {number && showNumber && `${number}. `}
            {t(title)}
            {infoIcon && <>{infoIcon}</>}
        </Title>
        { disabled ? null : children }
    </div>;
});

Section.displayName = 'Section';
