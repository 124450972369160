export const nexeoSystemTiers = [
    {
        'id': 1,
        'name': 'NEXEO Core Single',
        'key': 'NEXEO_CORE_SINGLE',
        'deviceTypeId': 5
    },
    {
        'id': 2,
        'name': 'NEXEO Core Dual',
        'key': 'NEXEO_CORE_DUAL',
        'deviceTypeId': 5
    },
    {
        'id': 3,
        'name': 'NEXEO Single',
        'key': 'NEXEO_SINGLE',
        'deviceTypeId': 5
    },
    {
        'id': 4,
        'name': 'NEXEO Dual',
        'key': 'NEXEO_DUAL',
        'deviceTypeId': 5
    },
    {
        'id': 5,
        'name': 'NEXEO Pro Single',
        'key': 'NEXEO_PRO_SINGLE',
        'deviceTypeId': 5
    },
    {
        'id': 6,
        'name': 'NEXEO Pro Dual',
        'key': 'NEXEO_PRO_DUAL',
        'deviceTypeId': 5
    }
];

export const jTechSystemTies = [
    {
        'id': 27,
        'name': 'LinkWear Core',
        'key': 'LINKWEAR_CORE',
        'deviceTypeId': 6
    },
    {
        'id': 28,
        'name': 'LinkWear Core + Task',
        'key': 'LINKWEAR_CORE_TASK',
        'deviceTypeId': 6
    },
    {
        'id': 29,
        'name': 'LinkWear Core + Connect',
        'key': 'LINKWEAR_CORE_CONNECT',
        'deviceTypeId': 6
    },
    {
        'id': 30,
        'name': 'LinkWear Core + Task and Connect',
        'key': 'LINKWEAR_CORE_TASK_CONNECT',
        'deviceTypeId': 6
    }
];
