import { CommonConstants } from '../../Constants';
import compareDeviceTypes from '../Device/compareDeviceTypes';
import {devicesToAdd} from '../../components/Stores/PreInstallStoreComponents/constants';

const {
    deviceType: {
        zoom,
        zoomNitro,
        nexeo,
    },
} = CommonConstants;

const getAvailableDevices = (existedDevices) => {
    const zoomNitroExists = existedDevices.some(device => compareDeviceTypes(device, zoomNitro));
    const zoomNitroOnlineExists = existedDevices.some(device => 
        compareDeviceTypes(device, zoomNitro) && !device['Device_IsPreconfigured'] && device['Device_IsActive']
    );
    const zoomNitroPreconfiguredExists = existedDevices.some(device => 
        compareDeviceTypes(device, zoomNitro) && device['Device_IsPreconfigured']
    );
    const zoomOnlineExists = existedDevices.some(device =>
        compareDeviceTypes(device, zoom) && !device['Device_IsPreconfigured'] && device['Device_IsActive']
    );
    const zoomPreconfiguredExists = existedDevices.some(device =>
        compareDeviceTypes(device, zoom) && device['Device_IsPreconfigured']
    );
    const nexeoOnlineExists = existedDevices.some(device =>
        device['Device_DeviceType_ID'] === nexeo.id && !device['Device_IsPreconfigured'] && device['Device_IsActive']
    );
    const nexeoPreconfiguredExists = existedDevices.some(device =>
        device['Device_DeviceType_ID'] === nexeo.id && device['Device_IsPreconfigured']
    );

    // due to DDM-1189
    /** No '+' button is displayed next to the last tab available if 
      - I have a ZOOM device online or pre-configured, 
        a NEXEO device online or pre-configured, 
        and no ZOOM Nitro device (because a store cannot have both timer device types at once)
      - I have a ZOOM Nitro device online or pre-configured, 
        a NEXEO device online or pre-configured, 
        and no ZOOM device (because a store cannot have both timer device types at once) */
    // DDM-1843
    // ZOOM devices are not available for preconfiguration
    const availableDevices =
        devicesToAdd
            .filter(d => {
                if (d['Device_Name'] === nexeo.name) {
                    if (!nexeoOnlineExists && !nexeoPreconfiguredExists) return true;
                }

                if (d['Device_Name'] === zoomNitro.name) {
                    if (!zoomNitroOnlineExists && !zoomNitroPreconfiguredExists && !zoomOnlineExists) return true;
                }
            });
    return availableDevices;
}
export default getAvailableDevices;