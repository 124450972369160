import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * @example
 * const fullTabRoutes = {
 *      tab1: '/fullPathToParentRoute/tab1'
 *      tab2: '/fullPathToParentRoute/tab2'
 * };
 *
 * const { activeTab, handleActiveTabChange } = useTabs('tab1', fullTabRoutes);
*/
export const useTabs = (initialTab = '', fullTabRoutes = {}) => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(initialTab);

    const handleActiveTabChange = useCallback((newTab) => {
        setActiveTab(newTab);
        navigate(fullTabRoutes[newTab]);
    }, [navigate]);

    return {
        activeTab,
        setActiveTab,
        handleActiveTabChange
    };
};
