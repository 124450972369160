export const headers = [
    {
        text: 'admin__customer-notification__list__grid__header__eng-text',
        property: 'engText',
        flex: 2.9
    },
    {
        text: 'common__type',
        property: 'type',
        className: 'hme-customer-notification-status__type'
    },
    {
        text: 'common__created-date-n-time',
        property: 'createdDate'
    },
    {
        text: 'common__created-by',
        property: 'createdBy'
    },
    {
        text: 'admin__customer-notification__list__grid__header__enabled-date',
        property: 'lastEnabledDate'
    },
    {
        text: 'admin__customer-notification__list__grid__header__enabled-by',
        property: 'enabledBy'
    },
    {
        text: 'admin__customer-notification__list__grid__header__disabled-date',
        property: 'lastDisabledDate'
    },
    {
        text: 'admin__customer-notification__list__grid__header__disabled-by',
        property: 'disabledBy'
    },
    {
        text: 'common__actions',
        property: 'Actions',
        flex: 1.6,
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-customer-notification-status__actions'
    }
];
