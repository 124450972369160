import React from 'react';
import { Section } from 'library/Section';
import { useTranslation } from 'react-i18next';
import { UpgradeInfoForm } from '../../UpgradeInfoForm';

import './SourceSection.css';

export const SourceSection = ({
    deviceVersions,
    upgradeVersions,
    formErrors,
    sectionNumber,
    col,
    disabled = false,
    onUpgradeInfoChange,
}) => {
    const { t } = useTranslation();
    return (
        <Section
            className="create-device-upgrade-source"
            number={sectionNumber}
            col={col}
            title={t('device-upgrades__section__title--enter-upgrade-information')}
            disabled={disabled}
        >
            <UpgradeInfoForm
                formErrors={formErrors}
                deviceVersions={deviceVersions}
                upgradeVersions={upgradeVersions}
                onChange={onUpgradeInfoChange}
            />
        </Section>
    );
};
