import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { formatStoreGroups, getStoreUids } from '../../../helpers/Store/storeGroups';
import { uniq, compose } from 'ramda';

import {
    TreeSelectInput,
    CheckboxInput
} from '../index';
import { SearchInput } from 'components/Inputs/SearchInput';

import './StoresSelector.css';

class StoresSelectorComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialStoresTree: [],
            workingStoresTree: [],
            checkedStores: [],
            searchStr: '',
            isAllSelected: false
        };

        this.onSearchRequest = this.onSearchRequest.bind(this);
    }

    // needed for case when parent change tree structure
    /**
     *
     * @param {*} props
     */
    UNSAFE_componentWillReceiveProps(props) {
        const { tree } = props;
        if (tree) {
            this.cleanCatch();
            this.storesTreeFormatter(tree);
        }
    }

    /**
     *
     */
    componentDidMount() {
        const { tree } = this.props;
        if (tree && tree.length) {
            this.storesTreeFormatter(tree);
        }
    }

    /**
     *
     * @param {*} searchStr
     */
    onSearchRequest(searchStr) {
        const workingStoresTree = this.getAvailableStores(searchStr);
        this.setState({
            searchStr,
            workingStoresTree
        }, this.onChange);
    }

    /**
     *
     * @param {*} isAllSelected
     * @return {*}
     */
    onSelectAll(isAllSelected) {
        const { checkedStores } = this.state;
        const { disabled } = this.props;
        if (disabled) {
            return;
        }
        const presentStores = this.getAvailableStoreKeys();
        let mergedStores = [];
        if (isAllSelected) {
            if (presentStores.length) {
                mergedStores = uniq([...checkedStores, ...presentStores]);
            } else {
                mergedStores = checkedStores;
            }
        } else if (!isAllSelected) {
            if (presentStores.length) {
                mergedStores = checkedStores.filter((store) => !presentStores.includes(store));
            } else {
                mergedStores = checkedStores;
            }
        }
        this.setState({
            checkedStores: mergedStores,
            isAllSelected
        }, this.onChange);
    }

    /**
     *
     * @param {*} groupedStores
     */
    storesTreeFormatter(groupedStores) {
        const workingStoresTree = formatStoreGroups(groupedStores);
        this.setState({
            initialStoresTree: workingStoresTree,
            workingStoresTree
        });
    }

    // returns tree that match with search string
    /**
     *
     * @param {*} searchStr
     * @return {*}
     */
    getAvailableStores(searchStr) {
        const { initialStoresTree } = this.state;
        if (!searchStr) {
            return initialStoresTree;
        }
        const filteredTree = initialStoresTree.reduce((filteredGroupsArray, group) => {
            // find stores that match with search string by StoreName or StoreNumber or Group Name
            const filteredStores = group.children.filter(({ id, name }) => {
                if (!id) {
                    return;
                }
                return ~`${name && name.toLowerCase()}`.search(searchStr.toLowerCase());
            });

            // put found stores to the mutated group for rendering result tree
            if (filteredStores && filteredStores.length) {
                const cloneGroup = { ...group };
                cloneGroup.children = filteredStores;
                filteredGroupsArray.push(cloneGroup);
            }
            return filteredGroupsArray;
        }, []);
        return filteredTree;
    }

    // returns array of store keys in present tree
    /**
     *
     * @return {*}
     */
    getAvailableStoreKeys() {
        const { searchStr } = this.state;
        return this.getAvailableStores(searchStr).reduce((stores, group) => {
            const storeUIDs = group.children.map((store) => store.id);
            if (storeUIDs && storeUIDs.length) {
                return stores.concat(storeUIDs);
            }
            return stores;
        }, []);
    }

    /**
     *
     * @param {*} stores
     */
    onStoreSelection(stores) {
        const { checkedStores } = this.state;
        const presentStores = this.getAvailableStoreKeys();
        const unselectedStores = presentStores.filter((store) => !stores.includes(store));
        const previouslySelectedStores = checkedStores.filter((store) => !unselectedStores.includes(store));
        this.setState({
            checkedStores: uniq([...previouslySelectedStores, ...stores])
        }, this.onChange);
    }

    /**
     *
     * @return {*}
     */
    isCheckAllEnabled() {
        const { actions } = this.props;
        return actions && actions.includes('selectAll');
    }

    /**
     *
     * @return {*}
     */
    isSearchInputEnabled() {
        const { filters } = this.props;
        return filters && filters.includes('search');
    }

    /**
     *
     */
    cleanCatch() {
        const { initialStoresTree, checkedStores } = this.state;
        const storeUidsInTree = getStoreUids(initialStoresTree);
        this.setState({
            checkedStores: checkedStores.filter((store) => storeUidsInTree.includes(store))
        });
    }

    // just returns results to parent component as array of selected stores
    /**
     *
     */
    onChange() {
        const { onChange } = this.props;
        const { checkedStores } = this.state;

        onChange(checkedStores);
    }

    /**
     *
     * @return {JSX}
     */
    render() {
        const { label, value, className, disabled, title, t } = this.props;
        const { isAllSelected, searchStr } = this.state;
        const presentStores = this.getAvailableStoreKeys();
        const tree = this.getAvailableStores(searchStr);
        const treeIsEmpty = !tree || !tree.length;
        if (treeIsEmpty && isAllSelected) {
            this.setState({ isAllSelected: false });
        }

        return (
            <div className={classNames(className, 'hme-input stores-selector')}>
                <div className="hme-stores-selector__fixed-height-block">
                    {/* TODO: Use css text-tranform to make text in uppercase */}
                    <b className="leaderboard-input-label">{t('add-leaderboard__search-stores')}</b>
                    <SearchInput placeholder={t('add-leaderboard__search-stores__placeholder')} value={searchStr} onChange={(value) => this.onSearchRequest(value)} />
                </div>

                { label && <b className="stores-selector__label leaderboard-input-label">{ label }</b> }

                { title && <b className="mt-5 leaderboard-input-label">{ title }</b> }
                <div className="new-groups stores-selector__wrap leaderboard-store-selection-border">
                    <div className="stores-selector__wrap__controllers mt-3">
                        {
                                this.isCheckAllEnabled() && <CheckboxInput
                                    name="selectAllAvaliableStores"
                                    disabled={disabled || treeIsEmpty}
                                    label={t('common__select-all')}
                                    value={isAllSelected}
                                    onChange={(value) => this.onSelectAll(value)}
                                />
                            }
                        </div>
                        <TreeSelectInput
                            key={searchStr}
                            flatten={Boolean(searchStr)}
                            defaultExpandAll={Boolean(searchStr)}
                            className="stores-selector__wrap__stores"
                            tree={tree}
                            value={value && value.filter((store) => presentStores.includes(store)) || []}
                            onChange={(checkedKeys) => this.onStoreSelection(checkedKeys)}
                            disabled={disabled}
                            omitRootNodes
                            strongRootNodes
                            rangeSelection
                        />
                    </div>
            </div>
        );
    }
}

StoresSelectorComponent.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    tree: PropTypes.array,
    label: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.string),
    actions: PropTypes.arrayOf(PropTypes.string)
};

export const StoresSelector = compose(
    withTranslation()
)(StoresSelectorComponent);
