import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Common/Popover/CustomPopover';

import './SourceDeviceSettingsInfo.css';

const customStyles = {
    'minWidth': '480px',
    'minHeight': '530px',
  };

export const SourceDeviceSettingsInfo = ({ open, onClose }) => {
    const { t } = useTranslation();

    return (
        <Modal visible={open} onClose={onClose} customStyles={customStyles}>
            <div className="source-device-settings-info">
                <div className="source-device-settings-info-icon"><span className='icon-info'></span></div>
                <div className="source-device-settings-info-content">
                    <span className="source-device-settings-info-title">{t('apply-device-settings-new__info__title')}</span>
                    <ul className="source-device-settings-info-list">
                        <li>{t('apply-device-settings-new__info__excluded--store-details')}</li>
                        <li>{t('apply-device-settings-new__info__excluded--hme-settings')}</li>
                        <li>{t('apply-device-settings-new__info__excluded--passwords')}</li>
                        <li>{t('apply-device-settings-new__info__excluded--network-settings')}</li>
                    </ul>
                    <span className="source-device-settings-info-title">{t('apply-device-settings-new__info__lane-settings__title')}</span>
                    <ul className="source-device-settings-info-list">
                        <li>{t('apply-device-settings-new__info__lane-settings__item-1')}</li>
                        <li>{t('apply-device-settings-new__info__lane-settings__item-2')}</li>
                        <li>{t('apply-device-settings-new__info__lane-settings__item-3')}</li>
                    </ul>
                    <span className="source-device-settings-info-title">{t('apply-device-settings-new__info__dayparts__title')}</span>
                    <ul className="source-device-settings-info-list">
                        <li>{t('apply-device-settings-new__info__dayparts__item-1')}</li>
                        <li>{t('apply-device-settings-new__info__dayparts__item-2')}</li>
                    </ul>
                    {
                        // Speed Goals will not be displayed on the initial release
                    }
                    <span className="source-device-settings-info-title">{t('apply-device-settings-new__info__speed-goals__title')}</span>
                    <ul className="source-device-settings-info-list">
                        <li>{t('apply-device-settings-new__info__speed-goals__item-1')}</li>
                        <li>{t('apply-device-settings-new__info__speed-goals__item-2')}</li>
                    </ul>
                    <span className="source-device-settings-info-title">{t('apply-device-settings-new__info__store-hours__title')}</span>
                    <ul className="source-device-settings-info-list">
                        <li>{t('apply-device-settings-new__info__store-hours__item-1')}</li>
                    </ul>
                    <span className="source-device-settings-info-title">{t('apply-device-settings-new__info__dashboard__title')}</span>
                    <ul className="source-device-settings-info-list">
                        <li>{t('apply-device-settings-new__info__dashboard__item-1')}</li>
                    </ul>
                    <span className="source-device-settings-info-title">{t('common__time-format')}</span>
                    <ul className="source-device-settings-info-list">
                        <li>{t('apply-device-settings-new__info__time-format__item-1')}</li>
                        <li>{t('apply-device-settings-new__info__time-format__item-2')}</li>
                    </ul>
                </div>
            </div>
        </Modal>
    );
};
