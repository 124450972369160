import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Checkbox, CHECKBOX_VALUES } from 'library/Checkbox';
import { CenterLoader } from 'components/Common/CenterLoader';
import { TileListItem } from './TileListItem/TileListItem';
import { SortFilterModal } from './SortFilterModal';
import { GridFiltersCount } from '../GridFiltersCount';
import { Button } from '../Button';
import { useTileList } from './hooks/useTileList';
import { mobileHeadersType } from './types';
import { availableFiltersType } from './types';
import { sortOptionsType } from './types';

import './TileList.scss';

export const TileList = forwardRef(({
    className,
    headers,
    rows,
    isLoading,
    ListItemComponent = TileListItem,
    noRecordsMessage='common__error--no-data-found',
    rowKey,
    availableFilters,
    filters,
    onFiltersChange,
    onFiltersReset,
    filtersOptions,
    filtersCount,
    sortSelection,
    onSortChange,
    isSortingDropdown,
    sortOptions,
    onFiltersAndSortChange,
    onFiltersAndSortReset,
    checkDisabled,
    sortButtonName = 'smack-talk__tiles-header--sort-n-filter',
    onRowClick,
    onSelectAll,
    radioColumn = null,
    checkSelected,
    selectable = false,
    listItemComponentProps = {}
}, ref) => {
    const { t } = useTranslation();
    const {
        isSortModalOpen,
        onSortModalOpen,
        onSortModalClose,
        isListExist,
        needToShowFilterCount,
        hasFilterSort,
    } = useTileList(rows, availableFilters, sortOptions);

    const shouldShowFiltersSort = useMemo(
        () => hasFilterSort && (isListExist || Boolean(filtersCount)),
        [hasFilterSort, isListExist, filtersCount],
    );

    const allSelectedStatus = useMemo(() => {
        if (!selectable) {
            return false;
        }

        const selected = rows.map(r => checkSelected && checkSelected(r)).filter(r => r);

        if (!selected.length) {
            return CHECKBOX_VALUES.UNCHECKED;
        }

        if (selected.length === rows.length) {
            return CHECKBOX_VALUES.CHECKED;
        }

        return CHECKBOX_VALUES.PARTIAL;
    }, [rows, checkSelected, selectable]);

    const isDisabledAll =
        rows.length > 0 && rows.reduce((result, row) => result && checkDisabled && checkDisabled(row), true);

    return <div className={classNames("hme-tile-list__wrapper", className)} ref={ref}>
        {shouldShowFiltersSort &&
            <div className="hme-tile-list__filters-sort">
                <Button
                    variants={['transparent']}
                    onClick={onSortModalOpen}
                    className="hme-tile-list__filters-sort__button"
                >{t(sortButtonName)}</Button>
                {needToShowFilterCount && <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} />}
            </div>
        }
        {selectable &&
            <div className="hme-tile-list__select-all">
                <Checkbox
                    label={t('common__select-all')}
                    checked={allSelectedStatus}
                    onChange={(value) => onSelectAll && onSelectAll(value)}
                    disabled={isDisabledAll}
                />
            </div>
        }
        {isLoading ?
            <CenterLoader>{t('common__loading')}</CenterLoader> :
            <div className="hme-tile-list">
                {
                    rows.length ?
                        <>{rows.map((row, index) =>
                            <ListItemComponent
                                key={rowKey ? row[rowKey] : index}
                                headers={headers}
                                item={row}
                                radioColumn={radioColumn}
                                onRowClick={onRowClick}
                                isSelected={checkSelected && checkSelected(row)}
                                isDisabled={checkDisabled && checkDisabled(row)}
                                {...listItemComponentProps}
                            />)}
                        </> :
                        <div className="hme-tile-list__no-records">{t(noRecordsMessage)}</div>
                }
            </div>
        }
        <SortFilterModal
            isSortModalOpen={isSortModalOpen}
            isSortingDropdown={isSortingDropdown}
            onSortModalClose={onSortModalClose}
            availableFilters={availableFilters}
            filters={filters}
            onFiltersChange={onFiltersChange}
            filtersOptions={filtersOptions}
            sortSelection={sortSelection}
            onSortChange={onSortChange}
            sortOptions={sortOptions}
            onFiltersAndSortChange={onFiltersAndSortChange}
            onFiltersAndSortReset={onFiltersAndSortReset}
        />
    </div>;
});

TileList.displayName = 'TileList';

TileList.propTypes = {
    className: PropTypes.string,
    headers: mobileHeadersType.isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool,
    ListItemComponent: PropTypes.elementType,
    noRecordsMessage: PropTypes.string,
    rowKey: PropTypes.string,
    availableFilters: availableFiltersType,
    filters: PropTypes.shape({}),
    onFiltersChange: PropTypes.func,
    onFiltersReset: PropTypes.func,
    filtersOptions: PropTypes.shape({}),
    filtersCount: PropTypes.number,
    sortSelection: PropTypes.shape({}),
    onSortChange: PropTypes.func,
    isSortingDropdown: PropTypes.bool,
    sortOptions: sortOptionsType,
    onFiltersAndSortReset: PropTypes.func,
    onFiltersAndSortChange: PropTypes.func,
    onSelectAll: PropTypes.func,
    checkSelected: PropTypes.func,
    checkDisabled: PropTypes.func,
    sortButtonName: PropTypes.string,
    selectable: PropTypes.bool
};
