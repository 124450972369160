import React, { Fragment } from 'react';

import HMECLOUDLogo from '../../../images/hmecloud-logo.svg';

export const HOME_TAB_NAME = 'welcome';

const ClientDesktopLogo = () => <img width="144px" height="20px" className='hidden-xxs-up' src={HMECLOUDLogo} alt="HME Logo" />;
const ClientMobileLogo = () => <img width="144px" height="20px" className='hidden-xxs-down' src={HMECLOUDLogo} alt="HME Logo" />;

export const PartnerTitle = () => {
    const mobileLogo = <ClientMobileLogo />;

    return <Fragment>
        <ClientDesktopLogo />
        {mobileLogo}
    </Fragment>;
};
