import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'library/Button';

import { ReactComponent as Pencil } from 'assets/icons/IconEditBlue.svg';

import './ViewEditAction.scss';

const buttonVariants = ['transparent'];

export const ViewEditAction = ({ url, onConfirmModalOpen, uuid, isOwner, hasManageUserAccess }) => {
    const { t } = useTranslation();

    const onRemoveButtonClick = useCallback(() => onConfirmModalOpen(uuid), [onConfirmModalOpen, uuid]);

    return (
        <>
            {hasManageUserAccess &&
                <Link to={url} className="hme-users-list--action--edit--user">
                    <Pencil className="hme--users--edit-icon" />
                    <Button variants={buttonVariants}>{t('common__view-edit')}</Button>
                </Link>
            }
            {hasManageUserAccess && !isOwner &&
                <>
                    <div className="hme-users-list--actions--separator" />
                    <div className="hme-users-list--action--remove--user">
                        <Button
                            variants={buttonVariants}
                            onClick={onRemoveButtonClick}
                        >{t('common__remove')}</Button>
                    </div>
                </>
            }
        </>
    );
};

ViewEditAction.propTypes = {
    url: PropTypes.string,
    onConfirmModalOpen: PropTypes.func,
    uuid: PropTypes.string,
    hasManageUserAccess: PropTypes.bool,
    isOwner: PropTypes.bool,
};
