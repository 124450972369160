import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useOnTrigger } from 'hooks/useTrigger';
import { pluck } from 'ramda';
import { Grid, resetFilters, getFiltersCount } from 'components/Common/Grid';

import './AccountsList.scss';

const ALL_COMPANY_TYPES_FILTER_TEXT = 'All Company Types';
const ALL_COUNTRIES_FILTER_TEXT = 'All Countries';
const ALL_ACCOUNT_STATUSES_FILTER_TEXT = 'All Statuses';
const ALL_SUBSCRIPTION_TYPES_FILTER_TEXT = 'All Subscription Types';

const filterOptions = {
    Account_IsActive: {
        allText: ALL_ACCOUNT_STATUSES_FILTER_TEXT,
    },
    Country_Name: {
        allText: ALL_COUNTRIES_FILTER_TEXT,
    },
    Company_Type: {
        allText: ALL_COMPANY_TYPES_FILTER_TEXT,
    },
    Subscription_Name: {
        allText: ALL_SUBSCRIPTION_TYPES_FILTER_TEXT,
    },
};

const gridOptions = [
    {
        text: 'common__account__email',
        property: 'User_EmailAddress',
        flex: 244,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__company__name',
        property: 'Company_Name',
        flex: 198,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'admin__accounts__grid__header--company-type',
        property: 'Company_Type',
        flex: 185,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__country',
        property: 'Country_Name',
        flex: 183,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'admin__accounts__grid__header--dealer-distributor',
        property: 'distributor',
        className: 'hme-grid-cell-show-all-content',
        flex: 228,
        sortable: true,
    },
    {
        text: 'admin__distributors__grid__header--subscription',
        property: 'Subscription_Name',
        flex: 198,
        headerClassName: 'hme-grid-filter-align-right',
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'admin__accounts__grid__header--account-status',
        property: 'Account_IsActive',
        flex: 137,
        headerClassName: 'hme-grid-centered-cell hme-grid-filter-align-right',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
        sortable: true,
    },
    {
        text: 'common__actions',
        property: 'Actions',
        flex: 152,
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
];

const getEditUrl = (companyType, userUID) => `/admin/account?type=${companyType || 'null'}&uuid=${userUID}`;
const getDistributorEditUrl = (distributorUID) => `/admin/account?type=distributor&useruid=${distributorUID}`;

const formatAvailableFilters = (filters) =>
    Object.keys(filters)
        .filter((property) => filterOptions[property])
        .map((property) => ({
            allText: filterOptions[property].allText,
            items: filters[property],
            property,
        }));

const formatGridFilters = (availableFilterList) =>
    availableFilterList.reduce((filtersObject, { property, items }) => {
        filtersObject[property] = pluck('value', items);

        return filtersObject;
    }, {});

const accountsToRows = ({ accounts, t }) =>
    accounts.map((account) => ({
        ...account,
        Account_IsActive: account.Account_IsActive ? t('common__active') : t('common__inactive'),
        distributor: <Link to={getDistributorEditUrl(account.Distributor_UID)}>{account.distributor}</Link>,
        Actions: <Link to={getEditUrl(account.Company_Type, account.User_UID)}>{t('common__view-edit')}</Link>,
    }));

export const AccountsList = ({
    accounts,
    filters,
    isLoading,
    sortSelection,
    onSortChange,
    onFiltersChange,
    onFiltersCountChange,
    resetFiltersTrigger,
}) => {
    const { t } = useTranslation();
    const [rows, setRows] = useState([]);
    const [availableFilters, setAvailableFilters] = useState([]);
    const [isRendering, setIsRendering] = useState(false);
    const [gridFilters, setGridFilters] = useState({});

    const rowsMemo = useMemo(() => accountsToRows({ accounts, t }), [accounts, t]);
    
    const onFiltersReset = useCallback(() => {
        resetFilters(availableFilters, setGridFilters);
    }, [availableFilters, setGridFilters]);

    useOnTrigger(resetFiltersTrigger, onFiltersReset);

    useEffect(() => {
        setIsRendering(true);
        setRows(rowsMemo);
    }, [rowsMemo, setIsRendering, setRows]);

    useEffect(() => {
        if (filters) {
            const availableFilterList = formatAvailableFilters(filters);

            setAvailableFilters(availableFilterList);
            setGridFilters(formatGridFilters(availableFilterList));
        }
    }, [filters]);

    const onFiltersChangeHandler = useCallback(
        (gridFilters) => {
            onFiltersChange(gridFilters);
            setGridFilters(gridFilters);
        },
        [setGridFilters, onFiltersChange],
    );

    useEffect(() => {
        onFiltersCountChange && onFiltersCountChange(getFiltersCount(availableFilters, gridFilters));
    }, [availableFilters, gridFilters]);

    useEffect(() => {
        setIsRendering(false);
    }, [rows]);

    return (
        <div className="accounts-list">
            <Grid
                headers={gridOptions}
                rows={rows}
                isLoading={isLoading || isRendering}
                rowKey="User_ID"
                noRecordsMessage={t('admin__accounts__no-accounts-found')}
                onSortChange={onSortChange}
                sortSelection={sortSelection}
                availableFilters={availableFilters}
                filters={gridFilters}
                onFiltersChange={onFiltersChangeHandler}
            />
        </div>
    );
};
