import { useState, useEffect, useCallback } from 'react';
import { scheduledUpgradesApi } from '../Controller';

export const useLoadDeviceScheduledUpgrade = (deviceUID) => {
    const [deviceScheduledUpgrade, setDeviceScheduledUpgrade] = useState(null);
    const [isDeviceScheduledUpgradeLoading, setIsDeviceScheduledUpgradeLoading] = useState(true);
    const [deviceScheduledUpgradeError, setDeviceScheduledUpgradeLoadingError] = useState(null);

    const loadDeviceScheduledUpgrade = useCallback(async (duid) => {
        if (!duid) return;

        try {
            setIsDeviceScheduledUpgradeLoading(true);
            setDeviceScheduledUpgradeLoadingError(null);
            const deviceSettings = (await scheduledUpgradesApi.getLatestScheduledUpgradeByDeviceUID({ deviceUID: duid })) || null;

            setDeviceScheduledUpgrade(deviceSettings);
        } catch (e) {
            console.error('Error while loading device scheduled upgrade', e);
            setDeviceScheduledUpgrade(null);
            setDeviceScheduledUpgradeLoadingError(e);
        }

        setIsDeviceScheduledUpgradeLoading(false);
    }, []);

    useEffect(() => { loadDeviceScheduledUpgrade(deviceUID); }, [deviceUID]);

    return { deviceScheduledUpgrade, isDeviceScheduledUpgradeLoading, deviceScheduledUpgradeError, loadDeviceScheduledUpgrade };
};
