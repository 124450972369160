import React, { useCallback, useMemo } from 'react';
import { Grid } from 'components/Common/Grid';
import { Button } from 'library/Button';
import { useTranslation } from 'react-i18next';
import { CommonConstants } from 'Constants';
import { DateLib } from '@hme-cloud/utility-common';
import { deviceTypes } from 'constants/device';
import { NEXEO_PERIPHERAL_TYPES } from 'constants/NEXEOPeripheralTypes';

import { nexeoUpgradeStatus, upgradeStatuses } from '../../../constants';
import { checkIsNexeoTwoStepUpgrade } from '../../../helpers';

import './DevicesList.css';

const { CANCELLED, FAILED, SCHEDULED, SENT, IN_PROGRESS, PENDING_APPROVAL } = upgradeStatuses;

const baseGridHeaders = [
    {
        text: 'device-upgrades__grid__header--account',
        property: 'AccountOwnerEmailAddress',
        flex: 125,
        sortable: true,
    },
    {
        text: 'device-upgrades__grid__header--brand',
        property: 'BrandName',
        className: 'hme-grid-cell-show-all-content',
        flex: 100,
        sortable: true,
    },
    {
        text: 'device-upgrades__grid__header--store-number',
        property: 'StoreNumber',
        className: 'hme-grid-cell-show-all-content',
        flex: 90,
        sortable: true,
    },
    {
        text: 'device-upgrades__grid__header--store-name',
        property: 'StoreName',
        className: 'hme-grid-cell-show-all-content',
        flex: 100,
        sortable: true,
    },
    {
        text: 'common__device__serial-number',
        property: 'DeviceSerialNumber',
        className: 'hme-grid-cell-show-all-content',
        flex: 110,
        sortable: true,
    },
    {
        text: 'common__device__product-id',
        property: 'DeviceProductID',
        className: 'hme-grid-cell-show-all-content',
        flex: 110,
        sortable: true,
    },
    {
        text: 'device-upgrades__grid__header--status',
        property: 'Status',
        flex: 115,
        sortable: true,
    },
    {
        text: 'Attempts',
        property: 'Attempts',
        className: 'hme-grid-cell-show-all-content',
        flex: 80,
        sortable: true,
    },
    {
        text: 'device-upgrades__grid__header--sent-date-time',
        property: 'UpgradeLastSent',
        className: 'hme-grid-cell-show-all-content',
        flex: 100,
        sortable: true,
    },
    {
        text: 'device-upgrades__grid__header--completed-date-time',
        property: 'CompletedDate',
        className: 'hme-grid-cell-show-all-content',
        flex: 100,
        sortable: true,
    },
    {
        text: 'device-upgrades__grid__header--actions',
        property: 'actions',
        className: 'hme-grid-cell-show-all-content',
        flex: 80,
    },
];

const getGridHeaders = (deviceType) => {
    return deviceType === CommonConstants.deviceType.nexeo.name.toLowerCase()
        ? baseGridHeaders
        : baseGridHeaders.filter((header) => header.property !== 'DeviceProductID');
};

export const DevicesList = ({
    devices,
    deviceType,
    upgradeStatus,
    onCancelUpgrade,
    selectedDevices,
    isLoading = false,
    onDeviceSelection,
}) => {
    const { t } = useTranslation();
    const gridHeaders = getGridHeaders(deviceType);

    const getStatus = (device) => {
        const nexeoName = CommonConstants.deviceType.nexeo.name.toLowerCase();
        const nexeoStatus = device.NexeoUpgradeStatus;
        const status = nexeoStatus ? nexeoUpgradeStatus[nexeoStatus] : device.Status;

        // Override nexeo status if cancelled
        if (device.Status === CANCELLED) return CANCELLED;

        // Handle status for nexeos that've never been attempted
        if (deviceType === nexeoName && device.RetryCount == 0) return '';

        return status === FAILED ? (
            <span className="upgrade-devices-list--failed-status">{status}</span>
        ) : (
            status
        );
    };

    const checkIfShowActions = useCallback(
        (device) =>
            device.Status !== CANCELLED &&
            (upgradeStatus === SCHEDULED ||
                (device.NexeoUpgradeStatus === 6 && device.RetryCount < 3)),
        [upgradeStatus],
    );

    const shouldShowActions = useMemo(
        () => devices.reduce((result, device) => result || checkIfShowActions(device), false),
        [devices, checkIfShowActions],
    );

    const checkIsSelectable = useCallback(
        (deviceUpgrade) => {
            const isNexeoPeripheralDevice = [NEXEO_PERIPHERAL_TYPES.IB7000.modelName].includes(deviceType);
            const isNexeoTwoStepUpgrade = checkIsNexeoTwoStepUpgrade({
                deviceVersion: deviceUpgrade?.InitialDeviceVersion,
                deviceType: isNexeoPeripheralDevice ? deviceTypes.NEXEO : deviceType,
                peripheralDeviceType: deviceType,
            });

            if (!isNexeoTwoStepUpgrade) {
                return [SCHEDULED].includes(deviceUpgrade.Status);
            }

            return (
                [SCHEDULED, IN_PROGRESS, SENT, PENDING_APPROVAL].includes(deviceUpgrade.Status) ||
                (deviceUpgrade.Status === FAILED && deviceUpgrade.RetryCount < 3)
            );

        },
        [deviceType],
    );

    const rows = useMemo(
        () =>
            devices.map((device) => ({
                ...device,
                isSelectable: checkIsSelectable(device),
                Status: getStatus(device),
                UpgradeLastSent: device.UpgradeLastSent
                    ? new DateLib(device.UpgradeLastSent).format(`${DateLib.FORMAT_TYPES.DATE_SELECTOR_DEFAULT} ${DateLib.FORMAT_TYPES.SHORTENED_HOURS_MINUTES}`)
                    : '',
                CompletedDate: device.CompletedDate
                    ? new DateLib(device.CompletedDate).format(`${DateLib.FORMAT_TYPES.DATE_SELECTOR_DEFAULT} ${DateLib.FORMAT_TYPES.SHORTENED_HOURS_MINUTES}`)
                    : '',
                Attempts: `${device.RetryCount || 0} of 3`,
                actions: checkIfShowActions(device) ? (
                    <Button
                        id={device.DeviceUpgradeID}
                        variants={['transparent']}
                        onClick={() => onCancelUpgrade([device.DeviceUpgradeID])}
                    >
                        {t('common__cancel')}
                    </Button>
                ) : null,
            })),
        [devices, checkIsSelectable],
    );

    const gridHeadersRows = useMemo(
        () => (shouldShowActions ? gridHeaders : gridHeaders.filter((gh) => gh.property !== 'actions')),
        [gridHeaders, shouldShowActions],
    );

    return (
        <div className="upgrade-devices-list hme-components">
            <Grid
                selectable
                headers={gridHeadersRows}
                rows={rows}
                isLoading={isLoading}
                rowKey="Store_Number"
                noRecordsMessage={t('common__no-devices--found')}
                selection={selectedDevices}
                onSelectionChange={onDeviceSelection}
            />
        </div>
    );
};
