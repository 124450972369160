/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import './User.css';
import { confirmAlert } from 'react-confirm-alert';
import Tree, { TreeNode } from 'rc-tree';
import { Config } from '../../Config';
import { CommonConstants } from '../../Constants';
import Api from '../../Api';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ErrorAlert from '../Alerts/ErrorAlert';
import moment from 'moment';
import 'url-search-params-polyfill';
import { setUserEmail } from '../../actions/headers';
import { connect } from 'react-redux';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { bindActionCreators } from 'redux';
import CommonLoader from '../Alerts/CommonLoader';
import { SaveUsers, sortActivity, getActivity } from '../../actions/users';
import grayDesc from '../../images/gray-desc.jpg';
import grayAsc from '../../images/gray-asc.jpg';
import unsorted from '../../images/gray-unsorted.jpg';
import { getPermission } from '../../actions/permissions';
import AuthFactory from '../../helpers/AuthFactory';
import { Button } from 'library/Button';
import { resendActivationEmail } from 'services/Account';
import { NotificationsList } from 'library/NotificationsList';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { isDistributor } from 'services/Auth';
import { checkAccess } from 'actions/permissions';
import 'rc-tree/assets/index.css';

import { withReactRouter } from 'HOCs/withReactRouter';
import { getQueryString } from 'helpers/distributor';
const _ = require('underscore');

const serverErrorTKey = {
    'noDataFound': 'common__error--no-data-found',
    'userupdateFailure': 'add-user__error--update-failed',
    'usercreateFail': 'add-user__error--create-failed',
    'usercreateFailure': 'add-user__error--create-failed',
    'userAlreadyExist': 'add-user__error--user-exists',
    'firstNameEmpty': 'add-user__error--first-name-empty'
};

const BASE_URL = Config.apiBaseUrl;

/**
 * User component
 */
class User extends Component {
    /**
   * Set initial state
   * @param {*} props
   */
    constructor(props) {
        super(props);

        this.api = new Api();
        this.authService = AuthFactory.AuthService;
        this.permissionSpecification = AuthFactory.PermissionSpecification;

        this.state = {
            userEmail: null,
            firstName: null,
            lastName: null,
            isActive: 1,
            isAccountOwner: 0,
            resetPassword: 0,
            isVerified: 1,
            userRole: null,
            uuids: [],
            showRemoveUser: null,
            isEdit: false,
            selectAll: false,
            deleteErrorMessage: 'add-user__error--unable-delete-group-data',
            errorMessage: '',
            errors: '',
            stores: [],
            groups: [],
            disableViewAs: false,
            showCommonLoader: false,
            Account_TOS_Agree: 0,
            isSuccess: false,
            isDeletedSuccess: false,
            Ascending: true,
            noStores: false,
            isAdmin: this.authService.isAdmin(),
            uuid: null,
            accountUuid: null,
            hasDistributorUserAccess: false,
        };

        this.submit = this.submit.bind(this);
    }

    /**
     * Using React lifecycle method and set the state value
     */
    UNSAFE_componentWillMount() {
        const { queryParams } = this.props;
        const uuid = queryParams.get('uuid');
        const accountUuid = queryParams.get('auuid');

        this.setState({
            showCommonLoader: true,
            uuid,
            accountUuid,
            hasDistributorUserAccess: !!accountUuid && isDistributor() &&
                checkAccess(CommonConstants.externalPermissions.ManageUsers),
        });

        this.props.getActivity(uuid, () => {});

        let urlRoles = '';
        // We use accountUuid for Distributors
        if (accountUuid) {
            urlRoles = `${BASE_URL}${CommonConstants.apiUrls.getDistributorAccountRoles}?auuid=${accountUuid}`;
        } else {
            urlRoles = `${BASE_URL}${CommonConstants.apiUrls.getUserRoles}`;
            if (this.state.isAdmin || this.authService.isMasquerade()) {
                urlRoles += '?uuid=' + uuid;
            }
        }

        // call to be moved to action
        this.api.getData(urlRoles, (data) => {
            this.setState({ roles: data.data });
        });

        // To load the user details, if Edit (UUID)
        this.load(uuid, () => {
            if (uuid) {
                this.setState({ isEdit: true });
                // call to be moved to action

                let url = '';

                // We use accountUuid for Distributors
                if (accountUuid) {
                    url = `${BASE_URL}${CommonConstants.apiUrls.getDistributorAccountUser}?auuid=${accountUuid}&uuId=${uuid}`;
                } else {
                    url = `${BASE_URL}${CommonConstants.apiUrls.getUser}?uuId=${uuid}`;
                }
                this.api.getData(url, (data) => {
                    const userObject = data.data;
                    this.authService.setUserEmail(userObject.userEmail);
                    this.props.setUserEmail(userObject.userEmail);
                    const treeData = this.state.treeData;

                    const {
                        userEmail,
                        firstName,
                        lastName,
                        resetPassword,
                        isVerified,
                        isActive,
                        isAccountOwner,
                        userRole
                    } = userObject;

                    this.setState({
                        uuid,
                        userEmail,
                        firstName,
                        lastName,
                        resetPassword,
                        isVerified,
                        isActive,
                        isAccountOwner,
                        disableViewAs: parseInt(userObject.isActive) === 1,
                        userRole,
                        Account_TOS_Agree: userObject.Account_TOS_Agree,
                        user: userObject
                    });

                    if (treeData) {
                        const keys = this.findMatchedIds(treeData, (item) => {
                            return (
                                item.Type === 'store' &&
                userObject.storeIds.indexOf(item.StoreUid.toString()) > -1
                            );
                        });
                        const stores = this.findMatchedClassName(treeData, (item) => {
                            return item.Type === 'store' && _.contains(userObject.storeIds, item.StoreUid);
                        });

                        this.setState({
                            defaultCheckedKeys: keys.map(String),
                            stores,
                            groups: R.pluck('Group_ID', userObject.groupIds)
                        });
                    }
                    this.setState({ showCommonLoader: false });
                }
                );
            } else {
                this.setState({ isEdit: false, showCommonLoader: false });
            }
        });
        this.props.sortActivity({ 'sortBy': '', 'sortType': '' });
    }

    /**
     * This Function will fetch details of stores and groups
     * @param {*} uuid
     * @param {*} callback
     */
    load(uuid, callback) {
    // OLD URL
    // let url = Config.apiBaseUrl + CommonConstants.apiUrls.getGroupHierarchyTree + '?showDualLane=' + true
        const { accountUuid } = this.state;
        const zoomStoresOnly = '?zoomStoresOnly=false';

        let groupUrl = '';

        // We use accountUuid for Distributors
        if (accountUuid) {
            groupUrl = `${BASE_URL}${CommonConstants.apiUrls.getDistributorAccountGroups}${zoomStoresOnly}&auuid=${accountUuid}`;
        } else {
            groupUrl = `${BASE_URL}${CommonConstants.apiUrls.getGroupHierarchyTree}${zoomStoresOnly}`;
            if (this.state.isAdmin || this.authService.isMasquerade()) {
                groupUrl += '&uuid=' + uuid;
            }
        }
        // get Tree hierarchy - stores and groups
        // call to be moved to action
        this.api.getData(groupUrl, (data) => {
            if (data.status) {
                this.setState({ noStores: data.data.length === 0 });
                if (data.data.length !== 0) {
                    const groupHierarchy = this.groupHierarchy(data.data, this.groupHierarchyCompleted);

                    this.setState({ treeData: groupHierarchy });
                    callback();
                }
                //  this.state.showCommonLoader = false
            } else {
                this.setState({ noStores: true });
                callback();
            }
            // OLD Method
            // this.state.treeData = data.data
            // this.setState(this.state)
            // callback()
        });
    }

    /**
     * On group hierarchy completed
     * @param {*} data
     * @param {*} instance
     */
    groupHierarchyCompleted(data, instance) {
        instance.setState({ treeData: data });
        const stores = instance.findMatchedClassName(data, (item) => {
            return item.Type === CommonConstants.Type.Store;
        });
        instance.setState({ noStores: stores.length === 0 });
    }

    /**
     * Build hierarchy
     * @return {object} hierarchy
     * @param {*} data
     * @param {*} callback
     */
    groupHierarchy(data, callback) {
        const findstore = (item) => {
            if (item.Children && item.Children.length) {
                return item.Children.find(findstore);
            } else {
                return item.Type === 'store';
            }
        };
        const gourpHierarchydata = (data, level = 0, parent = null) => {
            return data.map((item) => {
                if (item.Children && item.Children.length) {
                    gourpHierarchydata(item.Children, level + 1, item);
                    if (item['className'] !== 'show' && !item.Children.find(findstore)) {
                        item['className'] = 'hide';
                    }
                } else if (item.Type === 'store' && parent !== null || item.className) {
                    parent['className'] = 'show';
                } else {
                    item['className'] = 'hide';
                }
                return item;
            });
        };
        const result = gourpHierarchydata(data);
        callback(result, this);

        return result;
    }

    /**
     * On check of check box device Id will be noted
     * @param {array} checkedKeys;
     * @param {object} node
     */
    onCheck(checkedKeys, node) {
        const stores = _.pluck(_.where(node.checkedNodes, { type: 'store' }), 'className');
        const groups = R.reduce((groups, node) => {
            if (node.type === 'group' && node.children && node.children.find((node) => node.type === 'store')) {
                return [...groups, node.groupId];
            }
            return groups;
        }, [], node.checkedNodes);
        const deviceUIds = _.pluck(_.where(node.checkedNodes, { type: 'store' }), 'value');

        this.setState({
            defaultCheckedKeys: checkedKeys,
            stores,
            groups,
            deviceUIds
        });
    }

    /**
     * Render remove button
     * @return {JSX} remove button template
     */
    renderRemoveButton() {
        const { hasManageUserPermission } = this.permissionSpecification;
        const { t } = this.props;
        const { hasDistributorUserAccess } = this.state;
        // rendering the remove button based on the RemoveUser Permission and Edit.
        if ((hasManageUserPermission || hasDistributorUserAccess) && this.state.isEdit) {
            if (!this.state.isAccountOwner  && this.state.uuid!==this.authService.getLoggedInUserUID()) {
                return (
                    <div>
          &nbsp;|&nbsp;<a className="cancel_butt" id="remove" onClick={() => {
                            this.deleteUser();
                        }} >{t('add-user__remove-btn')}</a>
                        <br />
                        {this.state.isAdmin}
                    </div>
                );
            }
        }
        return '';
    }

    /**
     * Render save cancel button
     * @return {JSX} template
     */
    renderSaveCancelButton() {
        let showActionButtons = false;
        const { hasManageUserPermission } = this.permissionSpecification;
        const { hasDistributorUserAccess } = this.state;
        if (this.state.isEdit ) {
            if ((hasDistributorUserAccess || this.state.isAccountOwner != 1 || this.state.isAdmin ||
                this.authService.isMasquerade()) && this.state.uuid!==this.authService.getLoggedInUserUID()) {
                showActionButtons = hasManageUserPermission || hasDistributorUserAccess;
            }
        } else {
            showActionButtons = hasManageUserPermission || hasDistributorUserAccess;
        }
        // console.log('this.state.isEdit', this.state.isEdit)
        // rendering the save and cancel button based on the AddUser, EditUser Permission.
        const { t } = this.props;
        if (showActionButtons) {
            return (
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <input type="button" value={t('common__save')} onClick={() => {
                                        this.submit();
                                    }} />
                                </td>
                                <td>
                    &nbsp;|&nbsp;<a className="cancel_butt" onClick={() => {
                                        this.clearUser();
                                    }} >{t('common__cancel')}</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return <div />;
        }
    }

    /**
     * Handling the add/update user successMessage.
     * @return {JSX} template
     */
    handleSuccessMessage() {
        const { isEdit, userEmail } = this.state;
        const { t } = this.props;
        return (
            <div className="successMessage">
                <h4 style={{ color: '#009933' }}>{t('common__record-saved')}</h4>
                {/* Handling the willrecieveemail message if the user is adding a new user. */}
                {!isEdit ? <p style={{ 'font-size': '18px' }}>
                    <Trans i18nKey="add-user__recieve-email">
                        <span style={{ color: 'black' }}>The user</span>
                        <span style={{ color: '#009933' }}>{{ userEmail }}</span>
                        <span style={{ color: 'black' }}>will receive an email</span>
                    </Trans>
                </p> : '' }
            </div>
        );
    }


    resendActivationEmail = async () => {
        const { userEmail } = this.state;
        if (userEmail) {
            this.setState({ showCommonLoader: true });
            try {
                const successMessage = await resendActivationEmail(userEmail);
                addSuccessNotification(successMessage);
            } catch (error) {
                addErrorNotification(error.message);
            } finally {
                this.setState({ showCommonLoader: false });
            }
        } else {
            addErrorNotification('admin__user__no__uuid-error');
        }
    };

    /**
     * Main render function
     * @return {JSX} component template
     */
    render() {
        const { userEmail, firstName, lastName, isAdmin, Account_TOS_Agree: isTOSAccepted, isAccountOwner } = this.state;
        const isNeedToShowResendEmailLink = Boolean(isAdmin && !isTOSAccepted && !isAccountOwner);

        const { t } = this.props;

        const loop = (data, level = 0) => {
            if (!data) return null;

            return data.map((item) => {
                const { Id: itemID, Type: type, className, StoreUid: storeUID } = item;
                const title = this.renderStoresAndBrand(item, level);

                const commonProps = {
                    title,
                    key: itemID,
                    type,
                    groupId: itemID
                };

                if (item.Children && item.Children.length) {
                    return (
                        <TreeNode {...commonProps} className={`${storeUID} ${className}`} value={type === CommonConstants.Type.Store ? storeUID : null} >
                            {loop(item.Children)}
                        </TreeNode>
                    );
                } else {
                    if (item.Type === 'store') {
                        return <TreeNode {...commonProps} className={storeUID} value={item.Type === CommonConstants.Type.Store ? item.DeviceUID : null} />;
                    } else {
                        return <TreeNode {...commonProps} className={className ? className : ''} value={type === CommonConstants.Type.Store ? item.DeviceUID : null} />;
                    }
                }
            });
        };

        return <section className="editUser">
            <NotificationsList />
            <CommonLoader showLoader={this.state.showCommonLoader} message="common__loading" />
            {/* This condition will handle the different success message for adding, updating and deleting the user. */}
            <div className={'successMessage ' + (this.state.isSuccess ? 'show' : 'hide')}>
                {this.state.isDeletedSuccess ? <h4 style={{ color: '#009933' }}>{t('common__success--record-deleted')}</h4> : this.handleSuccessMessage()}
            </div>
            <div className={this.state.showCommonLoader || this.state.isSuccess ? 'hide' : 'show'}>
                <div className="userDetails">
                    <div className="col1">
                        <div className="forms clear">
                            <div className="row content-middle">
                                <h3 className="col-6">{t('add-user__title')}</h3>
                                <div className="col-lg-6 col-md-6 content-end">{this.renderViewAs()}</div>
                            </div>
                            <ErrorAlert errorMessage={this.state.errorMessage} errors={this.state.errors} />
                            <form className="row" onSubmit={() => {
                                this.submit();
                            }} >
                                <div className="col-12 col-md-6">

                                    <table className="user_form">
                                        <tbody>
                                            <tr className="userDetailsRows">
                                                <th className="block"><label>{t('add-user__label--username')} <span className="req" /></label></th>
                                                <td className="block"><input type="text" name="userEmail" value={userEmail} onChange={(e) => {
                                                    this.handleOnChange(e);
                                                }} /></td>
                                                <td>
                                                    {this.renderRemoveButton()}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="block"><label>{t('common__user__first-name')} <span className="req" /></label></th>
                                                <td className="block"><input type="text" maxLength="100" name="firstName" value={firstName} onChange={(e) => {
                                                    this.handleOnChange(e);
                                                }} /></td>
                                            </tr>
                                            <tr>
                                                <th className="block"><label>{t('common__user__last-name')} <span className="req" /></label></th>
                                                <td className="block"><input type="text" maxLength="100" name="lastName" value={lastName} onChange={(e) => {
                                                    this.handleOnChange(e);
                                                }} /></td>
                                            </tr>
                                            <tr>
                                                <th className="block"><label>{t('common__status')} <span className="req" /></label></th>
                                                <td className="block userStatus">
                                                    <input type="radio" name="isActive" value={1} checked={this.state.isActive === 1} onClick={(e) => {
                                                        this.handleRadioChange(e);
                                                    }} />
                                                    <span id="active_inactive" className="statusText"><span>{t('common__active')}</span>&nbsp;&nbsp;</span>
                                                    <input type="radio" name="isActive" value={0} checked={this.state.isActive === 0} onClick={(e) => {
                                                        this.handleRadioChange(e);
                                                    }} /><span className="statusText">{t('common__inactive')}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 col-md-6 content-end remove-end">
                                    <table className="user_form">
                                        <tbody>
                                            {
                                                isNeedToShowResendEmailLink && <tr>
                                                    <th/>
                                                    <th className="hme-user-form__resend__activation__email">
                                                        <Button
                                                            className="hme-user-form__resend__activation__email__button"
                                                            variants={['transparent']}
                                                            onClick={this.resendActivationEmail}
                                                        >
                                                            {t('admin__user__resend-activation-email')}
                                                        </Button>
                                                    </th>
                                                </tr>
                                            }
                                            <tr>
                                                <th className="block"><label>{t('add-user__label--access-level')} <span className="req" /></label></th>
                                                <td className="block">
                                                    <select name="userRole" hidden={this.state.isAccountOwner} className="wide_select" value={this.state.userRole} onChange={(e) => {
                                                        this.handleOnChange(e);
                                                    }}>
                                                        <option value="" selected={!this.state.userRole} />
                                                        {this.renderOptions()}
                                                    </select>
                                                    <select hidden={!this.state.isAccountOwner} className="wide_select" value={'Account Owner'} disabled >
                                                        <option value="Account Owner" label={'Account Owner'} selected={true} />
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr className="block" >
                                                <th className="multi_select block">
                                                    <div className=""><span><label>{t('add-user__label--store-access')}</label></span></div>
                                                    <div><span className="select_all">{t('add-user__label--select-apply')}</span></div></th>
                                                <td>
                                                    <div className="form_cbox">
                                                        <Tree
                                                            className="myCls"
                                                            showIcon={false}
                                                            showLine
                                                            checkable={!this.state.isAccountOwner}
                                                            selectable={false}
                                                            defaultSelectedKeys={this.state.defaultSelectedKeys}
                                                            defaultCheckedKeys={this.state.defaultCheckedKeys}
                                                            onSelect={this.onSelect}
                                                            onCheck={this.onCheck.bind(this)}
                                                            checkedKeys={this.state.defaultCheckedKeys}
                                                        >
                                                            {loop(this.state.treeData)}
                                                        </Tree>

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="block" />
                                                <td className="block" colSpan="6">
                                                    <div className="select_deselect"><a onClick={() => {
                                                        this.selectAll();
                                                    }} className="cancel_butt" id="select" hidden={this.state.isAccountOwner}>{t('common__select-all')}</a>
                                                    <span hidden={this.state.isAccountOwner}>&nbsp;|&nbsp;</span>
                                                    <a className="cancel_butt" onClick={() => {
                                                        this.deSelectAll();
                                                    }} id="select-none"  hidden={this.state.isAccountOwner}>{t('common__deselect-all')}</a></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                            <div className="col-12">
                                {this.renderSaveCancelButton()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'ctable ' + (this.state.isAdmin ? 'show' : 'hidden')}>
                    <div className="users listingAdmin">
                        <div className="activityLog"> User Activity Log </div>
                        <div className="ctable">
                            <Table>
                                <Thead>
                                    <Tr className="theader">
                                        <Th className="fixedHeader">
                                            <div onClick={this.sortActivity.bind(this, 'lastLogin')} className="activityHeader"><div id="lastLogin">Date/Time</div> <div><img src={(this.props.users.sortParamsActivity.sortBy === 'lastLogin' ? this.props.users.sortParamsActivity.sortType === 'ASC' ? grayAsc : grayDesc : unsorted)} alt="sort icon" /> </div> </div>
                                        </Th>
                                        <Th className="fixedHeader">
                                            <div onClick={this.sortActivity.bind(this, 'page')} className="activityHeader"><div>Page</div> <div><img src={(this.props.users.sortParamsActivity.sortBy === 'page' ? this.props.users.sortParamsActivity.sortType === 'ASC' ? grayAsc : grayDesc : unsorted)} alt="sort icon" /></div> </div>
                                        </Th>
                                        <Th className="fixedHeader">
                                            <div onClick={this.sortActivity.bind(this, 'action')} className="activityHeader"><div>Action</div> <div><img src={(this.props.users.sortParamsActivity.sortBy === 'action' ? this.props.users.sortParamsActivity.sortType === 'ASC' ? grayAsc : grayDesc : unsorted)} alt="sort icon" /></div> </div>
                                        </Th>
                                        <Th>
                                            <div onClick={this.sortActivity.bind(this, 'record')} className="activityHeader"><div>Record</div><div> <img src={(this.props.users.sortParamsActivity.sortBy === 'record' ? this.props.users.sortParamsActivity.sortType === 'ASC' ? grayAsc : grayDesc : unsorted)} alt="sort icon" /> </div></div>
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {this.renderAudit()}
                                </Tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </section>;
    }

    /**
     * returns masquarade reasons
     * @param {*} isActive
     * @param {*} isVerified
     * @param {*} isTermsofServiceAccepted
     * @return {array} defined reasons collection
     */
    getMasqueradeReasons(isActive, isVerified, isTermsofServiceAccepted) {
        const reasons = [];

        if (!isActive) {
            reasons.push('add-user__warning--inactive');
        }
        if (!isVerified) {
            reasons.push('add-user__warning--email-not-verified');
        }
        if (!isTermsofServiceAccepted) {
            reasons.push('add-user__warning--terms-not-accepted');
        }
        return reasons;
    }

    /**
     * Render 'view as' button, active, verified his account and aggreed to accounts terms and conditions
     * @return {JSX} view as template
     */
    renderViewAs() {
        const { isAdmin, disableViewAs, isActive, isVerified, Account_TOS_Agree: isTermsofServiceAccepted } = this.state;
        const userEmail = this.state.userEmail;
        const masqueradeAvailable = isAdmin && disableViewAs && isVerified && isTermsofServiceAccepted;
        const { t } = this.props

        if (!this.permissionSpecification.hasMasqueradePermission) {
            return <React.Fragment />;
        }

        if (masqueradeAvailable) {
            return <div className="viewing_as view-as-user">
                <span>
                    {t('add-user__masquerade')}
                    <div><span>
                        <a onClick={(e) => {
                            this.masquerade(e);
                        }}>
                            {userEmail}
                        </a></span></div>
                </span>
            </div>;
        } else if (this.state.isAdmin) {
            const reasons = this.getMasqueradeReasons(isActive, isVerified, isTermsofServiceAccepted);
            return <div className="viewing_as view-as-user">
                <div>
                    {t('add-user__warning--masquerade-unavailable')}
                </div>
                {reasons.map((reason, id) => <div key={id}>{t(reason)}</div>)}
            </div>;
        }
        return null;
    }

    /**
     * Render role options for dropdown
     * @return {array} Role options collections
     */
    renderOptions() {
        const roles = this.state.roles;
        if (roles) {
            const roleOptions = roles.map(function(role, index) {
                if (role.Role_IsDefault === 1) {
                    this.setState({ userRole: role.Role_UID });
                }
                return <option key={index} value={role.Role_UID} selected={(role.Role_IsDefault === 1)} >{role.Role_Name}</option>;
            });
            return roleOptions;
        }
        return [];
    }

    /**
     * Performs server side sorting
     * @param {*} sortBy
     * @param {*} e
     */
    sortActivity(sortBy, e) {
        const sortType = this.state.Ascending ? 'DESC' : 'ASC';
        const sortParams = { 'sortBy': sortBy, 'sortType': sortType };
        const newAscending = !this.state.Ascending;
        this.setState({ showCommonLoader: true, Ascending: newAscending });
        this.props.sortActivity(sortParams);
        const self = this;
        this.props.getActivity(this.state.uuid, () => {
            self.setState({ showCommonLoader: false });
        });
    }

    /**
     * Audit table
     * @return {JSX} table template
     */
    renderAudit() {
        const audits = this.props.users.activities;
        const { i18n } = this.props;
        const self = this;
        if (audits) {
            const roleOptions = audits.map(function(audit, index) {
                const locale = audit.lastLogin && moment(audit.lastLogin).locale(i18n.resolvedLanguage);
                return (
                    <Tr className="tdata clear" key={audit.page}>
                        <Td className="fixedHeader">
                            { locale ? moment(audit.lastLogin).lang(locale).format('MM/DD/YYYY - h:mm:ssA') : '' }
                        </Td>
                        <Td className="fixedHeader">{audit.page}</Td>
                        <Td className="fixedHeader">{audit.action}</Td>
                        <Td className="posRel">{self.renderRecord(audit)}</Td>
                    </Tr>
                );
            });
            return roleOptions;
        }
        return null;
    }

    /**
     * this function removes token from audit table, needs to be removed later point of time, when application is completely moved to React
     * @param {*} keyName
     * @param {*} record
     * @return {string} with removed query
     */
    removeQuery(keyName, record) {
        const index = record.indexOf(keyName);
        if (index > -1) {
            return record.substring(0, index);
        }

        return record;
    }

    /**
     * this function removes token from audit table, needs to be removed later point of time, when application is completely moved to React
     * @param {*} audit
     * @return {JSX} template
    */
    renderRecord(audit) {
        let record = audit.record;
        if (record) {
            record = this.removeQuery('token=', record);
            record = this.removeQuery('&token=', record);
            record = this.removeQuery('atoken=', record);
            record = this.removeQuery('&atoken=', record);
        }
        return (
            <span>
                {record.substring(0, 25) }
                <a className={'ellipse ' + (record.length > 25 ? '' : 'hide')} >
          ...
                    <span className="tooltipCustomAdmin">{record}</span>
                </a >
            </span>
        );
    }

    /**
     * Set to state changed control in form
     * @param {*} e
     */
    handleOnChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    /**
     * redirect to users page
     */
    clearUser() {
        window.history.back();
    }

    /**
     * Set to state when radio button changed state
     * @param {*} e
     */
    handleRadioChange(e) {
        const isActive = this.state.isActive === 1 ? 0 : 1;
        this.setState({ isActive });
    }

    /**
     * Render stores and brand template
     * @param {*} item
     * @param {*} level
     * @return {JSX} template
     */
    renderStoresAndBrand(item, level) {
        const { Name: itemName, StoreNumber: storeNum, Brand: brand } = item;
        return (
            <div className={`storeTree level-${level}`}>
                <span className={`StoreTitile level-${level}`}>{itemName ? (storeNum ? storeNum + '-' : '') + itemName : storeNum ? storeNum : ''}</span>
                <span className="StoreBrand">{brand ? brand : ''}</span>
            </div>);
    }

    /**
     * Get All Group Ids
     * @return {array} group ids
     */
    getAllGroupIds() {
        const i = 0;
        const groupIds = [];

        const iterate = (data) => {
            if (!data.length) {
                return groupIds;
            }

            const groups = R.filter((d) => d.Type === 'group' && d.Children.length, data);

            R.forEach(({ Id, Name, Children }) => {
                const store = R.find((data) => {
                    if (data.Type === 'store' ) {
                        return true;
                    }
                }, Children);

                if (store) {
                    groupIds.push(Id);
                }
            }, groups);

            return R.compose(iterate, R.unnest, R.pluck('Children'))(groups);
        };

        iterate(this.state.treeData);

        return groupIds;
    }

    /**
     * select all for stores and groups
     * @param {*} e
     */
    selectAll(e) {
        if (!this.state.selectAll) {
            this.setState({
                defaultCheckedKeys: _.pluck(this.state.treeData, 'Id').map(String),
                stores: this.findMatchedClassName(this.state.treeData, (item) => {
                    return item.Type === 'store';
                }),
                groups: this.getAllGroupIds(),
                selectedList: this.findMatchedIds(this.state.treeData, (item) => {
                    return true;
                }),
                deviceUIds: this.findMatchedIds(this.state.treeData, (item) => {
                    return item.Type === 'store';
                })
            });
            this.setState({
                selectAll: true
            });
        }
    }

    /**
     * deselecting, selected groups and stores
     * @param {*} e
     */
    deSelectAll(e) {
        this.setState({
            defaultCheckedKeys: [],
            selectedList: [],
            stores: [],
            groups: []
        });
        this.setState({
            selectAll: false
        });
    // }
    }

    /**
     * Find matched class name
     * @param {*} list
     * @param {*} keys
     * @return {array} selected items
     */
    findMatchedClassName(list, keys) {
        const selectedItems = [];
        const selectedList = [];
        const findStore = function(items) {
            items.map((item) => {
                if (item.Children && item.Children.length) {
                    findStore(item.Children);
                }
                if (keys(item)) {
                    selectedItems.push(item.StoreUid);
                    selectedList.push(item.Id);
                }
            });
        };

        findStore(list);
        this.setState({
            selectedList: selectedList
        });
        return selectedItems;
    }

    /**
     * find matched ids
     * @param {*} list
     * @param {*} keys
     * @return {array} selected items list
     */
    findMatchedIds(list, keys) {
        const selectedList = [];
        const findStore = function(items) {
            items.map((item) => {
                if (item.Children && item.Children.length) {
                    findStore(item.Children);
                }
                if (keys(item)) {
                    selectedList.push(item.Id);
                }
            });
        };

        findStore(list);
        return selectedList;
    }

    /**
     * remove user confirm alert popup
     */
    deleteUser() {
        const { t } = this.props;

        confirmAlert({
            title: t('common__confirm--delete'),
            message: t('add-user__confirm--remove'),
            buttons: [
                {
                    label: t('common__yes'),
                    onClick: () => {
                        this.confirmDelete();
                    }
                },
                {
                    label: t('common__no'),
                    onClick: () => { }
                }
            ]
        });
    }

    /**
     * remove user after confirmation
     */
    confirmDelete() {
        this.setState({ showCommonLoader: true });
        if (this.state.isEdit && this.state.user.uuId) {
            const { accountUuid, uuid } = this.state;
            const isMasquerade = this.authService.isMasquerade();

            // We use accountUuid for Distributors
            const endpointUrl = accountUuid ? CommonConstants.apiUrls.deleteDistributorUserFromAccount : CommonConstants.apiUrls.deleteUser;
            let url = `${BASE_URL}${endpointUrl}?uuId=${uuid}`;

            if (accountUuid) {
                url = `${url}&auuid=${accountUuid}`;
            }
            if (isMasquerade) {
                const adminUser = this.authService.getAdminProfile().unique_name;
                url = `${url}&adminUser=${adminUser}`;
            }

            this.api.deleteData(url, (data) => {
                this.setState({ showCommonLoader: false });
                if (data) {
                    this.setState({ isSuccess: true, isDeletedSuccess: true });
                } else {
                    const errorMessage = this.state.deleteErrorMessage;
                    this.setState({ errorMessage, successMessage: '' });
                }
            }, (error) => {
                this.setState({ errorMessage: error.message });
            });
        }
    }

    /**
     * Masquerade
     * @param {*} e
     */
    masquerade(e) {
        const userEmail = this.state.userEmail;
        const { t } = this.props;
        let userPostData = {
            username: userEmail,
            adminUser: this.authService.getAdminProfile() ? this.authService.getAdminProfile().unique_name : null
        };
        if (this.authService.getSelectedMenu() === 'accounts') {
            // window.location.href = '/admin/account?' + 'type=' + CompanyType + '&uuid=' + userUid
            userPostData = {
                username: userEmail,
                adminUser: this.authService.getAdminProfile()
          ? this.authService.getAdminProfile().unique_name
          : null
            };
        }
        const url = Config.authBaseUrl + Config.tokenPath;
        this.api.postData(url, userPostData, (data) => {
            // console.log("data: ", data)
            if (data === 'Unauthorized') {
                this.setState({ errorMessage: t('common__error--internal-server__p1') });
            } else {
                if (data && data.accessToken) {
                    window.localStorage.removeItem('user_email');
                    this.authService.clearUUID();
                    window.localStorage.setItem('ctx_token', data.accessToken);
                    window.location.href = '/welcome';
                }
            }
        });
    }

    /**
     * Submit Form
     * @param {*} e
     */
    submit(e) {
        this.setState({ showCommonLoader: true });
        let isError = false;
        const errors = [];
        this.setState({ errors: errors });

        const { accountUuid, isEdit } = this.state;

        // validating inputs
        if (!this.state.firstName) {
            errors.push('add-user__error--first-name-empty');
            isError = true;
        }
        if (!this.state.lastName) {
            errors.push('add-user__error--last-name-empty');
            isError = true;
        }
        if (!this.state.userEmail) {
            errors.push('common__error--invalid-email');
            isError = true;
        } else {
            const reg = /\S+@\S+\.\S+/;
            if (!reg.test(this.state.userEmail)) {
                errors.push('common__error--invalid-email');
                isError = true;
            }
        }
        if (!this.state.userRole) {
            errors.push('add-user__error--role-unassigned');
            isError = true;
        }

        if (!isError) {
            let url = '';
            // We use accountUuid for Distributors
            if (accountUuid) {
                if (isEdit) {
                    url = `${BASE_URL}${CommonConstants.apiUrls.editDistributorUserForAccount}?auuid=${accountUuid}`;
                } else {
                    url = `${BASE_URL}${CommonConstants.apiUrls.createDistributorUserForAccount}?auuid=${accountUuid}`;
                }
            } else {
                url = `${BASE_URL}${CommonConstants.apiUrls.createUser}`;
            }

            const userData = {
                'uuId': this.state.uuid ? this.state.uuid : null,
                'firstName': this.state.firstName,
                'lastName': this.state.lastName,
                'userEmail': this.state.userEmail,
                'isActive': this.state.isActive,
                'userRole': this.state.userRole,
                'storeIds': this.state.stores ? this.state.stores : [],
                'groupIds': this.state.groups ? this.state.groups : [],
                'createdDateTime': moment().format('YYYY-MM-DD HH:mm:ss'),
                'adminUser': this.state.isAdmin ? this.authService.getAdminProfile().unique_name : null,
                'masqueradedBy': this.authService.isMasquerade() ? this.authService.getAdminProfile().unique_name : null
            };

            this.props.SaveUsers(
                    userData,
                    (error, data) => {
                        if (error) {
                            console.log(error);
                            this.setState({ showCommonLoader: false, errorMessage: 'error', successMessage: '' });
                        } else {
                            this.setState({ showCommonLoader: false });
                            if (data.status) {
                                this.setState({ isSuccess: true });
                            } else if (!data.status) {
                                const errors = [];
                                errors.push(serverErrorTKey[data.key]);
                                this.setState({ errors: errors });
                            }
                        }
                    },
                    url);
        } else {
            this.setState({ errors: errors, showCommonLoader: false });
        }
    }
}

User.propTypes = {
    getActivity: PropTypes.func,
    sortActivity: PropTypes.func,
    setUserEmail: PropTypes.func,
    users: PropTypes.object,
    navigate: PropTypes.func,
    queryParams: PropTypes.object
};

/**
 * Map redux state to props
 * @param {*} state
 * @return {object} new fields in props
 */
function mapStateToProps(state) {
    return {
        headers: state.headers,
        users: state.users
    };
}

/**
 * Wrap redux actions in dispatch
 * @param {*} dispatch
 * @return {object} actions with wrapped dispatcher
 */
function matchDispatchToProps(dispatch) {
    return bindActionCreators(
            {
                setUserEmail: setUserEmail,
                SaveUsers,
                sortActivity,
                getActivity,
                getPermission
            }, dispatch
    );
}
export default R.compose(
        connect(mapStateToProps, matchDispatchToProps),
        withTranslation(),
        withReactRouter
)(User);
