import { Config } from '../Config';
import { CommonConstants } from '../Constants';
import { postDataAsync, getDataAsync, putDataAsync, patchDataAsync } from '../Api';

export const create = async (partner) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.createPartner}`;
    const response = await postDataAsync(url, partner);

    if (!response.status) {
        throw new Error(response.message);
    }

    return response.data;
};

export const load = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getPartners}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('common__error--unknown');
    }

    return response.data;
};

export const loadPartner = async (uid) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getPartner(uid)}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('common__error--unknown');
    }

    return response.data;
};

export const loadTypes = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getPartnerTypes}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('common__error--unknown');
    }

    return response.data;
};

export const updatePartner = async (uid, partner) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getPartner(uid)}`;

    const response = await putDataAsync(url, partner);

    if (!response.status) {
        throw new Error(response.message);
    }
};

export const resendActivationEmail = async (uid) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.reactivatePartner(uid)}`;

    const response = await postDataAsync(url);

    if (!response.status) {
        throw new Error('common__error--unknown');
    }
};

const verifyPassword = async ({ currentPassword: password }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.verifyPartnerCredential}`;

    const { status, validPassword } = await postDataAsync(url, { password });

    if (!status) {
        throw new Error('common__error--unknown');
    }

    if (!validPassword) {
        throw new Error('my-account__error__invalid-credentials');
    }

    return { status, validPassword };
};

export const changePassword = async ({ currentPassword, newPassword, confirmNewPassword }) => {
    const changePasswordURL = `${Config.apiBaseUrl}${CommonConstants.apiUrls.changePartnerUserPassword}`;

    await verifyPassword({ currentPassword });
    const { status, ...data } = await postDataAsync(changePasswordURL, { newPassword, confirmNewPassword });

    if (!status) {
        throw new Error(data?.passwordHasBeenPreviouslyUsed ? 'reset-pass__error__password-used-before' : 'common__error--unknown');
    }

    return { status, ...data };
};

export const updateUser = async ({
    email,
    firstName,
    lastName,
    officeNumber,
    mobileNumber
}) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.updateCurrentPartner}`;

    const { status } = await putDataAsync(url, {
        email,
        firstName,
        lastName,
        officeNumber,
        mobileNumber
    });

    if (!status) {
        throw new Error('common__error--unknown');
    }

    return { status };
};

export const loadProviders = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getPartnersProviders}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('common__error--unknown');
    }

    return response.data;
};

export const loadVaioProviderByUID = async (providerUID) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getPartnersProvider(providerUID)}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('common__error--unknown');
    }

    return response.data;
};

/**
 * Create vaio provider details
 * @param  {object} providerDetails details of new provider
 * @return {object} response
 */
export const createVaioProvider = async (providerDetails) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.createPartnersProvider}`;

    const response = await postDataAsync(url, providerDetails);

    return response.data;
};

/**
 * Update vaio provider details
 * @param  {object} updatedProviderDetails details of new provider
 * @return {object} response
 */
export const updateVaioProvider = async (updatedProviderDetails) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.updatePartnersProvider}`;

    const response = await patchDataAsync(url, updatedProviderDetails);

    return response.data;
};
