export const MOBILE_SORT_OPTIONS = [
    {
        label: 'add-user__activity-log__sorting__datetime__desc',
        value: { lastLoginDateTime: -1 },
    },
    {
        label: 'add-user__activity-log__sorting__datetime__asc',
        value: { lastLoginDateTime: 1 },
    },
    {
        label: 'add-user__activity-log__sorting__page__asc',
        value: { page: 1 },
    },
    {
        label: 'add-user__activity-log__sorting__page__desc',
        value: { page: -1 },
    },
    {
        label: 'add-user__activity-log__sorting__action__asc',
        value: { action: 1 },
    },
    {
        label: 'add-user__activity-log__sorting__action__desc',
        value: { action: -1 },
    },
    {
        label: 'add-user__activity-log__sorting__record__asc',
        value: { record: 1 },
    },
    {
        label: 'add-user__activity-log__sorting__record__desc',
        value: { record: -1 },
    },
];
