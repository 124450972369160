import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';

export const Header = ({ isEdit = false }) => {
    const { t } = useTranslation();
    const title = isEdit ?
    t('common__edit') + ' ' + t('settings_sso_configuration_admin__sso-management__tab-title') :
    t('common__create') + ' ' + t('settings_sso_configuration_admin__sso-management__tab-title');

    return (
        <div className="hme-sso-management-page__header">
            <Title>{title}</Title>
        </div>
    );
};
