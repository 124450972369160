import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Section } from 'library/Section';
import { Title } from 'library/Title';

import { SubscriptionType } from './SubscriptionType';
import { EngineeringSettings } from './EngineeringSettings';
import { AccountStatus } from './AccountStatus';
import { ActivationEmailLink } from './ActivationEmailLink';
import { isFormFieldDisabled } from '../helpers';

import './SubscriptionSection.scss';

export const SubscriptionSection = ({
    accountStatusInitialValues,
    subscriptionTypeInitialValues,
    engineeringSettingsInitialValues,
    isResendingActivationEmail,
    isActiveAccount,
    formErrors,
    onSubscriptionTypeChange,
    onEngineeringSettingsChange,
    onAccountStatusChange,
    onResendActivationEmail,
    isDistributorMode,
    isReadOnly
}) => {
    const { t } = useTranslation();
    const isFieldDisabled = useMemo(() => isFormFieldDisabled(), []);

    const needToShowActivationEmailLink = useMemo(() => isActiveAccount && (!isFieldDisabled && !isReadOnly), [isActiveAccount, isFieldDisabled]);
    return (
        <Section className="hme-section-md">
            <div className="hme-account-form-area">
                <Title>{t('account__section__title--subscription-information')}</Title>
                <div
                    className="hme-account-form-section hme-account-subscription-date-range-resend-email"
                >
                    {needToShowActivationEmailLink && (
                        <Section className="hme-section-sm">
                            <ActivationEmailLink
                                isDisabled={isResendingActivationEmail}
                                onResendActivationEmail={onResendActivationEmail}
                            />
                        </Section>
                    )}
                </div>
                {accountStatusInitialValues && (
                    <div className="hme-account-form-section hme-account-billing-cycle-account-status">
                        <Section className="hme-section-sm">
                            <AccountStatus
                                initialValues={accountStatusInitialValues}
                                formErrors={formErrors}
                                onChange={onAccountStatusChange}
                                isReadOnly={isReadOnly}
                            />
                        </Section>
                    </div>
                )}
                <div className={
                    classNames('hme-account-form-section', {
                        'hme-account-subscription-types__readonly': isReadOnly,
                        'hme-account-subscription-types': !isReadOnly
                    })}
                >
                    <Section className="hme-section-first hme-section-sm hme-account-subscription-types__readonly ">
                        <SubscriptionType
                            initialValues={subscriptionTypeInitialValues}
                            formErrors={formErrors}
                            onChange={onSubscriptionTypeChange}
                            isReadOnly={isReadOnly}
                        />
                    </Section>
                    <Section className={classNames('hme-section-sm', {
                        'hme-section-first hme-section-spacer': isReadOnly
                    })}>
                        {(!isDistributorMode && !isReadOnly) && (
                            <EngineeringSettings
                                initialValues={engineeringSettingsInitialValues}
                                onChange={onEngineeringSettingsChange}
                            />
                        )}
                    </Section>
                </div>
                {(!isDistributorMode && isReadOnly) &&
                    <div className="hme-account-section-wrapper">
                        <EngineeringSettings
                            initialValues={engineeringSettingsInitialValues}
                            onChange={onEngineeringSettingsChange}
                            isReadOnly={isReadOnly}
                        />
                    </div>
                }
            </div>
        </Section>
    );
};
