import { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PUBLIC_ROUTES } from 'constants/routes';
import { getAccountData, saveBrands } from 'services/Account';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { isBrandsChanged } from '../helpers/isBrandsChanged';

const load = async (setIsLoading, setBrands) => {
    setIsLoading(true);

    try {
        const account = await getAccountData();
        setBrands(account.corporateShare);
    } catch (err) {
        addErrorNotification('common__error--internal-server');
    }

    setIsLoading(false);
};

const save = async (brands, setIsLoading, setOriginBrands) => {
    setIsLoading(true);

    try {
        await saveBrands(brands);
        addSuccessNotification('my-account__success__save');
    } catch (err) {
        setIsLoading(false);
        addErrorNotification(err.message);

        return;
    }

    await load(setIsLoading, setOriginBrands);
};

export const useFranchise = () => {
    const [originBrands, setOriginBrands] = useState([]);
    const [brands, setBrands] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const isSubmitEnabled = useMemo(() => isBrandsChanged(originBrands, brands), [originBrands, brands]);
    const navigate = useNavigate();

    const onSave = useCallback(() => {
        save(brands, setIsLoading, setOriginBrands);
    }, [brands]);

    const onCancel = useCallback(() => {
        navigate(`/${PUBLIC_ROUTES.account}`);
    }, [navigate]);

    useEffect(() => {
        load(setIsLoading, setOriginBrands);
    }, []);

    useEffect(() => {
        setBrands(originBrands);
    }, [originBrands]);

    return {
        title: 'profile__brand-sharing__title_franchise',
        isLoading,
        isSubmitEnabled,
        brands,
        onChange: setBrands,
        onCancel,
        onSave
    };
};
