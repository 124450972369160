import { useEffect } from 'react';
import {
    equals,
    type,
    cond,
    pluck,
    uniqBy,
    prop,
    compose,
    sortBy,
    sort,
    flatten,
    toLower
  } from 'ramda';  

const toLowerIfString = cond([[compose(equals('String'), type), toLower]]);
const sortPredicate = compose(toLowerIfString, prop('text'));

const getUniqItems = uniqBy(prop('value'));
const getSortedUniqItems = compose(sortBy(sortPredicate), getUniqItems);


const prepareItems = (property, filterOption, data) => {
    const valuePropName = filterOption.valuePropName || property;
    const { sortComparator } = filterOption;
    const presortedData = sortComparator ? sort(sortComparator, data) : data;

    const items = flatten(presortedData.map(item => {
        const text = item[property] || '';
        const value = item[valuePropName];

        const textArray = Array.isArray(text) ? text : [text];
        const valueArray = Array.isArray(value) ? value : [value];

        return textArray.map((text, i) => ({
            text: text,
            value: valueArray[i],
        }));
    }));

    return sortComparator ? getUniqItems(items) : getSortedUniqItems(items);
};

const getFilterItems = (property, filterOption, data) => {
    const items = filterOption.items;
    const valuePropName = filterOption.valuePropName || property;

    const allValues = pluck(valuePropName, data);

    return items.filter(({ value }) =>
        Array.isArray(value) 
            ? value.some((v) => allValues.includes(v)) 
            : allValues.includes(value),
    );
};

export const getAvailableFilters = (options, data) => {
    return Object.keys(options).map((property) => {
        const filterOption = options[property];

        return {
            allText: filterOption.allText || 'All',
            items: filterOption.items ?
                getFilterItems(property, filterOption, data) :
                prepareItems(property, filterOption, data),
            property
        };
    });
};

export const useAvailableFilters = (options, data, setGridFilters, setAvailableFilters) => {
    useEffect(() => {
        const availableFilters = getAvailableFilters(options, data);

        const gridFilters = availableFilters.reduce((filtersObject, { property, items }) => {
            filtersObject[property] = pluck('value', items);

            return filtersObject;
        }, {});

        setGridFilters(gridFilters);
        setAvailableFilters(availableFilters);
    }, [options, data, setGridFilters, setAvailableFilters]);
};
