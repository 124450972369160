export const MOBILE_USERS_HEADERS = [
    { column: [
        { text: 'common__user__first-name', property: 'User_FirstName' },
        { text: 'common__user__last-name', property: 'User_LastName' }
    ] },
    { column: [
        { text: 'common__user__username', property: 'User_EmailAddress' },
        { text: 'common__role', property: 'RoleText' }
    ] }

];
