export const checkIsUpgradeAvailable = (deviceSettings) => {
    if (!deviceSettings || !deviceSettings.Device_LastUpgrade || !deviceSettings.Server_CurrentTime) {
        return true;
    }

    const upgradeTimeThreshold = 30 * 60 * 1000; // 30 min
    return (
        new Date(deviceSettings.Server_CurrentTime).getTime() >
        new Date(deviceSettings.Device_LastUpgrade).getTime() + upgradeTimeThreshold
    );
};
