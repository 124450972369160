import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreInput } from 'library/StoreInput';
import { scheduledUpgradesApi } from 'api/scheduledUpgradesApi';
import { useTrigger, useOnTrigger } from 'hooks/useTrigger';
import { StoreGrid } from './StoreGrid';
import { CommonConstants } from 'Constants';
import { NEXEO_BASE_STATION } from 'constants/NEXEOPeripheralTypes';

import './StoreForm.css';

const baseGridHeaders = [
    {
        text: 'device-upgrades__grid__header--store-number',
        property: 'Store_Number',
        className: 'hme-store-input-view-store-number-cell',
        headerClassName: 'hme-store-input-view-store-number-cell',
        flex: 'none',
    },
    {
        text: 'device-upgrades__grid__header--brand',
        property: 'Brand_Name',
        className: 'hme-store-input-view-brand-cell',
        headerClassName: 'hme-store-input-view-brand-cell',
        flex: 'none',
    },
    {
        text: 'device-upgrades__grid__header--store-name',
        property: 'Store_Name',
        className: 'hme-store-input-view-store-name-cell',
        headerClassName: 'hme-store-input-view-store-name-cell',
        flex: 'none',
    },
    {
        text: 'common__device__serial-number',
        property: 'Device_SerialNumber',
        className: 'hme-store-input-view-device-serial-number-cell',
        headerClassName: 'hme-store-input-view-device-serial-number-cell',
        flex: 'none',
    },
    {
        text: 'common__device__product-id',
        property: 'Device_Product_ID',
        className: 'hme-grid-cell-show-all-content',
        flex: 110,
        sortable: true,
    },
    {
        text: 'device-upgrades__grid__header--account',
        property: 'AccountOwner_EmailAddress',
        className: 'hme-store-input-view-account-cell',
        headerClassName: 'hme-store-input-view-account-cell',
        flex: 'none',
    },
];

const getGridHeaders = (deviceType, peripheralDeviceType) => (
    deviceType === CommonConstants.deviceType.nexeo.name.toLowerCase() && peripheralDeviceType === NEXEO_BASE_STATION.modelName
        ? baseGridHeaders
        : baseGridHeaders.filter((header) => header.property !== 'Device_Product_ID')
);

export const StoreForm = ({
    targetDeviceVersions,
    selectedDeviceType,
    selectedPeripheralDeviceType,
    onSelectStores,
    removeDevicesTrigger,
    changeUpgradeInfoTrigger,
}) => {
    const [store, setStore] = useState(null);
    const [selectedStores, setSelectedStores] = useState([]);
    const { t } = useTranslation();
    const resetFormTrigger = useTrigger();
    const gridHeaders = getGridHeaders(selectedDeviceType, selectedPeripheralDeviceType);

    useEffect(() => {
        onSelectStores(selectedStores);
    }, [selectedStores]);

    const getStores = useCallback(
        async (criteria) => {
            const storeList = await scheduledUpgradesApi.getStores({
                criteria,
                targetDeviceVersions,
                deviceType: selectedDeviceType,
                peripheralDeviceType: selectedPeripheralDeviceType,
            });

            return storeList && Array.isArray(storeList) ? storeList : [];
        },
        [targetDeviceVersions, selectedDeviceType],
    );

    const onDevicesRemove = useCallback(
        (deviceIDs) => {
            const filteredStores = selectedStores.filter((store) => !deviceIDs.includes(store.Device_ID));
            setSelectedStores(filteredStores);
        },
        [selectedStores],
    );

    const onStoreChange = useCallback(
        (newStore) => {
            // provide the same store entity in case it is already selected (for marking the selection as checked)
            const alreadySelectedStore = selectedStores.find((ss) => ss.Device_ID === newStore.Device_ID);

            setStore(alreadySelectedStore || newStore);
        },
        [selectedStores],
    );

    const onChangeUpgradeInfo = useCallback(() => {
        setStore(null);
        setSelectedStores([]);
        resetFormTrigger && resetFormTrigger.trigger();
    }, [resetFormTrigger]);

    const stores = [store];

    useOnTrigger(removeDevicesTrigger, onDevicesRemove);
    useOnTrigger(changeUpgradeInfoTrigger, onChangeUpgradeInfo);

    return (
        <div className="hme-create-device-upgrade-store-form">
            <div className="hme-create-device-upgrade-form-section">
                <StoreInput
                    storesHeaders={gridHeaders}
                    getStores={getStores}
                    onStoreChange={onStoreChange}
                    placeholder={t('common__placeholder__search-store-serial-product--short')}
                    noSuggestionText={t('common__no-stores--found')}
                    resetFormTrigger={resetFormTrigger}
                />
            </div>

            {store && (
                <StoreGrid stores={stores} selectedStores={selectedStores} onStoreSelection={setSelectedStores} />
            )}
        </div>
    );
};
