import React from 'react';

import { Translator } from 'library/Translator';

export const accountOwner = {
    Role_Name: 'Account Owner',
    Role_UID: '0000',
    isAccountOwner: true,
    Company_Name: '',
    Role_Description: (<Translator tKey='roles__grid__account-owner-desription' />),
    UserName: '',
};
