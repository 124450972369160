export const adminSystemsSelectSearchOptions = [
    {
        value: 'Store_Number',
        text: 'common__store__number',
        placeholder: 'admin__systems__search__placeholder--store-number'
    },
    {
        value: 'Device_SerialNumber',
        text: 'common__device__serial-number',
        placeholder: 'admin__systems__search__placeholder--serial-number'
    },
    {
        value: 'Device_Product_ID',
        text: 'common__device__product-id',
        placeholder: 'admin__systems__search__placeholder--product-id'
    },
    {
        value: 'Device_MainVersion',
        text: 'common__device__version',
        placeholder: 'admin__systems__search__placeholder--version'
    },
    {
        value: 'Device_SettingVersion',
        text: 'common__settings__version',
        placeholder: 'admin__systems__search__placeholder--settings-version'
    },{
        value: '',
        text: 'common__all',
        placeholder: 'admin__systems__search__placeholder--search-criteria'
    },
];
