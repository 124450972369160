import React, { Component } from 'react';
import AuthenticationService from './AuthenticationService';
import { Config } from '../../Config';
import NavigationServices from '../Common/NavigationServices';
import { withReactRouter } from 'HOCs/withReactRouter';
import PropTypes from 'prop-types';

/**
 * This component clears localstorage and redirects user to login page
 */
class Logout extends Component {
    /** */
    constructor() {
        super();
        this.authService = new AuthenticationService(Config.authBaseUrl);
        this.navigation = new NavigationServices();
    }
    /** */
    UNSAFE_componentWillMount() {
        const url = window.location.pathname.includes("/admin")
            ? window.location.origin + "/admin"
            : window.location.pathname.includes("/partner")
                ? window.location.origin + "/partner"
                : window.location.origin;
        const { location } = this.props;
        this.authService.clear();
        if (location.state !== null) {
            console.log('Redirect URL logout: ', location.state.referrer);
            window.location.href = location.state.referrer;
        } else {
            window.location.href = url;
        }
    }
    /**
     * @return {JSX} empty div
     */
    render() {
        return ( <div/> );
    }
}

Logout.propTypes = {
    location: PropTypes.object
};

export default withReactRouter(Logout);
