import React from 'react';

import { ErrorModal } from 'library/ErrorModal';
import { ErrorsList } from './ErrorsList';

import './RowErrorModal.scss';

const ErrorMessage = ({ length }) => (
    <span>
        The file could not be uploaded due to the following {' '}
        <b>{ length } { length === 1 ? 'issue' : 'issues' }:</b>
    </span>
);

export const RowErrorModal = ({ errors, actions, onHide, ...modalProps }) => (
    <ErrorModal
        errors={errors}
        onHide={onHide}
        {...modalProps}
        actions={actions}
        dialogClassName='apply-store-settings-status-upload-error apply-store-settings-status-upload-error-rows-modal'
        message={<ErrorMessage length={errors.length} />}
    >
        <ErrorsList errors={errors} />
    </ErrorModal>
);
