import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { weekDays } from '../constants/weekDays';

import './ScheduleDays.css';

export const ScheduleDays = ({ upgradeScheduleDays = '' }) => {
    const { t } = useTranslation();

    return (
        <div className="hme-schedule-days">
            {weekDays.map(({ text, value }) => (
                <div
                    key={value}
                    className={classNames([
                        'schedule-day',
                        upgradeScheduleDays.split(',').includes(`${value}`) ? 'schedule-day--checked' : '',
                    ])}
                >
                    <span className="schedule-day-text">{t(text)}</span>
                </div>
            ))}
        </div>
    );
};
