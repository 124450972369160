import React, { useState, useEffect, useCallback } from 'react';
import {t} from 'i18next'; // Non-hook version
import debounce from 'lodash.debounce';
const { Constant: { DeviceType } } = require('@hme-cloud/utility-common');

import { deviceConfig } from 'constants/device';
import { AutoCompleteInput } from 'library/AutoCompleteInput';
import regExpStringFilter from '../../../helpers/regExpStringFilter';
import { getDevices } from '../../CreateTaskController';

import './SourceDeviceInput.scss';

const {
    validRegExp: NON_ALPHANUMERIC_REGEXP,
    maxSerialNumberLength: SERIAL_NUMBER_MAX_LENGTH,
    getLaneType,
    zoomNitro: {
        applySettingsMinVersion: applySettingsMinVersionNitro,
    },
    zoom: {
        applySettingsMinVersion: applySettingsMinVersionZoom,
        applySettingsMaxVersion: applySettingsMaxVersionZoom,
    }
} = deviceConfig;

const debounceTimeout = 300;

const minValueLength = 3;

const prepareValue = value => {
    return regExpStringFilter(value, NON_ALPHANUMERIC_REGEXP);
};

const getParams = (deviceSn, type) => {
    const params = {
        searchDeviceSerialNumber: deviceSn,
        limit: 5,
        hideBusyDevices: 1, // device that already in progress of apply device settings should not be shown
    };

    switch(type) {
        case 'zoom':
            params.deviceMaxMainVersion = applySettingsMaxVersionZoom;
            params.deviceMinMainVersion = applySettingsMinVersionZoom;

            break;
        case 'zoomNitro':
            params.deviceMinMainVersion = applySettingsMinVersionNitro;

            break;
        default:

            break;
    }

    return params;
};

const loadDevices = async (deviceSn, type, setDevices, setLoading) => {
    const params = getParams(deviceSn, type);
    const devices = await getDevices(params);

    setDevices(devices);
    setLoading(false);
};

const loadDevicesDebounded = debounce(loadDevices, debounceTimeout);

const suggestionsHeaders = [{
    text: t('common__device-serial_no'),
    property: 'Device_SerialNumber',
    flex: 1.5,
}, {
    text: t('common__store-no'),
    property: 'Store_Number',
}, {
    text: t('common__brand'),
    property: 'Brand_Name',
}, {
    text: t('common__store__name'),
    property: 'Store_Name',
}, {
    text: t('common__lane-type'),
    property: 'laneType',
}];

export const suggestionsMobileHeaders = [
    {
        column: [
            { text: 'common__device-serial_no', property: 'Device_SerialNumber', },
            { text: 'common__brand', property: 'Brand_Name', },
            { text: 'common__lane-type', property: 'laneType', },
        ],
    },
    {
        column: [
            { text: 'common__store-no', property: 'Store_Number', },
            { text: 'common__store__name', property: 'Store_Name', },            
        ],
    },
];

const deviceToRow = device => ({
    ...device,
    laneType: getLaneType(device.Device_LaneConfig_ID),
});

export const SourceDeviceInput = ({
    value,
    label = t('common__device-serial-number'),
    type = 'zoomNitro',
    onChange,
}) => {
    const [deviceSn, setDeviceSn] = useState('');
    const [suggestedDevices, setSuggestedDevices] = useState([]);
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (deviceSn.length < minValueLength) {
            return;
        }

        setIsLoading(true);
        setSuggestedDevices([]);
        loadDevicesDebounded(deviceSn, type, setSuggestedDevices, setIsLoading);

        if (value && deviceSn !== value.Device_SerialNumber) {
            onChange(null);
        }
    }, [deviceSn, type, setSuggestedDevices, setIsLoading]);

    useEffect(() => {
        const suggestedRows = suggestedDevices.map(device => deviceToRow(device));

        setRows(suggestedRows);
    }, [suggestedDevices, setRows]);

    useEffect(() => {
        if (value) {
            setDeviceSn(value.Device_SerialNumber);
        }
    }, [value]);

    const checkDisabled = useCallback((row) => {
        return type === DeviceType.ZOOMNITRO.camelCase ? row?.iotDeviceId !== null : false;
    }, [type]);

    return (
        <div className='hme-source-device-input'>
            <AutoCompleteInput
                label={label}
                maxLength={SERIAL_NUMBER_MAX_LENGTH}
                success={value !== null}
                value={deviceSn}
                suggestions={rows}
                headers={suggestionsHeaders}
                mobileHeaders={suggestionsMobileHeaders}
                minValueLength={minValueLength}
                noSuggestionText='No device found.'
                rowKey='Device_UID'
                isLoading={isLoading}
                onChange={newSn => {
                    setDeviceSn(prepareValue(newSn));
                }}
                onSuggested={onChange}
                checkDisabled={checkDisabled}
            />
        </div>
    );
};
