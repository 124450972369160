import { loadProviders, loadVaioProviderByUID, updateVaioProvider } from 'services/Partner';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { getProfile } from 'services/Auth';
import { getPartnerStores } from 'services/Stores';
import { tokenIdTypes, tokenTypes } from 'constants/token';
import { errorKeys, toUpdateDTO } from 'pages/StoreFeatures/VaioProviderForm/helpers';
import { fetchPartnerTokenByUID, renewAPITokenPartner } from 'services/TokenManagement';

import { VAIO_DEFAULTS } from './TokenManagement/EditApiManagementForm/helpers';

const tokenEmailsAdaptor = (emails = []) => {
    const { email: tokenManager = '' } = emails.find((email) => email.isTokenManager);
    const [email2 = '', email3 = ''] = emails
        .filter((email) => !email.isTokenManager)
        .map(({ email }) => email);

    return {
        tokenManager,
        email2,
        email3
    };
};

const tokenTypeAdaptor = (tokenType) => tokenIdTypes[tokenType] || tokenTypes.VAIO;

const tokenAdaptor = (token = {}) => ({
    ...VAIO_DEFAULTS,
    ...token,
    tokenType: tokenTypeAdaptor(token.categoryId),
    ...tokenEmailsAdaptor(token.emails)
});

export const fetchProviders = loadProviders;

export const fetchProviderByUID = loadVaioProviderByUID;

export const editVaioProvider = async (values) => {
    const userEmail = getProfile().unique_name;

    try {
        await updateVaioProvider(toUpdateDTO(values, userEmail));

        setTimeout(() => {
            addSuccessNotification('admin__account__vaio__provider-updated', { autoClose: 5000 });
        }, 1000);
    } catch (err) {
        setTimeout(() => {
            addErrorNotification((errorKeys[err.response.data.message] || err.response.data.message), { autoClose: 5000 });
        }, 1000);
    }
};
export const loadPartnerStores = async () => {
    const result = await getPartnerStores();

    return {
        stores: result
    };
};

export const getAPIToken = async (uid) => {
    const token = await fetchPartnerTokenByUID(uid);
    return token.id ? tokenAdaptor(token) : null;
};

export const renewToken = async (token = {}) => {
    const { status, data } = await renewAPITokenPartner(token);

    return { status, token: data };
};
