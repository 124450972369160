import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { Overlay } from "react-bootstrap";
import { compose } from 'ramda';
import _ from "underscore";

// Custom popover template.
function StoreListPopover(props) {
  let { content, t, company } = props;
  let listItems = null;

  if (content && content.length > 0) {
    // Order the store list by store number and then by store name.
    let selectedMemberStores = _(content)
      .chain()
      .sortBy(store => store.StoreName)
      .sortBy(store => parseInt(store.StoreNumber))
      .value();

    // Show store name and number for each store formatted as "[Number]-[Name]".
    listItems = selectedMemberStores.map(store => {
      return (
        <li key={store.StoreUID}>
          {store.StoreNumber} - {store.StoreName || store.StoreNumber}
        </li>
      );
    });
  } else {
    listItems = <p> {t('add-leaderboard__stores__no-stores')} </p>;
  }

  return (
    <div
      style={{
        position: "absolute",
        marginLeft: 130,
        marginTop: -100,
        color: "#454545",
      }}
    >
      <div className="panel panel-default member-stores-popover">
        <div className="panel-heading">
          <h4>{t('common__stores')}</h4>
          <button type="button" className="participants-popover-close close" arial-label="close" onClick={() => props.closePopover()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="panel-body">
          <h5 className="store-name">{company}</h5>
          <ul className="list-unstyled store-list">{listItems}</ul>
        </div>
      </div>
    </div>
  );
}

// Find the target element and show the popover on right hand side of the element.
class PopoverComponent extends Component {
  render() {
    return (
      <div style={{ height: 100, position: "absolute" }}>
        <Overlay show={this.props.show} onHide={() => this.props.closePopover()} placement="right" rootClose={true} container={this.props.target}>
          <StoreListPopover closePopover={this.props.closePopover} content={this.props.content} company={this.props.company} />
        </Overlay>
      </div>
    );
  }
}

export default compose(
  withTranslation()
)(PopoverComponent);
