import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, BUTTON_VARIANTS } from 'library/Button';

import { useContestResult } from '../../../../Common/hooks';
import { Certificate as CertificateModal } from '../../../../Common/CertificateContainer';

import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';

const buttonVariants = [BUTTON_VARIANTS.TRANSPARENT];

const CertificateLoader = ({ onHide }) => {
    const { uid } = useParams();
    const { isLoading, contestResult, stores, selectedStores, setSelectedStores, generatePDF } = useContestResult({ uid });

    return <CertificateModal
        isLoading={isLoading}
        storeName={contestResult?.ContestName}
        stores={stores}
        selectedStores={selectedStores}
        setSelectedStores={setSelectedStores}
        onSubmit={generatePDF}
        onHide={onHide}
    />;
}

export const Certificate = () => {
    const { t } = useTranslation();

    const [isModalOpened, setIsModalOpened] = useState(false);

    const onClick = useCallback(() => {
        setIsModalOpened(true);
    }, []);

    const onHide = useCallback(() => {
        setIsModalOpened(false);
    }, []);

    return (
        <>
            <Button
                variants={buttonVariants}
                onClick={onClick}
            ><UploadIcon className='hme-view-edit-contest--results__header__download-icon' /> <span>{t('contest__certificate_other')}</span></Button>
            {
                isModalOpened &&
                <CertificateLoader onHide={onHide} />
            }
        </>
    )
};
