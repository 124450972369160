import { Config } from '../Config';
import { CommonConstants } from '../Constants';
import { getDataAsync } from '../Api';

export const getDistributors = async () => {
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.getDistributor;

    const response = await getDataAsync(url);

    if (!response.status) {
        return [];
    }

    return Array.isArray(response.data) ? response.data[0] : response.data;
};
