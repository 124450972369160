import React, { useMemo } from 'react';

import { InputComponent } from 'components/Inputs';
import { ErrorLabel } from 'library/ErrorLabel';

export const InputWithError = ({ error, variants = [], className = '', ...props }) => {
    const newVariants = useMemo(() => {
        return [...variants, (error ? 'invalid' : '')];
    }, [error, variants]);

    return (
        <div className="hme-input-with-error">
            <InputComponent
                variants={newVariants}
                className={className}
                {...props}
            />
            {error && <ErrorLabel>{error}</ErrorLabel>}
        </div>
    );
};
