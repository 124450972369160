import React, { useState, useEffect } from 'react';
import { SingleSelect } from 'library/SingleSelect';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const CountrySelect = ({ label, countries, value, onChange }) => {
    const [countryOptions, setCountryOptions] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const options = countries.map(({ Id, Name }) => ({
            text: Name,
            value: Id
        }));

        setCountryOptions(options);
    }, [countries, setCountryOptions]);

    return (
        <SingleSelect
            label={t(label)}
            value={value}
            onChange={onChange}
            items={countryOptions}
            variants={['label-inside']}
        />
    );
};

CountrySelect.propTypes = {
    label: PropTypes.string,
    countries: PropTypes.array,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func
};
