/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Button } from 'library/Button';

import './Footer.scss';

export const Footer = ({
    isSubmitEnabled,
    onCancel,
    onSave,
    additionalClass
}) => {
    const { t } = useTranslation();
    return (
        <div className={classNames('leaderboard-templates-footer', additionalClass)}>
            <Button
                onClick={onCancel}
            >
                {t('common__cancel')}
            </Button>
            <Button
                variants={['submit']}
                disabled={!isSubmitEnabled}
                onClick={onSave}
            >
                {t('common__save')}
            </Button>
        </div>
    );
};
