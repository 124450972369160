import React, { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button } from '../../Common/Button/Button';
import { CheckboxInput } from '../CheckboxInput/CheckboxInput';
import { Config } from '../../../Config';


import './ImageInput.css';

/**
 *
 */
class ImageInputComponent extends Component {
    /**
   *
   * @param {*} props
   */
    constructor(props) {
        super(props);

        this.fileInputRef = createRef();

        this.toggleImage = this.toggleImage.bind(this);
        this.changeImage = this.changeImage.bind(this);
        this.selectImage = this.selectImage.bind(this);
    }

    // Used to a trigger image upload or to remove existing image
    /**
     *
     * @param {*} checked
     */
    toggleImage(checked) {
        const { onChange } = this.props;
        const fileInputNode = this.fileInputRef.current;

        if (checked) {
            this.selectImage();
        } else {
            if (fileInputNode) {
                fileInputNode.value = null;
            }

            onChange(null);
        }
    }

    /**
     *
     * @param {*} image
     * @return {*}
     */
    getBase64FromFile(image) {
        const fileReader = new FileReader();
        const img = new Image();

        return new Promise((resolve, reject) => {
            img.onload = () => {
                fileReader.onloadend = () => resolve(fileReader.result);
                fileReader.onerror = () => reject(fileReader.error);

                fileReader.readAsDataURL(image);
            };

            img.src = window.URL.createObjectURL(image);
        });
    }

    // processes uploaded image
    /**
     *
     * @param {*} e
     */
    async changeImage(e) {
        const { onChange } = this.props;
        const file = e.target.files[0];

        if (file) {
            const encodedImage = await this.getBase64FromFile(file);
            onChange(encodedImage);
        }
    }

    /**
     *
     */
    selectImage() {
        const fileInputNode = this.fileInputRef.current;
        fileInputNode && fileInputNode.click();
    }

    /**
     *
     * @return {JSX}
     */
    renderPreview() {
        const { value, previewComponent: CustomPreview, t } = this.props;
        let src = value;

        if (value && !value.startsWith('data:')) {
            src = `${Config.blobNitroStorageBaseUrl}/${value}`;
        }

        if (CustomPreview) {
            return <CustomPreview src={src} />;
        }

        return value && <div className="image-input__preview">
            <img alt={t('common__image-preview')} src={src} />
        </div>;
    }

    /**
     *
     * @return {JSX}
     */
    render() {
        const { value, name, toggleImageLabel, changeImageLabel, className, disabled, t } = this.props;

        return (
            <div className={classNames(className, 'hme-input image-input')}>
                <div className="image-input__toggle-image">
                    {this.renderPreview()}
                </div>
                <div className="image-input__change-image">
                    <input
                        disabled={disabled}
                        ref={this.fileInputRef}
                        type="file"
                        name={name}
                        accept="image/*"
                        onChange={this.changeImage}
                    />
                    <Button
                        label={changeImageLabel || t('common__image-change')}
                        primary
                        disabled={disabled}
                        onClick={this.selectImage}
                    />
                </div>
                <div className="image-input__toggle-image">
                    <CheckboxInput
                        disabled={disabled}
                        onChange={this.toggleImage}
                        name="toggleImage"
                        label={toggleImageLabel || t('common__image-display')}
                        value={!!value}
                    />
                </div>
            </div>
        );
    }
}

ImageInputComponent.propTypes = {
    toggleImageLabel: PropTypes.string,
    changeImageLabel: PropTypes.string
};

const ImageInput = withTranslation()(ImageInputComponent);
export { ImageInput };
