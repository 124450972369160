import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ADMIN_ROUTES } from 'constants/routes';
import { Title } from 'library/Title';
import { Button } from 'library/Button';

export const Header = () => {
    const { t } = useTranslation();

    return (
        <div className="hme-sso-management-page__header">
            <Title>{t('settings_sso_configuration_admin__sso-management__tab-title')}</Title>
            <Button
                as={Link}
                to={ADMIN_ROUTES.addSSO}
                className="hme-sso-management__create-sso-btn"
            >
                {t('settings_sso_configuration_admin__sso-management__add-new-sso')}
            </Button>
        </div>
    );
};
