import React from 'react';
import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';
import { Separator, SEPARATOR_VARIANTS } from 'library/Separator';

import { FeedbackIntervalFormFieldset, IntervalFormFieldset, PeriofFormFieldset } from './FormFields';
import { formItemParts } from '../constants';
import { useValidators } from './hooks';

const separatorVariants = [SEPARATOR_VARIANTS.HORIZONTAL, SEPARATOR_VARIANTS.PURE];

export const AnnouncementDurationSettings = ({
    settingType,
    timeUnits,
    item,
    title,
    description,
    intervalDescription,
    parts,
    fieldsSubtitle
}) => {
    const { t } = useTranslation();

    const { displayPeriodValidator, displayDurationValidator, displayIntervalValidator } = useValidators({
        item,
        settingType
    });

    const hasPeriodPart = parts.includes(formItemParts.PERIOD);
    const hasIntervalPart = parts.includes(formItemParts.INTERVAL);
    const hasFeedbackPart = parts.includes(formItemParts.FEEDBACK_INTERVAL);

    return (
        <>
            <div className="nitro-announcement-settings__fieldset">
                <Title as="h4" className="nitro-announcement-settings__fieldset-legend">
                    {t(title)}
                </Title>

                <div className="nitro-announcement-settings__fieldset-wrapper">
                    {hasPeriodPart && <PeriofFormFieldset
                        item={item}
                        fieldsSubtitle={fieldsSubtitle}
                        timeUnits={timeUnits}
                        description={description}
                        settingType={settingType}
                        displayPeriodValidator={displayPeriodValidator}
                    />}
                    {hasIntervalPart && <IntervalFormFieldset
                        item={item}
                        fieldsSubtitle={fieldsSubtitle}
                        timeUnits={timeUnits}
                        description={intervalDescription}
                        settingType={settingType}
                        displayDurationValidator={displayDurationValidator}
                        displayIntervalValidator={displayIntervalValidator}
                    />}
                    {hasFeedbackPart && <FeedbackIntervalFormFieldset
                        item={item}
                        timeUnits={timeUnits}
                        description={description}
                        settingType={settingType}
                        displayDurationValidator={displayDurationValidator}
                    />}
                </div>
            </div>

            <Separator variants={separatorVariants} className="nitro-announcement-settings__fieldset-separator" />
        </>
    );
};
