import React from 'react';
import { Overlay } from 'react-bootstrap';

import { TooltipView } from './TooltipView';

import './Tooltip.scss';

export const Tooltip = ({ className = '', tooltipContentRef, children, ...props }) => (
    <Overlay {...props}>
        {(overlayProps) => (
            <TooltipView
                tooltipContentRef={tooltipContentRef}
                className={className}
                {...overlayProps}
            >
                {children}
            </TooltipView>
        )}
    </Overlay>
);
