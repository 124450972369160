import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';

import { deviceConfig } from 'constants/device';
import { Section } from 'library/Section';
import {
    Grid,
    useAvailableFilters,
    applyGridFilters,
    applySearchValue,
    resetFilters,
    getFiltersCount
} from 'components/Common/Grid';
import { useTrigger, useOnTrigger } from 'hooks/useTrigger';
import { GridFiltersCount } from 'library/GridFiltersCount';
import { SearchInput } from 'components/Inputs';

import './DevicesSection.scss';

const { getLaneType } = deviceConfig;

const ALL_ACCOUNTS_FILTER_TEXT = 'All Accounts';
const ALL_VERSIONS_FILTER_TEXT = 'All Versions';
const ALL_BRANDS_TEXT = 'All Brands';
const ALL_LANE_TYPES = 'All Lane Config Types';
const ALL_COUNTRIES = 'All Countries';
const ALL_REGIONS = 'All States';
const ALL_TIMEZONES = 'All Time Zones';

const gridOptions = [{
    text: 'Device Serial #',
    property: 'Device_SerialNumber',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Account Email',
    property: 'User_EmailAddress',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Device Email',
    property: 'Device_EmailAccount',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'AX Customer Account #',
    property: 'Store_AX_Customer_Account',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Brand',
    property: 'Brand_Name',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Version',
    property: 'Device_MainVersion',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Store #',
    property: 'Store_Number',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Store Name',
    property: 'Store_Name',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Lane Config',
    property: 'laneType',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Address',
    property: 'addressLine',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'Country',
    property: 'country',
    className: 'hme-grid-cell-show-all-content',
}, {
    text: 'State',
    property: 'Store_Region',
    className: 'hme-grid-cell-show-all-content',
    headerClassName: 'admin-create-apply-store-settings-task-state-cell',
}, {
    text: 'Time Zone',
    property: 'Timezone',
    className: 'hme-grid-cell-show-all-content',
    headerClassName: 'admin-create-apply-store-settings-task-timezone-cell',
}];

const filtersOptions = {
    User_EmailAddress: {
        allText: ALL_ACCOUNTS_FILTER_TEXT,
    },
    Brand_Name: {
        allText: ALL_BRANDS_TEXT,
        valuePropName: 'Brand_ID',
    },
    Device_MainVersion: {
        allText: ALL_VERSIONS_FILTER_TEXT,
    },
    laneType: {
        allText: ALL_LANE_TYPES,
        valuePropName: 'Device_LaneConfig_ID',
    },
    country: {
        allText: ALL_COUNTRIES,
        valuePropName: 'Store_Country_ID',
    },
    Store_Region: {
        allText: ALL_REGIONS,
    },
    Timezone: {
        allText: ALL_TIMEZONES,
    },
};

const getCountryName = (countryId, countries)  => {
    const country = countries.find(({ Id: id }) => id === countryId) || { Name: 'Unknown' };

    return country.Name;
}

const getAddressLine = ({ Store_AddressLine1, Store_AddressLine2, Store_AddressLine3 }) => (
    `${Store_AddressLine1 || ''}\n${Store_AddressLine2 || ''}\n${Store_AddressLine3 || ''}`
);

const devicesToRows = (devices, countries) => devices.map(device => ({
    ...device,
    country:  getCountryName(device.Store_Country_ID, countries),
    laneType: getLaneType(device.Device_LaneConfig_ID),
    addressLine: getAddressLine(device),
}));

const searchByProps = ['User_EmailAddress', 'Device_SerialNumber', 'Store_Number', 'Store_Name', 'Device_EmailAccount'];

export const DevicesSection = ({
    countries,
    devices,
    isLoading,
    sectionNumber,
    disabled,
    selectedDevices,
    onSelectionChange,
    onFiltersCountChange
}) => {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [availableFilters, setAvailableFilters] = useState([]);
    const [gridFilters, setGridFilters] = useState({});
    const [searchValue, setSearchValue] = useState('');
    const [filtersCount, setFiltersCount] = useState(0);
    const resetFiltersTrigger = useTrigger();

    const onFiltersReset = useCallback(() => {
        resetFilters(availableFilters, setGridFilters);
    }, [availableFilters, setGridFilters]);
    useOnTrigger(resetFiltersTrigger, onFiltersReset);

    useEffect(() => {
        setRows(devicesToRows(devices, countries));
    }, [devices, countries, setRows]);

    useEffect(() => {
        const newFilteredDevices = applyGridFilters(filtersOptions, rows, gridFilters);
        const newDevices = applySearchValue(newFilteredDevices, searchByProps, searchValue);

        setFilteredRows(newDevices);
    }, [rows, gridFilters, searchValue, setFilteredRows]);

    useEffect(() => {
        setFiltersCount(getFiltersCount(availableFilters, gridFilters));
    }, [availableFilters, gridFilters]);

    useAvailableFilters(filtersOptions, rows, setGridFilters, setAvailableFilters);

    return (
        <Section
            number={sectionNumber}
            title='Review and download ZOOM device file'
            disabled={disabled}
            className='admin-create-apply-store-settings-task-devices'
        >
            <div className='admin-create-apply-store-settings-task-devices-header'>
                <span className='admin-create-apply-store-settings-task-devices-count'>
                    {devices.length}&nbsp;
                    <span
                        className='admin-create-apply-store-settings-task-devices-count-label'
                    >
                        { devices.length !== 1 ? 'devices' : 'device' } found
                    </span>
                </span>
                <span className='admin-create-apply-store-settings-task-filters-count'>
                    <GridFiltersCount
                        count={filtersCount}
                        onFiltersReset={onFiltersReset}
                        variants={['single-line']}
                        />
                </span>
                <span className={classNames(
                        'admin-create-apply-store-settings-task-devices-count',
                        'admin-create-apply-store-settings-task-selected-devices-count',
                        {
                            'admin-create-apply-store-settings-task-no-selected-devices-count': selectedDevices.length === 0,
                        }
                    )}>
                    {selectedDevices.length}&nbsp;
                    <span
                        className='admin-create-apply-store-settings-task-devices-count-label'
                    >
                        { selectedDevices.length !== 1 ? 'devices' : 'device' } selected
                    </span>
                </span>
                <SearchInput
                    value={searchValue}
                    placeholder='Search for Device'
                    onChange={setSearchValue}
                />
            </div>
            <Grid
                rows={filteredRows}
                isLoading={isLoading}
                headers={gridOptions}
                noRecordsMessage='No devices were found'
                availableFilters={availableFilters}
                filters={gridFilters}
                onFiltersChange={setGridFilters}
                selection={selectedDevices}
                selectable={true}
                onSelectionChange={onSelectionChange}
                onFiltersCountChange={setFiltersCount}
                resetFiltersTrigger={resetFiltersTrigger}
            />
        </Section>
    );
};
