import { createContest } from 'services/LeaderboardContests';

import { prepareContestDTO } from '../utils/prepareContestDTO';

export const createLeaderboardContest = ({
    name,
    description,
    scheduledTimePeriod,
    recurrenceDays,
    contestStores,
    metric,
    metricDetails,
    reward,
    rewardImg,
    timeZone,
    endDate,
    startDate,
    language
}) => {
    const payloadValues = prepareContestDTO({
        name,
        description,
        scheduledTimePeriod,
        recurrenceDays,
        contestStores,
        metric,
        metricDetails,
        reward,
        rewardImg,
        timeZone,
        endDate,
        startDate,
        language
    });

    return createContest(payloadValues);
};
