import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { omit } from 'ramda';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'library/Loader';
import { NotificationsList } from 'library/NotificationsList';
import { createBatchUpgrade } from 'services/Device';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { Header } from './Header';
import { Footer } from './Footer';
import { SourceSection } from './SourceSection';
import { FilterSection } from './FilterSection';
import { TargetDevicesSection } from './TargetDevicesSection';
import { useLoadDeviceVersions } from '../hooks/useLoadDeviceVersions';
import { useLoadUpgradeVersions } from '../hooks/useLoadUpgradeVersions';
import { useLoadBrands } from 'hooks/useLoadBrands';
import { useLoadCountries } from 'hooks/useLoadCountries';
import { useTrigger } from 'hooks/useTrigger';
import { filterTypes, ADMIN_DEVICE_UPGRADES_URL, MAX_DEVICES_BULK_UPGRADE, MIN_DEVICES_FOR_POPUP } from '../constants';
import { formDataValidator, validationFieldsMessagesMap, formatInstallSchedulePayload } from '../helpers';
import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';
import { upgradeStatuses as UPGRADE_STATUSES } from '../constants/upgradeStatuses';
import { daysOfWeekNames } from '../constants/weekDays';
import { NEXEO_BASE_STATION } from 'constants/NEXEOPeripheralTypes';
import { CommonConstants } from 'Constants';

import './CreateDeviceUpgrade.scss';


const getNewTaskType = (upgrades) => {
    const isUpgradePendingApproval = upgrades.every(({ Status }) => Status === UPGRADE_STATUSES.PENDING_APPROVAL);

    if (isUpgradePendingApproval) {
        return UPGRADE_STATUSES.PENDING_APPROVAL;
    } else {
        return UPGRADE_STATUSES.SCHEDULED;
    }
};

const formatMessageTime = (time) => time.replace(/am|pm/i, '').trim();

const getDaysOfWeekNames = (daysOfWeek = '', t) => {
    const TOTAL_DAYS_OF_WEEK = 7;
    const daysOfWeekList = daysOfWeek.split(',').map(Number).sort();

    if (daysOfWeekList.length === TOTAL_DAYS_OF_WEEK) {
        return t('device-upgrades__section__form--all-days-of-the-week');
    }

    return daysOfWeekList.map(index => t(daysOfWeekNames[index])).join(', ');
}

const createBatchUpgradeTask = async ({ upgradeData, setIsCreatingDeviceUpgrade, navigate }) => {
    try {
        setIsCreatingDeviceUpgrade(true);
        const { installRunDate, installScheduleDays, installTo, installFrom, ...upgradePayload } = upgradeData;
        const result = await createBatchUpgrade({
            ...upgradePayload,
            installSchedule: formatInstallSchedulePayload({
                installRunDate,
                installScheduleDays,
                installTo,
                installFrom,
            }),
        });

        navigate(ADMIN_DEVICE_UPGRADES_URL);

        if (getNewTaskType(result) === UPGRADE_STATUSES.PENDING_APPROVAL) {
            setTimeout(() => {
                addErrorNotification(
                    'device-upgrades__notification__upgrade-pending-approval',
                    { className: 'hme-notification hme-info-message' },
                );
            }, 1000);
        } else {
            setTimeout(() => {
                addSuccessNotification('device-upgrades__notification__upgrade-succesful');
            }, 1000);
        }
    } catch (e) {
        addErrorNotification('device-upgrades__notification__upgrade-failed');
        setIsCreatingDeviceUpgrade(false);
    }
};

export const CreateDeviceUpgrade = () => {
    const [upgradeData, setUpgradeData] = useState({});
    const [isFilterSectionDisabled, setIsFilterSectionDisabled] = useState(true);
    const [isCreatingDeviceUpgrade, setIsCreatingDeviceUpgrade] = useState(false);
    const [foundDevices, setFoundDevices] = useState([]);
    const [filterType, setFilterType] = useState(filterTypes.BRAND);
    const [brandFilters, setBrandFilters] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [hasShownMaxDevicesWarning, setHasShownMaxDevicesWarning] = useState(false);
    const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
    const [isUpgradeDataValid, setIsUpgradeDataValid] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { deviceVersions, isDeviceVersionsLoading } = useLoadDeviceVersions();
    const { upgradeVersions, isUpgradeVersionsLoading } = useLoadUpgradeVersions();
    const { brands, isBrandsLoading } = useLoadBrands();
    const { countries, isCountriesLoading } = useLoadCountries();
    const removeDevicesTrigger = useTrigger();
    const changeUpgradeInfoTrigger = useTrigger();

    const brandDevicesTotal = useMemo(() => {
        const { timezones = [], excludedBrandDevicesByTimezone = {} } = brandFilters;
        const resultTotal = timezones.reduce((total, { quantity }) => total + quantity, 0);
        const excludedTotal = Object.values(excludedBrandDevicesByTimezone).reduce(
            (total, { count }) => total + count,
            0,
        );

        return resultTotal - excludedTotal;
    }, [brandFilters]);

    useEffect(() => {
        if (
            !hasShownMaxDevicesWarning &&
            (foundDevices.length >= MAX_DEVICES_BULK_UPGRADE || brandDevicesTotal >= MAX_DEVICES_BULK_UPGRADE)
        ) {
            addErrorNotification(
                t('device-upgrades__notification__max-devices-upgrade', {
                    count: MAX_DEVICES_BULK_UPGRADE,
                }),
                {
                    className: 'hme-notification hme-info-message',
                },
            );
            setHasShownMaxDevicesWarning(true);
        }

        setUpgradeData((prevUpgradeData) => ({
            ...prevUpgradeData,
            stores: foundDevices,
        }));
    }, [foundDevices, brandDevicesTotal]);

    useEffect(() => {
        const validationErrors = formDataValidator(upgradeData);
        setIsUpgradeDataValid(!validationErrors.status);
    }, [upgradeData]);

    const checkIsValidUpgradeInfo = useCallback((formData) => {
        const optionalFields = [
            'deviceInstallDate',
            'deviceInstallTimeFrom',
            'deviceInstallTimeTo',
            'installScheduleDays',
        ];

        return Object.keys(formData)
            .filter((key) => !optionalFields.includes(key))
            .every(
                (key) =>
                    (Array.isArray(formData[key]) && formData[key].length) ||
                    (!Array.isArray(formData[key]) && formData[key]),
            );
    }, []);

    const resetFormErrors = useCallback(
        (formData, updatedFields) => {
            // find specific updated field in the form
            const changedField = Object.keys(updatedFields).find(
                (fieldKey) => updatedFields[fieldKey] !== formData[fieldKey],
            );

            if (changedField) {
                let formErrorsToRemove = validationFieldsMessagesMap[changedField] || [];

                if (changedField === 'deviceType') {
                    formErrorsToRemove = [
                        ...formErrorsToRemove,
                        ...validationFieldsMessagesMap.targetDeviceVersions,
                        ...validationFieldsMessagesMap.upgradeVersion,
                    ];
                }
                setFormErrors(omit(formErrorsToRemove, formErrors));
            }
        },
        [validationFieldsMessagesMap],
    );

    const onUpgradeInfoChange = useCallback(
        (formData) => {
            // Only utilize peripheralDeviceType for peripheral devices
            const baseStation = NEXEO_BASE_STATION.modelName;
            const nexeo = CommonConstants.deviceType.nexeo.camelCase;

            const peripheralDeviceType = formData.targetPeripheralDeviceType === baseStation || formData.targetDeviceType !== nexeo
                ? '' : formData.targetPeripheralDeviceType;

            const infoData = {
                name: formData.upgradeName,
                deviceType: formData.targetDeviceType,
                peripheralDeviceType,
                targetDeviceVersions: formData.targetDeviceVersions,
                maxTargetDeviceVersion: formData.maxTargetDeviceVersion,
                upgradeVersion: formData.upgradeVersion,
                runDate: formData.deviceUpgradeDate,
                from: formData.deviceUpgradeTimeFrom,
                to: formData.deviceUpgradeTimeTo,
                scheduleDays: formData.upgradeScheduleDays,
                installRunDate: formData.deviceInstallDate, 
                installScheduleDays: formData.installScheduleDays,
                installFrom: formData.deviceInstallTimeFrom,
                installTo: formData.deviceInstallTimeTo,
            };

            resetFormErrors(upgradeData, infoData);

            const isValidUpgradeInfo = checkIsValidUpgradeInfo(formData);
            setIsFilterSectionDisabled(!isValidUpgradeInfo);

            if (
                upgradeData &&
                (upgradeData.deviceType !== formData.targetDeviceType ||
                    upgradeData.targetDeviceVersions.some(version => !formData.targetDeviceVersions.includes(version)))
            ) {
                setFoundDevices([]);
                changeUpgradeInfoTrigger && changeUpgradeInfoTrigger.trigger();
            }

            setUpgradeData((prevUpgradeData) => ({
                ...prevUpgradeData,
                ...infoData,
            }));
        },
        [upgradeData, isFilterSectionDisabled, resetFormErrors, checkIsValidUpgradeInfo, changeUpgradeInfoTrigger],
    );

    const startUpgrade = async () => {
        setIsConfirmPopupOpen(false);
        await createBatchUpgradeTask({
            upgradeData: {
                ...upgradeData,
                brandFilters,
            },
            setIsCreatingDeviceUpgrade,
            navigate,
        });
    };

    const onApply = useCallback(async () => {
        const errors = formDataValidator(upgradeData);
        if (errors.status) {
            setFormErrors(errors);
            return;
        }

        setFormErrors({});

        if (upgradeData.stores.length >= MIN_DEVICES_FOR_POPUP || brandDevicesTotal >= MIN_DEVICES_FOR_POPUP) {
            setIsConfirmPopupOpen(true);
        } else {
            await startUpgrade();
        }
    }, [upgradeData, formDataValidator]);

    const onRemoveAllDevices = useCallback(() => {
        if (!foundDevices.length) {
            return;
        }

        const deviceIDsToRemove = foundDevices.map((d) => d.Device_ID);
        removeDevicesTrigger && removeDevicesTrigger.trigger(deviceIDsToRemove);
        setFoundDevices([]);
    }, [foundDevices, removeDevicesTrigger, brandFilters]);

    const onRemoveDevice = useCallback(
        (deviceID) => {
            removeDevicesTrigger && removeDevicesTrigger.trigger([deviceID]);
            setFoundDevices((prevFoundDevices) => prevFoundDevices.filter((d) => d.Device_ID !== deviceID));
        },
        [removeDevicesTrigger],
    );

    const onFilterTypeSelection = useCallback((type) => {
        setBrandFilters({
            ...brandFilters,
        });
        setFilterType(type);
        setFoundDevices([]);
    }, []);

    const onCancel = useCallback(() => {
        navigate(ADMIN_DEVICE_UPGRADES_URL);
    }, [navigate]);

    const onRemoveBrandDevice = useCallback(({ WindowsTimeZone, Device_ID }) => {
        setBrandFilters((prevBrandFilters) => {
            const { excludedBrandDevicesByTimezone } = prevBrandFilters;

            return {
                ...prevBrandFilters,
                excludedBrandDevicesByTimezone: {
                    ...excludedBrandDevicesByTimezone,
                    [WindowsTimeZone]: excludedBrandDevicesByTimezone[WindowsTimeZone]
                        ? {
                            count: ++excludedBrandDevicesByTimezone[WindowsTimeZone].count,
                            deviceIDs: [...excludedBrandDevicesByTimezone[WindowsTimeZone].deviceIDs, Device_ID],
                        }
                        : {
                            count: 1,
                            deviceIDs: [Device_ID],
                        },
                }
            }
        })
    }, []);

    const onSelectTimezone = useCallback((brandFilter) => {
        const { excludedBrandDevicesByTimezone } = brandFilters;
        const windowsTimeZones = (brandFilter.timezones || []).map(({ timezone }) => timezone);
        const brandDevicesByTimezoneToRemove = windowsTimeZones.reduce(
            (acc, tz) =>
                excludedBrandDevicesByTimezone[tz]
                    ? {
                        ...acc,
                        [tz]: excludedBrandDevicesByTimezone[tz],
                    }
                    : acc,
            {},
        );

        setBrandFilters({
            ...brandFilter,
            excludedBrandDevicesByTimezone: brandDevicesByTimezoneToRemove
        });

        // clear devices if all the timezones are unchecked
        if (!brandFilter.timezones.length) {
            setFoundDevices([]);
        }
    }, [brandFilters]);

    const isTargetDevicesSectionDisabled = !foundDevices.length && !brandFilters.timezones?.length;
    const isSubmitEnabled = foundDevices.length && !isCreatingDeviceUpgrade && isUpgradeDataValid;

    if (
        isDeviceVersionsLoading ||
        isUpgradeVersionsLoading ||
        isBrandsLoading ||
        isCountriesLoading
    ) {
        return (
            <div className="hme-components create-device-upgrade-wrapper">
                <NotificationsList />
                <div className="create-device-upgrade">
                    <Loader />
                </div>
            </div>
        );
    }

    const deviceCount = upgradeData.stores && Math.max(upgradeData.stores.length, brandDevicesTotal);
    const popupMessage = (
        <>
            <div>
                <Trans i18nKey="create-device-upgrade--confirm-action" deviceCount={deviceCount}>
                    <span>You are about to upgrade </span>
                    <span id="create-device-upgrade-deviceCount">{{ deviceCount }} devices.</span>
                    <div> This requires an approval.</div>
                    <div> Are you sure you want to proceed?</div>
                </Trans>
            </div>
            {upgradeData.installRunDate && (
                <div className="create-device-upgrade__install-schedule-message">
                    <Trans i18nKey="create-device-upgrade--confirm-action-install-schedule">
                        <strong>Installation Schedule</strong>
                        <div>Days of week: ({{ daysOfWeek: getDaysOfWeekNames(upgradeData.installScheduleDays, t) }})</div>
                        <div>Date: {{ date: upgradeData.installRunDate }}</div>
                        <div>Time</div>
                        <div>Start: {{ startTime: formatMessageTime(upgradeData.installFrom) }}</div>
                        <div>End: {{ endTime: formatMessageTime(upgradeData.installTo) }}</div>
                    </Trans>
                </div>
            )}
        </>
    );

    return (
        <div className="hme-components create-device-upgrade-wrapper">
            <NotificationsList />
            <ConfirmPopupComponentGeneric
                show={isConfirmPopupOpen}
                onHide={() => setIsConfirmPopupOpen(false)}
                title={t('common__double-checking')}
                message={popupMessage}
                actions={[
                    {
                        children: t('common__popup--cancel-action'),
                        onClick: () => setIsConfirmPopupOpen(false)
                    },
                    {
                        children: t('common__popup--confirm-action'),
                        variants: ['submit'],
                        onClick: () => startUpgrade(),
                    }
                ]}
            />
            <div className="create-device-upgrade">
                <div className="create-device-upgrade-header">
                    <Header />
                </div>
                <div className="create-device-upgrade-sections">
                    <SourceSection
                        sectionNumber={1}
                        formErrors={formErrors}
                        deviceVersions={deviceVersions}
                        upgradeVersions={upgradeVersions}
                        onUpgradeInfoChange={onUpgradeInfoChange}
                    />
                    <FilterSection
                        devices={foundDevices}
                        filterType={filterType}
                        sectionNumber={2}
                        disabled={isFilterSectionDisabled}
                        brands={brands}
                        countries={countries}
                        brandFilters={brandFilters}
                        selectedDeviceType={upgradeData.deviceType}
                        selectedPeripheralDeviceType={upgradeData.peripheralDeviceType}
                        targetDeviceVersions={upgradeData.targetDeviceVersions}
                        onSelectDevices={setFoundDevices}
                        onSelectTimezone={onSelectTimezone}
                        onSelectStoresByBrand={setFoundDevices}
                        onSelectStores={setFoundDevices}
                        onFilterTypeSelection={onFilterTypeSelection}
                        removeDevicesTrigger={removeDevicesTrigger}
                        changeUpgradeInfoTrigger={changeUpgradeInfoTrigger}
                    />
                    <TargetDevicesSection
                        filterType={filterType}
                        devices={foundDevices}
                        brandDevicesTotal={brandDevicesTotal}
                        brandFilters={brandFilters}
                        selectedDeviceType={upgradeData.deviceType}
                        selectedPeripheralDeviceType={upgradeData.peripheralDeviceType}
                        sectionNumber={3}
                        disabled={isTargetDevicesSectionDisabled}
                        onLoadBrandDevices={setFoundDevices}
                        onRemoveAllDevices={onRemoveAllDevices}
                        onRemoveDevice={onRemoveDevice}
                        onRemoveBrandDevice={onRemoveBrandDevice}
                    />
                </div>
                <Footer isSubmitEnabled={isSubmitEnabled} onCancel={onCancel} onApply={onApply} />
            </div>
        </div>
    );
};
