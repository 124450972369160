import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Grid, headersType } from 'components/Common/Grid';
import { availableFiltersType } from 'library/TileList/types/availableFiltersType';

const sortDirections = [1, -1];

export const List = ({
    rows,
    headers,
    isLoading,
    sortSelection,
    onSortChange,
    availableFilters,
    filters,
    onFiltersChange
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Grid
                rows={rows}
                rowKey='User_UID'
                headers={headers}
                noRecordsMessage={t('users__no-users-found')}
                isLoading={isLoading}
                loadingText={t('common__loading')}
                sortDirections={sortDirections}
                sortSelection={sortSelection}
                onSortChange={onSortChange}
                availableFilters={availableFilters}
                filters={filters}
                onFiltersChange={onFiltersChange}
            />
        </>
    );
};

List.propTypes = {
    rows: PropTypes.array,
    headers: headersType,
    isLoading: PropTypes.bool,
    sortSelection: PropTypes.object,
    onSortChange: PropTypes.func,
    availableFilters: availableFiltersType,
    filters: PropTypes.shape({}),
    onFiltersChange: PropTypes.func,
};
