/* eslint-disable react/prop-types */
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import classNames from 'classnames';
import * as R from 'ramda';
import { Label } from 'library/Label';
import { Select } from 'library/Select';
import React, { useState } from 'react';
import './EnhancedFilter.scss';
import { MultiPopup } from './MultiPopup';
import { allMonths } from 'containers/Awards/AwardsResettable/config';
import { useTranslation } from 'react-i18next';
import { DateLib } from '@hme-cloud/utility-common';

export const EnhancedFilter = ({
    items = [],
    selection,
    allText,
    onChange,
    year: aYear,
    month: aMonth,
    isPopupShown,
    setIsPopupShown,
    loading,
    startYear
}) => {
    const [year, setYear] = useState(aYear || String(startYear));
    const [month, setMonth] = useState(aMonth || '0');
    const { t } = useTranslation();
    
    const onStoreChange = (stores) => {
        onChange({ stores, isPopupShown });
    };
    const getYearOptions = () => {
        const currentYear = new DateLib().format(DateLib.FORMAT_TYPES.YEAR_ONLY);
        return R.compose(
                R.update(0, {
                    text: t('common__all-years'),
                    value: String(startYear)
                }),
                R.map((year) => {
                    const value = String(year);
                    return ({
                        text: value,
                        value
                    });
                }),
                R.range(startYear),
                R.inc
        )(currentYear);
    };

    const HeaderComponent = () => {
        return (
            <>
                <div className="hme-enhanced-filter-additional-header">
                    <div>
                        <Label>{t('common__month')}</Label>
                        <Select
                            placeholder={(month && month != 0) ? t(allMonths[month]) : t('common__all-months')}
                            value={month}
                            onChange={(value) => {
                                setMonth(value);
                                const payload = { month: value, isPopupShown };
                                if (String(year) === String(startYear) && Number(value) > 0 ) {
                                    const currentYear = new DateLib().formatInUTC(DateLib.FORMAT_TYPES.YEAR_ONLY);
                                    payload.year = currentYear;
                                }
                                onChange(payload);
                            }}
                        >
                            {allMonths.map((value, i) => ({
                                text: t(value),
                                value: String(i)
                            }))}
                        </Select>
                    </div>
                    <div>
                        <Label>{t('common__year')}</Label>
                        <Select
                            placeholder={(year && year != startYear) ? year : t('common__all-years')}
                            value={year}
                            onChange={(value) => {
                                setYear(value);
                                const payload = { year: value, isPopupShown };
                                if (String(value) === String(startYear)) {
                                    payload.month = '0';
                                }
                                onChange(payload);
                            }}
                        >
                            {getYearOptions()}
                        </Select>
                    </div>
                </div>
                <Label>{t('common__stores')}</Label>
            </>
        );
    };
    return (
        <span className={classNames('hme-enhanced-filter', {
            'hme-enhanced-filter-opened': isPopupShown,
            'hme-enhanced-filter-loading': loading
        })}>
            <FilterIcon
                className="hme-enhanced-filter-icon"
                onClick={() => setIsPopupShown(!isPopupShown)}
            />
            <MultiPopup
                show={isPopupShown}
                items={items}
                selection={selection}
                allText={allText}
                onChange={onStoreChange}
                onHide={() => {
                    onChange({ isPopupShown: false });
                }}
                HeaderComponent={HeaderComponent}
                loading={loading}
            />
        </span>
    );
};
