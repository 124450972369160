import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { navigationService } from 'components/Common/NavigationServices';
import './UserEmails.scss';

export const UserEmails = ({ store }) => {
    const { User_UID: userUID, User_EmailAddress: emailAddress, Company_Type: type } = store;
    const linkUrl = useMemo(() =>
        navigationService.getMenu('account', userUID, null, type),
    [userUID, type]
    );
    return (
        <Link className="hme-admin-stores-status__list__account-email" to={linkUrl}>
            {emailAddress}
        </Link>
    );
};

UserEmails.propTypes = {
    store: PropTypes.shape({
        User_UID: PropTypes.string,
        User_EmailAddress: PropTypes.string,
        Company_Type: PropTypes.string
    }).isRequired
};
