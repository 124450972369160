import PropTypes from 'prop-types';

export const permissionProp = PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
});

export const permissionsProp = PropTypes.arrayOf(permissionProp);
