import React from 'react';

import { InputComponent } from '..';

import { ReactComponent as SearchIcon } from '../../../images/IconSearchField.svg';
import './SearchInput.scss';

export const SearchInput = ({ variants = [], onSearchIconClick, children, ...inputProps }) => (
    <InputComponent
        {...inputProps}
        variants={[...variants, 'search']}
    >
        <SearchIcon className='hme-input-search-icon' onClick={onSearchIconClick}/>
        { children }
    </InputComponent>
);
