/* eslint-disable new-cap */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import './ConfigureAwards.scss';
import { Modal } from 'library/Modal';
import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { GetLeaderboardPreference } from 'actions/leaderboard';
import hideGoalFilter from './helpers/hideGoalFilter';
import awardApi from 'api/awardApi';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'components/Common/Grid';
import { addNotification, dismissNotification, NOTIFICATION_TYPES } from 'services/Notifications';
import { useTranslation } from 'react-i18next';
import { awardKeys } from '../config';

const gridHeaders = [{
    text: 'awards__award-name',
    property: 'AwardShortName'
}, {
    text: 'common__description',
    property: 'AwardName'
}];
export const ConfigureAwards = ({ show, setShow, masqueradeUser, ...rest }) => {
    const dispatch = useDispatch();
    const getLeaderboardPreference = bindActionCreators(GetLeaderboardPreference, dispatch);
    const [awards, setAwards] = useState([]);
    const [groupedAwards, setGroupedAwards] = useState({});
    const [translatedDefaults, setTranslatedDefaults] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [diffs, setDiffs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hideGoal, setHideGoal] = useState(null);
    const { t } = useTranslation();

    const serializer = (list = []) => list.map(({ AwardID, AwardStatus }) =>
        ({ AwardID, AwardStatus })
    );

    const getDiffs = (newTranslatedDefaults = [], newGroupedAwards = []) => R.difference(
            serializer(newTranslatedDefaults),
            serializer(newGroupedAwards.default)
    );

    const groupAwards = (newHideGoal = hideGoal, newAwards = awards) => {
        const byType = R.groupBy(R.path(['AwardType', 'Name']));
        const newGroupedAwards = byType(R.filter(hideGoalFilter(newHideGoal), newAwards));
        return newGroupedAwards;
    };

    const translateProperties = (list = []) => {
        return list.map((el) => {
            const cloned = { ...el };
            gridHeaders.forEach(({ property }) => {
                const key = cloned[property];
                cloned[property] = t(awardKeys[property][key]);
            });
            return cloned;
        });
    };

    const getAwards = async () => {
        setLoading(true);
        const newAwards = await awardApi.getAwards();
        const newGroupedAwards = groupAwards(hideGoal, newAwards);
        setAwards(newAwards);
        setGroupedAwards(newGroupedAwards);
        setLoading(false);
    };
    useEffect(() => {
        getLeaderboardPreference();
    }, [dispatch]);

    const leaderBoardPreference = useSelector((state) => state.leaderboard.leaderBoardPreference);
    useEffect(() => {
        const { PreferenceHideGoal: preferenceHideGoal } = leaderBoardPreference || {};
        const newHideGoal = preferenceHideGoal ? preferenceHideGoal === '1' : hideGoal;
        setHideGoal(newHideGoal);
        getAwards();
    }, [leaderBoardPreference]);

    useEffect(() => {
        const newGroupedAwards = groupAwards(hideGoal, awards);
        setGroupedAwards(newGroupedAwards);
    }, [hideGoal, awards]);

    const mapCheckboxProps = (list = []) => list.map((row) => {
        row.checkboxProps = { label: <></> };
        return row;
    });

    useEffect(() => {
        const newTranslatedDefaults = mapCheckboxProps(translateProperties(groupedAwards.default || []));
        setTranslatedDefaults(newTranslatedDefaults);
        setSelectedRows(newTranslatedDefaults.filter((row) => row['AwardStatus']));
        const newDiffs = getDiffs(newTranslatedDefaults, groupedAwards);
        setDiffs(newDiffs);
    }, [groupedAwards.default]);

    const onConfirm = async () => {
        try {
            const body = {
                awards: diffs,
                MasqueradeUser: masqueradeUser
            };
            await awardApi.updateAwards(body);
            await getAwards();

            dismissNotification();
            addNotification({
                message: t('awards__configure-awards--settings-saved'),
                type: NOTIFICATION_TYPES.SUCCESS
            });
        } catch (e) {
            dismissNotification();
            addNotification({
                message: t('common__error-processing-request'),
                type: NOTIFICATION_TYPES.ERROR
            });
        }
    };

    const onHidePopup = () => {
        setShow(false);
        getAwards();
    };
    const onSelectionChange = (newSelectedRows) => {
        const newTranslatedDefaults = translatedDefaults.map((row) => {
            const isOn = newSelectedRows.includes(row);
            row.checkboxProps = { label: <></> };
            row['AwardStatus'] = Number(isOn);
            return row;
        });
        setTranslatedDefaults(newTranslatedDefaults);
        setSelectedRows(newSelectedRows);
        const newDiffs = getDiffs(newTranslatedDefaults, groupedAwards);
        setDiffs(newDiffs);
    };

    return (
        <Modal
            show={show}
            onHide={onHidePopup}
            actions={[{
                children: t('common__cancel'),
                onClick: onHidePopup
            }, {
                children: t('common__save'),
                variants: ['submit'],
                disabled: !diffs.length,
                onClick: () => {
                    onConfirm && onConfirm();
                    onHidePopup && onHidePopup();
                }
            }]}
            dialogClassName="hme-awards-resettable-configure"
            title={t('awards__configure-awards')}
            {...rest}
        >
            <Grid
                rows={translatedDefaults || []}
                headers={gridHeaders}
                rowKey="AwardID"
                isLoading={loading}
                selectable
                selection={selectedRows}
                onSelectionChange={onSelectionChange}
            />
        </Modal>
    );
};
