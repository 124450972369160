import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as Icon } from 'assets/icons/down.svg';
import { Button } from 'library/Button';

export const DetailsButton = ({ isDetailsVisible, onDetailsButtonClick }) => {
    const { t } = useTranslation();

    const buttonName = useMemo(() => {
        return isDetailsVisible ? t('common__hide-details') : t('common__show-details');
    }, [isDetailsVisible]);

    return (
        <Button
            variants={['transparent']}
            onClick={onDetailsButtonClick}
            className="hme-stores-mobile--public__details__button"
        >
            {buttonName}
            <Icon className={classNames('hme-stores-mobile--public__expand__icon', {'up': isDetailsVisible})} />
        </Button>
    );
};

DetailsButton.propTypes = {
    isDetailsVisible: PropTypes.bool.isRequired,
    onDetailsButtonClick: PropTypes.func.isRequired,
};
