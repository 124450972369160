import * as Permissions from '../actionTypes/Permissions/Permissions';
import Api from '../Api';
import { Config } from '../Config';
import { CommonConstants } from '../Constants';
import AuthenticationService from '../components/Security/AuthenticationService';
import SecurityService from '../components/Security/SecurityService';
import { filterReportsBySubscription } from 'components/PbiReportScreen/helpers/FilterReportsBySubscription';
import { getProfile } from 'services/Auth';
const _ = require('underscore');

const { apiUrls, subscription, userPermissions } = CommonConstants;

/**
 * @param {*} permissions
 * @return {{type: string, userPermissions: any}}
 */
function getPermissionSuccess(permissions) {
    return {
        type: Permissions.GET_USER_PERMISSIONS,
        userPermissions: permissions
    };
}
/**
 * @param {*} permissions
 * @param {*} authService
 * @param {Function} dispatch
 */
function apiGetPermissions(permissions, authService, dispatch) {
    const api = new Api();
    if (!authService.isAdmin()) {
        let url = Config.apiBaseUrl + apiUrls.getPermissions;
        if (authService.getLoggedInUserUID()) {
            url += '?uuid=' + authService.getLoggedInUserUID();
        }
        api.getData(url, (data) => {
            const changedPermissions = _.pluck(data.data[0], 'Permission_Name');
            if (changedPermissions !== permissions) {
                authService.setPermission(changedPermissions);
                dispatch(getPermissionSuccess(permissions));
                // callBack(changedPermissions)
            }
        });
    } else {
        dispatch(getPermissionSuccess([]));
    }
}

export const getPermission = (callBack) => {
    const authService = new AuthenticationService(Config.authBaseUrl);
    const permissions = authService.getPermission();

    return (dispatch, getState) => {
        if (permissions.length === 0) {
            apiGetPermissions(permissions, authService, dispatch);
        } else {
            dispatch(getPermissionSuccess(permissions));
            apiGetPermissions(permissions, authService, dispatch);
        }
    };
};

export const checkAccess = (permission) => {
    const securityService = new SecurityService();
    return securityService.hasAccess(permission);
};


export const PermissionFor = Object.seal({
    Contest: 'contests',
    Award: 'awards',
    SmackTalk: 'smacktalk',
    Avatar: 'avatars',
    AnnouncementSettings: 'announcementSettings',
    Feedback: 'feedback',
    LBTemplates: 'leaderboardTemplates'
});

const subscriptionLevels = [subscription.premiumStoreDriveThru, subscription.nitroLeaderboard];
const leaderboardOnlySubscriptionLevel = 2;

/**
 * Checks if current profile has access to given feature
 * @param {'smacktalk' | 'contests' | 'awards' | 'feedback' | 'avatars' | 'announcementSettings'} feature
 * @return {*} acccess
 */
export const hasGamificationFeature = (feature) => {
    const { SL: subscriptionLevel, IsNitro } = getProfile();

    // If not Nitro Gamification subscription or Nitro leaderboard subscription with 'leaderboardTemplates' feature return false
    if (!subscriptionLevels.includes(`${subscriptionLevel}`) &&
    !(subscriptionLevel == leaderboardOnlySubscriptionLevel && IsNitro == 1 && feature === 'leaderboardTemplates')) {
        return false;
    }

    const permissionMap = {
        smacktalk: [userPermissions.SmackTalk],
        contests: [userPermissions.EditLeaderboard],
        awards: [userPermissions.EditLeaderboard],
        feedback: [userPermissions.EditLeaderboard],
        avatars: [userPermissions.EditLeaderboard],
        announcementSettings: [userPermissions.EditLeaderboard],
        leaderboardTemplates: [userPermissions.EditLeaderboard, userPermissions.ViewLeaderboard]
    };

    return permissionMap[feature].some(checkAccess);
};

// 2 is for PremiumStoreWithLeaderboard, for some reason it is 2 here but 6 in subscription, need to double check why
const subscriptionLevelsLb = ['2', subscription.premiumStoreDriveThru, subscription.nitroLeaderboard];

/**
 * Checks if current profile has access to Leaderboards (3x or 4x)
 * @return {{accessLevel: string[], hasSubscription: boolean, isNitro: number}}
 */
export const hasLeaderboardAccess = () => {
    const { SL: subscriptionLevel, IsAccountOwner, IsNitro: isNitro } = getProfile();
    const permissions = [userPermissions.ViewLeaderboard, userPermissions.EditLeaderboard];

    let hasSubscription = false;
    let accessLevel = [];

    if (!subscriptionLevelsLb.includes(`${subscriptionLevel}`)) {
        return { accessLevel, hasSubscription };
    }

    hasSubscription = true;

    if (IsAccountOwner) {
        accessLevel = [...permissions];
        return { accessLevel, hasSubscription, isNitro };
    }

    accessLevel = permissions.filter((item) => checkAccess(item));
    console.log(accessLevel, hasSubscription, isNitro);

    return { accessLevel, hasSubscription, isNitro };
};

export const hasReportAccess = (reportType) => {
    const reportList = filterReportsBySubscription();
    const allowedReportTypes = reportList.map((report)=> report.type);
    return allowedReportTypes.includes(reportType);
};
