/* eslint-disable react/prop-types */
import React from 'react';
import { always } from 'ramda';
import classNames from 'classnames';

import './PureButton.scss';

const defaultOnClick = always();

export const PureButton = ({
    className,
    onClick = defaultOnClick,
    disabled,
    type = 'button',
    ...props
}) => {
    return <button
        type={type}
        onClick={!disabled ? onClick : defaultOnClick }
        disabled={disabled}
        className={classNames('hme-pure-button', className)}
        {...props}
    />;
};
