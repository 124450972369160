import React, { useState, useEffect, useCallback } from 'react';
import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import { useTrigger } from 'hooks/useTrigger';
import { UploadModal } from 'library/UploadModal';
import { LoadingModal } from 'library/LoadingModal';
import { NotificationsList } from 'library/NotificationsList';
import { addNotification, NOTIFICATION_TYPES } from 'services/Notifications';
import { Header } from './Header';
import { TasksList } from './TasksList';
import { UploadErrorModal } from './/UploadErrorModal';
import { CancelConfirmPopup } from './CancelConfirmPopup';
import { getTasks, uploadTask, cancelTask } from './Controller';

import './AdminStatusPage.scss';

const loadTasks = async (filters, setIsLoading, setTasks) => {
    try {
        setIsLoading(true);
        const tasks = await getTasks(filters);

        setTasks(tasks);
    } catch (e) {
        setTasks([]);
    }

    setIsLoading(false);
};

export const AdminStatus = () => {
    const [tasks, setTasks] = useState([]);
    const [isTasksLoading, setIsTasksLoading] = useState(false);
    const [isUploadShown, setIsUploadShown] = useState(false);
    const [taskForUpload, setTaskForUpload] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const [isCancelConfirmShown, setIsCancelConfirmShown] = useState(false);
    const [isCanceling, setIsCanceling] = useState(false);
    const [canceledTaskUid, setCanceledTaskUid] = useState(null);
    const [filtersCount, setFiltersCount] = useState(0);
    const resetFiltersTrigger = useTrigger()

    const onUploadShow = useCallback(task => {
        setTaskForUpload(task);
        setIsUploadShown(true);
    }, [setIsUploadShown, setTaskForUpload]);

    const onUploadClose = useCallback(() => {
        setIsUploadShown(false);
    }, [setIsUploadShown]);

    const onUpload = useCallback(async file => {
        setIsUploadShown(false);
        setIsUploading(true);

        const response = await uploadTask(taskForUpload.Task_UID, file);
        setIsUploading(false);

        if (!response.status) {
            setErrors(response.errors);
            setIsErrorsShown(true);

            return;
        }

        const updateData = response.data;

        const newTasks = tasks.map(task => task.Task_UID === taskForUpload.Task_UID ? ({
            ...task,
            ...updateData,
        }) : task);

        setTasks(newTasks);
        addNotification({
            message: 'The file was successfully uploaded and the task is in progress.',
            type: NOTIFICATION_TYPES.SUCCESS,
        });
    }, [tasks, taskForUpload, setTasks, setIsUploadShown, setIsUploading, setErrors, setIsErrorsShown]);

    const onErrorClose = useCallback(() => {
        setIsErrorsShown(false);
    }, [setIsErrorsShown]);

    const onTryAgain = useCallback(() => {
        setIsErrorsShown(false);
        setIsUploadShown(true);
    }, [setIsErrorsShown, setIsUploadShown]);

    const onCancelConfirmHide = useCallback(() => {
        setIsCancelConfirmShown(false);
    }, [setIsCancelConfirmShown]);

    const onCancel = useCallback((task) => {
        setCanceledTaskUid(task.Task_UID);
        setIsCancelConfirmShown(true);
    }, [setCanceledTaskUid, setIsCancelConfirmShown]);

    const onCancelConfirm = useCallback(async () => {
        setIsCancelConfirmShown(false);
        setIsCanceling(true);
        let updateData = {};

        try {
            updateData = await cancelTask(canceledTaskUid);
        } catch (err) {
            setIsCanceling(false);
            setCanceledTaskUid(null);

            return;
        }

        setIsCanceling(false);

        const newTasks = tasks.map((task) => task.Task_UID === canceledTaskUid ? ({
            ...task,
            ...updateData,
        }) : task);

        setTasks(newTasks);
        setCanceledTaskUid(null);
    }, [canceledTaskUid, tasks, setIsCancelConfirmShown, setIsCanceling, setTasks, setCanceledTaskUid]);

    const onDateFilterChange = useCallback((dateRange) => {
        loadTasks({
            dateRange,
        }, setIsTasksLoading, setTasks);
    }, [setIsTasksLoading, setTasks]);

    const onFiltersReset = useCallback(() => {
        resetFiltersTrigger.trigger();
    }, [tasks, setTasks]);

    return (
        <div className='hme-page-component apply-store-settings-status'>
            <NotificationsList />
            <Header onFiltersReset={onFiltersReset} filtersCount={filtersCount} onDateRangeChange={onDateFilterChange} />
            <TasksList
                tasks={tasks}
                onCancel={onCancel}
                onUpload={onUploadShow}
                isLoading={isTasksLoading}
                resetFiltersTrigger={resetFiltersTrigger}
                filtersCount={filtersCount}
                onFiltersCountChange={setFiltersCount}
            />
            <UploadModal
                show={isUploadShown}
                multiple={false}
                extensions='csv'
                onHide={onUploadClose}
                onUpload={onUpload}
            />
            <LoadingModal show={isUploading} message='The device file is being uploaded…' />
            <LoadingModal show={isCanceling} message='The task is being canceled…' />
            <UploadErrorModal
                show={isErrorsShown}
                errors={errors}
                onHide={onErrorClose}
                onTryAgain={onTryAgain}
            />
            <CancelConfirmPopup
                show={isCancelConfirmShown}
                onHide={onCancelConfirmHide}
                onConfirm={onCancelConfirm}
            />
        </div>
    );
};

export const AdminStatusPage = compose(
    withHMELayout({ variants: ['extended'] })
)(AdminStatus);
