import React from 'react';

import { CenterMessage } from '../CenterMessages';
import { Loader } from 'library/Loader';

import './CenterLoader.scss';

export const CenterLoader = ({ children, variants = ['loader'] }) => (
    <CenterMessage
        variants={variants}
        icon={<Loader />}
    >
        {children}
    </CenterMessage>
);
