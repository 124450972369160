import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Title } from 'library/Title';
import { Button } from 'library/Button';
import { SearchOptionable } from 'library/SearchOptionable';
import { GridFiltersCount } from 'library/GridFiltersCount';
import { CommonConstants } from 'Constants';
import { adminAccountsSelectSearchOptions } from '../constants/adminAccountsSelectSearchOptions';

import './Header.scss';

const { adminPermissions } = CommonConstants;

export const Header = ({
    searchParams,
    onSearchParamsChange,
    onSearch,
    accountPermissions,
    isSearchDisabled,
    searchRef = null,
    filtersCount,
    onFiltersReset,
}) => {
    const { t } = useTranslation();

    return (
        <div className="hme-admin-accounts-header">
            <div className="hme-admin-accounts-header-title-wrapper">
                <Title>{t('common__accounts')}</Title>
                <SearchOptionable
                    value={searchParams}
                    onChange={onSearchParamsChange}
                    onSearch={onSearch}
                    searchSelectOptions={adminAccountsSelectSearchOptions}
                    isDisabled={isSearchDisabled}
                    isSearchButtonDisabled={isSearchDisabled}
                    searchRef={searchRef}
                />
                <div className="hme-admin-accounts-filters-count">
                    <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} />
                </div>
            </div>
            {accountPermissions.includes(adminPermissions.CreateAccounts) && (
                <div className="hme-admin-accounts-header-link">
                    <Link type="button" to={'/admin/account?type=Franchise'}>
                        <Button>{t('admin__accounts__header__create-new-account')}</Button>
                    </Link>
                </div>
            )}
        </div>
    );
};
