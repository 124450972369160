export const headers = [{
    property: 'User_EmailAddress',
    text: 'common__user__username',
    sortable: true,
    flex: 1.5,
}, {
    property: 'User_FirstName',
    text: 'common__user__first-name',
    sortable: true,
}, {
    property: 'User_LastName',
    text: 'common__user__last-name',
    sortable: true,
}, {
    property: 'RoleText',
    text: 'common__role',
    sortable: true,
}, {
    property: 'Actions',
    text: 'common__actions',
    flex: 1.25,
    headerClassName: 'hme-grid-centered-cell',
    className: 'hme-users--distributor-account--actions-cell'
}];
