import React from 'react';

import { MainHeader, getActiveTab } from '../MainHeader';
import { getDeviceSettingsSubHeaders } from '../DeviceSettingsSubHeader/getDeviceSettingsSubHeaders';
import { getDeviceUpgradesSubHeaders } from '../DeviceUpgradesSubHeader/getDeviceUpgradesSubHeaders.js';
import { getAdminReportsTab } from '../AdminReportsSubHeader/getAdminReportsTab';
import { AdminTitle, HOME_TAB_NAME } from './AdminTitle';
import { getToolsSubHeaders } from '../ToolsSubHeader/getToolsSubHeaders';
import { isPartnersAccessable } from '../Partners';

// Welcome tab is seperated from other menu items because the tabs we show directly after it can vary and need to be cancatted on
const welcomeTab = {
    name: HOME_TAB_NAME,
    tKey: 'main-header__item--welcome'
};

const middleTabs = [{
    name: 'users',
    tKey: 'common__users'
}, {
    name: 'systems',
    tKey: 'main-header__item--systems'
}, {
    name: 'accounts',
    tKey: 'common__accounts'
}];

const getReportsTab = () => {
    const tabs = getAdminReportsTab();

    return tabs.length ? {
        name: 'hmeReports',
        tKey: 'admin__hme-reports__header',
        allowed: tabs,
    } : null;
};

const getSettingsTab = () => {
    const [name = ''] = getDeviceSettingsSubHeaders();

    return name ? {
        name,
        tKey: 'common__settings',
        allowed: ['applyDeviceSettings', 'manageDeviceSettings', 'applyStoreSettings', 'applyNexeoDeviceSettings', 'mergeDevices']
    } : null;
};

const getStoresTabs = () => {
    const [name = ''] = getDeviceUpgradesSubHeaders();

    return name ? {
        name,
        tKey: 'common__stores',
        allowed: ['stores', 'deviceUpgrades']
    } : null;
};

const getPartnerTabs = () => {
    return isPartnersAccessable() ? {
        name: 'partners',
        tKey: 'admin__partner__status__title',
    } : null;
};

const getToolsTabs = () => {
    const subHeaders = getToolsSubHeaders();
    const [name = ''] = subHeaders;

    return name ?
        [
            {
                name,
                tKey: 'main-header__item--tools',
                allowed: subHeaders
            }
        ] : [];
};

// /TODO: Adding stores tab here and removing it above fixed console error but broke column order. Fix column order.
export const AdminHeader = ({ userName, onLogout }) => {
    const settingsTab = getSettingsTab();
    const storesTab = getStoresTabs();
    const toolsTabs = getToolsTabs();
    const partnersTabs = getPartnerTabs();

    const reportTab = getReportsTab();
    const tabsWithSettingsAndMiddle = settingsTab ? [...middleTabs, partnersTabs, reportTab, settingsTab] : [...middleTabs, partnersTabs];
    const tabsWithEverything = storesTab ?
        [welcomeTab, storesTab, ...tabsWithSettingsAndMiddle, ...toolsTabs] :
        [welcomeTab, ...tabsWithSettingsAndMiddle, ...toolsTabs];

    // filter out empty tabs (in case of not enough permissions)
    const filteredTabsWithEverything = tabsWithEverything.filter(t => t);

    const title = <AdminTitle activeTab={getActiveTab(filteredTabsWithEverything)} />;

    return <MainHeader menuItems={filteredTabsWithEverything} userName={userName} className="admin-main-header" title={title} onLogout={onLogout}/>;
};
