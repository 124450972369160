import React from 'react';
import { toast, Slide } from 'react-toastify';

import { NotificationMessage } from 'library/NotificationMessage';

const defaultOptions = {
    position: `top-center`,
    transition: Slide,
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
    className: 'hme-notification',
};

export const NOTIFICATION_TYPES = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

export const addNotification = (notification, options = {}) => {
    switch (notification.type) {
        case NOTIFICATION_TYPES.ERROR:
            // TODO: We need to specify error design
            toast.error(<NotificationMessage message={notification.message} isFailure={true}/>, {
                ...defaultOptions,
                ...options,
            });
            break;

        default:
            const title = <NotificationMessage message={notification.message} />;
            toast.success(title, {
                ...defaultOptions,
                ...options,
            });

            break;
    }
};

export const addSuccessNotification = (message, options) => {
    return addNotification({
        message,
        type: NOTIFICATION_TYPES.SUCCESS,
    }, options);
};

export const addErrorNotification = (message, options) => {
    return addNotification({
        message,
        type: NOTIFICATION_TYPES.ERROR,
    }, options);
};

export const dismissNotification = () => {
    toast.dismiss();
};

export { toast };
