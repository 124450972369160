import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoCompleteInput } from 'library/AutoCompleteInput';
import { useLoadAccounts } from '../hooks/useLoadAccounts';

import './LinkAccountForm.scss';

export const accountsResultHeaders = [
    {
        text: '',
        property: 'User_EmailAddress',
    },
];

export const LinkAccountForm = ({  onFormChange }) => {
    const { t } = useTranslation();
    const [suggestedAccount, setSuggestedAccount] = useState(null);
    const [accountSearchValue, setAccountSearchValue] = useState('');

    const { accounts, isAccountsLoading, loadAccountsDebounced } = useLoadAccounts({ search: accountSearchValue});

    useEffect(() => {
        if (accountSearchValue === suggestedAccount?.User_EmailAddress) {
            // cancel redundant request after the suggestion has been chosen
            loadAccountsDebounced.cancel();
        }

        // remove the suggested account if the search field has been cleared
        if (!accountSearchValue) {
            setSuggestedAccount(null);
        }
    }, [accountSearchValue]);

    useEffect(() => {
        if (suggestedAccount) {
            setAccountSearchValue(suggestedAccount.User_EmailAddress);
            onFormChange({
                cloudAccountID: +suggestedAccount.Account_ID,
            });
        }
    }, [suggestedAccount]);

    return (
        <div>
            <div className="hme-feed-form-section">
                <AutoCompleteInput
                    isRequired
                    success={!!suggestedAccount}
                    value={accountSearchValue}
                    suggestions={accounts}
                    headers={accountsResultHeaders}
                    rowKey="Account_ID"
                    className="hme-account-input"
                    placeholder={t('common__account__email')}
                    isLoading={isAccountsLoading}
                    noSuggestionText={t('accounts__no-accounts')}
                    onChange={setAccountSearchValue}
                    onSuggested={setSuggestedAccount}
                />
            </div>
        </div>
    );
};
