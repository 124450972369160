/* eslint-disable complexity */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import { SELECTION_CHANGE_COMMAND, $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW } from 'lexical';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';

import { Checkbox } from 'library/Checkbox';

import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';

import { getSelectedNode, positionEditorElement } from '../../utils';

const isTargetBlank = (target) => {
    return target === '_blank';
};

export const FloatingLinkEditor = ({ editor }) => {
    const editorRef = useRef(null);
    const inputRef = useRef(null);
    const mouseDownRef = useRef(false);
    const [linkUrl, setLinkUrl] = useState('');
    const [isEditMode, setEditMode] = useState(false);
    const [lastSelection, setLastSelection] = useState(null);
    const [isInNewTab, setIsInNewTab] = useState(false);

    const updateDOMInDOM = useCallback(({ url, isInNewTab: shouldBeOpenedInNewTab }) => {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, { url, target: shouldBeOpenedInNewTab ? '_blank' : '_self' });
    }, [editor]);

    const updateLinkEditor = useCallback(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            const parent = node.getParent();

            if ($isLinkNode(parent)) {
                setLinkUrl(parent.getURL());
                setIsInNewTab(isTargetBlank(parent.getTarget()));
            } else if ($isLinkNode(node)) {
                setLinkUrl(node.getURL());
                setIsInNewTab(isTargetBlank(node.getTarget()));
            } else {
                setLinkUrl('');
                setIsInNewTab(false);
            }
        }

        const editorElem = editorRef.current;
        const nativeSelection = window.getSelection();
        const activeElement = document.activeElement;

        if (editorElem === null) {
            return false;
        }

        const rootElement = editor.getRootElement();
        if (
            selection !== null &&
            !nativeSelection.isCollapsed &&
            rootElement !== null &&
            rootElement.contains(nativeSelection.anchorNode)
        ) {
            const domRange = nativeSelection.getRangeAt(0);
            let rect;
            if (nativeSelection.anchorNode === rootElement) {
                let inner = rootElement;
                while (inner.firstElementChild != null) {
                    inner = inner.firstElementChild;
                }
                rect = inner.getBoundingClientRect();
            } else {
                rect = domRange.getBoundingClientRect();
            }

            if (!mouseDownRef.current) {
                positionEditorElement(editorElem, rect);
            }
            setLastSelection(selection);
        } else if (!activeElement || activeElement.className !== 'link-input') {
            positionEditorElement(editorElem, null);
            setLastSelection(null);
            setEditMode(false);
            setLinkUrl('');
        }

        return true;
    }, [editor]);

    const handleInputChange = useCallback((event) => {
        setLinkUrl(event.target.value);
    }, []);

    const handleInputKeyDown = useCallback((event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (lastSelection !== null) {
                if (linkUrl !== '') {
                    updateDOMInDOM({ url: linkUrl, isInNewTab });
                }
                setEditMode(false);
            }
        } else if (event.key === 'Escape') {
            event.preventDefault();
            setEditMode(false);
        }
    }, [editor, lastSelection, linkUrl, isInNewTab, updateDOMInDOM]);

    const handlNewTabChange = useCallback((event) => {
        event.preventDefault();
    }, []);

    const enableChangeMode = useCallback(() => {
        setEditMode(true);
    }, []);

    const handleIsInNewTabChange = useCallback((value) => {
        setIsInNewTab(value);
        updateDOMInDOM({ url: linkUrl, isInNewTab: value });
    }, [updateDOMInDOM, linkUrl]);

    useEffect(() => {
        return mergeRegister(
                editor.registerUpdateListener(({ editorState }) => {
                    editorState.read(() => {
                        updateLinkEditor();
                    });
                }),

                editor.registerCommand(
                        SELECTION_CHANGE_COMMAND,
                        () => {
                            updateLinkEditor();
                            return true;
                        },
                        COMMAND_PRIORITY_LOW
                )
        );
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateLinkEditor();
        });
    }, [editor, updateLinkEditor]);

    return (
        <div ref={editorRef} className="link-editor">
            {isEditMode ? (
                <>
                    <input
                        ref={inputRef}
                        className="link-input"
                        value={linkUrl}
                        onChange={handleInputChange}
                        onKeyDown={handleInputKeyDown}
                    />

                    <div className="link-open-new-tab">
                        <Checkbox checked={isInNewTab} onChange={handleIsInNewTabChange} id="openNewTab" />

                        <label className="link-open-new-tab__label" htmlFor="openNewTab">
                            <Trans i18nKey="admin__customer-notification__editor--open-new-tab" />
                        </label>
                    </div>
                </>
            ) : (
                <div className="link-input">
                    <a
                        href={linkUrl}
                        target={isInNewTab ? '_blank' : '_self'}
                        rel={classNames('noopener', { 'noreferrer': isInNewTab })}
                    >
                        {linkUrl}
                    </a>
                    <div
                        className="link-edit"
                        role="button"
                        tabIndex={0}
                        onMouseDown={handlNewTabChange}
                        onClick={enableChangeMode}
                    >
                        <PenIcon />
                    </div>
                </div>
            )}
        </div>
    );
};
