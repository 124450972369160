import React from 'react';
import classNames from 'classnames';

import { Modal as LibraryModal } from 'library/Modal';

import './Modal.scss';

export const Modal = ({ className, ...props }) => (
    <LibraryModal dialogClassName={classNames('hme-api-management-token-modal', className)} {...props} />
);
