import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { StoreStatusesMobile } from '../StoreStatusesMobile';

export const TileListItem = ({
    storeNumber,
    devices,
    brandName,
    storeName,
    storeAddress,
    cityAndState
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="hme-stores-mobile--partner__tile__main__info">
                <div
                    className={
                        'hme-stores-mobile--partner__tile__store_info_section'
                    }
                >
                    <span className="hme-stores-mobile--partner__tile__title">
                        {t('stores__list-header__store-information')}
                    </span>
                    <div className="hme-stores-mobile--partner__store__info__item">
                        <span className="hme-stores-mobile--partner__tile__sub__title">
                            {t('common__store__number')}
                        </span>
                        <span className="hme-stores-mobile--partner__tile__sub__value">
                            {storeNumber}
                        </span>
                    </div>
                    <div className="hme-stores-mobile--partner__store__info__item">
                        <span className="hme-stores-mobile--partner__tile__sub__title">
                            {t('common__brand')}
                        </span>
                        <span className="hme-stores-mobile--partner__tile__sub__value">
                            {brandName}
                        </span>
                    </div>

                    <div className="hme-stores-mobile--partner__store__info__item">
                        <span className="hme-stores-mobile--partner__tile__sub__title">
                            {t('common__store__name')}
                        </span>
                        <span className="hme-stores-mobile--partner__tile__sub__value">
                            {storeName}
                        </span>
                    </div>
                    <div className="hme-stores-mobile--partner__store__info__item">
                        <span className="hme-stores-mobile--partner__tile__sub__title">
                            {t('common__store__address')}
                        </span>
                        <span className="hme-stores-mobile--partner__tile__sub__value">
                            {storeAddress}
                        </span>
                    </div>
                    <div className="hme-stores-mobile--partner__store__info__item">
                        <span className="hme-stores-mobile--partner__tile__sub__title">
                            {t('stores__list-header__city-state')}
                        </span>
                        <span className="hme-stores-mobile--partner__tile__sub__value">
                            {cityAndState}
                        </span>
                    </div>
                </div>
                <div
                    className={
                        'hme-stores-mobile--partner__tile__device_info_section'
                    }
                >
                    <span className="hme-stores-mobile--partner__tile__title">
                        {t('stores__list-header__device-information')}
                    </span>
                    <StoreStatusesMobile devices={devices} />
                </div>
            </div>
        </>
    );
};

TileListItem.propTypes = {
    storeNumber: PropTypes.string.isRequired,
    devices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    brandName: PropTypes.string.isRequired,
    storeName: PropTypes.string.isRequired,
    storeAddress: PropTypes.string.isRequired,
    cityAndState: PropTypes.string.isRequired
};
