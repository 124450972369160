import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TileList } from 'library/TileList';
import { t } from 'i18next';
import {
    Grid,
    useAvailableFilters,
    resetFilters,
    applyGridFilters,
    applySearchValue,
    getFiltersCount,
} from 'components/Common/Grid';
import { Button } from 'library/Button';
import { deviceTypes, deviceServerTypes } from 'constants/device';
import {
    STORE_GRID_HEADERS,
    STORE_NEXEO_GRID_HEADERS,
    MOBILE_STORE_GRID_HEADERS,
    MOBILE_STORE_NEXEO_GRID_HEADERS,
} from '../../constants';
import { checkSelectable } from '../../../helpers/checkSelectable';
import { TargetDeviceHeader } from './TargetDeviceHeader';
import { Paginate } from 'library/Paginate';
import { PAGE_DATA, PAGE_DEFAULT } from 'constants/paginationDefault';
import { accountTypes } from 'constants/accountTypes';
import { applyPagination } from 'helpers';

const ALL_COMPANY_TYPE_FILTER_TEXT = 'All Company types';
const ALL_VERSIONS_FILTER_TEXT = 'All versions';
const ALL_BRANDS_FILTER_TEXT = 'All Brands';

const searchProperties = [
    'User_EmailAddress',
    'Company_Type',
    'Brand_Name',
    'Device_SerialNumber',
    'Store_Number',
    'Store_Name',
    'Device_MainVersion',
    'Device_LaneConfig_Name',
];

const getSearchProperties = (isNexeoSourceType) =>
    isNexeoSourceType ? [...searchProperties, 'Device_SettingVersion'] : searchProperties;

const filterOptions = {
    Company_Type: {
        title: 'common__company-type',
        allText: ALL_COMPANY_TYPE_FILTER_TEXT,
    },
    Device_MainVersion: {
        title: 'common__device__version',
        allText: ALL_VERSIONS_FILTER_TEXT,
    },
    Brand_Name: {
        title: 'common__brand',
        allText: ALL_BRANDS_FILTER_TEXT,
        valuePropName: 'Brand_ID',
    },
};

const deviceToRow = (device, onRemove) => ({
    ...device,
    DeviceServerType: device.iotDeviceId ? t('common__service-type__iot') : t('common__service-type__legacy'),
    actions: <Button onClick={() => { onRemove(device) }} variants={['transparent']}>{t('common__remove')}</Button>
});

const { PAGE_SIZES_ADMIN, PAGE_SIZES_PUBLIC } = PAGE_DATA;

export const StoreGrid = ({
    isLoading,
    targetDevices,
    params,
    selectedDeviceType,
    selectedSnapshot,
    onFiltersClear,
    onDeviceRemove,
    permissionType,
}) => {
    const [filter, setFilter] = useState('');
    const [rows, setRows] = useState([]);
    const [devices, setDevices] = useState([]);
    const [gridFilters, setGridFilters] = useState({});
    const [availableFilters, setAvailableFilters] = useState([]);
    const [isDisabledHidden, setIsDisabledHidden] = useState(false);
    const [pageData, setPageData] = useState(PAGE_DEFAULT);
    const [filtersCount, setFiltersCount] = useState(0);

    const sourceDevice = params?.sourceDevice;
    const isNexeoSourceType = selectedDeviceType === deviceTypes.NEXEO;
    const checkDisabled = (row) => !checkSelectable({ device: row, selectedSnapshot, sourceDevice });

    const onRemoveCallback = useCallback((device) => {
        onDeviceRemove(device);
    }, [onDeviceRemove]);

    const onFiltersReset = useCallback(() => {
        resetFilters(availableFilters, setGridFilters);
    }, [availableFilters, setGridFilters]);

    useEffect(() => {
        setRows(targetDevices.map((device) => deviceToRow(device, onRemoveCallback)));
    }, [targetDevices, onRemoveCallback]);

    useAvailableFilters(filterOptions, rows, setGridFilters, setAvailableFilters);

    useEffect(() => {
        const rowsFilteredByGrid = applyGridFilters(filterOptions, rows, gridFilters);
        const rowsFilteredBySearch = applySearchValue(rowsFilteredByGrid, getSearchProperties(isNexeoSourceType), filter, {
            minLength: 1,
        });

        const rowsFilteredBySettingsVersion = rowsFilteredBySearch.map((device) => {
            const isSelectable = checkSelectable({ device, selectedSnapshot, sourceDevice });

            return {
                ...device,
                isSelectable,
                rowClassName: !isSelectable ? 'hme-grid-row-disabled' : '',
            };
        });
        setDevices(rowsFilteredBySettingsVersion);
        setPageData({ ...pageData, page: PAGE_DATA.INIT_PAGE });
    }, [rows, gridFilters, filter, selectedSnapshot, sourceDevice, setDevices]);

    useEffect(() => {
        setFiltersCount(getFiltersCount(availableFilters, gridFilters));
    }, [availableFilters, gridFilters]);

    useEffect(() => {
        setPageData({ ...pageData, page: PAGE_DATA.INIT_PAGE });
    }, [isDisabledHidden]);

    const filteredDeviceRows = useMemo(
        () =>
            isDisabledHidden
                ? devices.filter((row) => checkSelectable({ device: row, selectedSnapshot, sourceDevice }))
                : devices,
        [devices, isDisabledHidden, selectedSnapshot, sourceDevice, checkSelectable],
    );

    return (
        <>
            <TargetDeviceHeader
                devices={filteredDeviceRows}
                gridFiltersCount={filtersCount}
                searchValue={filter}
                toggableDisabled={isNexeoSourceType}
                isDisabledHidden={isDisabledHidden}
                onDisabledViewChange={setIsDisabledHidden}
                onSearchChange={setFilter}
                onFiltersClear={onFiltersClear}
                onFiltersReset={onFiltersReset}
                isNexeoSourceType={isNexeoSourceType}
            />
            <Grid
                rows={applyPagination(filteredDeviceRows, pageData)}
                headers={isNexeoSourceType ? STORE_NEXEO_GRID_HEADERS : STORE_GRID_HEADERS}
                isLoading={isLoading}
                rowKey="Device_UID"
                availableFilters={availableFilters}
                filters={gridFilters}
                checkDisabled={checkDisabled}
                onFiltersChange={setGridFilters}
                noRecordsMessage={t('common__no-devices--found')}
            />
            <TileList
                headers={isNexeoSourceType ? MOBILE_STORE_NEXEO_GRID_HEADERS : MOBILE_STORE_GRID_HEADERS}
                rows={filteredDeviceRows}
                isLoading={isLoading}
                noRecordsMessage="common__no-devices--found"
                checkDisabled={checkDisabled}
                filtersOptions={filterOptions}
                availableFilters={availableFilters}
                filters={gridFilters}
                filtersCount={filtersCount}
                onFiltersChange={setGridFilters}
                onFiltersAndSortReset={onFiltersReset}
                onFiltersReset={onFiltersReset}
            />
            <Paginate
                className="admin-create-apply-device-settings__paginate hme-paginate__mobile"
                page={pageData.page}
                recordsPerPage={pageData.recordsPerPage}
                total={filteredDeviceRows.length}
                onChange={setPageData}
                pageSizes={permissionType === accountTypes.ADMIN ? PAGE_SIZES_ADMIN : PAGE_SIZES_PUBLIC}
            />
        </>
    );
};

StoreGrid.propTypes = {
    isLoading: PropTypes.bool,
    targetDevices: PropTypes.array,
    selectedDeviceType: PropTypes.string,
    selectedSnapshot: PropTypes.object,
    onFiltersClear: PropTypes.func,
    onDeviceRemove: PropTypes.func,
    permissionType: PropTypes.string,
};
