import React from 'react';
import { InfoField } from 'library/InfoField';
import { useTranslation } from 'react-i18next';

export const InfoSection = ({ accountEmail }) => {
    const { t } = useTranslation();

    return (
        <div className="dxs-account-info-section">
            <div className="hme-account-info-section">
                <InfoField label={t('common__account__email')}>{accountEmail}</InfoField>
            </div>
        </div>
    );
};
