import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from 'components/Common/Grid';

import { useTokensGrid } from './hooks';

export const headers = [
    {
        text: 'admin__token-management__token-name',
        property: 'name',
        flex: 0.5,
    },
    {
        text: 'admin__token-management__token-expiration-date',
        property: 'expiryDate',
        sortable: true,
        flex: 0.6,
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-centered-cell'
    },
    {
        text: 'admin__token-management__token-apis',
        property: 'apiName',
        headerClassName: 'hme-grid--header-text-transform--none',
        flex: 0.5
    },
    {
        text: 'partner__token-manager-email',
        property: 'tokenManagerEmail',
        flex: 0.7
    },
    {
        text: 'common__created-date-n-time',
        property: 'createdAt',
        flex: 0.6,
    },

    {
        text: 'common__updated-date-n-time',
        property: 'updatedAt',
        flex: 0.6,
    },
    {
        text: 'common__actions',
        property: 'Actions',
        flex: 0.7,
        headerClassName: 'hme-grid-centered-cell'
    }
];

export const List = ({ tokens, onRemoveToken, ...props }) => {
    const { t } = useTranslation();

    const { rows, isRemoveConfirmationShown, onRemoveCancel, handleConfirmRemoveClick, isLoadingModalShown } = useTokensGrid(
            { tokens, onRemoveToken }
    );

    return (
        <>
            <Grid
                rows={rows}
                headers={headers}
                noRecordsMessage={t('admin__token-management__token-not-found')}
                rowKey="id"
                className="hme-partner-token-management__list"
                {...props}
            />
        </>
    );
};
