/* eslint-disable complexity */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';
import { Section } from 'library/Section';
import { CommonConstants } from 'Constants';
import { checkAccess } from 'actions/permissions';
import { Grid } from 'components/Common/Grid';
import { PeripheralDevicesList } from './PeripheralDevicesList';
import { preparePeripheralDevices } from './helpers';

import './DevicesSection.scss';

const gridOptions = ([
    {
        text: 'common__device__type--nexeo-device',
        flex: 1.1,
        property: 'modelName',
        className: 'hme-grid-cell-show-all-content hme-grid-model-name',
        headerClassName: 'hme-grid-model-name',
    },
    {
        text: 'common__device__serial-number',
        flex: 1.1,
        property: 'serialNumber',
        className: 'hme-grid-cell-show-all-content hme-grid-serial-col',
        headerClassName: 'hme-grid-serial-col',
    },
    {
        text: 'common__device__product-id',
        flex: 1,
        property: 'productId',
        className: 'hme-grid-cell-show-all-content hme-grid-product-id-col',
        headerClassName: 'hme-grid-product-id-col',
    },
    {
        text: 'common__lane',
        flex: 1,
        property: '',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'system-status__last-upgrade',
        flex: 1,
        property: 'lastUpgradeData',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
    {
        text: 'system-status__devices-grid__current-firmware',
        flex: 1.1,
        property: 'device.mainVersion',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
    {
        text: 'system-status__devices-grid__on-latest-version',
        flex: 1.1,
        property: 'onLatestVersion',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell hme-on-latest-version',
    },
    {
        text: 'system-status__devices-grid__upgrade-status',
        flex: 1.1,
        property: 'upgradeStatus',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
    {
        text: 'system-status__devices-grid__current-upgrade',
        flex: 1.1,
        property: 'currentUpgrade',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
    checkAccess(CommonConstants.adminPermissions.PerformDeviceUpdate) ? {
        text: 'common__actions',
        flex: 1.4,
        property: 'actionsComponent',
        headerClassName: 'hme-grid-centered-cell hme-upgrade-actions',
        className: 'hme-grid-cell-with-outside-content hme-grid-centered-cell hme-upgrade-actions',
    } : null,
]).filter(item => !!item);

export const DevicesSection = ({
        peripheralDevices,
        device,
        layoutContentRect,
        onDeviceVersionUpgrade,
        deviceScheduledUpgrade,
        onScheduledUpgradeChanged,
        isScheduledUpgradeChanged,
    }) => {
    const { t } = useTranslation();
    const [rows, setRows] = useState([]);
    const [isAllExpanded, setIsAllExpanded] = useState(false);
    const [expandedDevices, setExpandedDevices] = useState({});

    useEffect(() => {
        const preparedPeripheralDevices = preparePeripheralDevices({
            peripheralDevices,
            device,
            layoutContentRect,
            onDeviceVersionUpgrade,
            deviceScheduledUpgrade,
            onScheduledUpgradeChanged,
            t,
        });

        setRows(preparedPeripheralDevices);
        setExpandedDevices(mapExpandedDevices(preparedPeripheralDevices, false));
    }, [peripheralDevices, setRows, isScheduledUpgradeChanged]);


    const mapExpandedDevices = useCallback((devices, isExpanded) =>
        devices.reduce(
            (acc, device) =>
                device.isMultiple
                    ? {
                        ...acc,
                        [device.deviceTypeID]: isExpanded,
                    }
                    : acc,
            {},
        ), []);

    const handleExpandAllChange = useCallback(() => {
        setIsAllExpanded(!isAllExpanded);
        setExpandedDevices(mapExpandedDevices(rows, !isAllExpanded));
    }, [isAllExpanded, rows, setIsAllExpanded, setExpandedDevices]);

    const handleExpandChange = useCallback((rowKey, isExpanded) => {
        const updatedExpandedDevices = {
            ...expandedDevices,
            [rowKey]: isExpanded,
        };
        setExpandedDevices(updatedExpandedDevices);

        const expandedDeviceKeys = Object.keys(updatedExpandedDevices);

        const allExpanded = expandedDeviceKeys.every((k) => updatedExpandedDevices[k]);
        if (allExpanded) {
            setIsAllExpanded(true);
            return;
        }

        const allCollapsed = expandedDeviceKeys.every((k) => !updatedExpandedDevices[k]);
        if (allCollapsed) {
            setIsAllExpanded(false);
            return;
        }
    }, [expandedDevices, setIsAllExpanded, setExpandedDevices]);

    const isExpandAllShown = useMemo(
        () => !device.Device_IsPreconfigured && rows.some((r) => r.isMultiple),
        [device, rows],
    );

    return (
        <Section className="hme-admin-system-status-devices">
            <div className="hme-admin-system-status-devices-headline">
                <Title>{t('system-status__title__system-devices')}</Title>
                {isExpandAllShown ? (
                    <div className="hme-admin-system-status-devices-expand-all" onClick={handleExpandAllChange}>
                        {isAllExpanded ? t('common__collapse-all') : t('common__expand-all')}
                    </div>
                ) : null}
            </div>
            <Grid
                rows={rows}
                rowKey="deviceTypeID"
                isLoading={false}
                headers={gridOptions}
                noRecordsMessage={t('system-status__title__system-devices__no-devices--found')}
                ExpandComponent={PeripheralDevicesList}
                expandable
                isAllExpanded={isAllExpanded}
                onExpandChange={handleExpandChange}
            />
        </Section>
    );
};
