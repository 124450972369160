import * as R from 'ramda';

const formatStoreGroups = (groupedStores) => {
  return groupedStores.map(group => {
    return {
      id: group.GroupID,
      name: group.GroupName,
      children: group.Stores.reduce((avaliableStores, store) => {
        if (!store.disabled) {
          avaliableStores.push({
            id: store.StoreUID,
            name: R.compose(R.join('-'), R.filter(R.identity))([store.StoreNumber, store.StoreName]),
          })
        }
        return avaliableStores;
      }, [])
    }
  });
}

const groupStores = (data, filedName = 'GroupName') => {
  const groupedByFieldName = (R.compose(
    R.map(value => {
      return {
        GroupName: value[0][filedName],
        GroupID: value[0].GroupID,
        isDefault: value[0].isDefault,
        Stores: value
      }
    }),
    R.groupBy(R.prop(filedName)),
  ))(data);
  return Object.keys(groupedByFieldName).map(key => groupedByFieldName[key]);
};

const getStoreUids = groupedStores => {
  return groupedStores.reduce((stores, group) => {
    const groupStoreUIDs = group.children.reduce( (avaliableStoresUIDs, child) => {
      if(child.id) {
        avaliableStoresUIDs.push(child.id)
      }
      return avaliableStoresUIDs;
    }, [])
    if(groupStoreUIDs.length) {
      stores = stores.concat(groupStoreUIDs);
    }
    return stores;
  }, []);
}

export {
  formatStoreGroups,
  groupStores,
  getStoreUids,
}
