import React from 'react';
import { compose } from 'ramda';
import { CommonConstants } from 'Constants';
import { withHMELayout } from 'HOCs/withHMELayout';
import { isAdmin } from 'services/Auth';
import { checkAccess } from 'actions/permissions';
import authenticate from 'components/Security/Authentication';
import { withAccess } from 'HOCs/withAccess';
import { accountTypes } from 'constants/accountTypes';
import { DXSAccount } from '../../DXSAccount';

import './DXSAccountPage.scss';

const hasAccess = () => isAdmin() && checkAccess(CommonConstants.adminPermissions.ApplyDeviceSettings);

export const AdminDXSAccount = () => <DXSAccount permissionType={accountTypes.ADMIN} />;

export const DXSAccountPage = compose(
    withHMELayout({
        contentClasses: ['admin-dxs-account-page'],
    }),
    authenticate,
    withAccess({
        checkAccess: hasAccess,
    }),
)(AdminDXSAccount);
