import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useAsync, ASYNC_STATUS } from 'hooks/useAsync';
import { resendActivationEmail } from 'services/Partner';

export const useResend = () => {
    const { partnerUID } = useParams();

    const {
        run,
        isLoading
    } = useAsync({
        status: ASYNC_STATUS.RESOLVED
    });

    const resend = useCallback(() => run(resendActivationEmail(partnerUID)));

    return {
        resend,
        isResending: isLoading,
    };
};
