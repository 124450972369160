const allowedSpecialChars = [';', ':', '\?', '!', ',', '.', '&', '\''];

// allow only alphanumeric characters, spaces and the allowed special characters
const validCharsRegexp = new RegExp(`^[A-Za-z0-9\\n ${allowedSpecialChars.join('')}]+$`);

export const validateMessageData = (message) => {
    if (message && !message.match(validCharsRegexp)) {
        return {
            invalidSpecialChars: {
                key: 'text-n-connect__send-message--message-invalid-special-chars',
                params: {
                    allowedSpecialChars: allowedSpecialChars.join(' '),
                },
            },
        };
    }

    return null;
};
