import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';

import { ReportDashboard } from './ReportDashboard';
import { useCheckAccount } from 'hooks/useCheckAccount';
import { useLocation } from 'react-router-dom';
import CommonLoader from 'components/Alerts/CommonLoader';
import AccountErrorMessage from 'components/Common/AccountErrorMessage';

const ReportLandingComponent = () => {
    const { t } = useTranslation();
    const [detailsChecked, setDetailsChecked] = useState({
        noStores: false,
        noPermissions: false,
        accountError: false
    });
    const {
        noStores,
        noPermissions,
        accountError
    } = detailsChecked;
    const [showCommonLoader, setShowCommonLoader] = useState(true);
    const location = useLocation();
    const pathArray = location.pathname.split('/');
    const reportType = pathArray[pathArray.length - 1];
    const isLoading = () => {
        setShowCommonLoader(false);
    };
    useCheckAccount(isLoading, setShowCommonLoader, setDetailsChecked, detailsChecked, reportType);

    if (showCommonLoader) {
        return <CommonLoader showLoader={showCommonLoader} message="common__loading" />;
    }

    if (accountError) {
        return (
            <div className="noStores">
                <AccountErrorMessage
                    noStores={noStores}
                    noPermissions={noPermissions}
                    t={t}
                />
            </div>
        );
    }
    return <ReportDashboard />;
};

export const ReportLanding = compose(
        withHMELayout({
            contentClasses: ['reports-landing-page']
        })
)(ReportLandingComponent);
