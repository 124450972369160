import React from 'react';
import { NavDropdown  } from 'react-bootstrap';
import classnames from 'classnames';

export const AccountDropdownItem = ({ id, url, icon, label, classes, onClick }) => (
    <NavDropdown.Item href={url} className={classnames('headerMenu', classes)} onClick={onClick} id={id}>
        <i className={classnames('icon', icon)} />
        {label}
    </NavDropdown.Item>
);
