export const defaultPartner = {
    name: '',
    types: [],
    address1: '',
    address2: '',
    countryId: 1,
    region: '',
    locality: '',
    postCode: '',
    phoneNumber: '',
    mobileNumber: '',
    isActive: true,
    firstName: '',
    lastName: '',
    email: '',
};
