/* eslint-disable complexity */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CenterLoader } from 'components/Common/CenterLoader';
import { CenterErrorMessage } from 'components/Common/CenterMessages';
import { CommonConstants } from 'Constants';
import OldViewDetails from 'containers/ViewDetails';
import { getLayout } from 'helpers/getLayout';
import { useLoadDevice } from '../Common/hooks/useLoadDevice';
import { PublicZoomNitroSystemStatus, PublicNexeoSystemStatus, PublicLinkWearSystemStatus } from '../Public';
import { Constant } from '@hme-cloud/utility-common';

const { BuildVersions } = Constant;

const { zoom, zoomNitro, nexeo, linkWear } = CommonConstants.deviceType;

const zoomMinVersionSemver = BuildVersions.parsedVersion(zoom.entryVersion);
const zoomNitroMinVersionSemver = BuildVersions.parsedVersion(zoomNitro.entryVersion);

/**
 * NOTE: This is a temporary solution to keep "system status" pages with old design
 * and at the same time creating with new design
 */

export const TempSystemStatusPage = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const deviceUID = searchParams.get('duid');

    const { device, isDeviceLoading, deviceLoadingError } = useLoadDevice(deviceUID);

    if (isDeviceLoading) {
        return (
            <CenterLoader>{t('system-status__message__loading-device')}</CenterLoader>
        );
    }

    if (deviceLoadingError) {
        return (
            <CenterErrorMessage>{t('system-status__message__loading-device-error')}</CenterErrorMessage>
        );
    }

    const {
        Device_MainVersion: deviceMainVersion,
        Device_DeviceType_ID: deviceTypeID
    } = device;

    const deviceVersionSemver = BuildVersions.parsedVersion(deviceMainVersion);

    /**
     * Zoom & ZoomNitro have the same template
     * so in order to simplify further changes we left
     */
    if (
        zoom.id === deviceTypeID
        && deviceVersionSemver
        && deviceVersionSemver.major === zoomMinVersionSemver.major
    ) {
        return <PublicZoomNitroSystemStatus />;
    }

    if (
        zoom.id === deviceTypeID
        && deviceVersionSemver
        && deviceVersionSemver.major >= zoomNitroMinVersionSemver.major
    ) {
        return <PublicZoomNitroSystemStatus isNitro />;
    }

    if (
        nexeo.id === deviceTypeID
    ) {
        return <PublicNexeoSystemStatus />;
    }

    if (linkWear.id === deviceTypeID) {
        return <PublicLinkWearSystemStatus />;
    }

    return getLayout(OldViewDetails);
};
