import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { GridActions } from 'library/GridActions';
import { Button } from 'library/Button';
import { ADMIN_ROUTES } from 'constants/routes';

const buttonVariants = ['transparent'];

const ActionsComponent = ({ tokenId, tokenUID, onRemove }) => {
    const { t } = useTranslation();

    const onRemoveNotificationClick = useCallback(() => {
        onRemove(tokenId);
    }, [tokenId, onRemove]);

    return (
        <GridActions className="hme-token-management__actions">
            <Button
                as={Link}
                to={`${ADMIN_ROUTES.createAPIToken}?tokenUID=${tokenUID}`}
                variants={buttonVariants}
            >
                {t('common__view-edit')}
            </Button>
            <Button variants={buttonVariants} onClick={onRemoveNotificationClick}>
                {t('common__delete')}
            </Button>
        </GridActions>
    );
};

export const Actions = memo(ActionsComponent);
