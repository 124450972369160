import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import { withAccess } from 'HOCs/withAccess';
import { getProfile } from 'services/Auth';
import { CommonConstants } from 'Constants';
import { checkAccess } from 'actions/permissions';

import { ManageHierarchy as ManageHierarchyCommon } from '../Common/ManageHierarchy';
import { usePublic } from './hooks/usePublic';

const { Subscription_ID: subscriptionId } = getProfile();

const { subscription, userPermissions } = CommonConstants;

const notAvailableSubscriptionsIds = [
    Number(subscription.basic),
    Number(subscription.basicWithUpgrade),
    Number(subscription.nitroEssentials)
];

const ManageHierarchyPage = () => {
    const state = usePublic();

    return (
        <ManageHierarchyCommon {...state} />
    );
};

const hasAccess = checkAccess(userPermissions.EditStoreAdvanced) && !notAvailableSubscriptionsIds.includes(subscriptionId);

export const ManageHierarchy = compose(
        withHMELayout(),
        withAccess({
            checkAccess: () => hasAccess
        })
)(ManageHierarchyPage);
