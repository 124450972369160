import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import classNames from 'classnames';
import LBGroupTable from '../../LeaderBoard/LBGroupTable.js';
import SelectStores from '../../LeaderBoard/SelectStoresSection.js';
import { groupStores } from '../../../helpers/Store/storeGroups';
import { Config } from '../../../Config';
import { CommonConstants } from '../../../Constants';
import Api from '../../../Api';

class StoresSelectorInputComponent extends Component {
  constructor() {
    super();
    this.addToParticipating = this.addToParticipating.bind(this);
    this.removeFromParticipating = this.removeFromParticipating.bind(this);
    this.api = new Api();

        this.selectAllParticipatedStores = this.selectAllParticipatedStores.bind(this);
        this.selectParticipatingStore = this.selectParticipatingStore.bind(this);
    }
  state = {
      checkedAvailableStores: [],
      participatingStores: [],
      isAllLBGroupSelected: false,
      companyDetails: {}
  }

  /**
   *
   */
  componentDidMount() {
      let { companyStores, value } = this.props;
      // property for scheduler reports with selected stores
      let {selected} = this.props;
      if (selected && selected.length > 0) {
          value = selected;
      }
      const availableStores = companyStores.filter((store) => !value.includes(store.StoreUID));
      this.setState({
          participatingStores: companyStores.filter((store) => value.includes(store.StoreUID)),
          treeList: groupStores(availableStores),
          companyStores
      }, this.onChange);

      const url = Config.apiBaseUrl + CommonConstants.apiUrls.getCompany;
      this.api.getData(url, (data) => {
          if (data) {
              this.setState({ companyDetails: data });
          } else {
              console.log('Failed to get companyID');
          }
      });
  }

  // TODO: find better way to do that
  /**
   *
   */
  addToParticipating() {
      const { companyStores, checkedAvailableStores, participatingStores } = this.state;
      if (!checkedAvailableStores.length) {
          return;
      }
      const alreadyParticipatedStores = participatingStores.map((s) => s.StoreUID);
      // stores that do not match with participated stores and selected available stores
      const availableStores = companyStores.filter((s) => ![...checkedAvailableStores, ...alreadyParticipatedStores].includes(s.StoreUID));
      // stores that will be merged with participated
      const newParticipatingStores = companyStores.filter((s) => checkedAvailableStores.includes(s.StoreUID));
      // make new participating store items unchecked
      newParticipatingStores.forEach((item) => { item.selected = false; });

      const treeList = groupStores(availableStores);
      this.setState({
          participatingStores: [...newParticipatingStores, ...participatingStores],
          checkedAvailableStores: [],
          treeList
      }, this.onChange);
  }

  // TODO: find better way to do that
  /**
   *
   */
  removeFromParticipating() {
      const { companyStores, participatingStores, isAllLBGroupSelected } = this.state;
      // get uids of selected participating stores
      const selectedParticipatingStores = participatingStores.filter((store) => store.selected).map((store) => store.StoreUID);
      if (!selectedParticipatingStores.length) {
          return;
      }
      // filter current participated stores from selected items
      const filteredParticipatingStores = participatingStores.filter((store) => !selectedParticipatingStores.includes(store.StoreUID));
      // tree structure of available stores without participating stores
      const alreadyParticipatedStores = filteredParticipatingStores.map((store) => store.StoreUID);
      const treeList = groupStores(companyStores.filter((store) => !alreadyParticipatedStores.includes(store.StoreUID)));

      // if not items in the list and 'Select All' is selected uncheck checkbox
      if ((!filteredParticipatingStores || !filteredParticipatingStores.length) && isAllLBGroupSelected) {
          this.setState({ isAllLBGroupSelected: false });
      }
      this.setState({
          participatingStores: filteredParticipatingStores,
          treeList
      }, this.onChange);
  }

  /**
   *
   * @param {*} checkedStoreUids
   */
  selectParticipatingStore(checkedStoreUids) {
      const { participatingStores } = this.state;

      // mark selected store as 'selected'
      const updatedParticipatingStores = participatingStores.map((store) => {
          store.selected = checkedStoreUids.includes(store.StoreUID);
          return store;
      });

      this.setState({
          participatingStores: updatedParticipatingStores,
      });
  }

  /**
   *
   * @param {*} event
   * @param {*} stores
   */
  selectAllParticipatedStores(event, stores) {
      const { disabled } = this.props;
      if (disabled) {
          return;
      }
      const { target: { checked } } = event;
      const updatedParticipatingStores = stores.map((store) => {
          store.selected = checked;
          return store;
      });
      this.setState({
          participatingStores: updatedParticipatingStores,
          isAllLBGroupSelected: checked
      });
  }

  /**
   *
   */
  onChange() {
      const { participatingStores } = this.state;
      const { onChange } = this.props;
      const storeUids = participatingStores.map((store) => store.StoreUID);
      onChange(storeUids);
  }

  /**
   *
   * @return {JSX}
   */
  render() {
    const { isAllLBGroupSelected, treeList, checkedAvailableStores, participatingStores } = this.state;
    const { className, disabled, t } = this.props;
    return (
      <div className={classNames(className, 'hme-input stores-selector-input-input')}>
        <SelectStores
          disabled={disabled}
          data={treeList}
          title={t('stores-selector__available-stores')}
          checkedStores={checkedAvailableStores}
          setCheckedStores={checkedAvailableStores => this.setState({ checkedAvailableStores })}
        />
        <div className={`stores-selector-input__controllers move-group-store store-selector-button-height`}>
          <div className="stores-selector-input__controllers__add">
            <button
              disabled={disabled}
              type="button"
              className="btn btn-secondary"
              onClick={this.addToParticipating}
            >
              &gt;
                      </button>
                  </div>
                  <div className="stores-selector-input__controllers__remove">
                      <button
                          disabled={disabled}
                          type="button"
                          className="btn btn-secondary"
                          onClick={this.removeFromParticipating}
                      >
              &lt;
                      </button>
                  </div>
              </div>
              {/* NOTE: LBGroupTable was originally designed to be used for Leaderboards page. We added the 'isContestsPage' parameter below
           to match the different css requirements for the current context (for contests page)*/}
        <LBGroupTable
          disabled={disabled}
          title={t('stores-selector__participating-stores')}
          items={participatingStores}
          selectAll={(e, stores) => this.selectAllParticipatedStores(e, stores)}
          onChange={this.selectParticipatingStore}
          isAllSelected={isAllLBGroupSelected}
          company={this.state.companyDetails}
          isDefault={this.state.isDefault === false}
          isContestsPage={true}
        />
      </div>
    )
  }
}

export const StoresSelectorInput = compose(
  withTranslation()
)(StoresSelectorInputComponent);
