import { useState, useEffect, useCallback } from 'react';

import {
    getCustomerNotifications,
    enableCustomerNotification,
    disableCustomerNotification,
    deleteCustomerNotification
} from 'services/CustomerNotification';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { notificationToRow } from 'library/CustomerNotificationBanner/helpers';

const load = async (setNotifications, setIsLoading) => {
    setIsLoading(true);

    try {
        const notifications = await getCustomerNotifications();

        setNotifications(notifications);
    } catch (err) {
        addErrorNotification(err.message);
    }

    setIsLoading(false);
};

const enable = async ({
    notification,
    setIsLoading,
    setNotifications
}) => {
    setIsLoading(true);

    try {
        await enableCustomerNotification(notification.customerNotificationID);
    } catch (err) {
        addErrorNotification(err.message);
        setIsLoading(false);

        return;
    }

    load(setNotifications, setIsLoading);
};

const disable = async ({
    notification,
    setIsLoading,
    setNotifications
}) => {
    setIsLoading(true);

    try {
        await disableCustomerNotification(notification.customerNotificationID);
    } catch (err) {
        addErrorNotification(err.message);
        setIsLoading(false);

        return;
    }

    load(setNotifications, setIsLoading);
};

const remove = async ({
    notification,
    setIsLoading,
    setNotifications
}) => {
    setIsLoading(true);

    try {
        await deleteCustomerNotification(notification.customerNotificationID);
    } catch (err) {
        addErrorNotification('admin__customer-notification__delete__notification__toast--error');
        setIsLoading(false);

        return;
    }

    await load(setNotifications, setIsLoading);

    addSuccessNotification('admin__customer-notification__delete__notification__toast--success');
};

export const useAdminStatus = () => {
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [isLoadingModalShown, setIsLoadingModalShown] = useState(false);

    const onDisable = useCallback((notification) => {
        disable({
            notification,
            setIsLoading: setIsLoadingModalShown,
            setNotifications
        });
    }, []);

    const onEnable = useCallback((notification) => {
        enable({
            notification,
            setIsLoading: setIsLoadingModalShown,
            setNotifications
        });
    }, []);

    const onRemove = useCallback((notification) => {
        remove({
            notification,
            setIsLoading: setIsLoadingModalShown,
            setNotifications
        });
    }, []);

    useEffect(() => {
        load(setNotifications, setIsLoading);
    }, []);

    useEffect(() => {
        const newRows = notifications.map((notification) => notificationToRow({
            notification
        }));

        setRows(newRows);
    }, [notifications]);

    return {
        notifications: rows,
        isLoading,
        isLoadingModalShown,
        onDisable,
        onEnable,
        onRemove,
    };
};
