import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { ProviderGrid } from '../ProviderGrid';

const ProviderForm = ({ vaioProvider }) => {
    const providersRef = useRef([vaioProvider]);

    return (
        <div>
            {vaioProvider && (
                <div>
                    <ProviderGrid  providers={providersRef.current}/>
                </div>
            )}
        </div>
    );
};

export const ProviderField = ({ vaioProvider }) => {
    return <div>
        <ProviderForm vaioProvider={vaioProvider} />
    </div>;
};

ProviderForm.propTypes = {
    formValues: PropTypes.object,
    onProviderChange: PropTypes.func
};

ProviderField.propTypes = ProviderForm.propTypes;
