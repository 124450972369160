import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';

import './Popup.scss';

export const Popup = ({ sourceDevice, destinationDevice, onConfirm, onCancel, showPopup }) => {
    const { t } = useTranslation();

    const popupMessage = (
        <div className="merge-devices-popup-table">
            <p>{t('merge_devices__popup__info')}</p>
            <table>
                <tbody>
                    <tr>
                        <td className="merge-devices-popup-table-cell-description">{t('merge_devices__popup__info--store-number')}</td>
                        <td>{sourceDevice.Store_Number}</td>
                    </tr>
                    <tr>
                        <td className="merge-devices-popup-table-cell-description">{t('merge_devices__popup__info--device-serial')}</td>
                        <td>{destinationDevice.Device_SerialNumber}</td>
                    </tr>
                    <tr>
                        <td className="merge-devices-popup-table-cell-description">{t('merge_devices__popup__info--system')}</td>
                        <td>{sourceDevice.DeviceType_Name}</td>
                    </tr>
                </tbody>
            </table>
            <p>{t('merge_devices__popup__question')}</p>
        </div>
    );

    return (
        <div className="merge-devices-popup">
            <ConfirmPopupComponent
                show={showPopup}
                onConfirm={onConfirm}
                onHide={onCancel}
                title={t('merge_devices__popup__title')}
                message={popupMessage}
                actions={[
                    {
                        children: t('common__popup--cancel-action'),
                        onClick: onCancel,
                    },
                    {
                        children: t('common__popup--confirm-action'),
                        variants: ['submit'],
                        onClick: onConfirm,
                    },
                ]}
            />
        </div>
    );
};
