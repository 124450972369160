export const DEVICE_SETTINGS_ZOOM_GRID_OPTIONS = [
    {
        text: 'common__name',
        property: 'SettingName',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__description',
        property: 'Description',
        flex: 169,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__device-server-type',
        property: 'DeviceServerType',
        flex: 80,
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__snapshot__version',
        property: 'SettingsVersion',
        flex: 80,
        sortable: true,
    },
    {
        text: 'common__brand',
        property: 'SourceBrand',
        flex: 80,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__account',
        property: 'SourceAccount',
        flex: 255,
        className: 'device-settings-snapshots-account-cell',
        sortable: true,
    },
    {
        text: 'settings_snapshots__grid__header-title--source-settings-version',
        property: 'SourceSettingsVersion',
        flex: 70,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'settings_snapshots__grid__header-title--source-version',
        property: 'SourceSoftwareVersion',
        flex: 70,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__device-serial_no',
        property: 'SourceSN',
        className: 'hme-grid-cell-show-all-content',
        flex: 100,
        sortable: true,
    },
    {
        text: 'common__lane-type',
        property: 'SourceLaneConfig',
        flex: 80,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__created',
        property: 'CreatedDate',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
        sortDirections: [0, -1, 1]
    },
    {
        text: 'common__created-by',
        property: 'CreatedBy',
        flex: 170,
        className: 'device-settings-snapshots-created-by-cell',
        headerClassName: 'hme-grid-filter-align-right',
        sortable: true,
    },
    {
        text: 'common__last-used',
        property: 'AppliedDate',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        headerClassName: 'hme-grid-filter-align-right',
        sortable: true,
        sortDirections: [0, -1, 1]
    },
    {
        text: 'common__last_applied_by',
        property: 'AppliedBy',
        flex: 170,
        className: 'device-settings-snapshots-applied-by-cell',
        headerClassName: 'hme-grid-filter-align-right',
        sortable: true,
    },
    {
        text: '',
        property: 'Actions',
        flex: 80,
        className: 'hme-grid-action-cell',
    },
];

export const DEVICE_SETTINGS_NEXEO_GRID_OPTIONS = [
    {
        text: 'common__name',
        property: 'SettingName',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__description',
        property: 'Description',
        flex: 169,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__snapshot__version',
        property: 'SettingsVersion',
        flex: 100,
        sortable: true,
    },
    {
        text: 'common__brand',
        property: 'SourceBrand',
        flex: 80,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__account',
        property: 'SourceAccount',
        flex: 255,
        className: 'device-settings-snapshots-account-cell',
        sortable: true,
    },
    {
        text: 'settings_snapshots__grid__header-title--source-settings-version',
        property: 'SourceSettingsVersion',
        flex: 70,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'settings_snapshots__grid__header-title--source-version',
        property: 'SourceSoftwareVersion',
        flex: 70,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__device-serial_no',
        property: 'SourceSN',
        className: 'hme-grid-cell-show-all-content',
        flex: 100,
        sortable: true,
    },
    {
        text: 'common__device__product-id',
        property: 'SourceDeviceProductID',
        className: 'hme-grid-cell-show-all-content',
        flex: 100,
        sortable: true,
    },
    {
        text: 'common__lane-type',
        property: 'SourceLaneConfig',
        flex: 80,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__created',
        property: 'CreatedDate',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
        sortDirections: [0, -1, 1]
    },
    {
        text: 'common__created-by',
        property: 'CreatedBy',
        flex: 170,
        className: 'device-settings-snapshots-created-by-cell',
        headerClassName: 'hme-grid-filter-align-right',
        sortable: true,
    },
    {
        text: 'common__last-used',
        property: 'AppliedDate',
        flex: 100,
        className: 'hme-grid-cell-show-all-content',
        headerClassName: 'hme-grid-filter-align-right',
        sortable: true,
        sortDirections: [0, -1, 1]
    },
    {
        text: 'common__last_applied_by',
        property: 'AppliedBy',
        flex: 170,
        className: 'device-settings-snapshots-applied-by-cell',
        headerClassName: 'hme-grid-filter-align-right',
        sortable: true,
    },
    {
        text: '',
        property: 'Actions',
        flex: 80,
        className: 'hme-grid-action-cell',
    },
];