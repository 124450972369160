export const sortByProductID = (rows, direction) => {
    return rows.map((row) => ({
        ...row,
        Device_Details: row.Device_Details
            .sort((a = '', b = '') => {
                return a.localeCompare(b) * direction;
            })
    })).sort((a, b) => {
        const firstValue = a.Device_Details.length ? a.Device_Details[0].Device_Product_ID : '';
        const secondValue = b.Device_Details.length ? b.Device_Details[0].Device_Product_ID : '';

        return firstValue.localeCompare(secondValue) * direction;
    });
};
