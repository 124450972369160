import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Grid, applySearchValue } from 'components/Common/Grid';
import { Paginate } from 'library/Paginate';
import { PAGE_DATA } from 'constants/paginationDefault';
import { getProfile } from 'services/Auth';
import { Address as LibAddress } from 'library/Address';
import { useQuery } from 'hooks/useQuery';
import { formatRegistrationKey } from 'helpers/formatRegistrationKey';
import { searchOptionsUser, searchOptionsDistributor } from '../searchOptions';
import { sortBySerialNumber, sortByVersion, sortByStatus, sortByStoreProps, sortByProductID } from '../helpers';
import { StoreSerialNumbers } from '../../../StatusPage/Common/List/StoreSerialNumbers';
import { DeviceProductIDs } from './DeviceProductIDs';
import { StoreVersions } from './StoreVersions';
import { StoreStatuses } from './StoreStatuses';
import { ViewButton } from './ViewButton';
import { TilesList } from './TilesList';
import { isDistributor as isAccountDistributor } from '../helpers/companyTypeDeterminator';

import './List.scss';

const getAddressColumns = ({ store, companyType }) => {
    if (!isAccountDistributor(companyType)) {
        return {
            cityString: store.Store_Locality && store.Store_Region
                ? `${store.Store_Locality}, ${store.Store_Region}`
                : store.Store_Locality || store.Store_Region || '',
            Store_AddressLine1: store.Store_AddressLine1
        };
    }

    return {
        Store_AddressLine1: <LibAddress
            value={{
                line1: store.Store_AddressLine1,
                city: store.Store_Locality || '',
                region: store.Store_Region || '',
                country: store.Country_Name,
                zipCode: ''
            }}
            isReadOnly
        />
    };
};

const storeToRow = ({ store, isCorpUser, profile, uuid, isAdminUser, companyType }) => {
    return {
        ...store,
        brandText: isCorpUser && profile.User_Company_ID === store.Store_Company_ID ?
            `${store.Brand_Name}*` :
            store.Brand_Name,
        ...getAddressColumns({ store, companyType }),
        Actions: (<ViewButton suid={store.Store_UID} uuid={uuid} isAdminUser={isAdminUser} />),
        SerialNumber: (<StoreSerialNumbers devices={store.Device_Details} />),
        DeviceProductID: (<DeviceProductIDs devices={store.Device_Details} />),
        Version: (<StoreVersions devices={store.Device_Details} />),
        Status: (<StoreStatuses devices={store.Device_Details} />),
        DeviceSerialNumbers: store.Device_Details
            .map(({ Device_SerialNumber }) => Device_SerialNumber)
            .filter(serialNumber => !!serialNumber)
            .join(' '),
        DeviceVersions: store.Device_Details
            .map(({ Device_MainVersion }) => Device_MainVersion)
            .filter(veriosn => !!veriosn)
            .join(' '),
        DeviceProductIDs: store.Device_Details
            .map(({ Device_Product_ID }) => Device_Product_ID)
            .filter(DeviceProductID => !!DeviceProductID)
            .join(' '),
        registrationKey: formatRegistrationKey(store.Store_RegistrationKey) || '',
    };
};

const sortDirections = [1, -1];

const sortRows = (rows, sorting) => {
    const prop = Object.keys(sorting)[0];
    const direction = sorting[prop];

    switch (prop) {
        case 'SerialNumber':
            return sortBySerialNumber(rows, direction)
                .map((row) => ({
                    ...row,
                    SerialNumber: (<StoreSerialNumbers devices={row.Device_Details} />),
                    Version: (<StoreVersions devices={row.Device_Details} />),
                    Status: (<StoreStatuses devices={row.Device_Details} />),
                    DeviceProductID: (<DeviceProductIDs devices={row.Device_Details} />),
                }));

        case 'DeviceProductID':
            return sortByProductID(rows, direction)
                .map((row) => ({
                    ...row,
                    SerialNumber: (<StoreSerialNumbers devices={row.Device_Details} />),
                    Version: (<StoreVersions devices={row.Device_Details} />),
                    Status: (<StoreStatuses devices={row.Device_Details} />),
                    DeviceProductID: (<DeviceProductIDs devices={row.Device_Details} />),
                }));

        case 'Version':
            return sortByVersion(rows, direction)
                .map((row) => ({
                    ...row,
                    SerialNumber: (<StoreSerialNumbers devices={row.Device_Details} />),
                    Version: (<StoreVersions devices={row.Device_Details} />),
                    Status: (<StoreStatuses devices={row.Device_Details} />),
                    DeviceProductID: (<DeviceProductIDs devices={row.Device_Details} />),
                }));

        case 'Status':
            return sortByStatus(rows, direction)
                .map((row) => ({
                    ...row,
                    SerialNumber: (<StoreSerialNumbers devices={row.Device_Details} />),
                    Version: (<StoreVersions devices={row.Device_Details} />),
                    Status: (<StoreStatuses devices={row.Device_Details} />),
                    DeviceProductID: (<DeviceProductIDs devices={row.Device_Details} />),
                }));

        case 'brandText':
            return sortByStoreProps(rows, direction, 'Brand_Name');

        default:
            return sortByStoreProps(rows, direction, prop);
    }
};

const filterAndSort = ({ searchValue, rows, sorting, setFilteredRows, setPage, setIsFiltering, allSearchFields }) => {
    setIsFiltering(true);

    setTimeout(() => {
        const fields = searchValue.selectValue ? [searchValue.selectValue] : allSearchFields;
        const newFilteredRows = applySearchValue(rows, fields, searchValue.searchValue, { minLength: 1 });
        const sortedRows = sortRows(newFilteredRows, sorting);

        setFilteredRows([...sortedRows]);
        setIsFiltering(false);
    }, 50);
};

export const List = ({
    stores,
    searchValue,
    sorting,
    isCorpUser = false,
    isLoading,
    onRenderingChange,
    onSorting,
    isAdminUser,
}) => {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [pagedRows, setPagedRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const profile = useMemo(() => getProfile(), []);

    const companyType = useSelector((s) => s.userAccounts.ownerAccount['Company_Type']);
    const isDistributor = isAccountDistributor(companyType);

    const { t } = useTranslation();
    const query = useQuery();
    const uuid = useMemo(() => query.get('uuid') || null, [query]);

    const searchOptions = useMemo(() => isDistributor ? searchOptionsDistributor : searchOptionsUser, [isDistributor]);
    const allSearchFields = useMemo(() => searchOptions.map(({ value }) => value).filter(value => !!value), [searchOptions]);

    useEffect(() => {
        setPage(0);
    }, [searchValue, setPage]);

    useEffect(() => {
        const newRows = stores.map((store) => storeToRow({ store, isCorpUser, profile, isAdminUser, uuid, companyType }));

        setRows(newRows);
    }, [stores, isCorpUser, profile, isAdminUser, uuid, setRows]);

    useEffect(() => {
        filterAndSort({
            searchValue,
            rows,
            sorting,
            setFilteredRows,
            setPage,
            setIsFiltering: onRenderingChange,
            allSearchFields,
        });
    }, [rows, searchValue, sorting, setFilteredRows, setPage, onRenderingChange]);

    useEffect(() => {
        if (rowsPerPage === -1) {
            return setPagedRows(filteredRows);
        }

        const newRows = filteredRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
        setPagedRows(newRows);
    }, [filteredRows, page, rowsPerPage, setPagedRows]);

    const onPaginationChange = useCallback(({ page, recordsPerPage }) => {
        setPage(page);
        setRowsPerPage(recordsPerPage === -1 ? recordsPerPage : Number.parseInt(recordsPerPage));
    }, [setPage, setRowsPerPage]);

    const onSortingChange = useCallback((newSorting) => {
        if (isLoading) {
            return;
        }

        onSorting(newSorting);
    }, [isLoading, onSorting]);

    const headers = useMemo(() => [{
        text: 'stores__list-header__store-information',
        property: [{
            text: 'common__brand',
            property: 'brandText',
            sortable: true,
        }, {
            text: 'common__store__number',
            property: 'Store_Number',
            sortable: true,
        }, {
            text: 'common__store__name',
            property: 'Store_Name',
            sortable: true,
        }, {
            text: !(isDistributor || isAdminUser) ? 'common__store__address' : 'common__address',
            property: 'Store_AddressLine1',
            sortable: true,
        }, !(isDistributor || isAdminUser) ? {
            text: 'stores__list-header__city-state',
            property: 'cityString',
            sortable: true,
        } : {
            text: 'common__registration-key',
            property: 'registrationKey',
            sortable: true,
        }, {
            text: 'common__leaderboard',
            property: 'Group_Name',
            sortable: true,
        }, {
            text: 'stores__list-header__report-group',
            property: 'GroupName',
            sortable: true,
        }, {
            text: 'stores__list-header__actions',
            property: 'Actions',
            className: 'hme-stores-status--public__grid__action',
            headerClassName: 'hme-stores-status--public__grid__action',
        }].filter(Boolean),
    }, {
        text: 'stores__list-header__device-information',
        property: [{
            text: 'common__device__serial-number--text',
            property: 'SerialNumber',
            sortable: true,
            flex: 1.25
        }, {
            text: 'common__device__product-id',
            property: 'DeviceProductID',
            sortable: true,
            flex: 1.25,
        }, {
            text: 'common__device__version',
            property: 'Version',
            sortable: true,
            flex: 0.5
        }, {
            text: 'common__status',
            property: 'Status',
            flex: 1.5,
            className: 'hme-stores-status--public__grid__status-cell',
            headerClassName: 'hme-stores-status--public__grid__header__status-cell',
            sortable: true,
        }],
    }], [companyType, isDistributor, isAdminUser]);

    return (
        <>
            <Grid
                headers={headers}
                rows={pagedRows}
                rowKey='Store_UID'
                className='hme-stores-status--public__grid hme-l-size'
                noRecordsMessage={t('common__no-stores--found')}
                isLoading={isLoading}
                loadingText={t('common__loading')}
                onSortChange={onSortingChange}
                sortSelection={sorting}
                sortDirections={sortDirections}
            />
            <TilesList
                stores={pagedRows}
                isLoading={isLoading}
                uuid={uuid}
                isAdminUser={isAdminUser}
                isDistributorUser={isDistributor}
                companyType={companyType}
            />
            { !isLoading &&
                    <Paginate
                        className='hme-stores-status--public__paginate'
                        page={page}
                        recordsPerPage={rowsPerPage}
                        pageSizes={ profile.admin ? PAGE_DATA.PAGE_SIZES_ADMIN : PAGE_DATA.PAGE_SIZES_PUBLIC}
                        total={filteredRows.length}
                        onChange={onPaginationChange}
                    />
            }
        </>
    );
};
