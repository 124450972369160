import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useClickOutSide } from 'hooks/useClickOutSide';

import { ReactComponent as DropdownIcon } from 'assets/icons/icon-dropdown.svg';

import './Dropdown.scss';

const defaultPlaceholder = 'common__select-item';

export const Dropdown = ({ placeholder, isDisabled = false, onChange, children }) => {
    const { t } = useTranslation();
    const selectRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);

    const outSideClickHandler = useCallback(() => {
        setIsOpen(false);
    }, []);

    useClickOutSide(selectRef, outSideClickHandler);

    return (
        <div
            className={classNames('hme-dropdown', { 'hme-dropdown-opened': isOpen })}
            onClick={() => (isDisabled ? '' : setIsOpen(!isOpen))}
            ref={selectRef}
        >
            <div className={classNames('hme-dropdown-text', { disabled: isDisabled })}>
                {t(placeholder || defaultPlaceholder)}
            </div>
            <DropdownIcon className={classNames('hme-dropdown-icon', { disabled: isDisabled })} />
            <ul disabled={isDisabled} className="hme-dropdown-list">
                {children.map(({ value: optionValue, text }) => (
                    <li
                        key={typeof optionValue === 'object' ? JSON.stringify(optionValue) : optionValue}
                        className="hme-dropdown-list-item"
                        onClick={() => {
                            setIsOpen(false);
                            onChange && onChange(optionValue);
                        }}
                    >
                        {text}
                    </li>
                ))}
            </ul>
        </div>
    );
};

const valueType = PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]);

const textType = PropTypes.oneOfType([PropTypes.string, PropTypes.element]);

Dropdown.propTypes = {
    placeholder: textType,
    onChange: PropTypes.func,
    children: PropTypes.arrayOf(
        PropTypes.shape({
            value: valueType.isRequired,
            text: textType.isRequired,
        }),
    ),
};
