import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from 'library/Button';

import './GridFiltersCount.scss';

const defaultVariants = [];

export const GridFiltersCount = ({ count, className, variants = defaultVariants, onFiltersReset }) => {
    const { t } = useTranslation();
    return (
        <div className={classNames(
            'hme-grid-filters-count',
            {
                'hme-grid-filters-count-no-filters': count === 0,
            },
            className,
            variants.map((variant) => `hme-grid-filters-count-${variant}-variant`)
        )}>
            <div className='hme-grid-filters-count-count-number'>{count}</div>
            <div className='hme-grid-filters-count-content'>
                <div className='hme-grid-filters-count-label'>{ count === 1 ? t('common__filter-applied') : t('common__filters-applied') }</div>
                <Button
                    variants={['transparent']}
                    className='hme-grid-filters-count-reset-button'
                    onClick={onFiltersReset}
                >{t('common__reset-all-filters')}</Button>
            </div>
        </div>
    );
}
