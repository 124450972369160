import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'ramda';
import ReactDOM from 'react-dom';
import './LBGroup.css';
import { Config } from '../../Config';
import Api from '../../Api';
import AuthenticationService from '../Security/AuthenticationService';
import { Modal } from 'react-bootstrap';
import ParticipantStoresPopover from './PaticipantStoresPopover';
import '../Group/ReportGroup.css';
import { CommonConstants } from '../../Constants';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';

const memberStatusTKeys = {
    Owner: 'common__owner',
    Participant: 'common__participant',
};

class LeaderboardMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            uuid: null,
            settingsDropdown: false,
            settingsDropdownID: 0,
            selectedGroupAdmin: null,
            showLoader: 'hidden',
            showInvite: false,
            showInviteConfirm: false,
            showStoreAssignment: false,
            showStoreAssignmentConfirm: false,
            inviteeEmail: null,
            storeUidArray: [],
            // State parameters for participant Stores popover.
            showParticipantStoresPopover: false,
            selectedParticipantCompany: null,
            selectedParticipantStores: [],
            showInviteMember: false,
            showRemoveMember: false,
            showUpdateOwner: false,
            showInviteResponse: false,
            removeModalWarning: '',
            updateOwnerModalWarning: '',
            selectedOwner: {},
            memberRemovalData: {
                ParticipantID: null,
                GroupUID: null,
                isAdmin: null,
                memberCount: 0
            },
            inviteResponseData: {
                GroupUID: null,
                userEmail: null,
                acceptedGroup: null,
                InvitedBy_UserUID: null
            },
            showInviteError: false,
            groupNotSelectedError: false,
            showInviteEmailError: false,
            showInvalidTitleError: false,
            emailLanguage: 'English',
            showEmailLanguageError: false,
            inviteeToMakeOwner: null
        };
    }

    render() {
        const { showRemoveMember } = this.state;
        const { t } = this.props;
        const closeModal = () => {
            this.setState({ showRemoveMember: false });
        };
        return (
            <div className="mt-3">
                <tbody>
                    <th><span className="leaderboard-section-header no-wrap">{t('add-leaderboard__accounts__participating')} </span></th>
                    <th id="brand_space" className={this.props.isAdmin === 'true' ? '' : 'hidden'}>
                        <button onClick={this.props.toggleInviteModal} className="invite-lb-group-btn hme-button-component">
                            {t('add-leaderboard__invite-participant')}
                        </button>
                    </th>
                </tbody>

                <table>
                    <thead>
                        <tr className="clear">
                            <th className="large-header" >
                                <span id="Company" className="cursor-pointer actcol participating-accounts-col paddingOnRight text-uppercase">
                                    {t('common__company')}
                                </span>
                            </th>
                            <th className="large-header" >
                                <span id="Company" className="cursor-pointer actcol participating-accounts-col paddingOnRight text-uppercase">
                                    {t('common__email')}
                                </span>
                            </th>
                            <th className="mid-header center-align">
                                <span id="MembersStatus" className="cursor-pointer actcol participating-accounts-col text-uppercase">
                                    {t('common__status')}
                                </span>
                            </th>
                            <th className="mid-header participating-accounts-col center-align" id="brand_space">
                                <span id="User_Name" className=" cursor-pointer actcol text-uppercase">
                                    {t('common__role')}
                                </span>
                            </th>
                            <th className={'mid-header participating-accounts-col' + (this.state.uuid == this.state.selectedGroupAdmin ? '' : 'hidden')}>
                                <span id="MembersRemove" className="cursor-pointer actcol paddingOnRight text-uppercase">
                                    {t('common__actions')}
                                </span>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {this.renderMembers()}
                    </tbody>
                </table>
                {this.renderParticipantStoresPopover()}
                <ConfirmPopupComponent
                    show={showRemoveMember}
                    title={t('common__double-checking')}
                    message={<>
                        <div className="admin-create-apply-device-settings-task-filters-confirm-line">
                            {this.props.isAdmin === 'true' ? t('add-leaderboard__modal--remove__confirm--admin') : t('add-leaderboard__modal--remove__confirm')}
                        </div>
                    </>}
                    onHide={closeModal}
                    onConfirm={(e) => this.confirmRemoveMember(e)}
                    confirmationVerb={t('common__remove')}
                />
                {this.renderUpdateOwnerModal()}
            </div>
        );
    }


    renderMembers() {
        const self = this;
        const { t } = self.props;
        const groupMembers = self.props.members;
        if (groupMembers != null && groupMembers[0] != null && groupMembers[0].CompanyUID != null) {
            const memberRows = groupMembers.map(function(member, index) {
                const isAdmin = self.props.isAdmin === 'true' && member.Status != 'Owner';
                const isCompanyUser = member.CompanyUID === self.props.CompanyUID && member.Status != 'Owner';
                const dispRemove = isCompanyUser || isAdmin;
                return (
                    <tr className={'tdata clear' + (index % 2 == 1 ? ' even-row' : ' odd-row')} key={member.CompanyUID}>
                        <td>
                            <span
                                // Make company name unclickable if status is Pending or Denied.
                                className="cursor-pointer"
                            >
                                {member.Status === 'Owner' || member.Status === 'Accepted' ? (
                  <a
                      ref={(companyLink) => {
                          self.target = companyLink;
                      }}
                      onClick={(event) => self.triggerParticipantStoresPopover(event, member)}
                  >
                      {member.Company}
                  </a>
                ) : (
                  member.Company
                )}
                            </span>
                        </td>
                        <td>{member.AccountEmail}</td>
                        {/* TODO: Use css text-transform for upper case */}
                        <td className="participating-accounts-row-text">{member.Status === 'Owner' ? '' : t(memberStatusTKeys[member.Status] || member.Status)}</td>
                        <td className="participating-accounts-row-text text-uppercase">
                            {member.Status === 'Owner' ? t('common__owner') : t('add-leaderboard__participant')}
                        </td>
                        <td>
                            <a className={dispRemove ? 'cursor-pointer ' : 'hidden'} onClick={(e) => self.toggleModal(e, member.ParticipantID, 'removeMember')}>
                              {t('common__remove')}
                            </a>
                        </td>
                    </tr>
                );
            });
            return memberRows;
        } else {
            return (
                <tr>
                    <td className="no-groups">{t('add-leaderboard__accounts__no-memebers--found')}</td>
                </tr>
            );
        }
    }

    // Popup modal that let's the user decide if they are sure that they want to change a LB's admin
    renderUpdateOwnerModal() {
        const { t } = this.props;
        const showUpdateOwner = this.state.showUpdateOwner;
        const closeModal = () => {
            this.setState({ showUpdateOwner: false });
        };
        const cancelUpdateOwner = () => {
            this.setState({ showUpdateOwner: false });
        };
        return (
            <Modal show={showUpdateOwner} onHide={closeModal} aria-labelledby="ModalHeader">
                <Modal.Header closeButton>
                    <Modal.Title id="ModalHeader" className="store-modal-content">
                        {t('add-leaderboard__accounts__choose-owner')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className="clear">{t('add-leaderboard__modal--update-owner__confirm')} </span>
                    {
                        this.state.updateOwnerModalWarning &&
                        <span className="red-text">{t(this.state.updateOwnerModalWarning)}</span>
                    }
                    <div className="formattingConfirmCancelButtons">
                        <button className="btn btn-primary fright paddingOnRight" onClick={cancelUpdateOwner}>
                            {t('common__cancel')}
                        </button>
                        <button className="btn btn-primary" onClick={(e) => this.confirmUpdateOwner(e)}>
                            {t('common__confirm')}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    // Fires off the API call to change the LB's owner
    async confirmUpdateOwner(e) {
        const { t } = this.props;
        if (this.state.inviteeToMakeOwner != undefined && this.props.members.length > 1) {
            this.api = new Api();
            this.authService = new AuthenticationService(Config.authBaseUrl);
            const url = Config.apiBaseUrl + CommonConstants.apiUrls.updateLBGroupOwner + '/' + this.state.inviteeToMakeOwner;
            await this.api.getData(url, (result) => {
                console.log('Operation Success: ', result);
                window.location.href = `/leaderBoards/LBGroups`;
            });
            this.setState({ showUpdateOwner: false });
        } else {
            this.setState({ updateOwnerModalWarning: 'add-leaderboard__modal--update-owner__error--no-access' });
        }
    }

    renderRemoveMemberModal() {
        const { t } = this.props;
        const showRemoveMember = this.state.showRemoveMember;
        const closeModal = () => {
            this.setState({ showRemoveMember: false });
        };
        const cancelRemove = () => {
            this.setState({ showRemoveMember: false });
        };
        return (
            <Modal show={showRemoveMember} onHide={closeModal} aria-labelledby="ModalHeader">
                <Modal.Header closeButton>
                    <Modal.Title id="ModalHeader" className="store-modal-content">
                        {t('add-leaderboard__modal--remove-memeber__title')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className="clear">{t('add-leaderboard__modal--remove-memeber__confirm')} </span>
                    {
                        this.state.removeModalWarning &&
                        <span className="red-text">{t(this.state.removeModalWarning)}</span>
                    }
                    <div className="formattingConfirmCancelButtons">
                        <button className="btn btn-primary fright paddingOnRight" onClick={cancelRemove}>
                            {t('common__cancel')}
                        </button>
                        <button className="btn btn-primary" onClick={(e) => this.confirmRemoveMember(e)}>
                            {t('common__confirm')}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    // Trigger Popover when a participant is selected and populate state with respective store list and company name.
    triggerParticipantStoresPopover(event, member) {
        if (member.Status === 'Owner' || member.Status === 'Accepted') {
            const selectedParticipantStores = this.props.assignedStores.filter((store) => store.CompanyUID === member.CompanyUID);
            this.setState({ selectedParticipantStores });
        } else {
            this.setState({ selectedParticipantStores: [] });
        }

        this.setState({ selectedParticipantCompany: member.Company });
        this.setState({
            showParticipantStoresPopover: true
        });
        this.setState({ selectedStoreTarget: event.target });
    }

    // Popover showing stores belonging to a valid participant.
    renderParticipantStoresPopover() {
        const closePopover = () => {
            this.setState(this.setState({ showParticipantStoresPopover: false }));
        };
        return (
            <ParticipantStoresPopover
                show={this.state.showParticipantStoresPopover}
                content={this.state.selectedParticipantStores}
                company={this.state.selectedParticipantCompany}
                target={() => ReactDOM.findDOMNode(this.state.selectedStoreTarget)}
                closePopover={closePopover}
            />
        );
    }

    async confirmRemoveMember(e) {
        if (this.state.memberRemovalData.memberCount > 1) {
            this.api = new Api();
            this.authService = new AuthenticationService(Config.authBaseUrl);
            const url = Config.apiBaseUrl + CommonConstants.apiUrls.deleteLBGroupUser + '/' + this.state.memberRemovalData.ParticipantID;
            this.api.deleteData(
                    url,
                    (data) => {
                        // if participant redirect to the LB list page
                        if (this.props.isAdmin != 'true') {
                            window.location.href = '/leaderBoards/LBGroups';
                        } else {
                            this.props.renderLBGroupDetails();
                            this.props.getMembers();
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
            );

            this.setState({ showRemoveMember: false });
        } else {
            this.setState({ removeModalWarning: 'add-leaderboard__modal--remove-memeber__error--no-access' });
        }
    }

    toggleModal(e, ParticipantID, modalType) {
        if (modalType === 'removeMember') {
            // Needs to know member ParticipantID, member groupUID, and number of members in the group
            this.setState({ showRemoveMember: !this.state.showRemoveMember });
            this.setState({
                memberRemovalData: {
                    ParticipantID: ParticipantID,
                    GroupUID: this.props.GroupUID,
                    memberCount: this.props.members.length
                }
            });
        } else if (modalType === 'updateOwner') {
            // Needs to know the ParticipantID. Will take care of demoting current admin on serverside.
            this.setState({ showUpdateOwner: !this.state.showUpdateOwner });
            this.setState({ inviteeToMakeOwner: ParticipantID });
        } else {
            console.log('Error: Modal type not found');
        }
    }
}

export default compose(
    withTranslation()
)(LeaderboardMembers);
