import { useContext, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import { storeHierarchyNodeTypes } from 'constants/storeHierarchy';
import { ReactComponent as StoreIcon } from 'assets/icons/store.svg';
import { ReactComponent as FolderClosedIcon } from 'assets/icons/folderClosed.svg';
import { ReactComponent as FolderOpenedIcon } from 'assets/icons/folderOpened.svg';
import { Label } from 'library/Label';

import { HierarchyItemContext } from './context/HierarchyItemContext';

import './HierarchyItem.scss';

export const HierarchyItemGroupText = ({ className, item, searchString='', ...props }) => {
    const isSearchMatch = useMemo(() => searchString.length !== 0 &&
        item.Name.toLowerCase().includes(searchString.toLowerCase()), [item, searchString]);

    return (
        <div className={classNames(
            'hme-manage-hierarchy__hierarchy__item',
            'hme-manage-hierarchy__hierarchy__item--group',
            className
        )} {...props}>
            <FolderClosedIcon className='hme-manage-hierarchy__hierarchy__item__folder--closed' />
            <FolderOpenedIcon className='hme-manage-hierarchy__hierarchy__item__folder--opened' />
            <Label className={classNames({
                'hme-manage-hierarchy__hierarchy__item__search-matched': isSearchMatch
            })}>{item.Name}</Label>
        </div>
    );
};

export const HierarchyItemStoreText = ({ className, item, searchString='', ...props }) => {
    const content = useMemo(() => {
        const texts = ([item.Name, item.StoreNumber])
            .filter((text) => text)
            .map((value, index) => <span key={index} className={classNames({
                'hme-manage-hierarchy__hierarchy__item__search-matched': searchString.length !== 0 &&
                    value.toLowerCase().includes(searchString.toLowerCase()),
            })}>{value}</span>);

        return texts.reduce((result, element, index) => {
            if (index !== 0) {
                result.push(<span key={`separator-${index}`}>{' - '}</span>)
            }

            result.push(element);

            return result;
        }, []);
    }, [item.Name, searchString, item.StoreNumber]);

    return (
        <div className={classNames(
            'hme-manage-hierarchy__hierarchy__item',
            'hme-manage-hierarchy__hierarchy__item--store',
            className
        )} {...props}>
            <StoreIcon className='hme-manage-hierarchy__hierarchy__item__icon'/>
            <Label>{content}</Label>
        </div>
    );
};

export const HierarchyItem = ({ item, className }) => {
    const { searchString, selectedItems, onItemSelect } = useContext(HierarchyItemContext);
    const isSelected = useMemo(() => selectedItems.includes(item), [selectedItems, item]);

    const onClick = useCallback(() => {
        onItemSelect && onItemSelect(item);
    }, [item, onItemSelect]);

    const Component = item.Type === storeHierarchyNodeTypes.GROUP ? HierarchyItemGroupText : HierarchyItemStoreText;

    return (
        <Component className={classNames(className, {
            'hme-manage-hierarchy__hierarchy__item--selected': isSelected,
        })} item={item} searchString={searchString} onClick={onClick} />
    );
};
