import { min } from 'lodash/fp';

import { useNitroAnnouncementSettings } from '../../state/NitroAnnouncementSettingsContext';
import { createDurationValidator, createFrequencyValidator } from '../validator';
import { announcementItems } from '../../constants';
import { selectNotificationDurations } from '../selectors';

// if unit is HOUR convert it to minutes for comparison
const getCompareRate = (unit) => (unit === 'HOUR' ? 60 : 1);

export const useValidators = ({ item, settingType }) => {
    const { settings } = useNitroAnnouncementSettings();

    const { DisplayPeriod: displayPeriod, DisplayPeriodUnit, DisplayDurationUnit, DisplayIntervalUnit } = item;
    const settingsArray = Object.values(settings);

    const displayPeriodUnit = DisplayPeriodUnit.value;
    const displayDurationUnit = DisplayDurationUnit.value;
    const displayIntervalUnit = DisplayIntervalUnit.value;

    let periodDuration = 0;

    if (settingType === announcementItems.NOTIFICATION_FREQUENCY) {
        // get durations from notification items
        const notificationOptions = selectNotificationDurations(settingsArray);

        const durations = notificationOptions.filter(
            (setting) => setting.SettingType !== announcementItems.NOTIFICATION_FREQUENCY
        );

        periodDuration = min(
            durations.map((setting) => setting.DisplayPeriod * getCompareRate(setting.DisplayPeriodUnit.value))
        );
    } else {
        periodDuration = displayPeriod * getCompareRate(displayPeriodUnit);
    }

    return {
        displayPeriodValidator: createDurationValidator(displayPeriodUnit, settingType),
        displayDurationValidator: createFrequencyValidator(
            displayDurationUnit,
            periodDuration,
            getCompareRate(displayDurationUnit),
            settingType
        ),
        displayIntervalValidator: createFrequencyValidator(
            displayIntervalUnit,
            periodDuration,
            getCompareRate(displayIntervalUnit),
            settingType
        )
    };
};
