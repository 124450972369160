import { useMemo } from 'react';
import { baseAuthService } from 'components/Security/AuthenticationService';
import { DEFAULT_FIELD_VARIANTS } from '../GeneralInformationBlock';

const isAdmin = baseAuthService.isAdmin();

export const useGeneralInformationBlock = (storeName, defaultStoreName) => {
    // at the moment logic is next - the store name can be empty,
    // and we pass it only from the Public Portal, it's undefined for the Admin Portal
    const storeNameValue = useMemo(() => {
        return typeof storeName !== 'undefined' ? storeName : defaultStoreName;
    }, [storeName, defaultStoreName]);

    const isStoreNameDisabled = useMemo(() => isAdmin, []);
    const isStoreNameReadOnly = useMemo(() => typeof storeName === 'undefined', [storeName]);

    const storeNameFieldVariants = useMemo(() => {
        return isStoreNameReadOnly ? DEFAULT_FIELD_VARIANTS : [...DEFAULT_FIELD_VARIANTS, 'label-inside'];
    }, [isStoreNameDisabled]);

    return {
        storeNameValue,
        isStoreNameDisabled,
        isStoreNameReadOnly,
        storeNameFieldVariants
    };
};
