import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { BadgePill } from 'library/BadgePill';
import { Label } from 'library/Label';
import { ReactComponent as DropdownIcon } from 'assets/icons/down.svg';
import { itemProps } from '../types/itemProps';

import './SelectInput.scss';

export const SelectInput = ({
    selections = [],
    label = '',
    className = '',
    children,
    isReadOnly = false,
    withShowMore = true,
    showMoreHeight = 200,
    showMoreLabel = 'common__show-all-items',
    showLessLabel = 'common__show-less-items',
    visibleRows = 3,
    onParentClick,
    onChange,
    onDelete,
    isDisabled,
    icon,
    ...inputProps
}) => {
    const [expanded, setExpanded] = useState(false);
    const [showMore, setShowMore] = useState(false);

    const inputEl = useRef(null);
    const containerEl = useRef(null);

    const { t } = useTranslation();

    const onWrapperClick = (e) => {
        inputEl.current.focus();
    };

    useEffect(() => {
        if (!withShowMore) {
            return;
        }

        if (containerEl.current.scrollHeight > showMoreHeight) {
            setShowMore(true);
            return;
        }
        setShowMore(false);
    }, [withShowMore, selections]);

    return (
        <div
            className={classNames('hme-multi-select__select-input__container', { expanded: expanded })}
            onClick={onParentClick}
        >
            <Label>{label}</Label>
            <div
                className={classNames('hme-multi-select__select-input__wrapper', {
                    'hme-multi-select__select-input__wrapper--empty': selections.length === 0,
                    'hme-multi-select__select-input__wrapper--with-show-more': withShowMore,
                    'hme-multi-select__select-input__wrapper--disabled': isDisabled,
                    'hme-multi-select__select-input__wrapper--readonly': isReadOnly,
                    'hme-multi-select__select-input__wrapper--show-more-shown': showMore,
                })}
            >
                {
                    <div className="hme-multi-select__select-input__wrapper__bages" onClick={onWrapperClick}>
                        {icon && <div className="hme-multi-select__select-input__wrapper__icon">{icon}</div>}
                        <div
                            ref={containerEl}
                            className={classNames([
                                'hme-multi-select__select-input__wrapper__bages__list',
                                `hme-multi-select__select-input__wrapper__bages__list--${visibleRows}-rows`,
                            ])}
                        >
                            {selections.map((item, index) => (
                                <BadgePill key={`${index}-selections`}>
                                    <span className="hme-multi-select__select-input__wrapper__bages__bage-pill-label">
                                        {item.text}
                                    </span>
                                    {!isReadOnly && (
                                        <div
                                            className="hme-multi-select__select-input__wrapper__bages__close-cross"
                                            onClick={() => onDelete && !isDisabled && onDelete(item.value)}
                                        ></div>
                                    )}
                                </BadgePill>
                            ))}
                        </div>
                    </div>
                }
                <div className="hme-multi-select__select-input__wrapper__footer">
                    <input
                        ref={inputEl}
                        className={`hme-multi-select__select-input__wrapper__footer__input ${
                            isReadOnly || isDisabled ? 'hide' : ''
                        }`}
                        onChange={onChange && (({ target: { value } }) => onChange(value))}
                        {...inputProps}
                    />
                    <span className={isReadOnly || isDisabled ? '' : 'hide'}>{inputProps.value}</span>
                    {showMore && (
                        <span
                            className={classNames('hme-multi-select__select-input__wrapper__footer__show-more', {
                                expanded: expanded,
                            })}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? (
                                <>
                                    …{t(showLessLabel)} <DropdownIcon />
                                </>
                            ) : (
                                <>
                                    …{t(showMoreLabel)} <DropdownIcon />
                                </>
                            )}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

SelectInput.propTypes = {
    selections: PropTypes.arrayOf(itemProps).isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    isReadOnly: PropTypes.bool,
    showMoreHeight: PropTypes.number,
    showMoreLabel: PropTypes.string,
    showLessLabel: PropTypes.string,
    onParentClick: PropTypes.func,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    inputProps: PropTypes.object,
};
