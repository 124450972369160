import React from 'react';
import { Trans } from 'react-i18next';

import { CONTEST_STATUS_NAMES, CONTEST_STATUS_TRANSLATION_KEYS } from '../Constants';

import './StatusCell.scss';

const ScheduledStatusCell = () => {
    return <span className="hme-status-cell"><Trans i18nKey={CONTEST_STATUS_TRANSLATION_KEYS.SCHEDULED} /></span>;
};
const InProgressStatusCell = () => {
    return <span className="hme-status-cell hme-status-cell--in-progress">
        <Trans i18nKey={CONTEST_STATUS_TRANSLATION_KEYS.IN_PROGRESS} />
    </span>;
};
const CompletedStatusCell = () => {
    return <span className="hme-status-cell"><Trans i18nKey={CONTEST_STATUS_TRANSLATION_KEYS.COMPLETED} /></span>;
};

const CONTESTS_STATUSES_CELL_TYPE = {
    [CONTEST_STATUS_NAMES.SCHEDULED]: ScheduledStatusCell,
    [CONTEST_STATUS_NAMES.IN_PROGRESS]: InProgressStatusCell,
    [CONTEST_STATUS_NAMES.COMPLETED]: CompletedStatusCell
};

export const StatusCell = ({ statusType }) => {
    const Component = CONTESTS_STATUSES_CELL_TYPE[statusType];

    return <Component />;
};
