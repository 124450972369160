import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Label } from 'library/Label';
import { useClickOutSide } from 'hooks/useClickOutSide';

import { ReactComponent as DropdownIcon } from 'assets/icons/down.svg';

import './SelectStacked.scss';

const defaultPlaceholder = 'common__select-item';

export const SelectStacked = ({
    value,
    label,
    placeholder,
    onChange,
    children,
    isDisabled,
    isRequired = false,
    showTooltip = false
}) => {
    const selectRef = useRef(null);
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [inputText, setInputText] = useState(null);

    useEffect(() => {
        const selectedItem = children.find((item) => item.value === value) || null;
        const text = selectedItem ? selectedItem.text : t(placeholder || defaultPlaceholder);

        setInputText(text);
    }, [value, placeholder, children]);

    const outSideClickHandler = useCallback(() => {
        setIsOpen(false);
    }, []);

    useClickOutSide(selectRef, outSideClickHandler);

    return (
        <div className="hme-select-stacked-wrapper" ref={selectRef}>
            <div
                className={classNames('hme-select-stacked', {'hme-select-stacked-opened': isOpen}, isDisabled ? 'bg-light-gray' : '' )}
                onClick={() => isDisabled ? '' : setIsOpen(!isOpen)}
            >
                {!isRequired && <>
                    <Label>{label}</Label>
                    <div
                        className="hme-select-stacked-text"
                        title={showTooltip && inputText ? inputText : ''}
                    >
                        {inputText}
                    </div>
                </>}
                {isRequired && <>
                    <div
                        className="hme-select-stacked-without-label"
                        title={showTooltip && inputText ? inputText : ''}
                    >
                        <span className={isDisabled ? 'hidden' : 'hme-select-stacked-required'}>*</span>
                        {inputText}
                    </div>
                </>}
                <DropdownIcon className="hme-select-stacked-icon" />
            </div>
            {isOpen && <ul disabled={isDisabled} className="hme-select-stacked-dropdown">
                {
                    children.map(({ value: optionValue, text }) => (
                        <li
                            key={optionValue}
                            className="hme-select-stacked-dropdown-item"
                            onClick={() => {
                                onChange && onChange(optionValue);
                                setIsOpen(false);
                            }}
                            title={showTooltip && text ? text : ''}

                        >
                            {text}
                        </li>
                    ))
                }
            </ul>}
        </div>
    );
};

const valueType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
]);

const textType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
]);

SelectStacked.propTypes = {
    value: valueType.isRequired,
    label: PropTypes.string,
    placeholder: textType,
    onChange: PropTypes.func,
    children: PropTypes.arrayOf(PropTypes.shape({
        value: valueType.isRequired,
        text: textType.isRequired
    })),
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    showTooltip: PropTypes.bool
};
