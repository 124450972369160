import { jwtDecode as decode } from 'jwt-decode';
import { Config } from '../../Config';
import fetch from 'isomorphic-fetch';
import reactUuid from 'react-native-uuid';
import { Buffer } from 'buffer';
import { CommonConstants } from '../../Constants';
import axios from 'axios';

const ACCOUNTS_PAGE_NAME = 'accounts';
const USERS_PAGE_NAME = 'users';
const SETTINGS_PAGE_NAME = 'manageDeviceSettings';
const STORES_PAGE_NAME = 'stores';

const ADMIN_PAGES_WITH_SUBHEADER_MENU = [ACCOUNTS_PAGE_NAME, USERS_PAGE_NAME, SETTINGS_PAGE_NAME, STORES_PAGE_NAME];

const paths4Uid = ['/welcome', '/admin/settings/stores', '/systems', '/admin/settings/users', '/admin/accounts', '/admin/reports/'];
if (window.localStorage.getItem('uuid') && paths4Uid.some((item) => window.location.pathname.includes(item))) {
    window.localStorage.removeItem('uuid');
    window.localStorage.removeItem('selected_uuid');
    window.localStorage.removeItem('pbi-token-multi');
    window.localStorage.removeItem('pbi-token-single');
    window.localStorage.removeItem('pbi-token-trends');
    window.localStorage.removeItem('pbi-token-trendsHistory');
    window.localStorage.removeItem('pbi-token-outliers-client');
    window.localStorage.removeItem('pbi-token-performance-analysis');
    window.localStorage.removeItem('pbi-token-self-service');
    window.localStorage.removeItem('pbi-token-overview');
    window.localStorage.removeItem('pbi-token-rcd');
}

/**
 * This Service is Designed to Authentication user with token.
 * localStorage change to window.localStorage according to standard JS
*/
export default class AuthenticationService {
    // Initializing important variables

    /**
     * Set initial state
     * @param {*} domain
     */
    constructor(domain) {
        this.domain = domain || Config.authBaseUrl; // API server domain
        this.fetch = this.fetch.bind(this); // React binding stuff
        // this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this);
    }

    /**
     * function to get cold fusion URL
     * @param {boolean} isAdmin
     * @return {string}
     */
    getColdFusionAppUrl(isAdmin) {
        /* ColdFusionAppURL is no longer needed but this gets called from many places.  We will remove this
       once all places have been changed to getAppUrl
  //  if (isAdmin) {
  //    return Config.adminColdFusionUrl
  //  } else {
  //    return Config.coldFusionUrl
  //  }
  */
        return this.getAppUrl(isAdmin);
    }

    /**
     * function to get current url
     * @param {boolean} isAdmin
     * @return {string}
     */
    getAppUrl(isAdmin = this.isAdmin(), isPartner = this.isPartner()) {
        if (isAdmin) {
            return window.location.origin + '/admin';
        } else if (isPartner) {
            return window.location.origin + '/partner';
        } else {
            return window.location.origin;
        }
    }

    /**
     * set UUID to local storage
     * @param {int} uuid
     */
    setUUID(uuid) {
        window.localStorage.setItem('uuid', uuid);
    }

    /**
     * gets the UUID by decoding ctx_token
     * @return {id}
     */
    getLoggedInUserUID() {
        const token = this.decodeToken(this.getToken());
        return token.User_UID;
    }

    /**
     * gets the email by decoding ctx_token
     * @return {email}
     */
    getLoggedInUserEmail() {
        const token = this.decodeToken(this.getToken());
        return token.User_EmailAddress;
    }

    /**
     * gets UUID from local storage
     * @return {id}
     */
    getUUID() {
        return window.localStorage.getItem('uuid');
    }

    /**
     * removes uuid from local storage
    */
    clearUUID() {
        window.localStorage.removeItem('uuid');
    }

    /**
     * checks if masquerading
     * @return {boolean}
     */
    isMasquerade() {
        return this.getToken() !== this.getIdToken();
    }
    /**
     * checks if admin login
     * @return {boolean}
     */
    isAdminFeature() {
        return (this.isAdmin() && window.location.pathname.indexOf('/admin') !== -1);
    }

    /**
     * verifies admin login based on jwt token
     * @return {boolean}
     */
    isAdmin() {
        if (this.isMasquerade()) {
            return false;
        } else if (this.getIdToken()) {
            return this.decodeToken(this.getIdToken()).admin;
        } else {
            if (window.location.pathname.includes('/admin')) {
                return true;
            } else {
                return false;
            }
        }
    }

    isPartner() {
        if (this.getIdToken()) {
            return this.decodeToken(this.getIdToken()).partner;
        } else {
            return window.location.pathname.includes('/partner') || window.location.pathname.includes('/validatePartnerAccount');
        }
    }

    /**
     * checks if admin accounts page is selected
     * @return {boolean}
     */
    isAdminAccountsPageSelected() {
        return this.isAdmin && this.getSubheaderMode() === ACCOUNTS_PAGE_NAME;
    }

    /**
     * checks if admin users page is selected
     * @return {boolean}
     */
    isAdminUsersPageSelected() {
        return this.isAdmin && this.getSubheaderMode() === USERS_PAGE_NAME;
    }

    /**
     * Checks if there is a saved token and it's still valid
     * @return {boolean}
     */
    loggedIn() {
        const token = this.getToken(); // GEtting token from window.localStorage
        return Boolean(token) && !this.isTokenExpired(token); // handwaiving here
    }

    /**
     * Checks if token is expired
     * @param {string} token
     * @return {boolean}
     */
    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    }

    /**
     * Sets id_token to local storage
     * @param {*} idToken
     */
    setIdToken(idToken) {
        if (idToken) {
            window.localStorage.setItem('id_token', idToken);
        }
    }

    /**
     * Sets context token to local storage
     * @param {*} ctxToken
     */
    setContextToken(ctxToken) {
        if (ctxToken) {
            window.localStorage.setItem('ctx_token', ctxToken);
        }
    }

    /**
     * Sets tokens to local storage
     * @param {*} idToken
     * @param {*} ctxToken
     */
    setTokens(idToken, ctxToken) {
        if (idToken) {
            window.localStorage.setItem('id_token', idToken);
        }
        if (ctxToken) {
            window.localStorage.setItem('ctx_token', ctxToken);
        }
        window.localStorage.setItem('sessionUID', reactUuid.v4().toUpperCase().replace(/-/g, ''));
    }

    /**
     * Gets session UID
     * @return {string}
     */
    getSessions() {
        return window.localStorage.getItem('sessionUID');
    }

    /**
     * Calls a method which sets tokens to local storage.
     * @param {*} idToken
     * @param {*} isAdmin
     */
    setToken(idToken) {
        this.setTokens(idToken, idToken);
    }


    /**
     * Sets username to local storage
     * @param {*} userName
     */
    setUserName(userName) {
        if (userName) {
            window.localStorage.setItem('userName', userName);
        }
    }
    /**
     * Gets username from local storage
     * @return {string}
     */
    getUserName() {
        return window.localStorage.getItem('userName');
    }

    /**
     * Gets context token from local storage
     * @return {string}
     */
    getToken() {
        // Retrieves the user token from window.localStorage
        return window.localStorage.getItem('ctx_token');
    }

    /**
     * Retrieves the user token from window.localStorage
     * @return {string}
     */
    getIdToken() {
        return window.localStorage.getItem('id_token');
    }

    /**
     * Clear user token and profile data from window.localStorage
    */
    logout() {
        window.localStorage.removeItem('ctx_token');
    }

    /**
     * Clear user token and profile data from window.localStorage
    */
    clear() {
        this.revoke();
        window.localStorage.removeItem('id_token');
        window.localStorage.removeItem('ctx_token');
        window.localStorage.removeItem('unread__last-time');
        window.localStorage.removeItem('uuid');
        // window.sessionStorage.removeItem('ssoUserId');
        window.localStorage.removeItem('isAccountSSO');
        window.localStorage.removeItem('isDFAVerified');
        window.localStorage.removeItem('loginAttempts');
        window.localStorage.removeItem('loginAttemptsDate');
        window.localStorage.removeItem('DashboardMetric');
        window.localStorage.removeItem('storeListShowCommonLoader');
        window.localStorage.removeItem('selected-menu');
        window.localStorage.removeItem('user_email');
        window.localStorage.removeItem('user_perm');
        window.localStorage.removeItem('admin_user_perm');
        window.localStorage.removeItem('error');
        window.localStorage.removeItem('erroInfo');
        window.localStorage.removeItem('sessionUID');
        window.localStorage.removeItem('CompanyUID');
        window.localStorage.removeItem('ShowBellInviteIcon');
        window.localStorage.removeItem('smackTalk_configuration');
        window.localStorage.removeItem('acceptedUpdatedTOS');
        window.localStorage.removeItem('pbi-token-multi');
        window.localStorage.removeItem('pbi-token-single');
        window.localStorage.removeItem('pbi-token-trendsHistory');
        window.localStorage.removeItem('pbi-token-trends');
        window.localStorage.removeItem('pbi-token-rcd');
        window.localStorage.removeItem('pbi-token-outliers');
        window.localStorage.removeItem('pbi-token-outliers-client');
        window.localStorage.removeItem('pbi-token-performance-analysis');
        window.localStorage.removeItem('pbi-token-self-service');
        window.localStorage.removeItem('pbi-token-overview');
        window.localStorage.removeItem('pbi-token-ghost-cars-report');
        window.localStorage.removeItem('pbi-token-speed-of-service');
        window.localStorage.removeItem('pbi-token-speed-of-service-detection');
        window.localStorage.removeItem('pbi-token-store-status');
    }
    /**
     * Revoke the user's token
    */
    revoke() {
        const logSessionEndUrl = Config.apiBaseUrl + CommonConstants.apiUrls.logUserOut;
        if (this.getToken()) {
            axios(logSessionEndUrl, {
                method: 'post',
                data: { UUID: this.getUUID() },
                responseType: 'json',
                headers: {
                    'Authorization': `Bearer ${this.getToken()}`,
                    'Content-Type': 'application/json'
                }
            }).then((data) => {
                console.log('logged out');
            });
        }
    }

    /**
     * Refresh the user's token
    */
    async refresh() {
        const refreshURL = Config.authBaseUrl + Config.tokenPath;
        const ctxToken = this.getToken();
        if (ctxToken) {
            try {
                const result = await axios(refreshURL, {
                    method: 'post',
                    data: { refresh: true },
                    responseType: 'json',
                    headers: {
                        'Authorization': `Bearer ${ctxToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (result.data && result.data.accessToken) {
                    if (this.isMasquerade()) {
                        this.setContextToken(result.data.accessToken);
                    } else {
                        this.setTokens(result.data.accessToken, result.data.accessToken);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    /**
     * Refresh the admin's id_token when in masquerade mode
    */
    async refreshIdToken() {
        const refreshURL = Config.authBaseUrl + Config.tokenPath;
        const idToken = this.getIdToken();
        if (idToken) {
            try {
                const result = await axios(refreshURL, {
                    method: 'post',
                    data: { refresh: true },
                    responseType: 'json',
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (result.data && result.data.accessToken) {
                    this.setIdToken(result.data.accessToken);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    /**
     * Returns how many seconds the token is valid for
     * @param {boolean} adminStatus
     * @return {number}
    */
    secondsUntilTokenExpires(adminStatus = false) {
        try {
            const decoded = adminStatus ? decode(this.getIdToken()) : decode(this.getToken());
            const currentTime = Math.floor((Date.now() - new Date().getTimezoneOffset()) / 1000);
            return (parseInt(decoded.exp) - currentTime);
        } catch (error) {
            console.log(error);
            return 0;
        }
    }

    /**
     * Clear user token and profile data from window.localStorage
    */
    clearAgreedAccount() {
        window.localStorage.removeItem('id_token');
        window.localStorage.removeItem('ctx_token');
    }
    /**
     * Clear user token and profile data from window.localStorage
    */
    logoutMasquerade() {
        window.localStorage.removeItem('masquerade');
    }

    /* getLoggedInProfile() {
        // Using jwt-decode npm package to decode the token

        return decode(window.localStorage.getItem('id_token'))
    } */
    /**
     * Checks if user is already logged in
     * @return {boolean}
     */
    isLoggedIn() {
        return this.loggedIn();
    }

    /**
     * @param {*} currentRoute
     * @return {boolean}
     */
    isShowHeader(currentRoute) {
        return currentRoute !== '/validateDfaAuth';
    }

    /**
     * Decodes the context token and returns the information.
     * @return {object}
     */
    getProfile() {
        // Using jwt-decode npm package to decode the token
        return this.decodeToken(this.getToken());
    }

    /**
     * Decodes the id token and returs the information.
     * @return {object}
     */
    getAdminProfile() {
        // Using jwt-decode npm package to decode the token
        return this.decodeToken(this.getIdToken());
    }

    /**
     * Decodes the token using jwt package's decode method
     * @param {*} token
     * @return {*}
     */
    decodeToken(token) {
        if (token) {
            return decode(token);
        } else {
            return {};
        }
    }

    /**
     * Parses permissions and returns JSON data
     * @param {*} permissions
     * @return {array}
     */
    parsePermissions(permissions) {
        if (permissions) {
            const buff = new Buffer(permissions, 'base64');
            const text = buff.toString('ascii');
            try {
                return JSON.parse(text);
            } catch (error) {
                return [];
            }
        } else {
            return [];
        }
    }

    /**
     * Gets user permissions from local storage
     * @return {*}
     */
    getPermission() {
        const userPermission = window.localStorage.getItem('user_perm');

        return this.parsePermissions(userPermission);
    }

    /**
     * Gets admin user permissions from local storage
     * @return {*}
     */
    getAdminPermission() {
        const adminPermission = window.localStorage.getItem('admin_user_perm');

        return this.parsePermissions(adminPermission);
    }

    /**
     * @param {*} data
     * @param {*} key
     */
    setPermissionByKey(data, key) {
        const buff = new Buffer(JSON.stringify(data));
        const base64data = buff.toString('base64');
        window.localStorage.setItem(key, base64data);
    }

    /**
     *  Sets user permissions in local storage
     * @param {*} data
     */
    setPermission(data) {
        this.setPermissionByKey(data, 'user_perm');
    }

    /**
     * Sets admin user permissions in local storage
     * @param {*} data
     */
    setAdminPermission(data) {
        this.setPermissionByKey(data, 'admin_user_perm');
    }

    /**
     * Sets user email in local storage
     * @param {string} email
     */
    setUserEmail(email) {
        window.localStorage.setItem('user_email', email);
    }

    /**
     * Gets user email from local storage
     * @return {*}
     */
    getUserEmail() {
        return window.localStorage.getItem('user_email') ? window.localStorage.getItem('user_email') : null;
    }

    /**
     * Removes user email from local storage
    */
    clearUserEmail() {
        window.localStorage.removeItem('user_email');
    }

    /**
     * @return {string}
     */
    getUrlMenu() {
        const url = window.location.href;
        // eslint-disable-next-line require-unicode-regexp
        const page = url.substr(url.lastIndexOf('/') + 1).split(/[?#]/)[0];
        return page;
    }

    /**
     * Sets current selected menu in local storage
     * @param {*} menu
     */
    setSelectedMenu(menu) {
        window.localStorage.setItem('selected-menu', menu);

        if (ADMIN_PAGES_WITH_SUBHEADER_MENU.some((item) => item === menu)) {
            this.setSubheaderMode(menu);
        }
    }
    /**
     * Sets current sub-header menu in local storage
     * @param {*} mode
     */
    setSubheaderMode(mode) {
        window.localStorage.setItem('subheader-mode', mode);
    }

    /**
   *  some pages in admin portal have submenu
   *  such Accounts/View Details and Users/ViewDetails
   * @return {string}
  */
    getSubheaderMode() {
        return window.localStorage.getItem('subheader-mode');
    }

    /**
     * Gets current selected menu from local storage
     * @return {string}
     */
    getSelectedMenu() {
        return window.localStorage.getItem('selected-menu');
    }

    /**
     * Sets company UID to local storage
     * @param {*} CompanyUID
     */
    setCompanyUID(CompanyUID) {
        window.localStorage.setItem('CompanyUID', CompanyUID);
    }

    /**
     * Gets company UID from local storage
     * @return {*}
     */
    getCompanyUID() {
        return window.localStorage.getItem('CompanyUID') ? window.localStorage.getItem('CompanyUID') : null;
    }

    /**
     * Removes company UID from local storage
    */
    clearCompanyUID() {
        window.localStorage.removeItem('CompanyUID');
    }

    /** */
    clearShowBellInviteIcon() {
        window.localStorage.removeItem('ShowBellInviteIcon');
    }
    /* getTokenDetails(token) {
        return decode(token)
    } */

    /**
     * performs api calls sending the required authentication headers
     * @param {*} url
     * @param {*} options
     * @return {*}
     */
    fetch(url, options) {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken();
        }

        return fetch(url, {
            headers,
            ...options
        })
                .then(this._checkStatus)
                .then((response) => response.json());
    }

    /**
     * raises an error in case response status is not a success
     * @param {*} response
     * @return {*}
     */
    _checkStatus(response) {
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response;
        } else {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    }

    /**
     * @return {*}
     */
    getMasqueradeAdminUser() {
        return this.isMasquerade() ? this.getAdminProfile().unique_name : null;
    }
}

export const baseAuthService = new AuthenticationService();
