import React from 'react';
import { useTranslation } from 'react-i18next';

import { path, prop } from 'ramda';

export const TileItemField = ({ text, device, property }) => {
    const { t } = useTranslation();

    return (
        <div className="hme-system-status-devices-grid__tile-item-field">
            <span className="hme-system-status-devices-grid__tile-item-key">{t(text)}</span>
            <span className="hme-system-status-devices-grid__tile-item-value">
                {
                    Array.isArray(property)
                    ? path(property, device) || ' '
                    : prop(property, device)
                }
            </span>
        </div>
    );
};
