import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { PUBLIC_ROUTES } from 'constants/routes';
import { Modal as LibraryModal } from 'library/Modal';
import { CheckboxGroup } from 'library/CheckboxGroup';
import { BUTTON_VARIANTS, Button } from 'library/Button';
import { SEPARATOR_VARIANTS, Separator } from 'library/Separator';
import { CenterLoader } from 'components/Common/CenterLoader';

import { ReactComponent as Ribbon } from 'assets/icons/certificate-ribbon.svg';

import './CertificateContainer.scss';

const storeGroupActionVariants = [BUTTON_VARIANTS.TRANSPARENT];
const downloadSertificateVariant = [BUTTON_VARIANTS.SUBMIT];
const separatorVariants = [SEPARATOR_VARIANTS.PURE];

const ModalHeader = () => {
    const { t } = useTranslation();

    return (
        <div className="hme-certificate-container__header">
            <div className="hme-certificate-container__header-icon">
                <Ribbon />
            </div>
            <h2 className="hme-certificate-container__header-title">{t('contest__certificate_other')}</h2>

            <p className="hme-certificate-container__header-description">{t('contest__certificate-description')}</p>
        </div>
    );
};

export const Certificate = ({
    stores = [],
    storeName,
    isLoading,
    onSubmit,
    selectedStores,
    setSelectedStores,
    onHide
}) => {
    const { t } = useTranslation();

    const [isModalClosed, setIsModalClosed] = useState(false);

    const selectAllStores = useCallback(() => {
        setSelectedStores(stores.map((store) => store.value));
    }, [stores]);

    const unselectAllStores = useCallback(() => {
        setSelectedStores([]);
    }, []);

    const hideModal = useCallback(() => {
        if (onHide) {
            onHide();
        } else {
            setIsModalClosed(true);
        }
    }, []);

    const isGeneratePdfDisabled = selectedStores.length === 0;

    if (isModalClosed && !onHide) {
        return <Navigate to={PUBLIC_ROUTES.contestsList} />;
    }

    return (
        <LibraryModal
            show
            title={<ModalHeader storeName={storeName} />}
            dialogClassName="hme-certificate-container"
            contentClassName="hme-certificate-container__content"
            onHide={hideModal}
        >
            <h3 className="hme-certificate-container__contest-name">{storeName}</h3>

            {isLoading ? <CenterLoader /> : (
                <>
                    <CheckboxGroup
                        items={stores}
                        value={selectedStores}
                        onChange={setSelectedStores}
                        className="hme-certificate-container__stores-group"
                    />
                    <div className="hme-certificate-container__stores-group-actions">
                        <Button variants={storeGroupActionVariants} onClick={selectAllStores}>{t('common__select-all')}</Button>
                        <Separator variants={separatorVariants} />
                        <Button variants={storeGroupActionVariants} onClick={unselectAllStores}>{t('common__deselect-all')}</Button>
                    </div>
                </>
            )}

            <div className="hme-certificate-container__actions">
                <Button onClick={hideModal}>{t('common__cancel')}</Button>

                <Button
                    onClick={onSubmit}
                    disabled={isGeneratePdfDisabled}
                    variants={downloadSertificateVariant}
                >
                    {t('contest__certificate-download-cta')}
                </Button>
            </div>
        </LibraryModal>
    );
};
