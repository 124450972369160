/* eslint-disable react/prop-types */
import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useOnTrigger } from 'hooks/useTrigger'; 

import './Slider.scss';

// Please use OnOff component. It implements controlled input
export const Slider = ({
    label,
    checked: defaultCheck,
    onSliderChange,
    readOnly = true,
    disabled,
    resetSliderTrigger,
    ...props
}) => {
    const [checked, setChecked] = useState(defaultCheck);
    const onSwipe = (e) => {
        if (disabled) {
            return;
        }

        e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation();
        setChecked(!checked);
        onSliderChange && onSliderChange(props.value, !checked);
    };

    const onReset = useCallback(() => {
        setChecked(defaultCheck);
    }, [setChecked])

    useOnTrigger(resetSliderTrigger, onReset);

    return (
        <div
            className={classNames("hme-onoff-slider-component", disabled ? 'hme-onoff-slider-component--disabled' : '')}
            onMouseDown={onSwipe}
            onDragStart={onSwipe}
        >
            <input
                type="checkbox"
                className="hme-checkbox-input"
                checked={checked}
                readOnly
                {...props}
            />
            {label && (
                <span className="hme-checkbox-slider-label">
                    <span className="hme-checkbox-slider-label-text">{label}</span>
                </span>
            )}
        </div>

    );
};
