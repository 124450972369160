import React, { useState, useEffect } from 'react';

import { ErrorModal } from 'library/ErrorModal';

const errorCodes = {
    INCORRECT_FILE_TYPE: 'You must upload a CSV file.',
    INVALID_FILENAME: `The name of the uploaded file must match the name of the file originally downloaded when creating the task.`,
    DUPLICATED_DEVICE: 'One or more devices on the list are duplicated.',
    EMPTY_ROWS: 'Devices are missed',
    INTERNAL_ERROR: 'Internal Error. Please try again later.',
    FILE_IS_MISSED: 'File is missed.',
    REQUEST_FAILED: 'Your request has been failed. Please try again later or check your file.',
};

const ErrorMessage = ({ length }) => (
    <span>
        The file could not be uploaded due to the following {' '}
        <b>{ length } { length === 1 ? 'issue' : 'issues' }:</b>
    </span>
);

export const CommonErrorModal = ({ errors, actions, onHide, ...modalProps }) => {
    const [errorTexts, setErrorTexts] = useState([]);

    useEffect(() => {
        const messages = errors.map(({ code }) => errorCodes[code] || code);

        setErrorTexts(messages);
    }, [errors, setErrorTexts]);

    return (
        <ErrorModal
            errors={errorTexts}
            onHide={onHide}
            {...modalProps}
            actions={actions}
            dialogClassName='apply-store-settings-status-upload-error'
            message={<ErrorMessage length={errors.length} />}
        />
    );
};
