import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { MIN_TEXT_CONNECT_NEXEO_VERSION, MIN_TEXT_CONNECT_VOICE_ENGINE_VERSION } from '../../../constants';

import './InfoMessage.scss';

const errorMessages = [
    {
        description: 'text-n-connect__info-message__description--status',
    },
    {
        description: 'text-n-connect__info-message__description--tier',
    },
    {
        description: 'text-n-connect__info-message__description--base-version',
        value: MIN_TEXT_CONNECT_NEXEO_VERSION,
    },
    {
        description: 'text-n-connect__info-message__description--voice-command-status',
    },
    {
        description: 'text-n-connect__info-message__description--voice-engine-version',
        value: MIN_TEXT_CONNECT_VOICE_ENGINE_VERSION
    },
];

const NEXEO_TRAINING_EXTERNAL_URL = 'www.hme.com/training/NEXEO_HDX';

export const InfoMessage = () => {
    const { t } = useTranslation();

    return (
        <div className="text-connect__sections__section__info-message">
            <p>{t('text-n-connect__info-message__introduction')}</p>
            <ul>
                {errorMessages.map((errMessage, index) => {
                    const tOptions = errMessage.value ? { version: errMessage.value } : null;

                    return (
                        <li key={index}>
                            {t(errMessage.description, tOptions)}
                        </li>
                    );
                })}
            </ul>
            <div className="text-connect__sections__section__info-message__contact">
                <p>
                    <Trans
                        i18nKey={'text-n-connect__info-message__visit-training'}
                        link={NEXEO_TRAINING_EXTERNAL_URL}
                        shouldUnescape
                    >
                        For information about Text & Connect, Tiers and updates, visit our training portal at:{' '}
                        <a href={`https://${NEXEO_TRAINING_EXTERNAL_URL}`} target="_blank" rel="noopener noreferrer">
                            {{ link: NEXEO_TRAINING_EXTERNAL_URL }}
                        </a>
                        .
                    </Trans>
                </p>
                <p>
                    {t('text-n-connect__info-message__contact')}
                </p>
            </div>
        </div>
    );
};
