import React, { useCallback } from 'react';

import { SingleSelect } from 'library/SingleSelect';
import { DateLib } from '@hme-cloud/utility-common';

import { ReactComponent as ClockIcon } from 'assets/icons/icon-clock.svg';
import { useTranslation } from 'react-i18next';

const Icon = <ClockIcon width={22} height={22} />;

export const timeLabels = [
    '12:00 AM',
    '1:00 AM',
    '2:00 AM',
    '3:00 AM',
    '4:00 AM',
    '5:00 AM',
    '6:00 AM',
    '7:00 AM',
    '8:00 AM',
    '9:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '1:00 PM',
    '2:00 PM',
    '3:00 PM',
    '4:00 PM',
    '5:00 PM',
    '6:00 PM',
    '7:00 PM',
    '8:00 PM',
    '9:00 PM',
    '10:00 PM',
    '11:00 PM'
];

const startDate = new DateLib().startOfDay().addHours(-1);

export const timeValues = Array.from({ length: 24 }, () =>
    startDate.addHours(1).format(DateLib.FORMAT_TYPES.TIME_WITH_DAY_PERIOD)
);

const selectOptions = timeValues.map((value, idx) => ({ value, text: timeLabels[idx] }));

export const TimeRangeSelector = ({ from: fromValue, to: toValue, readOnly = false, isRequired, onChange }) => {
    const { t } = useTranslation();
    const handleFromTime = useCallback((newFromValue) => {
        const newValue = { to: toValue, from: newFromValue };

        const newValueIndexes = {
            from: timeValues.indexOf(newValue.from),
            to: timeValues.indexOf(newValue.to)
        };

        if (newValueIndexes.from >= newValueIndexes.to) {
            if (newValueIndexes.from && newValueIndexes.to) {
                const newValueIndexesTo = newValueIndexes.from + 1;
                newValue.to = timeValues[newValueIndexesTo === timeValues.length ? 0 : newValueIndexesTo];
            }
        }

        onChange(newValue);
    }, [toValue, onChange]);

    const handleToTime = useCallback((newToValue) => {
        const newValue = { to: newToValue, from: fromValue };

        const newValueIndexes = {
            from: timeValues.indexOf(newValue.from),
            to: timeValues.indexOf(newValue.to)
        };

        if (newValueIndexes.from >= newValueIndexes.to) {
            const newValueIndexesFrom = newValueIndexes.to - 1;
            newValue.from = timeValues[newValueIndexesFrom >= 0 ? newValueIndexesFrom : timeValues.length - 1];
        }

        onChange(newValue);
    }, [fromValue, onChange]);

    return (
        <>
            <SingleSelect
                value={fromValue}
                items={selectOptions}
                label={t('common__from')}
                isReadOnly={readOnly}
                isRequired={isRequired}
                onChange={handleFromTime}
                Icon={Icon}
                placeholder="common__time-range-placeholder--am"
            />
            <SingleSelect
                value={toValue}
                items={selectOptions}
                label={t('common__to')}
                isReadOnly={readOnly}
                isRequired={isRequired}
                onChange={handleToTime}
                Icon={Icon}
                placeholder="common__time-range-placeholder--pm"
            />
        </>
    );
};
