import { useState, useCallback } from 'react';

import { useRemoveNotiication } from '../../hooks/useRemoveNotification';

export const onRemoveDefault = {
    isRemoveModalShown: false,
    isRemoving: false,
    onRemoveClick: () => {},
    onRemoveConfirm: () => {},
    onRemoveCancel: () => {},
};

export const useOnRemove = ({ onAfterRemove }) => {
    const [removeId, setRemoveId] = useState(null);
    const [isRemoveModalShown, setIsRemoveModalShown] = useState(false);

    const {
        removeNotification,
        isRemoving
    } = useRemoveNotiication();

    const onRemoveClick = useCallback((id) => {
        setRemoveId(id);
        setIsRemoveModalShown(true);
    }, []);

    const onRemoveCancel = useCallback(() => {
        setRemoveId(null);
        setIsRemoveModalShown(false);
    }, []);

    const onRemoveConfirm = useCallback(async () => {
        setIsRemoveModalShown(false);
        setRemoveId(null);

        if (removeId === null) {
            return;
        }

        await removeNotification(removeId);

        onAfterRemove && onAfterRemove();
    }, [removeId, removeNotification, onAfterRemove]);

    return {
        isRemoveModalShown,
        isRemoving,
        onRemoveClick,
        onRemoveConfirm,
        onRemoveCancel,
    };
};
