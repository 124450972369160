import React from 'react';
import classNames from 'classnames';

import { TITLE_VARIANTS, Title } from 'library/Title';

const defaultTitleVariants = [TITLE_VARIANTS.BLOCK];

export const FieldsetTitle = ({ children, variants = defaultTitleVariants, className, ...props }) => {
    return (
        <Title
            variants={variants}
            className={classNames('create-edit-scheduler__fieldset-title', className)}
            as="h2"
            {...props}
        >
            {children}
        </Title>
    );
};
