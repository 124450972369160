import { DateLib } from '@hme-cloud/utility-common';

// TODO: use src/constants/email.js `validEmailRegexp`
export const EMAIL = new RegExp(
        /^([a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-])+@(([0-2]?[0-5]?[0-5]\.[0-2]?[0-5]?[0-5]\.[0-2]?[0-5]?[0-5]\.[0-2]?[0-5]?[0-5])|((([a-zA-Z0-9-])+\.)+([a-zA-Z-])+))$/u
);

export const formDataValidator = (formConditions, t) => {
    const formErrorConditions = [
        {
            key: 'tokenName',
            condition: formConditions.tokenName,
            message: t('api-token-management__error--token-name')
        }, {
            key: 'tokenExpiration',
            condition: formConditions.tokenExpiration,
            message: t('api-token-management__error--tokenExpiration')
        }, {
            key: 'apiType',
            condition: formConditions.apiType,
            message: t('api-token-management__error--apiType')
        }, {
            key: 'tokenUser',
            condition: formConditions.tokenUser,
            message: t('api-token-management__error--tokenUser')
        }, {
            key: 'tokenManager',
            condition: formConditions.tokenManager,
            message: t('api-token-management__error--tokenManager')
        }, {
            key: 'vaioProvider',
            condition: formConditions.vaioProvider,
            message: t('api-token-management__error--vaioProvider')
        }, {
            key: 'email2',
            condition: formConditions.email2,
            message: t('api-token-management__error--email2')
        }, {
            key: 'email3',
            condition: formConditions.email3,
            message: t('api-token-management__error--email3')
        }
    ];

    const formError = formErrorConditions.reduce(
            (errors, el) => (el.condition && (errors[el.key] = el.message), errors),
            {}
    );

    return formError;
};

export const isExpired = (expDate) => {
    const today = new DateLib();
    const expirationDate = new DateLib(expDate);

    return expirationDate.diff(today, 'days') < 0;
};
