import React from 'react';
import { Root } from '@radix-ui/react-radio-group';
import classNames from 'classnames';

import { Item } from './Item';

import './PureRadioGroup.scss';

/**
 * @documentation can be found on https://www.radix-ui.com/docs/primitives/components/radio-group
 * @return {React.ReactNode}
 */
export const PureRadioGroup = ({ id, children, defaultValue, name, className, Radio = Item, ...props }) => {
    return (
        <Root
            id={id}
            name={name}
            defaultValue={defaultValue}
            className={classNames('hme-radio-group', className)}
            {...props}
        >
            {(children || []).map(({ id, value, label, ...radioProps }) => (
                <Radio id={id} value={value} label={label} {...radioProps} key={id} />
            ))}
        </Root>
    );
};
