import React from 'react';
import { compose } from 'ramda';
import { withHMELayout } from 'HOCs/withHMELayout';

import authenticate from 'components/Security/Authentication';
import { withAccess } from 'HOCs/withAccess';
import { TextConnect } from '../TextConnect';
import { accountTypes } from 'constants/accountTypes';
import { hasTextConnectAccess } from 'helpers/access';

import './TextConnectPage.scss';

export const PublicTextConnect = () => <TextConnect permissionType={accountTypes.PUBLIC} />;

export const TextConnectPage = compose(
    withHMELayout({
        contentClasses: ['public-text-connect-page'],
    }),
    authenticate,
    withAccess({
        checkAccess: hasTextConnectAccess,
    }),
)(PublicTextConnect);
