import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { flatten } from 'ramda';

import { Expand } from 'library/Icons/Expand';
import { Checkbox, CHECKBOX_VALUES } from 'library/Checkbox';
import { TreeItemProp } from '../types';

import './TreeItem.scss';

// Recursive. If we will have large of deepness we need to rewrite in other way
export const getNodeValues = (item, isGroupSelectable) => {
    const values = [item.value];
    if (isGroupSelectable && item.item.Type === 'group') {
        values.push(item.key);
    }

    if (!isGroupSelectable && item.children && item.children.length) {
        item.children.forEach((node) => {
            const nodeValues = getNodeValues(node);
            values.push(nodeValues);
        });
    }

    return flatten(values).filter((value) => typeof value !== 'undefined');
};

export const TreeItem = ({
    item,
    selectable,
    selection,
    isGroupSelectable,
    isAllExpanded,
    isExpandedDefault = false,
    disabledItems = {},
    onSelectionChange
}) => {
    const [isExpanded, setIsExpanded] = useState(isExpandedDefault);
    const isExpandable = useMemo(() => item.children && !!item.children.length, [item]);

    const innerValues = useMemo(() => {
        return selectable ? getNodeValues(item, isGroupSelectable) : [];
    }, [selectable, item, isGroupSelectable]);

    const allowedInnerValues = useMemo(
        () => innerValues.filter((value) => !disabledItems[value]),
        [innerValues, disabledItems],
    );

    const disabled = useMemo(
        () => !allowedInnerValues.some((innerValue) => !disabledItems[innerValue]) || item.disabled,
        [allowedInnerValues, item],
    );

    const checked = useMemo(() => {
        if (!selectable) {
            return CHECKBOX_VALUES.UNCHECKED;
        }

        const innerChecked = allowedInnerValues.filter((innerValue) => {
            return selection?.includes(innerValue);
        });

        if ((isGroupSelectable && selection.includes(item.key)) ) {
            return CHECKBOX_VALUES.CHECKED;
        }

        if (innerChecked.length === 0) {
            return CHECKBOX_VALUES.UNCHECKED;
        }

        if (innerChecked.length === innerValues.length) {
            return CHECKBOX_VALUES.CHECKED;
        }

        if (isGroupSelectable) {
            return CHECKBOX_VALUES.UNCHECKED;
        }

        return CHECKBOX_VALUES.PARTIAL;
    }, [selectable, selection, innerValues]);

    const onExpandChange = useCallback(() => {
        setIsExpanded(!isExpanded);
    }, [isExpanded, setIsExpanded]);

    const onCheckboxChange = useCallback((checkValue) => {
        if (!selectable) {
            return;
        }

        // always remove innerValues to avoid of duplicates
        const newValue = selection.filter((selectedValue) => !innerValues.includes(selectedValue));

        if (checkValue) {
            if (isGroupSelectable && item.children && !disabledItems[item.key]) {
                newValue.push(item.key);
            } else {
                newValue.push(...allowedInnerValues);
            }
        }

        onSelectionChange && onSelectionChange(newValue);
    }, [selection, innerValues, allowedInnerValues, onSelectionChange, disabledItems, isGroupSelectable]);

    useEffect(() => {
        if (!item.onExpand) {
            return;
        }

        const handler = () => setIsExpanded(true);

        item.onExpand.on(handler);

        return () => {
            item.onExpand.off(handler);
        }
    }, [item.onExpand]);

    if ( !isGroupSelectable && selectable && innerValues.length === 0) {
        return null;
    }

    return (
        <div className={classNames('hme-tree-item', {
            'hme-tree-item--final-node': !isExpandable,
            'hme-tree-item--expanded': isExpanded,
            'hme-tree-item--disabled': disabled,
            'hme-tree-item--checked': checked,
        })}>
            <div className="hme-tree-item__header">
                { isExpandable &&
                    <Expand expanded={isExpanded || isAllExpanded} onExpandChange={onExpandChange} />
                }
                { selectable &&
                    <Checkbox checked={checked} onChange={onCheckboxChange} disabled={item.disabled} />
                }
                { item.text }
            </div>
            { isExpandable && (isExpanded || isAllExpanded) &&
                <div className="hme-tree-item__expand-content">
                    {
                        item.children.map((child, index) => {
                            return <TreeItem
                                key={typeof child.key === 'undefined' ? index : child.key }
                                item={child}
                                selectable={selectable}
                                selection={selection}
                                onSelectionChange={onSelectionChange}
                                isGroupSelectable={isGroupSelectable}
                                isAllExpanded={isAllExpanded}
                                isExpandedDefault={isExpandedDefault}
                                disabledItems={disabledItems}
                            />;
                        })
                    }
                </div>
            }
        </div>
    );
};

TreeItem.propTypes = {
    item: TreeItemProp,
    selectable: PropTypes.bool,
    selection: PropTypes.arrayOf(PropTypes.any),
    onSelectionChange: PropTypes.func,
    isGroupSelectable: PropTypes.bool
};
