import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import debounce from 'lodash.debounce';

import { AutoCompleteInput } from 'library/AutoCompleteInput';
import { SearchInput } from 'components/Inputs/SearchInput';
import { loadAccounts } from './AccountInputController';

import './AccountInput.scss';

const suggestionsHeaders = [{
    text: 'Email',
    property: 'User_EmailAddress',
}];

const minLength = 3;
const debounceTimeout = 300;

const getAccounts = async (
    mask,
    sourceDevice,
    selectedDeviceType,
    limit,
    setAccounts,
    setIsLoading
) => {
    const newAccounts = await loadAccounts({ mask, sourceDevice, selectedDeviceType, limit });
    setAccounts(newAccounts);
    setIsLoading(false);
};

const getAccountsDebounced = debounce(getAccounts, debounceTimeout);

export const AccountInput = ({
    account,
    sourceDevice,
    selectedDeviceType,
    limit = 3,
    onAccountChange
}) => {
    const [value, setValue] = useState(account ? account.User_EmailAddress : '');
    const [suggestions, setSuggestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const onSuggested = useCallback(newAccount => {
        setValue(newAccount.User_EmailAddress);
        onAccountChange(newAccount);
    }, [setValue, onAccountChange]);

    useEffect(() => {
        if (!account || account.User_EmailAddress !== value) {
            onAccountChange(null);
        }
    }, [value, onAccountChange]);

    useEffect(() => {
        if (value.length < minLength) {
            return;
        }

        setIsLoading(true);
        getAccountsDebounced(
            value,
            sourceDevice,
            selectedDeviceType,
            limit,
            setSuggestions,
            setIsLoading
        );
    }, [value, sourceDevice, selectedDeviceType, limit, setSuggestions]);

    return (
        <AutoCompleteInput
            value={account ? account.User_EmailAddress : value}
            suggestions={suggestions}
            headers={suggestionsHeaders}
            rowKey='User_EmailAddress'
            className='hme-account-input'
            placeholder={t('common__type-an-account-email')}
            isLoading={isLoading}
            noSuggestionText={t('common__no-accounts-were-found')}
            Component={SearchInput}
            onChange={setValue}
            onSuggested={onSuggested}
        />
    );
};
