/* eslint-disable */
import { CommonConstants } from 'Constants';
import AuthenticationService from '../components/Security/AuthenticationService';
import { Config } from '../Config';
import { Constant } from '@hme-cloud/utility-common';

const { BuildVersions } = Constant;

export const createSystemPageURI = ({ deviceUID, deviceTypeID, deviceMainVersion }) => {
    // @todo could be better to move isAdmin as input param
    const authService = new AuthenticationService(Config.authBaseUrl);
    if (!authService.isAdmin()) {
        return `/settings/stores/viewDetail?duid=${deviceUID}`;
    }
    const params = new URLSearchParams(window.location.search);
    const uuid = params.get('uuid') || null;

    const deviceVersionSemver = BuildVersions.parsedVersion(deviceMainVersion);
    // CU40 ZOOM devices should have only old page version
    if (deviceTypeID === CommonConstants.deviceType.zoom.id
        && deviceVersionSemver
        && deviceVersionSemver.major < CommonConstants.deviceLimitation.minUpgradableVersion) {
        return `/settings/stores/viewDetail?duid=${deviceUID}`.concat(uuid ? `&uuid=${uuid}` : '');
    }

    switch (deviceTypeID) {
        case CommonConstants.deviceType.zoom.id:
            return `/admin/system/zoom/${deviceUID}`.concat(uuid ? `?uuid=${uuid}` : '');
        case CommonConstants.deviceType.nexeo.id:
            return `/admin/system/nexeo/${deviceUID}`.concat(uuid ? `?uuid=${uuid}` : '');
        case CommonConstants.deviceType.evd.id:
            return `/admin/system/evd/${deviceUID}`.concat(uuid ? `?uuid=${uuid}` : '');
        case CommonConstants.deviceType.linkWear.id:
            return `/admin/system/linkwear/${deviceUID}`.concat(uuid ? `?uuid=${uuid}` : '');
        default:
            return `/settings/stores/viewDetail?duid=${deviceUID}`.concat(uuid ? `&uuid=${uuid}` : '');
    }
};
