import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from 'library/Button';
import { Title } from 'library/Title';
import { Grid } from 'components/Common/Grid';

import './AdminDevicesInformation.scss';


export const AdminDevicesInformation = ({
    devicesRows,
    onAddDeviceClick,
    isAddDeviceButtonDisabled,
    devicesHeaders
}) => {
    const { t } = useTranslation();

    return <>
        <div className="hme-admin-store-details__device-info__header">
            <Title variants={['block']}>{t('common__devices')}</Title>
            <div className="hme-admin-store-details__device-info__buttons">
                <Button
                    onClick={onAddDeviceClick}
                    disabled={isAddDeviceButtonDisabled}
                >
                    {t('admin__add-device')}
                </Button>
            </div>
        </div>
        <Grid
            headers={devicesHeaders}
            rows={devicesRows}
            className="hme-view-edit-store__devices-info__grid"
            rowKey="Device_UID"
            noRecordsMessage={t('stores__view--edit__devices__no-records')}
        />
    </>;
};

AdminDevicesInformation.propTypes = {
    devicesHeaders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    devicesRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onAddDeviceClick: PropTypes.func.isRequired,
    isAddDeviceButtonDisabled: PropTypes.bool.isRequired
};
