import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { DFACode } from 'pages/DFACode';
import { Login } from 'pages/Login';
import { PublicCreateUser } from 'pages/Users/ViewEdit/PublicCreateUser';
import { PublicEditUser } from 'pages/Users/ViewEdit/PublicEditUser';
import { AdminEditUser } from 'pages/Users/ViewEdit/AdminEditUser';
import { SystemsPage as AdminSystemsPage } from 'pages/Systems/Admin/SystemsPage';
import { ForgotPassword } from 'pages/ForgotPassword';
import { ResetPassword } from 'pages/ResetPassword';
import { CreateTaskPage as AdminCreateTaskPage } from 'pages/ApplyDeviceSettings/Admin/CreateTaskPage';
import { CreateTaskPage as PublicCreateTaskPage } from 'pages/ApplyDeviceSettings/Public/CreateTaskPage';
import { ApplyDeviceSettingsPage as AdminApplyDeviceSettingsPage } from 'pages/ApplyDeviceSettings/Admin/ApplyDeviceSettingsPage';
import { ApplyDeviceSettingsPage as PublicApplyDeviceSettingsPage } from 'pages/ApplyDeviceSettings/Public/ApplyDeviceSettingsPage';
import { Accounts } from 'pages/Accounts';
import { Account } from 'pages/Account';
import { DeviceSettingsPage as AdminDeviceSettingsPage } from 'pages/ManageDeviceSettings/Admin';
import { DeviceSettingsPage as PublicDeviceSettingsPage } from 'pages/ManageDeviceSettings/Public';
import { AdminNexeoSystemStatus } from 'pages/SystemStatus/AdminNexeo';
import { AdminZOOMSystemStatus } from 'pages/SystemStatus/AdminZOOM';
import { AdminEVDSystemStatus } from 'pages/SystemStatus/AdminEVD';
import { AdminLinkwearSystemStatus } from 'pages/SystemStatus/AdminLinkwear';
import { NitroAnnouncementSettings } from 'pages/NitroAnnouncementSettings';
import { MergeDevicesPage as AdminMergeDevicesPage } from 'pages/MergeDevices/Admin/MergeDevicesPage';
import { MergeDevicesPage as PublicMergeDevicesPage } from 'pages/MergeDevices/Public/MergeDevicesPage';
import { AdminListPage as AdminFirmwareManagementListPage } from 'pages/FirmwareManagement/Admin/ListPage';
import { AdminViewEditPage as AdminFirmwareManagementViewEditPage } from 'pages/FirmwareManagement/Admin/ViewEditPage';
import { DXSFeedsPage as AdminDXSFeedsPage } from 'pages/DXSFeeds/Admin/DXSFeedsPage';
import { DXSAccountPage as AdminDXSAccountPage } from 'pages/DXSFeeds/Admin/DXSAccountPage';
import { DXSFeedPage as AdminDXSFeedPage } from 'pages/DXSFeeds/Admin/DXSFeedPage';
import { DistributorsAccounts } from 'pages/DistributorsAccounts';
import { DistributorsAccount } from 'pages/DistributorsAccount';
import { StatusPage as AdminStoresList } from 'pages/Stores/Admin/StatusPage';
import { StatusPage as PublicStoresList } from 'pages/Stores/Public/StatusPage';
import { CreateDeviceUpgradePage as AdminCreateDeviceUpgradePage } from 'pages/DeviceUpgrades/Admin/CreateDeviceUpgradePage';
import { DeviceUpgradesListPage as AdminDeviceUpgradesListPage } from 'pages/DeviceUpgrades/Admin/DeviceUpgradesListPage';
import { ViewDeviceUpgradePage as AdminViewDeviceUpgradePage } from 'pages/DeviceUpgrades/Admin/ViewDeviceUpgradePage';
import { ViewEdit as PublicViewEdit } from 'pages/Stores/ViewEdit/Public';
import { ViewEdit as AdminViewEdit } from 'pages/Stores/ViewEdit/Admin';
import { PublicRoles } from 'pages/Roles/Status/Public';
import { PublicCreateRole } from 'pages/Roles/ViewEdit/Public/Create';
import { PublicEditRole } from 'pages/Roles/ViewEdit/Public/ViewEdit';
import { CreateServiceProviderSettingsPage, EditServiceProviderSettingsPage, AdminVaioProviders } from 'pages/StoreFeatures/Admin';
import { UsersList as PublicUsersList } from 'pages/Users/List/Public';
import { UsersList as AdminUsersList } from 'pages/Users/List/Admin';
import { Profile } from 'pages/Profile';
import { Create as CreateCustomerNotification } from 'pages/CustomerNotification/Create';
import { Edit as EditCustomerNotification } from 'pages/CustomerNotification/Edit';
import { AdminStatus as CustomerNotifications } from 'pages/CustomerNotification/AdminStatus';
import { ManageHierarchy } from 'pages/ManageHierarchy/Public';
import { oldRoutes } from 'pages/ManageHierarchy/constants';
import { NotificationsCenter } from 'pages/NotificationsCenter/Public';
import { TempSystemStatusPage } from 'pages/SystemStatus/Public/TempSystemStatusPage';
import { SettingsDisplay } from 'pages/Settings';
import { StatusPage } from 'pages/TokenManagement';
import { AdminAPIManagementForm } from 'pages/APIManagement/Admin';
import { CreatePartner } from 'pages/Partners/ViewEdit/Admin/CreatePartner';
import { ViewEditPartner } from 'pages/Partners/ViewEdit/Admin/ViewEditPartner';
import { Status as AdminPartners } from 'pages/Partners/Status/Admin';
import { TextConnectPage as PublicTextConnectPage } from 'pages/TextConnect/Public';
import {
    StatusListPage as LeaderboardContestsListPage,
    CreatePage as LeaderboardContestsCreatePage,
    ResultsPage as LeaderboardContestsEditPage
} from 'pages/LeaderboardContests/Public';
import {
    AdminCreateTask as AdminApplyStoreSettings,
    AdminStatusPage as AdminApplyStoreSettingsStatusPage,
    AdminViewTask as AdminApplyStoreSettingsView
} from 'pages/ApplyStoreSettings';
import {
    SSOConfigurationPage
} from 'pages/SSOConfiguration'; 

import authenticate from 'components/Security/Authentication';
import Logout from 'components/Security/Logout';
import LBGroup from 'components/LeaderBoard/LBGroup';
import LongestTime from 'components/Report/LongestTime';
import User from 'components/User/User';
import EmailAlert from 'components/Alerts/Email';
import Message from 'components/Alerts/Message';
import SystemStatus from 'components/Stores/SystemStatus';
import Device from 'components/Device/Device';
import remoteSystemActions from 'components/Stores/RemoteSystemActions';
import Reconnect from 'components/Actions/Reconnect';
import Reboot from 'components/Actions/Reboot';
import Upgrade from 'components/Actions/Upgrade';
import Connect from 'components/Actions/Connect';
import CustomizeDashboard from 'components/Dashboard/CustomizeDashboard';
import LeaderBoardGroups from 'components/LeaderBoard/LeaderBoardGroups';
import LBTemplatesList from 'components/LeaderBoard/LBTemplatesList';
import LeaderBoardLanding from 'components/LandingPage/LeaderBoardLanding';
import { ProcessTemplates } from 'components/LeaderBoard/ProcessTemplates';
import PbiReportScreen from 'components/PbiReportScreen';
import PbiScheduler from 'components/PbiScheduler';
import CreateEditScheduler from 'components/PbiScheduler/createEditSchedule';
import PbiAdminReport from 'components/PbiAdminReport/PbiAdminReport';
import { ReportLanding } from 'components/LandingPage/ReportLandingPage/ReportLanding';
import { ReportContextWrapper } from 'components/LandingPage/ReportContextWrapper';
import SystemNotFound from 'components/ErrorBoundary/SystemNotFound';
import ModalContainer from 'containers/ModalContainer';
import StoreContainer from 'containers/StoreContainer';
import DashboardContainer from 'containers/DashboardContainer';
import StoreRanking from 'containers/StoreRanking';
import AvatarBox from 'containers/AvatarBox/AvatarBox';
import { AwardsResettable } from 'containers/Awards/AwardsResettable/AwardsResettable';
import LockDeviceSettingsPage from 'containers/LockDeviceSettingsPage';
import { ManageBlockedEmails } from 'containers/ManageBlockedEmails';
import { CustomSmackTalk } from 'containers/CustomSmackTalk';

import withNoLeaderboardFallback from 'HOCs/withNoLeaderboardCheckFallback';
import withAccountEmail from 'HOCs/withAccountEmail';
import { withOptions } from 'HOCs/withOptions';
import { withLayout } from 'HOCs/withLayout';
import withNoReportsFallback from 'HOCs/withNoReportsCheckFallback';
import { PermissionFor } from 'actions/permissions';
import { getLayout } from 'helpers/getLayout';
import { getAuthenticate } from 'helpers/getAuthenticate';
import { getAccountEmail } from 'helpers/getAccountEmail';
import { SSOManagementPage } from 'pages/SSOManagement/SSOManagementList';
import { CreateEditSSOManagement } from 'pages/SSOManagement/CreateEditSSOManagement';

import { NavigateWithQuery } from 'library/NavigateWithQuery';
import { COMMON_ROUTES, PUBLIC_ROUTES } from 'constants/routes';
import { CommonConstants } from 'Constants';

import { commonRoutes } from './Common';

export const defaultAppRoutes = () => {
    return (
        <Routes>
            {commonRoutes()}

            <Route path="accounts" element={<DistributorsAccounts />} />
            <Route
                path="accounts/:tabName/*"
                element={getAuthenticate(DistributorsAccount, CommonConstants.userPermissions.ViewAllStores)}
            />

            <Route path="manageAccount/resetpassword/:ruid" element={<ResetPassword />} />
            <Route path="manageAccount/forgotpassword" element={<ForgotPassword />} />
            <Route path="validateDfaAuth" element={<DFACode />} />
            <Route path="user/uuid" element={getLayout(authenticate(User, ''))} />
            {
                oldRoutes.map((route) => {
                    return (
                        <Route
                            key={route}
                            path={route}
                            element={<Navigate to={`/settings/${PUBLIC_ROUTES.manageReportGroups}`} replace={true} />}
                        />
                    );
                })
            }
            <Route path="reports" element={<Navigate to={COMMON_ROUTES.overview} />} />
            <Route path="summaryreport" element={<Navigate to={COMMON_ROUTES.overview} />} />
            <Route path="summaryreport/:id" element={<Navigate to={COMMON_ROUTES.overview} />} />
            <Route path="emailSent" element={getLayout(authenticate(EmailAlert, ''))} />
            <Route path="message" element={getLayout(authenticate(Message, ''))} />
            <Route path="longestTime" element={getLayout(authenticate(LongestTime, ''))} />
            <Route path="rawcardatareport" element={<Navigate to={COMMON_ROUTES.overview} />} />
            <Route path="systemStatus" element={getLayout(authenticate(SystemStatus, ''))} />
            <Route
                path="admin/systems"
                element={
                    getAuthenticate(AdminSystemsPage, '')
                }
            />
            <Route path="stores/device" element={getLayout(authenticate(Device, ''))} />
            <Route path="stores/remoteSystemActions" element={getLayout(authenticate(remoteSystemActions, ''))} />
            <Route path="storePopup" element={getLayout(authenticate(ModalContainer, ''))} />
            <Route path="reconnect" element={getLayout(authenticate(Reconnect, ''))} />
            <Route path="connect" element={getLayout(authenticate(Connect, ''))} />
            <Route path="reboot" element={getLayout(authenticate(Reboot, ''))} />
            <Route path="upgrade" element={getLayout(authenticate(Upgrade, ''))} />

            {/* leaderBoards urls */}
            <Route path="leaderBoards">
                <Route
                    path="LBGroups"
                    element={
                        getAuthenticate(
                                withNoLeaderboardFallback(
                                        withLayout(LeaderBoardGroups),
                                        '',
                                        'common__leaderboards'
                                ), ''
                        )
                    }
                />
                <Route path="LBGroups/lbGroup" element={getLayout(authenticate(LBGroup, ''))} />
                <Route
                    path="smackTalk/send"
                    element={
                        getAuthenticate(
                                withNoLeaderboardFallback(
                                        withOptions(withLayout(CustomSmackTalk), { defaultTab: 'send' }),
                                        PermissionFor.SmackTalk,
                                        'common__smack-talk'
                                ),
                                ''
                        )
                    }
                />
                <Route
                    path="smackTalk/manage"
                    element={
                        getAuthenticate(
                                withNoLeaderboardFallback(
                                        withOptions(withLayout(CustomSmackTalk), { defaultTab: 'manage' }),
                                        PermissionFor.SmackTalk,
                                        'common__smack-talk'
                                ),
                                ''
                        )
                    }
                />
                <Route
                    path="smackTalk/manage/editor"
                    element={
                        getAuthenticate(
                                withNoLeaderboardFallback(
                                        withOptions(withLayout(CustomSmackTalk), { defaultTab: 'manage/editor' }),
                                        PermissionFor.SmackTalk,
                                        'common__smack-talk'
                                ),
                                ''
                        )
                    }
                />
                <Route
                    path="smackTalk/history"
                    element={
                        getAuthenticate(
                                withNoLeaderboardFallback(
                                        withOptions(withLayout(CustomSmackTalk), { defaultTab: 'history' }),
                                        PermissionFor.SmackTalk,
                                        'common__smack-talk'
                                ),
                                ''
                        )
                    }
                />
                <Route
                    path="announcementSettings"
                    element={
                        getAuthenticate(
                                withNoLeaderboardFallback(
                                        NitroAnnouncementSettings,
                                        PermissionFor.AnnouncementSettings,
                                        'common__announcement-settings'
                                ),
                                ''
                        )
                    }
                />
                <Route
                    path="contests"
                    element={
                        getAuthenticate(
                                LeaderboardContestsListPage,
                                PermissionFor.Contest
                        )
                    }
                />
                <Route
                    path="avatarBox"
                    element={
                        getAuthenticate(
                                withNoLeaderboardFallback(withLayout(AvatarBox), PermissionFor.Avatar, 'sub-header--lb__avatars'), ''
                        )
                    }
                />
                <Route
                    path="awards"
                    element={
                        getAuthenticate(
                                withNoLeaderboardFallback(withLayout(AwardsResettable), PermissionFor.Award, 'common__awards'),
                                ''
                        )
                    }
                />
                <Route
                    path="LBTemplatesList"
                    element={
                        getAuthenticate(
                                withNoLeaderboardFallback(
                                        withLayout(LBTemplatesList),
                                        PermissionFor.LBTemplates, 'sub-header--lb__templates'
                                ),
                                ''
                        )
                    }
                />
                <Route
                    path="LBTemplatesList/:templateUID"
                    element={
                        getAuthenticate(
                                withNoLeaderboardFallback(ProcessTemplates, '', 'sub-header--lb__templates'), ''
                        )
                    }
                />
                <Route path="contests/new" element={getAuthenticate(LeaderboardContestsCreatePage, '')} />
                <Route path="contests/:uid" element={getAuthenticate(LeaderboardContestsEditPage, '')} />
            </Route>

            <Route path="notifications">
                <Route path="center" element={getAuthenticate(NotificationsCenter, '')} />
            </Route>

            {/* admin urls */}
            <Route path="admin">
                <Route path="" element={getLayout(Login)} />
                <Route path="accounts" element={getAuthenticate(Accounts, CommonConstants.adminPermissions.ViewAllAccounts)} />
                <Route path="account" element={getAuthenticate(Account, CommonConstants.adminPermissions.ViewAllAccounts)} />
                <Route path="settings/stores" element={getAuthenticate(AdminStoresList, '')} />
                <Route path="settings/stores/edit" element={getAuthenticate(AdminViewEdit, '')} />
                <Route path="settings/users/user" element={getAuthenticate(AdminEditUser, '')} />
                <Route path="settings/users" element={getAuthenticate(AdminUsersList, '')} />
                <Route
                    path="reports/outliers"
                    element={
                        getLayout(withAccountEmail(authenticate(
                                PbiAdminReport,
                                CommonConstants.adminPermissions.HMEReports
                        )))
                    }
                />
                <Route
                    path="reports/ghost-cars-report"
                    element={
                        getLayout(withAccountEmail(authenticate(
                                PbiAdminReport,
                                CommonConstants.adminPermissions.HMEReports
                        )))
                    }
                />
                <Route
                    path="reports/speed-of-service"
                    element={
                        getLayout(withAccountEmail(authenticate(
                                PbiAdminReport,
                                CommonConstants.adminPermissions.ViewSpeedOfServiceReport
                        )))
                    }
                />
                <Route
                    path="reports/speed-of-service-detection"
                    element={
                        getLayout(withAccountEmail(authenticate(
                                PbiAdminReport,
                                CommonConstants.adminPermissions.ViewSpeedOfServiceReport
                        )))
                    }
                />
                <Route
                    path="reports/store-status"
                    element={
                        getLayout(withAccountEmail(authenticate(
                                PbiAdminReport,
                                CommonConstants.adminPermissions.HMEReports
                        )))
                    }
                />
                <Route path="dashboard" element={getLayout(authenticate(DashboardContainer, ''))} />
                <Route
                    path="dashboard/customizedashboard"
                    element={
                        getLayout(
                                authenticate(CustomizeDashboard, '')
                        )
                    }
                />
                <Route path="dashboard/storeRanking" element={getLayout(authenticate(StoreRanking, ''))} />
                <Route path="manageBlockedEmails" element={getAuthenticate(ManageBlockedEmails, '')} />

                <Route path="vaioProviders"
                    element={
                        getAuthenticate(
                                AdminVaioProviders, CommonConstants.adminPermissions.EditAccounts
                        )
                    }
                />

                <Route path="createVaioProvider"
                    element={
                        getAuthenticate(
                                CreateServiceProviderSettingsPage, CommonConstants.adminPermissions.CreateVaioProvider
                        )
                    }
                />

                <Route path="editVaioProvider"
                    element={
                        getAuthenticate(
                                EditServiceProviderSettingsPage, CommonConstants.adminPermissions.CreateVaioProvider
                        )
                    }
                />

                <Route
                    path="dxsFeeds"
                    element={
                        getAuthenticate(
                                AdminDXSFeedsPage,
                                CommonConstants.adminPermissions.ManageDXS
                        )
                    }
                />
                <Route path="customerNotifications" element={<CustomerNotifications />} />
                <Route path="customerNotification/new" element={<CreateCustomerNotification />} />
                <Route path="customerNotification/:notificationId" element={<EditCustomerNotification />} />
                <Route
                    path="dxsFeeds/accounts/:accountId"
                    element={getAuthenticate(AdminDXSAccountPage, CommonConstants.adminPermissions.ManageDXS)}
                />
                <Route
                    path="dxsFeeds/feeds/:feedId"
                    element={getAuthenticate(AdminDXSFeedPage, CommonConstants.adminPermissions.ManageDXS)}
                />
                <Route
                    path="manageDeviceSettings"
                    element={getAuthenticate(AdminDeviceSettingsPage, CommonConstants.adminPermissions.CreateSettingSnapshot)}
                />
                <Route
                    path="applyDeviceSettingsStatus"
                    element={getAuthenticate(AdminApplyDeviceSettingsPage, CommonConstants.adminPermissions.ApplyDeviceSettings)}
                />
                <Route
                    path="applyStoreSettingsStatus"
                    element={getAuthenticate(AdminApplyStoreSettingsStatusPage, CommonConstants.adminPermissions.ApplyDeviceSettings)}
                />
                <Route
                    path="applyStoreSettings"
                    element={getAuthenticate(AdminApplyStoreSettings, CommonConstants.adminPermissions.ApplyDeviceSettings)}
                />
                <Route
                    path="applyDeviceSettings"
                    element={getAuthenticate(AdminCreateTaskPage, CommonConstants.adminPermissions.ApplyDeviceSettings)}
                />
                <Route
                    path="mergeDevices"
                    element={getAuthenticate(AdminMergeDevicesPage, CommonConstants.adminPermissions.ApplyDeviceSettings)}
                />
                <Route
                    path="firmwareManagement"
                    element={getAuthenticate(AdminFirmwareManagementListPage, CommonConstants.adminPermissions.ApplyDeviceSettings)}
                />
                <Route
                    path="firmwareManagement/:brandID"
                    element={getAuthenticate(AdminFirmwareManagementViewEditPage, CommonConstants.adminPermissions.ApplyDeviceSettings)}
                />
                <Route
                    path="storeSettingsTask/view/:taskUid"
                    element={getAuthenticate(AdminApplyStoreSettingsView, CommonConstants.adminPermissions.ApplyDeviceSettings)}
                />
                <Route path="system/not-found" element={getLayout(authenticate(SystemNotFound, ''))} />
                <Route
                    path="system/nexeo/:deviceUID"
                    element={getAuthenticate(AdminNexeoSystemStatus, '')}
                />
                <Route
                    path="system/zoom/:deviceUID"
                    element={getAuthenticate(AdminZOOMSystemStatus, '')}
                />
                <Route
                    path="system/evd/:deviceUID"
                    element={getAuthenticate(AdminEVDSystemStatus, '')}
                />
                <Route
                    path="system/linkwear/:deviceUID"
                    element={getAuthenticate(AdminLinkwearSystemStatus, '')}
                />
                <Route
                    path="settings/deviceUpgrades"
                    element={getAuthenticate(AdminDeviceUpgradesListPage, CommonConstants.adminPermissions.PerformDeviceUpdate)}
                />
                <Route
                    path="settings/deviceUpgrades/create"
                    element={getAuthenticate(AdminCreateDeviceUpgradePage, CommonConstants.adminPermissions.PerformDeviceUpdate)}
                />
                <Route
                    path="settings/deviceUpgrades/:scheduledupgradeid"
                    element={getAuthenticate(AdminViewDeviceUpgradePage, CommonConstants.adminPermissions.PerformDeviceUpdate)}
                />
                <Route path="logout" element={<Logout />} />
                <Route
                    path="tokenManagement"
                    element={getAuthenticate(StatusPage, CommonConstants.adminPermissions.ManageToken)}
                />
                <Route path="createAPIToken"
                    element={getAuthenticate(AdminAPIManagementForm, CommonConstants.adminPermissions.ManageToken)}
                />

                <Route path="partners/create"
                    element={
                        getAuthenticate(CreatePartner, CommonConstants.adminPermissions.ManagePartners)
                    }
                />
                <Route path="partners/:partnerUID" element={
                    getAuthenticate(ViewEditPartner, CommonConstants.adminPermissions.ManagePartners)
                } />
                <Route path="partners"
                    element={
                        getAuthenticate(AdminPartners, CommonConstants.adminPermissions.ManagePartners)
                    }
                />
                <Route path="ssoManagement"
                    element={
                        getAuthenticate(SSOManagementPage, CommonConstants.adminPermissions.ManageSSO)
                    }
                />
                <Route path="ssoManagement/add"
                    element={
                        getAuthenticate(CreateEditSSOManagement, CommonConstants.adminPermissions.ManageSSO)
                    }
                />
                <Route path="ssoManagement/edit"
                    element={
                        getAuthenticate(CreateEditSSOManagement, CommonConstants.adminPermissions.ManageSSO)
                    }
                />
            </Route>

            {/* dashboard urls */}
            <Route path="dashboard">
                <Route
                    index
                    element={getLayout(withAccountEmail(authenticate(DashboardContainer, '')))}
                />
                <Route path="customizedashboard" element={getLayout(authenticate(CustomizeDashboard, ''))} />
                <Route path="storeRanking" element={getLayout(authenticate(StoreRanking, ''))} />
            </Route>

            {/* settings urls */}
            <Route path={COMMON_ROUTES.settings}>
                <Route path="users/user" element={getAuthenticate(PublicCreateUser, '')} />
                <Route path="users/user/:uuid" element={getAuthenticate(PublicEditUser, '')} />
                <Route path="stores/viewDetail" element={getAuthenticate(TempSystemStatusPage, '')} />

                <Route path="stores/viewdetails" element={getLayout(authenticate(StoreContainer, ''))} />
                <Route path="stores/hierarchy" element={<ManageHierarchy />} />
                <Route path="stores" element={getAuthenticate(PublicStoresList, '')} />
                <Route path="stores/edit" element={getAuthenticate(PublicViewEdit, '')} />
                <Route path="users" element={getAuthenticate(PublicUsersList, '')} />
                <Route path="roles" element={getAuthenticate(PublicRoles, '')} />
                <Route path="roles/add-role" element={getAuthenticate(PublicCreateRole, '')} />
                <Route path="roles/edit-role" element={getAuthenticate(PublicEditRole, '')} />
                <Route path="roles/addRoles" element={<Navigate to="/settings/roles/add-role" />} />
                <Route path="roles/editRoles" element={<NavigateWithQuery to="/settings/roles/edit-role" replace={true} />} />
                <Route path="display" element={getAuthenticate(SettingsDisplay, '')} />
                <Route path="stores/leaderBoardGroups" element={getLayout(authenticate(LeaderBoardGroups, ''))} />
                <Route
                    path="lockDeviceSettings"
                    element={getLayout(withAccountEmail(authenticate(LockDeviceSettingsPage, '')))}
                />
                <Route
                    path="manageDeviceSettings"
                    element={getAccountEmail(authenticate(PublicDeviceSettingsPage, ''))}
                />
                <Route
                    path="applyDeviceSettingsStatus"
                    element={getAccountEmail(authenticate(PublicApplyDeviceSettingsPage, ''))}
                />
                <Route
                    path="mergeDevices"
                    element={getAccountEmail(authenticate(PublicMergeDevicesPage, ''))}
                />
                <Route
                    path="applyDeviceSettings"
                    element={getAccountEmail(authenticate(PublicCreateTaskPage, ''))}
                />
                <Route
                    path="ssoConfiguration"
                    element={getAuthenticate(SSOConfigurationPage, '')}
                />
            </Route>

            {/* Profile urls */}
            <Route
                path="profile/:activeTab"
                element={<Profile />}
            />

            {/* Text connect urls */}
            <Route path="text-connect" element={getAuthenticate(PublicTextConnectPage, '')} />

            {/* Power Bi Reports */}
            <Route path="new-reports" element={<ReportContextWrapper/>}>
                <Route path="overview" element={getAuthenticate(ReportLanding, '')}/>
                <Route path="multi" element={getAuthenticate(withNoReportsFallback(PbiReportScreen, 'multi'), '')}/>
                <Route path="single" element={getAuthenticate(withNoReportsFallback(PbiReportScreen, 'single'), '')}/>
                <Route path="trendsHistory" element={getAuthenticate(withNoReportsFallback(PbiReportScreen, 'trendsHistory'), '')}/>
                <Route path="trends" element={getAuthenticate(withNoReportsFallback(PbiReportScreen, 'trends'), '')}/>
                <Route path="outliers-client" element={getAuthenticate(withNoReportsFallback(PbiReportScreen, 'outliers-client'), '')}/>
                <Route
                    path="performance-analysis"
                    element={getAuthenticate(withNoReportsFallback(PbiReportScreen, 'performance-analysis'), '')}
                />
                <Route path="self-service" element={getAuthenticate(withNoReportsFallback(PbiReportScreen, 'self-service'), '')}/>
                <Route path="rcd" element={getAuthenticate(withNoReportsFallback(PbiReportScreen, 'rcd'), '')}/>
            </Route>

            <Route path="new-reports-scheduler">
                <Route path="" element={getAuthenticate(PbiScheduler, '')} />
                <Route path="create" element={getAuthenticate(CreateEditScheduler, '')} />
                <Route path="edit" element={getAuthenticate(CreateEditScheduler, '')} />
            </Route>

            {/* redirect routes */}
            <Route path="leaderBoards/smackTalk" element={<Navigate to="send" />} />

            {/* TODO: check if this route is used; add route for not found routes-? */}
            <Route path="/leaderBoardInvite" element={getLayout(authenticate(LeaderBoardLanding, ''))} />
        </Routes>
    );
};
