import { compose, cond, T } from 'ramda';

import { isAccountOwner as isProfileAccountOwner, isCorporation } from 'services/Auth';
import { withAccess } from 'HOCs/withAccess';
import { Franchise } from './Franchise';
import { Corporate } from './Corporate';

const isCorporationAccount = isCorporation();

const BrandTabCond = cond([
    [() => isCorporationAccount, () => <Corporate />],
    [T, () => <Franchise />]
]);

const isAccountOwner = isProfileAccountOwner();

export const BrandTab = compose(
    withAccess({
        checkAccess: () => isAccountOwner,
    })
)(BrandTabCond);
