import React from 'react';
import PropTypes from 'prop-types';

import { DeviceStatus } from 'library/DeviceStatus';
import { getDeviceByName } from 'helpers/Device/getDeviceType';

export const Statuses = ({ devices }) =>    (
    <div className="hme-stores-status__list__device-status">
        {
            devices.map((device) => {
                const { displayName } = getDeviceByName(device.Device_Name);

                return (
                    <div className="hme-stores-status__list__inner-cell" key={device.Device_ID}>
                        <DeviceStatus
                            online={device.Device_IsActive}
                            isPreconfigured={device.Device_IsPreconfigured}
                            deviceType={device.Device_DeviceType_ID}
                            name={displayName}
                            version={device.Device_MainVersion}
                            deviceUid={device.Device_UID}
                        />
                    </div>
                );
            })
        }
    </div>);

Statuses.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.object).isRequired
};
