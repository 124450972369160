export const applySearch = (list = [], selection = '', searchFieldNames) => {
    const searchFiels = Object.keys(searchFieldNames).map(n => searchFieldNames[n]);
    return list
        .filter(record =>
            searchFiels
            .some(field =>
                String(record[field]).toLowerCase()
                .includes(String(selection).toLowerCase())
            )
        )
}