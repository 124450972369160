import { getDeviceColumns } from '../helpers/mapDeviceColumns';

export const storeToRow = (store) => ({
    ...store,
    brandText: store.Store_Company_ID ?
        `${store.Brand_Name}*` :
        store.Brand_Name,
    cityString: store.Store_Locality && store.Store_Region ?
        `${store.Store_Locality}, ${store.Store_Region}` :
        store.Store_Locality || store.Store_Region || '',
    ...getDeviceColumns({ devices: store.Device_Details }),
    DeviceProductIDs: store.Device_Details
        .map(({ Device_Product_ID }) => Device_Product_ID)
        .filter(Boolean)
        .join(' '),
    DeviceSerialNumbers: store.Device_Details
        .map(({ Device_SerialNumber }) => Device_SerialNumber)
        .filter(Boolean)
        .join(' '),
    DeviceVersions: store.Device_Details
        .map(({ Device_MainVersion }) => Device_MainVersion)
        .filter(Boolean)
        .join(' '),
});
