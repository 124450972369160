// Following labels are not to be translated
export const SSOConfigLabels = {
    SSO_TARGET_URL: 'SSO TARGET URL',
    X509: 'X509 CERTIFICATE',
    LOGOUT_REDIRECT: 'LOGOUT REDIRECT URL',
    ASSERTION_URL: 'HME ASSERTION URL',
    ENTITY_ID: 'HME ENTITY ID'
};
export const SSOType = [
    {
        text: 'settings_sso_configuration_SAML',
        value: 'SAML'
    },
    {
        text: 'settings_sso_configuration_OAUTH2',
        value: 'OAUTH2'
    }
];
export const SSOTestStatus = {
    SUCCESS: 'success',
    FAILURE: 'failure',
    UNREACHABLE: 'unreachable'
};

export const SSO_CERT_LENGTH = 18;
export const SSO_TEST_PASS_STATUS = 'VERIFIED';

export const popWindowCenter = (width = 800, height = 600) => {
    const left = (screen.width / 2) - (width / 2);
    const top = (screen.height / 2) - (height / 2);

    return `toolbar=no,
        location=no,
        status=no,
        menubar=no,
        scrollbars=yes,
        resizable=yes,
        width=${width},
        height=${height},
        left=${left}
        top=${top}`;
};

export const HME_SETTINGS = {
    assertionUrl: 'https://sso-auth.hmecloud.com/api/sso-callback',
    entityID: 'hmecloud.com'
};

export const SSO_AUDIT_ACTIONS = {
    FAILED_TEST: 'Failed Test',
    SUCCESSFUL_TEST: 'Successful Test'
};