import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CheckboxInput } from '../CheckboxInput/CheckboxInput';
import './MultipleCheckboxInput.css';

/**
 *
 */
class MultipleCheckboxInputNotTranslated extends Component {
    /**
   *
   * @param {*} props
   */
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    /**
     *
     * @param {*} checked
     * @param {*} enumValue
     * @return {*}
     */
    onChange(checked, enumValue) {
        const { onChange, value } = this.props;
        if (checked) {
            return onChange([...value, enumValue]);
        }
        onChange(value.filter((el) => el !== enumValue));
    }

    /**
     *
     * @return {JSX}
     */
    render() {
        const { labelClassName, className, label, renderLabel, updateShowSaveCancelButtons, value,
            enums, vertical, disabled, useUpgradesInput, t } = this.props;
        return (
            <div className={classNames(className, 'hme-input', 'multiple-checkbox-input')} onClick={updateShowSaveCancelButtons}>
                {renderLabel && renderLabel()}
                {!renderLabel && label && (
                    <label className={labelClassName ? labelClassName : ''}>{label}</label>
                )}
                <div className={classNames(
                        'multiple-checkbox-input__inputs',
                        { 'multiple-checkbox-input__inputs--vertical': vertical }
                )}>
                    {enums.map((el) => (
                        <div key={el.value}>
                            <CheckboxInput
                                disabled={disabled}
                                useUpgradesInput={useUpgradesInput}
                                name={el.value}
                                label={t(el.label)}
                                value={value.includes(el.value)}
                                onChange={(value) => this.onChange(value, el.value)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

MultipleCheckboxInputNotTranslated.propTypes = {
    enums: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    })).isRequired
};

const MultipleCheckboxInput = withTranslation()(MultipleCheckboxInputNotTranslated);
export { MultipleCheckboxInput };
