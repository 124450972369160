import { useState, useCallback } from 'react';
import { scheduledUpgradesApi } from 'api/scheduledUpgradesApi';

const { getDevicesByBrand } = scheduledUpgradesApi;

export const useLoadDevicesByBrand = ({ brandFilters, search, pagenumber, perpage }) => {
    const [devices, setDevices] = useState([]);
    const [totalDevices, setTotalDevices] = useState(0);
    const [isDevicesLoading, setIsDevicesLoading] = useState(false);
    const [devicesLoadingError, setDevicesLoadingError] = useState(null);

    const loadDevicesByBrand = useCallback(
        async ({ brandFilters, search, pagenumber, perpage }) => {
            try {
                setIsDevicesLoading(true);
                setDevicesLoadingError(null);

                const {
                    brandIDs,
                    targetDeviceVersions,
                    deviceType,
                    peripheralDeviceType,
                    countryID,
                    timezones,
                    excludedBrandDevicesByTimezone = {},
                } = brandFilters;
                const timezoneIDs = timezones && timezones.length ? timezones.map(({ timezoneID }) => timezoneID) : [];

                if (
                    brandIDs &&
                    brandIDs.length &&
                    targetDeviceVersions &&
                    targetDeviceVersions.length &&
                    deviceType &&
                    countryID &&
                    timezoneIDs.length
                ) {
                    const excludeDeviceIDs = Object.values(excludedBrandDevicesByTimezone).reduce(
                        (acc, devicesByTimezone) => [...acc, ...devicesByTimezone.deviceIDs],
                        [],
                    );

                    const result = await getDevicesByBrand({
                        brandIDs,
                        targetDeviceVersions,
                        deviceType,
                        peripheralDeviceType,
                        countryID,
                        timezoneIDs,
                        search,
                        pagenumber,
                        perpage,
                        excludeDeviceIDs,
                    });

                    setDevices(result?.devices || []);
                    setTotalDevices(result?.total || 0);
                }
            } catch (e) {
                setDevices([]);
                setTotalDevices(0);
                setDevicesLoadingError(e);
            }

            setIsDevicesLoading(false);
        },
        [brandFilters, search, pagenumber, perpage],
    );

    return { devices, totalDevices, isDevicesLoading, devicesLoadingError, loadDevicesByBrand };
};
