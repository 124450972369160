import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';
import { ReactComponent as HeadphonesIcon } from 'assets/icons/icon-headphones.svg';

import './Header.scss';

export const Header = ({ title = '' }) => {
    const { t } = useTranslation();

    return (
        <div className="text-connect__header">
            <Title>{t(title)}</Title>
            <HeadphonesIcon className="connect__header__title-icon" />
        </div>
    );
};
