import {
    DEFAULT_REGIONS_COUNTRY_ID,
    DEFAULT_SUBSCRIPTION_TYPE,
    companyTypes,
    jtechSubscriptionTypeList
} from './constants';
import { initJtechSubscriptionTypes } from './initJtechSubscriptionTypes';

export const getAccountFormInitialValues = ({ pageAccountType }) => {
    return {
        accountOwnerInfo: {
            firstName: '',
            lastName: '',
            emailAddress: '',
            emailAddressConfirm: '',
        },
        accountSettings: {
            isDualFactorAuthentication: false,
            isTestAccount: false,
        },
        companyInfo: {
            companyName: '',
            companyCountryId: DEFAULT_REGIONS_COUNTRY_ID,
            companyRegionId: '',
            region: '',
            distributorId: '',
            brandUid: '',
            companyType: pageAccountType || companyTypes.FRANCHISE,
        },
        subscriptionType: {
            subscriptionType: `${DEFAULT_SUBSCRIPTION_TYPE}-1`,
        },
        jtechSubscriptionType: {
            isJtechCustomerSubscriptionType: false,
            jtechSubscriptionTypes: initJtechSubscriptionTypes(jtechSubscriptionTypeList),
        },
        engineeringSettings: {
            isDataServicePush: false,
            isDataServicePull: false,
        },
    };
};
