import React from 'react';
import { Section } from 'library/Section';
import { DeviceTypeForm } from './DeviceTypeForm/DeviceTypeForm';
import { DeviceFilterForm } from './DeviceFilterForm/DeviceFilterForm';
import './SourceDeviceSection.scss';

export const SourceDeviceSection = ({
    col,
    sectionNumber,
    permissionType,
    deviceTypes,
    onDeviceChange,
    selectedDeviceType,
    accounts,
    devices,
    accountSearch,
    selectedAccount,
    isAccountsLoading,
    isAccountLoading,
    isDevicesLoading,
    onAccountChange,
    onAccountSelected,
    accountStores,
    storeNumberSearch,
    formErrors,
    onStoreNumberChange,
    onStoreNumberSelected,
    deviceIdentifier,
    onDeviceIdentifierChange,
    onDeviceSelected,
}) => {
    return (
        <Section className="create-snapshot-source" col={col} number={sectionNumber}>
            <DeviceTypeForm
                number={1}
                title="settings_snapshots__create_snapshot__title--source-device-type"
                deviceTypes={deviceTypes}
                onDeviceChange={onDeviceChange}
                selectedDeviceType={selectedDeviceType}
            />
            <DeviceFilterForm
                number={2}
                title="settings_snapshots__create_snapshot__title--source-device"
                permissionType={permissionType}
                selectedDeviceType={selectedDeviceType}
                accounts={accounts}
                devices={devices}
                formErrors={formErrors}
                accountSearch={accountSearch}
                selectedAccount={selectedAccount}
                isAccountsLoading={isAccountsLoading}
                isAccountLoading={isAccountLoading}
                isDevicesLoading={isDevicesLoading}
                onAccountChange={onAccountChange}
                onAccountSelected={onAccountSelected}
                accountStores={accountStores}
                storeNumberSearch={storeNumberSearch}
                onStoreNumberChange={onStoreNumberChange}
                onStoreNumberSelected={onStoreNumberSelected}
                deviceIdentifier={deviceIdentifier}
                onDeviceIdentifierChange={onDeviceIdentifierChange}
                onDeviceSelected={onDeviceSelected}
            />
        </Section>
    );
};
