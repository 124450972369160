import { fetchAccountOwners } from 'services/Account';

export const getAccountOwners = async ({ emailSearchQuery, limit = 5 }, requestOptions = {}) => {
    const { data, status } = await fetchAccountOwners(
            {
                emailSearchQuery,
                limit
            }, requestOptions);

    if (!status) {
        throw new Error('common__error--unknown');
    }

    return data;
};
