import React from 'react';

import { SelectSnapshot } from './SelectSnapshot';

export const SnapshotForm = ({ snapshots, deviceType, params, onParamsChange, isSnapshotsLoading = false }) => {

    const onSnapshotChange = ({ settingTemplateID, isIOT, sourceSettingsList }) => {
        onParamsChange({
            ...params,
            settingTemplateID,
            isIOT,
            sourceSettingsList,
        })
    };

    return <SelectSnapshot
        snapshots={snapshots}
        deviceType={deviceType}
        value={params.settingTemplateID}
        onParamsChange={onSnapshotChange}
        params={params}
        isSnapshotsLoading={isSnapshotsLoading}
    />
};
