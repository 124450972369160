import React from 'react';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';

import './DeleteConfirmPopup.scss';
import { useTranslation } from 'react-i18next';

export const DeleteConfirmPopup = ({ onHide, onConfirm, name, ...props }) => {
    const { t } = useTranslation();

    return (
        <ConfirmPopupComponent
            {...props}
            onHide={onHide}
            title='common__popup--title-confirm'
            message={t('settings_snapshots__delete_snapshot--warning', { name })}
            dialogClassName='device-settings-confirm-delete'
            actions={[{
                children: t('common__cancel'),
                onClick: onHide,
            }, {
                children: t('settings_snapshots__delete_snapshot--confirm'),
                variants: ['submit'],
                onClick: () => {
                    onConfirm && onConfirm();
                    onHide && onHide();
                }
            }]}
        />
    );
}
