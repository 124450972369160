import { useCallback } from 'react';

import { useMounted } from 'hooks/useMounted';

/**
 * Purpose of this hook is to prevent state updating
 * on unmounted component
 * 
 * @example
 *      const [state, unsafeDispatch] = useReducer(initialState, reducer);
 *      const dispatch = useSafeDispatch(unsafeDispatch);
 *      Call "dispatch" to update state
 */
export const useSafeDispatch = (unsafeDispatch) => {
    const mountedRef = useMounted();

    return useCallback((...args) => {
        if (mountedRef.current) {
            unsafeDispatch(...args);
        }
    }, []);
};
