/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast, Slide } from 'react-toastify';

import { ConfirmPopupComponentGeneric } from 'library/ConfirmPopup';
import { Button } from 'library/Button';
import { CenterLoader } from 'components/Common/CenterLoader';

import { GeneralSection } from './GeneralSection';
import { SectionsSection } from './SectionsSection';
import { SettingsSection } from './SettingsSection';
import { Footer } from './Footer';
import { Header } from './Header';

import { ReactComponent as InfoIcon } from 'assets/icons/infoIcon.svg';

import './LeaderBoardTemplates.scss';

const defalutCloseBtnVariants = ['cancel'];

const defaultSections = [
    { Component: GeneralSection },
    { Component: SectionsSection },
    { Component: SettingsSection }
];

const keysForSectionsCompletion = [
    {
        every: [
            'TemplateName',
            'StoreLabel'
        ],
        some: [
            'TemplateName',
            'StoreLabel'
        ],
        validate: {
            'TemplateName': (value) => value && value.length > 2 && value.length <= 100
        }
    },
    {
        every: [],  //  'SortColumn'
        some: ['DisplayColumnOneType', 'DisplayColumnTwoType']
    },
    {
        every: [
            'HideGoal',
            'TimeFormat',
            'LanguageID',
            'IncludePullIns',
            'Metric',
            'GoalAColor',
            'GoalBColor',
            'GoalCColor'
        ],
        some: [
            'HideGoal',
            'TimeFormat',
            'LanguageID',
            'IncludePullIns',
            'Metric',
            'GoalAColor',
            'GoalBColor',
            'GoalCColor'
        ]
    }
];

export const LeaderBoardTemplates = ({
    template,
    onChange,
    values: {
        header: {
            title
        } = {},
        sections: sectionsOptions = [],
        footer: {
            onCancel,
            onSave
        } = {}

    } = {},
    isEditing,
    isDirty,
    isLoading = false,
    usedBy,
    languages,
    isConfirmPopupShown,
    setIsConfirmPopupShown,
    onConfirm,
    isNitro
}) => {
    const { t } = useTranslation();
    const [sections, setSections] = useState(sectionsOptions);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const isSectionCompleted = (sectionNumber) => {
        const currentSectionKeys = keysForSectionsCompletion[sectionNumber] || {};
        const checkKey = ( key ) => {
            const validate = currentSectionKeys &&
                currentSectionKeys['validate'] &&
                currentSectionKeys['validate'][key];
            if (validate && !validate(template[key])) {
                return 0;
            }
            return template[key] !== null;
        };
        return Number(currentSectionKeys['every'].every(checkKey)) +
            Number(currentSectionKeys['some'].some(checkKey)) === 2;
    };
    const disableFollowingSections = (sec, i, arr) => {
        if ( i > 0 ) {
            sec.props.disabled = sec.props.disabled || arr[i-1].props.disabled;
        }
        return sec;
    };
    const enableSectionsByCompletion = ( section, i ) => {
        const newSection = { ...section };
        newSection.props = newSection.props || {};
        if ( i > 0 ) {
            newSection.props.disabled = !isSectionCompleted(i-1);
        }
        return newSection;
    };
    const onHidePopup = () => setIsConfirmPopupShown(false);
    useEffect(() => {
        if (isEditing && !isLoading) {
            toast.dismiss();
            toast.warn(
                    (<>
                        <InfoIcon className="info-icon-svg" />
                        <span>
                            {isNitro ? t('leaderboard__template__edit-warning') : t('leaderboard__template__edit-warning-3x')}
                        </span>
                    </>),
                    {
                        transition: Slide,
                        hideProgressBar: true,
                        progress: 0,
                        containerId: 2
                    }
            );
        }
    }, [isEditing, isConfirmPopupShown, isLoading, t]);
    useEffect(() => {
        if (!isEditing) {
            const newSections = sections
                    .map( enableSectionsByCompletion )
                    .map( disableFollowingSections );
            setSections(newSections);
        }
        const enableSubmit = (defaultSections.every((_, i) => isSectionCompleted(i)) && (!isEditing || isDirty) && template.SortColumn);
        setIsSubmitEnabled(enableSubmit);
    }, [template, isEditing, isDirty]);
    return isLoading ? <CenterLoader>{t('common__loading')}</CenterLoader> :
    (
        <div className="hme-components leaderboard-templates">
            <ConfirmPopupComponentGeneric
                show={isConfirmPopupShown}
                title={t('common__double-checking')}
                message={<>
                    <p className="hme-leaderboard-templates-confirm-line">
                        {isNitro ? t('leaderboard__template__edit-confirmation') : t('leaderboard__template__edit-confirmation-3x')}
                    </p>
                    <p>
                        {isNitro ? t('leaderboard__template__edit-submit-confirm') : t('leaderboard__template__edit-submit-confirm-3x')}
                    </p>
                </>}
                onHide={onHidePopup}
                actions={[{
                    children: t('common__cancel'),
                    onClick: onHidePopup
                }, {
                    children: t('common__continue'),
                    variants: ['submit'],
                    onClick: () => {
                        onConfirm && onConfirm();
                        onHidePopup && onHidePopup();
                    }
                }]}
            />
            <ToastContainer
                enableMultiContainer
                containerId={2}
                classname="toast-container-custom"
                position="top-center"
                autoClose={false}
                newestOnTop
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
            />
            <div className="leaderboard-templates-header">
                <Header
                    title={t(title)}
                />
            </div>
            <div className="leaderboard-templates-sections">
                {defaultSections
                        .map((el, i) => {
                            const props = sections[i] && sections[i].props || {};
                            el.props = { ...props, template, onChange, isEditing, usedBy };
                            return el;
                        }) 
                        .map(({ Component, props }, i) => (
                            <Component
                                key={i}
                                className={`leaderboard-templates-${i+1}-section ${!isNitro && i===0 ? 'hidden' : ''}`}
                                isNitro={isNitro}
                                languages={languages}
                                {...props}
                            />
                        ))}
            </div>
            <Footer
                isSubmitEnabled={isSubmitEnabled}
                onCancel={onCancel}
                onSave={onSave}
            />
        </div>
    );
};

export const LeaderBoardTemplatePreview = ({
    template,
    onChange,
    values: {
        header: {
            title
        } = {},
        sections: sectionsOptions = [],
        footer: {
            onCancel,
            onSave
        } = {}

    } = {},
    isEditing,
    isDirty,
    isLoading = false,
    usedBy,
    isConfirmPopupShown,
    setIsConfirmPopupShown,
    isNitro,
    languages,
    onConfirm,
    onHide
}) => {
    const { t } = useTranslation();
    const [sections, setSections] = useState(sectionsOptions);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const isSectionCompleted = (sectionNumber) => {
        const currentSectionKeys = keysForSectionsCompletion[sectionNumber] || {};
        const checkKey = ( key ) => {
            const validate = currentSectionKeys &&
                currentSectionKeys['validate'] &&
                currentSectionKeys['validate'][key];
            if (validate && !validate(template[key])) {
                return 0;
            }
            return template[key] !== null;
        };
        return Number(currentSectionKeys['every'].every(checkKey)) +
            Number(currentSectionKeys['some'].some(checkKey)) === 2;
    };
    const disableFollowingSections = (sec, i, arr) => {
        if ( i > 0 ) {
            sec.props.disabled = sec.props.disabled || arr[i-1].props.disabled;
        }
        return sec;
    };
    const enableSectionsByCompletion = ( section, i ) => {
        const newSection = { ...section };
        newSection.props = newSection.props || {};
        if ( i > 0 ) {
            newSection.props.disabled = !isSectionCompleted(i-1);
        }
        return newSection;
    };
    const onHidePopup = () => setIsConfirmPopupShown(false);
    return isLoading ? <CenterLoader>{t('common__loading')}</CenterLoader> :
    (
        <div className="hme-components leaderboard-templates">
            <ConfirmPopupComponentGeneric
                show={isConfirmPopupShown}
                title={t('common__double-checking')}
                message={<>
                    <div className="hme-leaderboard-templates-confirm-line">
                        {t('leaderboard__template__edit-confirmation')}
                    </div>
                    <div>
                        {t('leaderboard__template__edit-submit-confirm')}
                    </div>
                </>}
                onHide={onHidePopup}
                actions={[{
                    children: t('common__cancel'),
                    onClick: onHidePopup
                }, {
                    children: t('common__continue'),
                    variants: ['submit'],
                    onClick: () => {
                        onConfirm && onConfirm();
                        onHidePopup && onHidePopup();
                    }
                }]}
            />
            <div className="leaderboard-templates-sections leaderboard-templates-sections--modal">
                {defaultSections
                        .map((el, i) => {
                            const props = sections[i] && sections[i].props || {};
                            el.props = { ...props, template, onChange, isEditing, usedBy, isPreview: true, onHide };
                            return el;
                        })
                        .map(({ Component, props }, i) => (
                            <Component
                                key={i}
                                className={`leaderboard-templates-${i+1}-section`}
                                isNitro={isNitro}
                                languages={languages}
                                {...props}
                            />
                        ))}
                <div className="leaderboard-templates-sections__modal-close-btn">
                    <Button
                        variants={defalutCloseBtnVariants}
                        onClick={onHide}
                    >
                        {t('common__close')}
                    </Button>
                </div>
            </div>
        </div>
    );
};
