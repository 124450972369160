import { Config } from '../Config';
import { CommonConstants } from '../Constants';
import { baseApi, postDataAsync, getDataAsync } from '../Api';
import { formatInstallSchedulePayload } from '../helpers';

export const BAD_DEVICE_ID_ERROR = 'Bad device id';
export const DEVICE_UNREACHABLE_FOR_DISCONNECT_ERROR = 'Device is unreachable for disconnect';
export const ZOOM_DEVICE_UNREACHABLE_ERROR = 'Zoom device is unreachable';
export const SCHEDULE_TIME_FORMAT = 'HH:mm a';

const getSearchString = obj => Object.keys(obj).reduce((params, key) => {
    params.set(key, obj[key]);
    return params;
}, new URLSearchParams()).toString();

export const checkDevice = (duid) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.checkDevice}?duid=${duid}`;
    return getDataAsync(url);
};

export const unBind = async (duid) => {
    const ip = await baseApi.getPublicIp();
    const clientIp = ip ? ip.clientIp : null;
    const absoluteUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.UnBindRemoteDeviceWeb}?oDeviceID=${duid}&sRequestorHost=${clientIp}`;
    return getDataAsync(absoluteUrl);
};

export const unBindEmail = (duid) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.unBindMail}`;
    const emailObject = {
      duid: duid,
    };

    return postDataAsync(url, emailObject);
};

export const callReboot = (duid) => {
    const absoluteUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.RestartDevice}?oDeviceID=${duid}`;
    return getDataAsync(absoluteUrl);
};

export const callReconnect = (duid) => {
    const absoluteUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.ForceReconnect}?oDeviceID=${duid}`;
    return getDataAsync(absoluteUrl);
};

export const callUpgrade = ({ deviceUID, modelName, version }) => {
    const absoluteUrl = Config.apiBaseUrl + CommonConstants.apiUrls.DownloadNewImage
    + `?oDeviceID=${deviceUID}&client=${modelName}&sVersion=${version}&sSettingsVersion=GNRC&sRunTime=`;

    return getDataAsync(absoluteUrl);
};

export const reboot = async (duid) => {
    const device = await checkDevice(duid);

    if (!device.status || device.data.DevicePresent !== 1) {
        throw new Error(BAD_DEVICE_ID_ERROR);
    }

    try {
        const data = await unBind(duid);

        if (data.data) {
            await unBindEmail(duid);
        }
    } catch (error) {
        throw new Error(DEVICE_UNREACHABLE_FOR_DISCONNECT_ERROR);
    }

    try {
        return await callReboot(duid);
    } catch (error) {
        throw new Error(DEVICE_UNREACHABLE_FOR_DISCONNECT_ERROR);
    }
};

export const reconnect = async (duid) => {
    const device = await checkDevice(duid);

    if (!device.status || device.data.DevicePresent !== 1) {
        throw new Error(BAD_DEVICE_ID_ERROR);
    }

    try {
        const data = await unBind(duid);

        if (data.data) {
            await unBindEmail(duid);
        }
    } catch (error) {
        throw new Error(DEVICE_UNREACHABLE_FOR_DISCONNECT_ERROR);
    }

    try {
        return await callReconnect(duid);
    } catch (error) {
        throw new Error(DEVICE_UNREACHABLE_FOR_DISCONNECT_ERROR);
    }
};

export const upgrade = async ({ deviceUID, modelName, version }) => {
    const device = await checkDevice(deviceUID);

    if (!device.status || device.data.DevicePresent !== 1) {
        throw new Error(BAD_DEVICE_ID_ERROR);
    }

    try {
        const data = await unBind(deviceUID);

        if (data.data) {
            await unBindEmail(deviceUID);
        }

        return await callUpgrade({ deviceUID, modelName, version });   
    } catch (error) {
        throw new Error(ZOOM_DEVICE_UNREACHABLE_ERROR);
    }
};

export const getAccountDevices = ({ isActive, deviceMinVersion, deviceType }) => {
    let absoluteUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.GetAccountDevices}`;
    let isFirstQuery = true;

    if (isActive) {
        absoluteUrl += '?isActive=1';
        isFirstQuery = false;
    }

    if (deviceType) {
        absoluteUrl += isFirstQuery ? `?deviceType=${deviceType}` : `&deviceType=${deviceType}`;
        isFirstQuery = false;
    }

    if (deviceMinVersion) {
        absoluteUrl += isFirstQuery ? `?minMainVersion=${deviceMinVersion}`: `&minMainVersion=${deviceMinVersion}`;
    }
    return getDataAsync(absoluteUrl);
};

export const getAllDevices = async filters => {
    const absoluteUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.GetAdminDevices}`;
    const search = getSearchString(filters);

    const response = await getDataAsync(`${absoluteUrl}?${search}`);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.data;
}

export const OverrideCountryForDevice = (countryId, deviceUID) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.setOverrideCountry}`;
    const payload = {
        countryId,
        deviceUID
    }
    return postDataAsync(url, payload);
}

export const getDevice = async (deviceUID) => {
    const absoluteUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.GetAdminDevice}`;
    const search = getSearchString({ deviceUID });

    const response = await getDataAsync(`${absoluteUrl}?${search}`);

    if (!response.status || !response.data) {
        throw new Error('Response is failed');
    }

    return response.data;
};

export const getDeviceRecentUpgradeTasks = async (deviceUID) => {
    const absoluteUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.GetDeviceRecentUpgradeTasks}`;
    const search = getSearchString({ deviceUID });

    const response = await getDataAsync(`${absoluteUrl}?${search}`);

    if (!response.status || !response.data) {
        throw new Error('Response is failed');
    }

    return response.data;
};

export const getDeviceSettings = async (deviceUID) => {
    const absoluteUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getSettingsDevices}`;
    const search = getSearchString({ duid: deviceUID });

    const response = await getDataAsync(`${absoluteUrl}?${search}`);

    if (!response) {
        throw new Error('Response is failed');
    }

    return response;
};

export const getPeripheralDevices = async (deviceUID) => {
    const absoluteUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDevicePeripheral}`;
    const search = getSearchString({ deviceUID });

    const response = await getDataAsync(`${absoluteUrl}?${search}`);

    if (!response.status || !response.data) {
        throw new Error('Response is failed');
    }

    return response.data;
};

// TODO make single function without default "typeName = 'ZOOM'" argument
export const getDeviceUpgradeVersions = async ({ typeName = 'ZOOM' }) => {
    const absoluteUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDeviceUpgradeVersions}`;
    const search = getSearchString({ DeviceTypeName: typeName });

    const response = await getDataAsync(`${absoluteUrl}?${search}`);

    if (!response.status || !response.data) {
        throw new Error('Response is failed');
    }

    return response.data;
};

// TODO substitute getUpgradeVersions with getDeviceUpgradeVersions when "typeName = 'ZOOM'" is removed from default
export const getUpgradeVersions = async ({ typeName } = {}) => {
    const absoluteUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDeviceUpgradeVersions}`;
    const url = typeName ? `${absoluteUrl}?DeviceTypeName=${typeName}` : absoluteUrl;

    const response = await getDataAsync(url);

    if (!response.status || !response.data) {
        throw new Error('Response is failed');
    }

    return response.data;
};

export const getSupportedDeviceUpgradeVersions = async ({ typeName } = {}) => {
    const absoluteUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getSupportedDeviceUpgradeVersions}`;
    const url = typeName ? `${absoluteUrl}?DeviceTypeName=${typeName}` : absoluteUrl;

    const response = await getDataAsync(url);

    if (!response.status || !response.data) {
        throw new Error('Response is failed');
    }

    return response.data;
}

export const createUpgradeTask = ({ deviceUID, deviceType, version }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.createUpgradeTask}`;
    const payload = { deviceUID, deviceType, version };
    return postDataAsync(url, payload);
}


export const createBatchUpgrade = async ({
    name,
    deviceType,
    peripheralDeviceType,
    runDate,
    scheduleDays,
    targetDeviceVersions,
    upgradeVersion,
    from,
    to,
    stores,
    brandFilters,
    installSchedule,
}) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.createBatchUpgrade}`;
    const payload = {
        name,
        deviceType,
        peripheralDeviceType,
        runDate,
        scheduleDays,
        targetDeviceVersions,
        targetVersion: upgradeVersion,
        from,
        to,
        stores: stores.map(store => ({
            Device_UID: store.Device_UID,
            Device_SerialNumber: store.Device_SerialNumber,
            Device_MainVersion: store.Device_MainVersion,
            Device_DeviceType_ID: store.Device_DeviceType_ID,
        })),
        installSchedule,
        brandFilters,
    };

    const response = await postDataAsync(url, payload);

    if (!response.status || !response.data) {
        throw new Error('Response is failed');
    }

    return response.data;
};

export const getScheduledDeviceUpgrades = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getBatchUpgrades}`;
    return await getDataAsync(url);
}

export const getFilters = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getDeviceFilters}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.data;
};

export const getPeripheralDevicesFiltered = async (input) => {
    const absoluteUrl = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getPeripheralDevices}`;
    const searchParams = ['storeBrandId', 'storeCountryId', 'storeAccountId','deviceStoreId', 'hideScheduledDevices', 'deviceTypeId']
        .reduce((acc, key) => {
        if (input[key]) {
            acc[key] = input[key];
        }
        return acc;
    }, {});

    const search = getSearchString(searchParams);
    const response = await getDataAsync(`${absoluteUrl}?${search}`);

    if (!response.status || !response.data) {
        throw new Error('Response is failed');
    }

    return response.data;
};