import { storeHierarchyNodeTypes } from 'constants/storeHierarchy';

const calculateDepth = (obj, depth, maxDepth) => {
    if (!obj.Children || obj.Children.length === 0) {
        return Math.max(maxDepth, depth);
    } else {
        let newMaxDepth = maxDepth;
        obj.Children.forEach((child) => {
            if (child['Type'] === storeHierarchyNodeTypes.GROUP) {
                newMaxDepth = calculateDepth(child, depth + 1, newMaxDepth);
            } else {
                newMaxDepth = calculateDepth(child, depth, newMaxDepth);
            }
        });
        return newMaxDepth;
    }
};

export const getMaxDepth = (items) => {
    let maxDepth = 0;

    items.forEach((item) => {
        maxDepth = calculateDepth(item, item['Type'] === storeHierarchyNodeTypes.GROUP ? 1 : 0, maxDepth);
    });

    return maxDepth;
};
