/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'library/Checkbox';
import { BUTTON_VARIANTS, Button } from 'library/Button';
import { Tip } from 'library/Tip';
import { Label } from 'library/Label';

import './DaypartSelection.scss';

const buttonVaraints = [BUTTON_VARIANTS.TRANSPARENT];

/**
 *
 * @param {list} dayparts
 * @return {JSX}
 */
export const DaypartSelection = ({
    dayparts,
    onChange,
    currentDaypartSelection
}) => {
    const { t } = useTranslation();

    const onSelectAll = useCallback(() => {
        const selectedDayparts = dayparts.map((daypart)=> {
            daypart.selected = true;
            return daypart.value;
        });
        onChange && onChange(selectedDayparts);
    }, [dayparts, onChange]);

    const onDeselectAll = useCallback(() => {
        dayparts.map((daypart) => {
            daypart.selected = false;
        });
        onChange && onChange([]);
    }, [onChange]);

    const onClickCheckbox = (checkValue, value) => {
        dayparts[value-1].selected = !dayparts[value-1].selected;
        onChange([value]);
        const selectedDayparts = currentDaypartSelection.daypartIDs;
        if (checkValue) {
            selectedDayparts.push(value);
        } else {
            const index = selectedDayparts.indexOf(value);
            if (index > -1) {
                selectedDayparts.splice(index, 1);
            }
        }
        onChange(selectedDayparts);
    };

    return (
        <div className="daypart-selection-view__section">
            <div className="daypart-selection-view__section__versions">
                <Label className="daypart-selection-view__section__subtitle">{t('common__daypart_other')}</Label>
                {' '}
                <Tip className="create-edit-scheduler__tooltip">{t('scheduler__report-dayparts-info-message')}</Tip>
                <div className="daypart-selection-view__section__version-list-wrapper">
                    <div className="daypart-selection-view__section__version-list">
                        {dayparts.map(({ value, selected }) => (
                            <div className="daypart-selection-view__section__version" key={value}>
                                <Checkbox
                                    value={value}
                                    label={t('common__daypart') + ' ' + value}
                                    checked={selected || false}
                                    onChange={(checkValue) => onClickCheckbox(checkValue, value)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="daypart-selection-view__section__selection">
                <Button
                    className="daypart-selection-view__section__selection__select-all"
                    variants={buttonVaraints}
                    onClick={onSelectAll}
                >
                    {t('common__select-all')}
                </Button>
                <Button
                    className="daypart-selection-view__section__selection__deselect-all"
                    variants={buttonVaraints}
                    onClick={onDeselectAll}
                >
                    {t('common__deselect-all')}
                </Button>
            </div>
        </div>
    );
};
