export const subscriptions = [
    {
        id: 3,
        isNitro: 0,
        level: 0,
        name: 'Free Store Subscription',
        description: 'Manage your device settings from a central location',
    },
    {
        id: 4,
        isNitro: 0,
        level: 1,
        name: 'Premium Store Subscription',
        description: 'View your drive-thru data in easy to read dashboards and reports',
    },
    {
        id: 4,
        isNitro: 1,
        level: 1,
        name: 'Nitro-Data',
        description: 'Nitro Connect with real-time dashboard and historic reports',
    },
    {
        id: 6,
        isNitro: 0,
        level: 2,
        name: 'PremiumPlus Store with Leaderboard Subscription',
        description: 'Enable Drive-Thru Leaderboard to ignite the spirit of competition among your crew members',
    },
    {
        id: 6,
        isNitro: 1,
        level: 2,
        name: 'Nitro-Leaderboard',
        description: 'Nitro Data with Leaderboard',
    },
    {
        id: 8,
        isNitro: 0,
        level: 3,
        name: 'BasicPlus Store Upgrade Subscription',
        description: 'Enable Store Upgrades for all of your devices',
    },
    {
        id: 8,
        isNitro: 1,
        level: 3,
        name: 'Nitro-Essentials',
        description: 'Remote Connection to HME devices, technical support and upgrades',
    },
    {
        id: 9,
        isNitro: 0,
        level: 4,
        name: 'API-Bundle',
        description: 'Enable HME CLOUD API Calls (Multi)',
    },
    {
        id: 14,
        isNitro: 0,
        level: 5,
        name: 'API-Standalone',
        description: 'Enable HME CLOUD API Calls (Single)',
    },
    {
        id: 15,
        isNitro: 1,
        level: 6,
        name: 'Nitro-Gamification',
        description: 'Nitro Leaderboard with Gamification',
    },
    {
        id: 16,
        isNitro: 0,
        level: 0,
        name: 'LinkWear Customer Only',
        description: 'LinkWear Customer Only- For LinkWear',
    },
    {
        id: 17,
        isNitro: 0,
        level: 0,
        name: 'NEXEO Customer Only',
        description: 'NEXEO Customer Only',
    },
];

export const getSubscription = (subscriptionID, isNitro) => subscriptions.find((s) => s.id === subscriptionID && s.isNitro === isNitro);
