import { useState, useCallback } from 'react';
import { reconnect } from '../../../../services/Device';

export const useReconnectLinkedDevice = (...actions) => {
    const [isLinkedDeviceReconnecting, setIsLinkedDeviceReconnecting] = useState(false);
    const [linkedDeviceReconnectingError, setLinkedDeviceReconnectingError] = useState(null);

    const reconnectLinkedDevice = useCallback(async (linkedDuid, duid) => {
        if (!linkedDuid || !duid) return;

        try {
            setIsLinkedDeviceReconnecting(true);
            setLinkedDeviceReconnectingError(null);

            const result = await reconnect(linkedDuid);
            await Promise.all(actions.map((action) => action(duid)));
            setIsLinkedDeviceReconnecting(false);

            return result && result.data && result.data.data;
        } catch (e) {
            setLinkedDeviceReconnectingError(e);
            setIsLinkedDeviceReconnecting(false);
        }
    }, []);

    return { isLinkedDeviceReconnecting, linkedDeviceReconnectingError, reconnectLinkedDevice };
};
