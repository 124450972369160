import { cond, T } from 'ramda';

import { taskConfig } from 'constants/applyStoreSettingsTask';
import { WaitingForUpload } from './WaitingForUpload';
import { InProgress } from './InProgress';
import { Completed } from './Completed';
import { Failed } from './Failed';
import { Canceled } from './Canceled';

const { statuses } = taskConfig;

const isStatus = expected => ({ status }) => expected === status;

const isWaitingForUpload = isStatus(statuses.WAITING_FOT_UPLOAD);
const isinProgress = isStatus(statuses.IN_PROGRESS);
const isCompleted = isStatus(statuses.COMPLETED);
const isFailed = isStatus(statuses.FAILED);
const isCanceled = isStatus(statuses.CANCELED);

export const StatusCell = cond([
    [isWaitingForUpload, WaitingForUpload],
    [isinProgress, InProgress],
    [isCompleted, Completed],
    [isFailed, Failed],
    [isCanceled, Canceled],
    [T, () => null],
]);
