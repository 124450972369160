import { saveBrandDeviceUpgradeVersions as saveDeviceUpgradeVersions } from 'services/Brands';
import { addSuccessNotification, addErrorNotification } from 'services/Notifications';
import { FIRMWARE_MANAGEMENT_PAGE_URL } from './Common/ViewEdit/constants';

export const saveBrandDeviceUpgradeVersions = async ({ brandID, payload, setIsLoading, navigate, t }) => {
    setIsLoading(true);

    try {
        await saveDeviceUpgradeVersions(brandID, payload);
        navigate(FIRMWARE_MANAGEMENT_PAGE_URL);
        setTimeout(() => {
            addSuccessNotification(t('firmware_management__view__notification--brand-firmware-successfully-changed'));
        }, 1000);
    } catch (err) {
        addErrorNotification(t('common__error--internal-server'));
    }

    setIsLoading(false);
};
