import { useState, useEffect, useCallback } from 'react';
import { getDevice } from 'services/Device';

export const useLoadDevice = (deviceUID) => {
    const [device, setDevice] = useState(null);
    const [isDeviceLoading, setIsDeviceLoading] = useState(true);
    const [deviceLoadingError, setDeviceLoadingError] = useState(null);

    const loadDevice = useCallback(async (duid) => {
        if (!duid) return;

        try {
            setIsDeviceLoading(true);
            setDeviceLoadingError(null);
            setDevice((await getDevice(duid)) || null);
        } catch (e) {
            setDevice(null);
            setDeviceLoadingError(e);
        }

        setIsDeviceLoading(false);
    }, []);

    useEffect(() => { loadDevice(deviceUID); }, [deviceUID]);

    return { device, isDeviceLoading, deviceLoadingError, loadDevice };
};
