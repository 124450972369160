// compare numbers for grid columns sorting
export default (a, b, order = 1) => {
    const numA = parseInt(a);
    const numB = parseInt(b);

    if (Number.isNaN(numA)) return 1;
    if (Number.isNaN(numB)) return -1;

    return numA > numB ? order : numA < numB ? -order : 0;
};
