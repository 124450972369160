import { Config } from './Config';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const HOUR_FORMAT = 'HH:mm a';

export const IMAGES = {
    award_tophy_icon: `${Config.blobNitroStorageBaseUrl}/icons/award-trophy-icon.png`,
    contest_tophy_icon: `${Config.blobNitroStorageBaseUrl}/icons/contest-trophy-icon.png`,
    smacktalk_icon: `${Config.blobNitroStorageBaseUrl}/icons/smacktalk-icon.png`
};
