import { Config } from '../Config';
import { CommonConstants } from '../Constants';
import { downloadBlobRequest, getDataAsync, getSearchString, postDataAsync, uploadDataAsync } from '../Api';

const getFileName = taskName => taskName
    .replace(/[^a-zA-Z0-9\s]/g, '')
    .trim()
    .replace(/\s+/g, '_');

export const getStores = async (params) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.SearchDeviceStores}`;

    const searchString = getSearchString(params);

    const response = await getDataAsync(`${url}?${searchString}`);

    if (!response.status) {
        throw new Error('Request failed');
    }

    return response.data;
};

export const getStoreSystemTiers = async () => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.getStoreSystemTiers}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('common__error--internal-server');
    }

    return response.data;
};

export const createSettingsTask = async params => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.CreateStoreSettingsTask}`;

    const fileName = getFileName(params.name);

    await downloadBlobRequest(url, params, `${fileName}.csv`);
};

export const getTasks = async ({ dateRange } = { }) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.GetStoreSettingTasks}`;
    const filters = {};

    if (dateRange) {
        filters.createdAtGt = dateRange.startDate.clone()
            .startOfDay()
            .toUTC()
            .toISOString();

        filters.createdAtLte = dateRange.endDate.clone()
            .endOfDay()
            .toUTC()
            .toISOString();;
    }

    const searchString = getSearchString(filters);

    const response = await getDataAsync(`${url}?${searchString}`);

    if (!response.status) {
        throw new Error('Response is failed');
    }

    return response.data;
};

export const uploadTask = async (taskUid, file) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.UploadStoreSettingsTask(taskUid)}`;

    try {
        return await uploadDataAsync(url, {
            file,
        });
    } catch (e) {
        return {
            status: false,
            errors: ['REQUEST_FAILED'],
        };
    }
};

export const getTask = async (taskUid) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.GetStoreSettingTask(taskUid)}`;

    const response = await getDataAsync(url);

    if (!response.status) {
        throw new Error('Response is failed');
    }

    return response.data;
};

export const cancelTask = async (taskUid) => {
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.CancelStoreSettingTask(taskUid)}`;

    const response = await postDataAsync(url);

    if (!response.status) {
        throw new Error('Response is failed');
    }

    return response.data;
};


export const redownloadTask = async (taskUid) => {
    const task = await getTask(taskUid);
    const url = `${Config.apiBaseUrl}${CommonConstants.apiUrls.RedownloadStoreSettingTask(taskUid)}`;

    const fileName = getFileName(task.Task_Name);

    await downloadBlobRequest(url, {}, `${fileName}.csv`, {
        method: 'get',
    });
};

export const confirmMerge = async (params) => {
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.saveMergeDevices;
    const response = await postDataAsync(url, params);

    if (!response.status) {
        throw new Error('Response is failed');
    }
    return response.data;
};
