import React from 'react';

import { hasLeaderboardAccess as getLeaderBoardAccess } from '../actions/permissions';
import { GamificationMarketing } from 'pages/GamificationMarketing';
import { hasLeaderboardAccess } from 'helpers/access';

/**
 * Checks given permission and renders 'access restricted' message or underlying page itself
 * @param {JSX.Element} Component
 * @param {'smacktalk' | 'contests' | 'awards' | 'feedback' | 'avatars' | 'announcementSettings'} permissionGroupName
 * @return {JSX.Element}
 */

// eslint-disable-next-line react/display-name
const withNoLeaderboardFallback = (Component, permissionGroupName, title) => (props) => {
    const isGranted = hasLeaderboardAccess(permissionGroupName);
    const additionalProps = {};
    const { accessLevel, hasSubscription, isNitro } = getLeaderBoardAccess();

    if (isGranted) {
        additionalProps.accessLevel = accessLevel;
        additionalProps.isNitro = isNitro;
    } else {
        additionalProps.hasNoSubscription = !hasSubscription;
    }

    return isGranted ? <Component {...props} {...additionalProps} /> : <GamificationMarketing {...additionalProps} title={title} />;
};

export default withNoLeaderboardFallback;
