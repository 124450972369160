import React from 'react';

import { Translator } from 'library/Translator';
import { composeValidators } from 'components/Inputs/Validators';

import { announcementItems, errorsTranslations } from '../constants';

const getOutOfRangeError = (min, max) => (
    <Translator tKey={errorsTranslations.get('out-of-range')} options={{ min, max }} />
);

const minValue = (min, max) => (value) => {
    return value >= min ? undefined : getOutOfRangeError(min, max);
};

const maxValue = (min, max) => (value) => {
    return value <= max ? undefined : getOutOfRangeError(min, max);
};

const frequency = (duration, compareRate) => (value) => {
    return duration > value * compareRate ? undefined : (
        <Translator tKey={errorsTranslations.get('frequency-duration')} />
    );
};

export const createDurationValidator = (unit, settingType) => {
    // if the settingtype is CONTEXTUAL_FEEDBACK Minute 1-5, Second 30-300
    if (settingType === announcementItems.CONTEXTUAL_FEEDBACK) {
        const max = unit === 'MINUTE' ? 5 : 300;
        const min = unit === 'MINUTE' ? 1 : 30;

        return composeValidators(minValue(min, max), maxValue(min, max));
    }

    const max = unit === 'MINUTE' ? 60 : 72;
    const min = 1;

    return composeValidators(minValue(min, max), maxValue(min, max));
};

export const createFrequencyValidator = (unit, duration, compareRate, settingType) => {
    return composeValidators(createDurationValidator(unit, settingType), frequency(duration, compareRate));
};
