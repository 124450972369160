import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Grid, applySearchValue, useAvailableFilters, applyGridFilters } from 'components/Common/Grid';
import {Paginate} from "library/Paginate";
import {PAGE_DATA, PAGE_DEFAULT} from "constants/paginationDefault";

import './DXSFeedList.scss';

const PREMIUM_MINIMUM_RETRIEVAL_TIME = 5;

const gridOptions = [
    {
        text: 'common__service-account',
        property: 'serviceAccountName',
        flex: 40,
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__brand',
        property: 'brandName',
        flex: 20,
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__dxs-premium',
        property: 'isDXSPremiumText',
        flex: 10,
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-centered-cell',
    },
    {
        text: 'dxs_feeds__grid__header--linked-accounts',
        property: 'linkedAccountsCount',
        flex: 10,
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
    {
        text: 'common__actions',
        property: 'Actions',
        flex: 10,
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
];

const searchByProps = ['serviceAccountName'];
const filterOptions = {
    brandName: {
        allText: 'common__all-brands',
    },
    isDXSPremiumText: {
        allText: 'common__all',
    },
};

const feedsToRows = ({ feeds, t }) =>
    feeds.map((feed) => ({
        ...feed,
        isDXSPremium: feed.minimumRetrievalTime === PREMIUM_MINIMUM_RETRIEVAL_TIME,
        isDXSPremiumText: feed.minimumRetrievalTime === PREMIUM_MINIMUM_RETRIEVAL_TIME ? t('common__yes') : t('common__no'),
        linkedAccountsCount: feed.linkedAccountsCount.toString(),
        Actions: <Link to={`feeds/${feed.serviceAccountID}`}>{t('common__view-edit')}</Link>,
    }));

export const DXSFeedsList = ({ feeds, searchValue, isLoading }) => {
    const [filteredRows, setFilteredRows] = useState([]);
    const [isRendering, setIsRendering] = useState(false);
    const [availableFilters, setAvailableFilters] = useState([]);
    const [gridFilters, setGridFilters] = useState({});
    const { t } = useTranslation();

    const [pageNumber, setPageNumber] = useState(PAGE_DEFAULT.page);
    const [itemsPerPage, setItemsPerPage] = useState(PAGE_DEFAULT.recordsPerPage);

    const paginatedPages = useMemo(()=> filteredRows
        .slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage), [filteredRows, pageNumber, itemsPerPage])

    const onPaginationChange = useCallback(({ page, recordsPerPage }) => {
        setPageNumber(page);
        setItemsPerPage(recordsPerPage);
    }, []);

    const rows = useMemo(() => feedsToRows({ feeds, t }), [feeds]);
    useAvailableFilters(filterOptions, rows, setGridFilters, setAvailableFilters);

    useEffect(() => {
        setIsRendering(true);
        const filteredFeeds = applyGridFilters(filterOptions, rows, gridFilters);
        setFilteredRows(applySearchValue(filteredFeeds, searchByProps, searchValue));
        setIsRendering(false);
    }, [rows, gridFilters, searchValue]);

    return (
        <div className="accounts-list">
            <Grid
                headers={gridOptions}
                rows={paginatedPages}
                isLoading={isLoading || isRendering}
                rowKey="serviceAccountID"
                noRecordsMessage={t('dxs_feeds__no-feeds-found')}
                availableFilters={availableFilters}
                filters={gridFilters}
                onFiltersChange={setGridFilters}
            />
            {
                !(isLoading || isRendering) &&
                    <Paginate
                        className="dxs-feeds-paginate"
                        page={pageNumber}
                        recordsPerPage={itemsPerPage}
                        pageSizes={PAGE_DATA.PAGE_SIZES_ADMIN}
                        total={filteredRows.length}
                        onChange={onPaginationChange}
                        hideSinglePage
                    />
            }
        </div>
    );
};
