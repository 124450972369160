import React, { useMemo } from 'react';
import { cond, T } from 'ramda';
import { useTranslation } from 'react-i18next';

import { storeHierarchyNodeTypes } from 'constants/storeHierarchy';
import { getStoreNexeoDeviceSerialNumber } from '../helpers/getStoreNexeoDeviceSerialNumber';
import { DEVICE_ERROR_TEXTS } from '../constants';

const GroupText = ({ item }) => (
    <div className="text-connect__stores__tree-text text-connect__stores__tree-text--group">{item.Name}</div>
);

const StoreText = ({ item }) => {
    const { t } = useTranslation();

    const storeName = useMemo(() => {
        const { storeStatus } = item;
        const errorText = storeStatus?.status ? '' : `${t(DEVICE_ERROR_TEXTS[storeStatus.errorCode])}`;

        const storeDetails = `${item.StoreNumber} - ${item.Name} - ${getStoreNexeoDeviceSerialNumber(item)}`;

        return (
            <>
                {errorText && <span className="text-connect__stores__tree-text__store-name--error">{errorText}</span>}
                {errorText && ' - '}
                <span className="text-connect__stores__tree-text__store-name--details">{storeDetails}</span>
            </>
        );
    }, [item]);

    return (
        <>
            <div className="text-connect__stores__tree-text text-connect__stores__tree-text--store">
                <span className="text-connect__stores__tree-text__store-name">{storeName}</span>
            </div>
        </>
    );
};

export const TreeText = cond([
    [({ item }) => item.Type === storeHierarchyNodeTypes.GROUP, GroupText],
    [({ item }) => item.Type === storeHierarchyNodeTypes.STORE, StoreText],
    [T, () => null],
]);
