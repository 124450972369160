import React from 'react';
import { useTranslation } from 'react-i18next';
import { SingleSelect as Select } from 'library/SingleSelect';

import './OverrideCountry.scss';

export const OverrideCountry = ({ countriesOptions, chosenCountryId, onCountryChange }) => {
    const { t } = useTranslation();

    return (
        <div className="hme-systems-override-country">
            <Select
                value={chosenCountryId}
                label={t('common__select-country')}
                variants={['label-inside']}
                onChange={onCountryChange}
                items={countriesOptions}
                isRequired
            />
        </div>
    );
};
