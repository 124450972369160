import { useEffect, useState } from 'react';
import { prop, uniqBy } from 'ramda';

import timeZoneApi from 'api/timeZoneApi';
import { addErrorNotification } from 'services/Notifications';

const DEFAULT_TIME_ZONE = { text: 'stores__modal__select_timezone', value: '' };

const getTimeZonesFromApi = async (setTimeZones, setTimeZonesLoading) => {
    try {
        setTimeZonesLoading(true);
        const data = await timeZoneApi.getAll();
        const timeZonesList = uniqBy(prop('ZoneName'), data).map(({ Name: text, ZoneName: value }) => ({ text, value }));

        setTimeZones([ ...timeZonesList]);
    } catch (error) {
        addErrorNotification('common__error--internal-server');
    } finally {
        setTimeZonesLoading(false);
    }
};

export const useTimeZoneSelect = (defaultTimeZoneValue = DEFAULT_TIME_ZONE) => {
    const [isTimeZonesLoading, setTimeZonesLoading] = useState(true);
    const [timeZones, setTimeZones] = useState([]);

    useEffect(() => {
        getTimeZonesFromApi(setTimeZones, setTimeZonesLoading);
        return () => setTimeZones([]);
    },
    [setTimeZones, setTimeZonesLoading, defaultTimeZoneValue]);

    return {
        isTimeZonesLoading,
        timeZones
    };
};
