import React, { useCallback, useEffect, useRef } from 'react';
import { $getSelection, $createParagraphNode, $isRangeSelection } from 'lexical';
import { $wrapNodes } from '@lexical/selection';
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from '@lexical/list';

import { PureButton } from 'library/PureButton';

import { ReactComponent as ParagraphIcon } from 'assets/icons/paragraph.svg';
import { ReactComponent as OrderedListIcon } from 'assets/icons/ordered-list.svg';
import { ReactComponent as UnorderedListIcon } from 'assets/icons/unordered-list.svg';

export const BlockOptionsDropdownList = ({ editor, blockType, toolbarRef, toolbarActionsDropdowRef, setShowBlockOptionsDropDown }) => {
    const dropDownRef = useRef(null);

    useEffect(() => {
        const toolbarActionsDropdow = toolbarActionsDropdowRef.current;
        const dropDown = dropDownRef.current;

        if (toolbarActionsDropdow !== null && dropDown !== null) {
            const { top, left } = toolbarActionsDropdow.getBoundingClientRect();
            dropDown.style.top = `${top + 40}px`;
            dropDown.style.left = `${left}px`;
        }
    }, []);

    useEffect(() => {
        const dropDown = dropDownRef.current;
        const toolbar = toolbarRef.current;

        const handle = (event) => {
            if (!dropDown || !toolbar) {
                return;
            }

            const target = event.target;

            if (!dropDown.contains(target) && !toolbar.contains(target)) {
                setShowBlockOptionsDropDown(false);
            }
        };

        document.addEventListener('click', handle);

        return () => {
            document.removeEventListener('click', handle);
        };
    }, [setShowBlockOptionsDropDown]);

    const formatParagraph = useCallback(() => {
        if (blockType !== 'paragraph') {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $wrapNodes(selection, $createParagraphNode);
                }
            });
        }
        setShowBlockOptionsDropDown(false);
    }, [blockType, editor]);

    const formatBulletList = useCallback(() => {
        if (blockType !== 'ul') {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND);
        }
        setShowBlockOptionsDropDown(false);
    }, [editor, blockType]);

    const formatNumberedList = useCallback(() => {
        if (blockType !== 'ol') {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND);
        }
        setShowBlockOptionsDropDown(false);
    }, [editor, blockType]);

    return (
        <div className="editor-dropdown" ref={dropDownRef}>
            <PureButton className="item" onClick={formatParagraph}>
                <ParagraphIcon className="icon" />
                <span className="text">Normal</span>
                {blockType === 'paragraph' && <span className="active" />}
            </PureButton>
            <PureButton className="item" onClick={formatBulletList}>
                <UnorderedListIcon className="icon" />
                <span className="text">Bullet List</span>
                {blockType === 'ul' && <span className="active" />}
            </PureButton>
            <PureButton className="item" onClick={formatNumberedList}>
                <OrderedListIcon className="icon" />
                <span className="text">Numbered List</span>
                {blockType === 'ol' && <span className="active" />}
            </PureButton>
        </div>
    );
};
