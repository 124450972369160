import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CUSTOMER_NOTIFICATION_TYPE_IDS, DEFAULT_TEXT_LANGUAGE } from 'constants/customerNotifications';

import { useCustomerNotificationContext } from '../context';
import { getNotificationByTypeId, notificationToRow } from '../helpers';
import { useCurrentText } from './useCurrentText';
import { DateLib } from '@hme-cloud/utility-common';

export const getAnnouncmentText = (enabledNotifications) => {
    if (!enabledNotifications || enabledNotifications.length < 0) return null;

    return getNotificationByTypeId({
        notifications: enabledNotifications,
        notificationTypeId: Number(CUSTOMER_NOTIFICATION_TYPE_IDS.announcement)
    });
};

export const useAnnouncementCustomerNotification = () => {
    const { enabledNotifications } = useCustomerNotificationContext();

    const announcementNotification = useMemo(() => getAnnouncmentText(enabledNotifications), [enabledNotifications]);

    const text = useCurrentText(announcementNotification);

    return { text };
};

const TranslatedText = ({ text }) => {
    return <div className="tile__box-modal-grid-item-text" dangerouslySetInnerHTML={{ __html: text }} />;
};

export const usePastCustomerNotification = () => {
    const { i18n } = useTranslation();
    const { pastNotifications = [] } = useCustomerNotificationContext();

    const { resolvedLanguage } = i18n;
    const currentLanguage = resolvedLanguage.toLowerCase();

    const notifications = useMemo(() => {
        return pastNotifications.map((pastNotification) => {
            // we use DEFAULT_TEXT_LANGUAGE because backend guarantees that at least 'en-us' translation exists
            const translatedText = pastNotification.texts[currentLanguage] || pastNotification.texts[DEFAULT_TEXT_LANGUAGE];

            return {
                ...notificationToRow({ notification: pastNotification }),
                lastEnabledDate: new DateLib(pastNotification.enabledAt).format(DateLib.FORMAT_TYPES.DATE_SELECTOR_DEFAULT),
                translatedText: <TranslatedText text={translatedText} />
            };
        });
    }, [pastNotifications, currentLanguage]);

    return { pastNotifications: notifications };
};
