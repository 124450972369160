import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Title as LibraryTitle } from 'library/Title';
import { useResetPasswordRules, partnerRules, publicRules, isValidStatus, isInvalidStatus } from 'hooks/useResetPassword';
import { ResetWidget, RuleMessage } from 'library/Password';
import { Button } from 'library/Button';
import { Loader } from 'library/Loader';
import { InputComponent as Input } from 'components/Inputs';
import { isPartner } from 'services/Auth';

import './ChangePasswordForm.scss';

const DEFAULT_INPUT_VARIANTS = ['label-inline', 'label-inside-mobile-only'];
const BTN_VARIANTS = ['submit'];

export const ChangePasswordForm = ({ account, isPasswordChangeInProgress, onAccountChange, onSubmit, onCancel }) => {
    const { t } = useTranslation();

    const rules = useMemo(()=> isPartner() ? partnerRules : publicRules,[isPartner])

    const { currentPassword = '', newPassword = '', confirmNewPassword = '' } = account;

    const { isAllValid, isSomeInvalid } = useResetPasswordRules({ newPassword, confirmNewPassword });

    const isSubmitDisabled = !currentPassword || !isAllValid;

    const handleFieldUpdate = useCallback(
            ({ target }) => {
                if (isPasswordChangeInProgress) return;

                const { name, value } = target;

                onAccountChange((accountData) => ({
                    ...accountData,
                    [name]: value
                }));
            },
            [onAccountChange, isPasswordChangeInProgress]
    );

    const newPasswordInputVariants = useMemo(() => {
        const variants = [...DEFAULT_INPUT_VARIANTS];

        if (isAllValid) {
            variants.push('valid');
        }

        if (isSomeInvalid) {
            variants.push('invalid');
        }

        return variants;
    }, [isAllValid, isSomeInvalid]);

    const Title = useMemo(
            () => (
                <LibraryTitle>
                    {t('profile__account__change-password')}
                </LibraryTitle>
            ),
            [t]
    );

    const Rules = useMemo(
            () =>
                rules.map(({ tKey, rule }) => {
                    const status = rule(newPassword, confirmNewPassword);
                    const isValid = isValidStatus(status);
                    const isInvalid = isInvalidStatus(status);

                    return (
                        <RuleMessage
                            key={tKey}
                            message={t(tKey)}
                            isInvalid={isInvalid}
                            isValid={isValid}
                            className="hme-change-password-form__rule"
                        />
                    );
                }),
            [t, newPassword, confirmNewPassword]
    );

    const handleSubmit = useCallback(
            async (evt) => {
                evt.preventDefault();

                if (!isSubmitDisabled || isPasswordChangeInProgress) {
                    try {
                        await onSubmit();
                        onCancel();
                    } catch (err) {
                    // Do nothing when password update was unsuccessful
                    }
                }
            },
            [isSubmitDisabled, isPasswordChangeInProgress, onSubmit]
    );

    const FormBody = useMemo(
            () => (
                <>
                    <Input
                        value={currentPassword}
                        onChange={handleFieldUpdate}
                        universalOnChange
                        variants={DEFAULT_INPUT_VARIANTS}
                        label={t('my-account__account-info__current-password')}
                        name="currentPassword"
                        type="password"
                        autoComplete="current-password"
                        autoFocus
                    />
                    <Input
                        value={newPassword}
                        onChange={handleFieldUpdate}
                        universalOnChange
                        variants={newPasswordInputVariants}
                        label={t('my-account__account-info__new-password')}
                        name="newPassword"
                        type="password"
                        autoComplete="new-password"
                    />
                    <Input
                        value={confirmNewPassword}
                        onChange={handleFieldUpdate}
                        universalOnChange
                        variants={newPasswordInputVariants}
                        label={t('reset-pass__label--confirm')}
                        name="confirmNewPassword"
                        type="password"
                        autoComplete="off"
                    />
                </>
            ),
            [newPasswordInputVariants, currentPassword, newPassword, confirmNewPassword, t, handleFieldUpdate]
    );

    const FormFooter = useMemo(
            () => (
                <div className="hme-change-password-form__buttons">
                    <Button onClick={onCancel} disabled={isPasswordChangeInProgress}>
                        {t('common__cancel')}
                    </Button>
                    <Button
                        variants={BTN_VARIANTS}
                        disabled={isSubmitDisabled || isPasswordChangeInProgress}
                        className="hme-change-password-form__submit-btn"
                        type="submit"
                    >
                        {!isPasswordChangeInProgress ? (
                        t('profile__account__change-password')
                    ) : (
                        <span className="hme-change-password-form__loader-wrapper">
                            <Loader className="hme-change-password-form__btn-loader" />
                        </span>
                    )}
                    </Button>
                </div>
            ),
            [isSubmitDisabled, isPasswordChangeInProgress, onCancel, t]
    );

    return (
        <ResetWidget
            Title={Title}
            Rules={Rules}
            FormBody={FormBody}
            FormFooter={FormFooter}
            onSubmit={handleSubmit}
            className="hme-change-password-form"
        />
    );
};
