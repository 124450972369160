import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoField } from 'library/InfoField';
import { DateLib } from '@hme-cloud/utility-common';

import { StatusIcon } from './StatusIcon';
import { upgradeStatuses } from '../../../constants';

import './UpgradeInfoStatus.css';

const dateFormatting = `${DateLib.FORMAT_TYPES.DATE_SELECTOR_DEFAULT} ${DateLib.FORMAT_TYPES.SHORTENED_HOURS_MINUTES}`;

export const UpgradeInfoStatus = ({ scheduledUpgrade = {}, upgradeStatus }) => {
    const { t } = useTranslation();

    const shouldShowIcon = useMemo(
        () => [upgradeStatuses.COMPLETED, upgradeStatuses.SCHEDULED].includes(upgradeStatus),
        [upgradeStatus],
    );

    return (
        <div className="view-device-upgrade-info-status">
            <InfoField label={t('common__status')} className="view-device-upgrade-info-status-field">
                <span>{upgradeStatus}</span>
                {shouldShowIcon && <StatusIcon upgradeStatus={upgradeStatus} />}
            </InfoField>
            <InfoField label={t('common__created-by')}>
                <span>{scheduledUpgrade.CreatedBy}</span>
                <span>{new DateLib(scheduledUpgrade.CreatedDate).format(dateFormatting)}</span>
            </InfoField>
            <InfoField label={t('common__changed-by')}>
                <span>{scheduledUpgrade.UpdatedBy}</span>
                <span>{new DateLib(scheduledUpgrade.UpdatedDate).format(dateFormatting)}</span>
            </InfoField>
            {scheduledUpgrade.ApprovedBy && <InfoField label={t('common__reviewer')} className="view-device-upgrade-info-status-field">
                <span>{scheduledUpgrade.ApprovedBy}</span>
                <span>{new DateLib(scheduledUpgrade.UpdatedDate).format(dateFormatting)}</span>
            </InfoField>}
        </div>
    );
};
