export const GRID_HEADERS = [
    {
        text: 'common__user',
        property: 'User_EmailAddress',
        sortable: true,
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'apply-device-settings-new__target-grid__header__serial-number',
        property: 'Device_SerialNumber',
        sortable: true,
    },
    {
        text: 'common__store__number',
        property: 'Store_Number',
        sortable: true,
    },
    {
        text: 'common__store__name',
        property: 'Store_Name',
        sortable: true,
    },
    {
        text: 'common__lane-config',
        property: 'Device_LaneConfig_Name',
        sortable: true,
    },
    {
        text: 'common__device-server-type',
        property: 'DeviceServerType',
    },
    {
        text: 'common__device__version',
        property: 'Device_MainVersion',
        sortable: true,
    },
];


export const NEXEO_GRID_HEADERS = [
    {
        text: 'common__user',
        property: 'User_EmailAddress',
        sortable: true,
        className: 'hme-grid-cell-show-all-content',
    },
    {
        text: 'common__device__serial-number',
        property: 'Device_SerialNumber',
        sortable: true,
    },
    {
        text: 'common__device__product-id',
        property: 'Device_Product_ID',
        sortable: true,
    },
    {
        text: 'common__store__number',
        property: 'Store_Number',
        sortable: true,
    },
    {
        text: 'common__store__name',
        property: 'Store_Name',
        sortable: true,
    },
    {
        text: 'common__lane-config',
        property: 'Device_LaneConfig_Name',
        sortable: true,
    },
    {
        text: 'common__device__version',
        property: 'Device_MainVersion',
        sortable: true,
    },
    {
        text: 'common__settings__version',
        property: 'Device_SettingVersion',
        sortable: true,
        headerClassName: 'apply-device-settings__settings__version__header',
    }
];
