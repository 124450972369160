import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputComponent as Input } from 'components/Inputs';
import { accountTypes } from 'constants/accountTypes';
import { Title } from 'library/Title';
import { ErrorLabel } from 'library/ErrorLabel';
import { AutoCompleteInput } from 'library/AutoCompleteInput';
import { checkIsNexeoDeviceType } from '../../../helpers/checkIsNexeoDeviceType';
import {
    accountsSuggestionsHeaders,
    storesSuggestionsHeaders,
    nexeoDevicesSuggestionsHeaders,
    mobileNexeoDevicesSuggestionsHeaders,
    zoomNitroDevicesSuggestionsHeaders,
    mobileZoomNitroDevicesSuggestionsHeaders,
} from './constants/suggestionsHeaders';

import './DeviceFilterForm.scss';

export const DeviceFilterForm = ({
    title,
    number,
    permissionType,
    accounts,
    accountSearch,
    selectedAccount,
    isAccountsLoading,
    isAccountLoading,
    isDevicesLoading,
    onAccountChange,
    onAccountSelected,
    accountStores,
    selectedDeviceType,
    devices,
    formErrors,
    storeNumberSearch,
    deviceIdentifier,
    onStoreNumberChange,
    onStoreNumberSelected,
    onDeviceIdentifierChange,
    onDeviceSelected,
}) => {
    const { t } = useTranslation();
    const isPublic = permissionType === accountTypes.PUBLIC;
    const isNexeoDeviceType = checkIsNexeoDeviceType(selectedDeviceType);

    const devicesSuggestionsHeaders = isNexeoDeviceType ? nexeoDevicesSuggestionsHeaders : zoomNitroDevicesSuggestionsHeaders;
    const devicesMobileSuggestionsHeaders = isNexeoDeviceType
        ? mobileNexeoDevicesSuggestionsHeaders
        : mobileZoomNitroDevicesSuggestionsHeaders;

    const checkStoreDisabled = (device) => {
        // public users are not allowed to create snapshots from DTDS devices
        if (isPublic) {
            return device.IoTDevice_ID === null;
        }

        return false;
    };

    const checkDeviceDisabled = (device) => {
        // public users are not allowed to create snapshots from DTDS devices
        if (isPublic) {
            return device.iotDeviceId === null;
        }

        return false;
    };

    return (
        <div className="create-snapshot-device-filter">
            {title && (
                <Title>
                    {number && `${number}.  `}
                    {t(title)}
                </Title>
            )}
            {!isPublic && (
                <AutoCompleteInput
                    suggestions={accounts}
                    headers={accountsSuggestionsHeaders}
                    rowKey="User_EmailAddress"
                    className="device-filter-form-input hme-source-device-account-input"
                    placeholder={'abc@hme.com'}
                    isLoading={isAccountsLoading}
                    noSuggestionText={t('device-upgrades__section__search__accounts__not-found')}
                    Component={Input}
                    onSuggested={onAccountSelected}
                    value={accountSearch}
                    onChange={onAccountChange}
                    label="settings_snapshots__create_snapshot__form--account"
                />
            )}

            {isAccountLoading ? (
                <div className="create-snapshot-device-filter--loading">{t('common__loading')}</div>
            ) : (
                <div className="hme-input-container create-snapshot-device-filter--store-number">
                    <AutoCompleteInput
                        suggestions={accountStores}
                        headers={storesSuggestionsHeaders}
                        rowKey="Store_Number"
                        className="device-filter-form-input hme-source-device-store-input"
                        placeholder={'12345'}
                        disabled={!selectedAccount}
                        isLoading={false}
                        noSuggestionText={t('No stores found')}
                        Component={Input}
                        onSuggested={onStoreNumberSelected}
                        value={storeNumberSearch}
                        onChange={onStoreNumberChange}
                        minValueLength={1}
                        label="settings_snapshots__create_snapshot__form--store-number"
                        checkDisabled={checkStoreDisabled}
                    />
                </div>
            )}
            <div className="create-snapshot-subhead">
                <p>{t('settings_snapshots__create_snapshot__form--or')}</p>
            </div>

            <div className="create-snapshot-setting-info-form-box create-snapshot-setting-info-form-box--serial-number">
                <div className="hme-input-container create-snapshot-device-filter--serial-number">
                    <AutoCompleteInput
                        suggestions={devices}
                        headers={devicesSuggestionsHeaders}
                        mobileHeaders={devicesMobileSuggestionsHeaders}
                        rowKey="id"
                        className="device-filter-form-input hme-source-device-serial-number-input"
                        disabled={false}
                        isLoading={isDevicesLoading}
                        noSuggestionText={t('common__no-devices--found')}
                        Component={Input}
                        onSuggested={onDeviceSelected}
                        value={deviceIdentifier?.value}
                        onChange={onDeviceIdentifierChange}
                        label={
                            isNexeoDeviceType
                                ? 'settings_snapshots__create_snapshot__form--source-device-serial-number-product-id'
                                : 'settings_snapshots__create_snapshot__form--source-device-serial-number'
                        }
                        checkDisabled={checkDeviceDisabled}
                    />
                </div>
                {formErrors.sourceDeviceIdentifier && (
                    <ErrorLabel>{t(formErrors.sourceDeviceIdentifier)}</ErrorLabel>
                )}
            </div>
        </div>
    );
};
