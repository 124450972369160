import { useEffect, useState, useMemo, useCallback } from 'react';
import { PAGE_DEFAULT } from 'constants/paginationDefault';
import { allSSOConfig } from 'pages/SSOManagement/services/ssoManagementService';

export const useSSOManagementList = () => {
    const [ssoFullList, setSSOFullList] = useState([]);
    const [pageNumber, setPageNumber] = useState(PAGE_DEFAULT.page);
    const [itemsPerPage, setItemsPerPage] = useState(PAGE_DEFAULT.recordsPerPage);
    const [isLoading, setIsLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);

    const onPaginationChange = useCallback(({ page, recordsPerPage }) => {
        setPageNumber(page);
        setItemsPerPage(recordsPerPage);
    }, []);

    const ssoPaginatedList = useMemo(
            () => ssoFullList.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage),
            [ssoFullList, pageNumber, itemsPerPage]
    );

    const fetchSSODetails = async () => {
        setIsLoading(true);
        // Fetch SSO Details
        try {
            const data = await allSSOConfig();
            setSSOFullList(data);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        (async function fetchSSO() {
            await fetchSSODetails();
        }());
    }, []);

    useEffect(() => {
        if (!ssoFullList) return;

        setTotalRows(ssoFullList.length);
    }, [ssoFullList]);

    return {
        ssoFullList,
        ssoPaginatedList,
        pageNumber,
        itemsPerPage,
        isLoading,
        totalRows,
        onPaginationChange,
        fetchSSODetails
    };
};
