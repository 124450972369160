import { useEffect, useCallback } from 'react';
import { getBrand } from 'services/Brands';
import { useAsync } from 'hooks/useAsync';

export const useLoadBrand = (brandID) => {
    const { run: runLoadBrand, isLoading: isBrandLoading = false, data: brand, error: brandLoadingError } = useAsync();

    const loadBrand = useCallback((brandID) => {
        runLoadBrand(getBrand(brandID));
    }, []);

    useEffect(() => { loadBrand(brandID); }, []);

    return {
        brand,
        isBrandLoading,
        brandLoadingError,
        loadBrand,
    };
};
