import { useCallback, useEffect, useMemo, useState } from 'react';
import { equals } from 'ramda';

const defaultArray = [];
const defaultObject = {};

export const useSortFilterModal = (
        availableFilters = defaultArray,
        filters = defaultObject,
        onFiltersChange,
        onSortModalClose,
        sortSelection = defaultObject,
        sortOptions = defaultArray,
        onSortChange,
        onFiltersAndSortChange,
        onFiltersAndSortReset
) => {
    const [selectedFilters, setSelectedFilters] = useState(filters);
    const [selectedSortValue, setSelectedSortValue] = useState(sortSelection);

    useEffect(() => {
        setSelectedFilters(filters);
        setSelectedSortValue(sortSelection);
    }, [filters, sortSelection]);

    const onFilterSelect = useCallback((newFilters) => {
        setSelectedFilters({ ...selectedFilters, ...newFilters });
    }, [selectedFilters, setSelectedFilters]);

    const onSortValueChange = useCallback((newSortValue) => {
        setSelectedSortValue(newSortValue);
    }, [setSelectedSortValue]);

    const onFiltersApply = useCallback(() => {
        if (onFiltersChange ) {
            const newFiltersQuery = { ...filters, ...selectedFilters };
            onFiltersChange(newFiltersQuery);
        }
        if (onSortChange) {
            onSortChange(selectedSortValue);
        }

        if (onFiltersAndSortChange) {
            onFiltersAndSortChange({
                selectedFilters: { ...filters, ...selectedFilters },
                selectedSortValue
            });
        }

        onSortModalClose();
    }, [filters, selectedFilters, onFiltersChange, selectedSortValue, onSortChange, sortSelection, onSortModalClose]);

    const onModalClose = useCallback(() => {
        setSelectedFilters(filters);
        setSelectedSortValue(sortSelection);
        onSortModalClose();
    }, [filters, setSelectedFilters, onSortModalClose, sortSelection]);

    const needToShowSorting = useMemo(() =>
        Boolean(Object.keys(sortSelection).length && sortOptions.length),
    [sortSelection, sortOptions]);

    const needToShowFilters = useMemo(() =>
        Boolean(availableFilters.length && Object.keys(filters).length),
    [availableFilters, filters]);

    const isApplyButtonDisabled = useMemo(() => {
        return equals(filters, selectedFilters) && equals(selectedSortValue, sortSelection);
    }, [filters, selectedFilters, selectedSortValue, sortSelection]);


    return {
        selectedFilters,
        onFilterSelect,
        onFiltersApply,
        needToShowSorting,
        needToShowFilters,
        onModalClose,
        selectedSortValue,
        onSortValueChange,
        isApplyButtonDisabled
    };
};
