import React, { createContext, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';

import { useScreenWidthMatch, useUnreadNotifications, useRefreshToken } from './slices';

const GlobalStateContext = createContext();
GlobalStateContext.displayName = 'GlobalStateContext';

export const GlobalStateProvider = ({ children }) => {
    const screenType = useScreenWidthMatch();
    const unreadNotifications = useUnreadNotifications();
    const refreshToken = useRefreshToken();

    const value = useMemo(() => ({
        screenType,
        unreadNotifications,
        refreshToken
    }), [screenType, unreadNotifications, refreshToken]);

    return (
        <GlobalStateContext.Provider value={value}>{children}</GlobalStateContext.Provider>
    );
};

GlobalStateProvider.propTypes = {
    children: PropTypes.element.isRequired
};

export const useGlobalState = () => {
    const context = useContext(GlobalStateContext);

    if (!context) {
        throw new Error('`useGlobalState` should be used inside `GlobalStateProvider`');
    }

    return context;
};

export const useScreenType = () => useGlobalState().screenType;
