import React, { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { SearchInput } from 'components/Inputs';
import { Button } from 'library/Button';

import { upgradeStatuses } from '../../../constants';

import './DevicesSearch.css';

export const DevicesSearch = ({ devices, upgradeStatus, selectedDevices, search, onSearchChange, onCancel }) => {
    const { t } = useTranslation();

    const isUpgradeScheduled = upgradeStatus === upgradeStatuses.SCHEDULED;

    const tKey = useMemo(
        () =>
            devices.length === 1
                ? 'device-upgrades__section__search_upgrade__count-results--single'
                : 'device-upgrades__section__search_upgrade__count-results--multiple',
        [devices],
    );

    const selectedDeviceUpgradeIDs = useMemo(() => selectedDevices.map((sd) => sd.DeviceUpgradeID), [selectedDevices]);
    const devicesCompleted = useMemo(
        () => devices.filter((d) => d.Status === upgradeStatuses.COMPLETED),
        [devices, upgradeStatuses],
    );

    return (
        <div className="view-device-upgrade-filters-row">
            <div className="view-device-upgrade-filters-panel">
                <span className="view-device-upgrade-count">
                    {isUpgradeScheduled ? (
                        <Trans i18nKey={tKey} count={devices.length}>
                            <span className="view-device-upgrade-count-label">{{ count: devices.length }}</span> devices
                            scheduled for upgrade
                        </Trans>
                    ) : (
                        <Trans
                            i18nKey={'device-upgrades__section__search_upgrade__count-completed-results'}
                            count={devices.length}
                            countCompleted={devicesCompleted.length}
                        >
                            <span className="view-device-upgrade-count-label">
                                {{ countCompleted: devicesCompleted.length }}
                            </span>{' '}
                            of <span className="view-device-upgrade-count-label">{{ count: devices.length }}</span>{' '}
                            devices upgraded
                        </Trans>
                    )}
                </span>
                {selectedDeviceUpgradeIDs.length > 0 && (
                    <Button onClick={() => onCancel(selectedDeviceUpgradeIDs)}>
                        {t('device-upgrades__cancel-selected-devices-button')}
                    </Button>
                )}
            </div>
            <SearchInput showClearIcon value={search} placeholder="Search for Device" onChange={onSearchChange} />
        </div>
    );
};
