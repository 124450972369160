import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';
import { Grid } from 'components/Common/Grid';

import { CONFIRM_UPGRADE_VERSIONS_GRID_HEADERS, ALL_VERSIONS_APPROVED_T_KEY } from '../constants';
import {
    formatAddUpgradeVersionsRowsWithAllApproved,
    formatRemoveUpgradeVersionsRowsWithAllApproved,
} from '../helpers';

import './ConfirmDetails.scss';

export const ConfirmDetails = ({
    addRemoveUpgradeVersionRows,
    initialAllVersionsApprovedMap,
    allVersionsApprovedMap,
    brandName,
}) => {
    const { t } = useTranslation();

    const { addUpgradeVersionsRows, removeUpgradeVersionsRows } = addRemoveUpgradeVersionRows;

    const addUpgradeVersionsRowsWithAllVersionsApproved = formatAddUpgradeVersionsRowsWithAllApproved(
        addUpgradeVersionsRows,
        initialAllVersionsApprovedMap,
        allVersionsApprovedMap,
        t(ALL_VERSIONS_APPROVED_T_KEY),
    );

    const removeUpgradeVersionsRowsWithAllVersionsApproved = formatRemoveUpgradeVersionsRowsWithAllApproved(
        removeUpgradeVersionsRows,
        initialAllVersionsApprovedMap,
        allVersionsApprovedMap,
        t(ALL_VERSIONS_APPROVED_T_KEY),
    );

    return (
        <div className="firmware-management-view__confirm-details">
            <div className="firmware-management-view__confirm-details__subtitle">
                {t('firmware-management__popup__save-versions--confirm-message')}
            </div>
            <div className="firmware-management-view__confirm-details__info">
                <Title>{t('firmware-management__popup__save-versions--title', { brandName })}</Title>
                <div className="firmware-management-view__confirm-details__sections">
                    <div className="firmware-management-view__confirm-details__section">
                        <div className="firmware-management-view__confirm-details__action">{t('common__remove')}</div>
                        <Grid
                            headers={CONFIRM_UPGRADE_VERSIONS_GRID_HEADERS}
                            rows={removeUpgradeVersionsRowsWithAllVersionsApproved}
                            noRecordsMessage={t('firmware-management__grid__no-changes')}
                        />
                    </div>
                    <div className="firmware-management-view__confirm-details__section">
                        <div className="firmware-management-view__confirm-details__action">{t('common__add')}</div>
                        <Grid
                            headers={CONFIRM_UPGRADE_VERSIONS_GRID_HEADERS}
                            rows={addUpgradeVersionsRowsWithAllVersionsApproved}
                            noRecordsMessage={t('firmware-management__grid__no-changes')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
