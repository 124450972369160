import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationsList } from 'library/NotificationsList';
import { Loader } from 'library/Loader';
import { getAllDevices } from 'services/Device';
import { confirmMerge } from 'services/Store';
import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { SourceDeviceSection } from './SourceDeviceSection';
import { DestinationDeviceSection } from './DestinationDeviceSection';
import { Popup } from './Popup';
import { Header } from './Header';
import { Footer } from './Footer';
import { CommonConstants } from 'Constants';

import './MergeDevices.scss';

const loadDevices = async ({ deviceStoreID, setIsLoading, setStoreDevices, deviceFilter = {}, onLoadDevices, t }) => {
    const { deviceType: { zoomNitro: { name: zoomNitroName, id: zoomNitroID, minMainVersion: zoomMinMainVersion } } } = CommonConstants;
    try {
        setIsLoading(true);
        const results = await getAllDevices({
            ...deviceFilter,
            deviceStoreID,
            orderBy: 'deviceSerialNumber',
            orderDirection: 'asc',
        });

        const devices = (results || []).map((d) => {
            const deviceDetails = {
                ...d,
                Device_Status: d.Device_IsActive
                    ? t('common__device__status--online')
                    : t('common__device__status--offline'),
            };
            if (d.Device_DeviceType_ID == zoomNitroID && d.Device_MainVersion[0] >= zoomMinMainVersion) {
                deviceDetails.DeviceType_Name = zoomNitroName;
            }
            return deviceDetails;
        });

        setStoreDevices(devices);
        onLoadDevices(devices);
    } catch (e) {
        addErrorNotification('common__error-processing-request');
        setStoreDevices([]);
    }

    setIsLoading(false);
};

const mergeStoreDevices = async ({ sourceDevice, destinationDevice, destinationStore, resetAll, setIsLoading, setSourceStore, t }) => {
    setIsLoading(true);

    try {
        await confirmMerge({ sourceDevice, destinationDevice, destinationStore });

        addSuccessNotification(t('merge_devices__merge-process--success'));

        setSourceStore(null);
    } catch (err) {
        addErrorNotification(t('merge_devices__merge-process--failed'));
    }

    resetAll();

    setIsLoading(false);
};

export const MergeDevices = ({ permissionType }) => {
    const [sourceStore, setSourceStore] = useState(null);
    const [destinationStore, setDestinationStore] = useState(null);
    const [sourceDevice, setSourceDevice] = useState(null);
    const [destinationDevice, setDestinationDevice] = useState(null);
    const [isPopupShown, setIsPopupShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const onMerge = useCallback(() => {
        setIsPopupShown(true);
    }, []);

    const onMergeConfirm = useCallback(() => {
        setIsPopupShown(false);
        mergeStoreDevices({
            sourceDevice,
            destinationDevice,
            destinationStore,
            resetAll,
            setIsLoading,
            setSourceStore,
            t,
        });
    }, [sourceDevice, destinationDevice]);

    const onMergeCancel = useCallback(() => {
        setIsPopupShown(false);
    }, []);

    // reset store and devices on source store changing
    useEffect(() => {
        setSourceDevice(null);
    }, [sourceStore]);

    useEffect(() => {
        setDestinationStore(null);
    }, [sourceDevice]);

    const checkSelectedSourceDevice = useCallback(
        (device) => (device && sourceDevice && device.Device_ID == sourceDevice.Device_ID),
    [sourceDevice]);

    const checkSelectedDestinationDevice = useCallback(
        (device) => (device && destinationDevice && device.Device_ID == destinationDevice.Device_ID),
    [destinationDevice]);

    useEffect(() => {
        setDestinationDevice(null);
    }, [destinationStore]);

    const resetAll = useCallback(() => {
        setDestinationDevice(null);
        setDestinationStore(null);
        setSourceDevice(null);
    }, []);

    const onLoadSourceDevices = useCallback((devices) => {
        if (devices.length === 1) {
            setSourceDevice(devices[0]);
        }
    }, []);

    const onLoadDestinationDevices = useCallback((devices) => {
        if (devices.length === 1) {
            setDestinationDevice(devices[0]);
        }
    }, []);

    if (isLoading) {
        return (
            <div className="hme-components merge-devices-wrapper loading">
                <NotificationsList />
                <Loader />
            </div>
        );
    }

    return (
        <div className="hme-components merge-devices-wrapper">
            <NotificationsList />
            <div className="merge-devices">
                <div className="merge-devices-content">
                    <Header />
                    <div className="merge-devices-sections">
                        <SourceDeviceSection
                            loadDevices={loadDevices}
                            sourceStore={sourceStore}
                            sourceDevice={sourceDevice}
                            onStoreSelection={setSourceStore}
                            onDeviceSelection={setSourceDevice}
                            onLoadDevices={onLoadSourceDevices}
                            checkSelected={checkSelectedSourceDevice}
                        />
                        <DestinationDeviceSection
                            loadDevices={loadDevices}
                            destinationStore={destinationStore}
                            sourceDevice={sourceDevice}
                            destinationDevice={destinationDevice}
                            onStoreSelection={setDestinationStore}
                            onDeviceSelection={setDestinationDevice}
                            isDisabled={!sourceDevice}
                            onLoadDevices={onLoadDestinationDevices}
                            permissionType={permissionType}
                            sourceStore={sourceStore}
                            checkSelected={checkSelectedDestinationDevice}
                        />
                    </div>
                    {sourceDevice && destinationDevice && (
                        <Popup
                            sourceDevice={sourceDevice}
                            destinationDevice={destinationDevice}
                            onConfirm={onMergeConfirm}
                            onCancel={onMergeCancel}
                            showPopup={isPopupShown}
                        />
                    )}
                </div>
                <Footer isSubmitEnabled={sourceDevice && destinationDevice} onMerge={onMerge} />
            </div>
        </div>
    );
};
