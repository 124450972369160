/* eslint-disable complexity */
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';
import { ErrorLabel } from 'library/ErrorLabel';
import { InputComponent as Input } from 'components/Inputs';
import { isFormFieldDisabled } from '../../helpers';

import './AccountOwnerInfo.scss';

export const AccountOwnerInfo = ({ initialValues, formErrors, onChange, isReadOnly }) => {
    const [firstName, setFirstName] = useState(initialValues.firstName || '');
    const [lastName, setLastName] = useState(initialValues.lastName || '');
    const [emailAddress, setEmailAddress] = useState(initialValues.emailAddress || '');
    const [emailAddressConfirm, setEmailAddressConfirm] = useState(initialValues.emailAddressConfirm || '');

    const { t } = useTranslation();
    const isFieldDisabled = useMemo(() => isFormFieldDisabled(), []);

    useEffect(() => {
        onChange({
            firstName,
            lastName,
            emailAddress,
            emailAddressConfirm
        });
    }, [firstName, lastName, emailAddress, emailAddressConfirm]);

    useEffect(()=>{
        setFirstName(initialValues.firstName || '');
        setLastName(initialValues.lastName || '');
        setEmailAddress(initialValues.emailAddress || '');
    }, [initialValues]);

    return (
        <div className="hme-account-form-area">
            <Title>{t('account__section__title--account-owner-information')}</Title>
            <div className="hme-account-form-section">
                <Input
                    isReadOnly={isReadOnly}
                    value={firstName}
                    onChange={setFirstName}
                    isRequired={!isReadOnly}
                    maxLength={50}
                    isDisabled={isFieldDisabled}
                    label="common__user__first-name"
                    variants={['label-inside-mobile-only']}
                    id="firstName"
                />
                {formErrors.firstName && <ErrorLabel>{t(formErrors.firstName)}</ErrorLabel>}
            </div>
            <div className="hme-account-form-section">
                <Input
                    isReadOnly={isReadOnly}
                    value={lastName}
                    onChange={setLastName}
                    isRequired={!isReadOnly}
                    maxLength={50}
                    isDisabled={isFieldDisabled}
                    label="common__user__last-name"
                    variants={['label-inside-mobile-only']}
                    id="lastName"
                />
                {formErrors.lastName && <ErrorLabel>{t(formErrors.lastName)}</ErrorLabel>}
            </div>
            <div className="hme-account-form-section">
                <Input
                    isReadOnly={isReadOnly}
                    value={emailAddress}
                    onChange={setEmailAddress}
                    isRequired={!isReadOnly}
                    maxLength={200}
                    isDisabled={isFieldDisabled}
                    label="common__email-address"
                    variants={['label-inside-mobile-only']}
                    id="emailAddress"
                />
                {formErrors.emailAddress && <ErrorLabel>{t(formErrors.emailAddress)}</ErrorLabel>}
                {!formErrors.emailAddress && formErrors.emailAddressIsValid && (
                    <ErrorLabel>{t(formErrors.emailAddressIsValid)}</ErrorLabel>
                )}
            </div>

            {
                !isReadOnly &&
                <div className="hme-account-form-section">
                    <Input
                        value={emailAddressConfirm}
                        onChange={setEmailAddressConfirm}
                        isRequired={!isReadOnly}
                        maxLength={200}
                        isDisabled={isFieldDisabled}
                        label="account__section__form--confirm-email-address"
                        variants={['label-inside-mobile-only']}
                        id="emailAddressConfirm"
                    />
                    {formErrors.emailAddressConfirm && <ErrorLabel>{t(formErrors.emailAddressConfirm)}</ErrorLabel>}
                    {!formErrors.emailAddressConfirm && formErrors.emailAddressConfirmEqual && (
                        <ErrorLabel>{t(formErrors.emailAddressConfirmEqual)}</ErrorLabel>
                    )}
                </div>
            }

        </div>
    );
};
