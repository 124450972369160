import React, { useCallback } from 'react';
import { accountTypes } from 'constants/accountTypes';
import { Toggle } from 'library/Toggle';
import { SearchInput } from 'components/Inputs/SearchInput';

import './AppliedBySearch.scss';

export const USER_TYPES = {
    ALL: 'ALL',
    HME: 'HME',
    DISTRIBUTORS: 'Distributors',
    CUSTOMER: 'CUSTOMER',
};

const getUserTypeToggleItems = (permissionType) => {
    return [
        {
            value: USER_TYPES.ALL,
            text: 'All',
            className: 'apply-device-settings-status-page-applied-by-filter-all-item',
        },
        {
            value: USER_TYPES.HME,
            text: 'HME',
            className: 'apply-device-settings-status-page-applied-by-filter-hme-item',
        },
        permissionType === accountTypes.DISTRIBUTOR ? {
            value: USER_TYPES.DISTRIBUTORS,
            text: 'Distributors',
            className: 'apply-device-settings-status-page-applied-by-filter-distributors-item',
        } : null,
        {
            value: USER_TYPES.CUSTOMER,
            text: 'Customers',
            className: 'apply-device-settings-status-page-applied-by-filter-customer-item',
        },
    ].filter(item => item);
};

export const AppliedBySearch = ({ value, onChange, permissionType }) => {
    const onUserTypeChange = useCallback((userType) => {
        onChange && onChange({
            ...value,
            userType,
        });
    }, [value, onChange]);

    const onSearchTextChange = useCallback((searchText) => {
        onChange && onChange({
            ...value,
            searchText,
        });
    }, [value, onChange]);

    return (
        <div className='apply-device-settings-status-page-applied-by-filter-search-container'>
            <Toggle value={value.userType} onChange={onUserTypeChange}>{getUserTypeToggleItems(permissionType)}</Toggle>
            <SearchInput value={value.searchText} onChange={onSearchTextChange} placeholder='Type Name or Email' />
        </div>
    );
};
