import { NotificationsCenter as CommonNotificationCenter, NotificationCenterProvider } from '../Common';
import { usePublic } from './hooks/usePublic';

export const NotificationsCenter = () => {
    const { state, providerData } = usePublic();

    return (
        <NotificationCenterProvider providerData={providerData} >
            <CommonNotificationCenter {...state} />
        </NotificationCenterProvider>
    );
};
