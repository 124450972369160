import { useEffect, useCallback } from 'react';
import { getBrandDeviceUpgradeVersions } from 'services/Brands';
import { useAsync } from 'hooks/useAsync';

export const useLoadBrandDeviceUpgradeVersions = (brandID) => {
    const {
        run: runLoadUpgradeVersions,
        isLoading: isBrandDeviceUpgradeVersionsLoading = false,
        data: brandDeviceUpgradeVersions,
        error: brandDeviceUpgradeVersionsLoadingError,
    } = useAsync();

    const loadBrandDeviceUpgradeVersions = useCallback((brandID) => {
        runLoadUpgradeVersions(getBrandDeviceUpgradeVersions(brandID));
    }, []);

    useEffect(() => { loadBrandDeviceUpgradeVersions(brandID); }, []);

    return {
        brandDeviceUpgradeVersions,
        isBrandDeviceUpgradeVersionsLoading,
        brandDeviceUpgradeVersionsLoadingError,
        loadBrandDeviceUpgradeVersions,
    };
};
