import React, { useState, useCallback } from 'react';
import { useTrigger } from 'hooks/useTrigger';

import { taskConfig } from 'constants/applyStoreSettingsTask';
import { Header } from './Header';
import { DevicesList } from './DevicesList';

import './DownloadedDevices.scss';

export const DownloadedDevices = ({ task, countries, onDownloadLinkClick }) => {
    const [searchValue, setSearchValue] = useState('');
    const [devices, setDevices] = useState(task.devices);
    const [filtersCount, setFiltersCount] = useState(0);
    const resetFiltersTrigger = useTrigger();
    const onFiltersReset = useCallback(() => {
        resetFiltersTrigger.trigger();
    }, [resetFiltersTrigger]);


    return (
        <div className='hme-view-store-settings-task-downloaded-devices'>
            <Header
                count={devices.length}
                showDownloadLink={task.Task_Status !== taskConfig.statuses.CANCELED}
                searchValue={searchValue}
                onSearchChange={setSearchValue}
                onDownloadLinkClick={onDownloadLinkClick}
                onFiltersReset={onFiltersReset}
                filtersCount={filtersCount}
            />
            <DevicesList
                devices={devices}
                searchValue={searchValue}
                countries={countries}
                filtersCount={filtersCount}
                onFiltersCountChange={setFiltersCount}
                resetFiltersTrigger={resetFiltersTrigger}
            />
        </div>
    );
};
