import { addErrorNotification, addSuccessNotification } from 'services/Notifications';
import { isResetPassTokenValid, resetUserPassword } from 'services/ResetPassword';
import { isPartner } from 'services/Auth';
import { defaultNotificationOptions } from 'constants/login';
import { ERRORS_ENUM } from './constants';

export const validateToken = async (token) => {
    try {
        const response = await isResetPassTokenValid(token);

        if (response.isLinkExpired) {
            return ERRORS_ENUM.PASSWORD_LINK_EXPIRED;
        }
    } catch (err) {
        return ERRORS_ENUM.INTERNAL_SERVER_ERROR;
    }
    return '';
};

export const resetPassword = async (payload, navigate, state) => {
    try {
        const response = await resetUserPassword(payload);

        if (response.isLinkExpired) {
            return ERRORS_ENUM.PASSWORD_LINK_EXPIRED;
        }

        if (response.passwordHasBeenPreviouslyUsed) {
            return ERRORS_ENUM.PASSWORD_HAS_BEEN_USED;
        }

        navigate(isPartner() ? '/partner' : '/', { state });
        setTimeout(() => {
            addSuccessNotification('reset-pass__success--reseted', defaultNotificationOptions);
        }, 1000);
    } catch (err) {
        addErrorNotification(ERRORS_ENUM.INVALID_LINK, defaultNotificationOptions);
    }
    return '';
};
