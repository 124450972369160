import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import { Title } from 'library/Title';
import { InputComponent as Input } from 'components/Inputs';
import { Button } from 'library/Button';
import { NotificationsList } from 'library/NotificationsList';
import { CenterLoader } from 'components/Common/CenterLoader';
import { publicRules, partnerRules, isValidStatus, isInvalidStatus } from './rules';
import { Rule } from './Rule';
import { validateToken, resetPassword } from './Controller';

import './ResetPassword.scss';
import { addErrorNotification } from 'services/Notifications';
import { defaultNotificationOptions } from 'constants/login';
import { isPartner } from 'services/Auth';
import { ERRORS_ENUM } from './constants';

const defaultInputVariants = ['label-inline'];

const getToken = (ruid) => ruid.split('=')[1];

const ResetPasswordComponent = () => {
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    const rules = useMemo(()=> isPartner() ? partnerRules : publicRules,[isPartner])

    const { ruid } = useParams();

    const redirectWithError = (errorMessage) => {
        navigate(isPartner() ? '/partner' : '/', location.state || {});
        addErrorNotification(errorMessage, defaultNotificationOptions);
    }
    const allValid = useMemo(
        () => rules.every(({ rule }) => isValidStatus(rule(password, confirm))),
        [rules, password, confirm]
    );
    const someInvalid = useMemo(
        () => rules.some(({ rule }) => isInvalidStatus(rule(password, confirm))),
        [rules, password, confirm]
    );
    const inputVariants = useMemo(() => {
        const variants = [...defaultInputVariants];

        if (allValid) {
            variants.push('valid');
        }

        if (someInvalid) {
            variants.push('invalid');
        }

        return variants;
    }, [allValid, someInvalid]);

    const { t, i18n } = useTranslation();

    const onSubmit = useCallback(async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!allValid) {
            return;
        }

        setIsLoading(true);

        const token = getToken(ruid);
        const errorMessage = await resetPassword({
            token,
            password,
            confirm,
            language: i18n.resolvedLanguage
        },
        navigate,
        location.state);
        if (errorMessage) {
            if (errorMessage === ERRORS_ENUM.PASSWORD_HAS_BEEN_USED)
                addErrorNotification(errorMessage, defaultNotificationOptions);
            else
                redirectWithError(errorMessage);
        }

        setIsLoading(false);
    }, [ruid, password, confirm, i18n.resolvedLanguage, location]);

    useEffect(()=>{
        const checkToken = async () => {
            setIsLoading(true)
            const token = getToken(ruid);

            const errorMessage = await validateToken(token);
            if (errorMessage) {
                redirectWithError(errorMessage);
            }

            setIsLoading(false);
        };
        checkToken();
    }, [ruid, setIsLoading]);

    if (isLoading) {
        return (
            <div className='hme-reset-password'>
                <CenterLoader>{t('common__loading')}</CenterLoader>
            </div>
        );
    }

    return (
        <div className='hme-reset-password'>
            <NotificationsList className='hme-reset-password__notifications-list' limit={1} />
            <Title>{t('forgot-pass__reset-button')}</Title>
            <div className='hme-reset-password__info'>
                <div className='hme-reset-password__info__title'>
                    {t('my-account__password-requirements')}
                </div>
                <div className='hme-reset-password__rules'>
                    {rules.map((rule) => (
                        <Rule key={rule.tKey} password={password} confirm={confirm} {...rule} />
                    ))}
                </div>
            </div>
            <form className='hme-reset-password__form' onSubmit={onSubmit}>
                <Input
                    variants={inputVariants}
                    label={t('my-account__account-info__new-password')}
                    name='newPassword'
                    type='password'
                    value={password}
                    onChange={setPassword}
                />
                <Input
                    variants={inputVariants}
                    label={t('reset-pass__label--confirm')}
                    name='confirmPassword'
                    type='password'
                    value={confirm}
                    onChange={setConfirm}
                />
                <Button
                    className='hme-login-form__login-button'
                    variants={['submit']}
                    disabled={!allValid}
                    type='submit'
                >
                    {t('forgot-pass__reset-button')}
                </Button>
            </form>
        </div>
    );
};

export const ResetPassword = compose(
    withHMELayout()
)(ResetPasswordComponent);
