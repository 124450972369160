import React, {useMemo} from 'react';
import { Grid } from 'components/Common/Grid';
import PropTypes from 'prop-types';

import './ProviderGrid.css';

const gridHeaders = [
    {
        text: 'api-token-management__form--vaio-provider-name',
        property: 'name',
        className: 'hme-grid-cell-show-all-content'
    },
    {
        text: 'api-token-management__form--locationType',
        property: 'locationType',
        className: 'hme-grid-cell-show-all-content'
    }
];
const defaultProviders = [];

export const ProviderGrid = ({ providers = defaultProviders, onChange }) => {

    const rows = useMemo(()=> providers.map((row) => {
        return {
            ...row
        };
    }), [providers]);

    return (
        <div className="hme-api-management-provider-grid">
            <Grid
                rows={rows}
                headers={gridHeaders}
                rowKey="id"
                isLoading={false}
            />
        </div>
    );
};

ProviderGrid.propTypes = {
    stores: PropTypes.array,
    onChange: PropTypes.func
};
