import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

import { AutoCompleteInput } from 'library/AutoCompleteInput';
import { SearchInput } from 'components/Inputs/SearchInput';
import { loadAccounts } from './Controller';

import './AccountInput.scss';

const suggestionsHeaders = [{
    text: 'Email',
    property: 'User_EmailAddress',
}];

const minLength = 3;
const debounceTimeout = 700;

const getAccounts = async (
    mask,
    limit,
    setAccounts,
    setIsLoading
) => {
    try {
        const newAccounts = await loadAccounts({ mask, limit });
        setAccounts(newAccounts);
        setIsLoading(false);
    } catch (err) {
        setAccounts([]);
        setIsLoading(false);
    }
};

const getAccountsDebounced = debounce(getAccounts, debounceTimeout);

export const AccountInput = ({
    account,
    limit = 3,
    onAccountChange
}) => {
    const [value, setValue] = useState(account ? account.User_EmailAddress : '');
    const [suggestions, setSuggestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const onSuggested = useCallback(newAccount => {
        setValue(newAccount.User_EmailAddress);
        onAccountChange(newAccount);
    }, [setValue, onAccountChange]);

    useEffect(() => {
        if (!account || account.User_EmailAddress !== value) {
            onAccountChange(null);
        }
    }, [account, value, onAccountChange]);

    useEffect(() => {
        if (value.length < minLength) {
            return;
        }

        setIsLoading(true);
        getAccountsDebounced(
            value,
            limit,
            setSuggestions,
            setIsLoading
        );
    }, [value, limit, setSuggestions, setIsLoading]);

    return (
        <AutoCompleteInput
            value={account ? account.User_EmailAddress : value}
            suggestions={suggestions}
            headers={suggestionsHeaders}
            rowKey='User_EmailAddress'
            className='create-apply-store-settings-task-account-input hme-account-input'
            placeholder={t('common__type-an-account-email')}
            isLoading={isLoading}
            noSuggestionText={t('device-upgrades__section__search__accounts__not-found')}
            Component={SearchInput}
            onChange={setValue}
            onSuggested={onSuggested}
        />
    );
};
