import React, { useState, useEffect, useCallback } from 'react';
import { compose, pluck } from 'ramda';
import { useLocation, useNavigate } from 'react-router-dom';

import { taskConfig } from 'constants/applyStoreSettingsTask';
import { withHMELayout } from 'HOCs/withHMELayout';
import { PAGE_STATE, withPageState } from 'HOCs/withPageState';
import { addNotification, NOTIFICATION_TYPES } from 'services/Notifications';
import { Header } from './Header';
import { Footer } from './Footer';
import { SearchSection } from './SearchSection';
import { SEARCH_TYPES } from './SearchSection/FiltersForm';
import { DevicesSection } from './DevicesSection';
import {
    getBrands,
    getCountries,
    getDevices,
    createStoreSettingsTask,
    isTaskNameValid,
} from './Controller';

import './AdminCreateTask.scss';

const {
    zoom: {
        applySettingsMinVersion: applySettingsMinVersionZoom,
        applySettingsMaxVersion: applySettingsMaxVersionZoom,
    },
} = taskConfig;

const REDIRECT_URL = '/admin/applyStoreSettingsStatus';
const REDIRECT_TIMEOUT = 3000;

const defaultFilters = {
    searchType: SEARCH_TYPES.BRAND,
    storeBrandId: '',
    storeCountryId: '',
    account: null,
    deviceMinMainVersion: applySettingsMinVersionZoom,
    deviceMaxMainVersion: applySettingsMaxVersionZoom,
    deviceTypeId: 1,
    deviceIsActive: 1,
};

const defaultParams = {
    taskName: '',
};

const loadBrands = async setBrands => {
    setBrands(await getBrands());
};

const loadCountries = async setCountries => {
    setCountries(await getCountries());
};

const loadDevices = async (filters, setLoading, setDevices) => {
    setLoading(true);
    setDevices([]);
    try {
        setDevices(await getDevices(filters));
    } catch (e) {
        setDevices([]);
    }

    setLoading(false);
};

const isFiltersFilled = ({
    storeBrandId,
    storeCountryId,
    account,
}) => (
    (storeBrandId !== '' && storeCountryId !== '') ||
    account !== null
);

const CreateTask = ({ setPageState }) => {
    const [brands, setBrands] = useState([]);
    const [countries, setCountries] = useState([]);
    const [params, setParams] = useState(defaultParams);
    const [filters, setFilters] = useState(defaultFilters);
    const [isDevicesSectionDisabled, setIsDevicesSectionDisabled] = useState(true);
    const [devices, setDevices] = useState([]);
    const [isDevicesLoading, setIsDevicesLoading] = useState(false);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [isFilterSectionShown, setIsFilterSectionShown] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        loadBrands(setBrands);
    }, [setBrands]);

    useEffect(() => {
        loadCountries(setCountries);
    }, [setCountries]);

    useEffect(() => {
        setIsDevicesSectionDisabled(!isFiltersFilled(filters));
    }, [filters, setIsDevicesSectionDisabled]);

    useEffect(() => {
        if (!isFiltersFilled(filters)) {
            return;
        }

        loadDevices(filters, setIsDevicesLoading, setDevices);
    }, [filters, setIsDevicesLoading, setDevices]);

    useEffect(() => {
        setIsSubmitEnabled(!isDevicesSectionDisabled && selectedDevices.length && isTaskNameValid(params.taskName));
    }, [isDevicesSectionDisabled, params.taskName, selectedDevices.length]);

    useEffect(() => {
        setIsFilterSectionShown(isFilterSectionShown || isTaskNameValid(params.taskName));
    }, [params.taskName, setIsFilterSectionShown]);

    const redirectToStatusPage = () => {
        setTimeout(() => {
            navigate(REDIRECT_URL);
        }, REDIRECT_TIMEOUT);
    };

    const onCancel = useCallback(() => {
        setFilters(defaultFilters);
        setParams(defaultParams);
        setDevices([]);
        setSelectedDevices([]);
        setIsFilterSectionShown(false);

    }, [setFilters, setParams]);

    const onApply = useCallback(async () => {
        setPageState(PAGE_STATE.LOADING);

        try {
            await createStoreSettingsTask({
                name: params.taskName,
                deviceIDs: pluck('Device_ID', selectedDevices),
            }, filters);
            addNotification({
                message: 'The device file was successfully downloaded and a task has been created.',
                type: NOTIFICATION_TYPES.SUCCESS,
            });
            redirectToStatusPage();
        } catch (err) {
            console.log(err);
            setPageState(PAGE_STATE.ERROR);

            setTimeout(() => {
                setPageState(PAGE_STATE.INFO);
            }, REDIRECT_TIMEOUT)
        }
    }, [params, filters, selectedDevices, location, setPageState]);

    const onFiltersChange = useCallback((filters) => {
        setFilters(filters);
        setDevices([]);
        setSelectedDevices([]);
    }, [setFilters, setSelectedDevices]);

    return (
        <div className='create-apply-store-settings-task hme-page-component hme-components'>
            <Header />
            <div className='create-apply-store-settings-task-sections'>
                <SearchSection
                    sectionNumber={1}
                    disabled={false}
                    brands={brands}
                    countries={countries}
                    params={params}
                    filters={filters}
                    isFilterSectionShown={isFilterSectionShown}
                    onParamsChange={setParams}
                    onFiltersChange={onFiltersChange}
                />
                <DevicesSection
                    sectionNumber={2}
                    disabled={isDevicesSectionDisabled}
                    devices={devices}
                    countries={countries}
                    isLoading={isDevicesLoading}
                    selectedDevices={selectedDevices}
                    onSelectionChange={setSelectedDevices}
                />
            </div>
            <Footer
                isSubmitEnabled={isSubmitEnabled}
                onCancel={onCancel}
                onApply={onApply}
            />
        </div>
    );
};

export const AdminCreateTask = compose(
    withHMELayout(),
    withPageState({
        loaderText: 'The device file is being downloaded…',
        successText: 'The file was successfully uploaded and the task is now in progress.',
        errorText: 'Error The device file could not be downloaded at this time.',
    })
)(CreateTask);
