import { fetchSettings, updateSettings } from 'services/Settings';

import { displaySettingsDataAdaptor, displaySettingsToDTOAdaptor } from './adaptors';

export const getDisplaySettingsData = async () => {
    const data = await fetchSettings();

    return displaySettingsDataAdaptor(data);
};

export const updateDisplaySettingsData = async (data) => {
    const result = await updateSettings(displaySettingsToDTOAdaptor(data));

    if (!result.status) {
        throw new Error('common__error--unknown');
    }

    return result;
};
