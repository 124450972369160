import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getNavigateUrl } from './Controller';

const ignoreList = ['/connect', '/leaderBoardInvite', '/leaderBoards/LBGroups/lbGroup'];

export const PageUrlManager = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Support for CU-40 remote connect URL
        if (ignoreList.includes(location.pathname)) {
            return;
        }

        const newNavigation = getNavigateUrl(location.pathname, location.search);

        if (!newNavigation) {
            return;
        }

        navigate(newNavigation.url, { state: newNavigation.state });
    }, [location]);

    return null;
};
