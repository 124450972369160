import React, { useState, useEffect, useCallback } from 'react';
import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';

import { Header, defaultSearchValue } from './Header';
import { SortFilterModal } from 'library/TileList';
import { List } from './List';
import { loadPartnerStores } from '../../Controller';
import { addErrorNotification } from 'services/Notifications';

import {
    defaultSorting,
    MOBILE_USERS_SORT_OPTIONS
} from './constants';

import './StatusPage.scss';


const StatusPageComponent = () => {
    const [stores, setStores] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRendering, setIsRendering] = useState(false);
    const [searchValue, setSearchValue] = useState(defaultSearchValue);
    const [sorting, setSorting] = useState(defaultSorting);
    const [isSortModalOpen, setSortModalOpen] = useState(false);

    const onSortModalOpen = useCallback(() => setSortModalOpen(true), []);
    const onSortModalClose = useCallback(() => setSortModalOpen(false), []);

    useEffect(() => {
        const loadStores = async () => {
            setIsLoading(true);

            try {
                const { stores } = await loadPartnerStores();

                setStores(stores);
            } catch (err) {
                addErrorNotification('common__error--internal-server');
                setStores([]);
            }

            setIsLoading(false);
        };

        loadStores();
    }, []);

    const onFiltersAndSortReset = useCallback(() => {
        setSorting(defaultSorting);
    }, []);

    return (
        <>
            <div className="hme-stores-status--partner">
                <Header
                    isStoresAvailable={stores.length > 0}
                    onSearch={setSearchValue}
                    onModalOpen={onSortModalOpen}
                />
                <List
                    stores={stores}
                    sorting={sorting}
                    isLoading={isLoading || isRendering}
                    searchValue={searchValue}
                    onRenderingChange={setIsRendering}
                    onSorting={setSorting}
                />
            </div>
            <SortFilterModal
                isSortModalOpen={isSortModalOpen}
                sortOptions={MOBILE_USERS_SORT_OPTIONS}
                sortSelection={sorting}
                onSortChange={setSorting}
                onFiltersAndSortReset={onFiltersAndSortReset}
                onSortModalClose={onSortModalClose}
            />
        </>
    );
};

export const StatusPage = compose(withHMELayout())(StatusPageComponent);
