export const ERRORS_ENUM = {
    INVALID_CREDENTIALS: 'Invalid credentials supplied',
    PASSWORD_HAS_BEEN_USED: 'Password has been used before'
};

export const ERRORS_MAP = new Map([
    ['emailused', 'my-account__error__duplicate-email'],
    [ERRORS_ENUM.INVALID_CREDENTIALS, 'my-account__error__invalid-credentials'],
    [ERRORS_ENUM.PASSWORD_HAS_BEEN_USED, 'reset-pass__error__password-used-before']
]);
