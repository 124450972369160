const DECLINE_MESSAGE_MAX_CHARS = 200;

export const declineUpgradeFormDataValidator = ({ message }) => {
    const formErrorsConditions = [
        {
            key: 'message',
            condition: !message,
            message: 'device-upgrades__popup__decline-upgrade__validation--empty-message',
        },
        {
            key: 'maxMessage',
            condition: message?.length > DECLINE_MESSAGE_MAX_CHARS,
            message: 'device-upgrades__popup__decline-upgrade__validation--max-chars',
        },
    ];

    const formError = formErrorsConditions.reduce(
        (errors, el) => (el.condition && ((errors[el.key] = el.message), (errors.status = true)), errors),
        { status: false },
    );

    return formError;
};

// form fields mapped to multiple validation messages
export const declineUpgradeValidationFieldsMessagesMap = {
    message: ['message', 'maxMessage'],
};
