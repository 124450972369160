import { saveAs } from 'file-saver';
import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { getExportFile, getSearchString } from 'Api';
import {
    addErrorNotification,
    addSuccessNotification
} from 'services/Notifications';
import { DateLib } from '../../../../helpers/DateLib';
import { FORMAT_TYPES } from '../../../../helpers/DateLib/constants';

const generateFileName = (extension) => {
    const timePart = new DateLib().format(FORMAT_TYPES.DATE_UNDERSCORED);
    return `Hierarchy${timePart}.${extension}`;
}

const empty = () => {};

export const downloadExportFile = async (exportType, setIsLoading = empty) => {
    try {
        setIsLoading(true);

        const searchString = getSearchString({
            filterOutNitroEssentialsStores: true
        });

        saveAs(
            await getExportFile({
                exportType,
                url: `${Config.apiBaseUrl}${CommonConstants.apiUrls.exportGroup}?${searchString}`,
                params: {
                    timestamp: new Date().getTime(),
                },
            }),
            generateFileName(exportType)
        );

        setIsLoading(false);

        addSuccessNotification('manage-hierarchy__export-success');
    } catch (err) {
        addErrorNotification('manage-hierarchy__error--empty-stores');
    }
};
