import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'library/Button';

export const View = ({ task }) => (
    <Link to={`/admin/storeSettingsTask/view/${task.Task_UID}`}>
        <Button variants={['transparent']}>View</Button>
    </Link>
);
