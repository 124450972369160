import compareDeviceTypes from 'helpers/Device/compareDeviceTypes';
import { settingApi } from 'api/settingApi';
import { CommonConstants } from 'Constants';
import { Constant } from '@hme-cloud/utility-common';

const { BuildVersions } = Constant;

const handleSnapshotsResponse = (response, { type = null, minVersion = null, status = null } = {}) => {
    const snapshots = response.status ? response.data : [];

    return filterSnapshots(snapshots, { type, minVersion, status });
};

export const filterSnapshots = (snapshots, { type = null, minVersion = null, status = null } = {}) => {
    if (!type && !minVersion && status === null) {
        return snapshots;
    }

    return snapshots
        .filter(({ SourceDeviceType }) => type === null || SourceDeviceType === type)
        .filter(({ Status }) => status === null || Status === status)
        .filter(
            ({ SourceSoftwareVersion }) =>
                minVersion === null || BuildVersions.semverGte(SourceSoftwareVersion, minVersion),
        );
};

// get snapshots without joined info
export const getSnapshotsList = async ({
    pageNumber,
    itemsPerPage = 10,
    searchValue = '',
    searchField = '',
    appliedFilters = {},
    sortColumn = '',
    sortingType = '',
}) => {
    const filters = { ...appliedFilters };
    if (appliedFilters.Status) {
        // convert Status filter to one dimensional array
        filters.Status = [].concat(...appliedFilters.Status);
    }

    const payload = {
        pagenumber: pageNumber || 1,
        searchValue: encodeURIComponent(searchValue),
        searchfield: searchField,
        sortcolumn: sortColumn,
        sorttype: sortingType,
        perpage: itemsPerPage,
        filters,
    };

    const { status, data } = await settingApi.findSnapshots(payload);

    if (!status) {
        throw new Error('common__error--internal-server');
    }

    return data;
};

// get snapshots with applied date & applied by info
export const getDetailedSnapshotsList = async (options) => {
    const response = await settingApi.get();

    return handleSnapshotsResponse(response, options);
};

export const deletePublicSnapshot = (id) => settingApi.deletePublicSnapshot(id);
export const deleteDistributorSnapshot = (id) => settingApi.deleteDistributorSnapshot(id);
export const deleteAdminSnapshot = (id) => settingApi.deleteAdminSnapshot(id);

export const getStores = async (userUID, selectedDeviceType) => {
    const accountStores = await settingApi.getAccountStoresByUserUID({ userUID, selectedDeviceType });

    return accountStores.status ? accountStores.data : [];
};

export const deviceTypeStoresFilter = (selectedDeviceType) => (store) =>
    compareDeviceTypes(store, CommonConstants.deviceType[selectedDeviceType]);

export const filterStoresByDeviceType = (stores, selectedDeviceType) =>
    stores.filter(deviceTypeStoresFilter(selectedDeviceType));

export const validateAddSnapshot = (snapshot) => settingApi.validateAddSnapshot(snapshot);
export const saveSnapshot = (snapshot) => settingApi.update(snapshot);
export const saveDistributorSnapshot = (snapshot) => settingApi.saveDistributorSetting(snapshot);
export const savePublicSnapshot = (snapshot) => settingApi.savePublicSetting(snapshot);

export const getDeviceSettingsName = async (params) => {
    const queryParams = new URLSearchParams(params).toString();

    const { status, data } = await settingApi.getDeviceSettingsName(queryParams);

    if (!status) {
        throw new Error('common__error--internal-server');
    }

    return data;
};

// Get all possible filters, or pull filter by device type (ie: ZOOM, NEXEO)
export const getFilters = async (deviceType = '') => {
    const { status, data } = await settingApi.getFilters(deviceType);

    if (!status) {
        throw new Error('common__error--internal-server');
    }

    return data;
};

export const findDevices = async params => {
    const queryParams = new URLSearchParams(params).toString();
    const { status, data } = await settingApi.findDevices(queryParams);

    if (!status) {
        throw new Error('common__error--internal-server');
    }

    return data;
}
