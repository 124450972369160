import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PureRadioGroup } from 'library/RadioGroup';
import { Button } from 'library/Button';
import { CenterLoader } from 'components/Common/CenterLoader';

import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg';

import { renewTokenTypes } from '../../constants';
import { Modal } from '../Modal';
import { getRadioOptions } from './RenewTokenOptions';

import './RenewTokenModal.scss';

const RENEW_TOKEN_BTN_VARIANTS = ['submit'];

export const RenewTokenModal = ({ isShown, onCancel, onSubmit, token, subtitleText, isOptionsHidden, isLoading, ...props }) => {
    const { t } = useTranslation();

    const [renewTokenType, setRenewTokenType] = useState(renewTokenTypes.ORIGINAL_EXP_DATE);
    const [specificDate, setSpecificDate] = useState(null);

    const isDatepickerDisabled = renewTokenType !== renewTokenTypes.SPECIFIC_EXP_DATE;

    const isSubmitDisabled = renewTokenType === renewTokenTypes.SPECIFIC_EXP_DATE && !specificDate;

    const handleSubmit = useCallback(
            (evt) => {
                evt.preventDefault();

                if (isSubmitDisabled || !onSubmit) {
                    return;
                }

                onSubmit(isOptionsHidden ? null : renewTokenType, { specificDate });
            },
            [renewTokenType, specificDate, isSubmitDisabled, isOptionsHidden, onSubmit]
    );

    return (
        <Modal show={isShown} className="hme-api-management-renew-token-modal" onHide={onCancel} {...props}>
            <div className="hme-api-management-renew-token-modal__content">
                {isLoading ? <CenterLoader /> : (
                    <>
                        <div className="hme-api-management-renew-token-modal__header">
                            <AlertIcon className="hme-api-management-renew-token-modal__alert-icon" />
                            <h2 className="hme-api-management-renew-token-modal__title">{t('api-token-management__renew-token')}</h2>
                            <p className="hme-api-management-renew-token-modal__description">   {subtitleText}
                            </p>
                        </div>

                        <form className="hme-api-management-renew-token-modal__form" onSubmit={handleSubmit}>
                            {!isOptionsHidden && (
                                <PureRadioGroup
                                    value={renewTokenType}
                                    onValueChange={setRenewTokenType}
                                    name="renewTokenAlgorithm"
                                    className="hme-api-management-renew-token-modal__options"
                                >
                                    {getRadioOptions({
                                        isDatepickerDisabled,
                                        tokenExpirationDate: token?.expiryDate,
                                        onDateChange: setSpecificDate,
                                        date: specificDate
                                    })}
                                </PureRadioGroup>)}

                            <div className="hme-api-management-renew-token-modal__btns-container">
                                <Button onClick={onCancel}>{t('common__cancel')}</Button>

                                <Button variants={RENEW_TOKEN_BTN_VARIANTS} disabled={isSubmitDisabled} type="submit">
                                    {t('api-token-management__renew-token')}
                                </Button>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </Modal>
    );
};
