const insertNodeFirstWithParent = (tree, parent, node) => {
    return tree.map((treeNode) => {
        if (treeNode.item === parent.item) {
            return {
                ...treeNode,
                children: [node, ...(treeNode.children ? treeNode.children : [])]
            };
        }

        if (!treeNode.children || treeNode.children.length === 0) {
            return treeNode;
        }

        return {
            ...treeNode,
            children: insertNodeFirstWithParent(treeNode.children, parent, node),
        };
    });
};

export const insertNodeFirst = (tree, parent, node) => {
    if (!parent) {
        return [node, ...tree];
    }


    return insertNodeFirstWithParent(tree, parent, node);
}
