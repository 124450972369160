import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Title } from 'library/Title';
import { Filter } from './Filter';

import { availableFiltersType } from '../../types';

import './Filters.scss';

export const Filters = ({
    availableFilters,
    filtersOptions,
    filters,
    onFiltersChange
}) => {
    const { t } = useTranslation();

    return <div className="hme-tile-list__sort-filter-modal__filters">
        <Title>{t('common__filter')}</Title>
        {
            availableFilters.map(({
                property,
                items,
                defaultSearchValue,
                SearchItemsComponent,
                onSearchItemsChange,
                noItemsFoundText,
                isOpen,
            }) => <Filter
                key={property}
                property={property}
                items={items}
                filtersOptions={filtersOptions}
                filters={filters}
                defaultSearchValue={defaultSearchValue}
                noItemsFoundText={noItemsFoundText}
                SearchItemsComponent={SearchItemsComponent}
                onSearchItemsChange={onSearchItemsChange}
                onFiltersChange={onFiltersChange}
                isOpen={isOpen}
            />)
        }
    </div>;
};

Filters.propTypes = {
    availableFilters: availableFiltersType,
    filters: PropTypes.shape({}),
    filtersOptions: PropTypes.shape({}),
    onFiltersChange: PropTypes.func
};
