import React from 'react';
import { Trans } from 'react-i18next';

import { deviceConfig } from 'constants/device';
import { DeviceStatus } from 'library/DeviceStatus';
import { Address } from 'library/Address';
import { SystemInformationAccountLink } from 'library/SystemInformationAccountLink';
import { StoreNumberLink } from 'library/StoreNumberLink';

const { getLaneType } = deviceConfig;

export const getDeviceStatusInfoFields = ({ profile, device, deviceSettings, storeCountry }) => {
    if (!device || !deviceSettings) return [];

    const StatusInfoFields = [
        {
            label: <Trans i18nKey="common__status" />,
            children: <DeviceStatus
                online={device.Device_IsActive}
                isPreconfigured={device.Device_IsPreconfigured}
                deviceType={device.Device_DeviceType_ID}
                name={device.Device_Name || device.DeviceType_Name}
                version={device.Device_MainVersion}
                deviceUid={device.Device_UID}
                hideDeviceName
            />
        },
        {
            label: <Trans i18nKey="common__store__name" />,
            children: device.Store_Name || '-'
        },
        {
            label: <Trans i18nKey="common__store__number--text" />,
            children: <StoreNumberLink storeUID={device.Store_UID}>{device.Store_Number}</StoreNumberLink>
        },
        {
            label: <Trans i18nKey="common__brand" />,
            children: device.Brand_Name || ''
        },
        {
            label: null,
            children: <Address
                value={{
                    line1: device.Store_AddressLine1,
                    line2: device.Store_AddressLine2,
                    line3: device.Store_AddressLine3,
                    line4: device.Store_AddressLine4,
                    city: device.Store_Locality,
                    zipCode: device.Store_PostCode,
                    region: device.Store_Region,
                    country: storeCountry
                }}
                isReadOnly={true}
            />
        },
        {
            label: <Trans i18nKey="common__account__email" />,
            children: <SystemInformationAccountLink device={device} profile={profile} />
        },
        {
            label: <Trans i18nKey="common__timezone" />,
            children: device.Timezone || '-'
        },
        {
            label: <Trans i18nKey="system-status__lane-config" />,
            children: getLaneType(device.Device_LaneConfig_ID)
        },
        {
            label: <Trans i18nKey="common__settings__version" />,
            children: device.Device_SettingVersion
        }
    ];

    return StatusInfoFields;
};
