import { CommonConstants } from 'Constants';

const { deviceType } = CommonConstants;

import { NEXEO_PERIPHERAL_TYPES } from 'constants/NEXEOPeripheralTypes';

export const formatBrandsDeviceUpgradeVersions = (brandsDeviceUpgradeVersions) => {
    if (!brandsDeviceUpgradeVersions?.length) return [];

    return brandsDeviceUpgradeVersions.map((brandDeviceUpgradeVersion) => {
        const { brandName, brandID, brandUpgradeVersions, updatedAt, updatedBy } = brandDeviceUpgradeVersion;

        const brandUpgradeVersionsByDeviceType = (brandUpgradeVersions || []).reduce(
            (acc, item) => ({
                ...acc,
                [item.deviceTypeID]: item.version,
            }),
            {},
        );

        return {
            brandName,
            brandID,
            IB7000Version: brandUpgradeVersionsByDeviceType[NEXEO_PERIPHERAL_TYPES.IB7000.deviceTypeID] || '',
            nexeoVersion: brandUpgradeVersionsByDeviceType[deviceType.nexeo.id] || '',
            zoomNitroVersion: brandUpgradeVersionsByDeviceType[deviceType.zoomNitro.id] || '',
            updatedAt,
            updatedBy,
        };
    });
};
