import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { compose } from 'ramda';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';

import { withHMELayout } from 'HOCs/withHMELayout';
import { Paginate } from 'library/Paginate/Paginate';
import { useStatusQueryParams } from 'hooks/useStatusQueryParams';
import * as StoreDetailsPopup from 'actionTypes/StoreDetailsPopup/storeDetailsPopup';
import { addErrorNotification } from 'services/Notifications';
import { NotificationsList } from 'library/NotificationsList';
import { PAGE_DATA } from 'constants/paginationDefault';
import { formatAppliedFilters } from 'helpers/formatAppliedFilters';
import { StorePopupType } from 'containers/PreInstallModalContainer/modals';
import { useIsFirstRender } from 'hooks/useIsFirstRender';
import { convertFiltersToDTO as filtersToDTOConverter } from 'helpers/form';
import { useLoadBrands } from 'hooks/useLoadBrands';
import { useTrigger } from 'hooks/useTrigger';

import { useLoadStoreSystemTiers } from '../../Common/hooks';
import { getAllStores } from './Controller';
import { List } from './List/List';
import { Header } from './Header/Header';
import { AdminStoreProperties } from './constants/adminStoreProperties';
import { nitroSubscriptionTypeList } from './constants/nitroSubscriptionTypeList';

import './StatusPage.scss';

const INIT_FILTER_VALUES = { selectValue: 'Store_Number', searchValue: '' };
const INIT_PAGINATION_VALUES = { pageSize: 10, pageNumber: 0 };
const INIT_SORT_SELECTION = { Company_Name: 1 };
const SEARCH_DEBOUNCE_TIME = 1000;
const ALL_SUBSCRIPTION_TYPES_FILTER_TEXT = 'All Subscription Types';

const prepareFilter = ({ text, value }) => ({
    text: <Trans i18nKey={text} />,
    value: String(value)
});

const formatFiltersOptions = (brands, tierSubscriptions = []) => {
    const tierSubscriptionsMap = tierSubscriptions.reduce((tiers, { name: text, id: value }) => {
        tiers[text] = prepareFilter({ text, value: `t.${value}` });
        return tiers;
    }, {});

    const { NVD = {}, ...tiers } = tierSubscriptionsMap;

    return ({
        [AdminStoreProperties.brandName]: {
            allText: 'common__all-brands',
            items: (brands || []).map(({ Name: text, Id: value }) => prepareFilter({ text, value })),
            property: AdminStoreProperties.brandName,
        },
        [AdminStoreProperties.userSubscription]: {
            allText: ALL_SUBSCRIPTION_TYPES_FILTER_TEXT,
            items: [
                ...nitroSubscriptionTypeList.map(({ label: text, value }) => prepareFilter({ text, value })),
                prepareFilter({ value: NVD.value, text: 'common__subscription--nvd-full' }),
                ...Object.values(tiers)
            ],
            property: AdminStoreProperties.userSubscription,
        },
        [AdminStoreProperties.deviceStatus]: {
            allText: 'common__all-statuses',
            items: statusList.map(({ label: text, value }) => prepareFilter({ text, value })),
            property: AdminStoreProperties.deviceStatus,
        }
    });
};

const getFiltersFromAppliedFilters = (appliedFilters = {}, filters = {}) => {
    return Object.keys(filters).reduce((result, key) => {
        result[key] = appliedFilters[key] ?? filters[key].items.map(({ value }) => value);

        return result;
    }, {});
};

const filterNames = {
    'Subscription_Name': 'subscriptionIDs',
    'Brand_Name': 'brandIDs',
    'Device_IsActive': 'deviceStatuses',
};

const convertFiltersToDTO = filtersToDTOConverter(filterNames);

const statusList = [
    {
        label: 'common__device__status--online',
        value: 1,
    },
    {
        label: 'common__device__status--offline',
        value: 0,
    },
    {
        label: 'common__device__status--pre-configured',
        value: 2,
    },
    {
        label: 'common__device__status--unavailable',
        value: 3,
    },
];

const getStoresFromApi = async ({
        sortSelection,
        filterParams,
        paginationData,
        appliedFilters = {},
        abortController = null,
        setStoresLoading,
        setRecordsTotal,
        setAdminStoreList,
        setAbortController,
        allGridFilters
}) => {
    if (abortController) {
        abortController.abort();
    }

    const newAbortController = new AbortController();

    setStoresLoading(true);
    setAbortController(newAbortController);
    try {
        const { storeList, pageDetails: { RecordCount } } = await getAllStores({
            filterParams,
            paginationData,
            sortSelection,
            appliedFilters: convertFiltersToDTO(formatAppliedFilters(allGridFilters, appliedFilters)),
            signal: newAbortController.signal
        });
        setAbortController(null);
        setRecordsTotal(RecordCount);
        setAdminStoreList(storeList);
        setStoresLoading(false);
    } catch (error) {
        switch (error.message) {
            case 'canceled':
                return;
            default:
                addErrorNotification('common__error--internal-server');
                setStoresLoading(false);
                break;
        }
    }
};

const getStoresFromApiDebounce = debounce(getStoresFromApi, SEARCH_DEBOUNCE_TIME);

const StatusPageComponent = () => {
    const {
        page,
        perPage,
        searchParams: filterParams,
        sortSelection,
        setPagination,
        setSearchParams: setFilterParams,
        setSortSelection,
        appliedFilters,
        setAppliedFilters
    } = useStatusQueryParams({
        page: INIT_PAGINATION_VALUES.pageNumber,
        perPage: INIT_PAGINATION_VALUES.pageSize,
        searchParams: INIT_FILTER_VALUES,
        sortSelection: INIT_SORT_SELECTION,
        appliedFilters: {},
    });

    const isFirstRender = useIsFirstRender();

    const [adminStoresList, setAdminStoreList] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [isStoresLoading, setStoresLoading] = useState(false);
    const [abortController, setAbortController] = useState(null);
    const [filters, setFilters] = useState({});
    const [filtersCount, setFiltersCount] = useState(0);
    const resetFiltersTrigger = useTrigger();

    const { brands, isBrandsLoading } = useLoadBrands();
    const { storeSystemTiers, isStoreSystemTiersLoading } = useLoadStoreSystemTiers();

    const paginationData = { pageNumber: page, pageSize: perPage };

    const isLoading = isStoresLoading || isBrandsLoading || isStoreSystemTiersLoading;

    const dispatch = useDispatch();

    const addNewStore = useCallback(() =>
        dispatch({
            type: StoreDetailsPopup.OPEN_POPUP_PRE_INSTALL,
            payload: { preInstallPopUpComponent: StorePopupType.addStore }
        }),
    [dispatch]
    );

    const isShowPaginate = useMemo(() => {
        return Boolean(adminStoresList.length) && !isStoresLoading;
    }, [adminStoresList, isStoresLoading]);

    const filtersOptions = useMemo(
        () => (formatFiltersOptions(brands, storeSystemTiers || [])),
        [brands, storeSystemTiers],
    );

    const availableFilters = useMemo(() => Object.values(filtersOptions), [filtersOptions]);
    const allGridFilters = useMemo(() => getFiltersFromAppliedFilters({}, filtersOptions), [filtersOptions]);

    const onSortChange = useCallback((newSortData) => {
        if (isShowPaginate) {
            getStoresFromApiDebounce.cancel();
            setSortSelection(newSortData);
            getStoresFromApi({
                sortSelection: newSortData,
                filterParams,
                paginationData: { pageNumber: INIT_PAGINATION_VALUES.pageNumber, pageSize: perPage },
                abortController,
                appliedFilters,
                allGridFilters,
                setStoresLoading,
                setRecordsTotal,
                setAdminStoreList,
                setAbortController
            });
        }
    }, [
        isShowPaginate,
        filterParams,
        paginationData,
        sortSelection,
        abortController,
        appliedFilters,
        allGridFilters,
        setSortSelection
    ]);

    const onPaginateChange = useCallback(({ page, recordsPerPage }) => {
        getStoresFromApiDebounce.cancel();
        setPagination({ page, perPage: recordsPerPage });
        getStoresFromApi({
            sortSelection,
            filterParams,
            paginationData: { pageNumber: page, pageSize: recordsPerPage },
            abortController,
            appliedFilters,
            allGridFilters,
            setStoresLoading,
            setRecordsTotal,
            setAdminStoreList,
            setAbortController
        });
    }, [
        filterParams,
        paginationData,
        sortSelection,
        abortController,
        appliedFilters,
        allGridFilters,
        setPagination
    ]);

    const setPaginationOnSearch = useCallback((newRecordsTotal) => {
        setRecordsTotal(newRecordsTotal);
        setPagination({ page: INIT_PAGINATION_VALUES.pageNumber, perPage: INIT_PAGINATION_VALUES.pageSize });
    }, [setPagination]);

    const onSearchParamsChange = useCallback((newSearchParams) => {
        setFilterParams(newSearchParams);
        getStoresFromApiDebounce.cancel();

        getStoresFromApiDebounce({
            sortSelection,
            filterParams: newSearchParams,
            paginationData: INIT_PAGINATION_VALUES,
            appliedFilters,
            abortController,
            allGridFilters,
            setStoresLoading,
            setRecordsTotal: setPaginationOnSearch,
            setAdminStoreList,
            setAbortController
        });
    }, [
        sortSelection,
        abortController,
        appliedFilters,
        allGridFilters,
        setFilterParams,
        setPaginationOnSearch
    ]);

    const onSearch = useCallback(() => {
        setPagination({ page: INIT_PAGINATION_VALUES.pageNumber, perPage: INIT_PAGINATION_VALUES.pageSize });
        getStoresFromApiDebounce.cancel();
        getStoresFromApi({
            sortSelection,
            filterParams,
            paginationData: INIT_PAGINATION_VALUES,
            abortController,
            appliedFilters,
            allGridFilters,
            setStoresLoading,
            setRecordsTotal,
            setAdminStoreList,
            setAbortController
        });
    }, [
        filterParams,
        paginationData,
        sortSelection,
        abortController,
        appliedFilters,
        allGridFilters,
        setPagination,
        setSortSelection
    ]);

    const handleFiltersChange = useCallback((newFilters) => {
        getStoresFromApiDebounce.cancel();

        setAppliedFilters(newFilters, allGridFilters);
        setFilters(newFilters);
        setPagination({ page: INIT_PAGINATION_VALUES.pageNumber, perPage });

        getStoresFromApi({
            sortSelection,
            filterParams,
            appliedFilters: newFilters,
            paginationData: { pageNumber: INIT_PAGINATION_VALUES.pageNumber, pageSize: perPage },
            abortController,
            allGridFilters,
            setStoresLoading,
            setRecordsTotal,
            setAdminStoreList,
            setAbortController
        });
    }, [
        sortSelection,
        filterParams,
        perPage,
        abortController,
        allGridFilters,
        setPagination,
        setAppliedFilters
    ]);

    const onFiltersReset = useCallback((newFilters) => {
        getStoresFromApiDebounce.cancel();

        setAppliedFilters(newFilters, allGridFilters);
        setFilters(newFilters);
        setPagination({ page: INIT_PAGINATION_VALUES.pageNumber, perPage });

        getStoresFromApi({
            sortSelection,
            filterParams,
            appliedFilters: newFilters,
            paginationData: { pageNumber: INIT_PAGINATION_VALUES.pageNumber, pageSize: perPage },
            abortController,
            allGridFilters,
            setStoresLoading,
            setRecordsTotal,
            setAdminStoreList,
            setAbortController
        });
    }, [
        sortSelection,
        filterParams,
        perPage,
        abortController,
        allGridFilters,
        setPagination,
        setAppliedFilters
    ]);

    useEffect(() => {
        setFilters(getFiltersFromAppliedFilters(appliedFilters, filtersOptions));
    }, [appliedFilters, filtersOptions]);

    useEffect(() => {
        // hook will be executed only on the first render which is needed to fetch data from the API
        if (!isFirstRender) {
            return;
        }

        getStoresFromApiDebounce.cancel();

        getStoresFromApi({
            sortSelection,
            filterParams,
            paginationData: { pageNumber: page, pageSize: perPage },
            appliedFilters,
            setStoresLoading,
            setRecordsTotal,
            setAdminStoreList,
            setAbortController,
            allGridFilters
        });
    }, [appliedFilters, sortSelection, filterParams, page, perPage]);

    return <>
        <div className="hme-stores-status--admin">
            <NotificationsList />
            <Header
                filterParams={filterParams}
                filtersCount={filtersCount}
                onFiltersReset={onFiltersReset}
                onSearchParamsChange={onSearchParamsChange}
                addNewStore={addNewStore}
                onSearch={onSearch}
            />
            <List
                stores={adminStoresList}
                availableFilters={availableFilters}
                isLoading={isLoading}
                onSortChange={onSortChange}
                sortSelection={sortSelection}
                filters={filters}
                resetFiltersTrigger={resetFiltersTrigger}
                onFiltersChange={handleFiltersChange}
                onFiltersCountChange={setFiltersCount}
            />
            {isShowPaginate &&
                <Paginate
                    total={recordsTotal}
                    page={page}
                    onChange={onPaginateChange}
                    recordsPerPage={perPage}
                    pageSizes={PAGE_DATA.PAGE_SIZES_ADMIN}
                    className="hme-stores-status--admin__paginate"
                />}
        </div>
    </>;
};

export const StatusPage = compose(
        withHMELayout()
)(StatusPageComponent);
