/* eslint-disable max-len */
const language = [
    // English
    {
        'notEnoughPermissions': 'You don\'t have required permissions', // new translation key: common__error--permissions
        'accountEmail': 'Account Email', // new translation key: common__account__email
        'account': 'Account', // new translation key: admin__sub-header__account
        'username': 'Username', // new translation key: common__user__username
        'password': 'Password', // new translation key: common__user-password
        'submitBtn': 'Login', // new translation key: login__login-button
        'forgotpass': 'I forgot my password', // new translation key: login__forget-pass-link
        'cookiepolicy': 'Cookie Policy',
        'californiasupply': 'California Supply Chains Act',
        'privacypolicy': 'Privacy Policy',
        'doNotSellInformation': 'Do Not Sell My Personal Information',
        'useragreement': 'User Agreement',
        'loginattemptsnumber': 'Login attempts ', // new translation key: login__attempts a part of that
        'outOf': 'out of', // new translation key: login__attempts a part of that
        'loginattemptsexceeded': 'Login attempts exceeded. Please try your request at a later time.', // new translation key: login__attempts__exceeded
        'youDoNotHaveAccess': 'You do not have access to the HME Admin Portal. To gain access, please submit a request to IT at isr@hme.com.',
        'youDoNotHaveAccessPage': 'You do not have access to view this page.', // new translation key: forbidden__access
        'emailaddress': 'Email Address:', // new translation key: common__email-address
        'userEmail': 'User Email Address',
        'resetpassBtn': 'Reset Password', // new translation key: forgot-pass__reset-button
        'reset': 'Reset', // new translation key: common__reset
        'internalservererror': 'Internal Server Error', // new translation key: common__error--internal-server
        'internalservererrorP1': 'An internal error occured while processing your request.', // new translation key: common__error--internal-server__p1
        'internalservererrorP2': 'The system administrator has been notifed and is working to resolve this issue as quickly as possible.',
        'internalservererrorP3': 'Should you continue to experience difficulty loading this page, please contact support directly.',
        'networkerror': 'Network error', // new translation key: common__error--network-error
        'usernameinvalid': 'Username is invalid. Please try again.',
        'invalidcredentials': 'Invalid credentials supplied. Please try again.', // new translation key: my-account__error__invalid-credentials
        'passwordblank': 'Password may not be blank. Please try again.', // new translation key: my-account-error__blank-password
        'loginattemptinvalid': 'Login Attempt Invalid. The system administrator has been notified.',
        'loginfail': 'Login fail. The username or password you entered was not found in our system. If this problem persists please contact your system administrator.', // new translation key: login__error--fail
        'emailblank': 'Email address may not be blank.',
        'emailinvalid': 'Email address is invalid. Please try again.', // new translation key: common__error--invalid-email
        'emailconfirm': 'Please confirm your email address.',
        'passwordsize': 'Password must be between 8 and 16 characters in length.',
        'passworduppercase': 'Password must contain at least 1 uppercase character.',
        'passwordlowercase': 'Password must contain at least 1 lowercase character.',
        'passwordnumber': 'Password must contain at least 1 number.',
        'passwordcontainblank': 'Password may not contain blank spaces.',
        'blankfirst': 'Please provide your first name.',
        'blanklast': 'Please provide your last name.',
        'blankcompany': 'Please provide your company name.',
        'blankstate': 'Please provide your state.', // new translation key: my-account__error__blank-company-state
        'blankavante': 'Please provide your Avante number.',
        'blankterms': 'Please accept terms and conditions to proceed.', // new translation key: my-account__error__blank-terms
        'blankshareddata': 'Please accept or decline the shared data agreement.',
        'emailnomatch': 'Email address do not match.',
        'passwordnomatch': 'Passwords do not match.', // new translation key: my-account__error__password-mismatch
        'emailused': 'Email address is already in use.', // new translation key: my-account-error__duplicate-email
        'usersignupsuccess': 'User Sign Up Successful',
        'usersignupactivate': 'You\'re almost done. Please check your email to activate your new account.',
        'loginsexceed': 'Logins Exceeded',
        'confirmemailaddress': 'Confirm Email address:',
        'passwordcolon': 'Password:',
        'confirmpasswordcolon': 'Confirm Password:', // new translation key: reset-pass__label--confirm
        'firstname': 'First Name', // new translation key: common__user__first-name
        'lastname': 'Last Name', // new translation key: common__user__last-name
        'avantenum': 'Avante Number', // new translation key: common__user__avante-number
        'companyname': 'Company Name', // new translation key: common__company__name
        'companystate': 'Company State:',
        'emailaddresscolon': 'Email address:', // new translation key: common__email-address
        'confirmemailaddresscolon': 'Confirm Email address:',
        'shareddata': 'Shared Data ',
        'agreement': 'Agreement', // new translation key: my-account__account-info__agreement
        'accept': 'Accept',
        'decline': 'Decline',
        'usernotfound': 'The user record you\'re trying to activate was not found.',
        'tologintoyouacct': 'to login to your account.',
        'useracctactivated': 'User Account Activated', // new translation key: validate-account__user-account-activated
        'passwordlinkinvalid': 'Password link invalid. Please try your request again.', // new translation key: reset-pass__error--invalid-link
        'passwordexpire': 'Password link expired. Please try your request again.', // new translation key: reset-pass__error--expired
        'passwordresetconfirm': 'Password Reset Confirmed', // new translation key: reset-pass__success--reseted
        'please': 'Please',
        'login': 'login',
        'toyouraccount': 'to your account.',
        'newpassword': 'New Password:', // new translation key: my-account__account-info__new-password
        'userEmailHasNotBeenVerified': 'User email has not been verified', // new translation key: add-user__warning--email-not-verified
        'userHasNotAcceptedTermsOfService': 'User has not accepted Terms of Service', // new translation key: add-user__warning--terms-not-accepted
        'userIsSetToInactive': 'User is set to inactive', // new translation key: add-user__warning--inactive
        'masqueradeNotAvailable': 'Masquerade not available for this User.', // new translation key: add-user__warning--masquerade-unavailable
        'passwordrequirements': 'Password Requirements', // new translation key: my-account__password-requirements
        'lengthofpasswordLi': 'The length of the password must be at least eight (8) characters and not more than sixteen (16) characters.', // new translation key: my-account__password-requirements__password-length
        'passwordblankLi': 'The password may not contain blank spaces.', // new translation key: my-account__password-requirements__password-with-spaces
        'passwordgroupsLi': 'The password must contain at least one (1) character from each of the three (3) groups below:', // new translation key: my-account__password-requirements__condition
        'passwordgroupupperLi': 'English uppercase characters (A through Z)', // new translation key: my-account__password-requirements__english-uppercase
        'passwordgrouplowerLi': 'English lowercase characters (a through z)', // new translation key: my-account__password-requirements__english-lowercase
        'passwordgroupnumberLi': 'Numerals (0 through 9)', // new translation key: my-account__password-requirements__numerals
        'passResetSent': 'Password Reset Request Sent', // new translation key: forgot-pass__request-sent
        'pleasecheckemailresetpass': 'Please check your email to reset your password.',
        'passresetchangeEmail': 'We have received a password change request for your HME CLOUD account: If you made this request, then please click on the link below or by pasting it into your browser\'s address bar. http://#APPLICATION.rootURL#/?pg=ManageAccount&st=rl&ruid=#Reset_UID# This link will work for 2 hours or until you reset your password. If you did not ask to change your password, then please ignore this email. Another user may have entered your username by mistake. No changes will be made to your account. The HME CLOUD team',
        'homepageNewFeatures': 'HME CLOUD has been updated with new features! ',
        'homepageNewFeaturesLink': 'Learn more',
        'homepageMaintenanceMessage': 'HME CLOUD will be maintained on',
        'homepageWebsiteAndLeaderboardMaintenance': 'The display of the Web site and the ranking in store will be temporarily unavailable',
        'homepageLeaderboardMaintenance': 'In-store ranking display will be temporarily unavailable',
        'homepageWelcomeMessage': 'Welcome to the HME CLOUD, ',
        'homepageStores': 'Stores', // new translation key: common__stores
        'homepageStoresDescription': 'View ZOOM settings for each store and access live dashboards.',
        'homepageUsers': 'Users', // new translation key: common__users
        'homepageUsersDescription': 'Add new users or modify existing users\' contact information and access to the store.',
        'homepageMyAccount': 'Accounts', // new translation key: common__accounts
        'homepageMyAccountDescription': 'Change the contact information for your account and change your username and password.',
        'trainingBannerLearnMore': 'HME Training Portal',
        'trainingBannerDescription': 'Find videos, supporting documents and other training guides.', // new translation key: help__training-portal__description
        'trainingBannerPortalLink': 'Visit the HME Training Portal >', // new translation key: help__training-portal__link
        'headerLoggedInAs': 'Logged in as ',
        'headerSignOut': 'Log Out', // new translation key: account-dropdown__log-out
        'navbarWelcome': 'Welcome', // new translation key: main-header__item--welcome
        'navbarDashboard': 'Dashboard', // new translation key: common__dashboard
        'navbarReports': 'Reports', // new translation key: common__reports
        'navbarPbiDashboard': 'PBI Dashboard', // new translation key: common__pbidashboard
        'navbarMyAccount': 'My Account', // new translation key: common__my-account
        'navbarSettings': 'Settings', // new translation key: common__settings
        'navbarHelp': 'Help', // new translation key: common__help
        'pleasecheckemailreport': 'Please check your email. Your Report has been sent to ', // new translation key: reports__please-check-email-report
        'summaryReport': 'Summary Reports', // new translation key: reports__summary-reports
        'brand': 'Brand', // new translation key: common__brand
        'store': 'Store', // new translation key: common__store
        'more': 'More', // new translation key: main-header__item--more
        'warningselect250stores': 'You may select up to 250 stores.',
        'warningselect100stores': 'You cannot exceed the default date range for reports with more than 100 stores.',
        'warningbigoperation': 'Warning: 100+ store reports will take a while before being emailed to you.',
        'warning100Plus': 'Warning: 100+ store report will generate an email with CSV file.', // new translation key: reports__warning-amount-limit
        'timemeasure': 'Time Measure', // new translation key: reports__time-measure
        'warningselect1stores': 'You may select only 1 store for Raw Data Reports.', // new translation key: reports__warning-select-one-store
        'from': 'From', // new translation key: common__from
        'to': 'To', // new translation key: common__to
        'advancedoptions': 'Advanced Options', // new translation key: reports__advanced-options
        'open': 'Open', // new translation key: common__open
        'close': 'Close', // new translation key: common__close
        'OPEN-CLOSE': 'OPEN - CLOSE', // new translation key: reports__open-close
        'type': 'Type', // new translation key: common__type
        'tempIdentification': 'The temporary identification code can be located in the body of the identification code email. The temporary code will expire 5 minutes after the email has been sent and will only be valid for a single use.', // new translation key: dfa-code__tooltip
        'timeslice': 'Time Slice', // new translation key: reports__time-slice
        'cumulative': 'Cumulative', // new translation key: reports__cumulative
        'reportsadvancedreport': '*Reports including Advanced Options are generated by CSV and sent by email.', // new translation key: reports__advanced-report
        'include': 'Include', // new translation key: common__include
        'exclude': 'Exclude', // new translation key: common__exclude
        'longesttimes': 'Longest Times', // new translation key: common__longest-times
        'systemstats': 'System Statistics', // new translation key: reports__system-stats
        'format': 'Format', // new translation key: common__format
        'secondswformat': 'Seconds (sec)', // new translation key: reports__seconds-format--text
        'minuteswformat': 'Minutes (min:sec)', // new translation key: reports__minutes-format--text
        'secondsShort': '(sec)', // new translation key: reports__seconds-format
        'minutesShort': '(min:sec)', // new translation key: reports__minutes-format
        'savedreporttemplates': 'Saved Report Templates', // new translation key: reports__saved-report-templates
        'generatereport': 'Generate Report', // new translation key: reports__generate-report
        'criteria': 'Criteria', // new translation key: common__criteria
        'stores': 'Stores', // new translation key: common__stores
        'saveastemplate': 'Save as Template', // new translation key: reports__save-as-template
        'namethistemplate': '(Name this template)', // new translation key: reports__name-this-template
        'hmeclouddashbord': 'HME CLOUD DASHBOARD', // new translation key: dashboard__hme-cloud-dashbord
        'rankingaccurate': 'Ranking accurate as of', // new translation key: dashboard__ranking-accurate
        'goal': 'Goal', // new translation key: common__goal
        'current': 'Current',
        'previous': 'Previous',
        'full': 'Full',
        'rank': 'Rank',
        'ranking': 'Ranking',
        'offline': 'offline', // new tranlation key: common__device__status--offline
        'Unavailable': 'Unavailable', // new translation key: common__device__status--unavailable
        'cars': 'Cars', // new translation key: common__cars
        'avg': 'Avg', // new translation key: common__avg
        'pullins': 'pullins',
        'includes': 'includes',
        'excludes': 'excludes',
        'thedata': 'The data', // new translation key: dashboard__data
        'customizedashboard': 'Customize Dashboard', // new translation key: dashboard__customize-dashboard
        'enableautorefresh': 'Enable Auto Refresh', // new translation key: dashboard__enable-auto-refresh
        'disableautorefresh': 'Disable Auto Refresh', // new translation key: dashboard__disable-auto-refresh
        'custom': 'Custom', // new translation key: common__custom
        'month': 'Month', // new translation key: common__month
        'Month': 'Month', // new translation key: common__month
        'week': 'Week', // new translation key: common__week
        'Week': 'Week', // new translation key: common__week
        'day': 'Day', // new translation key: common__day
        'Day': 'Day', // new translation key: common__day
        'daypart': 'Daypart', // new translation key: common__daypart
        'hour': 'Hour', // new translation key: common__hour
        'Hour': 'Hour', // new translation key: common__hour
        'HOUR_OPTION': 'hour(s)', // new translation key: announcement-settings__period-option--hour
        'MINUTE_OPTION': 'minute(s)', // new translation key: announcement-settings__period-option--minute
        'SECOND_OPTION': 'second(s)', // new translation key: announcement-settings__period-option--second
        'rollinghour': 'Rolling Hour', // new translation key: common__current-period--rollinghour
        'dashboardbeinggenerated': 'Please wait while your dashboard is being generated...', // new translation key: dashboard__loading-please-wait
        'dashboardtakeawhile': 'Please Note: This dashboard may take a while to load depending on the amount of data being requested.',
        'rawdatareport': 'Raw Data Report', // new translation key: reports__raw-data-report
        'none': 'None',
        'accountinfo': 'Account Information', // new translation key: my-account__account-info
        'recordsaved': 'Record Saved!', // new translation key: common__record-saved
        'userinfo': 'User Information', // new translation key: my-account__account-info__user-info
        'user name': 'User Name:', // new translation key: my-account__account-info__user-name
        'user': 'User', // new translation key: common__user
        'User': 'User', // new translation key: common__user
        'UnableToConnect': 'Unable to Connect', // new translation key: common__error--connect-unable
        'role': 'Role', // new translation key: common__role
        'userstoreaccess': 'User Store Access:', // new translation key: my-account__account-info__user-store-access
        'passchangereq': 'Password change required', // new translation key: my-account__account-info__change-password
        'currentpassword': 'Current Password:', // new translation key: my-account__account-info__current-password
        'confirmnewpassword': 'Confirm New Password:', // new translation key: my-account__account-info__confirm-new-password
        'jobtitle': 'Job Title:', // new translation key: common__job-title
        'officenum': 'Office Number:', // new translation key: common__office-number
        'mobilephone': 'Mobile Phone:', // new translation key: common__mobile-phone
        'fax': 'Fax:', // new translation key: common__fax
        'termagreementreq': 'Terms Agreement required', // new translation key: my-account-warning__terms-agreement-required
        'agreetohme': 'I agree to HME\'s', // new translation key: my-account__account-info__agree-to-hme
        'termsofservice': 'terms of service', // new translation key: my-account__account-info__terms-of-service
        'sharedatawithcorp': 'Share Data With Corporate', // new translation key: my-account__account-info__share-data-with-corp
        'shared': 'Shared', // new translation key: my-account__account-info__shared
        'notshared': 'Not Shared', // new translation key: my-account__account-info__not-shared
        'cancel': 'Cancel', // new translation key: common__cancel
        'on': 'ON',
        'off': 'OFF',
        'continue': 'Continue', // new translation key: common__continue
        'save': 'Save', // new translation key: common__save
        'companyinfo': 'Company Information', // new translation key: my-account__company-info
        'address1': 'Address Line 1', // new translation key: common__address-line--1
        'address2': 'Address Line 2', // new translation key: common__address-line--2
        'address3': 'Address Line 3', // new translation key: common__address-line--3
        'address4': 'Address Line 4', // new translation key: common__address-line--4
        'city': 'City:', // new translation key: common__city
        'country': 'Country:', // new translation key: common__country
        'state': 'State', // new translation key: common__state
        'selstate': '***Select Your State***', // new translation key: my-account__account-info__select-state
        'usterrcomm': '***US Territores/Commonwealth***', // new translation key: my-account__company-info__regions__usa-territories
        'zip': 'Zip:', // new translation key: common__zip
        'region': 'Region', // new translation key: common__region
        'distributor': 'Distributor:', // new translation key: common__distributor
        'distributor_view': 'Distributor',  // new translation key: common__distributor
        'phone': 'Phone:', // new translation key: common__phone
        'users': 'Users', // new translation key: common__users
        'addauser': 'Add a User', // new translation key: users__add-user-btn
        'addanaccount': 'Add an account',
        'expiredaccouts': 'Expired accounts',
        'accountrenewal': 'Account renewals',
        'freeaccounts': 'Free accounts',
        'viewedit': 'View/Edit', // new translation key: common__view-edit
        'userdetails': 'User Details', // new translation key: add-user__title
        'usernameemail': 'Username(Email Address):', // new translation key: add-user__label--username
        'removeuser': 'Remove User', // new translation key: add-user__remove-btn
        'areyousureremoveuser': 'Are you sure you want to remove this user?', // new translation key: add-user__confirm--remove
        'areyousuredeleterole': 'Are you sure you want to delete this role?',
        'areyousureremovegroup': 'Removing this Leaderboard will cause all stores associated with it to instead show the Default Leaderboard.\n\n Are you sure you want to remove this Leaderboard?', // new translation key: add-group__remove-confirm__body
        'lock': 'lock', // new translation key: lock-device-settings__settings-action--lock
        'unlock': 'unlock', // new translation key: lock-device-settings__settings-action--unlock
        'Locked': 'Locked', // new translation key: common__device__settings--locked
        'Unlocked': 'Unlocked', // new translation key: common__device__settings--unlocked
        'noDevicesForLock': 'Settings can only be locked on ZOOM devices that are on System Version 3.13 or higher.', // new translation key: lock-device-settings__settings-lock-limit
        'lockActionDescription': 'This action will lock all settings except those related to Passwords, Reports, Lane Configuration, and the Account Email Address on all devices that you have selected.', // new translation key: lock-device-settings__lock-action-description
        'ifsomeonechangessettings': 'If someone changes the settings on a device with locked settings, the settings will revert back to the locked settings, with the exception of the four categories listed above.', // new translation key: lock-device-settings__settings-changes-description
        'settingscannotbelockedondevices': 'Settings cannot be locked on devices on a System Version prior to Version 3.12.', // new translation key: lock-device-settings__settings-lock-description
        'areyousurelockdevicesettings': 'Are you sure you want to {action} the settings on these devices?', // new translation key: lock-device-settings__settings-action--question
        'confirmToDelete': 'Confirm to Delete', // new translation key: common__confirm--delete
        'ConfirmationRequired': 'Confirmation Required', // new translation key: common__confirmation-required
        'CIBSystem_Remove_Popup_Message': 'Are you sure you would like to remove the selected system?', // new translation key: admin__unregister-system__confirm--remove__message
        'yes': 'Yes', // new translation key: common__yes
        'no': 'No', // new translation key: common__no
        'no_go_back': 'No, go back', // new translation key: common__no-go-back
        'status': 'Status', // new translation key: common__status
        'inactive': 'inactive', // new translation key: common__inactive
        'Active': 'Active', // new translation key: common__active
        'Inactive': 'Inactive', // new translation key: common__inactive
        'userrole': 'User Role:',
        'userAccessLevel': 'User Access Level',
        'storeaccess': 'Store Access:', // new translation key: add-user__label--store-access
        'selectall': 'Select All', // new translation key: common__select-all
        'deselectall': 'Deselect All', // new translation key: common__deselect-all
        'roles': 'Roles', // new translation key: common__roles
        'addarole': 'Add a Role', // new translation key: roles__add-btn
        'company': 'Company', // new translation key: common__company
        'roledescription': 'Role Description', // new translation key: roles__grid__header--description, a part of add-role__label--role-description
        'optional': '(optional)', // a part of new translation key: add-role__label--role-description
        'deleterole': 'Delete Role', // new translation key: add-role__delete-btn
        'devices': 'devices',
        'Rolehasuser': 'Role', // a part of new translation key: add-role__error--role-assigned
        'rolename': 'Role Name:', // new translation key: add-role__label--role-name
        'roledetails': 'Role Details', // new translation key: add-role__title
        'leavetheopencloseboxes': 'Leave the Open and/or Close boxes checked if you want the start and/or end times to remain as configured in the ZOOM timer.  Uncheck the box(es) if you want to choose a specific start and/or end time.', // new translation key: reports__leave-the-open-close-boxes
        'choosetimeslice': 'Choose Time Slice if you are only interested in a narrow window of time within each daypart, day, or week.  Use the controls above to choose a start and end time.', // new translation key: reports__choose-time-slice
        'choosecumulative': 'Choose Cumulative if you want the report to include everything from the chosen start  date and time to the chosen end date and time.', // new translation key: reports__choose-cumulative
        'chooseseconds': 'Choose Seconds if you want your report in seconds.  Choose Minutes if you want your report in minutes:seconds.', // new translation key: reports__choose-seconds
        'selectapreviousreport': 'Select a previously saved report template to run a common report, or select new options and save a new report template to use later.', // new translation key: reports__select-previous-report
        'selectonestore': 'Select one store for a single-store report.  Select multiple stores for a comparison report.', // new translation key: reports__select-one-store
        'thereportwillsummarize': 'The report will summarize or average the data over the time intervals you choose. For example, choose week if you want all of the days of each rolled up into one-week intervals. Only one store may be selected at a time for a Raw Data Report.', // new translation key: reports__the-report-will-summarize
        'choosestartorend': 'Choose a start and end date for the overall time period of the report. Single store can select any 2 month period. Multiple store can select any 1 month period.',
        'choosestartandend': 'Choose a start and end date for the overall time period of the report. Maximum 100 stores can be selected for 1 month period', // new translation key: reports__choose-start-and-end
        'settingsStoresBrand': 'Brand', // new translation key: common__brand
        'settingsStoresStoreName': 'Store Name', // new translation key: common__store__name
        'settingsStoresStoreNumber': 'Store Number', // new translation key: common__store__number--text
        'store #': 'Store #', // new translation key: common__store__number
        'address': 'Address', // new translation key: common__address
        'settingsStatus': 'Settings Status', // new translation key: common__settings__status
        'settingsStoresAddressLineOne': 'Address Line 1', // new translation key: common__address-line--1
        'settingsStoresAddressLineTwo': 'Address Line 2', // new translation key: common__address-line--2
        'settingsStoresAddressLineThree': 'Address Line 3', // new translation key: common__address-line--3
        'settingsStoresAddressLineFour': 'Address Line 4', // new translation key: common__address-line--4
        'settingsStoresStoreCity': 'City', // new translation key: common__city
        'settingsStoresStoreState': 'State:', // new translation key: common__state
        'settingsStoresStoreRegion': 'Region', // new translation key: common__region
        'settingsStoresZip': 'Zip:', // new translation key: common__zip
        'settingsStoresCountry': 'Country:', // new translation key: common__country
        'settingsStoresPhone': 'Phone:', // new translation key: common__phone
        'settingsStoresFax': 'Fax:', // new translation key: common__fax
        'settingsStoresAXCustomerAccount': 'AX Customer Account #:', // new translation key: common__ax-account--customer
        'settingsStoresAXInvoiceAccount': 'AX Invoice Account #:', // new translation key: common__ax-account--invoice
        'settingsStoresEVDSubscription': 'EVD Subscription:', // new translation key: common__subscription--evd
        'settingsStoresNEXEOSubscription': 'NEXEO Subscription:', // new translation key: common__subscription--nexeo
        'settingsStoresCancel': 'Cancel', // new translation key: common__cancel. Not Replaced yet
        'settingsStoresRegisteredSystems': 'Associated Systems', // new translation key: store-details__associated-systems
        'settingsStoresSystemName': 'System Name', // new translation key: common__system__name
        'settingsStoresSystemVersion': 'System Version', // new translation key: common__system__version
        'settingsStoresSerialNumber': 'Serial Number', // new translation key: common__device__serial-number--text
        'settingsStoresMacAddress': 'MAC Address', // new translation key: system-status__mac-address
        'settingsStoresScheduledUpgrade': 'Scheduled Upgrade', // new translation key: system-status__scheduled-upgrade
        'settingsStoresSystemStatus': 'System Status', // new translation key: common__system__status
        'settingsStoresOnline': 'Online', // new translation key: common__device__status--online
        'settingsStoresOffline': 'Offline', // new tranlation key: common__device__status--offline
        'settingsStoresNotAvailable': 'Not Available',
        'settingsStoresViewDetails': 'View Details', // new translation key: stores__modal__view-details
        'storeSettingsConfirmUpdateSucessful': 'Update Successful!', // new translation key: stores__success--store-saved
        'storeSettingsConfirmError': 'The following errors occured during your request:', // new translation key: error-page__title
        'storeSettingsConfirmTransferSucessful': 'Transfer Successful!',
        'storeSettingsConfirmFiveMinuteNotice1': 'Please Note:',
        'storeSettingsConfirmFiveMinuteNotice2': 'The settings changes will take approximately 5 minutes to take effect.',
        'storeSettingsConfirmUpgradeSucessful': 'Upgrades Successful!',
        'storeListRecordSaved': 'Record Saved!', // new translation key: common__record-saved
        'storeListRecordRemoved': 'Record Removed!',
        'storeListStores': 'Stores', // new translation key: common__stores
        'storeListStoreDetails': 'Store Details', // new translation key: stores__modal__tab--details__title
        'settingsStoresCIBSettings': 'CIB Settings', // new translation key: stores__modal__cib-settings
        'settingsStoresCIBEmail': 'Email:', // new translation key: common__email
        'settingsStoresCIBBrand': 'Brand', // new translation key: common__brand
        'settingsStoresCIBTimeZone': 'Time Zone: ', // new translation key: common__timezone
        'settingsStoresRegisteredCIB': 'Registered CIB', // new translation key: common__device--registered
        'settingsStoresRegisteredEOS': 'Registered EOS', // new translation key: common__device--registered
        'settingsStoresRegisteredLinkWear': 'Registered LinkWear', // new translation key: common__device--registered
        'settingsStoresRegisteredION': 'Registered ION', // new translation key: common__device--registered
        'settingsStoresRegisteredZOOM': 'Registered ZOOM', // new translation key: common__device--registered
        'settingsStoresRegisteredEVD': 'Registered ZOOM Nitro/EVD', // new translation key: common__device--registered
        'settingsStoresPreconfiguredZOOM': 'Pre-configured ZOOM', // new translation key: common__device--pre-configured
        'settingsStoresPreconfiguredEVD': 'Pre-configured ZOOM Nitro/EVD', // new translation key: common__device--pre-configured
        'settingsStoresCorporateStore': 'Corporate Store', // new translation key: stores__corporate-store
        'settingsStoresNoStores': 'You have no stores', // new translation key: common__no-stores--exist
        'settingsStoresLoadingMessage1': 'Please wait while your request is being processed...', // new translation key: common__loading__request-being-processed
        'settingsStoresLoadingMessage2': 'Please Note: This may take a while to load depending on the amount of data being requested.',
        'settingsStoresAreYouSure': 'Are you sure you would like to update the selected store(s)?',
        'settingsStoresDetails': 'Details', // new translation key: common__details
        'settingsUsersDetails': 'User', // new translation key: common__user
        'settingsStoresUnavailable': 'Unavailable', // new translation key: common__device__status--unavailable
        'secondswithabb': 'Seconds (sec)',
        'minuteswithabb': 'Minutes (min:sec)',
        'SelectAStore': 'Select a Store', // new translation key: common__store__select-store
        'pleasefillinfirstname': 'Please fill in the user\'s first name.', // new translation key: add-user__error--first-name-empty
        'pleasefillinlastname': 'Please fill in the user\'s last name.', // new translation key: add-user__error--last-name-empty
        'savesuccessful': 'Save Successful!', // new translation key: my-account__success__save
        'new': 'New',
        'confirmed': 'Confirmed',
        'passwordnotmatchsystem': 'The password does not match our system.',
        'selectallapply': 'Select all that apply',
        'pleaseassignrole': 'Please assign a role to this user.', // new translation key: add-user__error--role-unassigned
        'selectuserstatus': 'Please select the user\'s status.',
        'useremailalready': 'User email address already in use.',
        'rolenotfound': 'Role not found.',
        'userrecordnotfound': 'User record not found.',
        'willrecieveemail': 'will receive an email containing the necessary login information.', // a part of new translation key: add-user__recieve-email
        'theuser': 'The user', // a part of new translation key: add-user__recieve-email
        'download': 'Download', // new translation key: common__download
        'companyblank': 'Company name may not be blank.', // new translation key: my-account__error__blank-company-name
        'companyaddressblank': 'Please fill in your company address.', // new translation key: my-account__error__blank-company-address
        'companycityblank': 'Please fill in your company city.', // new translation key: my-account__error__blank-company-city
        'zipcodeblank': 'Please provide your zip code.', // new translation key: my-account__error__blank-company-zipcode
        'zipcodeinvalid': 'Please provide a valid zip code.', // new translation key: my-account__error__invalid-company-zipcode
        'companyphoneblank': 'Please provide your company phone number.', // new translation key: my-account__error__blank-company-phone
        'settingsStoresErrorPickBrand': 'Please pick a brand.',
        'settingsStoresErrorNeedsStoreNum': 'Please fill in your store number.',
        'settingsStoresErrorNeedsAddress': 'Please fill in your store address.',
        'settingsStoresErrorNeedsCity': 'Please fill in your store city.',
        'settingsStoresErrorNeedsState': 'Please provide your state.',
        'settingsStoresErrorNeedsZip': 'Please provide your zip code.',
        'settingsStoresErrorNeedsZip2': 'Please provide a valid zip code.',
        'settingsStoresErrorNeedsNumber': 'Please provide your store phone number.',
        'settingsStoresErrorNeedsNewAddress': 'This address is already in use. Please provide a unique address.',
        'storeSettingsConfirmDeletion': 'Are you sure you want to delete this store?\nThis action cannot be undone.',
        'StoreSettingsHeaderBrand': 'Brand', // new translation key: common__brand
        'StoreSettingsHeaderStore': 'Store',
        'StoreSettingsHeaderStoreNumber': 'Store Number', // new translation key: common__store__number--text
        'StoreSettingsHeaderStoreName': 'Store Name', // new translation key: common__store__name
        'StoreSettingsHeaderAddress': 'Store Address', // new translation key: common__store__address
        'StoreSettingsHeaderCityState': 'City, State', // new translation key: stores__list-header__city-state
        'StoreSettingsHeaderName': 'System Name', // new translation key: common__system__name
        'StoreSettingsHeaderVersion': 'System Version', // new translation key: common__system__version
        'StoreSettingsHeaderSerialNumber': 'Serial #', // new translation key: common__device__serial-number
        'StoreSettingsHeaderStatus': 'System Status', // new translation key: common__system__status
        'StoreSettingsErrorSelectionInvalid': 'Please select a valid store.',
        'StoreSettingsErrorNameInvalid': 'Please enter an appropriate Store Name.', // new translation key: stores__error--invalid-store-name
        'StoreSettingsErrorProcessing': 'There has been an error processing your request, please try again later.', // new translation key: common__error-processing-request
        'StoreSettingsErrorReachingQueue': 'Queue system unreachable. Please try your request again later.',
        'StoreSettingsErrorSystemOffline': 'System must be online to change system settings.',
        'StoreSettingsErrorMakeSelection': 'You must select 1 or more systems to change settings.',
        'systemSettingsZoomUnreachable': 'Zoom system unreachable. Please try your request again later.',
        'systemSettingsNoSelection': 'You must select 1 or more systems to initiate upgrades.',
        'newFeaturesHeader': 'Save Report Templates',
        'newFeaturesFeature1': 'You can now save any report with the new \'Saved Report Templates\' feature',
        'newFeaturesFeature2': 'Run a report just as you normally would from the report generator',
        'newFeaturesFeature3': 'Check the box next to \'Save as Template\' and give the report a name',
        'newFeaturesFeature4': 'Click on the \'Generate Report\'',
        'newFeaturesFeature5': 'The report will now appear underneath your list of \'Saved Report Templates\'',
        'newFeaturesFeature6': 'Users may save up to 10 templates.',
        'newFeaturesFeature7': 'To rerun a template simply click on the name of the report you wish to view and click on \'Generate Report\'',
        'newFeaturesMajorHeader': 'New Features in the HME CLOUD',
        'dashboardcustomization': 'Dashboard Customization Successful!', // new translation key: dashboard__customization-successful
        'pleaseselectdashboard': 'Please select a valid Dashboard.',
        'pleaseselectdashboardorder': 'Please select a valid Dashboard Order.',
        'deleteroledialog': 'Are you sure you want to delete this role?\nThis action cannot be undone.', // new translation key: add-role__modal--delete__body
        'rolehasuser': 'cannot be deleted with users assigned to it. Please re-assign these (1) users before deleting this role.', // a part of new translation key: add-role__error--role-assigned
        'savechanges': 'Save Changes', // new translation key: dashboard__save-changes
        'confirm': 'Confirm', // new translation key: common__confirm
        'userremoved': 'User Removed!',
        'leaderboardsettings': 'Leaderboard Settings', // new translation key: display-settings--leaderboard__title
        'resettodefaults': 'Reset to defaults', // new translation key: common__reset-to-defaults
        'customizeleaderboard': 'Customize Leaderboard', // new translation key: common__customize-lb
        'createcustombyselecting': 'To create a customized dashboard, begin by selecting a time measurement', // new translation key: dashboard__tips--create-customized-dashboard
        'usingplussign': 'using the plus sign in an open column.', // new translation key: dashboard__tips--create-customized-dashboard
        'toremovekpi': 'To remove a KPI', // new translation key: dashboard__tips--remove-kpi
        'fromatimemeasurement': 'from a time measurement, click on the icon next to the KPI you\'d like to remove.', // new translation key: dashboard__tips--remove-kpi
        'addkpiuseplus': 'To add a KPI, use the small plus sign inside of the time measurement you are configuring.',
        'youmayselectdefault': 'You may also select a default KPI to establish the ranking of all your stores by using the icon next to the KPI you wish to use.',
        'torearrangetimemeasurement': 'To rearrange any time measurement or KPI within a given time measurement simply click on the column heading you want to move and dragging it to a new location.',
        'validtimemeasure': 'Valid time measurements are Current Hour, Current Daypart, Current Day, Rolling Hour, Previous Hour, Previous Daypart, Previous Day and Previous Rolling Hour.',
        'validkpicars': 'Valid Key Performance Indicators (KPI) are Cars, Avg and Goal%.',
        'carsaretotalnumber': 'Cars are the total number of cars that have been counted during for its respective time measurement; Avg is the average total 1 time for its respective time measurement; and Goal% is the percentage of cars that have come in underneath their Goal A times for its respective time measurement. ',
        'primarygoalcolors': 'Primary Goal Colors', // new translation key: leaderboard__template__primary-goal-colors
        'selectcustomgoalcolor': 'Select a custom goal color for your master A, B and over B goals by using the color pickers',
        'thesecolorsallow': 'These colors will allow you to easily identify the performance metrics for each of your stores.',
        'youcanmanuallyenterhex': 'You can also manually enter the hexadecimal value of the color if you prefer.',
        'timeformat': 'Time Format',
        'selectdefaulttime': 'Select the default time format',
        'thatwillbeshowndashboard': 'that will be shown on your dashboards and reports.',
        'timeformatmaybeshown': 'Time format may be shown in minutes:seconds or in seconds.',
        'Pullins': 'Pull-ins', // new translation key: common__pull-ins
        'fordashchoosepull': 'For dashboards and reports, you can choose to either include pull-in',
        'dataorexclude': 'data or exclude it.',
        'apullinisavehicle': 'A pull-in is a vehicle that entered the drive-thru lane after the first ON detection point, and exited through the last ON detection point.',
        'language': 'Language', // new translation key: language-select__label
        'changethelanguage': 'Change the language.',
        'seconds': 'Seconds',
        'minutesseconds': 'Minutes:Seconds',
        'currentrollinghour': 'Current Rolling Hour',
        'Stores': 'Stores', // new translation key: common__stores
        'Current Hour': 'Current Hour',
        'Previous Hour': 'Previous Hour',
        'Current Day': 'Current Day', // new translation key: welcome__tile--store-ranking__header part of it
        'Previous Day': 'Previous Day',
        'Current Daypart': 'Current Daypart',
        'Previous Daypart': 'Previous Daypart',
        'Current Rolling Hour': 'Current Rolling Hour',
        'current rollinghour': 'Current Rolling Hour',
        'Previous Rolling Hour': 'Previous Rolling Hour',
        'current hour': 'Current Hour',
        'previous hour': 'Previous Hour',
        'current day': 'Current Day', // new translation key: welcome__tile--store-ranking__header part of it
        'previous day': 'Previous Day',
        'current daypart': 'Current Daypart',
        'previous daypart': 'Previous Daypart',
        'current rolling hour': 'Current Rolling Hour',
        'previous rolling hour': 'Previous Rolling Hour',
        'previous rollinghour': 'Previous Rolling Hour',
        'Rank': 'Rank',
        'Store': 'Store', // new translation key: common__store
        'Name': 'Name', // new translation key: common__name
        'Goal': 'Goal', // new translation key: common__goal
        'Cars': 'Cars', // new translation key: common__cars
        'Avg': 'Avg', // new translation key: common__avg
        'Goal%': 'Goal%',  // probably can be used as new translation key: common__goal--percent
        'setdefaultsort': 'Set Ranking Metric', // new trasnlation key: common__set-ranking-metric
        'removecolumm': 'Remove Column', // new translation key: common__remove-column
        'leaderboardCustomSuccess': 'Leaderboard Customization Successful! Changes will take effect at next page refresh.',
        'leaderboardResetSuccess': 'Leaderboard Reset Successful! Changes will take effect at next page refresh.',
        'createacustomizeddashboard': 'To create a customized dashboard, begin by selecting a time measurement', // new translation key: dashboard__tips--create-customized-dashboard
        'usingtheplussign': 'using the plus sign in an open column.', // new translation key: dashboard__tips--create-customized-dashboard
        'toaddakpi': 'To add a KPI, use the small plus sign inside of the time measurement you are configuring.', // new translation key: dashboard__tips--add-kpi
        'youmayalsoselectdefaultKPI': 'You may also select a default KPI to establish the ranking of all your stores by using the icon next to the KPI you wish to use.', // new translation key: dashboard__tips--select-kpi
        'rearrangeanytimemeasurement': 'To rearrange any time measurement or KPI within a given time measurement simply click on the column heading you want to move and dragging it to a new location.', // new translation key: dashboard__tips--rearrange-time-measurement
        'validtimemeasurements': 'Valid time measurements are Current Daypart, Current Day, Current Week, Current Month, Previous Daypart, Previous Day, Previous Week and Previous Month.',
        'validkpis': 'Valid Key Performance Indicators (KPI) are Cars, Avg and Goal%.',
        'carsarethetotal': 'Cars are the total number of cars that have been counted during for its respective time measurement; Avg is the average total 1 time for its respective time measurement; and Goal% is the percentage of cars that have come in underneath their Goal A times for its respective time measurement.',
        'Current Week': 'Current Week',
        'Previous Week': 'Previous Week',
        'Current Month': 'Current Month',
        'Previous Month': 'Previous Month',
        'current week': 'Current Week',
        'previous week': 'Previous Week',
        'current month': 'Current Month',
        'previous month': 'Previous Month',
        'selectatleastinecolumn': 'You must select at least one column of data.',
        'error': 'Error!',
        'ManageRole': 'Manage Role',
        'AddUser': 'Add User', // new tranlsation key: common__permission--add-user
        'RemoveUser': 'Remove User', // new translation key: common__permission--remove-user
        'EditStoreBasic': 'Edit Store Basic', // new translation key: common__permission--edit-store-basic
        'EditStoreAdvanced': 'Edit Store Advanced', // new translation key: common__permission--edit-store-advanced
        'EditUser': 'Edit User', // new translation key: common__permission--edit-user
        'RemoteConnect': 'Remote Connect', // new translation key: common__permission--remote-connect
        'EditLeaderboard': 'Edit Leaderboard', // new translation key: common__permission--edit-leaderboard
        'ViewLeaderboard': 'View Leaderboard', // new translation key: common__permission--view-leaderboard
        'PerformDeviceRestart': 'Perform Device Restart', // new translation key: common__permission--perform-device-restart
        'ViewAllStores': 'View All Stores', // new translation key: common__permission--view-all-stores
        'ViewEditContests': 'Add/Edit Contest', // new translation key: common__permission--view-edit-contests
        'selectatleastinecolumn': 'You must select at least one column of data.', // new translation key: dashboard__select-one-column
        'error': 'Error!', // new translation key: common__error
        'ApplyDeviceSettings': 'Apply Device Settings', // new translation key: common__apply-device-settings
        'Basic': 'Basic', // new translation key: add-role__group--basic
        'Admin': 'Admin',
        'Owner': 'Owner', // new translation key: common__owner
        'Participant': 'Participant', // new translation key: add-leaderboard__participant
        'EditDeviceSettings': 'Edit Device Settings', // new translation key: common__permission--edit-device-settings
        'lockDeviceSettings': 'Lock Device Settings', // new translation key: sub-header--settings__lock-device-settings
        'lockSettings': 'Lock Settings', // new translation key: lock-device-settings__lock-settings
        'unlockSettings': 'Unlock Settings', // new translation key: lock-device-settings__unlock-settings
        'rolenameblank': 'Role name may not be blank.', // new translation key: add-role__error--role-name-empty
        'pleaseselectonepermission': 'Please select at least one(1) permission for this role.', // new translation key: add-role__error--no-permission
        'rolenameinvalid': 'Role is not valid.',
        'roleremoved': 'Role Removed!', // new translation key: add-role__success--removed
        'cannotbedeleted': 'cannot be deleted with users assigned to it. Please re-assign these',
        'usersbeforedeleting': 'users before deleting this role.',
        'storerankingbylane': 'Store Ranking by Lane Total AVG Time', // new translation key: dashboard__store-ranking-by-lane
        'top3stores': 'Top 3 Stores', // new translation key: welcome__tile--store-ranking__top-stores
        'lanetransactions': 'Lane Transactions', // new translation key: dashboard__lane-transactions
        'TRANSACTIONS': 'TRANSACTIONS', // new translation key: dashboard__transactions
        'AVG TIME (sec)': 'AVG TIME (sec)', // new translation key: dashboard__avg-time-title
        'lanetotalavgtime': 'Lane Total Avg Time', // new translation key: dashboard__lane-total-avg-time
        'Queue': 'Queue',
        'Service': 'Service', // new translation key: reports__service
        'Menu Board': 'Menu Board',
        'CURRENT DP': 'CURRENT DP', // new translation key: dashboard__current-dp
        'null': 'null',
        'Lane Total': 'Lane Total', // new translation key: common__lane-total
        'Lane 1 Total': 'Lane 1 Total', // new translation key: common__lane-1-total-1
        'Lane 1 Total 2': 'Lane 1 Total 2', // new translation key: common__lane-1-total-2
        'Lane 2 Total': 'Lane 2 Total', // new translation key: common__lane-2-total-1
        'Lane 2 Total 2': 'Lane 2 Total 2', // new translation key: common__lane-2-total-2
        'Average Current': 'Average Current', // new translation key: common__average-current
        'Status': 'Status', // new translation key: common__status
        'Please wait while your dashboard': 'Please wait while your dashboard is being generated...', // new translation key: dashboard__loading-please-wait
        'Please Note: This dashboard': 'Please Note: This dashboard may take a while to load depending on the amount of data being requested.', // new translation key: dashboard__loading
        'Daypart': 'Daypart',
        'Store ': 'Store ',
        'Number': 'Number',
        'invalidreporttemplate': 'Invalid Report Template.',
        'pleaseselectopentime': 'Please select an open time', // new translation key: reports__please-select-open-time
        'pleaseselectclosetime': 'Please select a close time.', // new translation key: reports__please-select-close-time
        'pleaseselectstore': 'Please select a Store', // new translation key: reports__please-select-store
        'invalidselectiononestore': 'Invalid selection. Please select no more than 1 store for a Raw Data Report', // new translation key: reports__store-selection-invalid--1
        'Please select a Start Date': 'Please select a Start Date',
        'Please select an End Date': 'Please select an End Date',
        'daterangeinvalid3month': 'Date range invalid. For Advanced Options Reports select any 3 month period.', // new translation key: reports__date-range-invalid--3-month
        'daterangeinvalid2week': 'Date range invalid. For Daypart Reports select any 2 week period.', // new translation key: reports__date-range-invalid--2-week
        'daterangeinvalid2month': 'Date range invalid. For Week Reports select any 2 month period.', // new translation key: reports__date-range-invalid--2-month
        'daterangeinvalidmonthreport3month': 'Date range invalid. For Month Reports select any 3 month period.',
        'daterangeinvalidsingleday': 'Date range invalid. For Raw Data Reports select a single day.', // new translation key: reports__date-range-invalid--single-day
        'daterangeinvalid1month': 'Date range invalid. For Day Reports select any 1 month period.', // new translation key: reports__date-range-invalid--1-month
        'daterangeinvalidbeyond': 'Date range invalid. Starting date may not be beyond ending date.', // new translation key: reports__date-range-invalid--beyond
        'storeselectioninvalid1000': 'Store selection invalid. You may select up to 1000 stores from the list below.', // new translation key: reports__store-selection-invalid--1000
        'storeselectioninvalid100': 'Store selection invalid. You may select up to 100 stores from the list below.',
        'pleaseneteratemplatename': 'Please enter a template name to save a template', // new translation key: reports__please-enter-template-name
        'savedtemplatelimit': 'You have reached the saved template limit of 10. Please remove an existing template to save a new template',
        'pleaseenterauniquetemplate': 'Please enter a unique template name to save this template', // new translation key: reports__please-enter-unique-template
        'pleasechecksaveastemplate': 'Please check the \'Save as Template\' box along with a template name to save a template', // new translation key: reports__please-check-save-as-template
        'Rolling Hour': 'Rolling Hour',
        'Previous Full Hour': 'Previous Full Hour',
        'Previous Full Rolling Hour': 'Previous Full Rolling Hour',
        'Current Rollinghour': 'Current Rolling Hour',
        'Previous Full Rollinghour': 'Previous Full Rolling Hour',
        'Previous Full Day': 'Previous Full Day',
        'Previous Full Daypart': 'Previous Full Daypart',
        'Previous Full Week': 'Previous Full Week',
        'Previous Full Month': 'Previous Full Month',
        'ReportsSummarizedReport': 'Summarized Report', // new translation key: reports__summarized-report
        'ReportsStart': 'Start Time: ', // new translation key: reports__start-time
        'ReportsEnd': 'End Time: ', // new translation key: reports__end-time
        'ReportsPrintDate': 'Print Date: ', // new translation key: reports__print-date
        'ReportsPrintTime': 'Print Time: ', // new translation key: reports__print-time
        'ReportsPage': 'Page ', // new translation key: common__page-navs
        'ReportsOf': 'of ', // new translation key: common__page-navs
        'ReportsStop': 'Stop Time: ', // new translation key: reports__stop-time
        'ReportsLongest': 'Longest', // new translation key: reports__longest
        'ReportsTimes': 'Times', // new translation key: reports__times
        'ReportsGoalsStatsFor': 'GOALS STATISTICS FOR', // new translation key: reports__goals-stats-for
        'ReportsAveragePerformancePerEvent': 'AVERAGE PERFORMANCE PER EVENT', // new translation key: reports__average-performance-per-event
        'ReportsGoalA': 'Goal A',
        'ReportsGoalB': 'Goal B',
        'ReportsGoalC': 'Goal C',
        'ReportsGoalD': 'Goal D',
        'ReportsDerivedPerformancetoGoal': ' Derived performance to goal (Lane Queue goal = Lane Total goal - Menu goal - Service goal)', // new translation key: reports__derived-performance-to-goal
        'ReportsLane': 'Lane', // new translation key: reports__lane
        'ReportsAverageCarsInLane': 'Average Cars in Lane', // new translation key: reports__average-cars-in-lane
        'ReportsTotalPullouts': 'Total Pullouts', // new translation key: reports__total-pullouts
        'ReportsTotalPullins': 'Total Pullins', // new translation key: reports__total-pullins
        'ReportsDeleteOverMax': 'Delete Over Maximum', // new translation key: reports__delete-over-maximum
        'ReportsPowerFails': 'Power Fails', // new translation key: reports__power-fails
        'ReportsSystemResets': 'System Resets', // new translation key: reports__system-resets
        'ReportsVBDResets': 'VBD Resets', // new translation key: reports__vbd-resets
        'ReportsLoadingPleaseWait': 'Please wait while your report is being generated...', // new translation key: reports__loading-please-wait
        'ReportsPleaseNote': 'Please Note: This report may take a while to load depending on the amount of data being requested.', // new translation key: reports__please-note
        'Default Dashboard View': 'Default Dashboard View', // new translation key: display-settings__title--dashboard
        'Display Settings': 'Display Settings', // new translation key: display-settings__title
        'Select a default view for your': 'Select a default view for your single-store and multi-store dashboards. By selecting either daypart, day, week, month or custom', // a part of new translation key: display-settings__tip--default-view
        'This will be the view you': 'This will be the view you first see when clicking on the ZOOM Dashboard link from the navigation bar.', // a part of new translation key: display-settings__tip--default-view
        'You must first configure a customized': 'You must first configure a customized dashboard before selecting the custom option as your default view.', // a part of new translation key: display-settings__tip--default-view
        'Select a custom goal color': 'Select a custom goal color for your master A, B and over B goals by using the color pickers',
        'These colors will allow you to easily': 'These colors will allow you to easily identify the performance metrics for each of your stores.',
        'You can also manually enter the hexadecimal': 'You can also manually enter the hexadecimal value of the color if you prefer.',
        'Select the default time format': 'Select the default time format', // a part of new translation key: display-settings__tip--time-format
        'that will be shown on your': 'that will be shown on your dashboards and reports.', // a part of new translation key: display-settings__tip--time-format
        'Time format may be shown in minutes:seconds': 'Time format may be shown in minutes:seconds or in seconds.', // a part of new translation key: display-settings__tip--time-format
        'For dashboards and reports': 'For dashboards and reports, you can choose to either include pull-in', // a part of new translation key: display-settings__tip--pullins
        'data or exclude it': 'data or exclude it.', // a part of new translation key: display-settings__tip--pullins
        'A pull-in is a vehicle that entered': 'A pull-in is a vehicle that entered the drive-thru lane after the first ON detection point, and exited through the last ON detection point.', // a part of new translation key: display-settings__tip--pullins
        'Store abbrev': 'Store',
        'Display Settings changes successful': 'Display Settings changes successful', // new translation key: display-settings__success--saved
        'ReportsAll': 'All', // new translation key: common__all
        'all': 'All', // new translation key: common__all
        'ReportsAverageTime': 'AVERAGE TIME', // new translation key: reports__average-time
        'ReportsWeightedAverage': '(W-Avg)',
        'ReportsShow': 'Show', // new translation key: common__show
        'ReportsTotalCars': 'Total Cars', // new translation key: reports__total-cars
        'Total Cars': 'Total Cars', // new translation key: reports__total-cars
        'ReportsPages': 'Pages', // new translation key: common__pages
        'ReportsOpen': 'OPEN', // new translation key: common__open
        'ReportsClose': 'CLOSE', // new translation key: common__close
        'ReportsOr': 'OR',
        'Greet': 'Greet', // new translation key: common__greet
        'Greet1': 'Greet 1', // new translation key: common__greet-1
        'Greet2': 'Greet 2', // new translation key: common__greet-2
        'Greet 1': 'Greet 1', // new translation key: common__greet-1
        'Greet 2': 'Greet 2', // new translation key: common__greet-2
        'Order': 'Order',
        'Order Point1': 'Order Point1', // new translation key: common__order-point-1
        'Order Point2': 'Order Point2', // new translation key: common__order-point-2
        'Order1': 'Order1',
        'Order2': 'Order2',
        'MenuBoard': 'Menu', // new translation key: common__menu-board
        'Cashier': 'Cashier', // new translation key: common__cashier
        'Pickup': 'Pickup',
        'LaneQueue': 'Lane Queue', // new translation key: common__lane-queue
        'Lane Queue': 'Lane Queue', // new translation key: common__lane-queue
        'Lane Queue 2': 'Lane Queue 2', // new translation key: common__lane-queue-2
        'Metrics': 'Metrics:', // new translation key: dashboard__metrics
        'lb-metrics': 'Metrics',
        'LaneTotal': 'Lane Total', // new translation key: common__lane-total
        'LaneTotal2': 'Lane Total 2', // new translation key: common__lane-total-2
        'Lane Total 2': 'Lane Total 2', // new translation key: common__lane-total-2
        'dashboardonly': '(Dashboard Only)',
        'MenuBoard1': 'Menu Board1',
        'MenuBoard2': 'Menu Board2',
        'Menu Board1': 'Menu Board1',
        'Menu Board2': 'Menu Board2',
        'OPEN': 'OPEN', // new translation key: common__open
        'CLOSE': 'CLOSE', // new translation key: common__close
        'CUSTOM': 'CUSTOM',
        'MONTH': 'MONTH',
        'WEEK': 'WEEK',
        'DAY': 'DAY',
        'DAYPART': 'DAYPART',
        'HOUR': 'HOUR',
        'permissions': 'Permissions', // new translation key: common__permissions
        'Current:': 'Current:',
        'bottom3stores': 'Bottom 3 Stores', // new translation key: welcome__tile--store-ranking__bottom-stores
        'yourranking': 'Your Ranking', // new translation key: common__your-ranking
        'ReportsEmailCSVVersion': 'Email CSV version', // new translation key: reports__email-csv-version
        'ReportsRawDataReport': 'Raw Car Data Report', // new translation key: reports__raw-data-report
        'ReportsNoRecordsFound': 'No records found', // new translation key: common__error--no-records-found
        'NoRecordsFound': 'No records found', // new translation key: common__error--no-records-found
        'ReportsDepartureTime': 'Departure Time', // new translation key: reports__departure-time
        'ReportsEventName': 'Event Name', // new translation key: reports__event-name
        'ReportsCarsinQueue': 'Cars In Queue', // new translation key: reports__cars-in-queue
        'Car_Departure': 'Car_Departure',
        'Description': 'Description', // new translation key: common__description
        'HelpFrequentlyAskedQs': 'Frequently Asked Questions', // new translation key: help__faq__title
        'TrainingTitle': 'HME Training Portal', // new translation key: welcome__tile--training__title
        'TrainingGetTrainingLink': 'Access HME Training Portal', // new translation key: welcome__tile--training__link
        'TrainingContent': 'Whether you are learning the system basics or are looking for resources and tips to build ongoing success, you will find instructional videos, supporting documents, job aids, and more!', // new translation key: welcome__tile--training__content
        'HelpOperatingInstructions': 'HME CLOUD Operating Instructions',
        'HelpNewFeatures': 'New Features', // new translation key: help__faq__subtitle--new-features
        'HelpNewDashboardOrReport': 'What if I want to create a custom report or dashboard?', // new translation key: help__faq__question--create-report-dashboard
        'HelpContactYourSalesRep': 'Contact your sales representative or dealer to discuss your options.', // new translation key: help__faq__answer--contact-sales-representative
        'HelpHowChangePassword': 'How do I change my password?', // new translation key: help__faq__question--change-password
        'HelpSignInClickOnMyAccount': 'Sign in and click on My Account.', // new translation key: help__faq__answer--click-my-account
        'HelpWhatIfForgotPass': 'What if I forgot my password?', // new translation key: help__faq__question--forgot-password
        'HelpGoHomeClickForgotPass': 'Go to the HME CLOUD home page and click on \'I forgot my password\'.', // new translation key: help__faq__answer--click-forgot-password
        'HelpHowSetDayparts': 'How do I set my dayparts for HME CLOUD\'s Dashboard and Reports to show the information I want to see?', // new translation key: help__faq__question--set-dayparts
        'HelpAllSettingsInDashboard': 'All of your ZOOM timer settings are automatically uploaded and displayed in your HME CLOUD Dashboard and Reports.', // new translation key: help__faq__answer--zoom-timer-dashboard-reports
        'HelpWhatAreRoles': 'As a Company Administrator adding more users to my account, what is the difference between adding a 1) Company Administrator; 2) District Manager; 3) Regional Manager;  4) Store Manager?', // new translation key: help__faq__question--roles
        'HelpCompanyAdminPowers': 'A Company Administrator can create other users and can access features such as Reboot System, and Force Reconnect.', // new translation key: help__faq__answer--company-admin-role
        'HelpCompanyManagerPowers': 'District Managers, Regional Managers, and Store Managers cannot access Reboot System, and Force Reconnect or create additional users.', // new translation key: help__faq__answer--company-manager-role
        'HelpDeterminePermissionLevel': 'How do I determine what is my permission level?', // new translation key: help__faq__question--permission-level
        'HelpPermissionLevelIsOnMyAccount': 'Click on My Account and view your permission level under User Information.',  // new translation key: help__faq__answer--permission-level
        'HelpUpdateEmployeeList': 'What do I do if an employee leaves and I want to update my User list?', // new translation key: help__faq__question--update-user-list
        'HelpAccessUserList': 'Access the \'Users\' page and click on the View/Edit User link to update the username (email address) and store access checklist.', // new translation key: help__faq__answer--access-user-list
        'HelpAccessZoomSettingsRemotely': 'How can I change my ZOOM settings remotely?', // new translation key: help__faq__question--access-zoom-settings-remotely
        'HelpAccessZoomSettingsInstructions': 'Click on Stores and click on Online in the right-hand column.  It may take several seconds for the ZOOM web portal to appear.  Click on the gear icon at the top left corner to access ZOOM settings.', // new translation key: help__faq__answer--access-zoom-settings
        'HelpSubscription': 'Subscription', // new translation key: help__faq__subtitle--subscription
        'HelpDoSubscriptionsRenew': 'Does my subscription auto-renew each year?', // new translation key: help__faq__question--subscription-auto-renew
        'HelpYesSubscriptionsRenew': 'Your subscription will renew for all your stores each year on the anniversary of your initial subscription.', // new translation key: help__faq__answer--subscription-auto-renew
        'HelpWhenSubscriptionExpires': 'How do I know when my subscription expires?', // new translation key: help__faq__question--subscription-expires
        'HelpContactTechSupport': 'Contact HME Tech Support at 800-848-4468 options 1, 2, 3 and they can let you know when you current subscription expires and next year of service begins.', // new translation key: help__faq__answer--subscription-expires-support
        'HelpCancelMySubscription': 'How do I cancel my subscription?', // new translation key: help__faq__question--subscription-cancel
        'HelpCancelationInstructions': 'If you wish to cancel your subscription, please contact your HME sales representative or dealer.', // new translation key: help__faq__answer--subscription-cancel
        'HelpAddingStoresFullPrice': 'If I add a store to my subscription, am I charged for a full year?',
        'HelpAddingStoresDiscountedPrice': 'You will be charged for the number of months left before your annual subscription renews.', // new translation key: help__faq__answer--add-store-subscription-charging
        'HelpTroubleshooting': 'Troubleshooting', // new translation key: help__faq__subtitle--troubleshooting
        'HelpDashboardNoContent': 'When I click on Dashboard or Reports, I do not see any content.', // new translation key: help__faq__question--reports-dashboard-no-content
        'HelpYouMayNeedPremium': 'You may need a premium subscription. To subscribe to HME CLOUD and get full access, please contact your HME sales representative.', // new translation key: help__faq__answer--premium-subscription
        'HelpNewZoomNotShowing': 'I installed a new ZOOM timer, but it is not showing up in my HME CLOUD account as one of my stores.', // new translation key: help__faq__question--zoom-timer-not-showing
        'HelpConfirmZoomConfigured': 'There are few key configurations that enable the ZOOM timer to register with the HME CLOUD and your account:', // new translation key: help__faq__answer--zoom-timer-configurations
        'HelpConfirmFirmwareVersion': 'Verify your firmware version is 2.0 or later.', // new translation key: help__faq__answer--verify-firmware-version
        'HelpConfirmAdminStatus': 'If you do not have a Company Administrator account (e.g., you are a store manager), your Company Administrator must assign stores to you, and then you will see the stores in your account.', // new translation key: help__faq__answer--confirm-admin-status
        'HelpConfirmTimerOptIn': 'The timer must be configured for opt-in for HME CLOUD service.  Your Installer or HME support representative can assist with verifying the configuration.', // new translation key: help__faq__answer--verify-timer-configuration
        'HelpVerifyPortsOpen': 'Your network must have outbound ports 18000 and 19000 open.', // new translation key: help__faq__answer--verify-ports
        'HelpVerifyActiveAccount': 'You must have a user account set up and the email address configured on the ZOOM timer must match the email address (user name) of the HME CLOUD account.', // new translation key: help__faq__answer--verify-active-account
        'HelpHowToGetHelp': 'How do I contact HME when I need technical assistance?', // new translation key: help__faq__question--technical-assistance
        'HelpContactUs': 'Contact HME Tech Support at 800-848-4468 options 1, 2, 3', // new translation key: help__faq__answer--contact-us
        'HelpPossibleInnaccurateData': 'What do I do if I think my dashboard is not displaying accurate information?', // new translation key: help__faq__question--inaccurate-info
        'HelpHandlingMissingData': 'Check to ensure you\'re looking at the correct store.  If the detection points or times look inaccurate or are missing information, report this issue to HME Tech Support to troubleshoot.', // new translation key: help__faq__answer--report-inaccurate-info
        'HelpUnableToConnect': 'When I try to make a remote connection to my system I get an \'unable to connect\' message. ', // new translation key: help__faq__question--unable-to-connect
        'HelpConnectingNeedsScripts': 'Certain browsers require the user to accept a script loading message before they will allow the page to load. Reload the page and attempt to make the remote connection. For Firefox and Chrome you will see a Shield appear in the address bar. Click the Shield and allow the script to run. For Internet Explorer, a pop up message will appear at the bottom of the page for a few seconds, click this message to accept the script. Now the remote connection can be made. Most browsers will remember your choice and you should not have to follow this procedure again.', // new translation key: help__faq__answer--unable-to-connect-instruction
        'HelpSystemStatusUnavailable': 'When I look in the Stores list I notice some stores show a System Status of Not Available. What does that mean?', // new translation key: help__faq__question--unavailable-system-status
        'HelpStoreIsUnregistered': 'It means your store is no longer registered with the HME CLOUD. If you wish, you can delete this store entry by clicking View/Edit Details for that store and click the Delete Store Link.  If you want to re-register the store with HME CLOUD, please contact HME Technical Support at 800-848-4468 options 1, 2, 3.', // new translation key: help__faq__answer--unregistered-store-instruction
        'HelpContactByEmail': 'Contact HME CLOUD support by', // new translation key: help__contact
        'HelpIfAddStoreSub': 'If I add a store to my subscription, am I charged for a full year?', // new translation key: help__faq__question--add-store-subscription
        'AttemptingConnection': 'Attempting Connection',
        'RebootStarted': 'Reboot Started', // new translation key: device-reboot__loading
        'UpgradeStarted': 'Upgrade Started', // new translation key: device-upgrade__loading
        'settingsDevicesFirmwareVersion': 'Firmware Version',
        'settingsDevicesSettingVersion': 'Settings Version', // new translation key: common__settings__version
        'settingsDevicesRegisteredToStoreInfo': 'Associated to Store Info', // new translation key: system-status__associated-store-info
        'settingsDevicesRegisteredToAccountInfo': 'Associated to Account Info', // new translation key: system-status__associated-account-info
        'settingsDevicesLaneConfiguration': 'Lane Configuration', // new translation key: system-status__lane-config
        'settingsDevicesFirstActivity': 'First Activity', // new translation key: system-status__activity--first
        'settingsDevicesLastActivity': 'Last Activity', // new translation key: system-status__activity--last
        'settingsDevicesNumberOfRecords': 'Number of Records', // new translation key: system-status__number-of-records
        'settingsDevicesUpgrade': 'Upgrade',
        'settingsDevicesReboot': 'Reboot', // new translation key: system-status__reboot
        'settingsDevicesForce': 'Force', // new translation key: system-status__force
        'settingsDevicesReconnect': 'Reconnect', // a part of new translation key: system-status__force
        'settingsDevicePreConfigured': 'Pre-configured', // new translation key: common__device__status--pre-configured
        'settingsDevicePreConfiguredBy': 'Pre-configured By', // new translation key: system-status__preconfigured-by
        'settingsDevicePreConfiguredDate': 'Date Pre-configured', // new translation key: system-status__preconfigured-date
        'SingleLane': 'Single Lane', // new translation key: common__device__lane-config--single
        'DualLane': 'Dual Lane', // new translation key: common__device__lane-config--dual
        'YLane': 'Y Lane', // new translation key: common__device__lane-config--y-lane
        'TandemLane': 'Tandem Lane', // new translation key: common__device__lane-config--tandem
        'MobileInsideLane': 'Mobile - Inside Lane', // new translation key: common__device__lane-config--mobile-inside
        'MobileOutsideLane': 'Mobile - Outside Lane', // new translation key: common__device__lane-config--mobile-outside
        'Single Lane': 'Single Lane', // new translation key: common__device__lane-config--single
        'Dual Lane': 'Dual Lane', // new translation key: common__device__lane-config--dual
        'Y Lane': 'Y Lane', // new translation key: common__device__lane-config--y-lane
        'Tandem Lane': 'Tandem Lane', // new translation key: common__device__lane-config--tandem
        'Mobile - Inside Lane': 'Mobile - Inside Lane', // new translation key: common__device__lane-config--mobile-inside
        'Mobile - Outside Lane': 'Mobile - Outside Lane', // new translation key: common__device__lane-config--mobile-outside
        'Please select a Time Format option': 'Please select a Time Format option.',
        'Please select a Pull-Ins option': 'Please select a Pull-Ins option',
        'Please select Primary Goal Colors': 'Please select Primary Goal Colors.',
        'There has been an error processing your request': 'There has been an error processing your request, please try again later.', // new translation key: common__error-processing-request
        'You are about to be signed out': 'You are about to be signed out due to inactivity.', // new translation key: auto-logout-modal__content
        'YouAreAboutToBeSignedOut': 'You are about to be signed out due to inactivity.', // new translation key: auto-logout-modal__content
        'YouAreCurrentlyViewing': 'You are currently viewing the site as', // new translation key: auto-logout-modal__content--masquerade
        'ContinueViewingAs': 'Continue Viewing as', // new translation key: auto-logout-modal__button--masquerade
        'Car_Pull_In': 'Car_Pull_In',
        'Car_Pull_Out': 'Car_Pull_Out',
        'Stay Logged In': 'Stay Logged In', // new translation key: auto-logout-modal__button
        'StayLoggedIn': 'Stay Logged In', // new translation key: auto-logout-modal__button
        'Auto Logout': 'Auto Logout', // new translation key: auto-logout-modal__title
        'AutoLogout': 'Auto Logout', // new translation key: auto-logout-modal__title
        'Show:': 'Show:', // new translation key: common__show--colon
        'longesttimesandsystemstatistics': 'Longest Times, System Statistics',
        'Please select the column of data': 'Please select the column of data you would like to',
        'rank your stores by': 'rank your stores by',
        'minigoal': 'Goal',  // new translation key: common__goal
        'viewFranchise': 'View Franchises', // new translation key: my-account__account-info__view-franchise
        'leaderboardgroups': 'Leaderboard Groups',
        'leaderboardgroupManage': 'Leaderboard Group Management',
        'franchiseHelp': 'Select to include any shared Franchise data', // new translation key: my-account__account-info__shared-franchise-data
        'leaderboardgroupsdetails': 'Leaderboard Group Details',
        'addagroup': 'Add New Group', // new translation key: common__add-group
        'manageleaderboardgroups': 'Manage Leaderboard Groups',
        'managereportgroups': 'Manage Hierarchy', // new translation key: stores__manage-hierarchy-btn
        'groupname': 'Group Name', // new translation key: common__group__name
        'createdBy': 'Created By', // new translation key: common__created-by
        'createdDate': 'Date Created',
        'deletegroup': 'Delete Role',
        'settingsStoresGroup': 'Group', // new translation key: common__group
        'storesingroup': 'Stores in Group',
        'groupdescription': 'Group Description', // new translation key: common__group__description
        'StoreSettingsLeaderboardGroup': 'Leaderboard Groups',
        'StoreSettingsReportGroup': 'Report Group', // new translation key: stores__list-header__report-group
        'availablestores': 'Available Stores', // new translation key: stores-selector__available-stores
        'youdonthavepermission': 'You do not have permission to use this feature',
        'noStores': 'To use this feature, you must first register a Zoom with HME CLOUD.', // new translation key: common__register-zoom
        'allDualLaneStores': 'Reports are not currently available for Stores with a Dual Lane configuration.', // new translation key: reports__all-dual-lane-stores
        'enterSearchCriteria': 'Please enter search criteria.', // new translation key: admin__stores__no-search-criteria
        'GroupNameTaken': 'A Leaderboard by the same name already exists. Please create a unique Leaderboard name.', // new translation key: add-leaderboard__error--name-exist
        'Active groups require': 'Active groups require at least 1 store to be chosen.',
        'Status is required': 'Status is required.', // new translation key: add-leaderboard__error--empty-status
        'Group name may not be blank': 'Group name may not be blank.', // new translation key: add-group__error--empty-group-name
        'Last Updated is empty': 'Group is not valid: Last Updated is empty.',
        'Company not found': 'Group is not valid: Company not found.', // new translation key: add-leaderboard__error--no-company
        'Store list mismatch': 'Group is not valid: Store list mismatch.', // new translation key: add-leaderboard__error--store-list-mismatch
        'Update failed': 'Group is not valid: Update failed.', // new translation key: add-leaderboard__error--update-failed
        'Company not found in Azure': 'Group is not valid: Company not found in Azure.', // new translation key: add-leaderboard__error--no-azure-company
        'Create Group failed': 'Update/Create Group failed.', // new translation key: add-leaderboard__error--failed
        'Store Group failed': 'Update Store Group failed.', // new translation key: add-leaderboard__error--store-failed
        'Group is not valid': 'Group is not valid.',
        'Inactive Group': 'Inactive Group',
        'All stores will be removed from this group': 'All stores will be removed from this group',
        'OK': 'OK', // new translation key: common__ok
        'StoreNameColumn': 'Store Name Column', // new translation key: display-settings__title--store-name
        'AddNewGroup': 'Add New Group', // new translation key: common__add-group
        'export': 'Export', // new translation key: common__export
        'exportCSV': 'Export in CSV', // new translation key: common__export--csv
        'exportJSON': 'Export in JSON', // new translation key: common__export--json
        'exportedSuccessfully': 'Exported Successfully', // new translation key: manage-hierarchy__export-success
        'Import': 'Import',
        'noFile': 'No file is chosen', // new translation key: file-select__no-file
        'chooseFile': 'Choose file', // new translation key: file-select__choose-file
        'importedSuccessfully': 'Imported Successfully', // new translation key: manage-hierarchy__import-success
        'import_common_error_message': 'The file you tried to import has missing data or has the wrong format. Please check the content of your file or contact HME Technical Support for assistance at (800)848-4468', // new translation key: manage-hierarchy__error--import-common
        'import_error_missing_column': 'Column {DETAIL} is missing in one or more stores', // new translation key: manage-hierarchy__error--missing-column
        'import_error_wrong_account': 'Stores for account(s) with email(s): {DETAIL}, could not be imported', // new translation key: manage-hierarchy__error--wrong-account
        'import_error_empty_line': 'Store number and group name are both empty', // new translation key: manage-hierarchy__error--empty-line
        'import_error_wrong_store': 'Store with number {DETAIL} doesn\'t belong to the account', // new translation key: manage-hierarchy__error--wrong-store
        'import_error_wrong_groupname': 'Group name {DETAIL} is incorrect', // new translation key: manage-hierarchy__error--wrong-group-name
        'backupReminder': 'Please, be sure you created a backup before uploading a new file.\nProceed?', // new translation key: manage-hierarchy__backup-reminder
        'UploadFile': 'Upload File', // new translation key: file-select__upload
        'UploadImage': 'Upload Image', // new translation key: file-select__upload-image
        'emptyError': 'You must have stores configured to export or import a hierarchy', // new translation key: manage-hierarchy__error--empty-stores
        'ReportingStoreHierarchy': 'Store Hierarchy', // new translation key: manage-hierarchy__title
        'ReportingGroupManagement': 'Group Management',
        'ReportingGroupDetails': 'Group Details',
        'EditReportingGroupDetails': 'Edit Group Details', // new translation key: add-group__edit__title
        'AddReportingGroupDetails': 'Add Group Details', // new translation key: group__add__title
        'GroupDescription': 'Group Description', // new translation key: common__group__description
        'AvailableGroupsStores': 'Available Groups/Stores', // new translation key: add-group__available-groups
        'GroupsStoresinGroup': 'Groups/Stores in Group', // new translation key: add-group__groups-stores-in-group
        'AccountInformation': 'Account Information',
        'delete': 'Delete', // new translation key: common__delete
        'navbarStores': 'Stores', // new translation key: common__stores
        'navbarSystems': 'Systems', // new translation key: main-header__item--systems
        'navbarUsers': 'Users', // new translation key: common__users
        'navbarAccounts': 'Accounts',  // new translation key: common__accounts
        'navbarDistributers': 'Distributors', // new translation key: common__distributors
        'navbarAudioManager': 'Audio Manager',
        'navbarOptionSettings': 'Display', // new translation key: sub-header--settings__display
        'navbarOptionLeaderboard': 'Customize Leaderboard',
        'navbarOptionHelp': 'Help', // new translation key: common__help
        'subNavbarStores': 'Stores', // new translation key: common__stores
        'subNavbarTools': 'Tools', // new translation key: main-header__item--tools
        'subNavbarUsers': 'Users', // new translation key: common__users
        'subNavbarRoles': 'Roles', // new translation key: common__roles
        'subNavbarDashboard': 'Dashboard', // new translation key: common__dashboard
        'subNavbarReports': 'Reports', // new translation key: common__reports
        'subNavbarPbiDashboard': 'PBI Dashboard', // new translation key: common__pbidashboard
        'subNavbarDeviceSettingsHistory': 'Device Settings History',
        'Groups': 'Groups', // new translation key: common__groups
        // added
        'requiredAccountId': 'accountId number is  required', // new translation key: common__error--missed-account-id
        'notRecordsFound': 'No Records found', // new translation key: common__error--no-records-found
        'requiredCreatedBy': 'createdBy number is  required',
        'saveTempplateSuccess': 'Template has been saved successfully',
        'saveTemplateFailure': 'Failed to Save template',
        'invalidTemplateName': 'Invalid template Name',
        'deletedTemplate': 'Saved Template has been deleted successfully',
        'InvalidStoreId': 'Please select a Store',
        'dateCannotbeEmpty': 'Please select a date',
        'dateRangeInvalid': 'Date range invalid. For Raw Data Reports select a single day.',
        'invalidReportType': 'Invalid Report Type param',
        'invalidEmail': 'Invalid Invalid Email Id', // new translation key: add-leaderboard__error--invalid-email
        'invalidRawCarStoreLength': 'Invalid selection. Please select no more than 1 store for a Raw Data Report',
        'advancedDateRangeInvalid': 'Date range invalid. For Advanced Options Reports select any 3 month period.',
        'dayPartInvalidDateRange': 'Date range invalid. For Daypart Reports select any 2 week period.',
        'weekInvalidDateRange': 'Date range invalid. For Week Reports select any 2 month period.',
        'dayInvalidDateRange': 'Date range invalid. For Day Reports select any 1 month period.',
        'fromDateGreaterThanToDate': 'Date range invalid. Starting date may not be beyond ending date.',
        'advancedDateRangeDayPartInvalid': 'Date range invalid. For Advanced Options Reports select any 3 weeks period.',
        'advancedDateRangeWeekInvalid': 'Date range invalid. For Advanced Options Reports select any 2 month period.',
        'storeSelectionInvalid': 'Store selection invalid. You may select up to 1000 stores from the list below.',
        'advancedDayInvalidDateRange': 'Date range invalid. For Advanced Options Reports select any 1 month period.',
        'groupNameEmpty': 'Group name can not be empty', // new translation key: common__error--group-name-empty
        'groupSuccess': 'Group has been created successfully', // new translation key: add-group__success--add
        'groupIdInvalid': 'Invalid groupId', // new translation key: common__error--group-id-invalid
        'invalidInput': 'Invalid input details has provided', // new translation key: common__error--input-invalid
        'groupAlreadyExist': 'Group already exist in the user account', // new translation key: add-group__error--group-exist
        'groupUpdatesSuccess': 'Group has been updated successfully', // new translation key: add-group__success--update
        'noRecordsFoundForDelete': 'No records found for deleting',
        'recordsDeleted': 'Records deleted Successfully',
        'recordDeleted': 'Record deleted Successfully', // new translation key: common__success--record-deleted
        'noDataForGivenId': 'No data found for the given Id', // new translation key: common__error--no-data-for-id
        'invalidRequestBody': 'Invalid request body',
        'groupCreationFailed': 'Group creation failed',
        'noDataForGivenName': 'No data found for the given name', // new translation key: common__error--no-data-for-name
        'groupUpdationFailed': 'Group updation failed', // new translation key: add-group__error--group-update-failed
        'invalidTemplateId': 'Invalid template Id',
        'createSuccess': 'Report Template create successfully',
        'createFailure': 'Unable to Create Report',
        'deleteSuccess': 'Report Template Deleted successfully',
        'deteleFailure': 'Unable to Delete Report',

        'firstNameEmpty': 'User firstName can not be empty',
        'invalidUserUUID': 'Invalid User id',
        'usercreateSuccess': 'Record Saved', // new translation key: common__record-saved
        'userAlreadyExist': 'User already exist', // new translation key: add-user__error--user-exists
        'usercreateFailure': 'User creation failed', // new translation key: add-user__error--create-failed
        'userupdatedSuccess': 'User details updated successfully',
        'userupdateFailure': 'User details updation failed', // new translation key: add-user__error--update-failed
        'userdeleteSuccess': 'User deleted successfully',
        'userDeleteFailed': 'User deletion failed',
        'rolecreateSuccess': 'Record Saved', // new translation key: common__record-saved
        'roleAlreadyExist': 'A role with this name already exists.', // new translation key: add-role__error--role-exists
        'rolecreateFailure': 'Role creation failed', // new translation key: add-role__error--create-fail
        'roleupdatedSuccess': 'Role details updated successfully',
        'roleupdateFailure': 'Role details updation failed', // new translation key: add-role__error--update-fail
        'roledeleteSuccess': 'Role deleted successfully',
        'roleDeleteFailed': 'Role deletion failed',
        'W-Avg': 'W-Avg', // new translation key: reports__w-avg
        'Total Day': 'Total Day', // new translation key: reports__total-day
        'Total Daypart': 'Total Daypart', // new translation key: reports__total-daypart
        'Total Week': 'Total Week', // new translation key: reports__total-week
        'SubTotal': 'SubTotal',
        'useragreementUrl': 'HMECLOUD_Terms_and_Conditions.pdf', // this key and next key is duplicate hence merged in new version.
        'agreementUrl': 'HMECLOUD_Terms_and_Conditions.pdf', // new translation key: my-account__account-info__agreement-url
        'privacypolicyUrl': 'HMECLOUD_Privacy_Policy.pdf',
        'californiasupplyUrl': 'California_Transparency_Supply_Chains_Act_Disclosures.pdf',
        'cookiepolicyUrl': 'HMECLOUD_Cookie_Policy.pdf',
        'displayFor': 'Display for', // new translation key: announcement-settings__display-for
        'every': 'every',
        'thenHideFor': 'and then hide for',
        'hoursAfterMessageIsSent': 'hours after message is sent',
        'Jan': 'Jan',
        'Feb': 'Feb',
        'Mar': 'Mar',
        'Apr': 'Apr',
        'May': 'May',
        'Jun': 'Jun',
        'Jul': 'Jul',
        'Aug': 'Aug',
        'Sept': 'Sept',
        'Oct': 'Oct',
        'Nov': 'Nov',
        'Dec': 'Dec',

        'Janv': 'Jan',
        'Févr': 'Feb',
        'Mars': 'Mar',
        'Avril': 'Apr',
        'Mai': 'May',
        'Juin': 'Jun',
        'Juil': 'Jul',
        'Août': 'Aug',
        'Déc': 'Dec',

        'janv': 'jan',
        'févr': 'feb',
        'mars': 'mar',
        'avril': 'apr',
        'mai': 'may',
        'juin': 'jun',
        'juil': 'jul',
        'août': 'aug',
        'sept': 'sept',
        'oct': 'oct',
        'nov': 'nov',
        'déc': 'dec',

        'all_months': 'All months', // new translation key: common__all-months
        'all_years': 'All years', // new translation key: common__all-years
        'full_january': 'January', // new translation key: common__full-january
        'full_february': 'February', // new translation key: common__full-february
        'full_march': 'March', // new translation key: common__full-march
        'full_april': 'April', // new translation key: common__full-april
        'full_may': 'May', // new translation key: common__full-may
        'full_june': 'June', // new translation key: common__full-june
        'full_july': 'July', // new translation key: common__full-july
        'full_august': 'August', // new translation key: common__full-august
        'full_september': 'September', // new translation key: common__full-september
        'full_october': 'October', // new translation key: common__full-october
        'full_november': 'November', // new translation key: common__full-november
        'full_december': 'December', // new translation key: common__full-december

        'Loading...': 'Loading...', // new translation key: common__loading
        'Loading': 'Loading',
        'Merge': 'Merge', // new translation key: admin__store-merge__title
        'SelectSystem': 'Select System(s)', // new translation key: admin__store-merge__select-store
        'System': 'System', // new translation key: common__system
        'SelectTargetStore': 'Select Target Store', // new translation key: admin__store-merge__select-target-store
        'EnterStoreNumber': 'Enter Store Number', // new translation key: admin__store-merge__enter-store-number
        'Search': 'Search', // new translation key: admin__store-merge__search
        'Timezone': 'Time Zone', // new translation key: common__timezone
        'TandemLinesError': 'ERROR: Tandem Lanes may not be selected in multiple store reports.', // new translation key: reports__error--tandem-lanes-store
        'TandemLanesStore': 'ERROR: Tandem Lanes may not be selected in multiple store reports.', // new translation key: reports__error--tandem-lanes-store
        'errorTimeout': 'Timeout: Request failed to complete in 15000ms', // new translation key: common__error--timeout
        'genericError': 'Error occured, please contact the administrator.', // new translation key: common__error--contact-admin
        'openTime': 'Enter Open Time', // new translation key: reports__open-time
        'closeTime': 'Enter Close Time', // new translation key: reports__close-time
        // Added -- New password and confirm password validation
        'newPasswordcontainblank': 'New password may not contain blank spaces.',
        'newPasswordinvalid': 'Invalid new password.',
        'newPasswordsize': 'New password must be between 8 and 16 characters in length.', // new translation key: my-account__error__new-password-size
        'newPassworduppercase': 'New password must contain at least 1 uppercase character.', // new translation key: my-account__error__new-password-uppercase
        'newPasswordlowercase': 'New password must contain at least 1 lowercase character.', // new translation key: my-account__error__new-password-lowercase
        'newPasswordnumber': 'New password must contain at least 1 number.', // new translation key: my-account__error__new-password-number
        'confirmasswordcontainblank': 'Confirm password may not contain blank spaces.', // new translation key: my-account__error__new-password-blank
        'confirmpasswordinvalid': 'Invalid confirm password.',
        'confirmPasswordsize': 'Confirm password must be between 8 and 16 characters in length.', // new translation key: my-account__error__confirm-password-size
        'confirmPassworduppercase': 'Confirm password must contain at least 1 uppercase character.', // new translation file: my-account__error__confirm-password-uppercase
        'confirmPasswordlowercase': 'Confirm password must contain at least 1 lowercase character.', // new translation key: my-account__error__confirm-password-lowercase
        'confirmPasswordnumber': 'Confirm password must contain at least 1 number.', // new translation key: my-account__error__confirm-password-number
        'confirmPasswordcontainblank': 'Confirm password may not contain blank spaces.', // new translation key: my-account__error__confirm-password-blank
        'passwordmisMatch': 'New Password and Confirmed Password mismatch.',
        'distributors': 'Distributors', // new translation key: common__distributors
        'AddDistributor': 'Add a Distributor', // new translation key: admin__distributors__add-distributor
        'percent': 'Goal%',  // new translation key: common__goal--percent
        'storeNumber': 'Number', // new translation key: dashboard__store-number
        'storeRank': 'Rank', // new translation key: dashboard__store-rank
        'storeName': 'Name', // new translation key: dashboard__store-name
        'login_reminder': 'Attention internal users: Please use your HME credentials to log in to the Admin site.', // new translation key: login__admin__notification
        'seldistributor': '***Select Distributor***:', // new translation key: my-account__account-info__select-distributor
        'distributorName': 'Distributor Name', // new translation key: admin__distributors__grid__header--name
        'HelpSubscription_type': 'Subscription Type', // new translation key: admin__distributors__grid__header--subscription
        'StartDate': 'Start Date', // new translation key: admin__distributors__grid__header--start-date
        'EndDate': 'End Date', // new translation key: admin__distributors__grid__header--end-date
        'hmeAvante': 'HME Avante #:', // new translation key: my-account__company-info__hme-avante
        'InvalidIdentificationCode': 'Invalid Identification Code entered.',
        'DFASubmitVerification': 'Submit Verification', // new translation key: dfa-code__form__submit
        'IdentificationCodeLabel': 'Identification Code', // new translation key: dfa-code__form__label
        'IdentificationCodeToolTip': 'As an added account security measure, you\'ll need to enter the identification code we\'ve just sent to your email', // new translation key: dfa-code__info
        'InvalidIdentificationCodeExpired': 'Identification code is expired.', // new translation key: dfa-code__error--expired
        'InvalidIdentificationCodeInvalid': 'Invalid Identification Code entered.', // new translation key: dfa-code__error--invalid
        'InvalidIdentificationCodeBlank': 'Identification Code may not be blank.',
        'hmedashboardheaderrealtime': 'HME CLOUD REAL TIME DASHBOARD', // new translation key: dashboard__hme-dashboard-header-real-time
        'pullintextforexclude': 'The data excludes pullins',
        'userInActive': 'User is inactive',
        'storeDetailsSaveSuccess': 'Store Details have been saved.', // new translation key: stores__success--details-saved
        'storeTZSaveError': 'Store TZ not added.',
        'storeTZBlank': 'Please select timezone.', // new translation key: stores__error--empty-timezone
        'storenameblank': 'Store name can not be blank.',
        'MustSelectStLeastOneSystem': 'Must select at least one system.', // new translation key: admin__store-merge__error--no-system
        'MustSelectTargetStore': 'Must select a target store.', // new translation key: admin__store-merge__error--no-target-store
        'Mergeconfirmation': 'Are you sure you would like to proceed ?', // new translation key: admin__store-merge__confirm
        'mergeActionRemove': 'This action will remove the following system(s) from the CLOUD', // new translation key: admin__store-merge__remove
        'MergeTargetStoreOffline': 'The Target Store should be Offline.', // new translation key: admin__store-merge__error--store-online
        'storestatus': 'Status', // new translation key: common__status
        'searchAll': 'Search all', // new translation key: common__search--all
        'searchByStoreNameOrStoreNumber': 'Search by Store Name or Store Number.', // new translation key: lock-device-settings__search-by-store-description
        'SearchBrand': 'Search brand', // new translation key: stores__search--brand
        'SearchAccountEmail': 'Search account email', // new translation key: admin__stores__search--account-email
        'SearchStore': 'Search store', // new translation key: stores__search--store
        'SearchStoreName': 'Search store name', // new translation key: stores__search--store-name
        'SearchSerial': 'Search serial', // new translation key: stores__search--serial
        'SearchSystemVersion': 'Search system version', // new translation key: stores__search--version
        'Serial': 'Serial',
        'RemoveSystem': 'Remove System', // new translation key: admin__unregister-system__button
        'RemoteSystemActions': 'Remote System Actions',
        'homepageMyAccountDescriptionForAdmin': 'Add new accounts or Edit existing account information.',
        'homepageStoresDescriptionForAdmin': 'Edit contact info for existing stores. View ZOOM settings for each store and access live dashboards.',
        'homepageUsersDescriptionForAdmin': 'Edit existing users\' contact info and store access.',
        'Details': 'Details', // new translation key: common__details
        'StoreSettingsSerialno': 'Serial Number', // new translation key: common__device__serial-number--text
        'View': 'View',
        'RegisteredDevices': 'Registered Devices',
        'SelectTimezone': 'Select a Timezone', // new translation key: stores__modal__select_timezone
        'clickhere': 'click here.', // part of new translation key: stores__modal__tab--details__store-merge
        'Master': 'Master', // new translation key: common__master
        'Settings': 'Settings', // new translation key: common__settings
        'HMECLOUDOperatingInstructionsUrl': 'HME_CLOUD_Operating_Instructions.pdf', // new translation key: help__instruction-filename
        'HMECLOUDOperatingInstructions': 'HME CLOUD Operating Instructions', // new translation key: help__instruction-title
        'UnregisteredSystems': 'Unregistered Systems', // new translation key: admin__systems__unregistered-systems
        'LBGroup': 'Leaderboard Group',
        'CreateLBGroup': 'Create Leaderboard Group',
        'create': 'Create', // new translation key: common__create
        'Members': 'Members',
        'Showing': 'Showing', // new translation key: common__showing
        'of': 'of',
        'in': 'in', // new translation key: common__in
        'results': 'results',
        'Show': 'Show',
        'Edit': 'Edit', // new translation key: common__edit
        'Invite': 'Invite',
        'Assign': 'Assign',
        'Remove': 'Remove', // new translation key: common__remove
        'JoinDate': 'Join Date',
        'MembersNumberOfStores': 'Number of Stores',
        'MembersStatus': 'Status',
        'MembersIsAdmin': 'Is Admin?',
        'MembersOf': 'Members of',
        'SendInvite': 'Send Invitation', // new translation key: add-leaderboard__send-invite
        'toTheseStores': 'to These Stores',
        'AssignedGroupOrLeaderboard': 'Assigned Leaderboard',
        // 'lbSelectedWillShowInStoresSelectedAbove':'*Leaderboard selected will show in the stores selected above',
        'selectedGroupWillReplaceCurrentStoresLB': '*Selected store\'s will be added to the Leaderboard',
        'noStoresFound': 'No stores found.', // new translation key: common__no-stores--found
        'noGroupsFound': 'No groups found',
        'noMembersFound': 'No members found.', // new translation key: add-leaderboard__accounts__no-memebers--found
        'confirmRemoval': 'Confirm Removal', // new translation key: add-leaderboard__modal--remove-memeber__title
        'areYouSureYouWantToRemoveThisGroupMember': 'Are you sure you want to remove this group member?', // new translation key: add-leaderboard__modal--remove-memeber__confirm
        'areYouSureYouWantToChangeMakeGroupMemberAnAdmin': 'Are you sure you want to make this group member the owner?', // new translation key: add-leaderboard__modal--update-owner__confirm
        'invitationMailAlreadySentToUser': 'Invitation Mail Already Sent to User!', // new translation key: add-leaderboard__error--invited-already
        'pleaseSelectALeaderBoardGroup': 'Please Select a LeaderBoard Group!',
        'reject': 'Reject',
        'confirmInvitation': 'Confirm Invitation',
        'pleaseClickBelowToAcceptInvitation': 'Please click below to accept invitation!',
        'GroupName': 'Group Name',
        'InvitedBy': 'Invited By',
        'InvitedDate': 'Invited Date',
        'AcceptInvitation': 'Accept Invitation!',
        'MakeAdmin': 'Make Owner',
        'LeaderboardOwner': 'Leaderboard Owner',
        'ChooseNewAdmin': 'Choose New Owner', // new translation key: add-leaderboard__accounts__choose-owner
        'pleaseEnterValidGroupTitle': 'Please Enter Valid Group Title!',
        'languagePreference': 'Language Preference:',
        'youcannotdeleteadminsorsolemembers': 'You cannot delete admins or sole members from groups.', // new translation key: add-leaderboard__modal--remove-memeber__error--no-access
        'French': 'French',
        'English': 'English',
        'InvalidEmail': 'The account email you entered is not a valid HME CLOUD user.',
        'InvalidEmailLanguage': 'Invalid Email Language',
        'selectCriteria': 'Select Criteria',
        'actions': 'Actions', // new translation key: common__actions
        'PasswordRequirements': 'Password Requirements', // new translation key: my-account__password-requirements
        'ResetPasswordLength': 'The length of the password must be at least eight (8) characters and not more than sixteen (16) characters.',
        'ResetPasswordBlank': 'The password may not contain blank spaces.',
        'ResetPasswordGroup': 'The password must contain at least one (1) character from each of the three (3) groups below:',
        'ResetPasswordGroupUpperCase': 'English uppercase characters (A through Z)',
        'ResetPasswordGroupLowerCase': 'English lowercase characters (a through z)',
        'ResetPasswordGroupNumerals': 'Numerals (0 through 9)',
        'NoDeviceFound': 'No Devices Found', // new translation key: common__no-devices--found
        'homepageMyAccountPublic': 'My account', // new translation key: common__my-account
        'settingsStoresNotavailable': 'Not Available', // new translation key: common__device__status--not-available
        'removeStoreConfirm': 'Are you sure you would like to remove the selected store?', // new translation key: stores__modal--confirmation__remove
        'warning': 'WARNING', // new translation key: common__warning
        'actioncannotbeundone': 'This action cannot be undone!', // new translation key: common__warning--undone
        'storeRemoveSuccess': 'Success! The requested store has been removed.', // new translation key: stores__success--remove
        'Upgradeyouraccount': 'Upgrade your account to Premium service to get access to this feature', // new translation key: common__upgrade-account-to-premium
        'Toupgradenow': 'To upgrade now', // new translation key: common__call-to-upgrade
        'pleasecall': 'please call', // new translation key: common__call-to-upgrade
        'DomesticSales': 'Domestic Sales', // new translation key: common__domestic-sales--title
        'Options': 'Options', // a part of new translation key: common__domestic-sales--info, common__international-sales--info-other-countries, common__international-sales--info-regions, common__international-sales--info-other-countries
        'InternationalSales': 'International Sales', // new translation key: common__international-sales--title
        'for': 'for', // a part of new translation key: common__international-sales--info-regions, common__international-sales--info-other-countries
        'othercountries': 'other countries', // a part of new translation key: common__international-sales--info-other-countries
        'storeRemoveFailure': 'Error! Something went wrong, please try your request again.', // new translation key: common__error--unknown
        'RemoveStore': 'Remove Store', // new translation key: stores__modal__remove
        'undergomaintenanceon': 'The HME CLOUD will undergo maintenance on',
        'undergomaintenanceontemporarilyunavailable': 'Website and in-store Leaderboard display will be temporarily unavailable',
        'GMT': 'GMT',
        'Zoomdeviceunreachablefordisconnect': 'The device is not able to be reached at this time. Please contact HME Tech Support.', // new translation key: device-connect__error--zomm-unreachable
        'Zoomdeviceunreachable': 'Zoom device unreachable. Please try your request again later.', // new tranlsation key: common__error--zoom-unreachable
        'BadDeviceID': 'Bad Device ID. The system administrator has been notified.', // new translation key: device-connect__error--bad-id
        'NoDistributorsFound': 'No Distributors Found',
        'masquerateIntoAccount': 'Masquerade into Account as', // new translation key: add-user__masquerade
        'Leaderboard': 'Leaderboard', // new translation key: common__leaderboard
        'AddLeaderboard': 'Add Leaderboard',
        'Title': 'Title', // new translation key: common__title
        'AddNewLeaderboard': 'Add New Leaderboard',
        'LeaderboardOverview': 'Leaderboard Overview',
        'LeaderboardName': 'Leaderboard Name', // new translation key: common__lb__name
        'LeaderboardDescription': 'Leaderboard Description', // new translation key: common__lb__description
        'NitroLeaderboard': 'Nitro Leaderboard',
        'LeaderboardDetails': 'Leaderboard Details',
        'EditLeaderboardDetails': 'Edit Leaderboard Details', // new translatioh key: add-leaderboard__title--edit a part of add-leaderboard__title--edit-default
        'LeaderboardContests': 'Contests', // new translation key: common__contests
        'LeaderboardAwards': 'Awards', // new translation key: common__awards
        'LeaderboardSmackTalk': 'Smack Talk', // new translation key: common__smack-talk
        'SmackTalk': 'Smack Talk', // new translation key: common__smack-talk
        'SMACKTALK': 'Smack Talk', // new translation key: announcement-settings__title--smacktalk
        'ROUNDSTARTINGBANNER': 'Round Starting Banner', // new translation key: announcement-settings__title--round-starting-banner
        'CONTESTSTARTINGBANNER': 'Contest Starting Banner', // new translation key: announcement-settings__title--contest-starting-banner
        'AWARDPAGE': 'Award Achievement',  // new translation key: announcement-settings__title--award-achievement
        'CONTESTWINNER': 'Contest Winner', // new translation key: announcement-settings__title--contest-winner
        'NOTIFICATION_FREQUENCY': 'Frequency of Notifications',
        'NOTIFICATIONFREQUENCY': 'Frequency of Notifications', // new translation key: announcement-settings__title--notification-frequency
        'WINNERBANNER': 'Contest Winner Banner', // new translation key: announcement-settings__title--winner-banner
        'notifications': 'Notifications',
        'presentationPages': 'Presentation Pages',
        'LeaderboardFeedback': 'Feedback',
        'Leaderboards': 'Leaderboards', // new translation key: common__leaderboards
        'LeaderboardHeader': 'Leaderboard', // new translation key: common__leaderboard
        'DefaultGroup': 'Default Group', // a part of new translation key: leaderboard__default-group and add-leaderboard__title--edit-default, new translation key: common__group--default
        'StoresInLeaderboard': 'Stores In Leaderboard:',
        'SelectStores': 'Select Stores:*',
        'Submit': 'Submit',
        'noLeaderboardSubscription': 'The person that you have invited to participate does not have a Leaderboard subscription.', // new translation key: add-leaderboard__error-no-lb-subscription
        'noEditLeaderboardPermission': 'The person that you invited to participate in your Leaderboard does not have the permission to receive Leaderboard invitations.', // new translation key: add-leaderboard__error-no-edit-permission
        'inviteFailed': 'Leaderboard Invitation Failed', // new translation key: add-leaderboard__error-invite-failed
        'inviteSuccess': 'Your invitation has been sent.',
        'notfound': 'The account email you entered is not a valid HME CLOUD user.', // new translation key: add-leaderboard__error-email-not-found
        'participatingAccounts': 'Participating Accounts', // new translation key: add-leaderboard__accounts__participating
        'participatingUsers': 'Participating Users',
        'LBInviteSuccess1': 'Congratulations, you have accepted to join the new Leaderboard!',
        'LBInviteSuccess2': 'All of your stores will be automatically moved over to the new Leaderboard',
        'LBInviteLoginNew': 'Click here to log into the HME CLOUD and view your new Leaderboard:',
        'LBInviteLoginCurrent': 'Click here to log into the HME CLOUD and view your current Leaderboard:',
        'LBInviteReject': 'You have chosen to decline the invitation to join a new Leaderboard.',
        'LBInviteInvalidURL': 'Invalid/Expired URL',
        'LastUpgrade': 'Last Upgrade', // new translation key: system-status__last-upgrade
        'currentlyviewingasUserAccountOwner': 'Currently viewing as User Account Owner',
        'settingsSent': 'Settings Sent!',
        'NoStoresFound': 'No Stores Found', // new translation key: common__no-stores--found
        'InternalErrorSomethingWentwrong': 'Sorry, Something went wrong',
        'InternalErrorContactAdministrator': 'Sorry, Please contact your administrator.',
        'InternalErrorTechnicalDetails': 'TECHNICAL DETAILS',
        'InternalErrorError': 'Error',
        'InternalErrorGoBackToSite': 'GO BACK',
        'MergeTargetStoreNoDeviceAssociated': 'There is no device associated to target store.', // new translation key: admin__store-merge__error--no-device
        // SmackTalk
        'smacktalk_heading': 'Smack Talk', // new translation key: common__smack-talk
        'smacktalk_advanced': 'Advanced Settings',
        'smacktalk_select_category': 'Select category of Smack Talk message to display',
        'smacktalk_selected_message': 'Selected message',
        'smacktalk_select_message': 'Select a {CATEGORY} message', // new translation key: smack-talk__tab__category-section--select-message
        'smacktalk_seconds': 'Seconds', // new translation key: common__seconds
        'smacktalk_minutes': 'Minutes',
        'smacktalk_hours': 'Hours',
        'smacktalk_display_time': 'Message Display Time',
        'smacktalk_date': 'Date',
        'smacktalk_date_time': 'Sent Date & Time', // new translation key: smack-talk__tab__history-list--date-time
        'smacktalk_from': 'From',
        'smacktalk_stores': 'Stores', // new translation key: common__stores
        'smacktalk_my_store': 'My Store',
        'smacktalk_to_stores': 'To',
        'smacktalk_message': 'Message', // new translation key: common__message
        'smacktalk_messages': 'Messages',
        'smacktalk_create_custom_message': 'Create New Custom Message', // new translation key: smack-talk__tab__messages-landing--create-custom-message
        'smacktalk_edit_custom_message': 'Edit Custom Message', // new translation key: smack-talk__tab__save-custom-message--edit-custom-message
        'smacktalk_no_custom_messages': 'You have not created and enabled any custom messages.',
        'smacktalk_no_messages_found': 'No messages found.',
        'smacktalk_message_status': 'Message Availability', // new translation key: smack-talk__tab__save-custom-message--message-status
        'smacktalk_enter_message': 'Enter a new message', // new translation key: smack-talk__tab__save-custom-message--enter-message
        'smacktalk_message_created': 'A new message was successfully created.', // new translation key: smack-talk__tab__save-custom-message--message-created
        'smacktalk_message_deleted': 'A custom message was successfully removed.', // new translation key: smack-talk__tab__manage-messages--message-deleted
        'smacktalk_message_saved': 'The message was successfully saved.', // new translation key: smack-talk__tab__manage-messages--message-saved
        'smacktalk_send_message': 'Send a message', // new translation key: smack-talk__tab--send-message
        'smacktalk_manage_messages': 'Manage Messages', // new translation key: smack-talk__tab--manage-messages
        'smacktalk_view_previously': 'View Previously Sent Messages',
        'smacktalk_add_emoji': 'Add emoji or picture to your message',
        'smacktalk_display_image': 'Display image',
        'smacktalk_select_store': 'Select Store(s) to display message',
        'smacktalk_no_stores_selected': 'No stores selected',
        'smacktalk_send': 'Send',
        'smacktalk_cancel': 'Cancel', // new translation key: common__cancel
        'smacktalk_change_image': 'Change image',
        'smacktalk_validation_generic': 'Please select a message category and destination store.',
        'smacktalk_validation_image_size_error': 'The uploaded image does not fit required size. Please adjust the size of the image to between {SIZE_SECTION} and under {WEIGHT_SECTION} and try again.',
        'smacktalk_validation_message': 'Please select a message to send.',
        'smacktalk_validation_store': 'Please select store to send the message to.',
        'smacktalk_validation_time': 'Display time should be in range from {MIN} seconds to {MAX} hours.',
        'smacktalk_sent': 'Your message was successfully sent',
        'smacktalk_send_failed': 'Could not send the message. Please try again', // new tranlation key: smack-talk__tab__send-message--send-failed
        'smacktalk_no_store': 'No store',
        'smacktalk__category_boast': 'Boast', // new translation key: smack-talk__category-boast
        'smacktalk__category_taunt': 'Taunt', // new translation key: smack-talk__category-taunt
        'smacktalk__category_congratulate': 'Congrats', // new translation key: smack-talk__category-congratulate
        'smacktalk__category_custom': 'Custom', // new translation key: smack-talk__category-custom
        'smacktalk__header_category': 'Category', // new translation key: common__category
        'smacktalk__message_desc__boast_king': 'King of the World!', // new translation key: smack-talk__boast-king
        'smacktalk__message_desc__boast_winning': 'Winning is the only thing!', // new translation key: smack-talk__boast-winning
        'smacktalk__message_desc__boast_in_the_lead': 'In the lead!', // new translation key: smack-talk__boast-in-the-lead
        'smacktalk__message_desc__boast_marshmallows': 'Someone grab some marshmallows cuz we\'re on fire!', // new translation key: smack-talk__boast-marshmallows
        'smacktalk__message_desc__boast_congratulate': 'Don\'t hate - congratulate!', // new translation key: smack-talk__boast-congratulate
        'smacktalk__message_desc__taunt_pace': 'Better pick up the pace!', // new translation key: smack-talk__taunt-pace
        'smacktalk__message_desc__taunt_catch_us': 'Catch us if you can!', // new translation key: smack-talk__taunt-catch-us
        'smacktalk__message_desc__taunt_laugh': 'Don\'t make me laugh!', // new translation key: smack-talk__taunt-laugh
        'smacktalk__message_desc__taunt_is_that_all': 'Is that all you got?', // new translation key: smack-talk__taunt-is-that-all
        'smacktalk__message_desc__taunt_ZZZZZZZ': 'I could do better in my sleep...ZZZZZZZ!', // new translation key: smack-talk__taunt-ZZZZZZZ
        'smacktalk__message_desc__taunt_broken_clock': 'I\'ve seen better times on a broken clock!', // new translation key: smack-talk__taunt_broken-clock
        'smacktalk__message_desc__taunt_valium': 'You\'re slower than a slug on valium!', // new translation key: smack-talk__taunt-valium
        'smacktalk__message_desc__taunt_slow': 'You\'re so slow, you\'d come in 3rd in a 2 man race!', // new translation key: smack-talk__taunt-slow
        'smacktalk__message_desc__taunt_zombies': 'You\'re so slow, you chase zombies!', // new translation key: smack-talk__taunt-zombies
        'smacktalk__message_desc__taunt_backwards': 'If you went any slower, you would be going backwards!', // new translation key: smack-talk__taunt-backwards
        'smacktalk__message_desc__taunt_do_you_even': 'Do you even drive-thru, bro?', // new translation key: smack-talk__taunt-do-you-even
        'smacktalk__message_desc__congratulate_congratulations': 'Congratulations, you rock!', // new translation key: smack-talk__congratulate-congratulations
        'smacktalk__message_desc__congratulate_good_work': 'Keep up the good work!', // new translation key: smack-talk__congratulate-good-work
        'smacktalk__message_desc__congratulate_we_didnt_bet': 'I\'m glad we didn\'t bet!', // new translation key: smack-talk__congratulate-we-didnt-bet
        'smacktalk__message_desc__congratulate_i_like_the_way': 'I like the way you work!', // new translation key: smack-talk__congratulate-i-like-the-way
        'smacktalk__message_desc__congratulate_good_job': 'Good job team!', // new translation key: smack-talk__congratulate-good-job
        'smacktalk__message_desc__congratulate_awesone': 'You\'re awesome!', // new translation key: smack-talk__congratulate-awesone
        'smacktalk__message_desc__congratulate_great_job': 'Great job today!', // new translation key: smack-talk__congratulate-great-job
        'smacktalk__message_desc__taunt_were_coming': 'We\'re coming for you!', // new translation key: smack-talk__taunt-were-coming
        'smacktalk__message_desc__taunt_mouth_shape': 'If you ran a store like your mouth, you\'d be in good shape!', // new translation key: smack-talk__taunt-mouth-shape
        'smacktalk__message_desc__taunt_bam': 'Bam!', // new translation key: smack-talk__taunt-bam
        'smacktalk__message_desc__taunt_uh_wow': 'Uh...wow!', // new translation key: smack-talk__taunt-uh-wow
        'smacktalk__message_desc__taunt_watch_out': 'Watch out!', // new translation key: smack-talk__taunt-watch-out
        'smacktalk__message_desc__taunt_going_down': 'You\'re going down!', // new translation key: smack-talk__taunt-going-down
        'smacktalk__message_desc__taunt_back_hurts': 'Man, my back hurts from carrying my team!', // new translation key: smack-talk__taunt-back-hurts
        'smacktalk__message_desc__taunt_me_awesome': 'There is no "u" in "awesome" but there is a "me"!', // new translation key: smack-talk__taunt-me-awesome
        'smacktalk__message_desc__taunt_ignore': 'I\'m busy right now, can I ignore you some other time?', // new translation key: smack-talk__taunt-ignore
        'smacktalk__message_desc__taunt_under_estimate': 'It\'s impossible to underestimate you!', // new translation key: smack-talk__taunt-under-estimate
        'smacktalk__message_desc__taunt_jealous': 'I\'m jealous of all the people who haven\'t met you!', // new translation key: smack-talk__taunt-jealous
        'smacktalk__message_desc__taunt_delusions': 'You have delusions of adequacy!', // new translation key: smack-talk__taunt-delusions
        'smacktalk__message_desc__taunt_wits': 'I\'d prefer a battle of wits, but you appear unarmed!', // new translation key: smack-talk__taunt-wits
        'smacktalk__lb_text__boast_king': 'King of the World!', // new translation key: smack-talk__boast-king
        'smacktalk__lb_text__boast_winning': 'Winning is the only thing!', // new translation key: smack-talk__boast-winning
        'smacktalk__lb_text__boast_in_the_lead': 'In the lead!', // new translation key: smack-talk__boast-in-the-lead
        'smacktalk__lb_text__boast_marshmallows': 'Someone grab some marshmallows, cuz we\'re on fire!', // new translation key: smack-talk__boast-marshmallows
        'smacktalk__lb_text__boast_congratulate': 'Don\'t hate - congratulate!', // new translation key: smack-talk__boast-congratulate
        'smacktalk__lb_text__taunt_pace': 'Better pick up the pace!', // new translation key: smack-talk__taunt-pace
        'smacktalk__lb_text__taunt_catch_us': 'Catch us if you can!', // new translation key: smack-talk__taunt-catch-us
        'smacktalk__lb_text__taunt_laugh': 'Don\'t make me laugh!', // new translation key: smack-talk__taunt-laugh
        'smacktalk__lb_text__taunt_is_that_all': 'Is that all you got?', // new translation key: smack-talk__taunt-is-that-all
        'smacktalk__lb_text__taunt_ZZZZZZZ': 'I could do better in my sleep...ZZZZZZZ!', // new translation key: smack-talk__taunt-ZZZZZZZ
        'smacktalk__lb_text__taunt_broken_clock': 'I\'ve seen better times on a broken clock!', // new translation key: smack-talk__taunt_broken-clock
        'smacktalk__lb_text__taunt_valium': 'You\'re slower than a slug on valium!', // new translation key: smack-talk__taunt-valium
        'smacktalk__lb_text__taunt_slow': 'You\'re so slow, you\'d come in 3rd in a 2 man race!', // new translation key: smack-talk__taunt-slow
        'smacktalk__lb_text__taunt_zombies': 'You\'re so slow, you chase zombies!', // new translation key: smack-talk__taunt-zombies
        'smacktalk__lb_text__taunt_backwards': 'If you went any slower, you would be going backwards!', // new translation key: smack-talk__taunt-backwards
        'smacktalk__lb_text__taunt_do_you_even': 'Do you even drive-thru, bro?', // new translation key: smack-talk__taunt-do-you-even
        'smacktalk__lb_text__congratulate_congratulations': 'Congratulations, you rock!', // new translation key: smack-talk__congratulate-congratulations
        'smacktalk__lb_text__congratulate_good_work': 'Keep up the good work!', // new translation key: smack-talk__congratulate-good-work
        'smacktalk__lb_text__congratulate_we_didnt_bet': 'I\'m glad we didn\'t bet!', // new translation key: smack-talk__congratulate-we-didnt-bet
        'smacktalk__lb_text__congratulate_i_like_the_way': 'I like the way you work!', // new translation key: smack-talk__congratulate-i-like-the-way
        'smacktalk__lb_text__congratulate_good_job': 'Good job team!', // new translation key: smack-talk__congratulate-good-job
        'smacktalk__lb_text__congratulate_awesone': 'You\'re awesome!', // new translation key: smack-talk__congratulate-awesone
        'smacktalk__lb_text__congratulate_great_job': 'Great job today!', // new translation key: smack-talk__congratulate-great-job
        'smacktalk__lb_text__taunt_were_coming': 'We\'re coming for you!', // new translation key: smack-talk__taunt-were-coming
        'smacktalk__lb_text__taunt_mouth_shape': 'If you ran a store like your mouth, you\'d be in good shape!', // new translation key: smack-talk__taunt-mouth-shape
        'smacktalk__lb_text__taunt_bam': 'Bam!', // new translation key: smack-talk__taunt-bam
        'smacktalk__lb_text__taunt_uh_wow': 'Uh...wow!', // new translation key: smack-talk__taunt-uh-wow
        'smacktalk__lb_text__taunt_watch_out': 'Watch out!', // new translation key: smack-talk__taunt-watch-out
        'smacktalk__lb_text__taunt_going_down': 'You\'re going down!', // new translation key: smack-talk__taunt-going-down
        'smacktalk__lb_text__taunt_back_hurts': 'Man, my back hurts from carrying my team!', // new translation key: smack-talk__taunt-back-hurts
        'smacktalk__lb_text__taunt_me_awesome': 'There is no "u" in "awesome" but there is a "me"!', // new translation key: smack-talk__taunt-me-awesome
        'smacktalk__lb_text__taunt_ignore': 'I\'m busy right now, can I ignore you some other time?', // new translation key: smack-talk__taunt-ignore
        'smacktalk__lb_text__taunt_under_estimate': 'It\'s impossible to underestimate you!', // new translation key: smack-talk__taunt-under-estimate
        'smacktalk__lb_text__taunt_jealous': 'I\'m jealous of all the people who haven\'t met you!', // new translation key: smack-talk__taunt-jealous
        'smacktalk__lb_text__taunt_delusions': 'You have delusions of adequacy!', // new translation key: smack-talk__taunt-delusions
        'smacktalk__lb_text__taunt_wits': 'I\'d prefer a battle of wits, but you appear unarmed!', // new translation key: smack-talk__taunt-wits
        'smacktalk__public_select_a_store_that_will_send_the_message': 'Select a store that will send the message.', // new translation key: smack-talk__tab__send-message--sender-store
        'smacktalk__public_select_a_store_that_will_receive_the_message': 'Select the stores that will receive the message.', // new translation key: smack-talk__tab__send-message--receiver-store
        'smacktalk__public_select_a_message_category': 'Select a message category.', // new Transaction key: smack-talk__tab__send-message--select-category
        'smacktalk__public_a_specific_store': 'A Specific Store', // new Transaction key: smack-talk__tab__sender-section--specific-store
        'smacktalk__public_store_num_name_lb': 'Store #, Name or Leaderboard', // new Transaction key: smack-talk__tab__receiver-section--store-num-name-lb
        'smacktalk__public_send_message': 'Send Message', // new translation key: smack-talk__tab__send-message--send-message
        'smacktalk__are_you_sure_to_delete_custom_message': 'Are you sure you want to remove this custom message?',  // new translation key: smack-talk__tab__messages-landing--are-you-sure
        'smacktalk__public_send_filter': 'Filter', // new translation key: common__filter
        'smacktalk__public_send_sort': 'Sort', // new translation key: common__sort
        'smacktalk__public_sort_n_filter': 'Sort & Filter', // new translation key: smack-talk__tiles-header--sort-n-filter
        'smacktalk__public_manage_disabled_message': 'ENABLED VS. DISABLED MESSAGES', // new translation key: smack-talk__tab__manage-messages--disabled-message
        'smacktalk__public_manage_hide_disabled_message': 'Hide disabled messages', // new translation key: smack-talk__tab__messages-landing--hide-disabled
        'smacktalk__public_apply_filters': 'Apply Filters', // new translation key: common__apply-filters
        'no_data_found': 'No data found', // new translation key: common__error--no-data-found
        'smacktalk__public_categories': 'Categories',  // new translation key: common__categories
        'smacktalk__public_all_categories': 'All Categories',  // new translation key: common__all-categories
        'smacktalk__public_senders': 'Senders', // new translation key: common__senders
        'smacktalk__public_all_senders': 'All Senders', // new translation key: common__all-senders
        'smacktalk__public_recipients': 'Recipients', // new translation key: common__recipients
        'smacktalk__public_all_recipients': 'All Recipients', // new translation key: common__all-recipients
        'smacktalk__public_new_to_old': 'Sort New to Old', // new translation key: smack-talk__tiles-header--new-to-old
        'smacktalk__public_old_to_new': 'Sort Old to New', // new translation key: smack-talk__tiles-header--old-to-new
        'smacktalk__public_a_to_z': 'Sort Messages A to Z', // new translation key: smack-talk__tiles-header--a-to-z
        'smacktalk__public_z_to_a': 'Sort Messages Z to A', // new translation key: smack-talk__tiles-header--z-to-a
        'smacktalk__public_filters_applied': 'Filters Applied', // new translation key: common__filters-applied
        'smacktalk__public_reset_all_filters': 'Reset All Filters', // new translation key: common__reset-all-filters
        'smacktalk__public_characters_remaining': '{COUNT} out of {MAX} characters remaining', // new key translation: smack-talk__tab__manage-messages--characters-remaining
        'smacktalk__public_characters_info': '{MAX} characters allowed', // new key translation: smack-talk__tab__manage-messages--characters-info

        'repeatPresentation': 'Repeat Presentation:',
        'endDisplay': 'End display',
        'awardDurationSettingDescription': 'This page will be displayed immediately after the Award is achieved and run for the amount of time and frequency selected below.', // new translation key: announcement-settings__award-duration-settings-description
        'contestWinnerSettingsDescription': 'This page will be displayed immediately after the Contest is won and run for the amount of time and frequency selected below.', // new translation key: announcement-settings__contest-winner-settings-description
        'afterMessageSent': 'after message is sent', // new translation key: announcement-settings__after-message-sent
        'roundStartingBannerDescription': 'In addition to the settings below, this banner will be displayed continuously for 60 seconds before the Round starts.', // new translation key: announcement-settings__round-starting-banner-description
        'contestStartingBannerDescription': 'In addition to the settings below, this banner will be displayed continuously for 60 seconds before the Contest starts.', // new translation key: announcement-settings__contest-starting-banner-description
        'beforeContestStarts': 'before Contest starts', // new translation key: announcement-settings__before-contest-starts
        'beforeRoundStarts': 'before Round starts', // new translation key: announcement-settings__before-round-starts
        'afterAwardIsAchieved': 'after Award is achieved', // new translation key: announcement-settings__after-award-achieved
        'afterContestEnds': 'after Contest ends', // new translation key: announcement-settings__after-contest-ends
        'sameCompanyInvite': 'A Leaderboard invitation cannot be sent to a user from the same account.', // new translation key: add-leaderboard__error-same-company
        'youAreNotAllowedToMakeThisUserAnOwner': 'You are not allowed to make this user an owner', // new translation key: add-leaderboard__modal--update-owner__error--no-access
        'acceptedInvite': 'Accepted Invite',
        'deniedInvite': 'Denied Invite',
        'companyExistsInLB': 'The invited company already exists in the Leaderboard.', // new translation key: add-leaderboard__error-company-exists-in-lb
        'companyDeniedInLB': 'The invited company has already denied an invitation.', // new translation key: add-leaderboard__error-company-denied
        'companyPendingInLB': 'The account email you entered has already been invited.', // new translation key: add-leaderboard__error-company-pending
        'selectStoresMsg': 'Please select Stores to add to this Leaderboard.', // new translation key: add-leaderboard__select-stores
        'NoParticipantStores': 'No Stores', // new translation key: add-leaderboard__stores__no-stores
        'declineInvitation': 'Are you sure you would like to decline the invitation?', // new translation key: invite-message__reject__body
        'selectNoStores': 'Are you sure you would like to select no stores at this time?',
        'storeSelection': 'Store Selection',
        'declineInvitationHeader': 'Decline Invitation', // new translation key: invite-message__reject__header
        'inviteHello': 'Hello,', // part of new translation key: invite-message__body
        'inviteMessage1': ' has invited you to participate in a shared Leaderboard store ranking!', // part of new translation key: invite-message__body
        'inviteConsent': 'Consent: ', // part of new translation key: invite-message__body
        'inviteMessage2': 'Please note that if you accept this invitation, you are consenting to having your stores Leaderboard ranking information shared with ', // part of new translation key: invite-message__body
        'inviteMessage3': ' and the others stores in the same Leaderboard.', // part of new translation key: invite-message__body
        'inviteMessage4': 'Clicking “Yes, I accept and will select my stores” button below will direct you to select your stores to add to the inviter’s Leaderboard.', // part of new translation key: invite-message__body
        'inviteMessage5': 'Clicking “No, I decline the invitation” will decline the invitation. ', // part of new translation key: invite-message__body
        'inviteAcceptBtn1': 'Yes, I accept and will', // part of new translation key: invite-message__accept-button
        'inviteAcceptBtn2': 'select my stores', // part of new translation key: invite-message__accept-button
        'inviteRejectBtn1': 'No, I decline the', // part of new translation key: invite-message__reject-button
        'inviteRejectBtn2': ' invitation', // part of new translation key: invite-message__reject-button
        'inviteOptout': 'Opt Out: ', // part of new translation key: invite-message__footer
        'inviteMessage6': 'If at any time, you wish to return your stores to your own account Leaderboard, you may log into the HME CLOUD and remove them from the Leaderboard Overview', // part of new translation key: invite-message__footer
        'Pending': 'Pending',
        'Denied': 'Denied',
        'Accepted': 'Accepted',
        'LeaderboardAd1': 'This area is for the administration of the HME’s Drive-thru Leaderboard, HME’s store ranking dashboard that creates friendly competition between stores to motivate crews and increase performance!', // new translation key: gamification-marketing__administration
        'LeaderboardAd2': 'You are not subscribed to this service. To subscribe, please contact your HME Account Representative or call: U.S. Sales: ', // new translation key: gamification-marketing__no-subscription
        'LeaderboardAd2_not_perm': 'You do not have permission to view this page. To be granted permission, please contact your account administrator or call HME technical support at: ', // new translation key: gamification-marketing__no-permissions
        'LeaderboardAd3': ' or International Sales: ', // a part of new translation key: gamification-marketing__support
        'LeaderboardAdPhone1': '800.848.4468', // a part of new translation key: gamification-marketing__support
        'LeaderboardAdPhone2': '+1 858.535.6000', // a part of new translation key: gamification-marketing__support
        'LeaderboardNoEditPerm': 'The account administrator in your organization has not provided you permission to use this feature. If you feel that you should have permission, please contact your account administrator to provide it for you.',
        'nitroAnnouncementValidationError': 'One or more of the display options that you have selected is missing a time.',
        'frequencyMustBeShorterThanDuration': 'You must enter a frequency setting that is shorter than the duration setting',
        'frequencyShouldNotBeEqual': 'The frequency of the Notifications cannot be set the same as the frequency for either of the Presentation Pages.', // new translation key: announcement-settings__validation--same-frequency
        'nitroAnnouncementOutOfRangeError': 'You must enter a number between {min} and {max}.',
        'ParticipatingStores': 'Participating Stores', // new translation key: stores-selector__participating-stores
        'ViewMyStoresOnly': 'View my stores only', // new translation key: add-leaderboard__view-my-stores
        'Default': '  (Default)',
        'selectStores': 'select stores', // new translation key: contest__select-stores
        'Predefined': 'Predefined', // new translation key: common__predefined
        'storesSelected': 'stores selected',
        'LeaderboardAvatarBox': 'Avatars', // new translation key: sub-header--lb__avatars
        'LBTemplatesList': 'Templates', // new translation key: sub-header--lb__templates
        'LeaderboardAnnouncementSettings': 'Announcement Settings', // new translation key: common__announcement-settings
        'LeaderboardNotifications': 'Notifications',
        'LeaderboardStores': 'Leaderboard Stores', // new translation key: common__leaderboard-stores
        'AvatarBoxMissingAvatarError': 'Avatars could not be loaded', // new translation key: avatars__error--missing-avatar
        'AvatarBoxAssigmentFailedError': 'Avatar assignment failed', // new translation key: avatars__error--assignment-failed
        'noAccessError1': 'The store you are looking for does not exist or you do not have permissions to view the store.  Please return to the ', // a part of new translation key: store-details__error--no-access
        'noAccessError2': 'page to find the store you are trying to access.', // a part of new translation key: store-details__error--no-access

        // Contests
        'contest_lastYear': 'Last year', // new translation key: contest__previous__year
        'contest_last6Mon': 'Last 6 months', // new translation key: contest__previous__six-months
        'contest_last3Mon': 'Last 3 months', // new translation key: contest__previous__three-months
        'contest_last1Mon': 'Last month', // new translation key: contest__previous__month
        'contest_dateRange': 'Date Range',
        'contest_status_scheduled': 'Scheduled', // new translation key: contest__status--scheduled
        'contest_status_onGoing': 'On-going', // new translation key: contest__status--on-going
        'contest_status_completed': 'Completed', // new translation key: contest__status--completed
        'contest_status_cancelled': 'Canceled', // new translation key: contest__status--canceled
        'contest_cancelConfirm': 'Are you sure you want to cancel this contest?\nThis action cannot be undone.', // new translation key: contest__warning__cancel-contest
        'displayImageOnLb': 'Display image on Contest Winner Presentation Page', // new translation key: contest__display-image-on-presentation-page
        'contest_add': 'Add a Contest', // new translation key: contest__add-contest
        'contestReward': 'Contest Reward', // new translation key: contest__reward
        'contest_no_records_found': 'Your on-going and scheduled contests list here',
        'contest_Name': 'Name', // new translation key: common__name
        'contest_Description': 'Description', // new translation key: common__description
        'contest_Reward': 'Reward',
        'contest_Stores': 'Stores', // new translation key: common__stores
        'contest_StartDateTime': 'Start Date/Time', // new translation key: contest__start-date--time
        'contest_EndDateTime': 'End Date/Time', // new translation key: contest__end-date--time
        'contest_Status': 'Status', // new translation key: common__status
        'contest_Cancel': 'Cancel', // new translation key: common__cancel
        'contest_Results': 'Results', // new translation key: contest__results
        'contest_View': 'View',
        'contest_Certificate': 'Certificate', // new translation key: contest__certificate
        'contest_Contests': 'Contests', // new translation key: common__contests
        'contest_Contest': 'Contest', // new translation key: common__contest
        'contest_History': 'History', // new translation key: common__history
        'contest_start': 'start', // new translation key: common__start
        'contest_end': 'end', // new translation key: common__end
        'contest_certificate_Header': 'Select Stores to Download Certificates', // new translation key: contest__certificate-header
        'contest_certificate_stores_error': 'Please select at least one store', // new translation key: contest__certificate-select-store-error
        'contest_Sunday': 'Sunday', // new translation key: common__sunday
        'contest_Monday': 'Monday', // new translation key: common__monday
        'contest_Tuesday': 'Tuesday', // new translation key: common__tuesday
        'contest_Wednesday': 'Wednesday', // new translation key: common__wednesday
        'contest_Thursday': 'Thursday', // new translation key: common__thursday
        'contest_Friday': 'Friday', // new translation key: common__friday
        'contest_Saturday': 'Saturday', // new translation key: common__saturday
        'contest_not_found': 'Contest not found', // new translation key: contest__not-found
        'contest_settings': 'Contest Settings', // new translation files: contest__settings
        'contest_settings_name': 'name',
        'contest_settings_Description': 'Description', // new translation key: common__description
        'contest_settings_Reward': 'Reward', // new translation key: common__reward
        'contest_settings_duration': 'Duration', // new translation key: common__duration
        'contest_settings_hours': 'hours', // new translation key: common__hours
        'contest_settings_timeZone': 'Time Zone', // new translation key: common__timezone
        'contest_settings_RecurrenceDays': 'Recurrence Days', // new translation key: common__recurrence-days
        'contest_settings_metric': 'Metric', // new trasnlation key: common__metric
        'contest_settings_metric_detail': 'Metric Detail',
        'contest_settings_metric_totalCar': 'Car Count', // new trasnlation key: contest__settings__metric-car-count
        'contest_settings_metric_laneTotal': 'Lane Total', // new trasnlation key: contest__settings__metric-lane-total
        'contest_settings_metric_laneTotal2': 'Lane Total 2', // new trasnlation key: contest__settings__metric-lane-total-two
        'contest_settings_metric_avg': 'Avg Time', // new trasnlation key: contest__settings__metric-avg-time
        'contest_settings_metric_percent': '% of Goal', // new trasnlation key: contest__settings__metric-goal-percent
        'contest_settings_Everyday': 'Everyday', // new translation key: common__everyday
        'contest_settings_AnnouncementSettings': 'Announcement Settings', // new translation key: common__announcement-settings
        'contest_settings_saved': 'Contest Settings have been saved', // new translation key: contest__settings-saved
        'contest_settings_error_length': '{NAME} description must be less than 50 characters.',
        'contest_settings_error_name': 'Only letters and numbers allowed',
        'contest_settings_error_duration_start_created': 'Contest Start date must be from date created or in the future',
        'contest_settings_error_duration_end': 'Contest End date must be after start date', // new translation key: contest__error__end-date-time
        'contest_settings_error_duration_start_date': 'Contest Start Date and Time must occur in the future', // new translation key: contest__error__start-date-time
        'contest_settings_error_days_selected': 'Selected days are not present in a contest date range', // new translation key: contest__error__days-not-present-in-range
        'contest_settings_error_duration_max': 'Maximum duration is a year', // new translation key: contest__error__max-duration
        'contest_settings_error_image_size_error': 'The uploaded image does not fit required size. Please adjust the size of the image to between {SIZE_SECTION} and try again.',
        'contest_settings_field_name': 'Contest Name', // new translation key: contest__name
        'contest_settings_field_hours': 'Hours', // new translation key: contest__time-period--hours
        'contest_settings_field_days': 'Recurrence Day(s)', // new translation key: contest__time-period--days
        'contest_settings_field_timeZone': 'Time Zone', // new translation key: common__timezone
        'contest_settings_field_stores': 'Stores', // new translation key: common__stores
        'contest_announsement_hours': 'Entered hours not allowed more than {HOUR} hrs', // new translation key: contest__error--announcement-hours
        'contest_announsement_duration': 'Entered value should be greater than 1/4 of the contest', // new translation key: contest__error--announcement-duration
        'contest_announsement_minutes': 'Entered minutes not allowed more than {MIN} min', // new translation key: contest__error--announcement-minutes
        'contest_info_ContestName': 'Contest',
        'contest_info_ContestDesc': 'Description',
        'contest_info_RewardDesc': 'Reward',
        'contest_info_Days': 'Days',
        'contest_info_Hours': 'Hours',
        'contest_info_StartDate': 'Start',
        'contest_info_EndDate': 'End',
        'contest_info_TimeZone': 'Time Zone', // new translation key: common__timezone
        'contest_info_ranking_metric': 'Ranking Metric', // new translation key: common__ranking-metric
        'contest_results_Header': 'Contest Results Report', // new translation key: contest__result__title-results
        'contest_results_Header_final': 'Contest Final Results Report', // new translation key: contest__result__title-final-results
        'contest_results_Rank': 'Rank',
        'contest_results_Store': 'Store',
        'contest_results_AvgTime': 'Avg Time', // new translation key: contest__settings__metric-avg-time
        'contest_results_Cars': 'Cars', // new translation key: common__cars
        'contest_results_GoalPercent': '% Goal Met', // new translation key: contest__result__goal-percent-met
        'contest_results_Points': 'Points', // new translation key: common__points
        'contest_results_Total_Points': 'Total points', // new translation key: contest__result__total-points
        'contest_results_Trend': 'Trend',
        'contest_results_report_date': 'Report date/time: {TIME}', // new translation key: contest__result__report-date-time
        'contest_results_trend_alt_down': 'trend down',
        'contest_results_trend_alt_zero': 'trend did not change',
        'contest_results_trend_alt_up': 'trend up',
        'contest_results_filename': 'ContestResults', // new translation key: contest__results__file-name
        'contest_results_warning_empty_results': 'Results is empty for this contest', // new translation key: contest__result__empty-results
        'contest_results_Overall_Contest_Ranking': 'Overall Contest Ranking', // new translation key: contest__result__overall-ranking
        'contest_results_Results_by_Round': 'Results by Round', // new translation key: contest__result__results-by-round
        'contest_submit': 'submit', // new translation key: common__submit
        'contest_storeHoursWarning': 'NOTE: You must select stores that are open throughout the length of the contest.', // new translation key: contest__store-hours-warning
        'button_icon': 'button icon', // new translation key: common__image-alt__button-icon
        'link_icon': 'link icon',
        'table_error_header': 'TableHeader should be the first child of Table',
        'table_error_children': 'Children of the Table should be of type TableRow or TableHeader, got {TYPE}',
        'table_error_header_children': 'Children of TableHeader should be of type TableHeaderCell, got {TYPE} instead',
        'table_error_row_children': 'Children of TableRow should be of type TableCell, got {TYPE} instead',
        'table_error_header_child': 'TableHeader is required as a child of Table',
        'table_error_header_one': 'There should be only one TableHeader per Table',
        'image_preview': 'Image preview', // new translation key: common__image-preview
        'display_image': 'Display image', // new translation key: common__image-display
        'change_image': 'Change image', // new translation key: common__image-change
        'SendSmackTalkMessage': 'Send Smack Talk Messages', // new translation key: common__permission--send-smack-talk-message
        'ViewEditAwards': 'View/Edit Awards', // new translation key: common__permission--view-edit-awards
        'treeSelect__empty_tree': 'No Stores', // new translation key: tree-select-input__empty
        // Announcement settings
        'announcement__header': 'Announcement Settings', // new translation key: common__announcement-settings
        'announcement__always': 'Always',
        'announcement__immediate': 'Immediate',
        'announcement__hours': 'Hours',
        'announcement__minutes': 'Minutes',
        'announcement__desc_before_start': 'Announcement before Contest Starts',
        'announcement__desc_has_started': 'Announcement that Contest Has Started',
        'announcement__desc_new_number_one': 'New Number one Announcement',
        'announcement__desc_will_end': 'Announcement that the Contest will End',
        'announcement__desc_has_ended': 'Announcement of Winners Podium ',
        'announcement__desc_winner': 'Announcement of Winners Presentation',
        'announcement__setting_desc_before_start_time': 'Time before contest starts',
        'announcement__setting_desc_before_start_repeat': 'Repeat Contest will start announcement',
        'announcement__setting_desc_before_start_display': 'Display time',
        'announcement__setting_desc_has_started_time': 'Time after contest has started',
        'announcement__setting_desc_has_started_repeat': 'Repeat contest has started announcement',
        'announcement__setting_desc_has_started_display': 'Display time',
        'announcement__setting_desc_new_number_one_time': 'Time to display after change in position',
        'announcement__setting_desc_new_number_one_repeat': 'How often to display',
        'announcement__setting_desc_new_number_one_display': 'Display time',
        'announcement__setting_desc_will_end_time': 'Time before contest ends',
        'announcement__setting_desc_will_end_repeat': 'Repeat Contest will End announcement',
        'announcement__setting_desc_will_end_display': 'Display time',
        'announcement__setting_desc_has_ended_time': 'Time after contest ends',
        'announcement__setting_desc_has_ended_repeat': 'Repeat contest has ended announcement',
        'announcement__setting_desc_has_ended_display': 'Display time',
        'announcement__setting_desc_winner_time': 'Display duration after contest ends',
        'announcement__setting_desc_winner_repeat': 'Repeat announcement',
        'announcement__setting_desc_winner_display': 'Display time',

        // general gamification translations
        'gamification__onCancel': 'Are you sure you would like to cancel? Changes will not be saved.', // new translation key: contest__cancel-confirmation

        // general validation messages
        'validation__required': '{NAME} must be entered.',
        'validation__min_symbols': 'Min {SIZE} symbols',
        'validation__max_symbols': 'Max {SIZE} symbols',
        'validation__min_array_elements': 'At least {SIZE} must be entered.', // new translation key: common__error--min-array-length
        'validation__must_be_number': 'Must be a number.',

        // Awards
        'awards__main_header': 'Awards', // new translation key: common__awards
        'awards__main_configure_awards': 'Configure Awards', // new translation key: awards__configure-awards
        'awards__main_summary_view': 'Summary view',
        'awards__main_detail_view': 'Detailed view',
        'awards__resettable_summary_view': 'Summary', // new translation key: common__summary
        'awards__resettable_detail_view': 'Details', // new translation key: common__details
        'awards__resettable_reset_history': 'Reset History', // new translation key: common__reset-history
        'awards__main_year': 'Year', // new translation key: common__year
        'awards__main_stores': 'Stores', // new translation key: common__stores
        'awards__all_stores': 'All Stores', // new translation key: common__all-stores
        'awards__trophy_case_store': 'Store:', // new translation key: common__store--colon
        'awards__main_filter': 'Filter', // new translation key: common__filter
        'awards__clear_filters': 'Clear Filters',
        'awards__resettable_filter': 'Filter Awards', // new translation key: awards__awards-resettable--filter-awards
        'awards__resettable_reset': 'Reset Awards Count', // new translation key: awards__awards-resettable--reset-awards-count
        'awards__resettable_count_reset': 'The awards count has been reset.', // new translation key: awards__awards-resettable--count-reset
        'awards__resettable_count_warning': 'Resetting this count will set the Awards count on all Leaderboards to 0.', // new translagion key: awards__awards-resettable--count-warning
        'awards__resettable_reset_warning': 'Are you sure you want to reset the Awards count on all Leaderboards?', // new translagion key: awards__awards-resettable--reset-warning
        'awards__empty_trophy_case': 'Trophy case is empty for specified parameters',
        'awards__no_records_found': 'There are no trophy cases that match your query', // new translation key: awards__trophy-case--no-records-found
        'awards__count_before_reset': 'award count before reset', // new translation key: awards__count-before-reset
        'awards__reset_by': 'reset by', // new translation key: common__reset-by
        'awards__reset_date_time': 'reset date & time', // new translation key: common__reset-date-time
        'awards__counts_reset_refuse': 'These award counts were reset within the last day.', // new translation key: awards__awards-resettable--reset-refuse
        // Awards config
        'awards__config_header': 'Configure Awards', // new translation key: awards__configure-awards
        'awards__config_sub_header': 'Pre-configured awards',
        'awards__config_add_custom': 'Add a custom award',
        'awards__config_surprise_header': 'Surprise awards (Intermittent)',
        'awards__config_surprise_opt_affort': 'Effort Award - accasionally when a store has improved significantly.',
        'awards__config_surprise_opt_affort_input_month_number': 'Maximum number of awards in a month',
        'awards__config_surprise_opt_achievement': 'Achievement award - accasionally when as store has hit a high achievement.',
        'awards__config_surprise_opt_achievement_input_month_number': 'Maximum number of awards in amonth',
        'awards__config_delete': 'Delete award',
        'awards__config_delete_confirm': 'Are you sure you want to delete? It cannot be redone.',
        'awards__config_save': 'Save award',
        'awards__config_cancel': 'cancel', // new translation key: common__cancel
        'awards__config_settings_saved': 'Award settings have been saved!', // new translation key: awards__configure-awards--settings-saved
        'awards__config_reset': 'Award settings have been saved!', // new translation key: awards__configure-awards--settings-saved
        'awards__config_actions_save': 'Save settings',
        'awards__config_actions_cancel': 'cancel', // new translation key: common__cancel
        // award config form
        'awards__config_name': 'name',
        'awards__config_short_name': 'short name',
        'awards__config_description': 'description',
        'awards__config_metric': 'metric',
        'awards__config_metric_detail': 'metric detail',
        'awards__config_period': 'period',
        'awards__config_period_info': 'Period is the time included to calculate the award.',
        'awards__config_period_value': 'period value',
        'awards__config_recurrence_type': 'Recurrence',
        'awards__config_recurrence_type_info': 'Recurrence is how often the award is given.  Ex.  A Weekly award is given once a week',
        'awards__config_operator': 'operator',
        'awards__config_operator_greater_than': 'Greater than',
        'awards__config_operator_less_than': 'Less than',
        'awards__config_amount': 'amount',
        'awards__config_announcement': 'Award announcement',
        'awards__config_announcement_image': 'Award image',
        'awards__config_announcement_message': 'display message',
        'awards__settings_metric_totalTime': 'Total Time',
        'awards_config_recurrence_type_hourly': 'hourly',
        'awards_config_recurrence_type_daily': 'daily',
        'awards_config_recurrence_type_weekly': 'weekly',
        'awards_config_recurrence_type_monthly': 'monthly',
        'awards_config_recurrence_type_yearly': 'yearly',
        'awards_config_operator_highest': 'highest',
        'awards_config_operator_lowest': 'lowest',
        'awards_config_period_settings_hour': 'hour',
        'awards_config_period_settings_day': 'day',
        'awards_config_period_settings_week': 'week',
        'awards_config_period_settings_month': 'month',
        'awards_config_period_settings_year': 'year',
        // Awards table
        'awards__config_table_enable': 'Enable',
        'awards__config_table_default': 'Default Award Name',
        'awards__config_table_default_short': 'Default Award Short Name',
        'awards__config_table_custom': 'Custom Award Name',
        'awards__config_table_custom_short': 'Custom Award Short Name',
        'awards__config_table_header_default': 'Default Awards',
        'awards__config_table_header_custom': 'Custom Awards',
        // Awards history
        'awards__history_month_year': 'Month/Year',
        'awards__history_date_time': 'Date/Time',
        'awards__history_store': 'Store', // new translation key: common__store
        'awards__history_contests': 'Contests', // new translation key: common__contests
        'awards__history_awards': 'Awards', // new translation key: common__awards
        'awards__history_award': 'Award',
        'awards__history_award_type': 'Award Type',
        'awards__history_Title': 'Awards History Report',
        'awards__history_filename': 'AwardResults', // new translation key: awards__awards-resettable--history-filename
        // Realtime feedback
        'realtime_feedback__header': 'Feedback Settings',
        'realtime_feedback__realtime_header': 'Real-Time Feedback',
        'realtime_feedback__inactivity_header': 'Inactivity Alert',
        'realtime_feedback__contextual_header': 'Contextual Feedback',
        'realtime_feedback__realtime_description': 'Alerts stores when there is a change in ranking position.',
        'realtime_feedback__inactivity_description': 'Alerts stores when there has been no change in ranking position for the specified period of time.',
        'realtime_feedback__contextual_description': 'Informs each store what is needed to move up in ranking, near the end of each period.',
        'realtime_feedback__time_until_period_ends': 'Time until period ends:',
        'realtime_feedback__minutes': 'Minutes',
        'realtime_feedback__hours': 'Hours',
        'realtime_feedback__ranking_changes': 'Ranking Changes',
        'realtime_feedback__1_lead': '#1 Lead',
        'realtime_feedback__no_ranking_changes_for': 'No Ranking Changes for',
        'multipleEmailsErrorMessage': 'You may not enter more than one email address. Please revise the email address entered.',
        'enterSingleEmailMessage': 'Enter a single email address for the account you will invite.',
        'leaderboardSettingsMessage': 'IMPORTANT: Please note that changing the settings on this page will change the settings for ALL Leaderboards in your account. This may affect stores that are not on your specific Leaderboard.',
        'char_counter__entered': 'characters entered', // new translation key: common__characters-entered
        'char_counter__left': 'characters remaining', // new translation key: common__characters-remaining
        'TileMoreOptionsDuplicate': 'Duplicate',
        'TileMoreOptionsRemove': 'Remove',

        // Added Dynamic HomeScreen
        'WelcomeTileHeader': 'Welcome', // new translation key: welcome__tile--welcome__title
        'WelcomeTileParagraph1': 'Welcome to the updated HME CLOUD and ZOOM Nitro Drive-thru Optimization system! Navigate using the Quick Links or click “Get Training” to access your HME Training Portal and watch videos on how best use your HME products to improve drive-thru performance and crew engagement.', // new translation key: welcome__tile--welcome__paragraph1
        'WelcomeTileParagraph2': 'Through the HME CLOUD, you can set store goals, monitor performance across your enterprise, and much more. Everything you need to optimize your QSR drive-thru operation is right here at your fingertips!', // new translation key: welcome__tile--welcome__paragraph2
        'WelcomeTileLearnMoreButton': 'Learn More',
        'WelcomeTileAddButton': 'New Tile',
        'QuickLinks': 'Quick Links', // new translation key: welcome__tile--quick-links__title
        'AvgTimeSec': 'Avg. Time (sec)', // new translation key: welcome__tile--store-ranking__header part of it
        'StoreRanking': 'Store Ranking', // new translation key: welcome__tile--store-ranking__title

        // Award Template - AnnouncementText
        'record_best_avg_time_hour_l': 'Record for Best Average Time in an Hour',
        'record_most_cars_day_l': 'Record for Most Cars Served in a Day',
        'most_cars_month_l': 'Most Cars Served in a Month',
        'record_best_avg_time_day_l': 'Record for Best Average Time in a Day',
        'best_avg_time_month_l': 'Best Avg Time for the Month',
        'best_%_goal_month_l': 'Best % of Goal for the Month',
        'goal_achiever_l': 'Best % of Goal for the Day',
        'speedster_l': 'Best Speed of Service for the Day',
        '%_goal_l': 'Best % Change Increase in Performance from Previous Day',
        'sprint_l': 'Consistent Improvement of Average Time over Three Hours',
        'streak_l': 'Three Days in a Row of Improved % of Goal Met',
        'underdog_l': 'Five Days in a Row of Improved % of Goal Met',
        'trifecta_l': 'Fastest Average Time, Most Cars and Best % of Goal Met in the same Week',
        'record_most_cars_hour_l': 'Record for Most Cars Served in a Hour',
        'triple_crown_l': 'Fastest Average Time, Most Cars and Best % of Goal In the same Month',

        //  Award Template - Award Short Name
        'record_best_avg_time_hour_s': 'Record - Best Avg Time/Hour',
        'record_most_cars_day_s': 'Record - Most Cars/Day',
        'most_cars_month_s': 'Most Cars/Month',
        'record_best_avg_time_day_s': 'Record - Best Avg Time/Day',
        'best_avg_time_month_s': 'Best Avg Time/Month',
        'best_%_goal_month_s': 'Best % of Goal/Month',
        'goal_achiever_s': 'Goal Achiever',
        'speedster_s': 'Speedster',
        '%_goal_s': '% of Goal',
        'sprint_s': 'Sprint',
        'streak_s': 'Streak',
        'underdog_s': 'Underdog',
        'trifecta_s': 'Trifecta',
        'record_most_cars_hour_s': 'Record - Most Cars/Hour',
        'triple_crown_s': 'Triple Crown',

        // Award Name - Default Awards
        'record_best_avg_time_hour_name': 'Record Holder - Best average Time for an Hour',
        'record_most_cars_day_name': 'Record Holder - Most Cars Served in a Day',
        'most_cars_month_name': 'Most Cars Served in a Month',
        'record_best_avg_time_day_name': 'Record Holder - Best average Time for a Day',
        'best_avg_time_month_name': 'Best average Time for a Month',
        'best_%_goal_month_name': 'Best % of Goal Met in a Month',
        'goal_achiever_name': 'Goal Achiever - Best % of Goal Met for the Day',
        'speedster_name': 'Speedster Award - Fastest Speed of Service for the Day',
        '%_goal_name': 'Most Improved - Most improved in Goal % Met for the Day',
        'sprint_name': 'Sprint Award - Consistent Improvement of Avg. Time over 3 Hours',
        'streak_name': 'Streak Award - Consistent improvement over 3 days',
        'underdog_name': 'Underdog Award - Consistent improvement in Avg Time for 5 Days in a Row',
        'trifecta_name': 'Trifecta - Fastest, Most Cars and Best % of Goal Met in a Week',
        'record_most_cars_hour_name': 'Record Holder - Most Cars Served in an Hour',
        'triple_crown_name': 'Triple Crown - Fastest, Most Cars and Best Goal % in a Month',

        // Pre-install store
        'storealreadyexists': 'A Store with this Brand and Store Number already exists on this Account.', // new translation key: stores__error--already-exists

        // ApplyDeviceSettings
        'apply_device_settings_title': 'Apply Device Settings', // new translation key: common__apply-device-settings
        'apply_device_settings_timeout': 'This request has time out and the settings were unable to be sent to the device. Please try again.',
        'account_not_selected': 'You must select an Account.',
        'no_device': 'No device found.',
        'type_mismatch': 'The device must match the Source Device Type selected.',
        'device_offline': 'The device must be online.',
        'snapshot_not_selected': 'You must select a Settings Snapshot.', // new translation key: admin__apply-settings-nexeo__error--empty-snapshot
        'source_not_selected': 'You must select a Settings Snapshot or a Source Device Serial Number and at least one group of Source Device Settings.',
        'store_number_not_associated': 'You must select a Store Number that is associated with the selected Account.',
        'unsupported_version': 'The device must be on Version 4.1 or higher.',
        'nothing_selected': 'You must select either a Store Number associated with an Account or a Target Device Serial Number.',
        'source_device_settings_empty': 'You must select at least one group of settings.',
        'empty_settings_version': 'Settings version cannot be empty',
        'process_not_ended': 'Settings application process has not ended',
        'equal_serial_numbers': 'The Target Device Serial Number must be different than the Source Device Serial Number.',
        'no_stores_found': 'No stores found', // new translation key: common__no-stores--found
        'account_should_be_selected': 'Account should be selected', // new translation key: admin__apply-settings--nexeo__error--empty-acount
        'no_snapshots': 'There are no Snapshots available.', // new translation key: admin__apply-settings-nexeo__no-snapshots
        'apply_device_settings_confirmation': 'Applying settings that change the lane configuration will result in data loss.', // new translation key: admin__apply-settings--nexeo__confirm__message
        'public_apply_device_settings_title': 'Apply Device Settings', // new translation key: common__apply-device-settings
        'public_apply_device_settings_timeout': 'This request has time out and the settings were unable to be sent to the device. Please try again.',
        'public_account_not_selected': 'You must select an Account.',
        'public_no_device': 'No device found.',
        'public_type_mismatch': 'The device must be ZOOM Nitro on Version 4.2.0 or higher.',
        'public_device_offline': 'The device must be online.',
        'public_snapshot_not_selected': 'You must select a Settings Snapshot.',
        'public_source_not_selected': 'You must select Source Device Serial Number and at least one group of Source Device Settings.',
        'public_nothing_selected': 'You must enter a Target Device Serial Number.',
        'public_store_number_not_associated': 'You must select a Store Number that is associated with the selected Account.',
        'public_unsupported_version': 'The device must be on Version 4.1 or higher.',
        'public_source_device_settings_empty': 'You must select at least one group of settings.',
        'public_empty_settings_version': 'Settings version cannot be empty',
        'public_process_not_ended': 'Settings application process has not ended',
        'public_equal_serial_numbers': 'The Target Device Serial Number must be different than the Source Device Serial Number.',
        'public_no_stores_found': 'No stores found', // new translation key: common__no-stores--found
        'public_account_should_be_selected': 'Account should be selected',
        'public_no_snapshots': 'There are no Snapshots available.',
        'no_available_systems': 'No systems available to merge.', // new translation key: admin__store-merge__error--no-systems
        'CONTEXTUALFEEDBACK': 'Contexual Feedback', // new translation key: announcement-settings__title--contextual-feedback
        'contexualFeedbackDescription': `Contextual Feedback will be displayed only when the Leaderboard is ranking on Avg Time for the Current Hour or Previous Hour. It will appear on a restaurant's Leaderboard when it is close to achieving first place and will cover part of the screen when active. Choose to display this window for a minimum of 30 seconds up to 5 minutes (300 seconds).`, // new translation key: announcement-settings__contextual-feedback-description
        'windowDisplay': 'The window will be displayed for',
        'GoalDisplay': 'Goal Display', // new translation key: display-settings__title--goal
        'ShowGoal': 'Show Goal', // new tranlsation key: display-settings__goal--show
        'PreferenceHideGoal': 'Hide Goal', // new translation key: display-settings__goal--hide
        'hideGoalTooltip': 'Hiding the Goal will remove the \'Goal %\' column and the display of the Goal in the \'Avg Time\' column on the Leaderboard', // new translation key: display-settings__tip--goal

        // DeviceUpgrades
        'admin_select_different_search_title': 'Whoa, hold on...', // new translation key: confirm-popup__default-title
        'admin_select_different_search': 'Only one search method can be used in Step 2 when scheduling devices for an upgrade. Selecting a different search method now will remove all devices you have selected in Step 3. Do you wish to continue?', // new translation key: admin__device-upgrade__confirm__message
        'admin_continue': 'Continue', // new translation key: common__continue

        // CREATE/EDIT LB TEMPLATES
        'public_create_template': 'Create Template', // new translation key: leaderboard__template__create-template
        'public_cancel_and_go_back_to_all_templates': 'Cancel and go back to all templates', // new translation key: leaderboard__template__cancel-go-back
        'public_cancel_and_go_back_to_all_leaderboards': 'Cancel and go back to all Leaderboards', // new translation key: add-leaderboard__back-link
        'public_give_the_template_a_name_and_choose_a_store_display': 'Give the template a name and choose a store display.', // new translation key: leaderboard__template__give-name-to-template
        'public_add_up_to_two_leaderboard_sections': 'Add up to two Leaderboard sections and select a ranking column.', // new translation key: leaderboard__template__add-ranking-column
        'public_configure_the_leaderboard_settings': 'Configure the Leaderboard settings.', // new translation key: leaderboard__template__configure-lb-settings
        'ranking_column': 'Ranking Column', // new translation key: leaderboard__template__ranking-column
        'public_edit_template': 'Edit Template', // new translation key: leaderboard__template__edit-template
        'public_general': 'General', // new translation key: common__general
        'public_sections': 'Sections', // new translation key: common__sections
        'public_settings': 'Settings', // new translation key: common__settings
        'public_please_note_that_editing_template': 'Please note that editing these settings will update the display for all Leaderboards using this template.', // new translation key: leaderboard__template__edit-warning
        'public_please_note_that_editing_settings': 'Please note that editing these settings will update the display for all owned Leaderboards.', // new translation key: leaderboard__template__edit-warning-3x
        'public_remember_that_changing_will_change_for_all_leaderboards': 'Remember that changing the settings on this template will change the settings for ALL Leaderboards that use this template.', // new translation key: leaderboard__template__edit-confirmation
        'public_remember_that_changing_will_change_for_all_owned_leaderboards': 'Remember that changing the settings here will change the settings for ALL owned Leaderboards.', // new translation key: leaderboard__template__edit-confirmation-3x
        'public_are_you_sure_to_make_changes_to_the_template': 'Are you sure you want to make these changes to the template and all associated Leaderboards?', // new translation key: leaderboard__template__edit-submit-confirm
        'public_are_you_sure_to_make_changes_to_the_leaderboard': 'Are you sure you want to make these changes to all associated Leaderboards?', // new translation key: leaderboard__template__edit-submit-confirm-3x
        'public_template_name': 'TEMPLATE NAME', // new translation key: common__template-name
        'public_store_display': 'STORE DISPLAY', // new translation key: common__store-display
        'public_store_name': 'Store Name', // new translation key: common__store__name
        'public_store_number': 'Store Number', // new translation key: common__store__number--text
        'public_summary': 'SUMMARY', // new translation key: common__summary
        'public_created_by': 'CREATED BY', // new translation key: add-leaderboard__created-by
        'public_changed_by': 'UPDATED BY', // new translation key: add-leaderboard__updated-by
        'public_used_by': 'USED BY', // new translation key: common__used-by
        'public_leaderboard_sections': 'LEADERBOARD SECTIONS', // new translation key: leaderboard__template__lb-secions
        'public_add_section': 'Add Section', // new trasnlation key: leaderboard__template__sortable-board-add-section
        'public_add_section_optional': 'Add Section (optional)', // new trasnlation key: leaderboard__template__sortable-board-add-section-optional
        'public_add_column': 'Add Column', // new translation key: leaderboard__template__sortable-board-add-column
        'storeDisplayTooltip': 'Choose to display Store Names or Store Numbers for all Stores participating in the Leaderboard.', // new translation key: leaderboard__template__store-display-tooltip
        'goalDisplayTooltip': 'Hiding the goal will remove the \'Goal %\' column and the display of the Goal in the \'Avg Time\' column on the Leaderboard.', // new translation key: leaderboard__template__goal-display-tooltip
        'includePullinsTooltip': 'Choose whether to include or exclude Pull-in data in the Leaderboard metrics calculation.',
        'wantToCreateYourOwnTemplates': 'Want to create your own custom templates for Leaderboard settings?', // a part of new translation key: add-leaderboard__upgrade-to-nitro
        'CallHME': 'Call HME at', // a part of new translation key: add-leaderboard__upgrade-to-nitro
        'soYouCanUpgradeToNitro': 'to upgrade to the HME Nitro Leaderboard or Nitro Gamification.', // a part of new translation key: add-leaderboard__upgrade-to-nitro
        'public_template_create_success': 'Success! The Template settings have been saved.', // new translation key: leaderboard__template__create-success
        'public_template_edit_success': 'Success! The Template settings have been updated.', // new translation key: leaderboard__template__edit-success
        'public_template_save_fail': 'Error! The Template settings could not be applied at this time.', // new translation key: leaderboard__template__error__save-template
        'public_template_duplicate_template_name': 'A template by the same name already exists. Please create a unique template name.', // new translation key: leaderboard__template__error__duplicate-template-name
        'public_lb_setting_save_success': 'Success! The Leaderboard settings were applied.', // new translation key: leaderboard__settings__success--save
        'public_lb_setting_save_fail': 'Error! The Leaderboard settings could not be applied at this time.', // new translation key: leaderboard__settings__error--save
        'public_lb_group_create_success': 'Success! The Leaderboard has been saved.', // new translation key: leaderboard__success--save
        'public_lb_group_edit_success': 'Success! The Leaderboard has been updated.', // new translation key: leaderboard__success--edit
        'justDoubleChecking': 'Just double-checking…', // new translation key: common__double-checking
        'noOfStores': '# of Stores', // new translation key: leaderboard__count-of-stores
        'template': 'Template', // new translation key: common__template
        'role': 'Role', // new translation key: common__role
        'Role': 'Role', // new translation key: common__role
        'description': 'Description',
        'history': 'History', // new translation key: common__history
        'createdhistory': 'CREATED', // new translation key: common__created
        'createdbyhistory': 'CREATED BY', // new translation key: common__created-by
        'updatedhistory': 'UPDATED', // new translation key: common__updated-by
        'updatedbyhistory': 'UPDATED BY', // new translation key: common__updated-by
        'Preview': 'Preview', // new translation key: common__preview
        'creatednewleaderboard': 'Create New Leaderboard', // new translation key: leaderboard__create-lb
        'lbwasremovedandstoresswitchedtodefaultgroup': 'The Leaderboard has been removed successfully and all stores can now be found in the Default Leaderboard.', // new translation key: leaderboard__success--cancel
        'removingthislbwillrevertstorestodefault': 'Removing this Leaderboard will cause all stores associated with it to instead show the Default Leaderboard.', // a part of new translation key: leaderboard__confirm--remove
        'areyousureaboutremovingthislb': 'Are you sure you want to remove this Leaderboard?', // a part of new translation key: leaderboard__confirm--remove
        'templateremovedandstoresreturnedtodefault': 'The template has been removed successfully. All associated Leaderboards have been moved back to the Default Template.', // new translation key: leaderboard__template__delete-success
        'Configuration': 'Configuration', // new translation key: leaderboard__template__configuration
        'DefaultTemplate': 'Default Template', // new translation key: leaderboard__template__default-template
        'thisisanaccountwideactiontodefaulttemplate': 'This is an account-wide action and removing this template will cause all Leaderboards using it to be switched over to using the Default Template.', // new translation key: leaderboard__template__delete-warning
        'createnewtemplate': 'Create New Template', // new translation key: leaderboard__template__create-new-template
        'youselectednostoresforthislb': 'You have not selected any stores to participate on this Leaderboard. You may choose to add stores later.', // new translation key: add-leaderboard__modal--no-stores__body
        'areyousureyouwanttomakealbwithoutanystores': 'Are you sure you want to create a Leaderboard now without selecting any stores to participate?', // new translation key: add-leaderboard__modal--no-stores__confirm
        'savingthiswilleffectallparticipatingstores': 'Saving these changes will affect the Leaderboard display for all participating stores and for any stores removed from the Leaderboard.', // new translation key: add-leaderboard__modal--edit__body
        'areyousureaboutthesechanges': 'Are you sure you want to make these changes?', // new translation key: add-leaderboard__modal--edit__confirm
        'givethelbanameanddescription': 'Give the Leaderboard a name and an optional description.', // new translation key: add-leaderboard__first-section__title
        'selectatemplate': 'Select a Leaderboard template.', // new translation key: add-leaderboard__second-section__title
        'findandselectdesiredstores': 'Find and select the stores you want to participate in this Leaderboard.', // new translation key: add-leaderboard__third-section__title
        'Administrator': 'Administrator', // new translation key: common__administrator
        'thisisanitrolb': 'This is a Nitro Leaderboard', // new translation key: add-leaderboard__nitro-label
        'leaderboardtemplate': 'Leaderboard Template', // new translation key: add-leaderboard__template-label
        'selecttemplate': 'Select a Template', // new translation key: add-leaderboard__template-placeholder
        'previewtemplate': 'Preview Template', // new translation key: common__preview-template
        'searchstores': 'SEARCH STORES', // new translation key: add-leaderboard__search-stores
        'typestorenumberorstorename': 'Type Store#, Store Name', // new translation key: add-leaderboard__search-stores__placeholder
        'AVAILABLE STORES': 'AVAILABLE STORES', // new translation key: add-leaderboard__available-stores
        'PARTICIPATINGSTORES': 'PARTICIPATING STORES', // new translation key: add-leaderboard__participating-stores
        'invitewassent': 'The Leaderboard invite was successfully sent.', // new translation key: add-leaderboard__success--invite
        'inviteparticipant': 'Invite Participant', // new translation key: add-leaderboard__invite-participant
        'email': 'email', // new translation key: common__email
        'areyousureyouwanttoremovethisparticipant': 'Are you sure you want to remove this Participant?', // new translation key: add-leaderboard__modal--remove__confirm--admin
        'areyousureyouwanttoremovethisaccount': 'Are you sure you want to remove your account from this Leaderboard?', // new translation key: add-leaderboard__modal--remove__confirm
        'enterjustoneemail': '`ENTER ONLY ONE EMAIL ADDRESS`', // new translation key: add-leaderboard__invite-email-placeholder
        'enterthedesiredparticipantaccount': 'Enter the account email address of the member you would like to participate on this Leaderboard.', // new translation key: add-leaderboard__enter-participant
        'rememberWarning': 'Remember...', // new translation key: add-leaderboard__modal--edit__title
        'Announcements': 'Announcements', // new translation key: welcome__tile--announcements__title
        'LearnAboutTemplatesTileP1': 'We are excited to debut a new feature that gives you more control over customizing your Leaderboard to better suit the needs of your organization!', // new translation key: welcome__tile--learn-lb-templates__paragraph1
        'LearnAboutTemplatesTileP2': 'New Leaderboard settings templates allow you to measure performance in different ways across your unique Leaderboards. For instance, you can rank on Goal % for one Leaderboard and Avg. Time for others. In creating these templates, you have complete control over how each individual Leaderboard is set up--there is no limit to the number of templates you can create and easily apply to one or more of your Leaderboards.', // new translation key: welcome__tile--learn-lb-templates__paragraph2
        'ClickHereToLearnMore': 'Click here to learn more!', // new translation key: welcome__tile--learn-lb-templates__link
        'portalUnavailable1': 'As part of our continuous efforts to provide the best service to our customers, the HME team is upgrading our current infrastructure. This process includes upgrading our application and database server hosts to use the latest hosting environment, which provides higher computing and operational powers, more opportunities for scalability, and enhanced stability that allows us to continue supporting the growth of the HME CLOUD and all connected devices. Please be informed that this requires an outage of the HME CLOUD portal from ',
        'portalUnavailable2': 'Data collection will continue during this outage and there is no action required from your end. The Leaderboard will also continue to display data, though we anticipate minor delays in updating the Leaderboard and Gamification Contest results during the outage. The HME CLOUD portal will be accessible again once the upgrades are completed. It is advisable to plan any changes to account and device settings, including Leaderboard and Nitro Gamification setup, ahead of time and to avoid any changes during the outage.',
        'portalUnavailableDate': 'July 24th, 9:00 AM PDT until July 25th, 11:00 PM PDT. ',
        'CustomizeSmackTalk': 'Customize Smack Talk', // new translation key: common__permission--customize-smack-talk
        'SendSmackTalk': 'Send Smack Talk', // new translation key: common__permission--send-smack-talk

        // Library Components
        'common_remaining_count_message': '{COUNT} out of {MAX} characters remaining',
        'autocomplete_input__suggestions': 'Suggestions…', // new translation key: autocomplete-input__suggestions
        'autocomplete_input__loading': 'Please wait...', // newtranslation key: autocomplete-input__loading
        'grid__filter__popup__show_me': 'Show me', // new translation key: common__show-me
        'grid__filter__popup__only': 'only', // new translation key: common__only
        'grid__filter__popup__all__default': 'All', // new translation key: common__all

        // Public Apply Device Settings Status Page new design
        'apply_device_settings_status__title': 'Apply Device Settings', // new translation key: common__apply-device-settings
        'apply_device_settings_status__create_new_task': 'Create New Task', // new translation key: apply-device-settings--status__create-new-task
        'apply_device_settings_status__grid_title__source_device': 'Source Device', // new translation key: apply-device-settings-status__grid-title__source-device
        'apply_device_settings_status__grid_title__task_information': 'Task Information', // new translation key: apply-device-settings-status__grid-title__task-information
        'apply_device_settings_status__grid_title__target_devices': 'Target devices', // new translation key: apply-device-settings-status__grid-title__target-devices
        'apply_device_settings_status__grid_header__serial_number': 'Serial #', // new translation key: common__device__serial-number
        'apply_device_settings_status__grid_header__store_name': 'Store name', // new translation key: common__store__name
        'apply_device_settings_status__grid_header__brand': 'Brand', // new translation key: common__brand
        'apply_device_settings_status__grid_header__store_number': 'Store #', // new translation key: common__store__number
        'apply_device_settings_status__grid_header__version': 'Version', // new translation key: common__device__version
        'apply_device_settings_status__grid_header__applied_settings': 'Applied settings', // new translation key: apply-device-settings-status__grid-header__applied-settings
        'apply_device_settings_status__grid_header__devices_count': '# of devices', // new translation key: apply-device-settings-status__grid-header__devices-count
        'apply_device_settings_status__grid_header__start_date': 'Start date & time', // new translation key: apply-device-settings-status__grid-header__start-date
        'apply_device_settings_status__grid_header__applied_by': 'Applied by', // new translation key: apply-device-settings-status__grid-header__applied-by
        'apply_device_settings_status__grid_header__task_status': 'Task status', // new translation key: apply-device-settings-status__grid-header__task-status
        'apply_device_settings_status__grid_header__device_status': 'Device status', // new translation key: apply_device_settings_status__grid_header__device-status
        'apply_device_settings_status__grid__loading': 'Loading...', // new translation key: common__loading
        'apply_device_settings_status__task_status--in_progress': 'In Progress', // new translation key: common__task__status--in-progress (check if used)
        'apply_device_settings_status__task_status--completed': 'Completed', // new translation key: common__task__status--completed
        'apply_device_settings_status__task_status--failed': 'Failed', // new translation key: common__task__status--failed
        'apply_device_settings_status__grid_filter__all_brands': 'All brands', // new translation key: apply-device-settings-status__grid-filter__all-brands
        'apply_device_settings_status__grid_filter__all_settings': 'All', // new translation key: common__all
        'apply_device_settings_status__grid_filter__all_versions': 'All versions', // new translation key: apply-device-settings-status__grid-filter__all-versions
        'apply_device_settings_status__grid_filter__all_statuses': 'All statuses', // new translation key: apply-device-settings-status__grid-filter__all-statuses
        'apply_device_settings_status__grid_filter__task_status--in_propgress': 'In Progress', // new translation key: common__task__status--in-progress
        'apply_device_settings_status__grid_filter__task_status--completed': 'Completed', // new translation key: common__task__status--completed
        'apply_device_settings_status__grid_filter__task_status--failed': 'Failed', // new translation key: common__task__status--failed
        'apply_device_settings_status__grid_filter__task_status--unknown': 'Unknown Status', // new translation key: common__task__status--unknown
        'apply_device_settings_status__setting_name--lane-settings': 'Lane Settings', // new translation key: apply-device-settings-status__setting-name--lane-settings
        'apply_device_settings_status__setting_name--dayparts_and_shifts': 'Dayparts and Shifts', // new translation key: apply-device-settings-status__setting-name--dayparts-and-shifts
        'apply_device_settings_status__setting_name--store_hours': 'Store Hours', // new translation key: apply-device-settings-status__setting-name--store-hours
        'apply_device_settings_status__setting_name--dashboard_settings': 'Dashboard Settings', // new translation key: apply-device-settings-status__setting-name--dashboard-settings
        'apply_device_settings_status__setting_name--all_settings': 'All Settings', // new translation key: apply-device-settings-status__setting-name--all-settings
        'apply_device_settings_status__setting_name--speed_goals': 'Speed Goals (Default & Daypart)', // new translation key: apply-device-settings-status__setting-name--speed-goals
        'apply_device_settings_status__setting_name--time_format': 'Time Format', // new translation key: common__time-format
        'apply_device_settings_status__no_tasks--load': 'You don’t have any tasks now', // new translation key: apply_device_settings_status__no_tasks--load
        'apply_device_settings_status__no_devices--load': 'You don’t have any devices now', // new translation key: apply-device-settings-status__no-devices--load
        'apply_device_settings_status__no_tasks--found': 'No tasks were found', // new translation key: apply-device-settings-status__no-tasks--found
        'apply_device_settings_status__no_devices--found': 'No devices were found', // new translation key: apply-device-settings-status__no-devices--found

        // Public Apply Device Settings Create New Task Page new design
        'apply_device_settings_new__back_link': 'Cancel and go back to all jobs', // new translation key: apply-device-settings-new__back-link
        'apply_device_settings_new__source_section__title': '1. Enter the ZOOM Nitro source device information.', // new translation key: apply-device-settings-new__source-section__title
        'apply_device_settings_new__target_section__title': '2. Select destination device(s) to apply settings', // apply-device-settings-new__target-section__title
        'apply_device_settings_new__loading': 'Device settings are being applied to the selected devices…', // apply-device-settings-new__loading
        'apply_device_settings_new__success': 'Success! The device settings were applied to the selected devices.', // new translation key: apply-device-settings-new__success
        'apply_device_settings_new__failed': 'Error The device settings could not be applied at this time.', // new translation key: apply-device-settings-new__failed
        'apply_device_settings_new__source_input__header__device_serial': 'Device Serial#', // new translation key: apply-device-settings-new__source-input__header__device-serial
        'apply_device_settings_new__source_input__header__store_number': 'Store#', // new translation key: apply-device-settings-new__source-input__header__store-number
        'apply_device_settings_new__source_input__header__brand': 'Brand', // new translation key: common__brand
        'apply_device_settings_new__source_input__header__store_name': 'Store Name', // new translation key: common__store__name
        'apply_device_settings_new__source_input__header__lane_type': 'Lane Type', // new translation key: apply-device-settings-new__source-input__header__lane-type
        'apply_device_settings_new__source_input__default_label': 'Device Serial Number', // new translation key: apply-device-settings-new__source-input__default-label
        'apply_device_settings_new__target_grid__header__serial_number': 'Device Serial #', // new translation key: apply-device-settings-new__target-grid__header__serial-number
        'apply_device_settings_new__target_grid__header__version': 'Version', // new translation key: common__device__version
        'apply_device_settings_new__target_grid__header__brand': 'Brand', // new translation key: common__brand
        'apply_device_settings_new__target_grid__header__store_name': 'Store Name', // new translation key: common__store__name
        'apply_device_settings_new__target_grid__header__store_number': 'Store #', // new translation key: common__store__number
        'apply_device_settings_new__target_grid__search_placeholder': 'Search for Device', // new translation key: apply-device-settings-new__target-grid__search-placeholder
        'apply_device_settings_new__footer__cancel': 'Cancel', // new translation key: common__cancel
        'apply_device_settings_new__footer__submit': 'Apply Settings', // new translation key: apply-device-settings-new__footer__submit
        'apply_device_settings_new__settings__title': 'CHOOSE DEVICE SETTINGS TO APPLY', // apply-device-settings-new__settings__title
        'apply_device_settings_new__settings__toggle__all': 'All Settings', // apply-device-settings-new__settings__toggle__all
        'apply_device_settings_new__settings__toggle__groups': 'Groups of Settings', // apply-device-settings-new__settings__toggle__groups
        'apply_device_settings_new__settings__all_info': 'All settings do not include store specific settings.', // apply-device-settings-new__settings__all-info
        'apply_device_settings_new__info__title': 'Examples of Excluded Store-specific Settings', // apply-device-settings-new__info__title
        'apply_device_settings_new__info__excluded--store_details': 'Store Details', // apply-device-settings-new__info__excluded--store-details
        'apply_device_settings_new__info__excluded--hme_settings': 'HME CLOUD Settings', // apply-device-settings-new__info__excluded--hme-settings
        'apply_device_settings_new__info__excluded--passwords': 'Passwords', // apply-device-settings-new__info__excluded--passwords
        'apply_device_settings_new__info__excluded--network_settings': 'Network Settings', // apply-device-settings-new__info__excluded--network-settings
        'apply_device_settings_new__info__lane_settings__title': 'Lane Settings', // apply-device-settings-new__info__lane-settings__title
        'apply_device_settings_new__info__lane_settings__item_1': 'Customize when your Total Time starts and stops (Total Time for Lane, Total 2 Time for Lane)', // apply-device-settings-new__info__lane-settings__item-1
        'apply_device_settings_new__info__lane_settings__item_2': 'Set Pullout, Pullin, and Pull Forward standards', // apply-device-settings-new__info__lane-settings__item-2
        'apply_device_settings_new__info__lane_settings__item_3': 'Choose to include or exclude Pullins from data', // apply-device-settings-new__info__lane-settings__item-3
        'apply_device_settings_new__info__dayparts__title': 'Dayparts and Shifts', // apply-device-settings-new__info__dayparts__title
        'apply_device_settings_new__info__dayparts__item_1': 'Choose Daypart start times and Shift start and end times', // apply-device-settings-new__info__dayparts__item-1
        'apply_device_settings_new__info__dayparts__item_2': 'Name Dayparts and Shifts', // apply-device-settings-new__info__dayparts__item-2
        'apply_device_settings_new__info__speed_goals__title': 'Speed Goals', // apply-device-settings-new__info__speed-goals__title
        'apply_device_settings_new__info__speed_goals__item_1': 'Customize Default Goals and Daypart Goals (Default Goals include any period of time outside of a Daypart)', // apply-device-settings-new__info__speed-goals__item-1
        'apply_device_settings_new__info__speed_goals__item_2': 'Set Default and Daypart Goals for each day of the week', // apply-device-settings-new__info__speed-goals__item-2
        'apply_device_settings_new__info__store_hours__title': 'Store Hours', // apply-device-settings-new__info__store-hours__title
        'apply_device_settings_new__info__store_hours__item_1': 'Set opening and closing time of the Store for each day of the week', // apply-device-settings-new__info__store-hours__item-1
        'apply_device_settings_new__info__dashboard__title': 'Dashboard Settings', // apply-device-settings-new__info__dashboard__title
        'apply_device_settings_new__info__dashboard__item_1': 'Customize dashboard layout and metric settings', // apply-device-settings-new__info__dashboard__item-1
        'apply_device_settings_new__info__time_format__item_1': 'Display time in seconds or minutes and seconds', // apply-device-settings-new__info__time-format__item-1
        'apply_device_settings_new__info__time_format__item_2': 'This setting is only available for devices on ZOOM Nitro Version 4.5.34 or higher', // apply-device-settings-new__info__time-format__item-2
        'leaderboard_sections_tooltip': 'When adding two sections, the first section will be shown on the left half of the Leaderboard and the second section will be shown on the right half of the Leaderboard.', // new translation key: leaderboard__template__lb-secions-tooltip
        'nitro_gamification_announcement_title': 'Exciting ZOOM Nitro® Gamification Updates', // new translation key: welcome__tile--announcement__title
        'nitro_gamification_announcement_desc_intro': 'Thanks to your feedback, we\'ve optimized the ZOOM Nitro Gamification experience with a redesigned Leaderboard layout and new custom Smack Talk messaging to further promote a fun and stress-free restaurant culture.', // new translation key: welcome__tile--announcement__description
        'nitro_gamification_announcement_desc_bullet1': 'Quickly send Smack Talk to other stores on the fly with the new, streamlined Smack Talk messaging, accessible on a mobile phone web browser.', // a part of new translation key: welcome__tile--announcement__bullets
        'nitro_gamification_announcement_desc_bullet2': 'Create your own Smack Talk messages to reflect team culture, drive competition, and recognize a job well done.', // a part of new translation key: welcome__tile--announcement__bullets
        'nitro_gamification_announcement_desc_bullet3': 'Engage your crew with a brighter, more colorful, and easier-to-read Nitro Gamification Leaderboard that is automatically displayed in stores as of November 12th.', // a part of new translation key: welcome__tile--announcement__bullets
        'nitro_gamification_announcement_info_title': 'Enable the new Smack Talk permission', // new translation key: welcome__tile--announcement__info__title
        'nitro_gamification_announcement_info_desc': 'Only Account Owners have the “Customize Smack Talk” permission by default and must enable this permission for other Roles.', // new translation key: welcome__tile--announcement__info__description
        'info_1': 'Review the Smack Talk User Guides for', // a part of new translation key: welcome__tile--announcement__info__body
        'info_2': 'to learn more.', // a part of new translation key: welcome__tile--announcement__info__body
        'mobile_browsers': 'Mobile Browsers', // a part of new translation key: welcome__tile--announcement__info__body
        'desktop_browsers': 'Desktop Browsers', // a part of new translation key: welcome__tile--announcement__info__body
        'and': 'and' // a part of new translation key: welcome__tile--announcement__info__body
    },

    // French
    {},

    // Spanish
    {},

    // Spanish - Latin American
    {},

    // Português (brasil)
    {}
];

export default language;
