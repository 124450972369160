import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';
import { DownloadLink } from 'library/DownloadLink';

import './Header.scss';

export const Header = ({ scheduledUpgrade = {}, isUpgradesCsvLoading = false, onUpgradesCsvDownload }) => {
    const { t } = useTranslation();

    return (
        <div className="view-device-upgrade-header-row">
            <Title>
                {t('device-upgrades__title--view-new-upgrade', {
                    upgradeName: scheduledUpgrade.ScheduledUpgradeName,
                    interpolation: { escapeValue: false },
                })}
            </Title>

            <DownloadLink
                className="hme-stores-status--public__header__download-stores-btn"
                disabled={isUpgradesCsvLoading}
                onClick={onUpgradesCsvDownload}
            >
                {t('common__download--csv')}
            </DownloadLink>
        </div>
    );
};
