import React from 'react';

import { useEditUser } from './hooks/useEditUser';
import { ViewEdit } from '../Common';

export const DistributorEditUser = () => {
    const state = useEditUser();

    return (
        <ViewEdit {...state} />
    );
};
