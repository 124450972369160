export const BRAND_ACCOUNT_FILTER_OPTIONS = {
    Company_Type: {
        title: 'common__company-type',
        allText: 'common__all-company-types',
    },
    Brand_Name: {
        title: 'common__brand',
        allText: 'common__all-brands',
    },
    Device_LaneConfig_Name: {
        title: 'common__lane-config',
        allText: 'common__all-lane-configs',
    },
    Device_MainVersion: {
        title: 'common__device__version',
        allText: 'common__all-versions',
    },
};

export const NEXEO_BRAND_ACCOUNT_FILTER_OPTIONS = {
    Company_Type: {
        title: 'common__company-type',
        allText: 'common__all-company-types',
    },
    Brand_Name: {
        title: 'common__brand',
        allText: 'common__all-brands',
    },
    Device_LaneConfig_Name: {
        title: 'common__lane-config',
        allText: 'common__all-lane-configs',
    },
    Device_MainVersion: {
        title: 'common__device__version',
        allText: 'common__all-versions',
    },
    Device_SettingVersion: {
        title: 'common__settings__version',
        allText: 'common__all-versions',
    },
};
