import { useState, useCallback } from 'react';
import { reboot } from '../../../../services/Device';

export const useRebootLinkedDevice = (...actions) => {
    const [isLinkedDeviceRebooting, setIsLinkedDeviceRebooting] = useState(false);
    const [linkedDeviceRebootingError, setLinkedDeviceRebootingError] = useState(null);

    const rebootLinkedDevice = useCallback(async (linkedDuid, duid) => {
        if (!linkedDuid || !duid) return;

        try {
            setIsLinkedDeviceRebooting(true);
            setLinkedDeviceRebootingError(null);

            const result = await reboot(linkedDuid);
            await Promise.all(actions.map((action) => action(duid)));
            setIsLinkedDeviceRebooting(false);

            return result && result.data && result.data.data;
        } catch (e) {
            setLinkedDeviceRebootingError(e);
            setIsLinkedDeviceRebooting(false);
        }
    }, []);

    return { isLinkedDeviceRebooting, linkedDeviceRebootingError, rebootLinkedDevice };
};
