import React, { useMemo } from 'react';
import { compose } from 'ramda';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { MobileHeader } from 'library/Header';
import { isLoggedIn } from 'services/Auth';
import { PARTNER_ROUTES } from 'constants/routes';
import { usePartnerProviders } from 'apps/Partner/PartnerData/PartnerData';

import { withSubscriptionBasedExclusions } from '../../../HOCs/withSubscriptionBasedExclusions';
import { MainHeader, getActiveTab } from '../MainHeader';
import { PartnerTitle, HOME_TAB_NAME } from './PartnerTitle';
import { getMenuItems } from './menuItems';

import '../Header.css';
import './PartnerHeader.scss';

const filterTabs = (tabs) => {
    const newItems = [...tabs];

    return newItems.filter(({ hasAccess }) => hasAccess);
};

const ClientHeaderComponent = ({ userName, onLogout, filterBySubscription }) => {
    const location = useLocation();

    const { isLoading, isVaioPartner } = usePartnerProviders();

    const menuItems = [
        {
            name: HOME_TAB_NAME,
            tKey: 'main-header__item--welcome',
            icon: 'home',
            hasAccess: true
        },
        {
            name: PARTNER_ROUTES.stores,
            tKey: 'common__stores',
            icon: 'stores',
            hasAccess: true
        },
        {
            name: PARTNER_ROUTES.settingsFull,
            tKey: 'partner__provider-settings__title',
            icon: 'settings',
            hasAccess: isVaioPartner
        },
        {
            name: PARTNER_ROUTES.tokenManagement,
            tKey: 'admin__token-management__tab-title',
            icon: 'settings',
            hasAccess: true
        }
    ];

    const tabs = filterBySubscription({ items: filterTabs(menuItems), key: 'name' });
    const mobileItems = useMemo(() => filterBySubscription({ items: getMenuItems(), key: 'name' }), [location]);

    const { pathname } = location;

    const title = <PartnerTitle activeTab={getActiveTab(tabs)} />;

    const hasRelative = pathname.includes('new-reports');

    if (isLoading && isLoggedIn()) {
        return null;
    }

    return (
        <>
            <div className={classNames('hme-l-size main-header', { 'relative': hasRelative })}>
                <MainHeader menuItems={tabs} userName={userName} className='user-main-header' title={title} onLogout={onLogout}>
                </MainHeader>
            </div>
            <div className='hme-s-size hme-header--client'>
                <MobileHeader menuItems={mobileItems} userName={userName} onLogout={onLogout} />
            </div>
        </>
    );
};

export const PartnerHeader = compose(
    withSubscriptionBasedExclusions()
)(ClientHeaderComponent);
