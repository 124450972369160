import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { pluck } from 'ramda';

import { useAsync, ASYNC_STATUS } from 'hooks/useAsync';
import { storeHierarchyNodeTypes } from 'constants/storeHierarchy';
import { getGroups, removeGroup, editGroupName, createGroupByName, moveItems } from 'services/Groups';
import {
    addErrorNotification,
    addSuccessNotification
} from 'services/Notifications';

import { findDepth } from 'helpers/tree/findDepth';
import { deleteItems } from 'helpers/tree/deleteItems';
import { insertNodeFirst } from 'helpers/tree/insertNodeFirst';
import { findNodeByItem } from 'helpers/tree/findNodeByItem';
import { replaceNode } from 'helpers/tree/replaceNode';

import { HierarchyItem, AddItem } from '../../Common/HierarchyBlock/HierarchyItem';
import { downloadExportFile } from '../helpers/downloadExportFile';
import { uploadImportFile } from '../helpers/uploadImportFile';
import { getStoresTree } from '../../../Users/ViewEdit/Common/helpers/getStoresTree';
import { EXPORT_TYPES } from '../../Common/FileManageBlock/Export';
import { searchTree } from '../../Common/helpers/searchTree';

const defaultTree = [];
const MAXIMUM_DEPTH_TO_CREATE_GROUP = 3;

const errorTKeys = {
    'missing_column': 'manage-hierarchy__error--missing-column',
    'wrong_account': 'manage-hierarchy__error--wrong-account',
    'empty_line': 'manage-hierarchy__error--empty-line',
    'wrong_store': 'manage-hierarchy__error--wrong-store',
    'wrong_groupname': 'manage-hierarchy__error--wrong-group-name',
    'empty_data': 'manage-hierarchy__error--empty-data',
    'requiredAccountId': 'common__error--missed-account-id',
    'noDataForGivenId': 'common__error--no-data-for-id',
    'groupIdInvalid': 'common__error--group-id-invalid',
    'groupNameEmpty': 'common__error--group-name-empty',
    'groupUpdationFailed': 'add-group__error--group-update-failed',
    'groupAlreadyExist': 'add-group__error--group-exist',
    'noDataForGivenName': 'common__error--no-data-for-name'
};

const loadHierarchy = async () => {
    const groups = await getGroups(null, { filterOutNitroEssentialsStores: true });
    return getStoresTree(groups, HierarchyItem);
};

const getIds = pluck('Id');

export const usePublic = () => {
    const { t } = useTranslation();
    const [selectedItems, setSelectedItems] = useState([]);
    const [isExportDisabled, setIsExportDisabled] = useState(false);
    const [isAddDisabled, setIsAddDisabled] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [tree, setTree] = useState([]);
    const [editItem, setEditItem] = useState(null);
    const [displayedHierarchy, setDisplayedHierarchy] = useState([]);
    const isOnlyOneGroupSelected = useMemo(() => {
        if (selectedItems.length !== 1) {
            return false;
        }

        return selectedItems[0].Type === storeHierarchyNodeTypes.GROUP;
    }, [selectedItems]);
    const emptyTreeMessage = useMemo(() => searchString.length !== 0 ?
        'common__no-results':
        'common__no-stores--available', [searchString]);

    const {
        isLoading,
        data: hierarchy,
        status: savingStatus,
        error: loadError,
        run
    } = useAsync({ data: [] });

    const {
        isLoading: isRemoving,
        run: remove
    } = useAsync({ status: ASYNC_STATUS.RESOLVED });

    const {
        isLoading: isSaving,
        run: save
    } = useAsync({ status: ASYNC_STATUS.RESOLVED });

    const {
        isLoading: isImporting,
        run: importFile
    } = useAsync({ status: ASYNC_STATUS.RESOLVED });

    const [exportFormat, setExportFormat] = useState(EXPORT_TYPES.CSV);
    const [importedFile, setImportedFile] = useState(null);

    const onItemSelect = useCallback((item) => {
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    }, [selectedItems]);

    useEffect(() => {
        if (selectedItems.length !== 1) {
            setIsAddDisabled(false);

            return;
        }

        const depth = findDepth(hierarchy, selectedItems[0]);
        setIsAddDisabled(depth > MAXIMUM_DEPTH_TO_CREATE_GROUP);
    }, [selectedItems]);

    const onExport = useCallback(() => {
        downloadExportFile(exportFormat, setIsExportDisabled);
    }, [exportFormat]);

    const onAddGroup = useCallback(async (name) => {
        const payload = {
            name,
            parentId: selectedItems.length ? selectedItems[0].Id : null
        };

        try {
            await save(createGroupByName(payload));
            await run(loadHierarchy());
            addSuccessNotification(t('manage-hierarchy__success--add'));
        } catch (error) {
            addErrorNotification(error.message);
            setTree(hierarchy);
        }

        setEditItem(null);
        setSelectedItems([]);
    }, [selectedItems, hierarchy]);

    const onEditGroup = useCallback(async (newName) => {
        try {
            const payload = {
                name: newName
            };

            await save(editGroupName(selectedItems[0].Id, payload));
            await run(loadHierarchy());
            addSuccessNotification(t('manage-hierarchy__success--edit'));
        } catch (error) {
            addErrorNotification(error.message);
            setTree(hierarchy);
        }

        setEditItem(null);
        setSelectedItems([]);
    }, [selectedItems, hierarchy]);

    const onImport = useCallback(() => {
        importFile((async () => {
            try {
                await uploadImportFile(importedFile);
                setImportedFile(null);
            } catch (err) {
                if (!err.response) {
                    addErrorNotification('common__error--internal-server');

                    return;
                }

                const { data } = err.response;
                const errorTKey = errorTKeys[data.message];
                const commonErrorTKey = 'manage-hierarchy__error--import-common';

                addErrorNotification(`${t(commonErrorTKey)}. ${t(errorTKey, { details: data.detail })}`);

                return;
            }

            await run(loadHierarchy());
            addSuccessNotification('manage-hierarchy__import-success');
        })());
    }, [importedFile, uploadImportFile, t]);

    const onRemove = useCallback(async (removingGroup) => {
        if (removingGroup.Type !== storeHierarchyNodeTypes.GROUP) {
            return;
        }

        try {
            await remove(removeGroup(removingGroup.Id));
            setSelectedItems([]);
        } catch (err) {
            if (!err.response) {
                addErrorNotification('common__error--internal-server');

                return;
            }

            const data = err.response;
            const errorTKey = errorTKeys[data.key];

            addErrorNotification(errorTKey);

            return;
        }

        await run(loadHierarchy());
        addSuccessNotification('stores-hierarchy__remove__success');
    }, []);

    const onAddStart = useCallback(() => {
        const newEditItem = {
            isEdit: true,
            editNode: null
        };

        setEditItem(newEditItem);

        const node = selectedItems.length ? findNodeByItem(tree, selectedItems[0]) : null;

        if (node) {
            node.onExpand.trigger();
        }

        setTree(insertNodeFirst(tree, node, {
            text: <AddItem defaultName="" />,
            key: 'add-group-item',
            item: newEditItem
        }));
    }, [tree, selectedItems]);

    const onEditStart = useCallback(() => {
        const node = findNodeByItem(tree, selectedItems[0]);

        const newEditItem = {
            isEdit: true,
            editNode: node
        };

        setTree(replaceNode(tree, selectedItems[0], {
            text: <AddItem defaultName={selectedItems[0].Name} />,
            item: newEditItem,
            key: node.key,
            children: node.children
        }));

        setEditItem(newEditItem);
    }, [tree, selectedItems]);

    const onAddCancel = useCallback(() => {
        setTree(deleteItems(tree, [editItem]));
        setEditItem(null);
    }, [tree, editItem]);

    const onEditCancel = useCallback(() => {
        setTree(replaceNode(tree, editItem, editItem.editNode));
        setEditItem(null);
    }, [tree, editItem]);

    const onMove = useCallback(async (parent, items) => {
        const stores = getIds(items.filter(({ Type }) => Type === storeHierarchyNodeTypes.STORE));
        const groups = getIds(items.filter(({ Type }) => Type === storeHierarchyNodeTypes.GROUP));

        const parentId = parent ? parent.Id : null;

        try {
            await save(moveItems(parentId, stores, groups));
            await run(loadHierarchy());
            addSuccessNotification(t('stores-hierarchy__move__success'));
        } catch (err) {
            addErrorNotification(err.message);
            setTree(hierarchy);
        }

        setSelectedItems([]);
    }, [t, hierarchy]);

    useEffect(() => {
        run(loadHierarchy());
    }, []);

    useEffect(() => {
        setTree(hierarchy);
    }, [hierarchy]);

    useEffect(() => {
        if (loadError) {
            addErrorNotification('common__error--internal-server');
        }
    }, [loadError]);

    useEffect(() => {
        setDisplayedHierarchy(searchTree(tree || defaultTree, searchString));
    }, [tree, searchString]);

    const isInLoading = isLoading || isImporting || isRemoving || isSaving;

    return {
        isLoading: isInLoading,
        isEditDisabled: !isOnlyOneGroupSelected || Boolean(editItem),
        isRemoveDisabled: !isOnlyOneGroupSelected || Boolean(editItem),
        isMoveDisabled: !selectedItems.length,
        tree: displayedHierarchy || defaultTree,
        selectedItems,
        searchString,
        onItemSelect: onItemSelect,
        exportFormat,
        isExportDisabled,
        emptyTreeMessage,
        importedFile,
        onExportFormatChange: setExportFormat,
        onExport,
        onImportChange: setImportedFile,
        onRemove,
        onImport,
        isAddDisabled: isAddDisabled || (!isOnlyOneGroupSelected && selectedItems.length !== 0) || Boolean(editItem),
        onSearchChange: setSearchString,
        onAddStart,
        onAddCancel,
        onEditCancel,
        onAddGroup,
        onEditGroup,
        onEditStart,
        onMove
    };
};
