import { compose, cond, T } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import { isPartner } from 'services/Auth';

import { Client } from './Client';
import { Partner } from './Partner';

import 'components/Header/Header.css';
import './help.scss';

const HelpCond = cond([
    [() => isPartner(), () => (<Partner />)],
    [T, () => <Client />]
]);

export const Help = compose(
    withHMELayout()
)(HelpCond);
