import {
    cond,
    equals,
    uniq,
    uniqBy,
    pluck,
    prop,
    flatten,
    pipe,
    map,
    sortBy,
    sortWith,
    compose,
    toLower,
    type
} from 'ramda';
import compareVersions from 'helpers/sortComparators/version';
import { getRowValues } from './getRowValues';

export const toLowerIfString = cond([[compose(equals('String'), type), toLower]]);

const filterByWords = (rows, words, deviceSearchType) => {
    const values = getRowValues(rows, deviceSearchType);

    return values.filter(({ value }) => words.every(word => value.includes(word)))
        .map(({ index }) => rows[index]);
}

const filterByGrid = (rows, gridFilters) => {
    return rows.filter(({ sourceDevice = {} }) => gridFilters
        .sourceBrandName
        .includes(sourceDevice.Brand_ID)
    )
    .filter(({ sourceVersion }) => gridFilters.sourceVersion.includes(sourceVersion))
    .filter(({ settingsGroups = [] }) => settingsGroups.some(
        settings => gridFilters.settingsGroupsText.includes(settings)
    ))
    .filter(({ taskStatusValue }) => gridFilters.taskStatusCell.includes(taskStatusValue))
    .filter(({ appliedBy }) => gridFilters.appliedBy.includes(appliedBy))
    .filter(({ deviceTypeName }) => gridFilters.deviceTypeName.includes(deviceTypeName));
}

export const filterRows = (rows, words, gridFilters, deviceSearchType) => {
    const filteredRowsByGrid = filterByGrid(rows, gridFilters);

    const filteredByWords = filterByWords(filteredRowsByGrid, words, deviceSearchType);

    return filteredByWords;
};

export const getBrandData = ({ sourceDevice: { Brand_ID: value, Brand_Name: text } }) => ({
    text,
    value,
});
export const getAllBrands = pipe(
    map(getBrandData),
    uniqBy(prop('value')),
    sortBy(compose(toLowerIfString, prop('text')))
);
export const getAppliedBy = pipe(
    pluck('appliedBy'),
    uniq,
    sortBy(toLowerIfString),
);
export const getVersions = pipe(
    pluck('sourceVersion'),
    uniq,
    sortWith([compareVersions]),
);
export const getRowSettingValues = pipe(pluck('settingsGroups'), flatten, uniq);
export const getRowStatusValues = pipe(pluck('taskStatusValue'), uniq);
export const getRowDeviceTypes = pipe(pluck('deviceTypeName'), uniq);