import React from 'react';

import { DateLib } from '@hme-cloud/utility-common';
import { Constant } from '@hme-cloud/utility-common';

import { Actions } from '../ProvidersList';

const { VaioDefaults } = Constant;

const dateFormatForKeyExpiration = DateLib.FORMAT_TYPES.DATE_ONLY;
const dateFormatForUpdate = DateLib.FORMAT_TYPES.FULL_YEAR_DATE_AND_TIME;

const formatDateTime = (date) => date && new DateLib(new Date(date)).format(dateFormatForUpdate);

export const prepareRow = (provider) => {
    const keyExpDate = provider.keyExpiration ? new DateLib(provider.keyExpiration).startOfDay() : null;

    return {
        ...provider,
        keyExpirationDate: keyExpDate?.format(dateFormatForKeyExpiration),
        createdDate: formatDateTime(provider.createdDate),
        updatedDate: formatDateTime(provider.updatedDate),
        originalUpdatedDate: provider.updatedDate,
        Provider_Actions: <Actions uid={provider.uid} />,
        botAudioSourceType: VaioDefaults.botProcessingType[provider.botAudioSourceType]
    };
};
