import { useTranslation } from 'react-i18next';

import { NotificationsList } from 'library/NotificationsList';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';
import { LoadingModal } from 'library/LoadingModal';
import { Paginate } from 'library/Paginate';
import { PAGE_DATA } from 'constants/paginationDefault';
import { Header } from './Header';
import { List } from './List';
import { useStatus } from './hooks/useStatus';

import './Status.scss';

export const Status = ({ notifications, isLoading, isLoadingModalShown, onEnable, onDisable, onRemove }) => {
    const { t } = useTranslation();

    const {
        rows,
        isEnablePopupShown,
        onEnableConfirm,
        onEnableCancel,
        isRemoveConfirmationShown,
        onRemoveCancel,
        onRemoveConfirm,
        pageNumber,
        itemsPerPage,
        totalRows,
        onPaginationChange,
        paginatedRows
    } = useStatus({
        notifications,
        onEnable,
        onDisable,
        onRemove,
    });

    return (
        <div className='hme-customer-notification-status'>
            <NotificationsList />
            <Header />
            <List rows={paginatedRows} isLoading={isLoading} />
            <Paginate
                className="hme-customer-notification-paginate"
                page={pageNumber}
                recordsPerPage={itemsPerPage}
                pageSizes={PAGE_DATA.PAGE_SIZES_ADMIN}
                total={totalRows}
                onChange={onPaginationChange}
                hideSinglePage
            />
            <LoadingModal show={isLoadingModalShown}/>
            <ConfirmPopupComponent
                show={isEnablePopupShown}
                message={t('admin__customer-notification__list__confirm--enable')}
                onConfirm={onEnableConfirm}
                onHide={onEnableCancel}
                cancelVerb={t('common__popup--cancel-action')}
                confirmationVerb={t('common__popup--confirm-action')}
            />
            <ConfirmPopupComponent
                show={isRemoveConfirmationShown}
                message={t('admin__customer-notification__list__confirm--remove')}
                onConfirm={onRemoveConfirm}
                onHide={onRemoveCancel}
                cancelVerb={t('common__popup--cancel-action')}
                confirmationVerb={t('common__popup--confirm-action')}
            />
        </div>
    );
};
