import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Title } from 'library/Title';
import { Address } from 'library/Address';
import { InputComponent } from 'components/Inputs';
import { formatRegistrationKey } from 'helpers/formatRegistrationKey';

import { STORE_DETAILS_TYPE } from '../../types/storeDetailsType';
import { useGeneralInformationBlock } from './hooks/useGeneralInformationBlock';
import { AccountLink } from './AccountLink';
import { AccountNumbers } from '../AccountNumbers';
import { StoreClassification } from '../StoreClassification';

export const DEFAULT_FIELD_VARIANTS = ['underlined'];

export const GeneralInformationBlock = ({
    storeDetails,
    storeName,
    setStoreName,
    showAccountEmail = false,
    showAxNumber = false,
    showClassifications = false,
    AXCustomerNumber,
    setAXCustomerNumber,
    classificationId = 1,
    setClassificationId,
    AXValidationErrors
}) => {
    const { t } = useTranslation();

    const {
        Store_Name: defaultStoreName = '',
        Brand_Name: defaultBrandName = '',
        Store_Number: defaultStoreNumber = '',
        GroupName: defaultReportGroup = '',
        Store_PhoneNumber: defaultStorePhone = '',
        Store_FaxNumber: defaultStoreFax = '',
        Store_AddressLine1: defaultLine1 = '',
        Store_AddressLine2: defaultLine2 = '',
        Store_AddressLine3: defaultLine3 = '',
        Store_AddressLine4: defaultLine4 = '',
        Store_Locality: defaultCity = '',
        Store_PostCode: defaultZipCode = '',
        Store_Region: defaultRegion = '',
        Store_RegistrationKey: defaultRegistrationKey = '',
        Country_Name: defaultCountry = '',
        Group_Name: defaultLeaderboardGroup = '',
    } = storeDetails;

    const {
        storeNameValue,
        isStoreNameDisabled,
        isStoreNameReadOnly,
        storeNameFieldVariants
    } = useGeneralInformationBlock(storeName, defaultStoreName);

    return <>
        <Title variants={['block']}>{t('common__general')}</Title>
        <InputComponent
            value={defaultBrandName || ''}
            label="common__brand"
            isReadOnly
            variants={DEFAULT_FIELD_VARIANTS}
        />
        <InputComponent
            value={storeNameValue || ''}
            label="common__store__name"
            isDisabled={isStoreNameDisabled}
            isReadOnly={isStoreNameReadOnly}
            variants={storeNameFieldVariants}
            onChange={setStoreName}
            maxLength={25}
        />
        <InputComponent
            value={defaultStoreNumber || ''}
            label="common__store__number--text"
            isReadOnly
            variants={DEFAULT_FIELD_VARIANTS}
        />
        <InputComponent
            value={defaultLeaderboardGroup || ''}
            label="common__leaderboard-group"
            isReadOnly
            variants={DEFAULT_FIELD_VARIANTS}
        />
        <InputComponent
            value={defaultReportGroup || ''}
            label="stores__list-header__report-group"
            isReadOnly
            variants={DEFAULT_FIELD_VARIANTS}
        />
        <InputComponent
            value={defaultStorePhone || ''}
            label="common__phone"
            isReadOnly
            variants={DEFAULT_FIELD_VARIANTS}
        />
        <InputComponent
            value={defaultStoreFax || ''}
            label="common__fax"
            isReadOnly
            variants={DEFAULT_FIELD_VARIANTS}
        />
        <Address
            value={{
                line1: defaultLine1,
                line2: defaultLine2,
                line3: defaultLine3,
                line4: defaultLine4,
                city: defaultCity,
                zipCode: defaultZipCode,
                region: defaultRegion,
                country: defaultCountry
            }}
            isReadOnly={true}
        />
        {
            showAccountEmail && <AccountLink store={storeDetails} />
        }
        {
            showAxNumber && <AccountNumbers
                AXCustomerNumber={AXCustomerNumber}
                setAXCustomerNumber={setAXCustomerNumber}
                validationErrors={AXValidationErrors}
            />
        }
        {
            showAxNumber && <InputComponent
                value={formatRegistrationKey(defaultRegistrationKey) || '-'}
                label="common__registration-key"
                isReadOnly
                variants={DEFAULT_FIELD_VARIANTS}
            />
        }
        {
            showClassifications && <StoreClassification
                value={classificationId}
                onChange={setClassificationId}
            />
        }
    </>;
};

GeneralInformationBlock.propTypes = {
    storeDetails: STORE_DETAILS_TYPE.isRequired,
    storeName: PropTypes.string,
    setStoreName: PropTypes.func
};
