import React from 'react';
import { Nav } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import classnames from 'classnames';

export const ProfileMenuItem = ({ userName, url, classes, children }) => {
    return (
        <div className={classnames('user-info-section', 'navbar-text', classes)}>
            <p className='profile-username'>
                <Trans shouldUnescape={true} i18nKey='account-dropdown__title'>
                    <span>Logged in as </span>
                    <Nav.Link href={url}>{{userName}}</Nav.Link>
                </Trans>
            </p>
            {children}
        </div>
    );
};
