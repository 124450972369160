import { prop, sortBy, compose } from 'ramda';
import { taskConfig } from 'constants/applyDeviceSettingsTask';
import { toLowerIfString } from '../../../DeviceSettings/helpers';
import { settingsGroupValues } from './settingsGroupValues';

const { statuses: deviceJobStatuses } = taskConfig;

const ALL_SETTINGS_FILTER_TEXT = 'All';
const ALL_STATUSES_FILTER_TEXT = 'All statuses';
const ALL_DEVICE_TYPES_FILTER_TEXT = 'All device types';

export const availableSettingsFilters = {
    allText: ALL_SETTINGS_FILTER_TEXT,
    property: 'settingsGroupsText',
    items: sortBy(compose(toLowerIfString, prop('text')), [
        {
            text: 'By Snapshots',
            value: null,
        },
        ...Object.keys(settingsGroupValues).map((key) => ({
            value: key,
            text: settingsGroupValues[key],
        })),
    ]),
};

export const availableStatusFilters = {
    property: 'taskStatusCell',
    allText: ALL_STATUSES_FILTER_TEXT,
    items: [
        {
            text: 'In Progress',
            value: deviceJobStatuses.IN_PROGRESS,
        },
        {
            text: 'Completed',
            value: deviceJobStatuses.COMPLETED,
        },
        {
            text: <span className="tasks-table-admin-status-filter-failed">Failed</span>,
            value: deviceJobStatuses.FAILED,
        },
        {
            text: 'Download In Progress',
            value: deviceJobStatuses.DOWNLOAD_IN_PROGRESS,
        },
        {
            text: 'Download Completed',
            value: deviceJobStatuses.DOWNLOAD_COMPLETED,
        },
        {
            text: 'Download Failed',
            value: deviceJobStatuses.DOWNLOAD_FAILED,
        },
        {
            text: 'Snapshot Overwriten',
            value: deviceJobStatuses.SNAPSHOT_OVERWRITEN,
        },
        {
            text: 'Apply In Progress',
            value: deviceJobStatuses.APPLY_IN_PROGRESS,
        },
        {
            text: 'Apply Failed',
            value: deviceJobStatuses.APPLY_FAILED,
        },
        {
            text: 'Unknown Status',
            value: -1,
        },
    ],
};

export const availableDeviceTypesFilters = {
    property: 'deviceTypeName',
    allText: ALL_DEVICE_TYPES_FILTER_TEXT,
    items: [
        {
            text: 'ZOOM',
            value: 'ZOOM',
        },
        {
            text: 'ZOOM Nitro',
            value: 'ZOOM Nitro',
        },
        {
            text: 'NEXEO',
            value: 'NEXEO',
        },
    ],
};
