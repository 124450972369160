import React, { useState, useEffect } from 'react';

import { Modal } from 'library/Modal';
import { FileInput } from 'library/FileInput';

import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';

import './UploadModal.scss';

const Title = () => (
    <>
        <UploadIcon className='hme-upload-modal-icon' />
        <span>Upload File</span>
    </>
)

export const UploadModal = ({ multiple = false, extensions, show, onHide, onUpload, ...modalProps }) => {
    const [value, setValue] = useState(multiple ? [] : null);
    const [actions, setActions] = useState([]);

    useEffect(() => {
        if (!show) {
            setValue(multiple ? [] : null);
        }
    }, [show]);

    useEffect(() => {
        setActions([{
            children: 'Cancel',
            onClick: onHide,
        }, {
            children: multiple ? 'Upload files' : 'Upload file',
            variants: ['submit'],
            onClick: () => onUpload(value),
            disabled: multiple ? value.length === 0 : !value,
        }]);
    }, [value, multiple, onHide, onUpload, setActions]);

    return (
        <Modal
            {...modalProps}
            show={show}
            dialogClassName='hme-upload-component'
            onHide={onHide}
            title={<Title />}
            actions={actions}
        >
            <FileInput value={value} multiple={multiple} extensions={extensions} onChange={setValue} />
        </Modal>
    );
};
