const REGISTRATION_KEY_PROP = 'registrationKey';

const getValuesForSearch = (row, props) =>
    props.map((prop) => {
        // special case for registration key that can contain '-' and should be treated as a single word
        if (prop === REGISTRATION_KEY_PROP && typeof row[prop] === 'string') {
            return row[prop].replaceAll('-', '').toLowerCase()
        }

        return typeof row[prop] === 'string'? row[prop].toLowerCase() : row[prop]
    });

export const applySearchValue = (rows, props, searchValue, options = {}) => {
    const { minLength = 3 } = options;

    if (searchValue.length < minLength) {
        return rows;
    }

    const words = searchValue
        .split(' ')
        .filter((value) => value)
        .map(value => value.toLowerCase());

    return rows.filter((row) => {
        const values = getValuesForSearch(row, props).join(' ');

        // special case for registration key
        if (props.includes(REGISTRATION_KEY_PROP)) {
            return words.every((word) => values.includes(word) || values.includes(word.replaceAll('-', '').toLowerCase()));
        }

        return words.every((word) => values.includes(word));
    });
};
