import { useCallback, useReducer } from 'react';

import { useSafeDispatch } from 'hooks/useSafeDispatch';

import { displaySettingsDataAdaptor } from '../adaptors';
import { DASHBOARD_ORDER_INTIAL, PREFERENCE_DASH_INTIAL } from '../constants';

export const displaySettingsReducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_DEFAULT_VIEW':
            return {
                ...state,
                preferredDefaultView: action.payload
            };
        case 'CHANGE_METRIC':
            return {
                ...state,
                preferredMetric: action.payload
            };
        case 'CHANGE_TIME_FORMAT':
            return {
                ...state,
                timeFormat: action.payload
            };
        case 'CHANGE_PULLINS':
            return {
                ...state,
                preferredPullins: action.payload
            };
        case 'CHANGE_STORE_COLUMN_NAME':
            return {
                ...state,
                preferredStoreColumnName: action.payload
            };
        case 'CHANGE_PRIME_COLOR':
            return {
                ...state,
                preferredPrimeColors: {
                    ...state.preferredPrimeColors,
                    [action.payload.colorType]: action.payload.color
                }
            };
        case 'CHANGE_STARTDAY':
            return {
                ...state,
                preferredStartDay: action.payload
            };
        case 'RESET_TO_DEFAULT':
            return displaySettingsDataAdaptor({
                DashboardOrder: DASHBOARD_ORDER_INTIAL,
                PreferenceDash: JSON.stringify(PREFERENCE_DASH_INTIAL),
                UserID: state.userId
            });
        default:
            return state;
    }
};

export const useSettingsData = (displaySettingsData) => {
    const [state, unsafeDispatch] = useReducer(displaySettingsReducer, displaySettingsData);

    const dispatch = useSafeDispatch(unsafeDispatch);

    const handleMetricChange = useCallback(
            (payload) => {
                dispatch({ type: 'CHANGE_METRIC', payload });
            },
            [dispatch]
    );

    const handleDefaultViewChange = useCallback(
            (payload) => {
                dispatch({ type: 'CHANGE_DEFAULT_VIEW', payload });
            },
            [dispatch]
    );

    const handleColorChange = useCallback(
            (colorType) => (color) => {
                dispatch({ type: 'CHANGE_PRIME_COLOR', payload: { colorType, color } });
            },
            [dispatch]
    );

    const handlePullinsChange = useCallback(
            (payload) => {
                dispatch({ type: 'CHANGE_PULLINS', payload });
            },
            [dispatch]
    );

    const handleStoreColumnNameChange = useCallback(
            (payload) => {
                dispatch({ type: 'CHANGE_STORE_COLUMN_NAME', payload });
            },
            [dispatch]
    );

    const handleTimeFormatChange = useCallback(
            (payload) => {
                dispatch({ type: 'CHANGE_TIME_FORMAT', payload });
            },
            [dispatch]
    );

    const handleStartDayChange = useCallback(
            (payload) => {
                dispatch({ type: 'CHANGE_STARTDAY', payload });
            },
            [dispatch]
    );

    const resetToDefault = useCallback(
            () => {
                dispatch({ type: 'RESET_TO_DEFAULT' });
            },
            [dispatch]
    );

    return {
        state,
        handleMetricChange,
        handleDefaultViewChange,
        handleColorChange,
        handlePullinsChange,
        handleStoreColumnNameChange,
        handleTimeFormatChange,
        handleStartDayChange,
        resetToDefault
    };
};
