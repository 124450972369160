/**
 * Filter not allowed characters in a string
 *
 * @param {String} str
 * @param {RegExp} regExp
 * @param {Boolean} condition
 * @returns {String}
 */
const regExpStringFilter = (str, regExp, condition = true) => {
    const proceed = condition && regExp && typeof str === 'string';
    if (!proceed) {
        return str
    };
    return str.replace(regExp, '');
}
module.exports = regExpStringFilter;