import api from './api';

const base = 'device/settings';

export const lockDeviceSettingsApi = {
  lockDeviceSettings(deviceIds) {
    return api.request({
      url: `${base}/lock`,
      method: 'POST',
      data: {
          deviceIds
      }
    });
  },
  unlockDeviceSettings(deviceIds) {
    return api.request({
      url: `${base}/unlock`,
      method: 'POST',
      data: {
          deviceIds
      }
    });
  },
};
