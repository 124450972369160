export const HISTORY_MOBILE_SORT_OPTIONS = [
    {
        label: 'text-n-connect__history__sorting__datetime__asc',
        value: { createdAt: 1 },
    },
    {
        label: 'text-n-connect__history__sorting__datetime__desc',
        value: { createdAt: -1 },
    },
];
