export const MOBILE_ACCOUNTS_HEADERS = [
    { column: [
        { text: 'common__account__email', property: 'User_EmailAddress' },
        { text: 'common__company__name', property: 'Company_Name' },
        { text: 'account__section__form--company-type', property: 'Company_Type' }
    ] },
    { column: [
        { text: 'common__country', property: 'Country_Name' },
        { text: 'account__section__form--subscription-type', property: 'Subscription_Name' },
        { text: 'account__section__form--account-status', property: 'isActive' }
    ] }

];
