import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from 'library/Title';

import './Header.scss';

export const Header = () => {
    const { t } = useTranslation();
    return (
        <div className="company-info-header">
            <div className="company-info-header-row">
                <Title>{t('my-account__company-info')}</Title>
            </div>
        </div>
    );
};
