export const trimFields = [
    'name',
    'address1',
    'address2',
    'region',
    'locality',
    'postCode',
    'phoneNumber',
    'mobileNumber',
    'firstName',
    'lastName',
    'email'
];
