import React from 'react';

import { DownloadLink } from 'library/DownloadLink';
import { GridFiltersCount } from 'library/GridFiltersCount';
import { SearchInput } from 'components/Inputs';

import './Header.scss';

export const Header = ({
    count,
    showDownloadLink,
    searchValue,
    onSearchChange,
    onDownloadLinkClick,
    filtersCount,
    onFiltersReset,
}) => (
    <div className='hme-view-store-settings-task-downloaded-devices-header'>
        <div className='hme-view-store-settings-task-downloaded-devices-header-count'>
            <span className='hme-view-store-settings-task-downloaded-devices-header-count-value'>
                {count}
            </span>
            {count === 1 ? ' device' : ' devices'} downloaded
        </div>
        {
            showDownloadLink ?
            <div className='hme-view-store-settings-task-downloaded-devices-header-download-link'>
                <DownloadLink onClick={onDownloadLinkClick}>Download Again</DownloadLink>
            </div> :
            null
        }
        <div className='hme-view-store-settings-task-downloaded-devices-reset-filters'>
            <GridFiltersCount count={filtersCount} onFiltersReset={onFiltersReset} variants={['single-line']} />
        </div>
        <div className='hme-view-store-settings-task-downloaded-devices-header-search'>
            <SearchInput
                value={searchValue}
                placeholder='Search for Device'
                onChange={onSearchChange}
            />
        </div>
    </div>
);
