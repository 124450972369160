import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'ramda';

import NavigationServices from 'components/Common/NavigationServices';
import { checkBaseAccessForAccounts } from 'helpers/distributor';
import { hasGamificationFeature } from 'actions/permissions';
import { titleCase } from 'helpers/strings';
import { withSubscriptionBasedExclusions } from 'HOCs/withSubscriptionBasedExclusions';
import { usePartnerProviders } from 'apps/Partner/PartnerData/PartnerData';
import { isDistributor, isAdmin as isUserAdmin, isPartner } from 'services/Auth';
import { quickLinks } from 'components/Header/PartnerHeader/menuItems';
import { PARTNER_ROUTES } from 'constants/routes';
import { checkNitroEssentials } from 'helpers';

import './QuickLinks.css';

const QuickLinksTileComponent = ({ links }) => {
    const { t } = useTranslation();

    return (
        <div className="tile-box quick-links-box">
            <div className="tile__box-supporting-container">
                <h3 className="tile__title">{t('welcome__tile--quick-links__title')}</h3>
                {links.map((link, index) => {
                    const className = `quicklink ${link.additionalClass || ''}`;
                    return (
                        <a key={index} className={className} href={link.url}>
                            {titleCase(t(link.linkText))}
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

export const PartnerQuickLinksTile = () => {
    const { isVaioPartner } = usePartnerProviders();

    const links = quickLinks.filter(({ name }) => name !== PARTNER_ROUTES.settingsFull || isVaioPartner);

    return <QuickLinksTileComponent links={links} />;
};

const DefaultQuickLinksTileComponent = ({ filterBySubscription }) => {
    const [links, setLinks] = useState([]);

    useEffect(() => {
        const isAdmin = isUserAdmin();

        if (isPartner()) {
            return;
        }

        const navigation = new NavigationServices();

        // TODO: links should be returned from an endpoint
        const mockLinks = [
            {
                linkText: 'common__stores',
                name: 'stores'
            },
            {
                linkText: 'common__users',
                name: 'users'
            }
        ];

        if (isDistributor() && checkBaseAccessForAccounts()) {
            mockLinks.push({
                linkText: 'common__accounts',
                name: 'distributorAccounts'
            })
        }
        // Add to the beginning of the quicklink list
        if (!isAdmin) {
            mockLinks.unshift({
                linkText: 'common__leaderboards',
                name: 'leaderBoards',
            });
        }
        if (hasGamificationFeature('smacktalk') && !isAdmin) {
            mockLinks.unshift({
                linkText: 'common__smack-talk',
                name: 'smackTalk',
                additionalClass: 'quicklinks__smacktalk_icon',
            });
        }
        // Add at the end of the quicklink list
        if (!isAdmin) {
            mockLinks.push({
                linkText: 'common__my-account',
                name: 'accounts',
            });
            if (!checkNitroEssentials()) {
                mockLinks.push({
                    linkText: 'sub-header--settings__display',
                    name: 'display',
                });
            }
        }
        const preparedLinks = filterBySubscription({ items: mockLinks, exclusion: 'pages', key: 'name' })
            .map(({ linkText, name, additionalClass }) => ({
                linkText,
                url: navigation.getUrl(name),
                additionalClass
            }));

        setLinks(preparedLinks);
    }, []);

    return <QuickLinksTileComponent links={links} />;
};

export const DefaultQuickLinksTile = compose(withSubscriptionBasedExclusions())(DefaultQuickLinksTileComponent);
