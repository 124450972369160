import React from 'react';
import PropTypes from 'prop-types';
import { Title } from 'library/Title';

import { CommonConstants } from 'Constants';

import './Header.scss';

export const Header = ({ device = {} }) => {
    const { Store_Name: storeName, Store_Number: storeNumber } = device;

    return (
        <div className="hme-admin-system-status-linkewear-header">
            <Title className="hme-admin-system-status-linkewear-header-title">
                {CommonConstants.deviceType.linkWear.name}
                {storeName ? ` - ${storeName}` : ''}
                {storeNumber ? ` - ${storeNumber}` : ''}
            </Title>

        </div>
    );
};

// Props validation
Header.propTypes = {
    device: PropTypes.object.isRequired
};
