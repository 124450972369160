/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'ramda';
import { withHMELayout } from 'HOCs/withHMELayout';
import './ProcessTemplates.scss';
import { LeaderBoardTemplates, LeaderBoardTemplatePreview } from '../LeaderBoardTemplates';
import { useParams, useSearchParams } from 'react-router-dom';
import { getLBSettingTemplate, setLBSettingTemplate } from 'services/LBTemplates';
import { getLBGroupNames } from 'services/LBGroupNames';
import { withPageState } from 'HOCs/withPageState';
import { Config } from '../../../Config';
import AuthenticationService from '../../Security/AuthenticationService';
import { Modal } from 'library/Modal';
import { fixTimeFormat, addPoundSign, removePoundSign } from '../helpers/formatting';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { NotificationMessage } from 'library/NotificationMessage';
import { getLanguages } from 'services/LBTemplates';
import { useNavigate } from 'react-router-dom';

const defaultCurrentTemplate = {
    ColumnOneAggregateTypeOne: null,
    ColumnOneAggregateTypeTwo: null,
    ColumnOneAggregateTypeThree: null,
    ColumnTwoAggregateTypeOne: null,
    ColumnTwoAggregateTypeTwo: null,
    ColumnTwoAggregateTypeThree: null,
    CompanyUID: null,
    CreatedAt: null,
    CreatedBy: null,
    DisplayColumnOneType: null,
    DisplayColumnTwoType: null,
    GoalAColor: '#00b04c',
    GoalBColor: '#dcba00',
    GoalCColor: '#b40000',
    GoalType: 'Master',
    HideGoal: false,
    IncludePullIns: false,
    IsDefaultLBTemplate: null,
    LBSettingTemplateID: null,
    LBSettingTemplateUID: null,
    LanguageID: 1,
    LastUpdateAt: null,
    LastUpdatedBy: null,
    Metric: 1,
    SortColumn: null,
    StoreLabel: 'StoreNumber',
    TemplateDescription: null,
    TemplateName: null,
    TimeFormat: 'minutes:seconds'
};

const P_K = 'LBSettingTemplateID';

const createSections = () => ({
    header: {
        title: 'leaderboard__template__create-template'
    },
    sections: [
        {
            props: {
                number: 1,
                title: 'leaderboard__template__give-name-to-template',
                disabled: false
            }
        },
        {
            props: {
                number: 2,
                title: 'leaderboard__template__add-ranking-column',
                disabled: true
            }
        },
        {
            props: {
                number: 3,
                title: 'leaderboard__template__configure-lb-settings',
                disabled: true
            }
        }
    ],
    footer: {
    }
});

const editSections = () =>({
    header: {
        title: 'leaderboard__template__edit-template'
    },
    sections: [
        {
            props: {
                title: 'common__general'
            }
        },
        {
            props: {
                title: 'common__sections'
            }
        },
        {
            props: {
                title: 'common__settings'
            }
        }
    ],
    footer: {
    }
});


const editSectionsFor3x = () =>({
    header: {
        title: 'common__customize-lb'
    },
    sections: [
        {
            props: {
                title: 'common__general'
            }
        },
        {
            props: {
                title: 'common__sections'
            }
        },
        {
            props: {
                title: 'common__settings'
            }
        }
    ],
    footer: {
    }
});

const loadLanguages = async (setLanguages, setIsLngLoading) => {
    setIsLngLoading(true);
    const lngs = await getLanguages();
    const lngsValues = lngs.map(({ LanguageID, DisplayName }) => ({
        value: LanguageID,
        text: DisplayName,
    }));

    setLanguages(lngsValues);
    setIsLngLoading(false);
};

const Templates = () => {
    const { t } = useTranslation();
    const [currentTemplate, setCurrentTemplate] = useState(defaultCurrentTemplate);
    const [originalTemplate, setOriginalTemplate] = useState(defaultCurrentTemplate);
    const [usedBy, setUsedBy] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const [isConfirmPopupShown, setIsConfirmPopupShown] = useState(false);
    const { templateUID } = useParams();
    const isEditing = templateUID && templateUID !== 'new';
    const [isLoading, setIsLoading] = useState(isEditing);
    const [languages, setLanguages] = useState([]);
    const [isLngLoading, setIsLngLoading] = useState(false);
    const navigate = useNavigate();
    // Create instance of auth service to get nitroStatus
    const authService = new AuthenticationService(Config.authBaseUrl);
    const { IsNitro: isNitro, SL: subscriptionLevel } = authService.getProfile();

    let currentValues;

    useEffect(() => {
        loadLanguages(setLanguages, setIsLngLoading);
    }, [setLanguages, setIsLngLoading]);

    // If user is not an old nitro account, rename templates page to look like "Edit Leaderboard" page
    if (isEditing) {
        currentValues = (isNitro ? editSections(t) : editSectionsFor3x(t));
    } else {
        currentValues = createSections(t);
    }
    const redirectTo = (url, timeout = 3000) => {
        setTimeout(() => {
            const defaultUrl = "/leaderBoards/LBGroups";
            navigate(url || defaultUrl, { state: {} });
            window.location.href = url || defaultUrl;
        }, timeout);
    };
    const onConfirm = async () => {
        setIsLoading(true);
        toast.dismiss();
        try {
            // removePoundSign to goal colors
            const template = removePoundSign(currentTemplate);
            const masqueradeUser = authService.getMasqueradeAdminUser();
            const receivedTemplate = await setLBSettingTemplate({ data: { ...template, ...{ AdminUser: masqueradeUser } } });
            if (receivedTemplate == 'TemplateNameDuplicate') {
                setIsLoading(false);
                toast.error(
                        ( <NotificationMessage message={'leaderboard__template__error__duplicate-template-name'} isFailure={true}/>),
                        {
                            transition: Slide,
                            hideProgressBar: true,
                            closeOnClick: true,
                            progress: 0,
                            containerId: 1
                        }
                );
            } else {
                const uid = isEditing ? currentTemplate['LBSettingTemplateUID'] : receivedTemplate['LBSettingTemplateUID'];
                if (!uid) {
                    throw Error();
                }
                redirectTo(isNitro ? `/leaderBoards/LBTemplatesList?statusId=1&isEdit=${isEditing}` : `/leaderBoards/LBGroups?statusId=1&isEdit=${isEditing}`);
            }
        } catch (e) {
            toast.error(
                    ( <NotificationMessage message={'leaderboard__template__error__save-template'} isFailure={true}/>),
                    {
                        transition: Slide,
                        hideProgressBar: true,
                        closeOnClick: true,
                        progress: 0,
                        containerId: 1
                    }
            );
        }
    };
    const onCancel = () => {
        const url = currentValues.header.backLink;
        redirectTo(url, 0);
    };
    const onSave = () => {
        if (isEditing) {
            setIsConfirmPopupShown(true);
            return;
        }
        onConfirm();
    };
    currentValues.footer = {
        onCancel,
        onSave
    };
    const onChange = ( resultObj ) => {
        const newCurrentTemplate = { ...currentTemplate, ...resultObj };
        setCurrentTemplate(newCurrentTemplate);
    };
    useEffect(()=>{
        const setTemplate = async () => {
            if (isEditing && currentTemplate[P_K]) {
                const dirty = !Object.keys(originalTemplate).every((key) => originalTemplate[key] === currentTemplate[key]);
                setIsDirty(dirty);
            }
            if (!isEditing || currentTemplate[P_K]) {
                return;
            }
            const rawTemplate = await getLBSettingTemplate(templateUID);
            let receivedTemplate = fixTimeFormat(rawTemplate);
            // addPoundSign to goal colors
            receivedTemplate = addPoundSign(rawTemplate);

            const lbGroupNames = await getLBGroupNames(receivedTemplate['LBSettingTemplateID']) || [];
            setIsLoading(false);
            if (!receivedTemplate) {
                return;
            }
            const LBNames = lbGroupNames.map(({ GroupName } = {}) => GroupName);
            setUsedBy(LBNames);
            if (!originalTemplate[P_K]) {
                setOriginalTemplate(receivedTemplate);
            }
            setCurrentTemplate(receivedTemplate);
        };
        setTemplate();
    }, [currentTemplate]);

    return (
        <>
            <ToastContainer
                className="toast-container-custom mt-3"
                enableMultiContainer
                containerId={1}
                position="top-center"
                autoClose={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
            />
            <LeaderBoardTemplates
                values={currentValues}
                template={currentTemplate}
                onChange={onChange}
                isEditing={isEditing}
                isDirty={isDirty}
                isLoading={isLoading || isLngLoading}
                isNitro={isNitro}
                usedBy={usedBy}
                languages={languages}
                isConfirmPopupShown={isConfirmPopupShown}
                setIsConfirmPopupShown={setIsConfirmPopupShown}
                onConfirm={onConfirm}
            />
        </>
    );
};

export const  TemplatePreview = ({ show, onHide, templateUID }) => {
    const { t } = useTranslation();
    const [currentTemplate, setCurrentTemplate] = useState(defaultCurrentTemplate);
    const [originalTemplate, setOriginalTemplate] = useState(defaultCurrentTemplate);
    const [usedBy, setUsedBy] = useState([]);
    const [isDirty, setIsDirty] = useState(true);
    const [isConfirmPopupShown, setIsConfirmPopupShown] = useState(false);
    const isEditing = templateUID && templateUID !== 'new';
    const [isLoading, setIsLoading] = useState(isEditing);
    const [languages, setLanguages] = useState([]);
    const [isLngLoading, setIsLngLoading] = useState(false);
    const currentValues = isEditing ? editSections(t) : createSections(t);
    // Create instance of auth service to get nitroStatus
    const authService = new AuthenticationService(Config.authBaseUrl);
    const { IsNitro: isNitro, SL: subscriptionLevel } = authService.getProfile();

    useEffect(() => {
        loadLanguages(setLanguages, setIsLngLoading);
    }, [setLanguages, setIsLngLoading]);

    useEffect(()=>{
        const setTemplate = async () => {
            if (isEditing && currentTemplate[P_K]) {
                const dirty = !Object.keys(originalTemplate).every((key) => originalTemplate[key] === currentTemplate[key]);
                setIsDirty(dirty);
            }
            if (!isEditing || currentTemplate[P_K]) {
                return;
            }
            const rawTemplate = await getLBSettingTemplate(templateUID);
            let receivedTemplate = fixTimeFormat(rawTemplate);
            // addPoundSign to goal colors
            receivedTemplate = addPoundSign(rawTemplate);

            const lbGroupNames = await getLBGroupNames(receivedTemplate['LBSettingTemplateID']) || [];
            setIsLoading(false);
            if (!receivedTemplate) {
                return;
            }
            const LBNames = lbGroupNames.map(({ GroupName } = {}) => GroupName);
            setUsedBy(LBNames);
            if (!originalTemplate[P_K]) {
                setOriginalTemplate(receivedTemplate);
            }
            setCurrentTemplate(receivedTemplate);
        };
        setTemplate();
    }, [templateUID]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName={`template-preview-box`}
            title={t('common__preview-template')}>
            <LeaderBoardTemplatePreview
                values={currentValues}
                template={currentTemplate}
                isEditing={true}
                isDirty={isDirty}
                isLoading={isLoading || isLngLoading}
                isNitro={isNitro}
                usedBy={usedBy}
                languages={languages}
                onHide={onHide}
                onChange={() => {}}
                isConfirmPopupShown={isConfirmPopupShown}
                setIsConfirmPopupShown={setIsConfirmPopupShown}
            />
        </Modal>

    );
};

export const ProcessTemplates =compose(
        withHMELayout(),
        withPageState({
            successText: 'Success! The Template settings were applied.',
            errorText: 'Error The Template settings could not be applied at this time.'
        })
)(Templates);
