import React from 'react';
import classNames from 'classnames';

import { Form } from '../Form';

import './Client.scss';

export const Client = ({ className, ...loginFormProps }) => {
    return (
        <div className={classNames('hme-client-login-page', className)}>
            <Form {...loginFormProps} />
        </div>
    );
};
