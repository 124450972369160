import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { map, prop, pipe, sortBy, identity, uniq, uniqBy, pluck } from 'ramda';
import { getStatusCell, getStatusCellValue } from '../helpers';
import { targetDevicesGridHeaderOptions } from '../constants';

import { Grid } from 'components/Common/Grid';

import { taskConfig } from 'constants/applyDeviceSettingsTask';

const { statuses: deviceJobStatuses } = taskConfig;

const ALL_VERSIONS_FILTER_TEXT = 'All Versions';
const ALL_STATUSES_FILTER_TEXT = 'All Statuses';
const ALL_BRANDS_FILTER_TEXT = 'All Brands';

const deviceToRow = device => ({
    ...device,
    statusCell: getStatusCell(device.ApplySettingsStatus),
    statusCellValue: getStatusCellValue(device.ApplySettingsStatus),
});

const sortDevices = devices => devices
    .sort((a, b) => a.ApplySettingsStatus - b.ApplySettingsStatus);

const statusFilterItems = [{
    text: 'In Progress',
    value: deviceJobStatuses.IN_PROGRESS,
}, {
    text: 'Completed',
    value: deviceJobStatuses.COMPLETED,
}, {
    text: <span className='tasks-table-admin-status-filter-failed'>Failed</span>,
    value: deviceJobStatuses.FAILED
}];

const getVersions = pipe(
    pluck('Device_MainVersion'),
    uniq,
    sortBy(identity)
);
const getBrandData = ({ Brand_ID: value, Brand_Name: text }) => ({
    value,
    text,
});
const getBrands = pipe(
    map(getBrandData),
    uniqBy(prop('value')),
    sortBy(prop('text'))
);

const getStatuses = pipe(
    pluck('statusCellValue'),
    uniq
);

export const TargetDevicesList = ({ data }) => {
    const { t } = useTranslation();
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [gridFilters, setGridFilters] = useState({
        Brand_Name: [],
        Device_MainVersion: [],
        statusCell: [],
    });
    const [availableFilters, setAvailableFilters] = useState([]);

    useEffect(() => {
        const versions = getVersions(rows);
        const brands = getBrands(rows);
        const statuses = getStatuses(rows);

        setGridFilters({
            Brand_Name: pluck('value', brands),
            Device_MainVersion: versions,
            statusCell: statuses,
        });

        setAvailableFilters([{
            property: 'Brand_Name',
            items: brands,
            allText: ALL_BRANDS_FILTER_TEXT,
        }, {
            property: 'Device_MainVersion',
            items: versions.map(version => ({
                text: version,
                value: version,
            })),
            allText: ALL_VERSIONS_FILTER_TEXT,
        }, {
            property: 'statusCell',
            items: statusFilterItems.filter(({ value }) => statuses.includes(value)),
            allText: ALL_STATUSES_FILTER_TEXT,
        }]);
    }, [rows, setGridFilters, setAvailableFilters]);

    useEffect(() => {
        const newFilteredRows = rows
            .filter(
                ({ Device_MainVersion }) => gridFilters
                    .Device_MainVersion
                    .includes(Device_MainVersion)
            )
            .filter(({ Brand_ID }) => gridFilters.Brand_Name.includes(Brand_ID))
            .filter(({ statusCellValue }) => gridFilters.statusCell.includes(statusCellValue));

        setFilteredRows(newFilteredRows);
    }, [gridFilters, rows, setFilteredRows]);

    useEffect(() => {
        const devices = data.destinationDevices.map(device => deviceToRow(device));

        setRows(sortDevices(devices));
    }, [data.destinationDevices, setRows]);

    return (
        <Grid
            rows={filteredRows}
            headers={targetDevicesGridHeaderOptions}
            rowKey='Device_UID'
            filters={gridFilters}
            availableFilters={availableFilters}
            onFiltersChange={setGridFilters}
            noRecordsMessage={t('common__device-peripheral-devices-absence')}
        />
    );
};
