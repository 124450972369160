import { DateLib } from '@hme-cloud/utility-common';
import { models } from 'powerbi-client';


const dateIntervalFilterName = {
    'Earliest Date': 'startDate',
    'DateFin_Filter': 'endDate',
    'DateIni_Filter': 'startDate'
};

const customDatesFilterName = {
    GreaterThanOrEqual: 'startDate',
    LessThan: 'endDate'
};

const getDateInfo = async (advancedCards, dateIntervalSlicer) => {
    const pbiEventData = {
        startDate: 'default',
        endDate: 'default'
    };
    try {
        // capture dates for date interval selection
        const advancedCardsPromise = advancedCards.map(async (card)=> {
            const res = await card.exportData(models.ExportDataType.Summarized, 1);
            if (res.data) {
                const dataSplit = res.data.split('\r\n');
                if (dataSplit.length > 2 && dateIntervalFilterName[dataSplit[0]]) {
                    pbiEventData[[dateIntervalFilterName[dataSplit[0]]]] =
                      new DateLib(dataSplit[1].trim()).format(DateLib.FORMAT_TYPES.FULL_YEAR_DATE_AND_TIME);
                }
            }
        });

        // capture dates for custom dates selection
        const dateIntervalSlicerPromise = dateIntervalSlicer.map(async (diSlicer)=> {
            const res = await diSlicer.getSlicerState();
            const { filters } = res;
            if (filters.length > 0) {
                filters[0].conditions.forEach((condition)=> {
                    pbiEventData[[customDatesFilterName[condition.operator]]] =
                      new DateLib(condition.value).format(DateLib.FORMAT_TYPES.FULL_YEAR_DATE_AND_TIME);
                });
            }
        });

        // eslint-disable-next-line no-undef
        await Promise.all([...advancedCardsPromise, ...dateIntervalSlicerPromise]);
        return pbiEventData;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
export { getDateInfo };
