import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { cond, T } from 'ramda';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { getStores } from 'services/Store';
import { addErrorNotification } from 'services/Notifications';
import { storeResultHeaders, storeResultMobileHeaders } from '../../constants/gridHeaders';
import { SearchForm } from './SearchForm';
import { Loader } from './Loader';
import { SuggestionsList } from './SuggestionsList';
import { CommonConstants } from 'Constants';

import './StoreSearchForm.css';

const SEARCH_DEBOUNCE_TIMEOUT = 500;

const Suggestions = cond([
    [({ isLoading }) => isLoading, Loader],
    [T, SuggestionsList],
]);

const loadStores = async ({ setIsLoading, setStores, searchParams = {}, storeFilter = {}, sourceDevice = null, onDone, t }) => {
    const { deviceType: { zoomNitro: { name: zoomNitroName, id: zoomNitroID, minMainVersion: zoomMinMainVersion } } } = CommonConstants;
    try {
        const { searchValue = '' } = searchParams;

        const requestParams = {
            ...storeFilter,
            searchString: searchValue,
            orderBy: 'Device_SerialNumber',
            orderDirection: 'asc',
        };

        if (sourceDevice !== null) {
            requestParams.storeAccountID = sourceDevice.Store_Account_ID;
        }

        const results = await getStores(requestParams);
        const stores = results.map((s) => {
            const updatedStore = {
                ...s,
                Device_Status: s.Device_IsActive
                    ? t('common__device__status--online')
                    : t('common__device__status--offline')
            };

            if (s.Device_DeviceType_ID == zoomNitroID && s.Device_MainVersion[0] >= zoomMinMainVersion) {
                updatedStore.DeviceType_Name = zoomNitroName;
            }

            return updatedStore;
        }).sort((a, b) => (a.Store_Number == searchValue ? -1 : 1));

        setStores(stores);
    } catch (e) {
        addErrorNotification('common__error-processing-request');
        setStores([]);
    }

    setIsLoading(false);

    // trigger callback for debounced executions
    onDone && onDone();
};

const loadStoresDebounced = debounce(loadStores, SEARCH_DEBOUNCE_TIMEOUT);

const SEARCH_MIN_SYMBOLS_AUTOSEARCH = 3;

export const StoreSearchForm = ({ storeFilter, onStoreSelection, checkDisabled, sourceDevice = null }) => {
    const [searchParams, setSearchParams] = useState({ searchValue: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [stores, setStores] = useState([]);
    const [inFocus, setInFocus] = useState(false);

    const searchRef = useRef(null);

    const { t } = useTranslation();

    useEffect(() => {
        setSearchParams({ searchValue: '' });
    }, [sourceDevice]);

    const onSuggested = useCallback(
        (store) => {
            onStoreSelection(store);
        },
        [onStoreSelection],
    );

    const onFocus = useCallback(() => {
        setInFocus(true);
    }, []);

    const onBlur = useCallback(() => {
        setInFocus(false);
    }, []);

    const focusSearch = useCallback(() => {
        if (searchRef && searchRef.current) {
            searchRef.current.focus();
        }
    }, [searchRef]);

    const onSearchDone = useCallback(() => {
        focusSearch();
    }, [focusSearch]);

    const onSearch = useCallback(
        (newSearchParams) => {
            setIsLoading(true);
            loadStoresDebounced.cancel();
            loadStores({
                setIsLoading,
                setStores,
                searchParams: newSearchParams,
                storeFilter,
                t,
                onDone: onSearchDone,
                sourceDevice,
            });
        },
        [storeFilter, onSearchDone],
    );

    const onSearchParamsChange = useCallback(
        (newSearchParams) => {
            setIsLoading(true);
            setSearchParams(newSearchParams);

            if (!newSearchParams.searchValue) {
                focusSearch();
            }

            if (newSearchParams.searchValue.length < SEARCH_MIN_SYMBOLS_AUTOSEARCH) {
                loadStoresDebounced.cancel();
                return;
            }

            loadStoresDebounced({
                setIsLoading,
                setStores,
                searchParams: newSearchParams,
                storeFilter,
                t,
                onDone: onSearchDone,
                sourceDevice,
            });
        },
        [storeFilter, onSearchDone, loadStoresDebounced],
    );

    // TODO make a "library" autocomplete component with Search button
    return (
        <div className="merge-devices__search-store-form" onFocus={onFocus} onBlur={onBlur}>
            <SearchForm
                value={searchParams}
                onChange={onSearchParamsChange}
                onSearch={onSearch}
                isSearchButtonDisabled={false}
                isDisabled={false}
                searchRef={searchRef}
                searchPlaceholder={t('merge_devices__search__placeholder--product-id')}
            />
            {inFocus && searchParams.searchValue && searchParams.searchValue.length >= SEARCH_MIN_SYMBOLS_AUTOSEARCH && (
                <div
                    className={classNames(
                        'hme-auto-complete-suggestions',
                        !stores.length ? 'hme-merge-devices-device-grid-no-stores' : '',
                    )}
                >
                    <Suggestions
                        suggestions={stores}
                        headers={storeResultHeaders}
                        mobileHeaders={storeResultMobileHeaders}
                        noSuggestionText={t('common__no-stores--found')}
                        isLoading={isLoading}
                        loadingMessage={t('autocomplete-input__loading')}
                        rowKey="Device_UID"
                        onSuggested={onSuggested}
                        checkDisabled={checkDisabled}
                    />
                </div>
            )}
        </div>
    );
};
