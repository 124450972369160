import { getDetailedSnapshotsList } from 'services/Snapshots';
import { getStores } from 'services/Store';
import { settingApi } from 'api/settingApi';
import { deviceConfig } from 'constants/device/config';
import { snapshotConfig } from 'constants/manageDeviceSettings';

export { getCountries } from 'services/Countries';
export { getBrands } from 'services/Brands';
export { getDevices } from '../ApplySettingsService';

export const getSnapshots = () => getDetailedSnapshotsList({
    type: 'ZOOM Nitro',
    minVersion: deviceConfig.zoomNitro.applySettingsMinVersion,
    status: snapshotConfig.statuses.CREATED,
});

export const getAllSnapshots = () => getDetailedSnapshotsList({});

export const applySettings = async params => {
    const response = await settingApi.applyToMany({
        ...params,
    });

    if (!response.status) {
        throw new Error('Settings are not applied');
    }
};

export const getInputStores = async (mask, filters) => {
    const data = {
        limit: 3,
        deviceIsActive: 1,
        deviceTypeId: 1,
        hideBusyDevices: 1,
        searchString: mask,
    };

    if (filters.sourceDevice) {
        data.excludeDeviceUID = filters.sourceDevice.Device_UID;
    }

    switch (filters.selectedDeviceType) {
        case 'zoom':
            data.deviceMaxMainVersion = deviceConfig.zoom.applySettingsMaxVersion;
            data.deviceMinMainVersion = filters.sourceDevice ?
                filters.sourceDevice.Device_MainVersion :
                deviceConfig.zoom.applySettingsMinVersion;
            data.sameDetectorsOf = filters.sourceDevice ? filters.sourceDevice.Device_UID : '';
            data.deviceLaneConfigId = filters.sourceDevice ? filters.sourceDevice.Device_LaneConfig_ID : '';

            break;
        case 'zoomNitro':
            data.deviceMinMainVersion = deviceConfig.zoomNitro.applySettingsMinVersion;

            break;
        case 'nexeo':
            data.deviceTypeId = 5;

            break;
        default:

            break;
    }

    return getStores(data);
};
