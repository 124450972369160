import { Config } from 'Config';
import { postDataAsync, getDataAsync, putDataAsync, deleteDataAsync } from 'Api';

export class Rest {
    constructor(baseUrl, { errorTKeys = {} } = {}) {
        this.baseURL = `${Config.apiBaseUrl}${baseUrl}`;
        this.errorTKeys = errorTKeys;
    }

    async request(caller) {
        const response = await caller();
    
        if (!response.status) {
            throw new Error(this.getErrorMessage(response.message));
        }
    
        return response.data;
    }

    getErrorMessage(message) {
        return this.errorTKeys[message] || 'common__error--unknown';
    }

    getCreateUrl() {
        return this.baseURL;
    }

    create(data) {
        return this.request(() => postDataAsync(this.getCreateUrl(), data));
    }

    getFetchAllUrl() {
        return this.baseURL;
    }

    fetchAll() {
        return this.request(() => getDataAsync(this.getFetchAllUrl()));
    }

    getFetchOneUrl(uid) {
        return `${this.baseURL}/${uid}`;
    }

    fetchOne(uid) {
        return this.request(() => getDataAsync(this.getFetchOneUrl(uid)));
    }

    getUpdateUrl(uid) {
        return `${this.baseURL}/${uid}`;
    }

    update(uid, data) {
        return this.request(() => putDataAsync(this.getUpdateUrl(uid), data));
    }

    getDeleteUrl(uid) {
        return `${this.baseURL}/${uid}`
    }

    delete(uid) {
        return this.request(() => deleteDataAsync(this.getDeleteUrl(uid)));
    }

    getCommandUrl(commandName) {
        return `${this.baseURL}/${commandName}`;
    }

    command(commandName, data) {
        return this.request(() => postDataAsync(this.getCommandUrl(commandName), data));
    }
};
