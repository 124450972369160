import React, { useState, useEffect, useCallback, useRef } from 'react';
import { last } from 'ramda';

import { Label } from 'library/Label';
import { Button } from 'library/Button';
import { FileName } from './FileName';

import './FileInput.scss';

const isValidFile = (file, extensions) => {
    const name = file.name;
    const hasAll = extensions.some(extension => extension === '*');

    if (hasAll) {
        return true;
    }

    if (name.indexOf('.') === -1) {
        return false;
    }

    const ext = last(name.split('.'));

    return extensions.some(extension => ext === extension);
};

const isFile = item => {
    // items of folders have kind is 'file' to detect directories from drop
    // we need to call getAsFileSystemHandle that is async function
    return item.kind === 'file' && item.webkitGetAsEntry().isFile;
};

const toFile = item => {
    if (item.kind !== 'file') {
        return null;
    }

    const result = item.getAsFile();

    return isFile(item) ? result : null;
};

export const FileInput = ({ value, multiple = false, extensions = '*', onChange }) => {
    const [fileNames, setFileNames] = useState([]);
    const [extensionsArray, setExtensionsArray] = useState([]);
    const [accept, setAccept] = useState('')
    const fileRef = useRef(null);

    const onDrop = useCallback(e => {
        e.stopPropagation();
        e.preventDefault();
        let files = [];
        const selectedItemsLength = (e.dataTransfer.items || e.dataTransfer.files).length;

        if (!multiple && selectedItemsLength > 1) {
            onChange(null);

            return;
        }

        if (e.dataTransfer.items) {
            // It is not an array
            const items = e.dataTransfer.items;
            const promises = []

            for (let i = 0; i < items.length; ++i) {
                const item = items[i];
                files.push(toFile(item));
            }
        } else {
            const items = e.dataTransfer.files;

            for (let i = 0; i < items.length; ++i) {
                const item = items[i];

                files.push(item);
            }
        }

        files = files
            .filter(file => file !== null)
            .filter(file => isValidFile(file, extensionsArray));

        if (multiple) {
            onChange(files);

            return;
        }

        if (files.length > 1) {
            return;
        }

        onChange(files[0] || null);
    }, [multiple, extensionsArray, onChange]);

    const onDragOver = useCallback(e => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const onOpenFileDialog = useCallback(() => {
        if (!fileRef.current) {
            return;
        }

        fileRef.current.click();
    }, [fileRef]);

    const onFileChange = useCallback(() => {
        if (!fileRef.current) {
            return;
        }

        const files = [];
        const filesList = fileRef.current.files;

        for (let i = 0; i < filesList.length; ++i) {
            files.push(filesList[i]);
        }

        onChange(multiple ? files : files[0]);
    }, [multiple, fileRef, onChange]);

    useEffect(() => {
        const files = multiple ? value : [value];
        const names = files.filter(file => file).map(file => file.name);

        setFileNames(names.length ? names : ['']);
    }, [multiple, value, setFileNames]);

    useEffect(() => {
        const array = extensions.split(',');
        setExtensionsArray(array);
        setAccept(array.map(ext => `.${ext}`).join(','));
    }, [extensions, setExtensionsArray, setAccept]);

    return (
        <div className='hme-file-input' onDrop={onDrop} onDragOver={onDragOver}>
            <Label>
                Drag and drop your file here
            </Label>
            <Label>
                or
            </Label>
            <Button onClick={onOpenFileDialog}>Browse for File</Button>
            <input
                ref={fileRef}
                type='file'
                multiple={multiple}
                accept={accept}
                className='hme-file-input-element'
                onChange={onFileChange}
            />
            {
                fileNames.map((fileName, index) => (
                    <FileName fileName={fileName} key={index} />
                ))
            }
        </div>
    );
};
