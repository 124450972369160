/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { isPublic } from 'services/Auth';

import '../Tiles.css';

const publicWelcomeTileList = [
    'welcome__tile--welcome__list-item-1--public',
    'welcome__tile--welcome__list-item-2--public',
    'welcome__tile--welcome__list-item-3--public',
    'welcome__tile--welcome__list-item-4--public',
    'welcome__tile--welcome__list-item-5--public'
];

const PublicWelcomeTile = () => {
    const { t } = useTranslation();

    return <>
        <p className="tile__paragraph">{t('welcome__tile--welcome__paragraph1--public')}</p>
        <p className="tile__paragraph">{t('welcome__tile--welcome__list-title--public')}:</p>
        <ul>
            {publicWelcomeTileList.map((item) => {
                return <li key={item} className="tile__paragraph">{t(item)}</li>;
            })}
        </ul>
        <p className="tile__paragraph">{t('welcome__tile--welcome__paragraph2--public')}</p>
    </>;
};

const AdminWelcomeTile = () => {
    const { t } = useTranslation();

    return <>
        <p className="tile__paragraph">{t('welcome__tile--welcome__paragraph1')}</p>
        <p className="tile__paragraph">{t('welcome__tile--welcome__paragraph2')}</p>
    </>;
};

export const DefaultWelcomeTile = ({ userName }) => {
    const { t } = useTranslation();

    return (
        <div className="tile-box">
            <div className="tile__box-supporting-container">
                <h3 className="tile__title">
                    {t('welcome__tile--welcome__title', { userName, interpolation: { escapeValue: false } })}
                </h3>

                {isPublic() ? <PublicWelcomeTile /> : <AdminWelcomeTile />}
            </div>
        </div>
    );
};
