import React, { useEffect, useState } from 'react';

import { Checkbox } from 'library/Checkbox';
import { Label } from 'library/Label';
import { SettingsInfo } from './SettingsInfo';

import './DeviceSettings.scss';

const MASTER_GOALS = 'MASTER_GOALS';
const DAYPART_GOALS = 'DAYPART_GOALS';

const options = [{
    value: 'DASHBOARD_LAYOUT',
    text: 'Dashboard Layout'
}, {
    value: 'DAYPART_TIMES',
    text: 'Daypart Times'
}, {
    value: 'TOTAL_TIME_DEFENITION',
    text: 'Total Time Definition'
}, {
    value: 'SHIFT_TIMES',
    text: 'Shift Times'
}, {
    value: MASTER_GOALS,
    text: 'Master Goals'
}, {
    value: 'STORE_HOURS',
    text: 'Store Hours',
}, {
    value: DAYPART_GOALS,
    text: 'Daypart Goals'
}];

const defaultValues = [];

const getNewValues = (checked, value, values) => checked ?
    [...values, value] :
    values.filter(oldValue => oldValue !== value);

const disableSettings = (values, settings) => values ?
    settings.map(({ value, text }) => {
        switch (value) {
        case MASTER_GOALS:
            return {
                value,
                text,
                disabled: values.includes(DAYPART_GOALS),
            }
        case DAYPART_GOALS:
            return {
                value,
                text,
                disabled: values.includes(MASTER_GOALS),
            }
        default:
            return {
                value,
                text,
                disabled: false,
            };
        }
    }) :
    settings;

export const DeviceSettings = ({ values = defaultValues, onChange }) => {
    const [settingsOptions, setSettingsOptions] = useState([]);
    const [isPopupShown, setIsPopupShown] = useState(false);

    useEffect(() => {
        setSettingsOptions(disableSettings(values, options));
    }, [values]);

    return (
        <div className='zoom-device-settings'>
            <Label>Choose device settings to apply</Label>
            <span
                className="icon-info subheader-info-icon"
                onClick={() => { setIsPopupShown(true); }}
            ></span>
            <div className='zoom-device-settings-checkboxes'>
                {
                    settingsOptions.map(({ value, text, disabled }) => (
                        <Checkbox
                            key={value}
                            label={text}
                            disabled={disabled}
                            checked={!!values && values.includes(value)}
                            onChange={checked => onChange(getNewValues(checked, value, values))}
                        />
                    ))
                }
            </div>
            <SettingsInfo
                show={isPopupShown}
                onHide={() => { setIsPopupShown(false); }}
            />
        </div>
    );
};
