/* eslint-disable react/prop-types */
import './HistoryTile.scss';
import { Tile } from 'library/Tile';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const HistoryTile = ({ tile }) => {
    const {
        category,
        dateTimeTiled,
        categoryName,
        from,
        to,
        message
    } = tile || {};
    const { t } = useTranslation();
    return (
        <Tile>
            <div className="hme-history-tile-inner">
                <div className="category-icon">
                    {category}
                </div>
                <div className="history-data">
                    <div className="history-info">
                        <div className="category-label">
                            {categoryName}
                        </div>
                        <div className="history-date">
                            {dateTimeTiled}
                        </div>
                    </div>

                    <dl  className="history-list">
                        <div>
                            <dt>{t('common__from').toUpperCase()}</dt>
                            <dd>{from}</dd>
                        </div>
                        <div>
                            <dt>{t('common__to').toUpperCase()}</dt>
                            <dd>{to}</dd>
                        </div>
                        <div>
                            <dt>{t('common__message').toUpperCase()}</dt>
                            <dd>{message}</dd>
                        </div>
                    </dl>
                </div>
            </div>
        </Tile>
    );
};
