import React from 'react';
import { useTranslation } from 'react-i18next';
import { cond, T } from 'ramda';

import { Toggle } from 'library/Toggle';
import { SerialNumberForm } from '../SerialNumberForm';
import { SnapshotForm } from '../SnapshotForm';

export const sourceTypes = {
    SNAPSHOT: 'SNAPSHOT',
    SERIAL_NUMBER: 'SERIAL_NUMBER',
};

const isType = type => ({ sourceType }) => type === sourceType;
const isSnapshotForm = isType(sourceTypes.SNAPSHOT);
const isSerialNumberForm = isType(sourceTypes.SERIAL_NUMBER);

const SourceForm = cond([
    [isSnapshotForm, SnapshotForm],
    [isSerialNumberForm, SerialNumberForm],
    [T, () => null]
]);

const snapshotsDefault = { zoomNitro: [] };

export const ZoomNitroForm = ({
    params,
    isSnapshotsLoading,
    snapshots = snapshotsDefault,
    onParamsChange,
}) => {
    const { t } = useTranslation();
    const onSourceTypeChange = sourceType => {
        onParamsChange({
            ...params,
            sourceType,
            sourceDevice: null,
            sourceSettingsList: null,
            settingTemplateID: '',
        });
    };

    const onSourceParamsChange = newParams => onParamsChange({
        ...params,
        sourceDevice: null,
        sourceSettingsList: null,
        settingTemplateID: '',
        ...newParams,
    });

    return (
        <>
            <Toggle
                className='source-type-toggle'
                value={params.sourceType}
                label={t('common__select-by')}
                onChange={onSourceTypeChange}
            >
                {[{
                    text: t('common__settings-snapshot'),
                    value: sourceTypes.SNAPSHOT,
                }, {
                    text: t('common__device-serial_no'),
                    value: sourceTypes.SERIAL_NUMBER,
                }]}
            </Toggle>
            <SourceForm
                deviceType='ZOOM Nitro'
                sourceType={params.sourceType}
                params={params}
                onParamsChange={onSourceParamsChange}
                isSnapshotsLoading={isSnapshotsLoading}
                snapshots={snapshots.zoomNitro}
            />
        </>
    );
};
