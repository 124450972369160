import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { VisuallyHidden } from '@reach/visually-hidden';

import { Checkbox } from 'library/Checkbox';
import { ErrorLabel } from 'library/ErrorLabel';
import { Label as LibraryLabel } from 'library/Label';

import './DaySelector.scss';

const RecurrenceLabel = () => {
    const { t } = useTranslation();

    return <LibraryLabel className="hme-day-selector__title">{t('scheduler__recurrence')}</LibraryLabel>;
};

export const weekDaysOptions = [
    {
        value: 0,
        text: 'common__day-abbreviation--sunday'
    },
    {
        value: 1,
        text: 'common__day-abbreviation--monday'
    },
    {
        value: 2,
        text: 'common__day-abbreviation--tuesday'
    },
    {
        value: 3,
        text: 'common__day-abbreviation--wednesday'
    },
    {
        value: 4,
        text: 'common__day-abbreviation--thursday'
    },
    {
        value: 5,
        text: 'common__day-abbreviation--friday'
    },
    {
        value: 6,
        text: 'common__day-abbreviation--saturday'
    }
];

const DEFAULT_CHECKED = [];

const extractValue = ({ value }) => value;

export const DaySelector = ({
    id,
    checkedValues,
    defaultChecked = DEFAULT_CHECKED,
    options = weekDaysOptions,
    onChange,
    disabled = false,
    className = '',
    error,
    Label = RecurrenceLabel
}) => {
    const { t } = useTranslation();
    const [checked, setChecked] = useState(defaultChecked);

    const actualCheckedValues = checkedValues || checked;

    const checkAllDays = useCallback((isAllChecked) => {
        const newCheckedValues = isAllChecked ? options.map(extractValue) : DEFAULT_CHECKED;

        setChecked(newCheckedValues);
        onChange?.(newCheckedValues);
    }, [options, onChange]);

    const checkDay = useCallback((isChecked, value) => {
        const newCheckedValues = isChecked
            ? [...actualCheckedValues, value]
            : actualCheckedValues.filter((checkedDay) => checkedDay !== value);

        setChecked(newCheckedValues);
        onChange?.(newCheckedValues);
    }, [actualCheckedValues, onChange]);

    const isAllDaysUpgrade = actualCheckedValues.length === options.length;

    return (
        <div className={classNames('hme-day-selector', className)}>
            <Label />

            <div className="hme-day-selector__options-wrapper">
                <Checkbox
                    checked={isAllDaysUpgrade}
                    onChange={checkAllDays}
                    disabled={disabled}
                    readOnly={disabled}
                    label={t('device-upgrades__section__form--all-days-of-the-week')}
                    className="hme-day-selector__all-selector"
                />
                <div className="hme-day-selector__checkbox-wrapper">
                    {options.map(({ text, value }) => (
                        <Checkbox
                            key={value}
                            label={t(text)}
                            checked={actualCheckedValues.includes(value)}
                            onChange={(isChecked) => checkDay(isChecked, value)}
                            disabled={disabled}
                            className="hme-day-selector__checkbox"
                        />
                    ))}
                    {error && <ErrorLabel>{error}</ErrorLabel>}
                </div>

                <VisuallyHidden>
                    <input type="text" id={id} name={id} value={actualCheckedValues} readOnly />
                </VisuallyHidden>
            </div>
        </div>
    );
};
