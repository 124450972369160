import React from 'react';
import { useTranslation } from 'react-i18next';

import { BrandSelect } from './BrandSelect';
import { CountrySelect } from './CountrySelect';

export const BrandForm = ({ brands, countries, filters, onFiltersChange }) => {
    const { t } = useTranslation();
    return (
  <>
        <BrandSelect
            brands={brands}
            label={t('common__brand')}
            value={filters.storeBrandId}
            onChange={onFiltersChange && (storeBrandId => onFiltersChange({
                ...filters,
                storeBrandId: +storeBrandId,
            }))}
        />
        {
            filters.storeBrandId ?
                <CountrySelect
                    countries={countries}
                    label={t('common__country')}
                    value={filters.storeCountryId}
                    onChange={onFiltersChange && (storeCountryId => onFiltersChange({
                        ...filters,
                        storeCountryId: +storeCountryId,
                    }))}
                /> :
                null
        }
    </>)
};
