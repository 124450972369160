import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAsync } from 'hooks/useAsync';
import { DateLib } from '@hme-cloud/utility-common';

import { getContestResult, getContestPDF } from '../Controller';

const DEFAULT_CONTEST_DATA = { data: { ContestName: '', results: [] } };

export const useContestResult = ({ uid }) => {
    const { t } = useTranslation();
    const { run, data: contestResult, isLoading } = useAsync(DEFAULT_CONTEST_DATA);

    const [selectedStores, setSelectedStores] = useState([]);

    useEffect(() => {
        run(getContestResult(uid));
    }, [uid]);

    const stores = useMemo(() => {
        return contestResult?.results?.map((store) => ({
            ...store,
            value: store.StoreUID,
            label: store.Store,
            key: store.StoreUID
        }));
    }, [contestResult]);

    const generatePDF = useCallback(async () => {
        const timePart = new DateLib().format(DateLib.FORMAT_TYPES.DATE_UNDERSCORED);

        await getContestPDF({
            contestUID: uid,
            storeUIDs: selectedStores,
            contestName: `${t('common__contest')}${t('contest__certificate')}_${contestResult?.ContestName}_${timePart}`
        });
    }, [selectedStores, uid, contestResult, t]);

    return {
        isLoading,
        contestResult,
        stores,

        generatePDF,

        selectedStores,
        setSelectedStores
    };
};
