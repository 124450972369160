/* eslint-disable complexity */
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'library/Label';
import { SingleSelect } from 'library/SingleSelect';
import { Toggle } from 'library/Toggle';
import { Title } from 'library/Title';
import { ErrorLabel } from 'library/ErrorLabel';
import { InputComponent as Input } from 'components/Inputs';
import { companyTypes, companyTypeList, isFormFieldDisabled } from '../../helpers';

import './CompanyInfo.css';

export const CompanyInfo = ({
    initialValues,
    distributors,
    countries,
    regions = [],
    brands,
    isRegionsLoading,
    isCompanyTypeDisabled,
    isDistributorCreationPage,
    formErrors,
    onChange,
    isReadOnly
}) => {
    const [companyName, setCompanyName] = useState(initialValues.companyName || '');
    const [companyCountryId, setCompanyCountryId] = useState(initialValues.companyCountryId || '');
    const [companyRegionId, setCompanyRegionId] = useState(initialValues.companyRegionId || '');
    const [region, setRegion] = useState(initialValues.region || '');
    const [distributorId, setDistributorId] = useState(initialValues.distributorId || '');
    const [brandUid, setBrandUid] = useState(initialValues.brandUid || '');
    const [companyType, setCompanyType] = useState(initialValues.companyType || companyTypes.FRANCHISE);
    const isMountedCountryRef = useRef(false);
    const isMountedCompanyTypeRef = useRef(false);

    const { t } = useTranslation();
    const isFieldDisabled = useMemo(() => isFormFieldDisabled(), []);

    useEffect(() => {
        // re-init company region after regions are loaded
        if (companyRegionId !== initialValues.companyRegionId) {
            setCompanyRegionId(initialValues.companyRegionId);
        }
    }, [initialValues]);

    useEffect(() => {
        onChange({
            companyName,
            brandUid,
            companyRegionId,
            region,
            distributorId,
        });
    }, [companyName, brandUid, companyRegionId, region, distributorId]);

    // reset region on country change
    useEffect(() => {
        if (isMountedCountryRef.current) {
            onChange({
                companyCountryId,
                companyRegionId: '',
                region: '',
            });
            setCompanyRegionId('');
            setRegion('');
        } else {
            isMountedCountryRef.current = true;
        }
    }, [companyCountryId]);

    // reset brand uid on company change
    useEffect(() => {
        if (isMountedCompanyTypeRef.current) {
            onChange({
                companyType,
                brandUid: '',
            });
            setBrandUid('');
        } else {
            isMountedCompanyTypeRef.current = true;
        }
    }, [companyType]);

    const brandsList = useMemo(
        () =>
            brands.map((brand) => ({
                text: brand.Name,
                value: brand.Uid,
            })),
        [brands],
    );

    const countriesList = useMemo(
        () =>
            countries.map((country) => ({
                text: country.Name,
                value: country.Id,
            })),
        [countries],
    );

    const distributorsList = useMemo(
        () => [
            {
                text: 'account__section__form--select-dealer-distributor',
                value: '',
            },
            ...distributors.map((distributor) => ({
                text: distributor.Company_Name,
                value: distributor.Company_ID,
            })),
        ],
        [distributors],
    );

    const regionsList = useMemo(
        () =>
            regions.map((region) => ({
                text: region.Name,
                value: region.Id,
            })),
        [regions],
    );

    const companyTypeOptions = useMemo(
        () =>
            isDistributorCreationPage
                ? [companyTypeList.find((ct) => ct.value === companyTypes.DISTRIBUTOR)]
                : companyTypeList,
        [isDistributorCreationPage],
    );

    return (
        <div className="hme-account-form-area">
            <Title>{t('my-account__company-info')}</Title>
            <div className="hme-account-form-section">
                <Input
                    value={companyName}
                    onChange={setCompanyName}
                    isRequired = {!isReadOnly}
                    maxLength={200}
                    isDisabled={isFieldDisabled}
                    label="common__company__name"
                    variants={['label-inside-mobile-only']}
                    isReadOnly={isReadOnly}
                    id="companyName"
                />
                {formErrors.companyName && <ErrorLabel>{t(formErrors.companyName)}</ErrorLabel>}
            </div>
            <div className="hme-account-form-section">
                <SingleSelect
                    value={companyCountryId}
                    label={t('account__section__form--company-country')}
                    onChange={(value) => {
                        setCompanyCountryId(+value);
                    }}
                    isRequired = {!isReadOnly}
                    isDisabled={isFieldDisabled}
                    items={countriesList}
                    isReadOnly={ isReadOnly }
                    variants={['no-label']}
                    id="companyCountry"
                />
                {formErrors.companyCountryId && <ErrorLabel>{t(formErrors.companyCountryId)}</ErrorLabel>}
            </div>
            <div className="hme-account-form-section">
                {regions.length ? (
                    <SingleSelect
                        isDisabled={isRegionsLoading || isFieldDisabled}
                        value={companyRegionId}
                        placeholder={t('account__section__form--select-your-state')}
                        label={t('account__section__form--company-state')}
                        onChange={(value) => {
                            setCompanyRegionId(+value);
                        }}
                        isRequired = {!isReadOnly}
                        items={regionsList}
                        isReadOnly={ isReadOnly }
                        variants={['no-label']}
                        id="companyRegion"
                    />
                ) : (
                    <Input
                        isDisabled={isRegionsLoading || isFieldDisabled}
                        value={region}
                        onChange={setRegion}
                        isRequired = {!isReadOnly}
                        maxLength={100}
                        label="account__section__form--company-state"
                        variants={['label-inside-mobile-only']}
                        isReadOnly={ isReadOnly }
                        id="companyRegion"
                    />
                )}
                {formErrors.companyRegionId && <ErrorLabel>{t(formErrors.companyRegionId)}</ErrorLabel>}
            </div>
            <div className="hme-account-form-section">
                <Label>{t('account__section__form--company-type')}</Label>
                <Toggle
                    value={companyType}
                    onChange={setCompanyType}
                    isDisabled={isCompanyTypeDisabled || isDistributorCreationPage || isFieldDisabled}
                    isReadOnly={ isReadOnly }
                >
                    {companyTypeOptions}
                </Toggle>
            </div>
            {companyType === companyTypes.CORPORATION && (
                <div className="hme-account-form-section">
                    <SingleSelect
                        isDisabled={isCompanyTypeDisabled || isFieldDisabled}
                        isRequired
                        value={brandUid}
                        placeholder={t('account__section__form--select-corporate-brand')}
                        label={t('account__section__form--corporate-brand')}
                        onChange={setBrandUid}
                        items={brandsList}
                        isReadOnly={ isReadOnly }
                        variants={['no-label']}
                        id="brand"
                    />
                    {formErrors.brandUid && <ErrorLabel>{t(formErrors.brandUid)}</ErrorLabel>}
                </div>
            )}
            {initialValues.companyType !== companyTypes.DISTRIBUTOR && (
                <div className="hme-account-form-section">
                    <SingleSelect
                        value={distributorId}
                        placeholder={t('account__section__form--select-dealer-distributor')}
                        label={t('account__section__form--dealer-distributor')}
                        onChange={setDistributorId}
                        isDisabled={isFieldDisabled}
                        items={distributorsList}
                        isReadOnly={ isReadOnly  }
                        variants={['no-label']}
                        id="distributor"
                    />
                </div>
            )}
        </div>
    );
};
