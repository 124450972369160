import * as actions from '../../actionTypes/Roles/Roles';

const initialState = {
    'rolesDetails': [
        {
            'Role_UID': '',
            'Role_Name': '',
            'Company_Name': '',
            'Role_Description': '',
            'UserName': ''
        }
    ],
    'userPermission': [
        {
            'Permission_Name': '',
            'Role_Name': ''
        }
    ],
    'permissionList': [
        {
            'Permission_Name': '',
            'Permission_UID': '',
            'Permission_Type': '',
            'Permission_Type_ID': null
        }
    ],
    'getRoleinfo': {
        'roles': {
            'Role_ID': null,
            'Role_IsActive': null,
            'Role_IsDefault': null,
            'Role_IsCorporate': null,
            'Role_Name': '',
            'Role_Description': '',
            'Role_CreatedBy': ''
        },
        'Permission': [
            {
                'Permission_ID': null,
                'Permission_UID': '',
                'Role_IsActive': null,
                'Permission_Name': '',
                'Permission_Description': '',
                'Role_CreatedBy': '',
                'isChecked': null
            }
        ],
        'IsOwner': 0
    },
    'state': true
};

/**
 * reducer
 * @param {Object} state
 * @param {Object} action
 * @return {Object} new state
 */
export default function rolesDetails(state = initialState, action) {
    switch (action.type) {
        case actions.GET_PERMISSIONS:
            state.userPermission = action.userPermission;
            return {
                ...state
            };
        case actions.GET_PERMISSIONS_LIST:
            state.permissionList = action.permissionList;
            return {
                ...state
            };
        case actions.GET_ROLES:
            state.rolesDetails = action.rolesDetails;
            return {
                ...state
            };
        case actions.GET_ROLE_INFORMATION:
            state.getRoleinfo = action.getRoleinfo;
            return {
                ...state
            };
        case actions.GET_ROLES_USERINFO:
            state.IsOwner = action.IsOwner;
            return {
                ...state
            };
        default:
            return state;
    }
}
