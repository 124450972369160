import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from 'library/Section';
import { TargetDeviceList } from './TargetDeviceList';
import { deviceConfig } from 'constants/device';

const { getLaneType } = deviceConfig;

export const TargetDevicesSection = ({
    params,
    sourceType,
    selectedSnapshot,
    selectedDevices,
    isTargetDeviceListShow,
    onDeviceSelection,
}) => {
    const { t } = useTranslation();

    return (
        <Section
            className="apply-settings-public-right-section"
            number={2}
            title={t('common__select-destination-devices')}
            disabled={!isTargetDeviceListShow}
        >
            {isTargetDeviceListShow && (
                <TargetDeviceList
                    selectedSnapshot={selectedSnapshot}
                    devices={params.targetDevices.map(device => ({
                        ...device,
                        Device_LaneConfig_Name: getLaneType(device.Device_LaneConfig_ID),
                    }))}
                    sourceType={sourceType}
                    selection={selectedDevices}
                    onSelectionChange={onDeviceSelection}
                    params={params}
                />
            )}
        </Section>
    );
};
