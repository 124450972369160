
import { Config } from '../../Config';
import AuthenticationService from '../Security/AuthenticationService';

const storesTabs = ['stores', 'deviceUpgrades'];

export default class StoresService {
    constructor() {
        this.authService = new AuthenticationService(Config.authBaseUrl);
    }

    canShowAdminHeader() {
        return this.authService.isAdmin() && this.authService.isLoggedIn();
    }

    canShowAdminSubHeader() {
        const page = this.authService.getUrlMenu();
        const isStores = storesTabs.includes(page);
        console.log('The current page is ', page);
        if (this.authService.getUUID() && isStores) {
            return this.authService.isAdmin() && this.authService.isLoggedIn();
        } else {
            return false;
        }
    }

    canShowPortalHeader() {
        return !this.authService.isAdmin() && this.authService.isLoggedIn();
    }
}
