export const FIRMWARE_MANAGEMENT_GRID_HEADERS = [
    {
        text: 'common__brand',
        property: 'brandName',
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__device__type--nexeo-base',
        property: 'nexeoVersion',
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__device__type--ib7000',
        property: 'IB7000Version',
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__device__type--zoom-nitro',
        property: 'zoomNitroVersion',
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__last-updated-by',
        property: 'updatedBy',
        className: 'hme-grid-cell-show-all-content',
        sortable: true,
    },
    {
        text: 'common__updated-date-n-time',
        property: 'updateDateTime',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
        sortable: true,
    },
    {
        text: 'common__actions',
        property: 'actions',
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-cell-show-all-content hme-grid-centered-cell',
    },
];
