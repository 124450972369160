import React from 'react';
import { compose } from 'ramda';
import { CommonConstants } from 'Constants';
import { withHMELayout } from 'HOCs/withHMELayout';
import { isDistributor } from 'services/Auth';
import { checkAccess } from 'actions/permissions';
import authenticate from 'components/Security/Authentication';
import { withAccess } from 'HOCs/withAccess';
import { MergeDevices } from '../../MergeDevices';
import { accountTypes } from 'constants/accountTypes';

import './MergeDevicesPage.css';

const hasDistributorAccess = () => isDistributor() && checkAccess(CommonConstants.externalPermissions.ManageDevicesAdvanced);

export const PublicMergeDevices = () => <MergeDevices permissionType={accountTypes.DISTRIBUTOR} />;

export const MergeDevicesPage = compose(
        withHMELayout({
            contentClasses: ['public-merge-devices-page'],
        }),
        authenticate,
        withAccess({
            checkAccess: hasDistributorAccess,
        }),
)(PublicMergeDevices);
