import React, { useEffect, useMemo, useState } from 'react';

import { useProfile } from 'components/Security/useProfile';
import { isPartner as isUserPartner } from 'services/Auth';

import { PartnerWelcomeTile } from './PartnerWelcomeTile';
import { DefaultWelcomeTile } from './DefaultWelcomeTile';

import '../Tiles.css';

export const WelcomeTile = () => {
    const [firstName, setFirstName] = useState('');
    const isPartner = useMemo(() => isUserPartner(), []);
    const user = useProfile();

    useEffect(()=> {
        const name = user.name ? user.name : user.User_FirstName;
        setFirstName(name);
    }, [user]);

    return (
        isPartner ? <PartnerWelcomeTile partnerName={firstName}/> : <DefaultWelcomeTile userName={firstName}/>
    );
};
